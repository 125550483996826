export interface ICreditCardStoreState{
    securityCode: string;
    expirationMonth: number;
    expirationYear: number;
    number: string;
    type: string;
    fullName: string;
}

export class CreditCardStoreState implements ICreditCardStoreState{
    securityCode: string;
    expirationMonth: number;
    expirationYear: number;
    number: string;
    type: string;
    fullName: string;

    constructor(dto?: any) {
        this.securityCode = dto && dto.securityCode ? dto.securityCode : '';
        this.expirationMonth = dto && dto.expirationMonth ? dto.expirationMonth : 0;
        this.expirationYear = dto && dto.number ? dto.expirationYear : 0;
        this.number = dto && dto.number ? dto.number : '';
        this.type = dto && dto.type ? dto.type : '';
        this.fullName = dto && dto.fullName ? dto.fullName : '';
    }
}