import '../dialogs.scss';
import '../legalTextsDialog.scss';
import '../../legalTexts.scss';
import * as React from 'react';
import { connect } from 'react-redux';

import { Modal } from '../../../components';
import { StM, ActM, SrvM } from '../../../modules';

export interface IWaiverDialogProps {
    isShow: boolean;
    isShowAuthDialog: boolean;
    isShowAuthDialogSignIn: boolean;

    closeDialog: () => void;
    openAuthDialog: () => Promise<any>;
    openTermsAndConditionsDialog: () => Promise<any>;
    accept: () => void;
    decline: () => void;
    logout: () => Promise<any>;
    getStatic: () => Promise<any>;
}

export interface IWaiverDialogState {
    isNeedAccept: boolean;
    isScrolledToBottom: boolean;
    title: string;
    content: string;
}

class WaiverDialog extends React.Component<IWaiverDialogProps, IWaiverDialogState> {
    private utils = new SrvM.Utils();
    constructor(props: IWaiverDialogProps) {
        super(props);

        this.state = {
            isNeedAccept: false,
            isScrolledToBottom: false,
            title: 'Release Waiver and Grant of Rights',
            content: 'Please wait...'
        };
    }

    componentDidUpdate(prevProps: IWaiverDialogProps) {
        const isShowChanged = !prevProps.isShow && this.props.isShow;
        const isNeedAccept = this.props.isShowAuthDialog || this.props.isShowAuthDialogSignIn;

        if (isNeedAccept !== this.state.isNeedAccept) {
            this.setState({ isNeedAccept });
        }

        if (isShowChanged) {
            this.init(this.props);
        }
    }

    public render() {
        if (!this.props.isShow) return null;
        return (
            <Modal classes="waiver-modal" closeHandler={(e) => { this.onCloseClick(e) }} dialogName={StM.DialogNames.Waiver} customZIndex={9999}>
                <div className="modal-dialog dialog">
                    <div className="modal-dialog-header">
                        <span className="close-dialog" onClick={e => this.onCloseClick(e)}></span>
                        <div className="title">{this.state.title}</div>
                    </div>
                    <div className="modal-dialog-body">
                        <div className="modal-dialog-body-content">
                            <article className="waiver-text-wrapper" 
                                onScroll={(e) => this.handleScroll(e)} 
                                dangerouslySetInnerHTML={this.state && { __html: this.state.content }}
                            />
                        </div>
                        <div className="divider-line"></div>
                        <div className="btns-wrapper modal-dialog-body-content">
                            {this.state.isNeedAccept ? (
                                <>
                                    <div className="left-btn-wrapper">
                                        <button className="btn-decline btn-black" onClick={e => this.onDeclineClick(e)}>CANCEL</button>
                                    </div>
                                    <div className="right-btn-wrapper">
                                        <button className="btn btn-accept btn-red" onClick={e => this.onAcceptClick(e)} disabled={!this.state.isScrolledToBottom}>ACCEPT</button>
                                    </div>
                                </>
                            ) : (
                                    <div className="right-btn-wrapper close">
                                        <button className="btn-accept btn-black" onClick={e => this.onCloseClick(e)}>CLOSE</button>
                                    </div>
                                )}
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }

    private init(props: IWaiverDialogProps) {
        props.getStatic().then((staticPages: Array<IStaticPageDto>) => {
            const page = staticPages.find((page) => page.name === StM.LegalTexts.Waiver);
            const content = page ? page.content : "Page is currently unavailable";
            const title = page ? page.title : "Not Found";
            this.setState({ content, title, isScrolledToBottom: !page || !page.content });
        });
        props.decline();
    }

    private handleScroll(e: any) {
        if (!e || !e.target) return;
        if (this.utils.getIsElementScrolledToBottom(e.target)) {
            this.setState({ isScrolledToBottom: true });
        }
    }

    private onCloseClick(e: any) {
        if (e) { e.preventDefault(); e.stopPropagation(); }
        this.props.closeDialog();
        this.logoutIfNotAcceptedWaiver();
    }

    private onDeclineClick(e: any) {
        if (e) { e.preventDefault(); e.stopPropagation(); }
        this.props.closeDialog();
        this.logoutIfNotAcceptedWaiver();
    }

    private onAcceptClick(e: any) {
        if (e) { e.preventDefault(); e.stopPropagation(); }
        this.props.accept();
        this.props.closeDialog();
        this.props.openTermsAndConditionsDialog();
    }

    private logoutIfNotAcceptedWaiver() {
        if (this.props.isShowAuthDialog && this.props.isShowAuthDialogSignIn) {
            this.props.logout();
        }
    }
}

const mapStateToProps = (state: StM.IGlobalStoreState, ownProps: any) => {
    return {
        isShow: state.dialogs.waiver.isOpened
        , isShowAuthDialog: state.dialogs.auth.isOpened
        , isShowAuthDialogSignIn: state.dialogs.auth.tab === StM.AuthDialogTabs.SignIn
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        closeDialog: () => dispatch(ActM.DialogActions.close(StM.DialogNames.Waiver))
        , openAuthDialog: () => dispatch(ActM.DialogActions.open(StM.DialogNames.Auth, { tab: StM.AuthDialogTabs.SignIn }))
        , openTermsAndConditionsDialog: () => dispatch(ActM.DialogActions.open(StM.DialogNames.TermsAndConditions))
        , accept: () => dispatch(ActM.WaiverDialogActions.accept())
        , decline: () => dispatch(ActM.WaiverDialogActions.decline())
        , logout: () => dispatch(ActM.AccountActions.logout())
        , getStatic: () => dispatch(ActM.AppActions.getStatic())
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(WaiverDialog);
