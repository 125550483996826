import './subscriptionCurrentCard.scss';
import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import * as SrvM from '../../../../services';
import * as StM from '../../../../models/store';
import SubscriptionCurrentCredits, { ISubscriptionCurrentCredit } from './subscriptionCurrentCredits';
import Alert from './alert';

const utils = new SrvM.Utils();

export type SubscriptionCurrentStatus =
    'Active' |
    'Canceling';

export interface ISubscriptionCurrent {
    id: number;
    title: string;
    description: string;
    status: string;
    price: number;
    credits: ISubscriptionCurrentCredit[];
    billingPeriod: string;
    nextRenewDate: string;
    endDate: string;
    billingAlerts: string[];
    subscriptionAlerts: string[];
}

export type ISubscriptionCurrentCardOptions = {
    invertColors?: boolean;
    isActiveSubtitle?: boolean;
};

export default class SubscriptionCurrentCard extends React.Component<{
    subscription: ISubscriptionCurrent,
    handleUnsubscribe: (id: number) => void,
    handleResubscribe: (id: number) => void,
    customSessionTypes: Array<StM.ICustomSessionTypeStoreState>;
    options?: ISubscriptionCurrentCardOptions,
    displayPrice?: boolean,
    displayCancelButton?: boolean,
    displayRemainingCredits?: boolean,
    displayResubscribeButton?: boolean,
}> {

    constructor(props) {
        super(props);
    }

    private formatDateString = (date: string) => moment(date).format('MMM DD, YYYY');

    private getExpirationText = (subscription: ISubscriptionCurrent) => {
        switch (subscription.status) {
            case 'Active':
                return subscription.billingPeriod && subscription.nextRenewDate ? `Renews ${subscription.billingPeriod} on ${this.formatDateString(subscription.nextRenewDate)}` : 'Date error';
            case 'Canceling':
                return subscription.endDate ? `Expires ${this.formatDateString(subscription.endDate)}` : 'Date error';
        }
    }

    render() {
        const subscription = this.props.subscription;
        const isActive = subscription.status === 'Active';
        const expirationClass = isActive ? '' : 'error-color';
        const credits = _.orderBy(subscription.credits, 'sortOrder');

        return (
            <article className="subscription-wrapper">
                <section className="subscription-current-header">
                    <div className="title-wrapper">
                        <span className="subscription-title">{subscription.title}</span>
                        {(this.props.options?.isActiveSubtitle && subscription.status === 'Active') && <div className="active-subtitle">
                            Subscribed
                        </div>}
                        <span className={`expiration ${expirationClass}`}>{
                            this.getExpirationText(subscription)}
                        </span>
                    </div>
                    <div className="desc">{subscription.description}</div>
                </section>

                <section className="subscription-current-main">
                    {!!subscription.subscriptionAlerts?.length && <div className="alerts  alerts-subscription">
                        {subscription.subscriptionAlerts.map((alert, index) =>
                            <Alert key={index} text={alert} type='warning'></Alert>)}
                    </div>}
                    {!!subscription.billingAlerts?.length && <div className="alerts  alerts-billing ">
                        {subscription.billingAlerts.map((alert, index) =>
                            <Alert key={index} text={alert} type='error'></Alert>)}
                    </div>}
                    <hr className="divider"></hr>
                    <div className="credits-wrapper">
                        <ul className="credits-list">
                            {credits.map((credit, index) => 
                                (<li key={index} className="credits-item">
                                    <SubscriptionCurrentCredits 
                                        credit={credit}
                                        displayRemainingCredits={this.props.displayRemainingCredits}
                                        customSessionTypes={this.props.customSessionTypes}
                                    />
                                </li>))}
                        </ul>
                    </div>
                </section>

                <section className="subscription-current-footer">
                    {this.props.displayPrice &&                     
                        <div className="price-wrapper">
                            <span className="price-title">Price:</span>
                            <span className="price">${utils.formatStringPrice(subscription.price.toString())}</span>
                        </div>
                    }
                    {isActive && this.props.displayCancelButton && <div className="btns-wrapper">
                            <button type="button" className="btn btn-black" onClick={(e) => this.handleOnCancellationClick(e)}>
                                Cancel Subscription
                            </button>
                        </div>
                    }
                    {subscription.status === StM.RecurrentPackageOfferStatuses.Canceled && this.props.displayResubscribeButton &&
                        <div className="btns-wrapper">
                            <button type="button" className="btn btn-black" onClick={(e) => this.handleResubscribeClick(e)}>
                                Subscribe
                            </button>
                        </div>
                    }
                </section>
            </article>
        );
    }

    private handleOnCancellationClick(e: any) {
        e.stopPropagation();
        this.props.handleUnsubscribe(this.props.subscription.id);
    }

    private handleResubscribeClick(e: any) {
        e.stopPropagation();
        this.props.handleResubscribe(this.props.subscription.id);
    }
}
