
import '../../../../containers/dialogs/bookingUserList.scss';

import * as React from 'react';

import * as SrvM from '../../../../services';
import Constants, { Images } from '../../../../constants';

export interface IInviteGroupMembersListItem extends IPublicUserDto {
    email?: string;
}

interface IInviteGroupMembersListProps {
    list: IInviteGroupMembersListItem[];
    caption?: string;

    onRemoveItem?: (item: IInviteGroupMembersListItem) => void;
}

export const InviteGroupMembersList = (props: IInviteGroupMembersListProps) => {
    if(!props.list.length) return null;
    const utils = new SrvM.Utils();
    const handleRemoveItemClick = (e: any, item: IInviteGroupMembersListItem) => {
        if (e) { e.stopPropagation(); e.preventDefault(); }
        if (props.onRemoveItem) {
            props.onRemoveItem(item);
        }
    };
    return (
        <ul className='users-list'>
            {props.caption && <span className='list-caption'>{props.caption}</span>}
            {props.list.map((item, index) => {
                const imageUrl = item.imageId ? utils.getImageUrl(item.imageId) : Images.NoPhoto2;
                return (
                    <li className='users-list-item' key={index}>
                        <div className='photo-wrapper'>
                            <img src={imageUrl} alt={item.displayName} title={item.displayName} />
                        </div>
                        <div className='content-wrapper'>
                            <strong className='title'>{utils.shortenString(item.displayName, Constants.NameLength)}</strong>
                            {item.email && <span className='subtitle'>{item.email}</span>}
                        </div>
                        <div className='commands-wrapper'>
                            <button className='btn-link' onClick={(e) => handleRemoveItemClick(e, item)} title='Remove user from list'>Remove</button>
                        </div>
                    </li>
                );
            })}
        </ul>
    );
}