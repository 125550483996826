import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as StoreModule from '../../../models/store';
import * as ServiceModule from '../../../services';
import { Badge } from './badges';

interface ICourtBoardProps {
    court: StoreModule.ICourtStoreState;
    timeSlot: StoreModule.ICourtTimeSlotStoreState;
    filter: StoreModule.IBookPageRouteParams;
    user?: StoreModule.IUserStoreState;

    onCreateSessionClick: (courtId: number, timeKey: string) => any;
    onSessionClick: (from: string, id:number) => void;
    onNonFilterSessionClick: (sessionId: number) => void;
    onBageMouseEnter?: () => void;
    onBageMouseLeave?: () => void;
    
}

interface ICourtBoardState {
}

export class CourtBoard extends React.Component<ICourtBoardProps, ICourtBoardState> {
    constructor(props: ICourtBoardProps) {
        super(props);
    }

    render() {
        const timeBlocks = this.props.timeSlot && this.props.timeSlot.timeBlocks ? this.props.timeSlot.timeBlocks: [];
        const court =  (this.props.timeSlot && this.props.timeSlot.court) || null;
        //
        return (
            <div className="table-column" >
                {timeBlocks.map((timeBlock) => { return(
                    <Badge key={timeBlock.key} 
                           court={this.props.court} 
                           time={timeBlock}
                           user={this.props.user}
                           onBageMouseEnter={this.props.onBageMouseEnter}
                           onBageMouseLeave={this.props.onBageMouseLeave} 
                           filter={this.props.filter} 
                           onCreateSessionClick={this.props.onCreateSessionClick}
                           onSessionClick={(from: string, id:number) => this.props.onSessionClick(from, id)}
                           onNonFilterSessionClick={(sessionId: number) => this.props.onNonFilterSessionClick(sessionId)}/>
                )})}
                
            </div>
        )
    }
}
