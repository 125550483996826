import './baseBadge.scss';

import moment from 'moment';
import * as React from 'react';

import * as StM from '../../../../../models/store';
import * as PolM from '../../../../../policies';
import * as SrvM from '../../../../../services';

const utils = new SrvM.Utils();

export interface IBaseBadgeProps {
    time: StM.ICourtTimeBlockStoreState;
}

export interface IBaseBadge {
}

export interface IBaseBadgeState { }

export class DurationClasses {
    static BLOCK_MIN_15 = 'min-15-cell-dashboard';
    static BLOCK_MIN_30 = 'min-30-cell-dashboard';
    static BLOCK_MIN_45 = 'min-45-cell-dashboard';
    static BLOCK_MIN_60 = 'min-60-cell-dashboard';
    static BLOCK_MIN_90 = 'min-45-2x-cell-dashboard';
    static BLOCK_MIN_120 = 'min-60-2x-cell-dashboard';

    static SMALL = 'badge-sm';
    static LARGE = 'badge-lg';
    static EXTRA_LARGE = 'badge-xl';

    static getDurationClass(duration: number): string {
        let dict: any = DurationClasses;
        return dict['BLOCK_MIN_{0}'.format(duration.toString())];
    }
    static getDurationClassFromSession(session: StM.ISessionStoreState) {
        let minutes = moment.duration(session.endDateTime.diff(session.startDateTime)).asMinutes();
        return this.getDurationClass(minutes);
    }

    static getBadgeSizeByDuration(duration: number): string {
        if (duration < 45) return this.SMALL;
        if (duration > 60 && duration <= 120) return this.LARGE;
        if (duration > 120) return this.EXTRA_LARGE;
        return null;
    }
}

export class CommonBadge{
    private static heightOneMin = 57 / 15;

    public static renderPlayers(session: StM.ISessionStoreState, time: StM.ICourtTimeBlockStoreState){
        const sessionInfoPolicy = new PolM.SessionInfoPolicy(session);
        const players = sessionInfoPolicy.getPlayers();
        const sessionOwner = session.owner;
        const lesson30minVisiblePlayerCount = 2
        const lessonMorePlayers = session.bookings.length > 1
                && time.duration === 30 
                && players.length > lesson30minVisiblePlayerCount
            ? (<div className="players-list-item how-much-players">{(players.length - lesson30minVisiblePlayerCount)} more players</div>)
            : null;

        return (
            <div className="players-list-wrapper-dashboard">
                {players && players.length && players.length > 0 ? players.map((player: StM.IPublicUserStoreState) => {
                    let isHost = session.ownerId === player.id;
                    let host = isHost ? '(host)' : '';
                    return (<div key={player.id} className="players-list-item">{player.displayName} {host}</div>);
                })
                : (sessionOwner ? (<div key={sessionOwner.id} className="players-list-item">{sessionOwner.displayName}</div>) : null)
                }
                {lessonMorePlayers}
            </div>
        );
    } 

    public static getRenderVideoIcon(session: StM.ISessionStoreState) {
        return (utils.hasVideo(session) && (
            <span className="ic_video"></span>
        ));
    }

    public static getBadgeHeightByDuration(duration: number): number {
        return duration * this.heightOneMin;
    }
}