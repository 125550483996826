import './playBadge.scss';
import ClassNames from 'classnames';
import * as React from 'react';
import { IBaseBadgeProps, DurationClasses, CommonBadge } from './baseBadge';

interface IPlayBadgeBadgeProps extends IBaseBadgeProps {
}

interface IPlayBadgeState { }

export class PlayBadge extends React.Component<IPlayBadgeBadgeProps, IPlayBadgeState> {

    constructor(props: IPlayBadgeBadgeProps) {
        super(props);
    }

    render() {
        const durationClass = DurationClasses.getDurationClass(this.props.time.duration);
        const classes = ClassNames('table-dashboard-column-item-wrapper', 'play', durationClass);
        const renderedPlayers = CommonBadge.renderPlayers(this.props.time.session, this.props.time);
        return (
            <div className={classes}>
                <div className="table-dashboard-column-item">
                    <div className="name-coach-dashboard-wrapper">
                        <div className="name-dashboard-wrapper">
                            <div className="type-lesson-dashboard">
                                <span className="type-title">Play</span>
                                {CommonBadge.getRenderVideoIcon(this.props.time.session)}
                            </div>
                        </div>
                    </div>
                    {renderedPlayers}
                </div>
            </div>
        );
    }
}
