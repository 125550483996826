import * as React from 'react';

import { StM, SrvM } from '../../modules';

interface IBasketPackageItemProps {
    basketPackage: StM.IPackageDefinitionStoreState;
    
    removeItem: (id: number, isPackage: boolean) => void;
    onBasketPackageClick: (basketPackage: StM.IPackageDefinitionStoreState) => void;
}

class BasketPackageItemMobile extends React.Component<IBasketPackageItemProps> {
    private utils = new SrvM.Utils();

    constructor(props: IBasketPackageItemProps) {
        super(props);
    }

    public render() {
        if (!this.props.basketPackage) return null;
        const handledPrice = this.utils.formatStringPrice(this.props.basketPackage.price.toString());

        return (
            <div className="order-item package">
                <span className="close" onClick={(e) => this.onRemoveItemClick(e)}></span>
                <div className="order-name">
                    {this.props.basketPackage.title}
                </div>
                <div className="court">Package</div>
                <div className="price">{'$' + handledPrice}</div>
            </div>
        )
    }

    private onRemoveItemClick(e: any) {
        if (e) { e.preventDefault(); e.stopPropagation(); }
        this.props.removeItem(this.props.basketPackage.id, true);
    }
}

export default BasketPackageItemMobile;