import * as React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import * as SrvM from '../../../services';
import * as StM from '../../../models/store';
import * as ActM from '../../../actions';

export const genders: any = [
    { key: 1, value: StM.Gender.Male },
    { key: 2, value: StM.Gender.Female }
];

export const skills: any = [
    { key: 1, value: StM.DisplayUserSkill.Beginner },
    { key: 2, value: StM.DisplayUserSkill.Intermediate },
    { key: 3, value: StM.DisplayUserSkill.Advanced }
];

export interface IDemographicInfoDialogProps extends IBaseDemographicInfoDialogState {
    isShown: boolean;
    user: StM.IUserStoreState;

    closeDialog: () => void;
    onCloseClick: (e: any) => void;
    onNextClick: (e: any) => void;
    handleFieldChange: (name: string, value: any) => void;
    onDrop: (acceptedFiles: any, rejectedFiles: any) => void;
}

export interface IBaseDemographicInfoDialogProps {
    isShow: boolean;
    isAuth: boolean;
    returnUrl: string;
    user: StM.IUserStoreState;
    club: StM.IClubStoreState;

    closeDialog: () => void;
    getCurrentUser: () => Promise<any>;
    save: (model: StM.IUserManagableStoreState) => Promise<any>;
    showSpinner: () => any;
    hideSpinner: () => any;
    showAlert: (msgKey: string) => Promise<any>;
    uploadProfileImage: (file: any) => Promise<any>;
    openBillingInfoDialog: (returnUrl: string) => Promise<any>;
    openByReturnUrl: (returnUrl: string) => void;
    setProfileCompletionConfirmation: (value: boolean) => void;
}

export interface IBaseDemographicInfoDialogState {
    demInfo: StM.IUserManagableStoreState;
    errors: any;
    imagePreview: string;
    validationResult: any;
}

const withBaseDemographicDialog = (Component: React.ComponentType<IDemographicInfoDialogProps>) => {
    return class BaseDemographicInformationDialog extends React.Component<IBaseDemographicInfoDialogProps, IBaseDemographicInfoDialogState> {
        private validator = new SrvM.ValidationService();
        private formValidator = new SrvM.FormValidationService();

        constructor(props: IBaseDemographicInfoDialogProps) {
            super(props);
            this.state = {
                errors: null
                , demInfo: new StM.UserManagableStoreState()
                , imagePreview: ''
                , validationResult: null
            }
        }

        componentDidMount() {
            this.init(this.props);
        }
    
        componentDidUpdate(prevProps: IBaseDemographicInfoDialogProps) {
            const isShowChanged = !prevProps.isShow && this.props.isShow;
            const isAuthChanged = this.props.isShow && prevProps.isAuth && !this.props.isAuth;
    
            if (isAuthChanged) {
                this.props.closeDialog();
            }
    
            if (isShowChanged) {
                this.init(this.props);
            }
        }

        render() {
            return (
                <Component
                    {...this.state}
                    user={this.props.user}
                    isShown={this.props.isShow && this.props.isAuth}
                    onCloseClick={(e) => this.onCloseClick(e)}
                    onNextClick={(e) => this.onNextClick(e)}
                    onDrop={(accepted, rejected) => this.onDrop(accepted, rejected)}
                    handleFieldChange={(name, value) => this.handleFieldChange(name, value)}
                    closeDialog={() => this.props.closeDialog()}
                />
            );
        }

        private init(props: IBaseDemographicInfoDialogProps) {
            const demInfo = new StM.IUserManagableStoreState();
            demInfo.dateOfBirth = props.user.dateOfBirth;
            demInfo.phone = props.user.phone;
            demInfo.gender = props.user.gender;
            demInfo.skill = props.user.skill;
            demInfo.emergencyContactFullName = props.user.emergencyContactFullName;
            demInfo.emergencyContactPhoneNumber = props.user.emergencyContactPhoneNumber;
            demInfo.emergencyContactRelationship = props.user.emergencyContactRelationship;

            this.setState({ ...this.state, ...{ demInfo: demInfo }, errors: null }, () => {
                if (!!demInfo.dateOfBirth && !!demInfo.skill && !!demInfo.gender) {
                    this.validate(demInfo);
                }
            });
        }

        private validate(user: StM.IUserManagableStoreState): boolean {
            const errors = this.formValidator.validateAccountInformationForm(user, this.props.club, !this.props.user.dateOfBirth);
            if (this.validator.hasErrors(errors)) {
                this.setState({ ...this.state, errors });
                return false;
            }
            return true;
        }

        private onCloseClick(e: any) {
            if (e) { e.preventDefault(); e.stopPropagation(); }
            this.props.setProfileCompletionConfirmation(false);
            this.props.closeDialog();
            document.body.style.overflow = '';
        };

        private onNextClick(e: any) {
            if (e) { e.preventDefault(); e.stopPropagation(); }

            if (!this.validate(this.state.demInfo)) return false;
            const returnUrl = this.props.returnUrl;
            this.props.showSpinner();
            this.props.save(this.state.demInfo)
                .then(() => {
                    return this.props.getCurrentUser();
                }).then(() => {
                    this.props.hideSpinner();
                    this.props.closeDialog();
                    if (!this.props.user.paymentProfileId ||
                        this.props.user.paymentProfileStatus === StM.PaymentProfileStatus.Suspended ||
                        this.props.user.paymentProfileStatus === StM.PaymentProfileStatus.NotInitialized) 
                    {
                        this.props.openBillingInfoDialog(returnUrl);
                    } else if (returnUrl) {
                        this.props.openByReturnUrl(returnUrl);
                    }
                }).catch(() => {
                    this.props.hideSpinner();
                });
        }

        private handleFieldChange(name: string, value: any) {
            let errors = !!this.state.errors && { ...this.state.errors };
            if (errors) delete errors[name];
            this.setState({
                demInfo: {
                    ...this.state.demInfo,
                    [name]: value
                },
                errors
            });
        };

        private onDrop(acceptedFiles: any, rejectedFiles: any) {
            if (rejectedFiles.length) {
                this.setState({
                    ...this.state, ...{
                        imagePreview: null
                        , validationResult: { error: 'Please choose another file and try again' }
                    }
                });
            } else if (acceptedFiles.length) {
                this.props.uploadProfileImage(acceptedFiles[0])
                    .then(() => {
                        this.setState({
                            ...this.state,
                            ...{
                                imagePreview: URL.createObjectURL(acceptedFiles[0]),
                                validationResult: { success: 'Your profile image was updated' },
                            },
                        });
                    }).catch((error) => {
                        this.setState({
                            ...this.state, ...{
                                imagePreview: null,
                                validationResult: { error: error.message || 'Error updating profile image. Please try again' }
                            }
                        });
                    });
            }
        };
    };
};

const mapStateToProps = (state: StM.IGlobalStoreState) => {
    return {
        isShow: state.dialogs.demographicInformation.isOpened
        , returnUrl: state.dialogs.demographicInformation.returnUrl
        , isAuth: state.app.isAuthorized
        , user: state.user
        , club: state.club
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        closeDialog: () => dispatch(ActM.DialogActions.close(StM.DialogNames.DemographicInformation))
        , save: (model: StM.IUserManagableStoreState) => dispatch(ActM.DemographicInformationDialogActions.save(model))
        , getCurrentUser: () => dispatch(ActM.UserActions.getCurrent())
        , showSpinner: () => dispatch(ActM.AppActions.showSpinner())
        , hideSpinner: () => dispatch(ActM.AppActions.hideSpinner())
        , showAlert: (msgKey: string) => dispatch(ActM.DialogActions.open(StM.DialogNames.Alert, { msgKey: msgKey, messageType: StM.MessageTypes.Success }))
        , uploadProfileImage: (file: any) => dispatch(ActM.ProfilePageActions.uploadProfileImage(file))
        , openBillingInfoDialog: (returnUrl: string) => dispatch(ActM.DialogActions.open(StM.DialogNames.BillingInfo, { returnUrl: returnUrl }))
        , openByReturnUrl: (returnUrl: string) => ActM.DialogActions.openByReturnUrl(returnUrl)
        , setProfileCompletionConfirmation: (value: boolean) => dispatch(ActM.UserActions.setProfileCompletionConfirmation(value))
    };
};

export const withDemographicInfoDialog = compose(
    connect(mapStateToProps, mapDispatchToProps)
    , withBaseDemographicDialog);
