import './dialogs.mobile.scss';
import './sessionInvitationDialog.mobile.scss';

import * as React from 'react';
import ClassNames from 'classnames';

import { StM } from '../../modules';
import UserListMobile from './bookingUserList.mobile';
import { ModalMobile, InputSearchMobile } from '../../components';

interface ISessionInvitationDialogProps {
    isShow: boolean;
    maxPlayerCount: number;
    session?: StM.ISessionStoreState;
    sessionType: string;
    user: StM.IUserStoreState;
    users: Array<StM.IPublicUserStoreState>;
    playersList: Array<any>;
    isGroup?: boolean

    closeInvitationDialog: () => void;
    selectPlayer: (list: any[]) => void;
    handlePlayerRemove: (id: string) => void;
}

export default class SessionInvitationDialogMobile extends React.Component<ISessionInvitationDialogProps> {
    private availablePlayers: Array<any>;
    private userSelected: Array<any>;
    private playersListFiltered: Array<any>;

    constructor(props: ISessionInvitationDialogProps) {
        super(props);
    }

    public render() {
        const { maxPlayerCount, user, users, isShow, playersList, sessionType } = this.props;
        if (!isShow) return null;

        const availableUsers: StM.IPublicUserStoreState[] = [];
        this.playersListFiltered = playersList;

        if (this.playersListFiltered.length > (maxPlayerCount)) {
            this.playersListFiltered = this.playersListFiltered.slice(0, maxPlayerCount);
            this.userSelected = this.getInputSearchUsers(this.playersListFiltered);
        }

        if(maxPlayerCount >= 1) {
            availableUsers.push(...users.filter(u => u.isAvailableForInvitation && u.id !== user.id ));
        }

        this.availablePlayers = this.getInputSearchUsers(availableUsers);
        this.userSelected = this.getInputSearchUsers(playersList);

        const modalClasses = ClassNames('invitation-dialog', sessionType);
        return (
            <ModalMobile classes={modalClasses} dialogName={"session-invitation"}>
                <div className="modal-dialog-header">
                    <div className="title">Invite a {this.props.isGroup ? 'Player' : 'Friend'}</div>
                </div>
                <div className="modal-dialog-body invite-wrapper-mobile">
                    <div className="form-wrapper">
                        <InputSearchMobile placeholder="Search"
                            classes="input-search-wrapper-mobile" 
                            array={this.availablePlayers} 
                            max={maxPlayerCount} 
                            multiple
                            selected={this.userSelected} 
                            notFoundText="no players found"
                            addBtnText="Invite"
                            isNeedSort
                            onSelectChange={(list)=> this.selectChangeHandler(list)}
                        >
                        </InputSearchMobile>
                        <UserListMobile 
                            list={this.playersListFiltered} 
                            removeClick={(id) => this.removePlayerHandler(id)}
                            showRemoveButton={(item) => this.showRemoveButton(item)}
                        />
                    </div>
                </div>
                <div className="modal-dialog-footer">
                    <div className="btns-wrapper">
                        <button className="btn-mobile btn-black btn-invite" onClick={() => this.props.closeInvitationDialog()}>OK</button>
                    </div>
                </div>
            </ModalMobile>
        );
    }

    // helpers

    private getInputSearchUsers(array: StM.IPublicUserStoreState[]) {
        return array.map(item => {
            return {
                key: item.id,
                value: item.displayName
            }
        });
    }

    private showRemoveButton(user: StM.IPublicUserStoreState) : boolean {
        return user.id === this.props.user.id || user.invitedBy === this.props.user.id || (this.props.session && this.props.session.ownerId === this.props.user.id);
    }

    // handlers

    private removePlayerHandler(id: string) {
        this.props.handlePlayerRemove(id);
    }

    private selectChangeHandler(list: any[]) {
        this.props.selectPlayer(list);
    }
}