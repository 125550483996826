import * as React from 'react';
import { compose, Dispatch } from 'redux';
import { connect } from 'react-redux';
import * as _ from 'lodash';
import moment from 'moment';

import { StM, SrvM, ActM } from '../../../modules';
import { IInputSearchItem } from '../../../components/inputSearch';

export interface IBillingInfoDialogProps extends IBaseBillingInfoDialogState {
    isShown: boolean;
    months: IInputSearchItem[];
    years: IInputSearchItem[];
    countries: IInputSearchItem[];
    type: StM.PaymentSystem;
    club: StM.IClubStoreState;

    onCloseDialog: (e: any) => void;
    onSave: (e: any) => void;
    onFieldChange: (name: string, value: any) => void;
    validateForm: (validateCard?: boolean) => boolean;
    showSpinner: () => void;
    hideSpinner: () => void;
    updatePaymentProfile: (profile: StM.IPaymentProfileInformationStoreState) => Promise<string>;
    completePaymentMethod: (success?: boolean) => Promise<void>;
    handleCardSave: () => Promise<any>;
    handleCardSaveError: (error: any) => void;
    getCurrentUser: (isLoadNotifications?: boolean) => Promise<void>;
    startCardValidation: () => void;
    stopCardValidation: () => void;
}

interface IBaseBillingInfoDialogProps {
    isShown: boolean;
    returnUrl: string;
    isAuth: boolean;
    user: StM.IUserStoreState;
    needProfileCompletionConfirmation: boolean;
    type: StM.PaymentSystem;
    club: StM.IClubStoreState;

    showSpinner: () => any;
    hideSpinner: () => any;
    closeDialog: () => void;
    getCountries: () => any[];
    save: (paymentProfiileInformation: StM.IPaymentProfileInformationStoreState) => Promise<any>;
    getCurrentUser: (isLoadNotifications?: boolean) => Promise<any>;
    openByReturnUrl: (returnUrl: string) => void;
    showBalanceProcessedAlert: () => Promise<any>;
    setProfileCompletionConfirmation: (value: boolean) => void;
    showAlert: (msgKey: string, messageType: string) => void;
    closeAlert: () => void;
    updatePaymentProfile: (profile: StM.IPaymentProfileInformationStoreState) => Promise<string>;
    completePaymentMethod: (success?: boolean) => Promise<void>;
    startCardValidation: () => void;
    stopCardValidation: () => void;
}

interface IBaseBillingInfoDialogState {
    paymentProfile: StM.IPaymentProfileInformationStoreState;
    errors?: any;
    hadOutstandingBalanceError?: boolean;
}

export const BillingInfoDescription = () => {
    return (
        <div className='description'>
            <p>To book courts please provide your billing information.</p>
        </div>
    );
};

export const getCardTypeClass = (type: string) => {
    let result = '';
    switch (type) {
        case StM.CardSystem.Visa:
            result = 'visa-card';
            break;
        case StM.CardSystem.MasterCard:
            result = 'master-cart-card';
            break;
        case StM.CardSystem.AMEX:
            result = 'amex-card';
            break;
        default:
            result = '';
            break;
    }
    return result;
};

const withBaseBillingInfoDialog = (Component: React.ComponentType<IBillingInfoDialogProps>) => {
    return class BaseBookDialog extends React.Component<IBaseBillingInfoDialogProps, IBaseBillingInfoDialogState> {
        private utils = new SrvM.Utils();
        private validator = new SrvM.ValidationService();
        private formValidator = new SrvM.FormValidationService();
        private countries: IInputSearchItem[] = [];
        private months: IInputSearchItem[] = [];
        private years: IInputSearchItem[] = [];
        constructor(props: IBaseBillingInfoDialogProps) {
            super(props);

            this.countries = this.utils.getCountriesInputSearchItems(this.props.getCountries());
            this.months = this.getMonths();
            this.years = this.getYears();

            this.state = {
                paymentProfile: new StM.PaymentProfileInformationStoreState(
                    { 
                        creditCard: new StM.CreditCardStoreState(),
                        billingAddress: new StM.BillingAddressStoreState()
                    }) ,
                errors: null,
            };
        }

        componentDidUpdate(prevProps: IBaseBillingInfoDialogProps) {
            const isAuthChanged = this.props.isShown && prevProps.isAuth && !this.props.isAuth;
            const isShownChanged = this.props.isShown !== prevProps.isShown;
    
            if (isAuthChanged) {
                this.props.closeDialog();
            }
    
            if (!isShownChanged) return;
    
            if (!this.props.isShown) {
                this.props.setProfileCompletionConfirmation(false);
                this.setState({
                    paymentProfile: new StM.PaymentProfileInformationStoreState({
                        creditCard: new StM.CreditCardStoreState(),
                        billingAddress: new StM.BillingAddressStoreState(),
                    }),
                    errors: null,
                    hadOutstandingBalanceError: false,
                });
            } else {
                if (this.utils.getIsOpenDemographicInfoDialog(this.props.user)) {
                    this.props.setProfileCompletionConfirmation(false);
                }
                if (!this.props.user.outstandingBalance && this.state.hadOutstandingBalanceError) {
                    this.props.showBalanceProcessedAlert();
                    this.setState({ hadOutstandingBalanceError: false });
                }
            }
        }

        public render() {
            return (
                <Component
                    {...this.state}
                    isShown={this.props.isShown}
                    months={this.months}
                    years={this.years}
                    countries={this.countries}
                    type={this.props.type}
                    club={this.props.club}
                    onCloseDialog={(e: any) => this.handleCloseDialog(e)}
                    onSave={(e) => this.handleSave(e)}
                    onFieldChange={(name, value) => this.handleFieldChange(name, value)}
                    validateForm={(validateCard?: boolean) => this.validateForm(validateCard)}
                    showSpinner={() => this.props.showSpinner()}
                    hideSpinner={() => this.props.hideSpinner()}
                    completePaymentMethod={(success) => this.props.completePaymentMethod(success)}
                    updatePaymentProfile={(profile) => this.props.updatePaymentProfile(profile)}
                    handleCardSave={() => this.handleCardSave()}
                    handleCardSaveError={(error) => this.handleCardSaveError(error)}
                    getCurrentUser={(isLoadNotifications) => this.props.getCurrentUser(isLoadNotifications)}
                    startCardValidation={() => this.props.startCardValidation()}
                    stopCardValidation={() => this.props.stopCardValidation()}
                />
            );
        }

        private handleCloseDialog(e: any) {
            if (e) e.preventDefault();
            this.props.closeDialog();
            this.props.setProfileCompletionConfirmation(false);
        }

        // handlers
        private handleSave(e: any) {
            if (e) e.preventDefault();

            const card = this.state.paymentProfile;
            if(!this.validateForm()) return true;

            if (this.props.user.outstandingBalance) {
                this.setState({ ...this.state, hadOutstandingBalanceError: true });
            }
            if (this.state.errors) delete this.state.errors.server;

            this.props.showSpinner();
            this.props.save(card)
                .then(() => this.handleCardSave())
                .catch((error: any) => this.handleCardSaveError(error));
        }

        private handleCardSave() {
            return this.props.getCurrentUser()
                .then(() => {
                    this.props.hideSpinner();
                    this.props.stopCardValidation();
                    if (this.props.returnUrl) {
                        this.props.openByReturnUrl(this.props.returnUrl);
                    }
                    if (this.props.needProfileCompletionConfirmation) {
                        this.props.showAlert(StM.MessagesKey.ProfileCompleted, StM.MessageTypes.Success);
                        this.props.setProfileCompletionConfirmation(false);
                    }
                    this.props.closeDialog();
                }).catch(() => {
                    this.props.hideSpinner();
                    this.props.stopCardValidation();
                });
        }

        private handleCardSaveError(error: any) {
            this.props.hideSpinner();
            this.props.stopCardValidation();
            this.setState({
                ...this.state,
                errors: {
                    ...this.state.errors,
                    ...this.validator.setError('server', error.data ? (error.data.exceptionMessage || error.message) : null)
                },
            });
        }

        private handleFieldChange(name: string, value: any) {
            let errors = !!this.state.errors && { ...this.state.errors };
            if (errors) errors = _.omit(errors, name);
            const profile = { ...this.state.paymentProfile };
            switch (name) {
                case 'creditCard.number':
                    value = value.replace(/(\D)+/g, '').replace(/^(\d{1,19})?.*/, '$1');
                    profile.creditCard.type = this.getCardType(value);
                    break;
                case 'creditCard.fullName':
                    value = value.replace(/([^A-Za-z_\s])+/g, '').toUpperCase();
                    break;
                case 'creditCard.securityCode':
                    value = value.replace(/^(\d{1,4})?.*/, '$1');
                    break;
            }
            this.setState({
                ...this.state,
                paymentProfile: {
                    ..._.set(profile, name, value),
                },
                errors,
            });
        }

        private getMonths() {
            let monthes = moment.monthsShort();
            let result: IInputSearchItem[] = [];
            for (let i = 0; i < monthes.length; i++) {
                let number = i + 1 < 10 ? '0' + (1 + i) : i + 1;
                result.push({
                    key: i + 1,
                    value: number,
                });
            }
            return result;
        }

        private getYears() {
            let minYear = moment().year();
            let maxYear = moment().year() + 20;
            let years: IInputSearchItem[] = [];
            for (let i = minYear; i <= maxYear; i++) {
                years.push({
                    key: i,
                    value: i,
                });
            }
            return years;
        }

        private getCardType(number: string) {
            if (!number) return '';
            const control = number[0];
            switch (control) {
                case '4': {
                    return StM.CardSystem.Visa;
                }
                case '5': {
                    return StM.CardSystem.MasterCard;
                }
                case '3': {
                    return StM.CardSystem.AMEX;
                }
                default: {
                    return '';
                }
            }
        }

        private validateForm(validateCard: boolean = true) {
            const profile = this.state.paymentProfile;
            const errors = this.formValidator.validateBillingForm(profile, validateCard, this.shouldValidateAddress(profile.billingAddress.country));
            if (this.validator.hasErrors(errors)) {
                this.setState({ ...this.state, errors });
                return false;
            }
            return true;
        }

        private shouldValidateAddress(country: string) {
            return _.includes(['United States', 'US'], country);
        }
    };
};

const mapStateToProps = (state: StM.IGlobalStoreState, ownProps: any) => {
    const dialog = state.dialogs.billingInfo;
    return {
        user: state.user,
        isShown: dialog.isOpened && dialog.type === ownProps.type,
        returnUrl: state.dialogs.billingInfo.returnUrl,
        isAuth: state.app.isAuthorized,
        needProfileCompletionConfirmation: state.needProfileCompletionConfirmation,
        club: state.club,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        showSpinner: () => dispatch(ActM.AppActions.showSpinner()),
        hideSpinner: () => dispatch(ActM.AppActions.hideSpinner()),
        closeDialog: () => dispatch(ActM.DialogActions.close(StM.DialogNames.BillingInfo)),
        getCountries: () => dispatch(ActM.BillingInfoDialogActions.getCountries()),
        save: (paymentProfiileInformation: StM.IPaymentProfileInformationStoreState) => dispatch(ActM.BillingInfoDialogActions.save(paymentProfiileInformation)),
        getCurrentUser: (isLoadNotifications?: boolean) => dispatch(ActM.UserActions.getCurrent(isLoadNotifications, false)),
        openByReturnUrl: (returnUrl: string) => ActM.DialogActions.openByReturnUrl(returnUrl),
        showBalanceProcessedAlert: () =>
            dispatch(
                ActM.DialogActions.open(StM.DialogNames.Alert, {
                    msgKey: StM.MessagesKey.OutstandingBalanceUpdated,
                    messageType: StM.MessageTypes.Success,
                })
            ),
        setProfileCompletionConfirmation: (value: boolean) => dispatch(ActM.UserActions.setProfileCompletionConfirmation(value)),
        showAlert: (msgKey: string, messageType: string) => dispatch(ActM.DialogActions.open(StM.DialogNames.Alert, { msgKey, messageType })),
        closeAlert: () => dispatch(ActM.DialogActions.close(StM.DialogNames.Alert, null, true)),
        updatePaymentProfile: (profile: StM.IPaymentProfileInformationStoreState) => dispatch(ActM.UserActions.updatePaymentProfile(profile)),
        completePaymentMethod: (success?: boolean) => dispatch(ActM.UserActions.completePaymentMethod(success)),
        startCardValidation: () => dispatch(ActM.UserActions.startCreditCardValidation()),
        stopCardValidation: () => dispatch(ActM.UserActions.stopCreditCardValidation()),
    };
};

export const withBillingInfoDialog = compose(connect(mapStateToProps, mapDispatchToProps), withBaseBillingInfoDialog);
