import './dialogs.scss';
import './dialogs.mobile.scss';
import './cancelConfirmation.mobile.scss';

import ClassNames from 'classnames';
import _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';

import * as StM from '../../models/store';
import { SrvM } from '../../modules';
import * as ActM from '../../actions';
import { ModalMobile } from '../../components/modal.mobile';

let utils = new SrvM.Utils();

interface ICancelConfirmationProps {
    isShown: boolean;
    messageType: string;
    title: string;
    note: string;
    isOpenByService?: boolean;
    session: StM.ISessionStoreState;
    match: any;
    agreeWithCancelationFee: boolean;
    acceptButtonText: string;
    rejectButtonText: string;

    closeDialog: (isOpenByService?: boolean) => void;
    cancelSession: (session: StM.ISessionStoreState, agreeWithCancelationFee: boolean) => Promise<StM.ISessionStoreState>;
    updateCurrentPageSessions: (params: any) => Promise<StM.ISessionStoreState>;
    closeSessionInfoDialog: () => void;
    showAlertCancelSession: (msgKey: string) => Promise<any>;
    showAlert: (msgKey: string, messageType?: string, message?: string) => Promise<any>;
    showSpinner: () => any;
    hideSpinner: () => any;
    yesCallback: () => void;
}

interface ICancelConfirmationState {}

class CancelConfirmationMobile extends React.Component<ICancelConfirmationProps, ICancelConfirmationState> {
    constructor(props: ICancelConfirmationProps) {
        super(props);
    }

    render() {
        if (this.props.isShown) {
            const messageType = this.props.messageType || StM.MessageTypes.Success || StM.MessageTypes.Warning || StM.MessageTypes.Error;
            const rootClasses = ClassNames('alert-dialog-mobile', messageType);
            const titleClasses = ClassNames('title', messageType);
            return (
                <ModalMobile customZIndex={999999} classes={rootClasses} closeHandler={() => this.props.closeDialog(this.props.isOpenByService)} dialogName={StM.DialogNames.CancelConfirmation} showHeader={false}>
                    <div className="modal-dialog-content-mobile">
                        <div className="modal-dialog-header">
                            <div className={titleClasses}><span>{this.props.title}</span></div>
                        </div>
                        <div className="modal-dialog-body">
                            {this.renderNote(this.props.note)}
                        </div>
                    </div>
                    <div className="modal-dialog-footer">
                        <div className="btns-wrapper">
                            <button className="btn-mobile btn-black btn-close" onClick={(e) => { this.props.yesCallback(); this.closeClick(e) }}>
                                {this.props.acceptButtonText}
                            </button>
                            <button className="btn-mobile btn-black btn-close" onClick={(e) => this.closeClick(e)}>
                                {this.props.rejectButtonText}
                            </button>
                        </div>
                    </div>
                </ModalMobile>
            );
        } else {
            return null;
        }
    }

    renderNote(string: string) {
        if(string) {
            return <div className="note-mobile" dangerouslySetInnerHTML={{__html: string}}></div>
        } 
        return null;
    }

    closeClick(e:any) {
        if(e) {e.stopPropagation();}
        const prom = new Promise<void>((resolve, reject)=>{
            setTimeout(()=>{
                this.props.closeDialog(this.props.isOpenByService);
                return resolve();
            }, 200);
        });
        this.props.showSpinner();
        prom.then(()=>{
            this.props.hideSpinner();
        }).catch(() => {
            this.props.hideSpinner();
        });
    }

    cancelSessionAction (e) {
        if(e) {e.stopPropagation();}

        this.props.showSpinner();
        this.props.closeDialog(this.props.isOpenByService);

        this.props.cancelSession(this.props.session, this.props.agreeWithCancelationFee)
        .then(() => {
            return Promise.resolve(this.props.updateCurrentPageSessions(this.props.match.params));
        }).then(() => {
            this.props.closeSessionInfoDialog();
            this.props.showAlertCancelSession(StM.MessagesKey.CancelSession);
            this.props.hideSpinner();
        }).catch((error: any) => {
            this.props.hideSpinner();
            if (error && error.response && error.response.data && error.response.data.exceptionMessage) {
                const message: string = error.response.data.exceptionMessage;
                if (message.indexOf('Unable to cancel session') > -1) { //'Unable to cancel session when at least one user is checked in'
                    this.props.showAlert(
                        StM.MessagesKey.RenderingError,
                        StM.MessageTypes.Error, 
                        'Something went wrong! Try again later'
                    );
                }
            }
        });
    }
}

const mapStateToProps = (state: StM.IGlobalStoreState) => {
    let confirmationDialog = state.dialogs.cancelConfirmation;
    let sessionInfoDialog = state.dialogs.sessionInfo;
    let title = StM.Messages[confirmationDialog.msgKey] || 'Success!';
    let note = confirmationDialog.message ? `<p>${confirmationDialog.message}</p>` : StM.Notes.getNote(confirmationDialog.msgKey, state.club);
    let goods = state.basket.goods;
    let session: StM.ISessionStoreState = null;
    let basketCopy = null;
    let originCopy = null;
    let agreeWithCancelationFee = true;

    if (sessionInfoDialog.isOpened) {
        let sessions = utils.getPageSessions(state);
        originCopy = state.dialogs.sessionInfo.session;
        const checkIsCourse = (basketItem: StM.ISessionStoreState, session: StM.ISessionStoreState): boolean => {
            const isCourse = !!session && !!session.series && session.series.isCourse && !!basketItem.series && basketItem.series.isCourse;
            return isCourse && basketItem.series.id === session.series.id;
        };

        if (sessionInfoDialog.from == StM.SessionInfoFromTypes.basket) {
            session = _.find(sessions, { basketId: + sessionInfoDialog.id }) || _.find(sessions, { id: + sessionInfoDialog.id });
            basketCopy = originCopy = session;
        }
        else {
            originCopy = state.dialogs.sessionInfo.session;
            basketCopy = goods.find(item => checkIsCourse(item, originCopy) || item.id === +sessionInfoDialog.id);
            if (basketCopy) originCopy = basketCopy;
        }

        if (session && session.basketId) {
            let fromBasket = _.find(goods, (item) => { return checkIsCourse(item, session) || item.basketId === session.basketId; });
            if (!fromBasket) session.basketId = null;
        }
        if (session && session.id) {
            let fromBasket = _.find(goods, (item) => { return checkIsCourse(item, session) || item.id === session.id; });
            if (fromBasket) session.basketId = fromBasket.basketId;
        }
    }

    session = basketCopy || originCopy; 
    
    return {
        isShown: confirmationDialog.isOpened,
        messageType: confirmationDialog.messageType,
        title: title,
        note: note,
        isOpenByService: confirmationDialog.isOpenByService,
        session,
        match: state.app.match,
        agreeWithCancelationFee: agreeWithCancelationFee,
        acceptButtonText: confirmationDialog.acceptButtonText,
        rejectButtonText: confirmationDialog.rejectButtonText,
        yesCallback: confirmationDialog.yesCallback
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        showSpinner: () => dispatch(ActM.AppActions.showSpinner()),
        hideSpinner: () => dispatch(ActM.AppActions.hideSpinner()),
        closeDialog: (isOpenByService?: boolean) => dispatch(ActM.DialogActions.close(StM.DialogNames.CancelConfirmation, null, isOpenByService)),
        updateCurrentPageSessions: (params: any) => dispatch(ActM.SessionActions.updateForCurrentPage(params)),
        closeSessionInfoDialog: () => dispatch(ActM.DialogActions.close(StM.DialogNames.SessionInfo)),
        showAlert: (msgKey: string, messageType: string = StM.MessageTypes.Error, message?: string) => dispatch(ActM.DialogActions.open(StM.DialogNames.Alert, { msgKey, messageType, message })),
        showAlertCancelSession: (msgKey: string) => dispatch(ActM.DialogActions.open(StM.DialogNames.Alert, { msgKey: StM.MessagesKey.CancelSession, messageType: StM.MessageTypes.Success })),
        cancelSession: (session: StM.ISessionStoreState, agreeWithCancelationFee: boolean) => dispatch(ActM.SessionInfoDialogActions.cancel(session, agreeWithCancelationFee)),
    };

}

export default connect(mapStateToProps, mapDispatchToProps)(CancelConfirmationMobile);
