import * as React from 'react';
import * as _ from 'lodash';
import moment from 'moment';

import * as StM from '../../../models/store';
import * as PolM from '../../../policies'; 
import BadgeMobile from './badges/badge.mobile';
import TimeRangeBlockMobile from './badges/availability/timeRangeWrapper.mobile';

interface ICourtBoardProps {
    club: StM.IClubStoreState;
    courtTimeBlocks: Array<StM.ICourtTimeBlockStoreState>;
    sessionType: string;
    notifications: Array<StM.INotificationStoreState>;
    user?: StM.IUserStoreState;
    basket: StM.IBasketStoreState;
    timeKey: string;
    start: moment.Duration;
    end: moment.Duration;
    date: moment.Moment;
    expandedSlotTimeKey: string;
    coaches: Array<StM.ICoachStoreState>;
    coachFeeTiers: Array<StM.ICoachFeeTierStoreState>;
    params: StM.IBookPageRouteParams;
    availableTimes: Array<StM.IAvailableTimeStoreState>;
    selectedAvailability: StM.ICoachAvailabilityStoreState;

    onCreateSessionClick: (courtId: number, timeKey: string) => any;
    openSessionClick: (from: string, id: number) => void;
    onExpandSlot: (timeKey: string) => void;
    setAvailabilityClick?: (timeItem: StM.ICoachAvailabilityTimeItem) => void;
}

interface ICourtBoardState {
}

export default class CourtBoardMobile extends React.Component<ICourtBoardProps, ICourtBoardState> {
    constructor(props: ICourtBoardProps) {
        super(props);
    }

    render() {
        const isAvailability = this.props.params.sessionType === StM.BookPageSessionType.Availability;
        const coachTimeBlocks = _.uniqBy(this.props.courtTimeBlocks.filter((timeBlock) => {
            const availabilityPolicy = new PolM.SessionAvailabilityPolicy(timeBlock.session, [], this.props.user);
            const hasSession = !!timeBlock.session;
            const isOwner = hasSession && availabilityPolicy.getIsOwner();
            const isTrainer = hasSession && availabilityPolicy.getIsTrainer();
            const isShared = hasSession && _.some(timeBlock.session.bookings, (booking) => {
                return booking.userId === this.props.user.id;
            });
            return isOwner || isShared || isTrainer;
        }), i => i.session.id);
        return (
            <div className="court-board-item-mobile mobile-row">
                {!isAvailability && <BadgeMobile courts={this.props.club.courts}
                    courtTimeBlocks={this.props.courtTimeBlocks}
                    user={this.props.user}
                    club={this.props.club}
                    basket={this.props.basket}
                    params={this.props.params}
                    sessionType={this.props.sessionType}
                    notifications={this.props.notifications}
                    expandedSlotTimeKey={this.props.expandedSlotTimeKey}
                    onCreateSessionClick={(courtId, timeKey) => this.props.onCreateSessionClick(courtId, timeKey)}
                    openSessionClick={this.props.openSessionClick}
                    timeKey={this.props.timeKey}
                    coaches={this.props.coaches}
                    coachFeeTiers={this.props.coachFeeTiers}
                    onExpandSlot={(timeKey) => this.props.onExpandSlot(timeKey)}
                />}
                {isAvailability && <TimeRangeBlockMobile
                    date={this.props.date}
                    user={this.props.user}
                    courtTimeBlocks={coachTimeBlocks}
                    start={this.props.start}
                    end={this.props.end}
                    availableTimes={this.props.availableTimes}
                    selectedAvailability={this.props.selectedAvailability}
                    club={this.props.club}
                    setAvailabilityClick={this.props.setAvailabilityClick}
                />}
            </div>
        )
    }
}
