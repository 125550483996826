import '../dialogs.mobile.scss';
import '../legalTextsDialog.mobile.scss';
import '../../legalTexts.scss';

import * as React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { ModalMobile } from '../../../components/modal.mobile';
import * as StM from '../../../models/store';
import * as ActM from '../../../actions';

export interface TermsAndConditionsDialogProps {
    isShow: boolean;

    closeDialog: () => void;
    getStatic: () => Promise<any>;
}

export interface TermsAndConditionsDialogState {
    title: string;
    content: string;
}

class CreditTermsDialogMobile extends React.Component<TermsAndConditionsDialogProps, TermsAndConditionsDialogState> {
    constructor(props: TermsAndConditionsDialogProps) {
        super(props);

        this.state = {
            title: 'Terms and Conditions',
            content: 'Please wait...',
        };
    }

    componentDidUpdate(prevProps: TermsAndConditionsDialogProps) {
        if (!prevProps.isShow && this.props.isShow) {
            this.init();
        }
    }

    public render() {
        if(!this.props.isShow) return null;
        return (
            <ModalMobile classes="waiver-modal" dialogName={StM.DialogNames.TermsAndConditions} customZIndex={9999}>
                <div className="modal-dialog-header">
                    <div className="title">{this.state.title}</div>
                </div>
                <div className="modal-dialog-body container">
                <article className="waiver-text-wrapper" dangerouslySetInnerHTML={this.state && { __html: this.state.content }}></article>
                    <div className="divider-line"></div>
                    <div className="btns-wrapper">
                        <button className="btn-mobile btn-black" onClick={e => this.onCloseClick(e)}>CLOSE</button>
                    </div>
                </div>
            </ModalMobile>
        );
    }

    private init() {
        this.props.getStatic().then((staticPages: Array<IStaticPageDto>) => {
            const page = staticPages.find(p => p.name === StM.LegalTexts.Terms);
            const content = page ? page.content : "Page is currently unavailable";
            const title = page ? page.title : "Not Found";
            this.setState({ content, title });
        });
    }

    private onCloseClick(e: any){
        if(e) {e.preventDefault(); e.stopPropagation();}
        this.props.closeDialog();
    }
}

const mapStateToProps = (state: StM.IGlobalStoreState, ownProps: any) => {
    return {
        isShow: state.dialogs.creditTerms.isOpened
    };
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        closeDialog: () => dispatch(ActM.DialogActions.close(StM.DialogNames.CreditTerms)),
        getStatic: () => dispatch(ActM.AppActions.getStatic())
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreditTermsDialogMobile);
