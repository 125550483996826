import * as React from 'react';
import { compose, Dispatch } from 'redux';
import { connect } from 'react-redux';

import * as StM from '../../../../models/store';
import * as ActM from '../../../../actions';
import * as SrvM from '../../../../services';
import { IInviteGroupMembersListItem } from '../../../../components/pages/user/groups';

export interface IInviteGroupMemberDialogProps extends IBaseInviteGroupMemberDialogState {
    isOpened: boolean;
    group: StM.IGroupStoreState;

    onCloseDialog: (e?: any) => void;
    onFieldChange: (name: string, value: string) => void;
    onFieldKeyDown: (e: any) => void;
    onSendInvite: (e: any) => void;
    onAddUser: (e: any) => void;
    onRemoveUser: (user: IPublicUserDto) => void;
}

interface IBaseInviteGroupMemberDialogProps {
    isOpened: boolean;
    group: StM.IGroupStoreState;

    closeDialog: () => void;
    searchMember: (model: IGroupMemberSearchDto) => Promise<IGroupMemberSearchDto>;
    updateGroup: (group: StM.IGroupStoreState) => Promise<StM.IGroupStoreState>;
    showAlert: (msgKey: string, messageType: string) => void;
    closeAlert: () => void;
}

interface IBaseInviteGroupMemberDialogState {
    searchModel: IGroupMemberSearchDto;
    addedUsers: IInviteGroupMembersListItem[];
    errors?: any;
    searchError?: string;
    isEmailNeeded: boolean;
}

const withBaseInviteGroupMemberDialog = (Component: React.ComponentType<IInviteGroupMemberDialogProps>) => {
    return class BaseInviteGroupMemberDialog extends React.Component<IBaseInviteGroupMemberDialogProps, IBaseInviteGroupMemberDialogState> {
        private validator = new SrvM.ValidationService();
        constructor(props: IBaseInviteGroupMemberDialogProps) {
            super(props);

            this.state = {
                addedUsers: [],
                searchModel: {
                    email: '',
                    userName: '',
                    match: null,
                    numberOfMatches: 0
                } as IGroupMemberSearchDto,
                errors: null,
                isEmailNeeded: false,
                searchError: null,
            }
        }

        componentDidUpdate(prevProps) {
            const isOpenedChanged = !prevProps.isOpened && this.props.isOpened;
            const isGroupChanged = this.props.isOpened && !!prevProps.group && !this.props.group;
            
            if (isGroupChanged) {
              this.reset();
              this.props.closeDialog();
            }
        
            if (isOpenedChanged) {
              this.reset();
            }
          }

        public render() {
            return (
                <Component 
                    {...this.state}
                    isOpened={this.props.isOpened}
                    group={this.props.group}
                    onCloseDialog={(e) => this.handleCloseDialog(e)}
                    onFieldChange={(name, value) => this.handleFieldChange(name, value)}
                    onFieldKeyDown={(e) => this.handleFieldKeyDown(e)}
                    onSendInvite={(e: any) => this.handleSendInvite(e)}
                    onAddUser={(e: any) => this.handleAddUser(e)}
                    onRemoveUser={(user: IPublicUserDto) => this.handleRemoveUser(user)}
                />
            );
        }

        private handleCloseDialog(e: any) {
            if(e) e.preventDefault();
            this.props.closeDialog();
        }

        private handleFieldChange(name: string, value: any) {
            let errors = !!this.state.errors && { ...this.state.errors };
            if (errors) delete errors[name];
            this.setState({
                ...this.state,
                searchModel: {
                    ...this.state.searchModel,
                    [name]: value
                },
                errors,
                searchError: null
            });
        };

        private handleFieldKeyDown(e: any) {
            if(e.keyCode === 13) { // handle Enter key
                this.handleAddUser(e);
            }
        }

        private validateAddedPlayers(addedUsers: IPublicUserDto[]): boolean {
            const errors = !addedUsers.length ? this.validator.setError('addedUsers', 'No players added to invite') : null;
            return this.handleValidationErrors(errors);
        }

        private validateSearchModel(model: IGroupMemberSearchDto) {
            const errors = !model.userName && !model.email ? this.validator.setError('userName', 'Please input player name or email') : null;
            return this.handleValidationErrors(errors);
        }

        private handleValidationErrors(errors: any) {
            if(this.validator.hasErrors(errors)) {
                this.setState({ ...this.state, errors });
                return false;
            }
            return true;
        }

        private handleSendInvite(e: any) {
            if(e) e.preventDefault();
            if (!this.validateAddedPlayers(this.state.addedUsers)) return false;

            const group = {
                ...this.props.group,
                members: [
                    ...this.props.group.members,
                    ...this.state.addedUsers.map((user: StM.IPublicUserStoreState) => {
                        return {
                            invitationStatus: StM.InvitationStatus.Pending,
                            type: StM.GroupMemberType.Regular,
                            user,
                        } as StM.IGroupMemberStoreState
                    })
                ]
            } as StM.IGroupStoreState;

            this.props.updateGroup(group).then(() => {
                this.props.showAlert(StM.MessagesKey.SendInvitation, StM.MessageTypes.Success);
                this.props.closeDialog();
            }).catch((error) => {
                this.props.closeAlert();
                this.setState({
                    ...this.state, 
                    errors: this.validator.setServerError(error.data, this.state.errors)
                });
            });
        }

        private handleAddUser(e: any) {
            if(e) e.preventDefault();
            if (!this.validateSearchModel(this.state.searchModel)) return false;

            this.props.searchMember(this.state.searchModel)
                .then((response) => {
                    const result = response.match;
                    const addedUsers = this.state.addedUsers;
                    const searchModel = {...this.state.searchModel};
                    let isEmailNeeded = this.state.isEmailNeeded || (response.numberOfMatches > 1 && !searchModel.email);
                    let searchError = null;
                    if(response.numberOfMatches === 1) {
                        const foundUser = result;
                        const isUserAdded = addedUsers.some(user => user.id === foundUser.id);
                        const isUserMember = this.props.group.members.some(member => member.user && member.user.id === foundUser.id);
                        const wasWildcardEmailSearch = searchModel.userName.indexOf("@") >= 0;
                        if(!isUserAdded && !isUserMember) {
                            addedUsers.push({
                                ...foundUser,
                                email: wasWildcardEmailSearch ? searchModel.userName : searchModel.email 
                            } as IInviteGroupMembersListItem);
                            searchModel.email = '';
                            searchModel.match = null;
                            searchModel.userName = '';
                            isEmailNeeded = false;
                        } else if(isUserAdded) {
                            searchError = `Player ${foundUser.displayName} is already added`;
                        } else if(isUserMember) {
                            searchError = `Player ${foundUser.displayName} is already a member of the group`;
                        }
                    } else {
                        searchError = 'We cannot find the player, please try writing their email address';
                    }
                    this.setState({
                        ...this.state,
                        searchModel,
                        addedUsers,
                        isEmailNeeded,
                        searchError,
                        errors: null
                    })
                }).catch((error) => {
                    this.props.closeAlert();
                    this.setState({
                        ...this.state, 
                        errors: this.validator.setServerError(error.data, this.state.errors)
                    });
                });
        }

        private handleRemoveUser(user: IPublicUserDto) {
            this.setState({ 
                ...this.state,
                addedUsers: this.state.addedUsers.filter((item) => item.id !== user.id)
            });
        }

        private reset() {
            this.setState({
                ...this.state,
                errors: null,
                addedUsers: [],
                isEmailNeeded: false,
                searchModel: {
                    email: '',
                    userName: '',
                    match: null,
                    numberOfMatches: 0
                },
                searchError: null
            });
        }
    }
}

const mapStateToProps = (state: StM.IGlobalStoreState, ownProps: any) => ({
    isOpened: state.dialogs.inviteGroupMember.isOpened,
    group: state.user && state.user.group && state.user.group.id
        ? state.user.group 
        : state.groupAccounts.find((group) => group.id === +state.dialogs.inviteGroupMember.groupId),
});

const mapDispatchToProps = (dispatch: any) => ({
    closeDialog: () => dispatch(ActM.DialogActions.close(StM.DialogNames.InviteGroupMember)),
    searchMember: (model: IGroupMemberSearchDto) => dispatch(ActM.GroupAccountActions.searchMemberToInvite(model)),
    updateGroup: (group: StM.IGroupStoreState) => dispatch(ActM.GroupAccountActions.update(group)),
    showAlert: (msgKey: string, messageType: string) => dispatch(ActM.DialogActions.open(StM.DialogNames.Alert, { msgKey, messageType })),
    closeAlert: () => dispatch(ActM.DialogActions.close(StM.DialogNames.Alert)),
});

export const withInviteGroupMemberDialog = compose(connect(mapStateToProps, mapDispatchToProps), withBaseInviteGroupMemberDialog);