import './dialogs.mobile.scss';
import './alertDialog.mobile.scss';

import * as _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import * as StM from '../../models/store';
import * as ActM from '../../actions';
import * as SrvM   from '../../services';
import {ModalMobile} from '../../components';

interface ISessionCreateSuccessDialogProps {
    isAuthorized: boolean;
    isShown: boolean;
    isExist: boolean;

    closeDialog: () => void;
    openBookDialog: () => void;
    openAuthDialog: (url:string) => void;
}

interface ISessionCreateSuccessDialogState {}

class SessionCreateSuccessDialogMobile extends React.Component<ISessionCreateSuccessDialogProps, ISessionCreateSuccessDialogState> {
    constructor(props: ISessionCreateSuccessDialogProps) {
        super(props);
    }
    
    render() {
        if(!this.props.isShown) return null;

        const message = `Session has been ${this.props.isExist ? '' : 'created and '}added to your cart`;
        return (
            <ModalMobile classes="alert-dialog-mobile success session-create" 
                closeHandler={this.props.closeDialog} 
                dialogName={StM.DialogNames.SessionCreateSuccess}
                showHeader={false}
            >
                <div className="modal-dialog-header">
                    <div className="title success create-session-title">{message}</div>
                </div>
                <div className="modal-dialog-body">
                    <p className="note-mobile">Checkout to confirm your session or Continue to add more.</p>
                </div>
                <div className="modal-dialog-footer">
                    <div className="btns-wrapper">
                        <button className="btn-mobile btn-checkout btn-black" onClick={(e) => this.checkoutClick(e)}>Checkout</button>
                        <button className="btn-mobile btn-continue btn-black" onClick={(e) => this.props.closeDialog()}>Continue</button>
                    </div>
                </div>
            </ModalMobile>
        );
    }

    private checkoutClick(e:any) {
        if(e) { e.preventDefault(); e.stopPropagation(); }
       
        if (!this.props.isAuthorized) {
            let routeSrv = new SrvM.RouteDialogService();
            const hash = routeSrv.getDialogHash(StM.DialogNames.Book, {});
            const url = encodeURIComponent(hash);
            this.props.closeDialog();
            this.props.openAuthDialog(url);
        } else {
            this.props.closeDialog();
            this.props.openBookDialog();
        }
    }
}

const mapStateToProps = (state: StM.IGlobalStoreState, ownProps: any) => {
    const dialog = state.dialogs.sessionCreateSuccess;
    return {
        isShown: dialog.isOpened,
        isAuthorized: state.app.isAuthorized,
        isExist: dialog.isExist
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        closeDialog: () => dispatch(ActM.DialogActions.close(StM.DialogNames.SessionCreateSuccess)),
        openBookDialog: () => (dispatch(ActM.DialogActions.open(StM.DialogNames.Book))),
        openAuthDialog: (url:string) => dispatch(ActM.DialogActions.open(StM.DialogNames.Auth, { tab: StM.AuthDialogTabs.SignIn, returnUrl: url})),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SessionCreateSuccessDialogMobile);
