import './baseBadge.scss';
import './priceBadge.scss';

import * as React from 'react';
import ClassNames from 'classnames';
import moment from 'moment';
import { StM, PolM, SrvM } from '../../../../modules';
import { IBaseBadgeProps, DurationClasses, CommonBadge } from './baseBadge';

interface IPriceBadgeBadgeProps extends IBaseBadgeProps {
    court: StM.ICourtStoreState;

    onCreateSessionClick: (courtId: number, timeKey: string) => any;
    onMouseEnter?: (time: StM.CourtTimeBlockStoreState, top: number, right: number) => void;
    onMouseLeave?: () => void;
}

export class PriceBadge extends React.Component<IPriceBadgeBadgeProps> {
    private utils = new SrvM.Utils();
    private _DOM: any = null;

    constructor(props: IPriceBadgeBadgeProps) {
        super(props);
    }

    public render() {
        const typeClass = this.getSessionTypeClass(this.props.filter.sessionType);
        const durationClass = DurationClasses.getDurationClass(this.props.time.duration);
        const classes = ClassNames('table-column-item-wrapper price-cell', durationClass, typeClass, {
            'touch-price-cell': this.utils.getIsTouchDetected(),
        });
        const price = this.utils.formatStringPrice(this.props.time.price.toString());
        const styles: any = {
            height: CommonBadge.getBadgeHeightByDuration(this.props.time.duration),
        };
        const courtId = this.props.court.id;
        const sessionTime = moment.utc(this.props.time.start.asMilliseconds()).format('HH-mm');

        return (
            <div
                className={classes}
                ref={(component) => (this._DOM = component)}
                style={styles}
                onClick={(e) => this.onClick(e)}
                onMouseEnter={(e) => this.onMouseEnter(e)}
                onMouseLeave={(e) => this.onMouseLeave(e)}
                id={`court-${courtId}-time-${sessionTime}`}
            >
                <div
                    className='table-column-item'
                    onClick={(e) => this.onClick(e)}
                    onMouseEnter={(e) => this.onMouseEnter(e)}
                    onMouseLeave={(e) => this.onMouseLeave(e)}
                >
                    {!this.props.time.credits || PolM.PackagePolicy.HideCreditsOnCalendar ? (
                        <span>${price}</span>
                    ) : (
                        <span className='price-credits-price-cell'>{this.props.time.credits}</span>
                    )}
                </div>
            </div>
        );
    }

    // handlers

    private onClick(e: any) {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        this.props.onCreateSessionClick(this.props.court.id, this.props.time.key);
    }

    private onMouseEnter(e: any) {
        if (this.props.onMouseEnter) {
            if (!this._DOM) return false;
            let top = this._DOM.getBoundingClientRect().top;
            let right = this._DOM.getBoundingClientRect().right;
            this.props.onMouseEnter(this.props.time, top, right);
        }
    }

    private onMouseLeave(e: any) {
        if (this.props.onMouseLeave) {
            this.props.onMouseLeave();
        }
    }

    // helpers

    private getSessionTypeClass(type: string) {
        let typeClass: string;
        switch (type) {
            case StM.BookPageSessionType.Play:
            case StM.BookPageSessionType.Group: {
                typeClass = 'play-type';
                break;
            }
            case StM.BookPageSessionType.Clinic: {
                typeClass = 'clinic-type';
                break;
            }
            case StM.BookPageSessionType.Lesson: {
                typeClass = 'lesson-type';
                break;
            }
            case StM.BookPageSessionType.Custom: {
                typeClass = 'custom-type';
                break;
            }
            default: {
                typeClass = '';
            }
        }
        return typeClass;
    }
}
