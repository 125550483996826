import './baseBadge.mobile.scss';
import moment from 'moment';
import * as React from 'react';
import ClassNames from 'classnames';
import * as StM from '../../../../models/store';
import * as PolM from '../../../../policies';

export interface IBaseBadgeProps {
    user?: StM.IUserStoreState;
    courts: Array<StM.ICourtStoreState>;
    sessionType: string;
    courtTimeBlocks: Array<StM.ICourtTimeBlockStoreState>;
    expandedSlotTimeKey: string;
    timeKey: string;
    coaches: Array<StM.ICoachStoreState>;
    coachFeeTiers: Array<StM.ICoachFeeTierStoreState>;
    params: StM.IBookPageRouteParams;
}

export interface IBaseBadge {
}

export interface IBaseBadgeState { }

export class DurationClasses {
    static BLOCK_MIN_15 = 'min-15-cell';
    static BLOCK_MIN_30 = 'min-30-cell';
    static BLOCK_MIN_45 = 'min-45-cell';
    static BLOCK_MIN_60 = 'min-30-2x-cell';
    static BLOCK_MIN_90 = 'min-45-2x-cell';
    static BLOCK_MIN_120 = 'min-60-2x-cell';

    static SMALL = 'badge-sm';
    static LARGE = 'badge-lg';
    static EXTRA_LARGE = 'badge-xl';

    static getDurationClass(duration: number): string {
        let dict: any = DurationClasses;
        return dict['BLOCK_MIN_{0}'.format(duration.toString())];
    }
    static getDurationClassFromSession(session: StM.ISessionStoreState) {
        let minutes = moment.duration(session.endDateTime.diff(session.startDateTime)).asMinutes();
        return this.getDurationClass(minutes);
    }

    static getBadgeSizeByDuration(duration: number): string {
        if (duration < 45) return this.SMALL;
        if (duration > 60 && duration <= 120) return this.LARGE;
        if (duration > 120) return this.EXTRA_LARGE;
        return null;
    }
}

export class SkillClasses {
    static Beginner = 'lvl-beginer';
    static Intermediate = 'lvl-intermediate';
    static Advanced = 'lvl-advanced';
    static getSkillClasses(level: string) {
        let dict: any = SkillClasses;
        switch (level) {
            case StM.UserSkill.Beginner: {
                return dict.Beginner;
            }
            case StM.UserSkill.Intermediate: {
                return dict.Intermediate;
            }
            case StM.UserSkill.Advanced: {
                return dict.Advanced;
            }
            default: {
                return '';
            }
        }
    }
}

export class CommonBadgeMobile {

    public static getRenderSlots(maxUserCount: number, playersCount: number) {
        if (maxUserCount > 1) {
            let arr: Array<any> = [];

            for (let i = 0; i < maxUserCount; i++) {
                let classes = ClassNames("slots-item",
                    { "one-slot": i == 0 },
                    { "two-slot": i == 1 },
                    { "three-slot": i == 2 },
                    { "four-slot": i == 3 },
                    { "five-slot": i == 4 },
                    { "much-slot": i > 4 },
                    { vacancy: i < playersCount })
                arr.push(<span className={classes} key={i}></span>)
            }

            let classesSlots = ClassNames("slots",
                { "one-player": maxUserCount == 1 },
                { "two-players": maxUserCount == 2 },
                { "three-players": maxUserCount == 3 },
                { "four-players": maxUserCount == 4 },
                { "more-than-5": maxUserCount > 4 },
            );
            return (
                <div className={classesSlots}>
                    {arr}
                    <span className="slots-item plus-slot"></span>
                </div>
            );
        }
        return null;
    }

    public static getMinBadgePrice(courtTimeBlocks: Array<StM.ICourtTimeBlockStoreState>) {
        return Math.min(...courtTimeBlocks.filter(block => { return block.isAvailableTime && block.isFilter && !block.session }).map(block => { return block.price }));
    }

    public static getMinBadgeCredits(courtTimeBlocks: Array<StM.ICourtTimeBlockStoreState>) {
        return Math.min(...courtTimeBlocks.filter(block => { return block.isAvailableTime && block.isFilter && !block.session && !!block.credits }).map(block => { return block.credits }));
    }

    public static getIsHasCredits(courtTimeBlocks: Array<StM.ICourtTimeBlockStoreState>) {
        return courtTimeBlocks.every((block) => { return !!block.credits && !PolM.PackagePolicy.HideCreditsOnCalendar });
    }
}