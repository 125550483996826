import '../dialogs.scss';
import './billingInfoDialog.scss';

import * as React from 'react';
import * as _ from 'lodash';

import { StM } from '../../../modules';
import * as BD from './baseBillingInfoDialog';
import { InputSearch, Modal, ModalHeader, ModalBody, ModalSection, ModalFooter, Field } from '../../../components';

interface IBillingInfoDialogProps extends BD.IBillingInfoDialogProps {}

class BillingInfoDialog extends React.Component<IBillingInfoDialogProps> {
    public render() {
        if (!this.props.isShown) return null;
        const { months, years, countries, paymentProfile, errors } = this.props;
        const month = months.find((m) => m.value === paymentProfile.creditCard.expirationMonth);
        const year = years.find((y) => y.value === paymentProfile.creditCard.expirationYear);
        const country = countries.find((c) => c.value === paymentProfile.billingAddress.country);

        return (
            <Modal classes='billing-info-modal' closeHandler={(e) => this.props.onCloseDialog(e)} dialogName={StM.DialogNames.BillingInfo}>
                <div className='modal-dialog dialog'>
                    <ModalHeader title='Billing Information' onCloseDialog={(e) => this.props.onCloseDialog(e)} />
                    <ModalBody>
                        <ModalSection>
                            <BD.BillingInfoDescription />
                            <div className='billing-card-info-wrapper'>
                                <div className='inputs-wrapper'>
                                    <Field
                                        label='Card Number'
                                        name='creditCard.number'
                                        type='text'
                                        value={paymentProfile.creditCard.number}
                                        className={BD.getCardTypeClass(paymentProfile.creditCard.type)}
                                        error={errors && errors.creditCard && errors.creditCard.number}
                                        onFieldChange={(name, value) => this.props.onFieldChange(name, value)}
                                    />
                                    <Field
                                        name='creditCard.fullName'
                                        type='text'
                                        label='Name (as it appears on card)'
                                        value={paymentProfile.creditCard.fullName}
                                        error={errors && errors.creditCard && errors.creditCard.fullName}
                                        onFieldChange={(name, value) => this.props.onFieldChange(name, value)}
                                    />
                                    <div className='inputs-group-inline'>
                                        <div className='input-item expiration-date-wrapper'>
                                            <label>Expiration Date</label>
                                            <div className='inputs-group-inline'>
                                                <Field name='creditCard.expirationMonth' error={errors && errors.creditCard && errors.creditCard.expirationMonth}>
                                                    <InputSearch
                                                        selected={month ? month.key : null}
                                                        array={months}
                                                        onSelectChange={(item) => this.props.onFieldChange('creditCard.expirationMonth', item.value)}
                                                        readOnly
                                                        placeholder='Month'
                                                    />
                                                </Field>
                                                <Field name='creditCard.expirationYear' error={errors && errors.creditCard && errors.creditCard.expirationYear}>
                                                    <InputSearch
                                                        selected={year ? year.key : null}
                                                        array={years}
                                                        readOnly
                                                        onSelectChange={(item) => this.props.onFieldChange('creditCard.expirationYear', item.value)}
                                                        placeholder='Year'
                                                    />
                                                </Field>
                                            </div>
                                            {this.renderExpirationDateErrors(errors)}
                                        </div>
                                        <Field
                                            name='creditCard.securityCode'
                                            type='text'
                                            label='CVV'
                                            value={paymentProfile.creditCard.securityCode}
                                            className='cvv-input'
                                            error={errors && errors.creditCard && errors.creditCard.securityCode}
                                            onFieldChange={(name, value) => this.props.onFieldChange(name, value)}
                                        />
                                    </div>
                                </div>
                                <div className='img-sertificate-wrapper'>
                                    <img src='/content/img/authorize.png' alt='Verified Merchant Authorize.net.' />
                                </div>
                            </div>
                            <div className='divider-line' />
                        </ModalSection>
                        <ModalSection>
                            <div className='inputs-wrapper'>
                                <Field
                                    name='billingAddress.street'
                                    type='text'
                                    label='Billing Address'
                                    error={errors && errors.billingAddress && errors.billingAddress.street}
                                    value={paymentProfile.billingAddress.street}
                                    onFieldChange={(name, value) => this.props.onFieldChange(name, value)}
                                />
                                <div className='inputs-group-inline'>
                                    <Field
                                        name='billingAddress.city'
                                        type='text'
                                        label='City'
                                        error={errors && errors.billingAddress && errors.billingAddress.city}
                                        value={paymentProfile.billingAddress.city}
                                        onFieldChange={(name, value) => this.props.onFieldChange(name, value)}
                                    />
                                    <Field
                                        name='billingAddress.state'
                                        type='text'
                                        label='State'
                                        value={paymentProfile.billingAddress.state}
                                        error={errors && errors.billingAddress && errors.billingAddress.state}
                                        onFieldChange={(name, value) => this.props.onFieldChange(name, value)}
                                    />
                                </div>
                                <div className='inputs-group-inline'>
                                    <Field 
                                        name='billingAddress.country' 
                                        label='Country' 
                                        error={errors && errors.billingAddress && errors.billingAddress.country}
                                    >
                                        <InputSearch
                                            array={countries}
                                            selected={country ? country.key : null}
                                            onSelectChange={(item) => this.props.onFieldChange('billingAddress.country', !!item && item.value)}
                                            placeholder='Select Country'
                                        />
                                    </Field>
                                    <Field
                                        name='billingAddress.zip'
                                        type='text'
                                        label='Postal / Zip code'
                                        error={errors && errors.billingAddress && errors.billingAddress.zip}
                                        value={paymentProfile.billingAddress.zip}
                                        onFieldChange={(name, value) => this.props.onFieldChange(name, value)}
                                    />
                                </div>
                            </div>
                        </ModalSection>
                    </ModalBody>
                    <ModalFooter className='btns-wrapper' errors={errors}>
                        <button type='button' className='btn btn-red' onClick={(e) => this.props.onSave(e)}>
                            SAVE
                        </button>
                    </ModalFooter>
                </div>
            </Modal>
        );
    }

    private renderExpirationDateErrors(errors: any) {
        const cardError = errors && errors.creditCard ? errors.creditCard.expirationMonth || errors.creditCard.expirationYear : null;
        return !!cardError && <div className='validation-error'>{cardError}</div>;
    }
}

export default BD.withBillingInfoDialog(BillingInfoDialog);
