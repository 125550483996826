import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as StM from '../../../../models/store';
import * as PolM from '../../../../policies';
import { IBaseBadgeProps, DurationClasses } from './baseBadge';
import { PrivateBadge } from './privateBadge';
import { PriceBadge } from './priceBadge';
import { NABadge } from './naBadge';
import { PlayBadge } from './playBadge';
import { LessonBadge } from './lessonBadge';
import { ClinicBadge } from './clinicBadge';
import { CustomBadge } from './customBadge';
import { CourtShiftBadge } from './courtShiftBadge';

interface IBadgeBadgeProps extends IBaseBadgeProps {
    court: StM.ICourtStoreState;
    user?: StM.IUserStoreState;

    onCreateSessionClick: (courtId: number, timeKey: string) => any;
    onSessionClick: (from: string, id: number) => void;
    onNonFilterSessionClick: (sessionId: number) => void;
    onBageMouseEnter?: () => void;
    onBageMouseLeave?: () => void;

}

interface IBadgeState { }

export class Badge extends React.Component<IBadgeBadgeProps, IBadgeState> {
    constructor(props: IBadgeBadgeProps) {
        super(props);
    }

    render() {
        const badge = this.getBadge(this.props.time, this.props.filter);
        return badge;
    };

    private getBadge(time: StM.ICourtTimeBlockStoreState, filter: StM.IBookPageRouteParams): any {
        if (!!time.session) {
            const isPrivate = this.getIsPrivate(time, filter.sessionType);
            if (isPrivate) {
                return (<PrivateBadge user={this.props.user} time={time} filter={filter}
                                      onNonFilterSessionClick={(sessionId: number) => this.props.onNonFilterSessionClick(sessionId)}/>);
            }

            switch (time.session.type) {
                case StM.SessionType.Play: {
                    return (<PlayBadge user={this.props.user} time={time} filter={filter}
                                       onBadgeClick={(from: string, id: number) => this.props.onSessionClick(from, id)}/>);
                }
                case StM.SessionType.Private: {
                    return (<LessonBadge user={this.props.user} time={time} filter={filter}
                                         onBadgeClick={(from: string, id: number) => this.props.onSessionClick(from, id)}/>);
                }
                case StM.SessionType.Clinic: {
                    return (<ClinicBadge user={this.props.user} time={time} filter={filter}
                                         onBadgeClick={(from: string, id: number) => this.props.onSessionClick(from, id)}/>);
                }
                case StM.SessionType.Custom: {
                    return (<CustomBadge user={this.props.user} time={time} filter={filter}
                                         onBadgeClick={(from: string, id: number) => this.props.onSessionClick(from, id)}/>);
                }
                default: {
                    return (<PrivateBadge user={this.props.user} time={time} filter={filter}
                                          onNonFilterSessionClick={(sessionId: number) => this.props.onNonFilterSessionClick(sessionId)}/>);
                }
            }

        }

        if (time.isNotAvailable) {
            return (<NABadge time={time} filter={filter} />);
        }
        else if (time.isOffset) {
            return (<CourtShiftBadge offset={time.duration} />);
        } else if (time.isAvailableTime && time.isFilter) {
            return (<PriceBadge time={time}
                court={this.props.court}
                filter={filter}
                onMouseEnter={this.props.onBageMouseEnter}
                onMouseLeave={this.props.onBageMouseLeave}
                onCreateSessionClick={this.props.onCreateSessionClick} />);
        } else {
            return (
                <NABadge 
                    time={time} 
                    filter={filter}
                    court={this.props.court}/>
            );
        }
    }

    private getIsPrivate(time: StM.ICourtTimeBlockStoreState, sessionType: string) {
        const sessionAvailability = new PolM.SessionAvailabilityPolicy(time.session, [], this.props.user);

        const isGroupMemberSession = sessionAvailability.getIsGroupMemberSession(false);
        if(isGroupMemberSession && time.isFilter)
            return false;

        const isMemberTierMatched = sessionAvailability.isMemberTierMatched();
        if (!isMemberTierMatched)
            return true;

        let isPrivate = true;
        const isGroupType = sessionType === StM.BookPageSessionType.Group;
        const isTrainer = sessionAvailability.getIsTrainer();
        const isBought = (!isGroupType || time.isFilter) && time.isBought;

        if (time.isFutureTime) {
            isPrivate = !(
                isBought
                || isTrainer
                || time.isSessionFromBasket
                || (time.isAvailableTime && time.isAvailableSession && time.isFilter)
            );
        } else {
            isPrivate = !isBought
                && !isTrainer
                && !(time.isAvailableSession && time.isFilter);
        }
        return isPrivate;
    }
}
