import '../dialogs.scss';
import './editUserDialog.scss';
import 'react-international-phone/style.css';
import * as _ from 'lodash';
import * as React from 'react';
import { PhoneInput } from 'react-international-phone';
import { connect } from 'react-redux';

import * as StM from '../../../models/store';
import * as ActM from '../../../actions';
import { Modal, InputSearch, Field, FormError } from '../../../components';
import DatePicker from '../../../components/datepicker';
import * as SrvM from '../../../services';
import Strings from "../../../models/store/strings";

interface IEditUserDialogProps {
    isShown: boolean;
    isAuth: boolean;
    user: StM.IUserStoreState;
    club: StM.IClubStoreState;

    showSpinner: () => any;
    hideSpinner: () => any;
    closeDialog: () => void;
    save: (profile: StM.IUserManagableStoreState) => Promise<any>;
    closeAlert: () => void;
}

interface IEditUserDialogState {
    profile?: StM.IUserManagableStoreState;
    errors: any;
}

class EditUser extends React.Component<IEditUserDialogProps, IEditUserDialogState> {
    private validator = new SrvM.ValidationService();
    private formValidator = new SrvM.FormValidationService();
    private utils = new SrvM.Utils();
    private authSrv = new SrvM.AuthenticationService();

    constructor(props: IEditUserDialogProps) {
        super(props);
        this.state = {
            profile: this.getProfile(this.props.user)
            , errors: null
        };
    }

    private getProfile(user: StM.IUserStoreState) {
        let profile = new StM.UserManagableStoreState(user);
        (profile && !profile.skill) && (profile.skill = StM.UserSkill.Beginner);
        if(user && !user.dateOfBirth) profile.dateOfBirth = null;
        return profile;
    }

    private sex = [
        { key: 1, value: StM.Gender.Male },
        { key: 2, value: StM.Gender.Female }
    ];

    private skills = [
        { key: 1, value: StM.UserSkill.Beginner, title: StM.DisplayUserSkill.Beginner },
        { key: 2, value: StM.UserSkill.Intermediate, title: StM.DisplayUserSkill.Intermediate },
        { key: 3, value: StM.UserSkill.Advanced, title: StM.DisplayUserSkill.Advanced }
    ];

    componentDidUpdate(prevProps: IEditUserDialogProps) {
        const isShowChanged = !prevProps.isShown && this.props.isShown;
        const isAuthChanged = this.props.isShown && prevProps.isAuth && !this.props.isAuth;

        if (isAuthChanged || (this.props.user && this.authSrv.isInRole(StM.Roles.GroupAccount, this.props.user))) {
            this.props.closeDialog();
            return;
        }

        if (isShowChanged) {
            this.setState({ profile: this.getProfile(this.props.user) });
        } else if (prevProps.isShown && !this.props.isShown) {
            this.setState({ errors: null });
        }
    }

    public render() {
        if (this.props.isShown) {
            let profile = this.state.profile;
            const ownerPrefix = this.authSrv.getOwnerPrefix(this.props.user);
            let sex: any = _.find(this.sex, { value: profile.gender });
            let skillString = this.utils.getSkill(profile.skill);

            let skill: any = _.find(this.skills, { value: profile.skill });
            if (sex) sex = sex.key;
            else sex = 0;
            if (skill) skill = skill.key;
            else skill = this.skills[0].key;

            return (
                <Modal classes="profile-edit-modal" closeHandler={this.props.closeDialog} dialogName={StM.DialogNames.UserEdit}>
                    <div className="modal-dialog dialog">
                        <div className="modal-dialog-header">
                            <span className="close-dialog" onClick={(e) => this.closeClick(e)}></span>
                            <div className="title">{ownerPrefix} Profile</div>
                        </div>
                        <div className="modal-dialog-body profile-edit">
                            <div className="modal-dialog-body-content ">
                                <div className="form-wrapper">
                                    <div className="inputs-wrapper">
                                        <Field name="firstName"
                                            type="text"
                                            label="First Name"
                                            error={this.state.errors && this.state.errors.firstName}
                                            maxLength={32}
                                            value={profile.firstName}
                                            onFieldChange={(name, value) => this.handleFieldChange(name, value)}
                                        />
                                        <Field name="lastName"
                                            type="text"
                                            label="Last Name"
                                            error={this.state.errors && this.state.errors.lastName}
                                            maxLength={32}
                                            value={profile.lastName}
                                            onFieldChange={(name, value) => this.handleFieldChange(name, value)}
                                        />
                                        <Field name="dateOfBirth" label="Date of Birth" error={this.state.errors && this.state.errors.dateOfBirth}>
                                            <DatePicker classes="input-item date-of-birth"
                                                date={profile.dateOfBirth}
                                                disabled={!!this.props.user.dateOfBirth && this.utils.checkIsBirthdayUnderage(this.props.user.dateOfBirth, this.props.club)}
                                                onChange={(date) => this.handleFieldChange('dateOfBirth', this.utils.toDateOfBirth(date))}
                                            />
                                        </Field>
                                        <Field name="email"
                                            type="email"
                                            label="Email"
                                            error={this.state.errors && this.state.errors.email}
                                            maxLength={128}
                                            autoComplete="off"
                                            value={profile.email}
                                            onFieldChange={(name, value) => this.handleFieldChange(name, value)}
                                        />
                                        <Field name="phone" label="Phone" error={this.state.errors && this.state.errors.phone}>
                                            <PhoneInput
                                                value={profile.phone}
                                                preferredCountries={['us']}
                                                defaultCountry="us"
                                                onChange={(value) => { this.handleFieldChange('phone', value) }}
                                                placeholder="+1 (111) 111 1111"
                                            />
                                        </Field>
                                        <Field name="gender" label="Gender" error={this.state.errors && this.state.errors.gender}>
                                            <InputSearch
                                                array={this.sex}
                                                placeholder=" "
                                                classes="sex-input-wrapper"
                                                selected={sex}
                                                readOnly
                                                onSelectChange={(item) => this.handleFieldChange('gender', item.value)}
                                            />
                                        </Field>
                                        <Field name="skill" label="Skill" error={this.state.errors && this.state.errors.skill}>
                                            <InputSearch
                                                array={this.skills}
                                                placeholder=" "
                                                classes="skill-input-wrapper"
                                                selected={skill}
                                                readOnly
                                                onSelectChange={(item) => this.handleFieldChange('skill', item.value)}
                                            />
                                        </Field>
                                        {this.renderEmergencyContactForm()}
                                        <div className="input-item checkbox-available">
                                            <div className="checkbox-wrapper">
                                                <label className="available-invite">
                                                    <input type="checkbox" hidden checked={profile.isAvailableForInvitation} onChange={(e: any) => this.handleFieldChange('isAvailableForInvitation', e.target.checked)} />
                                                    <span>{Strings.AvailableForInvitationTitle}</span>
                                                </label>
                                                <div className="news-description">{Strings.AvailableForInvitationExplanation}</div>
                                            </div>
                                        </div>
                                        <div className="input-item squash-news-item">
                                            <label className="squash-news">
                                                <input type="checkbox" hidden checked={profile.newsletterSubscribe} onChange={(e: any) => this.handleFieldChange('newsletterSubscribe', e.target.checked)} />
                                                <span>{Strings.getReceiveNewsTitle(this.props.club)}</span>
                                            </label>
                                        </div>
                                    </div>
                                    <FormError errors={this.state.errors} />
                                </div>
                            </div>
                            <div className="divider-line"></div>
                            <div className="btns-wrapper modal-dialog-body-content">
                                <button className="btn-next btn-red" onClick={(e) => this.handleOnSaveClick(e)} >Save</button>
                            </div>
                        </div>
                    </div>
                </Modal>
            );
        } else {
            return null;
        }
    }

    private renderEmergencyContactForm() {
        const name = this.state.profile.emergencyContactFullName;
        const phone = this.state.profile.emergencyContactPhoneNumber;
        const relationship = this.state.profile.emergencyContactRelationship;

        return (
            <div>
                <Field name="emergencyContactFullName"
                    label="Emergency Contact Name"
                    type="text"
                    maxLength={128}
                    value={name}
                    error={this.state.errors && this.state.errors.emergencyContactFullName}
                    onFieldChange={(name, value) => this.handleFieldChange(name, value)}
                />
                <Field name="emergencyContactPhoneNumber" label="Emergency Contact Phone" error={this.state.errors && this.state.errors.emergencyContactPhoneNumber}>
                    <PhoneInput
                        value={phone}
                        preferredCountries={['us']}
                        defaultCountry="us"
                        onChange={(value) => { this.handleFieldChange('emergencyContactPhoneNumber', value) }}
                        placeholder="+1 (111) 111 1111"
                    />
                </Field>
                <Field name="emergencyContactRelationship"
                    label="Emergency Contact Relationship"
                    type="text"
                    maxLength={128}
                    value={relationship}
                    error={this.state.errors && this.state.errors.emergencyContactRelationship}
                    onFieldChange={(name, value) => this.handleFieldChange(name, value)}
                />
            </div>
        );
    }

    private closeClick(e: any) {
        if (e) { e.stopPropagation(); }
        this.props.closeDialog();
    }

    private handleFieldChange(name: string, value: any) {
        let errors = !!this.state.errors && { ...this.state.errors };
        if (errors) delete errors[name];
        this.setState({
            profile: {
                ...this.state.profile,
                [name]: value
            },
            errors
        });
    }

    private handleOnSaveClick(e: any) {
        if (e) { e.stopPropagation(); }

        const validateBirthdate = !this.props.user.dateOfBirth || !this.utils.checkIsBirthdayUnderage(this.props.user.dateOfBirth, this.props.club);
        const errors = this.formValidator.validateEditUserProfileForm(this.state.profile, this.props.club, validateBirthdate);
        if (this.validator.hasErrors(errors)) {
            this.setState({ ...this.state, errors });
            return false;
        }

        this.props.showSpinner();
        if (this.state.errors) delete this.state.errors.server;

        var savedProfile = {...this.state.profile};

        if(this.props.user.dateOfBirth && this.utils.checkIsBirthdayUnderage(this.props.user.dateOfBirth, this.props.club)){
            savedProfile.dateOfBirth = null;
        }
        else {
            savedProfile.dateOfBirth = savedProfile.dateOfBirth.startOf('day');
        }

        this.props.save(savedProfile)
            .then(() => {
                this.props.hideSpinner();
                this.props.closeDialog();
            }).catch((error) => {
                this.props.hideSpinner();
                this.props.closeAlert();
                if (this.state.errors) this.setState({ profile: this.getProfile(this.props.user), errors: { ...this.state.errors, server: error, email: error } });
                else this.setState({ profile: this.getProfile(this.props.user), errors: { server: error, email: error } });
            });
    }
}

const mapStateToProps = (state: StM.IGlobalStoreState, ownProps: any) => {
    return {
        isShown: state.dialogs.userEdit.isOpened
        , user: state.user
        , isAuth: state.app.isAuthorized
        , club: state.club
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        closeDialog: () => dispatch(ActM.DialogActions.close(StM.DialogNames.UserEdit))
        , save: (profile: StM.IUserManagableStoreState) => dispatch(ActM.UserEditDialogActions.save(profile))
        , showSpinner: () => dispatch(ActM.AppActions.showSpinner())
        , hideSpinner: () => dispatch(ActM.AppActions.hideSpinner())
        , closeAlert: () => dispatch(ActM.DialogActions.close(StM.DialogNames.Alert, null, true))
    };

}

export default connect(mapStateToProps, mapDispatchToProps)(EditUser);
