import * as React from 'react';
import ClassNames from 'classnames';
import * as _ from 'lodash';
import { Link } from 'react-router-dom';
import { history } from '../../../../store/store';

import { StM, SrvM } from '../../../../modules';

interface IClinicTabLinkProps {
    routeParams: StM.IBookPageRouteParams;

    onTabClickHandler: (params: any) => void;
}

interface IClinicTabLinkState {
    isShown?: boolean;
}

export class ClinicTabLink extends React.Component<IClinicTabLinkProps, IClinicTabLinkState> {
    private routeSrv = new SrvM.RouteService();

    constructor(props: any) {
        super(props);
        this.state={
            isShown: props.routeParams.sessionType === StM.BookPageSessionType.Clinic
        }
    }

    componentDidUpdate(prevProps: IClinicTabLinkProps){
        if (prevProps.routeParams.sessionType !== this.props.routeParams.sessionType){
            this.setState({  isShown: this.props.routeParams.sessionType === StM.BookPageSessionType.Clinic});
        }
    }
    
    public render() {
        const isActive = this.props.routeParams.sessionType === StM.BookPageSessionType.Clinic;
        const isClinicListView = this.getIsClinicListView();
        const additionalLink = isClinicListView ? 'Back to Calendar View' : 'Clinic List View' ;

        const classes = ClassNames('side-block-item-wrapper', 'clinic', 'side-block-item-wrapper-clinic', {
            extended: isActive,
            open: this.state.isShown,
        });

        const additionalLinkClasses = ClassNames('clinic-additional-link', { 'back-link': isClinicListView });

        const setHeightOuter = (elem:any) => {
            const blockLessonItem:any = document.getElementsByClassName('block-item-clinic')[0];
            const blockLessonItemOutter:any = document.getElementsByClassName('side-block-item-wrapper-clinic')[0];
            if (this.state.isShown){
                
                blockLessonItem.style.height= '127px';
                blockLessonItemOutter.style.height= '127px';
            }
            else{
                blockLessonItem.style.height= '82px';
                blockLessonItemOutter.style.height= '82px';
                setTimeout(()=>this.setOpacity(), 370);
            }
        };

        return (                
            <div ref={(elem) => setHeightOuter(elem)}  className={classes} onClick={(e) => history.replace(this.getRoute())}>
                <div className="side-block-item side-block-item-clinic ">
                    <div className="block-item block-item-clinic">
                        <div className="block-item-title">CLINIC</div>
                        <div className="hidden-content clinic-hidden-content">
                            <Link to={this.getRoute(!isClinicListView)} className={additionalLinkClasses} onClick={(e) => this.showClinicList(e)}>{additionalLink}</Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private getIsClinicListView(): boolean {
        return _.includes(window.location.pathname,  'list');
    }

    private setOpacity(){
        var blockClinicHidden: any = document.getElementsByClassName('clinic-hidden-content')[0];
        if (blockClinicHidden) {
            blockClinicHidden.style.opacity = '1';
        }
    }

    private showClinicList(e: any){
        if(e) {e.preventDefault(); e.stopPropagation();}
        const isClinicListView = this.getIsClinicListView();
        this.props.onTabClickHandler({
            sessionType: StM.BookPageSessionType.Clinic,
            date: this.props.routeParams.date,
            clinicSubfilter: !isClinicListView ? 'list' : null,
            clinicSubfilterId: !isClinicListView ? 'all' : null,
        })
    }

    private getRoute(isClinicListView: boolean = false) {
        return this.routeSrv.getBookPage({
            ...this.props.routeParams,
            sessionType: StM.BookPageSessionType.Clinic,
            clinicSubfilter: isClinicListView ? 'list' : null,
            clinicSubfilterId: isClinicListView ? 'all' : null,
        }).path;
    }
}
