import './emptyVideoPage.scss';
import * as React from 'react';
import moment from 'moment';
import * as _ from 'lodash';

import * as StM from '../../../models/store';
import * as SrvM from '../../../services';
import { Footer } from '../../../components/pages/video';
import { Constants } from '../../../constants';


interface IEmptyVideoPageProps {
    club: StM.IClubStoreState;
    session: StM.ISessionStoreState;
}

interface IEmptyVideoPageState {
    timeLeft: moment.Duration;
}

export default class EmptyVideoPage extends React.Component<IEmptyVideoPageProps, IEmptyVideoPageState> {
    private intervalID: any;
    private utils = new SrvM.Utils();
    constructor(props: IEmptyVideoPageProps) {
        super(props);

        this.state = {
            timeLeft: this.getTimeLeft()
        }

        document.body.classList.add('video-page-countdown');
    }

    componentDidMount() {
        this.intervalID = setInterval(() => this.tick(), 1000);
    }

    componentWillUnmount() {
        document.body.classList.remove('video-page-countdown');
        clearInterval(this.intervalID);
    }

    render() {
        const session = this.props.session;
        const club = this.props.club;
        if (!club || !club.id) return null;

        const timeLeft = this.state.timeLeft;
        const days = timeLeft.days();
        const hours = this.padTimeUnit(timeLeft.hours());
        const minutes = this.padTimeUnit(timeLeft.minutes());
        const seconds = this.padTimeUnit(timeLeft.seconds());
        const isVideoAvailable = !!session && this.utils.hasVideo(session) && session.status !== StM.SessionStatus.Cancel;
        return (
            <div className="video-page-empty page">
                <div className="main-logo">
                    <a href={club.logoLinkUrl} target="_blank">
                        <img src={'/api/v2/blobs/logo'} alt="club logo" />
                    </a>
                </div>
                <img className="promo-landscape" src={'../content/img/promo-video-1920x776.jpg'} />
                <img className="promo-portrait" src={'../content/img/promo-video-1125x1398.jpg'} />
                {isVideoAvailable && (<aside className="countdown">
                    <div className="intro">Video streaming</div>
                    <div className="intro">Begins in</div>
                    <table>
                        <thead>
                            <tr className="digits">
                                <th className="part days">{days}</th>
                                <th className="separator">:</th>
                                <th className="part hours">{hours}</th>
                                <th className="separator">:</th>
                                <th className="part minutes">{minutes}</th>
                                <th className="separator">:</th>
                                <th className="part seconds">{seconds}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="labels">
                                <td>Days</td>
                                <td></td>
                                <td>Hours</td>
                                <td></td>
                                <td>Minutes</td>
                                <td></td>
                                <td>Seconds</td>
                            </tr>
                        </tbody>
                    </table>
                </aside>)}
                {!isVideoAvailable && (this.renderUnavailableVideoMessage(session))}
                <div className="footer-wrapper">
                    <Footer club={this.props.club} />
                </div>
            </div>
        );
    };

    private renderUnavailableVideoMessage(session: StM.ISessionStoreState) {
        let message = 'Video is not available';
        if(!!session && session.status === StM.SessionStatus.Cancel) message = 'Session has been cancelled';
        return (
            <aside className="countdown">
                <div className="intro">{message}</div>
            </aside>
        );
    }

    private tick() {
        this.setState({ ...this.state, timeLeft: this.getTimeLeft() })
    }

    private getTimeLeft(): moment.Duration {
        const club = this.props.club;
        const session = this.props.session;
        if (!club || !club.id || !session) return moment.duration(0);

        const currentTime = this.utils.getCurrentClubDateTime(club);
        const currentTimeString = currentTime.format(Constants.DateTime.API_FORMAT);

        const startTime = session.startDateTime.clone();
        const startTimeString = startTime.format(Constants.DateTime.API_FORMAT);
        const timeLeft = moment(startTimeString, Constants.DateTime.API_FORMAT).diff(moment(currentTimeString, Constants.DateTime.API_FORMAT));
        const isTimePassed = startTimeString < currentTimeString;
        return moment.duration(!isTimePassed ? timeLeft : 0);
    }

    private padTimeUnit(value: number): string {
        return _.padStart(value.toString(), 2, '0');
    }
};
