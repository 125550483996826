import * as React from 'react';
import { compose, Dispatch } from 'redux';
import { connect } from 'react-redux';

import * as StM from '../../../../models/store';
import * as ActM from '../../../../actions';
import * as SrvM from '../../../../services';

export interface ICreateGroupAccountDialogProps extends IBaseCreateGroupAccountDialogState {
    isOpened: boolean;

    onCloseDialog: (e?: any) => void;
    onFieldChange: (name: string, value: string) => void;
    onSave: (e: any) => void;
    onDrop: (accepted: any, rejected: any) => void;
}

interface IBaseCreateGroupAccountDialogProps {
    isOpened: boolean;

    closeDialog: () => void;
    showSpinner: () => void;
    hideSpinner: () => void;
    createGroupAccount: (group: StM.IGroupStoreState, image?: any) => Promise<StM.IGroupStoreState>;
    showAlert: (msgKey: string, messageType: string) => void;
    closeAlert: () => void;
}

interface IBaseCreateGroupAccountDialogState {
    group: StM.IGroupStoreState;
    image?: any;
    imagePreview?: any;
    errors?: any;
}

const withBaseCreateGroupAccountDialog = (Component: React.ComponentType<ICreateGroupAccountDialogProps>) => {
    return class BaseCreateGroupAccountDialog extends React.Component<IBaseCreateGroupAccountDialogProps, IBaseCreateGroupAccountDialogState> {
        private validator = new SrvM.ValidationService();
        private formValidator = new SrvM.FormValidationService();
        constructor(props: IBaseCreateGroupAccountDialogProps) {
            super(props);

            this.state = {
                group: new StM.GroupStoreState(),
                errors: null,
                image: null
            }
        }

        componentDidUpdate(prevProps) {
            if (this.props.isOpened !== prevProps.isOpened) {
              this.reset();
            }
        }

        public render() {
            return (
                <Component 
                    {...this.state}
                    isOpened={this.props.isOpened}
                    onCloseDialog={(e) => this.handleCloseDialog(e)}
                    onFieldChange={(name, value) => this.handleFieldChange(name, value)}
                    onSave={(e: any) => this.handleSave(e)}
                    onDrop={(accepted: any, rejected: any) => this.handleDrop(accepted, rejected)}
                />
            );
        }

        private handleCloseDialog(e: any) {
            if(e) e.preventDefault();
            this.props.closeDialog();
        }

        private handleFieldChange(name: string, value: any) {
            let errors = !!this.state.errors && { ...this.state.errors };
            if (errors) delete errors[name];
            this.setState({
                group: {
                    ...this.state.group,
                    [name]: value
                },
                errors
            });
        };

        private handleDrop(acceptedFiles: any, rejectedFiles: any) {
            if (rejectedFiles.length) {
                this.setState({
                    ...this.state, ...{
                        image: null
                        , errors: { image: 'Please choose another file and try again' }
                    }
                });
            } else if (acceptedFiles.length) {
                this.setState({...this.state, image: acceptedFiles[0], imagePreview: URL.createObjectURL(acceptedFiles[0])});
            }
        };

        private validate(model: any): boolean {
            const errors = this.formValidator.validateCreateGroupAccountForm(model);
            if (this.validator.hasErrors(errors)) {
                this.setState({ ...this.state, errors });
                return false;
            }
            return true;
        }

        private handleSave(e: any) {
            if(e) e.preventDefault();
            if (!this.validate(this.state.group)) return false;

            this.props.showSpinner();
            this.props.createGroupAccount(this.state.group, this.state.image)
                .then(() => {
                    this.props.hideSpinner();
                    this.props.closeDialog();
                    this.props.showAlert(StM.MessagesKey.CreateGroupAccount, StM.MessageTypes.Success);
                }).catch((error) => {
                    this.props.closeAlert();
                    this.props.hideSpinner();
                    
                    this.setState({...this.state, errors: this.validator.setServerError(error.data, this.state.errors)});
                });
        }

        private reset() {
            this.setState({
                errors: null,
                group: new StM.GroupStoreState(),
                image: null,
                imagePreview: null
            });
        }
    }
}

const mapStateToProps = (state: StM.IGlobalStoreState, ownProps: any) => ({
    isOpened: state.dialogs.createGroupAccount.isOpened,
});

const mapDispatchToProps = (dispatch: any) => ({
    showSpinner: () => dispatch(ActM.AppActions.showSpinner()),
    hideSpinner: () => dispatch(ActM.AppActions.hideSpinner()),
    closeDialog: () => dispatch(ActM.DialogActions.close(StM.DialogNames.CreateGroupAccount)),
    createGroupAccount: (group: StM.IGroupStoreState, image?: any) => dispatch(ActM.GroupAccountActions.create(group, image)),
    showAlert: (msgKey: string, messageType: string) => dispatch(ActM.DialogActions.open(StM.DialogNames.Alert, { msgKey, messageType })),
    closeAlert: () => dispatch(ActM.DialogActions.close(StM.DialogNames.Alert)),
});

export const withCreateGroupAccountDialog = compose(connect(mapStateToProps, mapDispatchToProps), withBaseCreateGroupAccountDialog);