import moment from 'moment';
import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as StM from '../../../models/store';
import * as ActM from '../../../actions';
import * as SrvM from '../../../services';
import TimeBoardMobile from './timeBoard.mobile';

interface IСalendarCourtBoardProps {
    club: StM.IClubStoreState;
    date: moment.Moment;
    sessionType: string;
    user?: StM.IUserStoreState;
    basket: StM.IBasketStoreState;
    timeSlots: Array<StM.ICourtTimeSlotStoreState>;
    notifications: Array<StM.INotificationStoreState>;
    expandedSlotTimeKey: string;
    coaches: Array<StM.ICoachStoreState>;
    coachFeeTiers: Array<StM.ICoachFeeTierStoreState>;
    params: StM.IBookPageRouteParams;
    availableTimes: Array<StM.IAvailableTimeStoreState>;
    selectedAvailability: StM.ICoachAvailabilityStoreState;

    onCreateSessionClick: (courtId: number, timeKey: string) => any;
    openSessionClick: (from: string, id: number) => void;
    onExpandSlot: (timeKey: string) => void;
    setAvailabilityClick?: (timeItem: StM.ICoachAvailabilityTimeItem) => void;
}

interface IСalendarCourtBoardState {
}

class СalendarCourtBoardMobile extends React.Component<IСalendarCourtBoardProps, IСalendarCourtBoardState> {
    courtTimeService: SrvM.CourtTimeService;
    constructor(props: IСalendarCourtBoardProps) {
        super(props);
        this.courtTimeService = new SrvM.CourtTimeService();
    }

    render() {
        return (
            <div className="calendar-court-mobile" id="calendarCourt">
                <TimeBoardMobile club={this.props.club}
                    date={this.props.date}
                    sessionType={this.props.sessionType}
                    timeSlots={this.props.timeSlots}
                    user={this.props.user}
                    params={this.props.params}
                    basket={this.props.basket}
                    notifications={this.props.notifications}
                    coaches={this.props.coaches}
                    coachFeeTiers={this.props.coachFeeTiers}
                    expandedSlotTimeKey={this.props.expandedSlotTimeKey}
                    availableTimes={this.props.availableTimes}
                    selectedAvailability={this.props.selectedAvailability}
                    onCreateSessionClick={(courtId, timeKey) => this.props.onCreateSessionClick(courtId, timeKey)}
                    openSessionClick={this.props.openSessionClick}
                    onExpandSlot={(timeKey) => this.props.onExpandSlot(timeKey)}
                    setAvailabilityClick={this.props.setAvailabilityClick}
                    />
            </div>
        )
    };
};

export default СalendarCourtBoardMobile;
