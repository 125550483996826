import * as Redux from 'redux';

import { ThunkService } from '../../services/thunkService';
import * as StM from '../../models/store';
import * as ActM from '../';

export class ProfilePageActions {
    public static init(): (dispatch: any) => void {
        return (dispatch: any) => {
            dispatch({ type: 'Pages/Profile/Init', payload: null });
        };
    }

    public static uploadProfileImage(file: any): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<any> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            dispatch(ActM.AppActions.showSpinner())

            const final = () => {
                dispatch(ActM.UserActions.getCurrent())
                dispatch(ActM.AppActions.hideSpinner());
                dispatch(ActM.BaseActions.hideSplash());
            };
            
            return thunkService.api.user.uploadProfileImage(file)
            .then((response: any) => {
                    return Promise.resolve();
            }).catch((error) => {
                    throw  { message: error };
            }).then(()=> final());
        };
    }
}
