import './calendar.scss';

import moment from 'moment';
import * as React from 'react';

interface ICalendarFilterBoardProps {
    date: moment.Moment;
    collapse?: boolean

    onDateChangedHandler: (date: moment.Moment) => void;
}

interface ICalendarFiterBoardState {
    show: boolean;
}

export class CalendarFilterBoard extends React.Component<ICalendarFilterBoardProps, ICalendarFiterBoardState> {
    private _monthes = moment.monthsShort();
    private _days = ["S", "M", "T", "W", "T", "F", "S"];

    constructor(props: ICalendarFilterBoardProps) {
        super(props);
        this.state = {
            show: !this.props.collapse
        }
    }

    public render() {
        const current = this.props.date.clone();
        const month = current.month();
        const year = current.format('YYYY');

        return (
            <div className={"side-block-item-wrapper date" + (this.state.show ? ' show-calendar' : '')}>
                <div className="side-block-item">
                    <div className="block-item-title" onClick={(e) => this.toggleClick(e)}>
                        {this._monthes[month] + " " + year}
                    </div>
                    <div className="sq-datepicker-wrapper">
                        <table className="sq-datepicker">
                            <thead>
                                <tr className="header-date-wrapper">
                                    <th className="date" colSpan={5} onClick={(e) => this.toggleClick(e)}>
                                        {this._monthes[month] + ' '  + year}
                                    </th>
                                    <th className="arrows-wrapper" colSpan={2}>
                                        <span className="left-arrow-wrapper">
                                            <span className="left-arrow" onClick={(e) => this.arrowClick(e, true)}></span>
                                        </span>
                                        <span className="right-arrow-wrapper">
                                            <span className="right-arrow" onClick={(e) => this.arrowClick(e)}></span>
                                        </span>
                                    </th>
                                </tr>
                                <tr className="header-days-week-wrapper">
                                    {this._days.map((day, index) => <th key={index}>{day}</th>)}
                                </tr>
                            </thead>
                            <tbody>
                                {this.renderMonth(this.props.date)} 
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>                
        )
    };

    private renderMonth(date: moment.Moment) {
        let daysInMonth = date.daysInMonth();
        let firstDay = date.clone().date(1);
        let firstDayShown = firstDay.clone().weekday(0);
        let lastDayShown = date.clone().date(daysInMonth).weekday(6);
        let sumDays = lastDayShown.diff(firstDayShown, 'days') + 1;
        let startDate = firstDayShown.clone();

        let monthElement : Array<any> = [];
        for (let i = 0; i < sumDays / 7; i++) {
            let isActive = startDate.week() == date.week();
            let weekElems = this.renderWeek(startDate, date);
            
            monthElement.push(
                <tr className={isActive ? 'active-week' : ''} key={startDate.week()}>
                    {weekElems}
                </tr>
            );
        };

        return monthElement;
    };

    private renderWeek(start:moment.Moment, active:moment.Moment) {
        let week: Array<any> = [];
        for (let i = 0; i < 7; i++) {
            let isNew = active.month() > start.month() || active.year() > start.year();
            let isOld = active.month() < start.month() || active.year() < start.year();
            let activeDate = start.clone();
            week.push(
                <td className={'day ' + (isNew ? 'new' : (isOld ? 'last' : ''))} key={start.date()} onClick={() => this.props.onDateChangedHandler(activeDate)}>{start.date()}</td>
            )
            start.add(1, 'days');
        }
        return week;
    }

    private toggleClick(e:any) {
        if(e) e.stopPropagation();
        this.setState({ ...this.state, show: !this.state.show });
    }

    private arrowClick(e: any, prev: boolean = false) {
        if(e) e.stopPropagation();
        const date = this.props.date.clone().set('date', 1).add(prev ? -1 : 1, 'month');
        this.props.onDateChangedHandler(date);
    }
};
