import moment from 'moment';

import { ThunkService } from '../../services/thunkService';
import { StM, ActM } from '../../modules';

export class ClubDashboardActions {
    constructor() {
    }

    static init(date: moment.Moment): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<void> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            dispatch(ActM.AppActions.showSpinner());
            const final = () => {
                dispatch(ActM.AppActions.hideSpinner());
                dispatch(ActM.BaseActions.hideSplash());
            }
            Promise.resolve(dispatch(ActM.ClubDashboardActions.getSessions(date)))
                .then(() => {
                    return Promise.resolve(dispatch(ActM.ClubDashboardActions.fillCourtTimeSlots(date)));
                }).then(() => {
                    final();
                }).catch(() => {
                    final();
                });
            dispatch({ type: 'Pages/ClubDashboard/Init', payload: null });
            return Promise.resolve();
        };
    }

    // tslint:disable-next-line:member-ordering
    static getSessions(date: moment.Moment): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<any> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            const { club, pricingTiers } =  getState();
            const start = date.clone().startOf('day');
            const end = start.clone().add(1, 'day');
            return thunkService.api.session.getByPeriod(start, end, club, pricingTiers, true)
                .then((response: any) => {
                    let sessions = <Array<StM.ISessionStoreState>>response;
                    dispatch({
                        type: 'Pages/ClubDashboard/Sessions',
                        payload: sessions
                    });
                });
        };
    }

    static refreshDictionaries(date: moment.Moment): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<any> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            return thunkService.api.common.getDictionaries()
                .then((dictionaries: StM.ICommonDictionariesStoreState) => {
                    const clubP = dispatch(ActM.ClubActions.init(dictionaries.club));
                    const coachesP = dispatch(ActM.CoachesActions.init(dictionaries.coaches));
                    const coachFeeTiersP = dispatch(ActM.CoachFeeTiersActions.init(dictionaries.coachFeeTiers));
                    const membershipLevelsP = dispatch(ActM.MembershipLevelsActions.init(dictionaries.membershipLevels));
                    const packagesP = dispatch(ActM.PackagesActions.init(dictionaries.packageDefinitions, dictionaries.packageSessionTypes));

                    const allP = Promise.all([clubP, coachesP, coachFeeTiersP, membershipLevelsP, packagesP
                    ]).then(() => {
                        return dispatch({
                            type: 'App/Init'
                        });
                    });
                    return allP;
                }).catch((error) => {
                    thunkService.logger.error(error);
                });
        };
    }

    static fillCourtTimeSlots(date: moment.Moment)
        : (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Array<StM.CourtTimeSlotStoreState> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            const store = <StM.IGlobalStoreState>getState();
            const club = store && store.club ? store.club : null;
            const sessions = store && store.pages && store.pages.clubDashboard && store.pages.clubDashboard.sessions ? store.pages.clubDashboard.sessions : [];
            const coaches = store && store.coaches ? store.coaches : [];
            const pricingTiers = store && store.pricingTiers ? store.pricingTiers : [];

            const timeSlots = thunkService.courtTime.getCourtDaySlots(date, club, sessions, [], [], null, [], null, pricingTiers, false, coaches);

            dispatch({
                type: 'Pages/ClubDashboard/CourtTimeSlots',
                payload: timeSlots
            });

            return timeSlots
        };
    }
}
