import * as React from 'react';
import ClassNames from 'classnames';

import BaseBasketItem, { IBaseBasketItemProps } from './baseBasketItem';

interface IBasketItemProps extends IBaseBasketItemProps {}

export class BasketItem extends BaseBasketItem<IBasketItemProps> {
    constructor(props: IBasketItemProps) {
        super(props);
    }

    public render() {
        const session = this.props.session;
        if (!session) return null;

        const basketItemClasses = ClassNames('order-item', session.type.toLowerCase(), {
            'order-with-coach': !!session.trainer,
            'credits': !!session.checkoutCredits
        });

        return (
            <div className={basketItemClasses} onClick={(e) => this.onItemClick(e)}>
                <span className="close" onClick={(e) => this.onRemoveItemClick(e)}></span>
                {!this.getIsCourse() && this.renderDateTime()}
                <div className="order-name">{this.utils.getSessionTitle(session)}</div>
                {this.renderCourtPriceWrapper()}
                {this.renderCoach()}
            </div>
        );
    }

    private renderCourtPriceWrapper() {
        const isCourse = this.getIsCourse();
        return (
            <div className="court-price-wrapper">
                {!isCourse && this.renderCourt()}
                {isCourse && <div className="course-title">{this.getCourseTitle()}</div>}
                {this.renderPrice()}
            </div>
        );
    } 
}
export default BasketItem;
