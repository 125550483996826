
import './userPage.scss';

import * as React from 'react';
import { connect } from 'react-redux';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';

import * as StM from '../../../models/store';
import * as ActM from '../../../actions';
import * as SrM from '../../../services';
import UserNavigation from '../../../components/pages/profile/userNavigation';
import ProfilePage from './profile/profilePage';
import HistoryPage from './historyPage';
import MyPackagesPage from './myPackages/myPackagesPage';
import GroupsPage from './groups/groupsPage';
import GroupMembersPage from './groups/membersPage';

interface IUserPageProps {
    club: StM.IClubStoreState;
    isAuth: boolean;
    user: StM.IUserStoreState;

    go: (url: string) => any;
}

interface IUserPageState { }

class UserPage extends React.Component<IUserPageProps, IUserPageState> {
    private routeService = new SrM.RouteService();
    private authSrv = new SrM.AuthenticationService();
    constructor(props: IUserPageProps) {
        super(props);
    }

    componentDidUpdate(prevProps: IUserPageProps) {
        if (prevProps.isAuth && !this.props.isAuth) {
            const bookTodayRoute = this.routeService.getBookTodayRoute();
            this.props.go(bookTodayRoute);
        }
    }

    public render() {
        const isGroupAccount = this.authSrv.isInRole(StM.Roles.GroupAccount, this.props.user);
        return (
            <div className="user-pages">
                <UserNavigation club={this.props.club} user={this.props.user} />
                <Switch>
                    <Route path={`${StM.Pages.User}/${StM.UserPages.Profile}`} exact component={ProfilePage} />
                    <Route path={`${StM.Pages.User}/${StM.UserPages.History}/:start/:end`} exact component={HistoryPage} />
                    <Route path={`${StM.Pages.User}/${StM.UserPages.MyPackages}`} exact component={MyPackagesPage} />
                    <Route path={`${StM.Pages.User}/${StM.UserPages.MyGroups}`} exact component={GroupsPage} />
                    {isGroupAccount && <Route path={`${StM.Pages.User}/${StM.UserPages.GroupMembers}`} exact component={GroupMembersPage} />}
                    <Redirect to={`${StM.Pages.User}/${StM.UserPages.Profile}`} />
                </Switch>
            </div>
        )
    };
};

function mapStateToProps(state: StM.IGlobalStoreState, ownProps: any) {
    return {
        club: state.club,
        isAuth: state.app.isAuthorized,
        user: state.user,
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        go: (url: string) => dispatch(ActM.RouteActions.replace(url))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserPage));
