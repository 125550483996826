import './calendar.scss';
import * as React from 'react';
import * as StM from '../../../../models/store';
import {Badge} from './badges/badge';

interface ICourtBoardProps {
    club: StM.IClubStoreState;
    timeSlot: StM.ICourtTimeSlotStoreState;
}

interface ICourtBoardState {
}

class CourtBoard extends React.Component<ICourtBoardProps, ICourtBoardState> {
    
    constructor(props: ICourtBoardProps) {
        super(props);
    }
    
    render() {
        const times = this.props.timeSlot ? this.props.timeSlot.timeBlocks : [];
        return (
            <div className="table-dashboard-column">
                <div className="shift-for-fist-time">
                </div>
                {times.map((time) => {
                    return (<Badge time={time} key={time.key}/>);
                })}
            </div>
        );
    };
}

export default CourtBoard;
