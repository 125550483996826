import './playBadge.scss';
import * as React from 'react';

import { PolM, StM } from '../../../../modules';
import { IBaseBadgeProps, CommonBadge } from './baseBadge';

interface IPlayBadgeBadgeProps extends IBaseBadgeProps {
    user: StM.IUserStoreState;
    
    onBadgeClick: (from: string, id: number) => void;
}

export class PlayBadge extends React.Component<IPlayBadgeBadgeProps> {
    private sessionInfo: PolM.SessionInfoPolicy;
    private sessionAvailability: PolM.SessionAvailabilityPolicy;

    constructor(props: IPlayBadgeBadgeProps) {
        super(props);
        this.init(props);
    }

    public componentDidUpdate(prevProps: IPlayBadgeBadgeProps) {
        const didUserChange = this.props.user.id != prevProps.user.id;
        const didTimeChange = this.props.time.date != prevProps.time.date;
        
        if (didUserChange || didTimeChange) this.init(this.props);
    }

    public render() {
        const session = this.props.time.session;
        const isGroupSession = this.sessionAvailability.getIsGroupSession();
        const isGroupMemberSession = this.sessionAvailability.getIsGroupMemberSession(false);
        const isBoughtByGroupMember = isGroupMemberSession && !isGroupSession && !this.props.time.isSessionFromBasket;        
        const styles: any = {
            height: CommonBadge.getBadgeHeightByDuration(this.props.time.duration)
        }

        return (
            <div className={CommonBadge.getBadgeClasses(this.props.time, this.props.user)} onClick={(e) => this.onBadgeClick(e, session)}>
                <div className="table-column-item" style={styles}>
                    {!isBoughtByGroupMember && this.renderFullContent(session)}
                    {isBoughtByGroupMember && CommonBadge.renderGroupMemberContent(session, this.props.user, this.props.filter)}
                </div>
            </div>
        )
    }

    private renderFullContent(session: StM.ISessionStoreState) {
        const headerTitle = CommonBadge.getBadgeTitle(session, this.props.user, this.props.time.isOwner);

        let skill: string;
        if (session.seekingId) {
            if (session.playerQualification.skill) {
                skill = session.playerQualification.skill;
            }
        }

        const slots = CommonBadge.getRenderSlots(session.maxUserCount, this.sessionInfo.getPlayers(true, true).length);
        const ownerDisplayName = session.owner && session.owner.displayName ? session.owner.displayName : 'No Owner';

        let invitation = '';
        let invitationTitle = '';
        if (this.props.time.isInvited) {
            let inviterName = this.props.time.userInviter ? this.props.time.userInviter.displayName : ownerDisplayName;
            invitation = 'from ' + inviterName;
            invitationTitle = 'Invitation ' + invitation;
        }

        return (
            <>
                <div className="header-cell">
                        <div className="lesson-name-difficult">
                            <div className="lesson-name" title={invitationTitle}>{headerTitle}</div>
                            {CommonBadge.getRenderVideoIcon(session)}
                            {CommonBadge.renderSkillMarkup(skill)}
                        </div>
                    </div>
                    <div className="header-cell-full">
                        <div className="lesson-time-difficult">
                            <div className="lesson-time-wrapper">
                                <span className="lesson-time">{CommonBadge.getTimeString(session)}</span>
                                <span className="lesson-time-zone">{session.endDateTime.format('a')}</span>
                            </div>
                            {CommonBadge.renderSkillMarkup(skill)}
                        </div>
                        <div className="lesson-summary">
                            <div className="lesson-name">
                                <span className="title">{headerTitle}</span>
                                {CommonBadge.getRenderVideoIcon(session)}
                            </div>
                            <span className="game-owner">{ownerDisplayName}</span>
                            <span className="invitation" title="Invitation">Invitation</span>
                            <span className="invitation-from" title={invitation} >{invitation}</span>
                        </div>
                    </div>
                    <div className="body-cell">
                        {slots}
                        {CommonBadge.renderPrice(session, this.props.time, this.props.user)}
                    </div>
            </>
        );
    }

    private init(props: IPlayBadgeBadgeProps) {
        this.sessionInfo = new PolM.SessionInfoPolicy(props.time.session);
        this.sessionAvailability = new PolM.SessionAvailabilityPolicy(props.time.session, [], props.user);
    }

    private onBadgeClick(e: any, session: StM.SessionStoreState) {
        if (e) { e.preventDefault(); e.stopPropagation(); }
        let id = session.id || session.basketId;
        let from = session.id ? StM.SessionInfoFromTypes.calendar : StM.SessionInfoFromTypes.basket;
        this.props.onBadgeClick(from, id);
    }
}
