import '../dialogs.mobile.scss';
import './demographicInformationDialog.mobile.scss';
import 'react-international-phone/style.css';
import * as React from 'react';
import moment from 'moment';
import { PhoneInput } from 'react-international-phone';
import * as _ from 'lodash';

import { ModalMobile, InputSearchMobile, ImageUploader, Field, FormError } from '../../../components';
import DatePickerMobile from '../../../components/datepicker.mobile';
import * as SrvM from '../../../services';
import * as StM from '../../../models/store';
import * as BD from "./baseDemographicInformationDialog";
import Constants, { Images } from '../../../constants';

export interface DemographicInformationDialogMobileProps extends BD.IDemographicInfoDialogProps { }

class DemographicInformationDialogMobile extends React.Component<DemographicInformationDialogMobileProps> {
    private utils = new SrvM.Utils();

    constructor(props: DemographicInformationDialogMobileProps) {
        super(props);
    }

    render() {
        if (!this.props.isShown) return null;
        const demInfo = this.props.demInfo;
        const errors = this.props.errors;

        let dateOfBirth = this.props.user.dateOfBirth;
        let sex: any = _.find(BD.genders, { value: demInfo.gender });
        let skillString = this.utils.getSkill(demInfo.skill);
        let skill: any = _.find(BD.skills, { value: skillString });

        sex = sex ? sex.key : null;
        skill = skill ? skill.key : null;

        let imageUrl = this.props.user.imageId ? this.utils.getImageUrl(this.props.user.imageId) : Images.NoPhoto2;
        if (this.props.imagePreview) imageUrl += '?' + moment().milliseconds();

        return (
            <ModalMobile classes="demographic-info mobile" dialogName={StM.DialogNames.DemographicInformation} customZIndex={9999}>
                <div className="modal-dialog-header">
                    <div className="title">Account Information</div>
                </div>
                <div className="modal-dialog-body container">
                    <div className="title">Please update your profile information.</div>
                    <div className="form-wrapper">
                        <ImageUploader url={imageUrl} onDrop={(acceptedFiles, rejectedFiles) => this.props.onDrop(acceptedFiles, rejectedFiles)} />
                        <div className="inputs-wrapper">
                            <Field name="dateOfBirth" className="date-of-birth" label="Date of Birth" error={errors && errors.dateOfBirth}>
                                <DatePickerMobile 
                                    classes="input-item date-of-birth"
                                    date={dateOfBirth}
                                    disabled={!!dateOfBirth}
                                    onChange={(date) => this.props.handleFieldChange('dateOfBirth', this.utils.toDateOfBirth(date))}
                                />
                            </Field>
                            <Field name="phone" label="Phone" error={errors && errors.phone}>
                                <PhoneInput
                                    value={demInfo.phone}
                                    preferredCountries={['us']}
                                    defaultCountry="us"
                                    onChange={(value) => { this.props.handleFieldChange('phone', value) }}
                                    placeholder={Constants.PhonePlaceholder}
                                />
                            </Field>
                            <Field name="gender" label="Gender" error={errors && errors.gender}>
                                <InputSearchMobile
                                    array={BD.genders}
                                    placeholder=" "
                                    classes="sex-input-wrapper"
                                    selected={sex}
                                    readOnly
                                    onSelectChange={(item) => this.props.handleFieldChange('gender', item.value)}
                                />
                            </Field>
                            <Field name="skill" label="Skill" error={errors && errors.skill}>
                                <InputSearchMobile
                                    array={BD.skills}
                                    placeholder=" "
                                    classes="skill-input-wrapper"
                                    selected={skill}
                                    readOnly
                                    onSelectChange={(item) => this.props.handleFieldChange('skill', this.utils.getShortSkill(item.value))}
                                />
                            </Field>
                        </div>
                    </div>
                    {this.renderEmergencyContactForm()}
                    <FormError errors={errors} />
                    <div className="divider-line"></div>
                    <div className="btns-wrapper">
                        <button className="btn-mobile btn-next btn-red" onClick={(e) => this.props.onNextClick(e)}>NEXT</button>
                    </div>
                </div>
            </ModalMobile>
        );
    }

    private renderEmergencyContactForm() {
        const demInfo = this.props.demInfo;
        const errors = this.props.errors;
        const name = demInfo.emergencyContactFullName;
        const phone = demInfo.emergencyContactPhoneNumber;
        const relationship = demInfo.emergencyContactRelationship;

        return (
            <div>
                <div className="title">Emergency contact information</div>
                <div className="form-wrapper emergency-contact-form">
                    <div className="inputs-wrapper">
                        <Field name="emergencyContactFullName"
                            label="Name"
                            type="text"
                            maxLength={128}
                            value={name}
                            error={errors && errors.emergencyContactFullName}
                            onFieldChange={(name, value) => this.props.handleFieldChange(name, value)}
                        />
                        <Field name="emergencyContactPhoneNumber" label="Phone" error={errors && errors.emergencyContactPhoneNumber}>
                            <PhoneInput
                                value={phone}
                                preferredCountries={['us']}
                                defaultCountry="us"
                                onChange={(value) => { this.props.handleFieldChange('emergencyContactPhoneNumber', value) }}
                                placeholder={Constants.PhonePlaceholder}
                            />
                        </Field>
                        <Field name="emergencyContactRelationship"
                            label="Relationship"
                            type="text"
                            maxLength={128}
                            value={relationship}
                            error={errors && errors.emergencyContactRelationship}
                            onFieldChange={(name, value) => this.props.handleFieldChange(name, value)}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default BD.withDemographicInfoDialog(DemographicInformationDialogMobile);