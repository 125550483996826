import './userNavigation.mobile.scss';
import * as React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import ClassNames from 'classnames';
import * as StM from '../../../models/store';
import * as SrvM from '../../../services';
interface IUserNavigationProps {
    club: StM.IClubStoreState;
    match: any;
    location: any;
    history: any;
    staticContext: any;
    user: StM.IUserStoreState;
}

interface IUserNavigationState {
    isMenuOpen: boolean
    activeMenuItem: string;
}

class UserNavigationMobile extends React.Component<IUserNavigationProps, IUserNavigationState> {
    private routeService: SrvM.RouteService;
    private authSrv = new SrvM.AuthenticationService();
    constructor(props: IUserNavigationProps) {
        super(props);
        this.state = {
            isMenuOpen: false,
            activeMenuItem: StM.UserPages.Profile
        };
        this.routeService = new SrvM.RouteService();
    }
    menuToggle(item?: string) {
        this.setState({
            ...this.state,
            isMenuOpen: !this.state.isMenuOpen,
            activeMenuItem: item ? item : this.state.activeMenuItem
        });
    }
    render() {
        let selectedItemTitle: string = null;
        switch (this.state.activeMenuItem) {
            case StM.UserPages.Profile:
                selectedItemTitle = "Profile";
                break;
            case StM.UserPages.History:
                selectedItemTitle = "History";
                break;
            case StM.UserPages.MyPackages:
                selectedItemTitle = "Club Credits";
                break;
            case StM.UserPages.MyGroups:
                selectedItemTitle = "My Groups";
                break;
            case StM.UserPages.GroupMembers:
                selectedItemTitle = "Group Members";
                break;
        }

        let menuListClasses = ClassNames('menu-list-mobile', { 'open-mobile': this.state.isMenuOpen });
        const historyPath = this.routeService.getHistoryDefaultRoute(this.props.club);
        const isGroupAccount = this.authSrv.isInRole(StM.Roles.GroupAccount, this.props.user);
        
        return (
            <div className="main-content-wrapper-mobile user-page-mobile">
                <div className="menu-wrapper-mobile">
                    <div className="selected-bar-mobile" onClick={() => this.menuToggle()}>
                        <span className="selected-title">{selectedItemTitle}</span>
                        <span className="caret-mobile"></span>
                    </div>
                    <ul className={menuListClasses}>
                        <NavLink 
                          className="menu-item-mobile" 
                          activeClassName="menu-item-active-mobile" 
                          to={"{0}/{1}".format(StM.Pages.User, StM.UserPages.Profile)} 
                          onClick={() => this.menuToggle(StM.UserPages.Profile)}
                        >
                            <li>My Profile</li>
                        </NavLink>
                        <NavLink 
                            className="menu-item-mobile" 
                            activeClassName="menu-item-active-mobile" 
                            to={historyPath} 
                            onClick={() => this.menuToggle(StM.UserPages.History)}
                        >
                            <li>History</li>
                        </NavLink>
                        <NavLink 
                            className="menu-item-mobile" 
                            activeClassName="menu-item-active-mobile" 
                            to={`${StM.Pages.User}/${StM.UserPages.MyPackages}`} 
                            onClick={() => this.menuToggle(StM.UserPages.MyPackages)}
                        >
                            <li>Club Credits</li>
                        </NavLink>
                        {!isGroupAccount && 
                            <NavLink 
                                className="menu-item-mobile" 
                                activeClassName="menu-item-active-mobile" 
                                to={`${StM.Pages.User}/${StM.UserPages.MyGroups}`} 
                                onClick={() => this.menuToggle(StM.UserPages.MyGroups)}
                            >
                                <li>My Groups</li>
                            </NavLink>
                        }
                        {isGroupAccount && 
                            <NavLink 
                                className="menu-item-mobile" 
                                activeClassName="menu-item-active-mobile" 
                                to={`${StM.Pages.User}/${StM.UserPages.GroupMembers}`} 
                                onClick={() => this.menuToggle(StM.UserPages.GroupMembers)}
                            >
                                <li>Group Members</li>
                            </NavLink>
                        }
                    </ul>
                </div>
                {this.props.children}
            </div>
        )
    }
}

const connectedNavigationMobile = withRouter(UserNavigationMobile);
export default connectedNavigationMobile;
