import './contactsPage.mobile.scss';
import * as React from 'react';
import { connect } from 'react-redux';
import * as StoreModule from '../../models/store';
import * as StM from '../../models/store'
import * as ActM from '../../actions';

interface IContactsPageMobileProps {
     openContactUs: () => void;
     club: StM.IClubStoreState;
}

interface IContactsPageMobileState {

}

class ContactsPageMobile extends React.Component<IContactsPageMobileProps, IContactsPageMobileState> {

     openContactUs(e:any) {
        if(e) {e.stopPropagation();e.preventDefault()};
        this.props.openContactUs();
    }
    render() {
        const club = this.props.club;
        const fineUrl = club.url.replace(/^http(s?):\/\//, '').replace(/\/$/, '');

        return (
            <div className="main-content-wrapper-mobile contacts-page-mobile">
                <div className="page-title">Contacts</div>
                <div className="field-title">Address</div>
                <div className="field-value">{club.address1}</div>
                <div className="field-value final">{club.address2}</div>
                <div className="field-title">Telephone</div>
                <div className="field-value final">{club.phone}</div>
                <div className="field-divider"></div>
                <a className="field-url" href={club.url} target="_blank">{fineUrl}</a>
                <div className="field-divider"></div>
                <div className="btns-wrapper">
                    <button className="btn btn-black" onClick={(e) => this.openContactUs(e)}>Contact Us</button>
                </div>
            </div>
        )
    };
};

function mapStateToProps(state: StoreModule.IGlobalStoreState, ownProps: any) {
    return {
        club: state.club
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        openContactUs : () =>  dispatch(ActM.DialogActions.open(StM.DialogNames.ContactUs))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactsPageMobile);
