import * as Redux from 'redux';

import { ThunkService } from '../../services/thunkService';
import { StM, ActM } from '../../modules';

export class FBLinkPageActions {


    static init(inviteToken: string)
    : (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<any> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            const { club, pricingTiers } =  getState();
            return thunkService.api.session.getByInviteToken(inviteToken, club, pricingTiers)
                .then((session: StM.ISessionStoreState) => {
                    if(session){
                        dispatch(ActM.DialogActions.open(StM.DialogNames.SessionInfo, {
                            from: StM.SessionInfoFromTypes.inviteLink,
                            id: inviteToken,
                        }));
                        dispatch({
                            type: 'Dialogs/SessionInfo/Session',
                            payload: session
                        });
                    }else{
                        dispatch(ActM.RouteActions.replace(thunkService.routeService.getBookTodayRoute()));
                        dispatch(ActM.DialogActions.open(StM.DialogNames.Alert, {msgKey: StM.MessagesKey.SessionNotFound, messageType: StM.MessageTypes.Error}));
                    }
                }).catch((error) => {
                    thunkService.logger.error(error);
                    dispatch(ActM.RouteActions.replace(thunkService.routeService.getBookTodayRoute()));
                });
        };
    }
}
