import './customBadge.scss';
import * as React from 'react';

import { StM, PolM } from '../../../../modules';
import { IBaseBadgeProps, CommonBadge } from './baseBadge';

interface ICustomBadgeBadgeProps extends IBaseBadgeProps {
    user: StM.IUserStoreState;

    onBadgeClick: (from: string, id: number) => void;
}

export class CustomBadge extends React.Component<ICustomBadgeBadgeProps> {
    private sessionInfo: PolM.SessionInfoPolicy;
    private sessionAvailability: PolM.SessionAvailabilityPolicy;

    constructor(props: ICustomBadgeBadgeProps) {
        super(props);
        this.init(props);
    }

    public componentDidUpdate(prevProps: ICustomBadgeBadgeProps) {
        const didUserChange = this.props.user.id != prevProps.user.id;
        const didTimeChange = this.props.time.date != prevProps.time.date;
        
        if (didUserChange || didTimeChange) this.init(this.props);
    }

    public render() {
        const session = this.props.time.session;
        const isGroupSession = this.sessionAvailability.getIsGroupSession();
        const isGroupMemberSession = this.sessionAvailability.getIsGroupMemberSession(false);
        const isBoughtByGroupMember = isGroupMemberSession && !isGroupSession && !this.props.time.isSessionFromBasket;
        let styles: any = {
            height: CommonBadge.getBadgeHeightByDuration(this.props.time.duration) - 1,
        }
        if (!this.props.time.isSessionFromBasket) {
            styles.borderTopColor = session.customBackgroundColor;
            if (this.props.time.isBought) {
                styles.backgroundColor = session.customBackgroundColor;
                styles.color = session.customTextColor;
            }
        }

        return (
            <div className={CommonBadge.getBadgeClasses(this.props.time, this.props.user)} onClick={(e) => this.onBadgeClick(e, session)}>
                <div className="table-column-item" style={styles}>
                    {!isBoughtByGroupMember && this.renderFullContent(session)}
                    {isBoughtByGroupMember && CommonBadge.renderGroupMemberContent(session, this.props.user, this.props.filter)}
                </div>
            </div>
        )
    }

    private renderFullContent(session: StM.ISessionStoreState) {
        const headerTitle = CommonBadge.getBadgeTitle(session, this.props.user, this.props.time.isOwner);
        const skill = session.playerQualification ? session.playerQualification.skill : null;
        const slots = CommonBadge.getRenderSlots(session.maxUserCount, this.sessionInfo.getPlayers(true, true).length);
        return (
            <>
                <div className="header-cell">
                        <div className="lesson-name-difficult">
                            <div className="lesson-name">{headerTitle}</div>
                            {CommonBadge.getRenderVideoIcon(session)}
                            {CommonBadge.renderSkillMarkup(skill)}
                        </div>
                    </div>
                    <div className="header-cell-full">
                        <div className="lesson-time-difficult">
                            <div className="lesson-time-wrapper">
                                <span className="lesson-time">{CommonBadge.getTimeString(session)}</span>
                                <span className="lesson-time-zone">{session.endDateTime.format('a')}</span>
                            </div>
                            {CommonBadge.renderSkillMarkup(skill)}
                        </div>
                        <div className="coach-name">
                            {session.trainer ? session.trainer.displayName : ''}
                        </div>
                        <div className="coach-tooltip">
                            <span className="coach-tooltip-title">{session.trainer ? session.trainer.displayName : ''}</span>
                        </div>
                        <div className="lesson-summary">
                            <div className="lesson-name">
                                <span className="title">{headerTitle}</span>
                                {CommonBadge.getRenderVideoIcon(session)}
                            </div>
                        </div>
                    </div>
                    <div className="body-cell">
                        {slots}
                        {CommonBadge.renderPrice(session, this.props.time, this.props.user)}
                    </div>
            </>
        );
    }

    private init(props: ICustomBadgeBadgeProps) {
        this.sessionInfo = new PolM.SessionInfoPolicy(props.time.session);
        this.sessionAvailability = new PolM.SessionAvailabilityPolicy(props.time.session, [], props.user);
    }

    private onBadgeClick(e: any, session: StM.SessionStoreState) {
        if (e) { e.preventDefault(); e.stopPropagation(); }
        let id = session.id || session.basketId;
        let from = session.id ? StM.SessionInfoFromTypes.calendar : StM.SessionInfoFromTypes.basket;
        this.props.onBadgeClick(from, id);
    }    
}
