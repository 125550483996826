import './lessonBadge.scss';
import * as React from 'react';
import ClassNames from 'classnames';
import * as PolM from '../../../../../policies';
import * as SrvM from '../../../../../services';
import { IBaseBadgeProps, DurationClasses, CommonBadge } from './baseBadge';

interface ILessonBadgeProps extends IBaseBadgeProps { }

interface ILessonBadgeState { }

export class LessonBadge extends React.Component<ILessonBadgeProps, ILessonBadgeState> {
    private utils = new SrvM.Utils();
    constructor(props: ILessonBadgeProps) {
        super(props);
    }

    render() {
        const sessionInfoPolicy = new PolM.SessionInfoPolicy(this.props.time.session);
        const players = sessionInfoPolicy.getPlayers();
        const coach = this.props.time.session && this.props.time.session.trainer
            ? this.props.time.session.trainer.displayName
            : ''
            ;
        const durationClass = DurationClasses.getDurationClass(this.props.time.duration);
        const classes = ClassNames('table-dashboard-column-item-wrapper', 'lesson', 'with-coach-dashboard', durationClass
            , { 
                'many-players': this.props.time.duration === 30 && players.length > 2,
                'has-video': this.utils.hasVideo(this.props.time.session) 
            }
        );
        const renderedPlayers = CommonBadge.renderPlayers(this.props.time.session, this.props.time);
        return (
            <div className={classes}>
                <div className="table-dashboard-column-item">
                    <div className="name-coach-dashboard-wrapper">
                        <div className="name-dashboard-wrapper">
                            <div className="type-lesson-dashboard">
                                <span className="type-title">Lesson</span>
                                {CommonBadge.getRenderVideoIcon(this.props.time.session)}
                            </div>
                            <div className="name-lesson-dashboard"></div>
                        </div>
                        {coach && <div className="coach-dashboard-wrapper">{coach}</div>}
                    </div>
                    {renderedPlayers}
                </div>
            </div>
        );
    }
};
