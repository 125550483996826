import * as React from 'react';

import { CommonBadge } from './baseBadge';

interface ICourtShiftBadgeProps {
    offset: number;
}

export const CourtShiftBadge = (props: ICourtShiftBadgeProps) => {
    if (!props.offset) return null;
    const style: React.CSSProperties = {
        height: `${CommonBadge.getBadgeHeightByDuration(props.offset)}px`
    };

    return (
        <div style={style} className='table-dashboard-column-item-wrapper'>
            <div className='table-dashboard-column-item' />
        </div>
    );
};
