import * as React from 'react';

import { StM, SrvM } from '../../modules';

interface IBookPackageDialogItemProps {
    item: StM.IPackageDefinitionStoreState;

    removeItem: (id: number) => void;
}

export class BookPackageDialogItem extends React.Component<IBookPackageDialogItemProps> {
    private utils: SrvM.Utils = new SrvM.Utils();

    constructor(props: IBookPackageDialogItemProps) {
        super(props);
    }
    
    public render() {
        let hasErrors = this.props.item.validationResult && this.props.item.validationResult.errors && this.props.item.validationResult.errors.length;
        let hasWarnings = this.props.item.validationResult && this.props.item.validationResult.warnings && this.props.item.validationResult.warnings.length;

        let errors: any;
        let warnings: any;
        let validationMessages: any;

        if(hasErrors) errors = this.renderErrors(this.props.item.validationResult.errors);
        if(hasWarnings) warnings = this.renderWarnings(this.props.item.validationResult.warnings)

        if(hasErrors || hasWarnings) validationMessages = (<div className="validation-wrapper">{errors}{warnings}</div>);

        const price = this.utils.handleMinPrice(this.props.item.price);
        const handledPrice = this.utils.formatStringPrice(price.toString());

        return(
            <li className={"estimated-charges-list-item"
            + (hasErrors ? " validation-error" : "")
            + (hasWarnings ? " validation-warning" : "")
            }>
                <div className="estimated-charges-date">
                </div>
                <div className="estimated-charges-list-item-content">
                    <div className="time-wrapper">
                        <div className="time-start-wrapper">
                            <div className="time-start"></div>
                            <div className="time-start-time-type"></div>
                        </div>
                        <div className="time-divider"></div>
                        <div className="time-end-wrapper">
                            <div className="time-end"></div>
                            <div className="time-end-time-type"></div>
                        </div>
                    </div>
                    <div className="info-wrapper">
                        <div className="session-name">{this.props.item.title}</div>
                        <div className="court-info"><div className="court-wrapper">PACKAGE</div></div>
                    </div>
                    <div className="credit-price-delete-wrapper">
                        <div className="price-delete-wrapper">
                            <div className="price-wrapper">{'$' + handledPrice}</div>
                            <div className="delete-wrapper" onClick={(e) => this.removeItemClick(e)}></div>
                        </div>  
                    </div>
                </div>
                {validationMessages}
            </li>
        )
    }

    private renderErrors(errors: Array<IValidationMessageDto>) {
         return errors.map((error: IValidationMessageDto, index: number) => (
           <div className="validation-error" key={index}>{error.message}</div>
        ));
    }

    private renderWarnings(warnings: Array<IValidationMessageDto>) {
         return warnings.map((warning: IValidationMessageDto, index: number) => (
           <div className="validation-warning" key={index}>{warning.message}</div>
        ));
    }

    private removeItemClick(e: any) {
        if(e) {e.preventDefault(); e.stopPropagation()};
        this.props.removeItem(this.props.item.id);
    }
}
export default BookPackageDialogItem;