import * as React from 'react';
import moment from 'moment';
import ClassNames from 'classnames';
import * as StM from '../../../../../models/store';

interface IAvailableBadgeProps {
    date: moment.Moment;
    start: moment.Duration;
    user: StM.IUserStoreState;
    durationMins: number;
    isAvailable: boolean;
    isSelected: boolean;
    isAvailabilityMode: boolean;

    setAvailabilityClick?: (timeItem: StM.ICoachAvailabilityTimeItem) => void;
}

interface IAvailableBadgeState {
}


export default class AvailableBadgeMobile extends React.Component<IAvailableBadgeProps, IAvailableBadgeState> {
    constructor(props: IAvailableBadgeProps) {
        super(props);
    }

    render() {
        const start = moment({ hours: this.props.start.hours(), minutes: this.props.start.minutes() });
        const timeBlockClasses = ClassNames('time-range-block-mobile available-block', {
            'available': this.props.isAvailable,
            'selected': this.props.isSelected
        });
        return (
            <div className={timeBlockClasses} onClick={(e) => this.onClick(e)}>
                <div className="block-wrapper">
                    <span className="time-wrapper">{start.format('h:mm')}</span>
                </div>
            </div>
        );
    };

    private onClick(e: any) {
        if (this.props.setAvailabilityClick) {
            const startTime = this.props.start;
            const time = !this.props.isAvailabilityMode ? startTime : moment.duration(startTime.asMilliseconds());
            const startAvailabilityTime = this.props.date.clone().set({ hours: time.hours(), minutes: time.minutes() });
            const endAvailabilityTime = startAvailabilityTime.clone().add(this.props.durationMins, 'minutes');
            this.props.setAvailabilityClick({
                startTime: startAvailabilityTime,
                rect: null,
                endTime: endAvailabilityTime
            });
        }
    }
};
