import moment from 'moment';

import { ThunkService } from '../../services/thunkService';
import * as StM from '../../models/store';

export class HistoryPageActions {
    public static init(start: moment.Moment, end: moment.Moment): (dispatch: any, getState: () => StM.IGlobalStoreState) => Promise<any> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState) => {
            dispatch({ type: 'Pages/History/Init', payload: null });
            
            if(!getState().app.isAuthorized) return Promise.resolve();
            return Promise.resolve(dispatch(HistoryPageActions.getPaymentHistory(start, end)));
        };
    }

    public static getPaymentHistory(start: moment.Moment, end: moment.Moment): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<any>{
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            const club = getState().club;
            return thunkService.api.user.getUserPayments(start, end, club)
                .then((userPayments: Array<StM.IUserPaymentStoreState>) => {
                    return dispatch({ type: 'Pages/History/Payments', payload: userPayments });
                });
        };
    }
}
