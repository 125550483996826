import './button-group.scss';
import React from 'react';

type ButtonGroupValue = string;
type ButtonConfig = { label: string, value: ButtonGroupValue };
type ButtonGroupProps = { value: ButtonGroupValue, select: (v: ButtonGroupValue) => void, config: ButtonConfig[] };

const ButtonGroup: React.FC<ButtonGroupProps> = (props: ButtonGroupProps) => {
    const createButton = (buttonConfig: ButtonConfig, value: ButtonGroupValue) =>
        (<button className={`btn sof-btn-group-item ${buttonConfig.value === value && 'sof-btn-group-item-active' || ''}`}
            onClick={() => props.select(buttonConfig.value)}
            key={buttonConfig.value}
        >{buttonConfig.label}
        </button>)

    return (
        <div className="sof-button-group">
            {(props.config || []).map((v) => createButton(v, props.value))}
        </div>
    )
}

export default ButtonGroup;
