import * as React from 'react';
import { compose, Dispatch } from 'redux';
import { connect } from 'react-redux';

import * as StM from '../../../../models/store';
import * as ActM from '../../../../actions';
import * as SrvM from '../../../../services';

import { IMemberItem } from '../../../../components/pages/user/groups/groupMembersGrid';

export interface IMembersPageProps {
    adminUserId: string;
    group: StM.IGroupStoreState;

    onInviteMemberBtnClick?: (e: any) => void;
    onRemoveMember?: (member: IMemberItem) => void;
    onResendInvite?: (member: IMemberItem) => void;
    onSwitchAdminAccess?: (member: IMemberItem) => void;
}

interface IBaseMembersPageProps {
    isAuthorized: boolean;
    isFinalized: boolean;
    adminUserId: string;
    user: StM.IUserStoreState;
    group: StM.IGroupStoreState;

    openInviteMemberToGroupDialog: (groupId: number) => void;
    showConfirm: (msg: string, yesCallback: () => void, noCallback: () => void, yesBtnTxt?: string, noBtnTxt?: string, showExitBtn?: boolean) => Promise<any>;
    closeConfirm: () => Promise<any>;
    updateGroup: (group: StM.IGroupStoreState) => Promise<StM.IGroupStoreState>;
    closeAlert: () => void;
    resendInvitation: (memberId: number) => Promise<void>;
}

const withBaseMembersPage = (Component: React.ComponentType<IMembersPageProps>) => {
    return class BaseMembersPage extends React.Component<IBaseMembersPageProps> {
        private authSrv = new SrvM.AuthenticationService();
        constructor(props: IBaseMembersPageProps) {
            super(props);
        }

        public render() {
            if(!this.props.group || !this.props.group.id) return null;
            const isGroupAccount = this.authSrv.isInRole(StM.Roles.GroupAccount, this.props.user);
           
            if (!this.props.isAuthorized || !this.props.isFinalized || !isGroupAccount) {
                return (
                    <div className="main-content-wrapper profile-pages groups-page">
                        <h2 className="page-title">Group Members</h2>
                        <p>{StM.Strings.UnathorizedAccessTitle}</p>
                    </div>
                );
            }
            return (
                <Component 
                    group={this.props.group}
                    adminUserId={this.props.adminUserId}
                    onInviteMemberBtnClick={(e) => this.handleInviteMemberBtnClick(e)}
                    onRemoveMember={(member) => this.handleRemoveMember(member)}
                    onResendInvite={(member) => this.handleResendInvite(member)}
                    onSwitchAdminAccess={(member) => this.handleSwitchAdminAccess(member)}
                />
            );
        }

        private handleInviteMemberBtnClick(e: any) {
            if(e) e.preventDefault();
            this.props.openInviteMemberToGroupDialog(this.props.group.id);
        }

        private handleResendInvite(member: IMemberItem) {
            this.props.resendInvitation(member.id);
        }

        private handleRemoveMember(member: IMemberItem) {
            this.props.showConfirm(
                'Remove from the group.'
                , () => {
                    const group = {
                        ...this.props.group,
                        members: this.props.group.members.map((item) => {
                            return {
                                ...item,
                                disabled: item.id === member.id
                            }
                        })
                    } as StM.IGroupStoreState;
                    this.props.updateGroup(group)
                        .then(() => {
                            this.props.closeConfirm();
                        });
                    this.props.closeConfirm();
                }, () => this.props.closeConfirm()
                , 'Remove'
                , 'Keep');
        }

        private handleSwitchAdminAccess(member: IMemberItem) {
            this.props.showConfirm(
                `${member.isAdmin ? StM.Strings.RemoveAdminStatus : StM.Strings.MakeGroupAdmin}?`
                , () => {
                    const group = {
                        ...this.props.group,
                        members: this.props.group.members.map((item) => {
                            return {
                                ...item,
                                canLoginAsGroupAccount: item.id === member.id ? !item.canLoginAsGroupAccount : item.canLoginAsGroupAccount
                            }
                        })
                    } as StM.IGroupStoreState;
                    this.props.updateGroup(group)
                        .then(() => {
                            this.props.closeConfirm();
                        });
                    this.props.closeConfirm();
                }, () => this.props.closeConfirm()
                , 'Yes'
                , 'No');
        }
    }
}

const mapStateToProps = (state: StM.IGlobalStoreState, ownProps: any) => ({
    isAuthorized: state.app.isAuthorized
    , isFinalized: state.app.isFinalized
    , adminUserId: state.authenticationTicket.selfId
    , user: state.user
    , group: state.user ? state.user.group : null,
});

const mapDispatchToProps = (dispatch: any) => ({
    openInviteMemberToGroupDialog: (groupId: number) => dispatch(ActM.DialogActions.open(StM.DialogNames.InviteGroupMember, { groupId })),
    showConfirm: (msg: string, yesCallback: () => void, noCallback: () => void, yesBtnTxt?: string, noBtnTxt?: string, showExitBtn?: boolean) =>
        dispatch(ActM.DialogActions.open(StM.DialogNames.Confirm, { msg, yesCallback, noCallback, yesBtnTxt, noBtnTxt, showExitBtn })),
    closeConfirm: () => dispatch(ActM.DialogActions.close(StM.DialogNames.Confirm)),
    updateGroup: (group: StM.IGroupStoreState) => dispatch(ActM.GroupAccountActions.update(group)),
    closeAlert: () => dispatch(ActM.DialogActions.close(StM.DialogNames.Alert)),
    resendInvitation: (memberId: number) => dispatch(ActM.GroupAccountActions.resendInvitation(memberId)),
});

export const withMembersPage = compose(connect(mapStateToProps, mapDispatchToProps), withBaseMembersPage);