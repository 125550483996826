import './dialogs.scss';
import './confirmDialog.scss';
import ClassNames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';
import * as StM from '../../models/store';
import * as ActM from '../../actions';
import { Modal, ModalFooter, ModalBody, ModalHeader } from '../../components';

interface IConfirmDialogProps {
    isShown: boolean;
    msg: string;
    yesBtnTxt: string;
    noBtnTxt: string;
    showExitBtn: boolean;

    yesBtnClick: () => void;
    noBtnClick: () => void;
    closeDialog: () => void;
}

interface IConfirmDialogState { }

class ConfirmDialog extends React.Component<IConfirmDialogProps, IConfirmDialogState> {
    constructor(props: IConfirmDialogProps) {
        super(props);
    }

    render() {
        if (this.props.isShown) {
            const rootClasses = ClassNames('confirm-dialog');
            return (
                <Modal 
                    classes={rootClasses} 
                    ref={(elem) => this.setPositionConfirmDialog(elem)} 
                    closeHandler={this.props.closeDialog} 
                    dialogName={StM.DialogNames.Confirm} 
                    customZIndex={1000}
                >
                    <ModalHeader title={this.props.msg} />
                    <ModalBody className='modal-dialog-body'/>
                        <ModalFooter className="btns-wrapper">
                                <button className='btn btn-black' onClick={(e) => this.onNoBtnClick(e)}>
                                    {this.props.noBtnTxt || 'No'}
                                </button>
                                <button className='btn btn-red' onClick={(e) => this.onYesBtnClick(e)}>
                                    {this.props.yesBtnTxt || 'Yes'}
                                </button>
                        </ModalFooter>
                </Modal>
            );
        } else {
            return null;
        }
    }

    private onYesBtnClick(e: any) {
        if (e) { e.preventDefault(); e.stopPropagation(); }
        this.props.yesBtnClick();
    }

    private onNoBtnClick(e: any) {
        if (e) { e.preventDefault(); e.stopPropagation(); }
        this.props.noBtnClick();
    }

    private setPositionConfirmDialog = (elem: any) => {
        const windowHeight: any = document.body.clientHeight;
        const dialog: any = document.getElementsByClassName('confirm-dialog')[0];
        const dialogHeight: any = dialog.offsetHeight;
        const topPosition = (windowHeight - dialogHeight) / 2;
        dialog.style.top = topPosition + 'px';
    }
}

const mapStateToProps = (state: StM.IGlobalStoreState, ownProps: any) => {
    const dialog = state.dialogs.confirmDlg;
    return {
        isShown: dialog.isOpened,
        msg: dialog.msg,
        yesBtnTxt: dialog.yesBtnTxt,
        noBtnTxt: dialog.noBtnTxt,
        yesBtnClick: dialog.yesCallback,
        noBtnClick: dialog.noCallback,
        showExitBtn: dialog.showExitBtn
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        closeDialog: () => dispatch(ActM.DialogActions.close(StM.DialogNames.Confirm)),
    };

};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmDialog);
