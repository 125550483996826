import './mySessionsPage.scss';

import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from "moment";
import * as _ from 'lodash';

import * as StM from '../../../models/store';
import * as ActM from '../../../actions';
import * as SrvM from '../../../services';
import BigSessionInfoItem from '../../../components/pages/bigSessionInfoItem';
import Constants from '../../../constants';
import { InputSearch } from "../../../components";

import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';

interface IMySessionsPageProps {
    mySessions: Array<StM.ISessionStoreState>;
    onSessionInfoClick: (from: string, id: number) => void;
    user: StM.IUserStoreState;
    isAuth: boolean;
    isLoading: boolean;
    club: StM.IClubStoreState;
    coachFeeTiers: Array<StM.ICoachFeeTierStoreState>;
    coaches: Array<StM.ICoachStoreState>;
    basket: StM.IBasketStoreState;
    start: moment.Moment;
    end: moment.Moment;

    init: (start: moment.Moment, end: moment.Moment) => Promise<any>;
    openAuthDialog: () => void;
    go: (url: string) => any;
    location: any;
}

interface IMySessionsPageState {
    isSessionsLoading: boolean;
    periodKey?: number;
    filterStartDate: moment.Moment;
    filterEndDate: moment.Moment;
}

interface IMySessionsDayItem {
    date: moment.Moment;
    title: string;
    array: Array<StM.ISessionStoreState>;
}

class MySessionsPage extends React.Component<IMySessionsPageProps, IMySessionsPageState> {
    private routeService: SrvM.RouteService;
    private authSrv = new SrvM.AuthenticationService();
    periodList: Array<any> = [
        {
            key: 1,
            value: StM.HistoryPeriods.Month
        },
        {
            key: 2,
            value: StM.HistoryPeriods.NextMonth
        },
        {
            key: 3,
            value: StM.HistoryPeriods.Any,
            calendarShow: true
        }
    ];
    constructor(props: IMySessionsPageProps) {
        super(props);
        this.routeService = new SrvM.RouteService();
        this.state = {
            isSessionsLoading: false,
            periodKey: 2,
            filterStartDate: moment(),
            filterEndDate: moment()
        };
    }

    componentDidMount() {
        if (this.props.club.id) {
            this.updateSessions(this.props.start, this.props.end);
        }
        this.setState({
            filterStartDate: this.props.start.clone(),
            filterEndDate: this.props.end.clone()
        });
    }

    componentDidUpdate(prevProps: IMySessionsPageProps) {
        const isAuthChanged = !prevProps.isAuth && this.props.isAuth;

        if (prevProps.isAuth && !this.props.isAuth) {
            const bookTodayRoute = this.routeService.getBookTodayRoute();
            this.props.go(bookTodayRoute);
        } else if (this.props.club.id && (isAuthChanged || !prevProps.club.id)) {
            this.updateSessions(this.props.start, this.props.end);
        }

        const isStartChanged = !prevProps.start.isSame(this.props.start);
        const isEndChanged = !prevProps.end.isSame(this.props.end);

        if (isStartChanged || isEndChanged) {
            this.setState({
                filterStartDate: this.props.start.clone(),
                filterEndDate: this.props.end.clone()
            });
        }
    }

    shouldComponentUpdate(nextProps: IMySessionsPageProps, nextState: IMySessionsPageState): boolean {
        const isLoadingChanged = this.state.isSessionsLoading !== nextState.isSessionsLoading;
        const isSessionsChanged = !_.isEqual(this.props.mySessions, nextProps.mySessions);
        const isStartChanged = !this.props.start.isSame(nextProps.start);
        const isEndChanged = !this.props.end.isSame(nextProps.end);
        const isUserChanged = !_.isEqual(this.props.user, nextProps.user);
        const isClubChanged = !_.isEqual(this.props.club, nextProps.club);
        const isPeriodKeyChanged = !_.isEqual(this.state.periodKey, nextState.periodKey);
        const isFilterStartChange = !this.state.filterStartDate.isSame(nextState.filterStartDate);
        const isFilterEndChange = !this.state.filterEndDate.isSame(nextState.filterEndDate);
        return isSessionsChanged || isStartChanged || isEndChanged || isUserChanged
            || isClubChanged || isPeriodKeyChanged || isFilterStartChange || isFilterEndChange || isLoadingChanged;
    }

    render() {
        let daysList = this.getDaysList(this.props.mySessions);
        let selectedPeriod = this.state.periodKey || 2;
        let period = _.find(this.periodList, (i) => i.key === selectedPeriod);
        const ownerPrefix = this.authSrv.getOwnerPrefix(this.props.user);
        return (
            <div className="main-content-wrapper my-session-page">
                <div className="page-title">{ownerPrefix} Sessions</div>
                {this.renderPeriodHeader(period, this.state.filterStartDate.clone(), this.state.filterEndDate.clone())}
                {this.props.isAuth
                    ? this.state.isSessionsLoading ? (<p> Loading, please wait </p>) : (this.renderDayList(daysList))
                    : (<p>Please login to continue</p>)
                }
            </div>
        )
    }

    renderDayList(list: Array<IMySessionsDayItem>) {
        if (!list.length) {
            return (
                <p>No sessions. <a href="#" onClick={(e) => { this.onBookPageClick(e) }}>Fire up your game</a></p>
            )
        }
        return (<div className="days-wrapper">{list.map((item, index) => {
            let weekday = item.date.format('dddd'),
                month = item.date.format('MMM'),
                date = item.date.format('DD');
            return (
                <div className="day-item" key={index}>
                    <div className="title-wrapper">
                        <div className="day-of-week">{weekday}</div>
                        <span className="date">{date}</span>
                        <span className="month">{month}</span>
                    </div>
                    <div className="session-wrapper">
                        {item.array.map((session, index) => {
                            return <BigSessionInfoItem
                                session={session}
                                user={this.props.user}
                                basket={this.props.basket}
                                coaches={this.props.coaches}
                                coachFeeTiers={this.props.coachFeeTiers}
                                key={index}
                                club={this.props.club}
                                infoClick={this.props.onSessionInfoClick} />
                        })}
                    </div>
                </div>
            );
        })}
        </div>);
    }

    private renderPeriodHeader(period: any, start: moment.Moment, end: moment.Moment) {
        if (period && period.calendarShow) {
            return (
                <div className="filter-history-wrapper">
                    <InputSearch placeholder="Search"
                        classes="period-select-wrapper"
                        selected={period.key}
                        array={this.periodList}
                        readOnly={true}
                        onSelectChange={(item) => this.updateSelectedPeriod(item)} />
                    <DatePicker
                        locale="en-US"
                        format={Constants.DateTime.DATEPICKER_FORMAT}
                        value={start.toDate()}
                        nextLabel={(<svg height="24" viewBox="0 0 24 24" width="24"><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg>)}
                        next2Label={(<div><svg height="24" viewBox="0 0 24 24" width="24" style={{ marginRight: -18 }}><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg><svg height="24" viewBox="0 0 24 24" width="24"><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path></svg></div>)}
                        prevLabel={(<svg height="24" viewBox="0 0 24 24" width="24"><path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg>)}
                        prev2Label={(<div><svg height="24" viewBox="0 0 24 24" style={{ marginRight: -18 }} width="24"><path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg><svg height="24" viewBox="0 0 24 24" width="24"><path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg></div>)}
                        onChange={(date: Date) => this.startDateHandler(date)}
                        maxDate={Constants.MaxDate}
                    />
                    <div className="divider-wrapper">
                        <div className="divider"></div>
                    </div>
                    <DatePicker
                        locale="en-US"
                        format={Constants.DateTime.DATEPICKER_FORMAT}
                        value={end.toDate()}
                        nextLabel={(<svg height="24" viewBox="0 0 24 24" width="24"><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg>)}
                        next2Label={(<div><svg height="24" viewBox="0 0 24 24" width="24" style={{ marginRight: -18 }}><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg><svg height="24" viewBox="0 0 24 24" width="24"><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path></svg></div>)}
                        prevLabel={(<svg height="24" viewBox="0 0 24 24" width="24"><path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg>)}
                        prev2Label={(<div><svg height="24" viewBox="0 0 24 24" style={{ marginRight: -18 }} width="24"><path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg><svg height="24" viewBox="0 0 24 24" width="24"><path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg></div>)}
                        onChange={(date: Date) => this.endDateHandler(date)}
                        maxDate={Constants.MaxDate}
                    />
                    <div className="button-apply-wrapper">
                        <div className="btn btn-apply" onClick={(e) => this.updatePeriodClick(e)}>apply</div>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="filter-history-wrapper">
                    <InputSearch placeholder="Search"
                        classes="period-select-wrapper"
                        selected={period.key}
                        array={this.periodList}
                        readOnly={true}
                        onSelectChange={(item) => this.updateSelectedPeriod(item)} />
                </div>
            )
        }
    }

    private updateSessions(start: moment.Moment, end: moment.Moment): Promise<any> {
        if (this.state.isSessionsLoading) return;
        this.setState({ isSessionsLoading: true });
        var that = this;
        return this.props.init(start, end)
            .then(() => {
                if (!that.props.user.id && !that.routeService.isInvitationWithoutLoginLink(this.props.location.hash)) {
                    that.props.openAuthDialog();
                }
                that.setState({ isSessionsLoading: false });
            }).catch(() => {
                that.setState({ isSessionsLoading: false });
            });
    }

    getDate(date: moment.Moment) {
        return date.clone().startOf('day');
    }

    getDaysList(sessions: Array<StM.ISessionStoreState>) {
        let result: Array<IMySessionsDayItem> = [];
        for (let i = 0; i < sessions.length; i++) {
            let session = sessions[i];
            let date = this.getDate(session.startDateTime);
            let isInArray = false;
            for (let j = 0; j < result.length; j++) {
                let dayItem = result[j];
                if (date.isSame(dayItem.date)) {
                    isInArray = true;
                    dayItem.array.push(session);
                    break;
                }
            }
            if (!isInArray) {
                result.push({ date: date, array: [session], title: date.format() });
            }
        }
        return result;
    }

    private updateSelectedPeriod(item: any) {
        const utils = new SrvM.Utils();
        let date = utils.getCurrentClubDateTime(this.props.club);
        let startDate = moment(this.props.start);
        let endDate = moment(this.props.end);
        switch (item.value) {
            case StM.HistoryPeriods.NextMonth: {
                startDate = date.clone();
                endDate = date.clone().endOf('day').add(1, 'month');
                this.updateRoute(startDate, endDate);
                break;
            }
            case StM.HistoryPeriods.Month: {
                endDate = date.clone().endOf('day');
                startDate = date.clone().add(-1, 'month');
                this.updateRoute(startDate, endDate);
                break;
            }
        }
        this.setState({
            periodKey: item.key,
            filterStartDate: startDate.clone(),
            filterEndDate: endDate.clone()
        });
    }

    private endDateHandler(date: Date) {
        this.setState({ filterEndDate: moment(date).endOf('day') });
    }

    private startDateHandler(date: Date) {
        this.setState({ filterStartDate: moment(date) });
    }

    private onBookPageClick(e) {
        if (e) { e.preventDefault(); e.stopPropagation(); }
        this.props.go(this.routeService.getDefaultPage());
    }

    private updatePeriodClick(e: any) {
        if (e) e.stopPropagation();
        let isValid = this.validPeriod(this.state.filterStartDate, this.state.filterEndDate);
        if (isValid) {
            this.updateRoute(this.state.filterStartDate, this.state.filterEndDate);
        } else {
            this.setState({
                filterStartDate: this.props.start.clone(),
                filterEndDate: this.props.end.clone()
            });
            this.forceUpdate();
        }
    }

    private validPeriod(start: moment.Moment, end: moment.Moment) {
        let endIsNotBefore = end.isAfter(start) || start.isSame(end);
        if (!endIsNotBefore) return false;
        return true;
    }

    private updateRoute(start: moment.Moment, end: moment.Moment) {
        let url = `${StM.Pages.MySessions}/${start.format(Constants.DateTime.DATE_FORMAT)}/${end.format(Constants.DateTime.DATE_FORMAT)}`;
        this.props.go(url);
        this.props.init(start, end);
    }
};

function mapStateToProps(state: StM.IGlobalStoreState, ownProps: any) {
    return {
        mySessions: state.pages.mySessions.sessions,
        user: state.user,
        isAuth: state.app.isAuthorized,
        isLoading: state.app.isLoading,
        coachFeeTiers: state.coachFeeTiers,
        basket: state.basket,
        coaches: state.coaches,
        club: state.club,
        start: moment(ownProps.match.params.start, Constants.DateTime.DATE_FORMAT),
        end: moment(ownProps.match.params.end, Constants.DateTime.DATE_FORMAT),
        location: ownProps.location
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        init: (start: moment.Moment, end: moment.Moment) => dispatch(ActM.MySessionsPageActions.init(start, end))
        , onSessionInfoClick: (from: string, id: number) => dispatch(ActM.DialogActions.open(StM.DialogNames.SessionInfo, { from: from, id: id }))
        , openAuthDialog: () => dispatch(ActM.DialogActions.open(StM.DialogNames.Auth, { tab: StM.AuthDialogTabs.SignIn }))
        , go: (url: string) => dispatch(ActM.RouteActions.replace(url))
    }
}

const connectedMySessionsPage = connect(mapStateToProps, mapDispatchToProps)(MySessionsPage);
const MySessionsPageController = withRouter(connectedMySessionsPage);
export default MySessionsPageController;