import './landing.mobile.scss';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { StM, SrvM } from '../../modules';

export interface ILandingMobileProps {
    isAuthorized: boolean;
}


export class LandingMobile extends React.Component<ILandingMobileProps> {
    private routeService = new SrvM.RouteService();
    constructor(props: ILandingMobileProps) {
        super(props);
    }

    public render() {
        return (
            <div className="landing-page">
                <div className="mobile-row">
                    <div className="mobile-col-12">
                        {this.props.isAuthorized &&
                            (<Link to={StM.Pages.MySessions} className="btn-view-session btn-mobile">View My Sessions</Link>)
                        }
                        <h3>Book sessions</h3>
                        <Link to={this.getRoute(StM.BookPageSessionType.Play)} className="btn-play btn-mobile">Play</Link>
                        <Link to={this.getRoute(StM.BookPageSessionType.Lesson)} className="btn-lesson btn-mobile">Lesson</Link>
                        <Link to={this.getRoute(StM.BookPageSessionType.Clinic)} className="btn-clinic btn-mobile">Clinic</Link>
                        <Link to={this.getRoute(StM.BookPageSessionType.Custom)} className="btn-join-session btn-mobile">Other Sessions</Link>
                    </div>
                </div>
            </div>
        )
    }

    private getRoute(sessionType: string) {
        const handledFilter: StM.IBookPageRouteParams = {
            sessionType
            , date: null
            , playSubfilter: null
            , lessonSubfilter: null
            , lessonSubfilterId: null
            , clinicSubfilter: null
            , clinicSubfilterId: null
            , otherSubfilter: null
            , memberId: null
        };
        return this.routeService.getBookPage(handledFilter).path;
    }
}

function mapStateToProps(state: StM.IGlobalStoreState, ownProps: any) {
    return {
        isAuthorized: state.app.isAuthorized
    }
}

export default connect(mapStateToProps)(LandingMobile);