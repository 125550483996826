import './footer.scss';
import * as React from 'react';
import moment from 'moment';

import * as StM from '../../../models/store';

interface IFooterProps {
    showLogo?: boolean;
    club: StM.IClubStoreState;
}

interface IFooterState { }

export class Footer extends React.Component<IFooterProps, IFooterState> {
    constructor(props: IFooterProps) {
        super(props);
    }

    render() {
        const club = this.props.club;
        const year = moment().year();
        return ([
            <div key="footer-content" className="footer-content"></div>
            ,
            <div key="logo" className="logo">
                <a href={club.url} target="_blank">
                    <span className="copyright">&copy; {club.title} {year}</span>
                </a>
            </div>
            ,
            <div key="footer-content-wrapper" className="footer-content-wrapper">
                <div className="links-wrapper">
                    <ul>
                        <li><a href="https://squashonfire.com/newplayers.html" target="_blank">New Players</a></li>
                        <li><a href="https://squashonfire.com/adults.html" target="_blank">Adults</a></li>
                        <li><a href="https://squashonfire.com/juniors.html" target="_blank">Juniors</a></li>
                        <li><a href="https://squashonfire.com/team.html" target="_blank">Our Team</a></li>
                        <li><a href="http://upperwestsidecafe.com/" target="_blank">Our Cafe</a></li>
                        <li><a href="https://sof.advantagebooking.com/" target="_blank" className="underlined">Book now</a></li>
                    </ul>
                </div>
                <div className="contacts">
                    <div className="social-links">
                        <a className="social-link instagram" href="https://www.instagram.com/squashonfire/" target="_blank"></a>
                        <a className="social-link twitter" href="https://twitter.com/squashonfire/" target="_blank"></a>
                        <a className="social-link facebook" href="https://www.facebook.com/squashonfire/" target="_blank"></a>
                    </div>
                </div>
            </div>
        ])
    };
};