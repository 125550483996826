import * as React from 'react';

import { Modal, ModalHeader, FormError, Field, ImageUploader, ModalBody, ModalFooter } from '../../../../components';
import * as StM from '../../../../models/store';
import * as BD from './baseCreateGroupAccountDialog';
import { Images } from '../../../../constants';

interface ICreateGroupAccountDialogProps extends BD.ICreateGroupAccountDialogProps {}

class CreateGroupAccountDialog extends React.Component<ICreateGroupAccountDialogProps> {
    constructor(props: ICreateGroupAccountDialogProps) {
        super(props);
    }

    public render() {
        if(!this.props.isOpened) return null;
        const errors = this.props.errors;
        let imageUrl = this.props.imagePreview || '';
        return (
            <Modal size='small' closeHandler={(e) => this.props.onCloseDialog(e)} dialogName={StM.DialogNames.CreateGroupAccount}>
                <div className="modal-dialog dialog">
                    <ModalHeader title="Create Group" onCloseDialog={(e) => this.props.onCloseDialog(e)} />
                    <ModalBody>
                        <section>
                            <ImageUploader url={imageUrl} onDrop={(acceptedFiles, rejectedFiles) => this.props.onDrop(acceptedFiles, rejectedFiles)} />
                        </section>
                        <section>
                            <div className="inputs-wrapper">
                                <Field name="title"
                                    label="Group Name"
                                    type="text"
                                    maxLength={128}
                                    value={this.props.group.title}
                                    error={errors && errors.title}
                                    autoComplete='off'
                                    onFieldChange={(name, value) => this.props.onFieldChange(name, value)}
                                />
                            </div>
                        </section>
                    </ModalBody>
                    <ModalFooter className='btns-wrapper' errors={errors}>
                        <button className="btn btn-red" onClick={e => this.props.onSave(e)} title='Save'>Save</button>
                    </ModalFooter>
                </div>
            </Modal>
        );
    }
}

export default BD.withCreateGroupAccountDialog(CreateGroupAccountDialog);