import './baseBadge.mobile.scss';
import './priceBadge.mobile.scss';
import * as React from 'react';
import moment from 'moment';
import * as StM from '../../../../models/store';
import * as PolM from '../../../../policies';
import {IBaseBadgeProps, CommonBadgeMobile} from './baseBadge.mobile';
import * as SrvM from '../../../../services';
import * as _ from 'lodash';

let utils = new SrvM.Utils();

interface IPriceBadgeBadgeMobileProps extends IBaseBadgeProps {
    user?: StM.IUserStoreState;
    courts: Array<StM.ICourtStoreState>;
    sessionType: string;
    courtTimeBlocks: Array<StM.ICourtTimeBlockStoreState>;
    expandedSlotTimeKey: string;
    timeKey: string;
    isEventShow?: boolean;
    coaches: Array<StM.ICoachStoreState>;
    coachFeeTiers: Array<StM.ICoachFeeTierStoreState>;
    params: StM.IBookPageRouteParams;
    onCreateSessionClick: (courtId: number, timeKey: string) => any;
    onExpandSlot: (timeKey: string) => any;
    onExpandHandle?: (isExpanded: boolean) => any;
}

interface IPriceBadgeMobileState { 
    isExpanded: boolean;
}

export class PriceBadgeMobile extends React.Component<IPriceBadgeBadgeMobileProps, IPriceBadgeMobileState> {
    constructor(props: IPriceBadgeBadgeMobileProps) {
        super(props);
        this.state = {
            isExpanded: !this.props.isEventShow && this.props.expandedSlotTimeKey == this.props.timeKey
        };
    }

    componentDidUpdate(prevProps: IPriceBadgeBadgeMobileProps) {
        const isSessionTypeChanged = !_.isEqual(prevProps.sessionType, this.props.sessionType);
        const isSessionsChanged = !_.isEqual(prevProps.courtTimeBlocks, this.props.courtTimeBlocks);
        const isParamsChanged = !_.isEqual(prevProps.params, this.props.params);

        if (isSessionTypeChanged || isSessionsChanged || isParamsChanged) {
            this.reset();
        }

        const shouldExpand = this.props.timeKey === this.props.expandedSlotTimeKey && !this.props.isEventShow;
        if (this.state.isExpanded !== shouldExpand) {
            this.setState({ isExpanded: shouldExpand });
        }
    }

    private reset(){
        this.setState({...this.state, isExpanded: false});
        this.props.onExpandSlot("");
    }

    render() {
        let badgePrice = utils.formatStringPrice(CommonBadgeMobile.getMinBadgePrice(this.props.courtTimeBlocks).toString());
        let badgeCredits = CommonBadgeMobile.getMinBadgeCredits(this.props.courtTimeBlocks);
        let hasCredits = CommonBadgeMobile.getIsHasCredits(this.props.courtTimeBlocks);
        return (
            this.state.isExpanded ?
            (this.getExpandedBadge()) :
            (<div className="badge-mobile badge-mobile-price" onClick={(e)=>this.onBadgeClick(e)}>
                {!hasCredits && <span className="price-mobile">${badgePrice}</span>}
                {hasCredits && <span className="credits-mobile">{badgeCredits}</span>}
            </div>)
        )
    }

    private getExpandedBadge() {
        let timeRanges = [];
        this.props.courtTimeBlocks.map(block => {
            let court = this.props.courts.filter(court => {
                return block.isAvailableTime && block.isFilter && !block.session && court.id == block.courtId
            })[0];
            if(!court) {return;}
            let timeRange = timeRanges.filter(range => {
                return block.start.asMilliseconds() == range.start.asMilliseconds() 
                    && block.end.asMilliseconds() == range.end.asMilliseconds();
            });
            if(timeRange.length > 0){
                timeRange[0].courtBlocks.push({courtBlock: block, court: court});
            } else {
                timeRanges.push({
                    start: block.start, 
                    end: block.end,
                    courtBlocks: [{courtBlock: block, court: court}]
                });
            }
        });
        return (
            <div className="badge-mobile badge-mobile-price-expanded">
                {timeRanges.sort((a,b)=>{return a.start.asMilliseconds()-b.start.asMilliseconds()})
                    .map(range => { 
                    let startTimeUtc = moment.utc(range.start.asMilliseconds());
                    let endTimeUtc = moment.utc(range.end.asMilliseconds());
                    let rangeKey = "{0}_{1}_{2}_{3}".format(startTimeUtc.format('h:mm'), startTimeUtc.format('a'),endTimeUtc.format('h:mm'),endTimeUtc.format('a'));
                    return (
                        <div key={rangeKey} className="time-block-wrapper-mobile mobile-row">
                            <div className="time-range-mobile mobile-col-12">
                                <span className="time-mobile">{startTimeUtc.format("h:mm")} </span>
                                <span className="time-format-mobile">{startTimeUtc.format("a")}</span>
                                <span> — </span>
                                <span className="time-mobile">{endTimeUtc.format("h:mm")} </span>
                                <span className="time-format-mobile">{endTimeUtc.format("a")}</span>
                            </div>
                            <div className="courts-wrapper-mobile mobile-col-12">
                                {range.courtBlocks.map(block => { 
                                    return (
                                        <div key={"court-"+block.court.id} className="court-block-mobile mobile-col-6" onClick={()=>this.onCourtClick(block)}>
                                            <div className="mobile-row">
                                                <div className="mobile-col-8 court-id-mobile">{utils.getReadableCourtTitle(block.court.title)}</div>
                                                {(!block.courtBlock.credits || PolM.PackagePolicy.HideCreditsOnCalendar) ?
                                                    (<div className="mobile-col-4 court-price-mobile">${utils.formatStringPrice(block.courtBlock.price.toString())}</div>) :
                                                    (<div className="mobile-col-4 court-credits-mobile">{block.courtBlock.credits}</div>)
                                                }
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>); 
                })}
                <div className="badge-collapse-btn-mobile mobile-row" onClick={(e) => this.onCollapseBadge(e)}></div>
            </div>);
    }

    onCourtClick(block: any) {
        this.props.onCreateSessionClick(block.court.id, block.courtBlock.key);
        if(this.props.onExpandHandle){
            this.props.onExpandHandle(false);
        }
    }

    onBadgeClick(e) {
        if(e) {e.preventDefault(); e.stopPropagation();}
        if(this.props.isEventShow && this.props.onExpandHandle){
            this.props.onExpandHandle(true);
        } else{
            this.props.onExpandSlot(this.props.timeKey);
        }
    }

    onCollapseBadge(e) {
        if(e) {e.preventDefault(); e.stopPropagation();}
        if(!this.props.isEventShow && this.props.onExpandHandle){
            this.props.onExpandHandle(false);
        } else{
            this.props.onExpandSlot("");
        }
    }
};