import '../dialogs.scss';
import './billingInfoDialog.scss';
import './billingInfoStripeDialog.scss';

import * as React from 'react';
import * as _ from 'lodash';
import { Elements, ElementsConsumer, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import {
    loadStripe,
    StripeElements,
    Stripe,
    StripeCardNumberElementOptions,
    StripeElementStyle,
    StripeCardExpiryElementOptions,
    StripeCardCvcElementOptions,
    StripeError,
} from '@stripe/stripe-js';
import ClassNames from 'classnames';

import * as StM from '../../../models/store';
import * as SrvM from '../../../services';
import * as BD from './baseBillingInfoDialog';
import { InputSearch, Modal, ModalHeader, ModalBody, ModalSection, ModalFooter, Field } from '../../../components';

const utils = new SrvM.Utils();
const routeSrv = new SrvM.RouteService();
const isMobile = utils.isMobile();

const defaultFont = 'Arial, Helvetica, sans-serif';
const fontsConfig = [
    {
        src: `url(${routeSrv.getBaseUrl()}/content/fonts/Neusa-Medium/neusa-medium.woff)`,
        family: 'Neusa-Medium',
    },
];
const elementStyle: StripeElementStyle = {
    base: {
        fontSize: isMobile ? '20px' : '17px',
        color: '#404040',
        fontFamily: `${isMobile ? 'Neusa-Medium,' : ''}${defaultFont}`,
    },
    invalid: {
        color: '#ff4242',
    },
};
const cardNumberOptions: StripeCardNumberElementOptions = {
    showIcon: true,
    style: elementStyle,
    placeholder: '',
};

const cardExpiryOptions: StripeCardExpiryElementOptions = {
    style: elementStyle,
};

const cardCvcOptions: StripeCardCvcElementOptions = {
    style: elementStyle,
    placeholder: '',
};

const BillingInfoStripeDialog = (props: BD.IBillingInfoDialogProps) => {
    if (!props.isShown) return null;
    const paymentSystem = props.club.paymentSystem;
    if (props.club.paymentSystem.type === StM.PaymentSystemType.Stripe) {
        const stripe = React.useMemo(() => loadStripe(paymentSystem.publicApiKey), [paymentSystem.publicApiKey]);
        return (
            <Elements options={{ fonts: fontsConfig }} stripe={stripe}>
                <ElementsConsumer>{({ elements, stripe }) => <BillingInfoDialog {...props} elements={elements} stripe={stripe} />}</ElementsConsumer>
            </Elements>
        );
    } else return null;
};

interface IBillingInfoDialogProps extends BD.IBillingInfoDialogProps {
    elements: StripeElements;
    stripe: Stripe;
}

interface IBillingInfoDialogState {
    cardErrors?: any;
}

class BillingInfoDialog extends React.Component<IBillingInfoDialogProps, IBillingInfoDialogState> {
    private clientSecretId?: string;
    private validator = new SrvM.ValidationService();
    constructor(props: IBillingInfoDialogProps) {
        super(props);

        this.state = {
            cardErrors: null,
        };
    }

    public render() {
        if (!this.props.isShown) return null;
        const { countries, paymentProfile, errors } = this.props;
        const { cardErrors } = this.state;
        const country = countries.find((c) => c.value === paymentProfile.billingAddress.country);
        const inputsGroupClass = ClassNames(isMobile ? 'inputs-group' : 'inputs-group-inline');

        return (
            <Modal
                classes='billing-info-modal billing-info-modal-stripe'
                closeHandler={(e) => this.handleCloseDialog(e)}
                dialogName={StM.DialogNames.BillingInfo}
            >
                <div className='modal-dialog dialog'>
                    <ModalHeader title='Billing Information' onCloseDialog={(e) => this.handleCloseDialog(e)} />
                    <ModalBody>
                        <ModalSection>
                            <BD.BillingInfoDescription />
                            <div className='billing-card-info-wrapper'>
                                <div className='inputs-wrapper'>
                                    <Field name='cardNumber' label='Card Number' id='cardNumber'>
                                        <CardNumberElement options={cardNumberOptions} onChange={() => this.handleCardFieldChange()} />
                                    </Field>
                                    <Field
                                        id='fullName'
                                        name='creditCard.fullName'
                                        type='text'
                                        label='Name (as it appears on card)'
                                        value={paymentProfile.creditCard.fullName}
                                        error={errors && errors.creditCard && errors.creditCard.fullName}
                                        onFieldChange={(name, value) => this.props.onFieldChange(name, value)}
                                    />
                                    <div className='inputs-group-inline'>
                                        <Field name='cardExpirationDate' label='Expiration Date' id='expirationDate'>
                                            <CardExpiryElement options={cardExpiryOptions} onChange={() => this.handleCardFieldChange()} />
                                        </Field>
                                        <Field name='cardCode' label='CVC' id='cvc'>
                                            <CardCvcElement options={cardCvcOptions} onChange={() => this.handleCardFieldChange()} />
                                        </Field>
                                    </div>
                                </div>
                            </div>
                            <div className='divider-line' />
                        </ModalSection>
                        <ModalSection>
                            <div className='inputs-wrapper'>
                                <Field
                                    id='billingStreet'
                                    name='billingAddress.street'
                                    type='text'
                                    label='Billing Address'
                                    error={errors && errors.billingAddress && errors.billingAddress.street}
                                    value={paymentProfile.billingAddress.street}
                                    onFieldChange={(name, value) => this.props.onFieldChange(name, value)}
                                />
                                <div className={inputsGroupClass}>
                                    <Field
                                        id='billinCity'
                                        name='billingAddress.city'
                                        type='text'
                                        label='City'
                                        error={errors && errors.billingAddress && errors.billingAddress.city}
                                        value={paymentProfile.billingAddress.city}
                                        onFieldChange={(name, value) => this.props.onFieldChange(name, value)}
                                    />
                                    <Field
                                        id='billingState'
                                        name='billingAddress.state'
                                        type='text'
                                        label='State'
                                        error={errors && errors.billingAddress && errors.billingAddress.state}
                                        value={paymentProfile.billingAddress.state}
                                        onFieldChange={(name, value) => this.props.onFieldChange(name, value)}
                                    />
                                </div>
                                <div className={inputsGroupClass}>
                                    <Field
                                        id='billingCountry'
                                        name='billingAddress.country'
                                        label='Country'
                                        error={errors && errors.billingAddress && errors.billingAddress.country}
                                    >
                                        <InputSearch
                                            array={countries}
                                            selected={country ? country.key : null}
                                            onSelectChange={(item) => this.props.onFieldChange('billingAddress.country', !!item && item.value)}
                                            placeholder='Select Country'
                                        />
                                    </Field>
                                    <Field
                                        id='billingZip'
                                        name='billingAddress.zip'
                                        type='text'
                                        label='Postal / Zip code'
                                        error={errors && errors.billingAddress && errors.billingAddress.zip}
                                        value={paymentProfile.billingAddress.zip}
                                        onFieldChange={(name, value) => this.props.onFieldChange(name, value)}
                                    />
                                </div>
                            </div>
                        </ModalSection>
                    </ModalBody>
                    <ModalFooter className='btns-wrapper' errors={cardErrors || errors}>
                        <button type='button' className='btn btn-red btn-next' onClick={(e) => this.saveCard(e)}>
                            Save
                        </button>
                        <button type='button' className='btn btn-black btn-next' onClick={(e) => this.props.onCloseDialog(e)}>
                            Cancel
                        </button>
                    </ModalFooter>
                </div>
            </Modal>
        );
    }

    private saveCard(e: any) {
        if (e) e.preventDefault();
        const { stripe, elements, errors, paymentProfile } = this.props;
        const cardElement = elements.getElement(CardNumberElement);

        const isValidForm = this.props.validateForm(false);
        if (errors) delete errors.server;

        this.props.showSpinner();
        stripe
            .createPaymentMethod({
                card: cardElement,
                type: 'card',
                billing_details: {
                    name: paymentProfile.creditCard.fullName,
                    address: {
                        city: paymentProfile.billingAddress.city,
                        country: paymentProfile.billingAddress.country,
                        postal_code: paymentProfile.billingAddress.zip,
                        state: paymentProfile.billingAddress.state,
                        line1: paymentProfile.billingAddress.street,
                    },
                },
            })
            .then(({ paymentMethod, error }) => {
                if (error || !isValidForm) {
                    this.handleCardError(isValidForm && error);
                    return;
                }
                return this.props
                    .updatePaymentProfile({
                        ...paymentProfile,
                        creditCard: {
                            ...paymentProfile.creditCard,
                            type: paymentMethod.card.brand,
                            number: paymentMethod.card.last4,
                        },
                    })
                    .then((clientSecretId) => {
                        this.props.getCurrentUser(false).then(() => {
                            this.clientSecretId = clientSecretId;
                            this.props.startCardValidation();
                            stripe
                                .confirmCardSetup(clientSecretId, {
                                    payment_method: {
                                        card: cardElement,
                                        billing_details: paymentMethod.billing_details,
                                    },
                                })
                                .then(({ setupIntent, error }) => {
                                    if (error) {
                                        this.handleCardError(error);
                                        return;
                                    }
                                    if (setupIntent && setupIntent.status === 'succeeded' && setupIntent.payment_method) {
                                        this.props
                                            .completePaymentMethod()
                                            .then(() => {
                                                this.clientSecretId = null;
                                                this.props.handleCardSave();
                                            })
                                            .catch((error) => this.props.handleCardSaveError(error));
                                    }
                                })
                                .catch((error) => this.handleCardError(error));
                        });
                    })
                    .catch((errors) => {
                        this.props.hideSpinner();
                    });
            })
            .catch((error) => this.handleCardError(error));
    }

    private handleCardFieldChange() {
        this.setState({ ...this.state, cardErrors: null });
    }

    private handleCloseDialog(e: any) {
        if (this.clientSecretId) {
            this.props.completePaymentMethod(false).then(() => {
                this.clientSecretId = null;
                this.props.getCurrentUser(false);
            });
        }
        this.props.onCloseDialog(e);
    }

    private setCardErrors(error: StripeError) {
        return this.validator.setError('server', error.message);;
    }

    private handleCardError(error: any) {
        this.props.hideSpinner();
        this.props.stopCardValidation();
        if (error) {
            this.setState({ ...this.state, cardErrors: this.setCardErrors(error) });
        }
    }
}

export default BD.withBillingInfoDialog(BillingInfoStripeDialog);
