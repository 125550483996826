import './userPage.mobile.scss';
import * as React from 'react';

import { Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as StM from '../../../models/store';
import * as ActM from '../../../actions';
import * as SrM from '../../../services';
import UserNavigationMobile from '../../../components/pages/profile/userNavigation.mobile';

import ProfilePageMobile from '../../../containers/pages/user/profile/profilePage.mobile';
import HistoryPageMobile from '../../../containers/pages/user/historyPage.mobile';
import GroupsPageMobile from './groups/groupsPage.mobile';
import GroupMemebersMobile from './groups/membersPage.mobile';
import MyPackagePageMobile from '../../../containers/pages/user/myPackages/myPackagesPage.mobile';

interface IUserPageProps {
    club: StM.IClubStoreState;
    isAuth: boolean;
    go: (url: string) => any;
    match: any;
    location: any;
    history: any;
    user: StM.IUserStoreState;
}

interface IUserPageState { }

class UserPageMobile extends React.Component<IUserPageProps, IUserPageState> {
    private routeService: SrM.RouteService;
    constructor(props: IUserPageProps) {
        super(props);
        this.routeService = new SrM.RouteService();
    }

    componentDidUpdate(prevProps: IUserPageProps) {
        if (prevProps.isAuth && !this.props.isAuth) {
            this.props.go("/");
        }
    }

    render() {
        return (
            <div className="user-pages">
                <UserNavigationMobile club={this.props.club} user={this.props.user}/>
                <Switch>
                    <Route path={'{0}/{1}'.format(StM.Pages.User, StM.UserPages.Profile)} component={ProfilePageMobile} />
                    <Route path={`${StM.Pages.User}/${StM.UserPages.History}/:start?/:end?`} component={HistoryPageMobile} />
                    <Route path={`${StM.Pages.User}/${StM.UserPages.MyPackages}`} component={MyPackagePageMobile} />
                    <Route path={`${StM.Pages.User}/${StM.UserPages.MyGroups}`} exact component={GroupsPageMobile} />
                    <Route path={`${StM.Pages.User}/${StM.UserPages.GroupMembers}`} exact component={GroupMemebersMobile} />
                    <Route component={ProfilePageMobile} />
                </Switch>
            </div>
        )
    };
};

function mapStateToProps(state: StM.IGlobalStoreState, ownProps: any) {
    return {
        club: state.club,
        isAuth: state.app.isAuthorized,
        user: state.user,
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        go: (url: string) => dispatch(ActM.RouteActions.replace(url))
    }
}

const connectedUserPage = connect(mapStateToProps, mapDispatchToProps)(UserPageMobile);
const UserPageController = withRouter(connectedUserPage);
export default UserPageController;
