import * as React from 'react';
import * as Redux from 'redux';
import { connect } from 'react-redux';
import * as ReactRouter from 'react-router-dom';
import {IGlobalStoreState, GlobalStoreState} from '../models/store';
import Basket from './basket';
import Notifications from './notifications';

interface IRightBlockProps {
    notificationsShown: boolean;
}

interface IRightBlockState {
}

class RightBlock extends React.Component<IRightBlockProps, IRightBlockState> {
    constructor(props: IRightBlockProps) {
        super(props);
    }

    render() {
        return (
            this.props.notificationsShown ? 
            <Notifications /> :
            <Basket /> 
        )
    };
};

const mapStateToProps = (state: IGlobalStoreState) => {
    return {
        notificationsShown: state.notificationBoard.isShown
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(RightBlock);
