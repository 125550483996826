import './hoverLine.scss'
import * as React from 'react';
import moment from 'moment';
import * as StM from '../../../models/store';
import Constants from '../../../constants';
import * as _ from 'lodash';

interface IHoverLineProps {
    courts: Array<StM.ICourtStoreState>;
    timeBlock: StM.ICourtTimeBlockStoreState;
    isShown: boolean;
    top?: number;
    right?: number;
    sessionType: string;
    hideHandler?: () => void;
}
interface IHoverLineState {}

class TimeLine extends React.Component<IHoverLineProps,  IHoverLineState> {
    private offsetTop = Constants.TimeLine.topOffset;
    private height = Constants.TimeLine.hourHeight;
    private _DOMParent:any = null;
    private _DOM:any = null;

    constructor(props: IHoverLineProps) {
        super(props);
    }
    timeFromDuration(dur:moment.Duration) {
        return moment({hour: dur.hours(), minutes: dur.minutes()});
    }
    formatTime(time:moment.Duration) {
        return this.timeFromDuration(time).format('h:mm');
    }
    formatTimeSection(time:moment.Duration) {
        return this.timeFromDuration(time).format('a');
    }
    componentWillUnmount() {
        if(this && this._DOMParent){
            this._DOMParent.removeEventListener('mouseover', this.mouseOverHandler);
        }
    }

    isTouchDetect(): boolean {
        if (window.matchMedia('(any-hover: hover)').matches) return false;
        return 'ontouchstart' in window || 'ontouchstart' in document.documentElement // works on most browsers
        //   || 'onmsgesturechange' in window; // works on ie10
    }

    mouseOverHandler = (e:any) => {

        if(!this.isTouchDetect()){
            let timeline = document.getElementsByClassName('time-choosen-line');
             

            let target = e.target;
            let isPriceBadge = false;
            let targetClass = 'price-cell'
            while(target != this._DOMParent) {
                if (target.classList.contains && (target.classList.contains(targetClass) || target.classList.contains('time-choosen-line'))) {
                    isPriceBadge = true;
                    break;
                }
                target = target.parentElement;
            }
            if (!isPriceBadge) {
                if (this.props.hideHandler && this.props.isShown) {
                    this.props.hideHandler();
                } 
            }
        }


    }
    setPosition(elem:any) {
        if(this.isTouchDetect()){
            return;
        }
            if (!elem || !elem.offsetParent) return false;
            if(!this._DOMParent) {
                this._DOMParent = elem.offsetParent;
                this._DOM = elem;
                this._DOMParent.addEventListener('mouseover', this.mouseOverHandler);
            }
            let parentTop = elem.offsetParent.getBoundingClientRect().top;
            let left = elem.getBoundingClientRect().left;
            let top = this.props.top || parentTop;
            let delta = top - parentTop;
            let right = this.props.right || left;
            let width = right - left;
            elem.style.top = delta.toFixed(1) + 'px';
            elem.style.width = width.toFixed(1) + 'px';
            // }

    }
    render() {
        if (this.props.isShown) {
            let className:any;
            let classNameTouch: any;

            if(this.isTouchDetect()){
                classNameTouch = ' touch-class'
            }
            else{
                classNameTouch = ' '
            }
            switch(this.props.sessionType) {
                case StM.BookPageSessionType.Play:
                case StM.BookPageSessionType.Group: {
                    className = ' play';
                    break;
                }
                case StM.BookPageSessionType.Clinic: {
                    className = ' clinic';
                    break;
                }
                case StM.BookPageSessionType.Lesson: {
                    className = ' lesson'
                    break;
                }
                default: {
                    className = '';
                }
            }
            return (
                <div className={"time-choosen-line" + className + classNameTouch } ref={(component) => this.setPosition(component)}>
                    <div className={"time-wrapper-time-choosen-line" + className}>
                        <div className="time-small">{this.formatTime(this.props.timeBlock.start)}</div>
                        <div className="time-zone-small">{this.formatTimeSection(this.props.timeBlock.start)}</div>
                        <div className="court-small">{StM.Strings.CourtPrefix} {_.find(this.props.courts, c => c.id === this.props.timeBlock.courtId).title}</div>
                    </div>
                </div>
            )
        }
        return null;
    }
}
export default TimeLine;