
import './packageInfo.scss';
import * as React from 'react';
import moment from 'moment';
import * as _ from 'lodash';

import * as StM from '../../../models/store';
import PackageBalanceItem from '../../credits/packageBalanceItem';
import * as SrvM from '../../../services';

const utils = new SrvM.Utils();

export interface IPackageInfoCreditsDefinitionWithRemaining {
    id: number;
    sortOrder: number;
    credits: number;
    title: string;
    description: string;
    sessionType: string;
    alias: string;
    remaining?: number;
}

export interface IPackageInfoCreditsBalance {
    id: number;
    amount: number;
}

interface IPackageInfoProps {
    id: number;
    title: string;
    description: string;
    price: number;
    expirationDate: moment.Moment;
    creditsDefinitions: Array<IPackageInfoCreditsDefinitionWithRemaining>;
    customSessionTypes: Array<StM.ICustomSessionTypeStoreState>;
    coachFeeTier?: StM.ICoachFeeTierStoreState;
    enablePurchasing?: boolean;
    onBuyClick?: (id: number) => void;
}


export default class PackageCurrentInfo extends React.Component<IPackageInfoProps> {
    render() {
        return (
            <article className="package-wrapper">
                {this.renderHeader()}
                {this.renderMainContent()}
                {this.props.enablePurchasing && this.renderFooter()}
            </article>
        );
    }

    private renderHeader() {
        return (
            <header>
                <div className="title-wrapper">
                    <span className="package-title">{this.props.title}</span>
                    <span className="expiration">Expires {this.props.expirationDate.format('MMM D, YYYY')}</span>
                </div>
                <div className="desc">{this.props.description}</div>
            </header>
        );
    }

    private renderMainContent() {
        return (
            <main>
                <div className="credits-wrapper">
                    <ul className="credits-list">
                        {_.sortBy(this.props.creditsDefinitions.filter((item) => item.credits > 0), c => c.sortOrder)
                            .map((item, index) => {
                                return (
                                    <li key={index} className="credits-item">
                                        <PackageBalanceItem
                                            id={item.id}
                                            title={item.title}
                                            description={item.description}
                                            credits={item.credits}
                                            creditsBalance={item.remaining}
                                            isRemainingVisible={true}
                                            sessionType={item.sessionType}
                                            alias={item.alias}
                                            coachFeeTier={this.props.coachFeeTier}
                                            customSessionTypes={this.props.customSessionTypes}
                                        />
                                    </li>
                                );
                            })}
                    </ul>
                </div>
            </main>
        );
    }

    private renderFooter() {
        return (
            <footer>
                <div className="price-wrapper">
                    <span className="price-title">Price:</span>
                    <span className="price">${utils.formatStringPrice(this.props.price.toString())}</span>
                </div>
                <div className="btns-wrapper">
                    <button className="btn btn-black" onClick={(e) => this.handleOnBuyClick(e)}>BUY</button>
                </div>
            </footer>
        );
    }

    private handleOnBuyClick(e: any) {
        if (e) { e.preventDefault(); e.stopPropagation(); }
        this.props.onBuyClick(this.props.id);
    }

    private getExpirationDateString(expirationTimeSpan: moment.Duration) {
        return moment().clone().add(expirationTimeSpan.asDays(), 'days').format('MMM D, YYYY');
    }
}
