import * as React from 'react';
import { StM } from '../../../modules';
import { IPackageSessionTypeStoreState } from '@models/store/packages';
import { ICustomSessionTypeStoreState } from '@models/store/clubs';
import SubscriptionOfferInfo from '../../../components/pages/subscriptionOffer/subscriptionOfferInfo';
import { ISubscriptionOfferStateModel } from '@models/store/subscriptionOffers';
import { ICoachFeeTierStoreState } from '@models/store/coachFeeTierStoreState';
import SubscriptionCurrentCard from '../../../components/pages/subscriptionOffer/currentSubscription/subscriptionCurrentCard';
import { toSubscriptionCurrent } from '../../../components/pages/subscriptionOffer/currentSubscription/convertors';

export interface ISubscriptionCreditsDefinition {
    alias: string;
    color: string;
    credits: number;
    description: string;
    packageSessionTypeId: number;
    sortOrder: number;
    title: string;
}

export type SubscriptionOfferOrSubscription = IRecurrentPackageCommitmentDetailsDto | ISubscriptionOfferStateModel;

export const SubscriptionsList: React.FC<{
    subscriptions: SubscriptionOfferOrSubscription[],
    onBuySubscriptionClick: (id: number) => void,
    onResubscribeClick?: (id: number) => void,
    onSubscriptionCancellation: (id: number, billingPeriod: string) => void,
    customSessionTypes: ICustomSessionTypeStoreState[],
    sessionTypes: IPackageSessionTypeStoreState[],
    coachFeeTiers: ICoachFeeTierStoreState[],
    currentSubscriptions: IRecurrentPackageCommitmentDetailsDto[];
    subscriptionDefinitions: StM.ISubscriptionOfferStateModel[];
}> = (props) => {

    const getPackageCreditsDefinitions = (subscriptionOfferStateModel: ISubscriptionOfferStateModel): Array<ISubscriptionCreditsDefinition> => {
        return subscriptionOfferStateModel.creditsDefinitions.map((c: IRecurrentPackageCreditsDefinitionDto) => {
            return {
                alias: c.typeAlias,
                color: c.color,
                credits: c.credits,
                description: c.description,
                packageSessionTypeId: c.packageSessionTypeId,
                sortOrder: c.sortOrder,
                title: c.title,
            }
        });
    }

    const isSubscription = (s: SubscriptionOfferOrSubscription): s is IRecurrentPackageCommitmentDetailsDto => {
        return (s as IRecurrentPackageCommitmentDetailsDto).currentOffer !== undefined;
    }

    const isOffer = (s: SubscriptionOfferOrSubscription): s is ISubscriptionOfferStateModel => {
        return (s as ISubscriptionOfferStateModel).statusHistory !== undefined;
    }

    return <>
        {
            (props.subscriptions || []).map((item, index) => {
                if (isOffer(item)) {
                    const creditsDefinitions = getPackageCreditsDefinitions(item);
                    return <SubscriptionOfferInfo key={index}
                        id={item.id}
                        title={item.title}
                        description={item.description}
                        coachFeeTierId={item.coachFeeTierId}
                        billingPeriod={item.billingPeriod}
                        price={item.price}
                        creditsDefinitions={creditsDefinitions}
                        customSessionTypes={props.customSessionTypes}
                        enablePurchasing={true}
                        onBuyClick={props.onBuySubscriptionClick}
                        sessionTypes={props.sessionTypes || []}
                        coachFeeTiers={props.coachFeeTiers || []}
                    />
                } else if (isSubscription(item)) {
                    const currentSubscription = props.currentSubscriptions.find((subscription) => {
                        return subscription.id === item.id;
                    });
                    const duplicatedSubscriptions = props.currentSubscriptions.filter((subscription) => {
                        return subscription.recurrentPackageOfferId === currentSubscription.recurrentPackageOfferId;
                    });
                    const foundCanceledSubscription = props.subscriptionDefinitions.find((definition) => definition.id === currentSubscription.recurrentPackageOfferId);
                    const displayResubscribeButton = duplicatedSubscriptions.length < 2 && typeof foundCanceledSubscription !== 'undefined';

                    return (
                        <SubscriptionCurrentCard key={item.id}
                            subscription={toSubscriptionCurrent(item)}
                            options={{ isActiveSubtitle: true, invertColors: true }}
                            handleResubscribe={() => props.onResubscribeClick(item.recurrentPackageOfferId)}
                            handleUnsubscribe={() => props.onSubscriptionCancellation(item.id, item.currentOffer.billingPeriod)}
                            displayPrice
                            displayRemainingCredits={false}
                            displayResubscribeButton={displayResubscribeButton}
                            customSessionTypes={props.customSessionTypes}
                        />
                    );
                }
                console.error('--- never happen, check your types and models');
                return null;
            })
        }
    </>
}
