import * as React from 'react';
import * as StoreModule from '../../../models/store';
import * as ServiceModule from '../../../services';
import moment from 'moment';
import * as _ from 'lodash';

interface IDateSelectorProps {
    params: StoreModule.IBookPageRouteParams;
    date: moment.Moment;
    onDateChangedHandler: (params:any) => void;
    club: StoreModule.IClubStoreState;
}

interface IDateSelectorState {}

class DateSelectorMobile extends React.Component<IDateSelectorProps, IDateSelectorState> {
    private utils: ServiceModule.Utils;
    constructor(props: IDateSelectorProps) {
        super(props);
        this.utils = new ServiceModule.Utils();
    }

    render() {
        let day = this.renderDay(this.props.date);
        return (
            <div className="date-selector-mobile mobile-row">
                <div className="mobile-col-1 arrow-mobile" onClick={(e) => this.lastClick(e)}>
                    <div className="left-arrow-mobile"></div>
                </div>
                <div className="mobile-col-10">{day}</div>
                <div className="mobile-col-1 arrow-mobile" onClick={(e) => this.nextClick(e)}>
                    <div className="right-arrow-mobile"></div>
                </div>
            </div>
        )
    };

    renderDay(day: moment.Moment) {
        let date = day.date();
        let month = day.format('MMM');
        let weekday = day.format('ddd');
        return (
            <div className="day-mobile">
                <span className="date-mobile">{date} </span>
                <span className="month-mobile">{month}, </span>
                <span className="weekday-mobile">{weekday}</span>
            </div>
        );
    }

    private nextClick(e: any) {
        if(e) {e.stopPropagation();}
        let date = this.props.date.add(1, 'day');
        this.onDateChanged(date);
    }

    private lastClick(e: any) {
        if(e) {e.stopPropagation();}
        let date = this.props.date.add(-1, 'day');
        this.onDateChanged(date);
    }

    private onDateChanged(date: moment.Moment){
        const dateFormat = this.utils.getDateFormat(date);
        this.props.onDateChangedHandler(_.assign({}, this.props.params, {date: dateFormat}));
    }
};

export default DateSelectorMobile;
