import './bigSessionInfoItem.scss'
import * as React from 'react';
import ClassNames from 'classnames';
import moment from 'moment';
import * as _ from 'lodash';

import * as StM from '../../../models/store';
import * as SrvM from '../../../services';
import * as PolM from '../../../policies';
import Constants from '../../../constants';
import PlayerTooltip from '../../tooltips/playerTooltip';
import Credits from '../../credits/credits';

let utils = new SrvM.Utils();
let render = new SrvM.SessionInfoDialogRenderService();

interface IBigSessionInfoItemProps {
    session: StM.ISessionStoreState;
    user: StM.IUserStoreState;
    basket: StM.IBasketStoreState;
    coaches: Array<StM.ICoachStoreState>;
    coachFeeTiers: Array<StM.ICoachFeeTierStoreState>;
    isOpenBoard?: boolean;
    club: StM.IClubStoreState;

    infoClick?: (from: string, id: number) => void;
}
interface IBigSessionInfoItemState { }

export default class BigSessionInfoItem extends React.Component<IBigSessionInfoItemProps, IBigSessionInfoItemState> {
    private sessionInfo: PolM.SessionInfoPolicy;
    private sessionAvailability: PolM.SessionAvailabilityPolicy;
    private authSrv = new SrvM.AuthenticationService();

    constructor(props: IBigSessionInfoItemProps) {
        super(props);
        this.initFromProps(props);
    }

    public componentDidUpdate(prevProps: IBigSessionInfoItemProps) {
        const havePropsChanged = this.havePropsChanged(prevProps);
        if (havePropsChanged) this.initFromProps(prevProps);
    }
    
    private havePropsChanged(prevProps: IBigSessionInfoItemProps) {
        return (
            !_.isEqual(this.props.session, prevProps.session) ||
            !_.isEqual(this.props.user, prevProps.user) ||
            !_.isEqual(this.props.basket, prevProps.basket) ||
            !_.isEqual(this.props.coaches, prevProps.coaches) ||
            !_.isEqual(this.props.isOpenBoard, prevProps.isOpenBoard)
        );
    }

    render() {
        if (this.props.session) {
            let classes = ClassNames("session-item", utils.getSessionClass(this.props.session), {
                'open-session-board': this.props.isOpenBoard,
                'no-free-slots': (this.props.session.maxUserCount - this.sessionInfo.getPlayers().length) == 0,
                'custom-session': this.props.session.type == StM.SessionType.Custom
            });
            return (
                <div className={classes}>
                    {this.props.isOpenBoard ? <div className="day-of-week-row">{this.props.session.startDateTime.format('dddd')}</div> : ""}
                    <div className="session-details-row">
                        {this.renderSessionTimeBlock(this.props.session)}
                        {this.renderSessionDescription(this.props.session)}
                    </div>
                </div>
            );
        }
        return null;
    }

    renderSessionTimeBlock(session: StM.ISessionStoreState) {
        let classesTimeWrapper = ClassNames("session-time-wrapper", {
            'open-board-time-wrapper': this.props.isOpenBoard
        });
        return (

            <div className={classesTimeWrapper}>
                <div className="date">{session.startDateTime.date()}</div>
                <div className="month">{this.formatMonth(session.startDateTime)}, </div>
                <div className="time-start-wrapper">
                    <div className="time-start">{this.formatTime(session.startDateTime)}</div>
                    <div className="time-start-time-type">{this.formatTimeZone(session.startDateTime)}</div>
                </div>
                <div className="time-divider">&ndash; </div>
                <div className="time-end-wrapper">
                    <div className="time-end">{this.formatTime(session.endDateTime)}</div>
                    <div className="time-end-time-type">{this.formatTimeZone(session.endDateTime)}</div>
                </div>
            </div>
        );
    }

    renderSessionDescription(session: StM.ISessionStoreState) {
        const players = this.sessionInfo.getPlayers();
        const invited = this.sessionInfo.getInvitedUsers();
        const declined = this.sessionInfo.getDeclinedUsers();

        const classes = ClassNames("session-description-wrapper",
            {
                decline: !!declined.length,
                invite: !!invited.length
            });

        const classesTitleOpenBoard = ClassNames("session-title",
            {
                "open-board-session-title": this.props.isOpenBoard
            });

        const sessionInfoClasses = ClassNames("session-info",
            {
                "session-info-my-sessions": !this.props.isOpenBoard
            });

        let styles: any = {}
        if (session.type === StM.SessionType.Custom) {
            styles.backgroundColor = session.customBackgroundColor;
        }
        return (
            <div className={classes}>
                <div className="session-title-wrapper">
                    <div className={classesTitleOpenBoard}>
                        <i className="session-color" style={styles}></i>
                        {this.getSessionTitle(session)}
                        {this.renderTitleBage(session)}
                        {this.renderVideoIcon(session)}
                    </div>
                    <button className="btn-view" onClick={(e) => this.infoClickHandler(e, session)}>View</button>
                </div>
                <div className={sessionInfoClasses}>
                    {this.renderCoach(session)}
                    <div className="court">{utils.getSessionCourtsTitle(session)}</div>
                    {this.renderSlot(session)}

                    {this.renderGender(session)}
                    {this.renderTypeOfPlay(session)}
                    {this.renderAge(session)}

                    {this.renderSkillLvl(session)}
                </div>
                {session.maxUserCount > 1 && session.type != StM.SessionType.Custom && session.type != StM.SessionType.Clinic && <div className="session-players">
                    <div className="players">
                        <span className="title">Players: </span>
                        {players.map((item) => this.renderPlayerItem(item))}
                    </div>
                    <div className="divider-line-players">
                    </div>
                    <div className="invited">
                        <span className="title">Invited:</span>
                        <span className="invited-count">{invited.length}</span>
                    </div>
                    <div className="divider-line-players">
                    </div>
                    <div className="declined">
                        <span className="title">Declined:</span>
                        <span className="declined-count">{declined.length}</span>
                    </div>
                </div>}
                {session.maxUserCount > 1 && session.type == StM.SessionType.Custom && session.isPaidByOwner && <div className="session-players">
                    <div className="players">
                        <span className="title">Host: </span>
                        {this.renderPlayerItem(session.owner)}
                    </div>
                </div>}
                {this.renderPrice(session)}
            </div>
        );
    }

    private renderPrice(session: StM.ISessionStoreState) {
        const isCourse = session.series && session.series.isCourse;
        const pricesPolicy = new PolM.PricesPolicy(session.isDoubledSession, session);
        const prices = pricesPolicy.handle();
        const price = prices.price + prices.servicesPrice;
        const handledPrice = utils.formatStringPrice(price.toString());
        const priceTypeTitle = !this.props.isOpenBoard && session.isPaidByOwner ? 'session' : 'person';
        const priceTitle = `$${handledPrice}${handledPrice ? `/${priceTypeTitle}` : ''}`;
        return (
            <div className="price">
                <span className="title">{!isCourse ? 'Price: ' : 'Total price: '}</span>
                {!prices.credits && (<div className="price-count">{priceTitle}</div>)}
                {!!prices.credits && (<div className="price-count-club-credits">
                    <Credits credits={prices.credits} type={session.type} bgColor={session.customBackgroundColor} txtColor={session.customTextColor} />
                    <div className="club-credit-label">{prices.servicesPrice ? `+ $${utils.formatStringPrice(prices.servicesPrice.toString())}` : 'Club Credit'}</div>
                </div>)}
            </div>
        );
    }

    getSessionTitle(session: StM.ISessionStoreState) {
        let isOwner = this.sessionAvailability.getIsOwner();
        const ownerPrefix = this.authSrv.getOwnerPrefix(this.props.user);
        const isCourse = session.series && session.series.isCourse;
        const courseTitle = isCourse ? ` (${session.seriesOrder + 1} of ${session.series.sessionCount})` : '';

        if (session.type == StM.SessionType.Custom) {
            return (isOwner ? `${ownerPrefix} ` : '') + (session.title || 'Custom Session') + courseTitle;
        }
        let isDouble = session.isDoubledSession;
        let type = utils.getSessionTypeTitle(session);
        let title = '';
        if (session.type == StM.SessionType.Clinic) {
            isOwner = false;
            title = session.title ? ': ' + session.title : '';
        }

        return (isOwner ? `${ownerPrefix} ` : '') + (isDouble ? 'Double ' : '') + type + title + courseTitle;
    }

    renderTitleBage(session: StM.ISessionStoreState) {
        if (session && session.type != StM.SessionType.Clinic) {
            const badgeClasses = ClassNames("bage", { "open": !session.isHidden, "close": session.isHidden });
            const badgeTitle = session.isHidden ? "PRIVATE" : "PUBLIC";
            const styles: any = {};
            if (session.type === StM.SessionType.Custom && !session.isHidden) {
                styles.backgroundColor = session.customBackgroundColor;
                styles.color = session.customTextColor;
            }

            return <span className={badgeClasses} style={styles}>{badgeTitle}</span>;
        }
        return null;
    }

    renderVideoIcon(session: StM.ISessionStoreState) {
        const isBought = this.sessionAvailability.getIsBoughtUser();
        const isVideoEnabled = utils.hasVideo(session);
        return (isBought && isVideoEnabled) && (
            <span className="ic_video"></span>
        );
    }

    renderCoach(session: StM.ISessionStoreState) {
        const trainer: StM.IPublicUserStoreState = session.trainer
            ? session.trainer
            : _.find(this.props.coaches, { id: session.trainerId });
        if (!trainer) return null;
        let image = trainer.imageId ? trainer.imageId : '';
        let imageUrl = image ? `/api/v2/blobs/${image}/content` : '/content/img/nophoto-2.png';
        return (
            <div className="coach">
                <img className="coach-photo" src={imageUrl} />
                <span className="coach-wrapper">{trainer.displayName}</span>
                <div className="coach-tooltip">
                    <span className="coach-tooltip-title">{trainer.displayName}</span>
                </div>
            </div>
        );
    }


    renderGender(session: StM.SessionStoreState) {
        if (session.playerQualification && session.playerQualification.sex) {

            return <div className="gender">{session.playerQualification.sex}</div>

        }
        return null;
    }


    renderAge(session: StM.SessionStoreState) {
        return session.type === StM.SessionType.Clinic
            ? render.renderAge(session)
            : null
            ;
    }

    renderSlot(session: StM.ISessionStoreState) {
        if (session.maxUserCount < 2) return null;
        const free = session.maxUserCount - this.sessionInfo.getPlayers().length;
        return (
            <div className="slots">
                {/*<span className="count-free-slots">{free}</span> of <span className="count-all-slots">{session.maxUserCount}</span> Places Free*/}
            </div>
        );
    }

    renderSkillLvl(session: StM.ISessionStoreState) {
        if (session.playerQualification && session.playerQualification.skill) {
            let skill = session.playerQualification.skill;
            let skillClass = this.getSkillClass(skill)
            let classes = ClassNames("lvl", skillClass);
            if (!skillClass) return (
                <div className="lvl all-skills">
                    Any Skill
                </div>
            );
            return (
                <div className={classes}>
                    {skill}
                </div>
            )
        }
        return null;
    }

    renderTypeOfPlay(session: StM.ISessionStoreState) {

        if (session.playerQualification && session.seeking) {
            let type = session.seeking.type;
            let typeClass = this.getTypeClass(type)
            let classes = ClassNames("type-play");
            if (typeClass == "Other") {
                return (
                    <div className={classes}>
                        {this.props.session.seeking.description}
                    </div>
                )
            }
            if (!typeClass) return null;
            return (
                <div className={classes}>
                    {type}
                </div>
            )
        }
        return null;
    }

    getTypeClass(type: string) {
        switch (type) {
            case StM.PlayType.Match: {
                return 'Match';
            }
            case StM.PlayType.Drills: {
                return 'Drills';
            }
            case StM.PlayType.Hit: {
                return 'Hit';
            }
            case StM.PlayType.Other: {
                return 'Other';
            }
            default: {
                return '';
            }
        }
    }

    renderPlayerItem(user: StM.IPublicUserStoreState) {
        let title = user.displayName;
        if (title.length > Constants.NameLength) {
            title = title.substring(0, Constants.NameLength - 3) + '...';
        }
        return (
            <span className="players-name" key={user.id}>
                {title}
                <PlayerTooltip user={user} />
            </span>
        );
    }

    formatMonth(date: moment.Moment) {
        return date.format('MMM');
    }

    formatTime(date: moment.Moment) {
        return date.format('h:mm');
    }

    formatTimeZone(date: moment.Moment) {
        return date.format('a');
    }

    infoClickHandler(e: any, session: StM.ISessionStoreState) {
        if (e) { e.preventDefault(); e.stopPropagation(); }
        let id = session.id || session.basketId;
        let from = session.id ? StM.SessionInfoFromTypes.calendar : StM.SessionInfoFromTypes.basket;
        this.props.infoClick(from, id);
    }

    getSkillClass(skill: string) {
        switch (skill) {
            case StM.UserSkill.Beginner: {
                return 'beginner';
            }
            case StM.UserSkill.Intermediate: {
                return 'intermediate';
            }
            case StM.UserSkill.Advanced: {
                return 'advanced';
            }
            default: {
                return '';
            }
        }
    }

    private initFromProps(props: IBigSessionInfoItemProps) {
        this.sessionInfo = new PolM.SessionInfoPolicy(props.session, props.user);
        this.sessionAvailability = new PolM.SessionAvailabilityPolicy(props.session, [], props.user);
    }

    private getFilterFromSession(session: StM.ISessionStoreState): StM.IBookPageRouteParams {
        let filter = new StM.BookPageRouteParams();
        switch (session.type) {
            case StM.SessionType.Play: {
                filter.sessionType = StM.BookPageSessionType.Play;
                break;
            }
            case StM.SessionType.Private: {
                filter.sessionType = StM.BookPageSessionType.Lesson;
                filter.lessonSubfilter = StM.BookPageLessonFilterType.Coach;
                filter.lessonSubfilterId = session.trainerId || StM.BookPageFilterTypeDefault.SubFilterId;
                break;
            }
            case StM.SessionType.Clinic: {
                filter.sessionType = StM.BookPageSessionType.Clinic;
                break;
            }
        }

        return filter;
    }
}