import './groupsPage.scss';

import * as React from 'react';

import * as BP from './baseGroupsPage';
import * as StM from '../../../../models/store';
import { GroupsGrid } from '../../../../components/pages/user/groups';
import { Constants } from '../../../../constants';

class GroupsPageMobile extends React.Component<BP.IGroupsPageProps> {
    constructor(props: BP.IGroupsPageProps) {
        super(props);
    }

    public render() {
        if(!this.props.groups) return null;
        return (
            <div className="main-content-wrapper profile-pages groups-page">
                <p className='page-description'>Are you a family who loves squash? A head of a team or a league? Create a Group Account and easily see, schedule, and pay for multiple sessions for your family, friends, team or league.</p>
                {this.renderHeadOfSection()}
                {this.renderMemberOfSection()}
            </div>
        );
    }

    private renderHeadOfSection() {
        const headedGroups = this.props.groups.filter((group) => 
            group.members.some((member) => member.user 
                && member.user.id === this.props.user.id 
                && member.type === StM.GroupMemberType.Head)
        );

        const isAvailableToCreateGroup = headedGroups.length < Constants.MaxGroupAccounts;
        return (
            <section className='page-section' id='group-page-content'>
                <header>
                    <h1 className='page-title'>My Groups <br /> (Group Head)</h1>
                    <div className='btns-wrapper'>
                        <button className='btn btn-red btn-bold' 
                            title={isAvailableToCreateGroup ? 'Create a new group' : 'You have reached maximum of group accounts'}
                            disabled={!isAvailableToCreateGroup} 
                            onClick={(e) => isAvailableToCreateGroup && this.props.onCreateGroupBtnClick(e)}
                        >
                            Create group
                        </button>
                    </div>
                </header>
                <p className='description page-description'>As a Head of a Group, you can invite players to be members of your Group, view their calendar of sessions, schedule and pay for multiple squash sessions with Group credits or the Group credit card. Only the Head of the Group can allocate Group credits or use the Group credit card, or even add another Group Admin. A player must have a Squash On Fire account to be invited into a Group.</p>
                <div className='page-list-wrapper'>
                    <GroupsGrid 
                        data={headedGroups} 
                        user={this.props.user}
                        isHead
                        onSwitchToGroup={(group) => this.props.onSwitchToGroup(group)}
                        onInviteGroupMember={(group) => this.props.openInviteMemberToGroupDialog(group.id)}
                        onRemoveGroup={(group) => this.props.onRemoveGroup(group)}
                    />
                </div>
            </section>
        );
    }

    private renderMemberOfSection() {
        const memberedGroup = this.props.groups
        .filter((group) => 
            group.members.some((member) => member.user 
                && member.user.id === this.props.user.id 
                && member.type !== StM.GroupMemberType.Head)
        );

        return (
            <section className='page-section'>
                <header>
                    <h1 className='page-title'>My Group Memberships</h1>
                </header>
                <p className='description page-description'>As a Group Member, benefit from having your squash sessions scheduled and paid for by the Group Head, on your behalf. You still have access to your own account and can still book sessions using your own personal credit card or credits (the Group Head will be able to see those sessions).</p>
                <div className='page-list-wrapper'>
                    <GroupsGrid 
                        data={memberedGroup}
                        user={this.props.user}
                        onSwitchToGroup={(group) => this.props.onSwitchToGroup(group)}
                        onLeaveGroup={(group) => this.props.onLeaveGroup(group)}
                    />
                </div>
            </section>
        );
    }
}

export default BP.withGroupsPage(GroupsPageMobile);