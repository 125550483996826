import './bigSessionInfoItem.mobile.scss'
import * as React from 'react';
import ClassNames from 'classnames';
import * as _ from 'lodash';

import * as StM from '../../../models/store';
import * as SrvM from '../../../services';
import * as PolM from '../../../policies';
import Credits from '../../credits/credits';

let utils = new SrvM.Utils();

interface IBigSessionInfoItemProps {
    session: StM.ISessionStoreState;
    user: StM.IUserStoreState;
    coachFeeTiers: Array<StM.ICoachFeeTierStoreState>;
    coaches: Array<StM.ICoachStoreState>;
    basket: StM.IBasketStoreState;
    isOpenBoard?: boolean;
    club: StM.IClubStoreState;

    infoClick?: (from: string, id: number) => void;
}
interface IBigSessionInfoItemState { }

export default class BigSessionInfoItemMobile extends React.Component<IBigSessionInfoItemProps, IBigSessionInfoItemState> {
    private sessionInfo: PolM.SessionInfoPolicy;
    private sessionAvailability: PolM.SessionAvailabilityPolicy;
    private authSrv = new SrvM.AuthenticationService();

    constructor(props: IBigSessionInfoItemProps) {
        super(props);
        this.initFromProps(props);
    }

    componentDidUpdate(prevProps: IBigSessionInfoItemProps) {
        if (!_.isEqual(this.props, prevProps)) {
            this.initFromProps(this.props);
        }
    }

    render() {
        if (this.props.session) {
            let classes = ClassNames("session-item-mobile", utils.getSessionClass(this.props.session), {
                'open-session-board-mobile': this.props.isOpenBoard
            });
            return (
                <div className={classes}>
                    {this.props.isOpenBoard ? <div className="day-of-week-row">{this.props.session.startDateTime.format('dddd')}</div> : ""}
                    {this.renderSessionTimeBlock(this.props.session)}
                    {this.renderSessionDescription(this.props.session)}
                </div>
            );
        }
        return null;
    }

    renderSessionTimeBlock(session: StM.ISessionStoreState) {
        let classesTimeWrapper = ClassNames("session-time-wrapper-mobile mobile-row", {
            'open-board-time-wrapper-mobile': this.props.isOpenBoard
        });
        return (

            <div className={classesTimeWrapper}>
                <div className="mobile-col-11 date-time-wrapper-mobile flex-baseline-mobile">
                    <span className="day-mobile">{session.startDateTime.date()} </span>
                    <span className="month-mobile">{session.startDateTime.format('MMM')}, </span>
                    <div className="time-wrapper-mobile">
                        <div className="time-mobile">{session.startDateTime.format("h:mm")}</div>
                        <div className="time-type-mobile">{session.startDateTime.format("a")}</div>
                    </div>
                    <div className="time-divider-mobile">&ndash;</div>
                    <div className="time-wrapper-mobile">
                        <div className="time-mobile">{session.endDateTime.format("h:mm")}</div>
                        <div className="time-type-mobile">{session.endDateTime.format("a")}</div>
                    </div>
                </div>
                <div className="mobile-col-1 level-indicator-mobile">
                    {this.renderSkillLvl(session)}
                </div>
            </div>
        );
    }

    renderSessionDescription(session: StM.ISessionStoreState) {
        const players = this.sessionInfo.getPlayers();
        const invited = this.sessionInfo.getInvitedUsers();
        const declined = this.sessionInfo.getDeclinedUsers();

        const classes = ClassNames("session-description-wrapper-mobile",
            {
                decline: !!declined.length,
                invite: !!invited.length
            });

        let styles: any = {}
        if (session.type === StM.SessionType.Custom) {
            styles.backgroundColor = session.customBackgroundColor;
        }

        const courtText = utils.getReadableSessionCourtsTitle(session);
        const isBought = this.sessionAvailability.getIsBoughtUser();
        const isVideoEnabled = utils.hasVideo(session);

        return (
            <div className={classes}>
                <div onClick={(e) => this.infoClickHandler(e, session)}>
                    <div className="session-title-wrapper-mobile mobile-row flex-center-mobile">
                        <i className="session-color" style={styles}></i>
                        <div className="session-title-mobile mobile-col-10 flex-center-mobile">
                            {this.getSessionTitle(session, this.props.user)}
                        </div>
                        {isBought && isVideoEnabled && <span className="ic_video"></span>}
                        <div className="session-badge-mobile mobile-col-2">
                            {this.renderTitleBage(session)}
                        </div>
                    </div>
                    <div className="session-info-mobile mobile-row">
                        <div className="mobile-col-12 flex-center-mobile">
                            <span className="court-info-mobile">{courtText}</span>
                            {this.renderCoach(session)}
                        </div>
                    </div>
                </div>
                <div className="price-info-mobile mobile-row flex-center-mobile">
                    <div className="mobile-col-6 slots-mobile">
                        {this.renderSlot(session)}
                    </div>
                    {this.renderPrice(session)}
                </div>
            </div>
        );
    }

    getSessionTitle(session: StM.ISessionStoreState, user: StM.IUserStoreState) {
        let isOwner = this.sessionAvailability.getIsOwner();
        const ownerPrefix = this.authSrv.getOwnerPrefix(user);
        const isCourse = session.series && session.series.isCourse;
        const courseTitle = isCourse ? ` (${session.seriesOrder + 1} of ${session.series.sessionCount})` : '';

        if (session.type == StM.SessionType.Custom) {
            return (isOwner ? `${ownerPrefix} ` : '') + (session.title || 'Custom Session') + courseTitle;
        }
        let isDouble = session.isDoubledSession;
        let type = utils.getSessionTypeTitle(session);
        let title = '';
        if (session.type == StM.SessionType.Clinic) {
            isOwner = false;
            title = session.title ? ': ' + session.title : '';
        }
        return (isOwner ? `${ownerPrefix} ` : '') + (isDouble ? 'Double ' : '') + type + title + courseTitle;
    }

    private renderPrice(session: StM.ISessionStoreState) {
        const pricesPolicy = new PolM.PricesPolicy(session.isDoubledSession, session);
        const prices = pricesPolicy.getSessionPrices();
        const sessionAvailabilityPolicy = new PolM.SessionAvailabilityPolicy(session, this.props.basket.goods, this.props.user);
        const basketPolicy = new PolM.BasketPolicy(this.props.club, this.props.basket, this.props.user);
        const price = prices.price + prices.servicesPrice;
        const handledPrice = utils.formatStringPrice(price.toString());
        const basketSession = basketPolicy.getBasketSession(session);
        const showPriceInCredits = !!basketSession ? !!basketSession.checkoutCredits : PolM.PackagePolicy.showPriceInCredits(session, null, sessionAvailabilityPolicy);
        return (
            <div className="mobile-col-6 price-wrapper-mobile">
                {!showPriceInCredits && <span className="price-mobile">${handledPrice}</span>}
                {showPriceInCredits && <Credits credits={session.checkoutCredits} type={session.type} bgColor={session.customBackgroundColor} small />}
                {(showPriceInCredits && !!prices.servicesPrice) && <span className="additional-price"> + ${utils.formatStringPrice(prices.servicesPrice.toString())}</span>}
            </div>
        );
    }

    renderTitleBage(session: StM.ISessionStoreState) {
        if (session && session.type != StM.SessionType.Clinic) {
            const badgeClasses = ClassNames("bage", { "open": !session.isHidden, "close": session.isHidden });
            const badgeTitle = session.isHidden ? "PRIVATE" : "PUBLIC";
            const styles: any = {};
            if (session.type === StM.SessionType.Custom && !session.isHidden) {
                styles.backgroundColor = session.customBackgroundColor;
                styles.color = session.customTextColor;
            }

            return <span className={badgeClasses} style={styles}>{badgeTitle}</span>;
        }
        return null;
    }

    renderCoach(session: StM.ISessionStoreState) {
        const trainer: StM.IPublicUserStoreState = session.trainer
            ? session.trainer
            : _.find(this.props.coaches, (i) => i.id === session.trainerId);
        if (!trainer) return null;
        let image = trainer.imageId ? trainer.imageId : '';
        let imageUrl = image ? `/api/v2/blobs/${image}/content` : '/content/img/nophoto-2.png';
        return (
            <div className="coach-wrapper-mobile flex-center-mobile">
                <div className="coach-photo-mobile">
                    <img src={imageUrl} />
                </div>
                <span className="coach-name text-ellipsis">{trainer.displayName}</span>
            </div>
        );
    }

    renderSlot(session: StM.ISessionStoreState) {
        if (session.maxUserCount < 2) return null;
        const playersCount = this.sessionInfo.getPlayers().length;
        let arr: Array<any> = [];

        for (let i = 0; i < session.maxUserCount; i++) {
            let classes = ClassNames("slots-item",
                { "one-slot": i == 0 },
                { "two-slot": i == 1 },
                { "three-slot": i == 2 },
                { "four-slot": i == 3 },
                { "five-slot": i == 4 },
                { "much-slot": i > 4 },
                { vacancy: i < playersCount })
            arr.push(<span className={classes} key={i}></span>)
        }

        let classesSlots = ClassNames("slots-mobile",
            { "one-player": session.maxUserCount == 1 },
            { "two-players": session.maxUserCount == 2 },
            { "three-players": session.maxUserCount == 3 },
            { "four-players": session.maxUserCount == 4 },
            { "more-than-5": session.maxUserCount > 4 },
        );
        return (
            <div className={classesSlots}>
                {arr}
            </div>
        );
    }

    renderSkillLvl(session: StM.ISessionStoreState) {
        if (session.playerQualification && session.playerQualification.skill) {
            let skill = session.playerQualification.skill;
            let skillClass = this.getSkillClass(skill)
            let classes = ClassNames("lvl", skillClass);
            if (skillClass) return (
                <div className={classes}></div>
            );
        }
        return null;
    }

    infoClickHandler(e: any, session: StM.ISessionStoreState) {
        if (e) { e.preventDefault(); e.stopPropagation(); }
        let id = session.id || session.basketId;
        let from = session.id ? StM.SessionInfoFromTypes.calendar : StM.SessionInfoFromTypes.basket;
        this.props.infoClick(from, id);
    }

    getSkillClass(skill: string) {
        switch (skill) {
            case StM.UserSkill.Beginner: {
                return 'beginner';
            }
            case StM.UserSkill.Intermediate: {
                return 'intermediate';
            }
            case StM.UserSkill.Advanced: {
                return 'advanced';
            }
            default: {
                return '';
            }
        }
    }

    private initFromProps(props: IBigSessionInfoItemProps) {
        this.sessionInfo = new PolM.SessionInfoPolicy(props.session, props.user);
        this.sessionAvailability = new PolM.SessionAvailabilityPolicy(props.session, [], props.user);
    }

    private getFilterFromSession(session: StM.ISessionStoreState): StM.IBookPageRouteParams {
        let filter = new StM.BookPageRouteParams();
        switch (session.type) {
            case StM.SessionType.Play: {
                filter.sessionType = StM.BookPageSessionType.Play;
                break;
            }
            case StM.SessionType.Private: {
                filter.sessionType = StM.BookPageSessionType.Lesson;
                filter.lessonSubfilter = StM.BookPageLessonFilterType.Coach;
                filter.lessonSubfilterId = session.trainerId;
                break;
            }
            case StM.SessionType.Clinic: {
                filter.sessionType = StM.BookPageSessionType.Clinic;
                break;
            }
        }

        return filter;
    }
}