import './scoreboardPage.scss';
import * as React from 'react';
import moment from 'moment';
import ClassNames from 'classnames';
import { connect } from 'react-redux';
import * as _ from 'lodash';

import * as StM from '../../../models/store';
import * as ActM from '../../../actions';
import * as SrvM from '../../../services';
import { Images } from '../../../constants';

const utils = new SrvM.Utils();

interface IScoreboardPageProps {
    club: StM.IClubStoreState;
    coaches: Array<StM.ICoachStoreState>;
    courtId: number;
    sessions: Array<StM.ISessionStoreState>;

    init: (courtId: number, startDate: moment.Moment, needSpinner?: boolean) => Promise<Array<StM.ISessionStoreState>>;
}

interface IScoreboardPageState {
    currentClubTime: moment.Moment;
    checkingSessions: boolean;
    currentSession?: StM.ISessionStoreState;
    nextSession?: StM.ISessionStoreState;
    pageLoadedAt: moment.Moment;
}

class ScoreboardPage extends React.Component<IScoreboardPageProps, IScoreboardPageState>{
    private intervalID: any;
    private checkSessionsIntervalID: any;
    private endingMinutes = 5;
    private secsInMin = 60;
    private refreshPageAt = moment({ hours: 2, minutes: 0 });
    private colorConverter = new SrvM.ColorConverter();
    private styleColor: string;
    private styleFilter: string;
    constructor(props: IScoreboardPageProps) {
        super(props);

        this.state = {
            currentClubTime: utils.getCurrentClubDateTime(props.club),
            checkingSessions: false,
            currentSession: null,
            nextSession: null,
            pageLoadedAt: moment(),
        }
    }

    componentDidMount() {
        document.body.classList.add('scoreboard');
        this.intervalID = setInterval(() => this.tick(), 1000);
        this.setState({ pageLoadedAt: utils.getCurrentClubDateTime(this.props.club) });
        this.props.init(this.props.courtId, this.state.currentClubTime, true).then(() => {
            this.checkSessionsIntervalID = setInterval(() => this.checkSessions(), this.secsInMin * 1000);
        });
    }

    componentDidUpdate(prevProps: IScoreboardPageProps) {
        const isCourtChanged = prevProps.courtId !== this.props.courtId;
        const isClubChanged = !_.isEqual(prevProps.club, this.props.club);
        const isSessionsChanged = !_.isEqual(prevProps.sessions, this.props.sessions);

        if ((isCourtChanged || isClubChanged) && !!this.props.club && !!this.props.club.id) {
            this.props.init(this.props.courtId, this.state.currentClubTime, true).then(() => {
                this.checkSessionsIntervalID = setInterval(() => this.checkSessions(), this.secsInMin * 1000);
            });
        }

        if (isSessionsChanged) {
            this.setCurrentAndNextSessions(this.props.sessions);
        }
    }

    componentWillUnmount() {
        document.body.classList.remove('scoreboard');
        clearInterval(this.intervalID);
        clearInterval(this.checkSessionsIntervalID);
    }

    render() {
        const currentSession = this.state.currentSession;
        const nextSession = this.state.nextSession;
        const club = this.props.club;
        const hasCurrentSession = !!currentSession;
        if (!club || !club.id) return null;
        const hasNextSession = !!nextSession;
        const sessionForStyles = hasCurrentSession ? currentSession : nextSession;

        const pageClasses = ClassNames(
            'scoreboard-page page',
            { 'session': !!currentSession },
            utils.getSessionClass(sessionForStyles)
        );

        let styles: any = {};
        if (sessionForStyles && this.styleColor !== sessionForStyles.customBackgroundColor) {
            this.styleColor = sessionForStyles.customBackgroundColor;
            this.styleFilter = this.styleColor ? this.colorConverter.hexToFilter(this.styleColor) : null;
        } else if (!sessionForStyles) {
            this.styleFilter = null;
            this.styleColor = null;
        }
        styles.filter = this.styleFilter;

        return (
            <div className={pageClasses}>
                <div className="image-background"></div>
                <video className="video-background" playsInline={true} preload="auto" autoPlay={true} loop={true} muted={true}>
                    <source itemType="video/webm" src="/content/img/calendar-background.webm" />
                    <source itemType="video/mp4" src="/content/img/calendar-background.mp4" />
                    <source itemType="video/ogg" src="/content/img/calendar-background.ogv" />
                </video>
                <div className="background-overlay"></div>
                <div className="lines-wrapper">
                    <div className="line line-corner" style={styles}></div>
                    <div className="line line-simple" style={styles}></div>
                    <div className="lines-bg"></div>
                </div>
                {hasCurrentSession && this.renderCurrentSessionPage(currentSession, nextSession)}
                {!hasCurrentSession && this.renderEmptyPage(nextSession)}
            </div>
        );
    }

    private renderCurrentSessionPage(currentSession: StM.ISessionStoreState, nextSession: StM.ISessionStoreState) {
        if (!currentSession) return null;
        const endTimeDuration = utils.getDurationTime(currentSession.endDateTime);
        const currentTimeDuration = utils.getDurationTime(this.state.currentClubTime);
        const minutesLeft = Math.floor(endTimeDuration.asMinutes() - currentTimeDuration.asMinutes());
        let secondsLeft = (this.secsInMin - currentTimeDuration.seconds());
        secondsLeft = secondsLeft !== this.secsInMin ? secondsLeft : 0;
        const timeLeftClasses = ClassNames('time-left-wrapper', { 'ending': minutesLeft < this.endingMinutes });
        return (
            <div className="current-session-wrapper">
                {!!currentSession && (
                    <div className="current-session-info">
                        {this.renderVideoIcon(currentSession)}
                        <div className="session-title">{utils.getSessionTitle(currentSession, false)}</div>
                        {this.renderCoaches(currentSession)}
                        {this.renderPlayers(currentSession)}
                        <div className={timeLeftClasses}>
                            <div className="time-unit-wrapper">
                                <div className="value">{this.padTimeUnit(minutesLeft)}</div>
                                <div className="title">minutes</div>
                            </div>
                            <div className="delimiter">:</div>
                            <div className="time-unit-wrapper">
                                <div className="value">{this.padTimeUnit(secondsLeft)}</div>
                                <div className="title">seconds</div>
                            </div>
                        </div>
                    </div>
                )}
                {this.renderNextSession(nextSession)}
            </div>
        );
    }

    private renderNextSession(session: StM.ISessionStoreState) {
        if (!session) return null;
        const sessionTitle = utils.getSessionTitle(session, false);
        const startTime = session.startDateTime.format('h:mm A');
        return (
            <div className="next-session-info">
                <div className="title">Next:</div>
                <div className="session-title">{sessionTitle} {startTime}</div>
            </div>
        );
    }

    private renderVideoIcon(session: StM.ISessionStoreState) {
        if(!utils.hasVideo(session, null, true)) return null;
        return <img className="ic_video" src={Images.VideoIcon} alt='Video recording enabled' />
    }

    private renderEmptyPage(nextSession: StM.ISessionStoreState) {
        return (
            <div className="empty-wrapper">
                {!!nextSession && (
                    <>
                        {this.renderVideoIcon(nextSession)}
                        <div className="next-session-info">
                            <div className="title">
                                <div>Next session</div>
                                <div>Starts at</div>
                            </div>
                            <div className="start-time">{nextSession.startDateTime.format('h:mm A')}</div>
                            <div className="session-title">{utils.getSessionTitle(nextSession, false)}</div>
                            {this.renderCoaches(nextSession)}
                            {this.renderPlayers(nextSession)}
                        </div>
                    </>
                )}
                {this.renderCurrentTime()}
            </div>
        );
    }

    private renderCurrentTime() {
        return (
            <div className="current-time-wrapper">
                <div className="title">Now:</div>
                <div className="time">{this.state.currentClubTime.format('h:mm A')}</div>
            </div>
        );
    }

    private renderPlayers(session: StM.ISessionStoreState) {
        if (!session || (session.type !== StM.SessionType.Play && session.type !== StM.SessionType.Private)) return null;
        const bookings = session.bookings.filter(booking => {
            return !_.includes([
                StM.BookingStatus.Cancelled
                , StM.BookingStatus.LateCancel
                , StM.BookingStatus.LateCancelPayFail
            ], booking.status);
        });
        if (!bookings || bookings.length === 0) return null;
        return (
            <div className="players-wrapper">
                <ul className="users-list">
                    {bookings.map((booking, index) => {
                        return <li key={index} className="user-item">{booking.user.displayName}</li>
                    })}
                </ul>
            </div>
        );
    }

    private renderCoaches(session: StM.ISessionStoreState) {
        if (!session || session.type === StM.SessionType.Play) return null;
        const mainTrainer = _.find(this.props.coaches, { id: session.trainerId });
        let assistants: Array<StM.IPublicUserStoreState> = [];
        if (session.type === StM.SessionType.Custom) {
            assistants = session.assistants;
        }
        const hasAssistants = !!assistants && assistants.length > 0;
        const tier = hasAssistants ? 'Lead Coach' : 'Coach';

        return (
            <div className="coaches-wrapper">
                <ul className="users-list">
                    {!!mainTrainer && <li className="user-item">{mainTrainer.displayName} ({tier})</li>}
                    {hasAssistants && assistants.map((assistant, index) => {
                        return <li key={index} className="user-item">{assistant.displayName}</li>
                    })}
                </ul>
            </div>
        );
    }

    private tick() {
        const club = this.props.club;
        const currentTime = utils.getCurrentClubDateTime(club);
        this.checkTimeAndRefreshPage(currentTime);
        this.setState({ ...this.state, currentClubTime: currentTime }, () => {
            this.setCurrentAndNextSessions(this.props.sessions);
        });
    }

    private checkTimeAndRefreshPage(currentTime: moment.Moment) {
        if (currentTime.isSame(this.state.pageLoadedAt, 'day')) return;
        const isSameHour = currentTime.isSame(this.refreshPageAt, 'hours');
        const isSameMinute = currentTime.isSame(this.refreshPageAt, 'minutes');
        if (isSameHour && isSameMinute) {
            window.location.reload();
        }
    }

    private checkSessions() {
        if (!this.state.checkingSessions) {
            this.setState({ ...this.state, checkingSessions: true }, () => {
                this.props.init(this.props.courtId, this.state.currentClubTime, false)
                    .then(() => {
                        this.setState({ ...this.state, checkingSessions: false })
                    });
            })
        }
    }

    private padTimeUnit(value: number): string {
        return _.padStart(value.toString(), 2, '0');
    }

    private setCurrentAndNextSessions(sessions: Array<StM.ISessionStoreState>) {
        const currentTime = this.state.currentClubTime.format();
        sessions = sessions.filter(session => {
            const startTime = session.startDateTime.format();
            const endTime = session.endDateTime.format();
            const isCurrent = startTime <= currentTime && endTime >= currentTime;
            const isAfter = startTime > currentTime;
            return isCurrent || isAfter;
        });

        let currentSession: StM.ISessionStoreState = null;
        let nextSession: StM.ISessionStoreState = null;

        if (sessions && sessions.length > 0) {
            const firstSession = sessions[0];
            const hasCurrentSession = firstSession.startDateTime.format() <= currentTime
                && firstSession.endDateTime.format() >= currentTime;

            if (hasCurrentSession) {
                currentSession = firstSession;
                nextSession = sessions.length > 1 ? sessions[1] : null;
            } else {
                nextSession = firstSession;
            }
        }

        this.setState({ ...this.state, currentSession, nextSession });
    }
}

function mapStateToProps(state: StM.IGlobalStoreState, ownProps: any) {
    return {
        club: state.club
        , courtId: +ownProps.match.params.courtId
        , sessions: state.pages.scoreboard.sessions
        , coaches: state.coaches
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        init: (courtId: number, startDate: moment.Moment, needSpinner?: boolean) =>
            dispatch(ActM.ScoreboardPageActions.init(courtId, startDate, needSpinner))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ScoreboardPage);