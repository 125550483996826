import './clinicListView.scss';
import moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import * as _ from 'lodash';

import { StM, ActM, SrvM } from '../../../modules';
import ClinicItem from '../../../components/pages/clinics/clinicItem';

interface IClinicListViewProps {
    isAuthorized: boolean;
    routeParams: StM.IBookPageRouteParams;
    clinics: Array<StM.ISessionStoreState>;
    basketSessions: Array<StM.ISessionStoreState>;
    club: StM.IClubStoreState;
    user: StM.IUserStoreState;
    isLoading: boolean;
    isFinalized: boolean;

    init: () => Promise<any>;
    onFilterChange: (params: any) => void;
    go: (url: string) => any;
}

interface IClinicsPageState { }

interface IClinicsDayItem {
    date: moment.Moment;
    title: string;
    array: Array<StM.ISessionStoreState>;
}

class ClinicListView extends React.Component<IClinicListViewProps, IClinicsPageState> {
    filters: Array<any>;
    private routeService: SrvM.RouteService;

    constructor(props: IClinicListViewProps) {
        super(props);
        this.filters = [
            { tag: 'all', label: 'All', filter: (session: StM.ISessionStoreState) => true }
        ];
        this.routeService = new SrvM.RouteService();
    }

    public componentDidMount() {
        if (this.props.isFinalized) this.props.init();
    }

    public componentDidUpdate(prevProps: IClinicListViewProps) {
        if (this.props.isFinalized !== prevProps.isFinalized && this.props.isFinalized) {
            this.props.init();
        }
    }

    public render() {
        let filteredSessions = new Array<StM.ISessionStoreState>();
        let availableFilters = this.filters;
        let isActive = this.isFilterActive.bind(this);

        if (!this.isFilterActive('all')) {
            this.props.clinics.forEach(function (session) {
                let isMatch = true;
                _.each(availableFilters, (filter) => { isMatch = isMatch && (isActive(filter.tag) ? filter.filter(session) : true) });

                if (isMatch) filteredSessions.push(session);
            });
        }
        else filteredSessions = this.props.clinics;

        filteredSessions = _.sortBy(filteredSessions, (clinic: StM.ISessionStoreState) => {
            return clinic.startDateTime.valueOf();
        });

        let filteredSessionsLength = filteredSessions.length;

        let daysList = this.getDaysList(filteredSessions);

        if (!this.props.clinics.length) return (
            <div className="main-content-wrapper clinic-list">
                <div className="header-clinic-wrapper">
                    <div className="title">Join a Clinic</div>
                    <div className="sub-title">&nbsp;</div>
                </div>
                <div className="days-wrapper clinic">
                    {this.props.isLoading ? (<p> Loading, please wait </p>) : (<p>No clinics available right now. <a href="#" onClick={(e) => { this.onBookPageClick(e) }}>Fire up your game</a></p>)}
                </div>
            </div>
        );

        return (
            <div className="main-content-wrapper clinic-list">
                <div className="header-clinic-wrapper">
                    <div className="title">Join a Clinic</div>
                    <div className="clinic-count-wrapper">
                        <div className="clinic-count">{filteredSessionsLength}</div>
                        <div className="clinic-label">Clinic{filteredSessionsLength > 1 ? "s" : ""}</div>
                    </div>
                    <div className="sub-title">Learn with a group! Timetable for upcoming clinics.</div>
                </div>
                <div className="filter-clinic-wrapper">
                    {this.filters.map((filter, index) => {
                        return <div key={filter.tag} className={'filter-clinic-item ' + (this.isFilterActive(filter.tag) ? 'active' : '')} onClick={() => this.changeFilter(filter.tag)}>{filter.label}</div>
                    })}
                </div>
                <div className="days-wrapper clinic">{this.renderDayList(daysList)}</div>
            </div>
        );
    }

    private renderDayList(list: Array<IClinicsDayItem>) {
        return list.map((item, index) => {
            let weekday = item.date.format('dddd'),
                month = item.date.format('MMM'),
                date = item.date.format('DD');
            return (
                <div className="day-item" key={index}>
                    <div className="title-wrapper">
                        <div className="day-of-week">{weekday}</div>
                        <span className="date">{date}</span> <span className="month">{month}</span>
                    </div>
                    <div className="session-wrapper">
                        {item.array.map((session, index) => {
                            return <ClinicItem session={session} user={this.props.user} key={index} params={this.props.routeParams} />
                        })}
                    </div>
                </div>
            );
        });
    }

    private getDate(date: moment.Moment) {
        return date.clone().startOf('day');
    }

    private getDaysList(sessions: Array<StM.ISessionStoreState>) {
        let result: Array<IClinicsDayItem> = [];
        for (let i = 0; i < sessions.length; i++) {
            let session = sessions[i];
            let date = this.getDate(session.startDateTime);
            let isInArray = false;
            for (let j = 0; j < result.length; j++) {
                let dayItem = result[j];
                if (date.isSame(dayItem.date)) {
                    isInArray = true;
                    dayItem.array.push(session);
                    break;
                }
            }
            if (!isInArray) {
                result.push({ date: date, array: [session], title: date.format() });
            }
        }
        return result;
    }

    private changeFilter(filterId: string) {
        let filterString = 'all';

        if (filterId !== 'all' || filterId) {
            let filters = this.props.routeParams.clinicSubfilterId.split(',');

            _.pull(filters, 'all');

            if (this.isFilterActive(filterId)) {
                _.pull(filters, filterId);
            }
            else {
                filters.push(filterId);
            }

            filterString = _.uniq(filters).join(',');
        }

        this.props.onFilterChange({ sessionType: StM.BookPageSessionType.Clinic, clinicSubfilter: 'list', clinicSubfilterId: filterString });
    }

    private isFilterActive(filterId: string) {
        let filters = this.props.routeParams.clinicSubfilterId.split(',');
        return _.indexOf(filters, filterId) >= 0;
    }

    private onBookPageClick(e) {
        if (e) { e.preventDefault(); e.stopPropagation(); }
        this.props.go(this.routeService.getDefaultPage());
    }
}

function mapStateToProps(state: StM.IGlobalStoreState, ownProps: any) {
    return {
        isAuthorized: state.app.isAuthorized,
        user: state.user,
        club: state.club,
        clinics: state.pages.book.clinics,
        basketSessions: state.basket.goods,
        isLoading: state.app.isLoading,
        isFinalized: state.app.isFinalized,
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        init: () => dispatch(ActM.ClinicListViewActions.init()),
        go: (url: string) => dispatch(ActM.RouteActions.replace(url))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClinicListView);
