import './videoMenu.mobile.scss';
import * as React from 'react';
import * as Redux from 'redux';
import { connect } from 'react-redux';
import * as ActM from '../../actions';
import * as StM from '../../models/store';
import { CourtSelectorMobile } from '../../components/pages/video/courtSelector.mobile';

export interface IBurgerMenuMobileProps {
    courts: Array<StM.ICourtStoreState>;
    session: StM.ISessionStoreState;
    selectedYoutubeId: StM.IYoutubeIdStoreState;

    closeMenu: () => Promise<any>;
    onChangeCourtId: (youtubeId: StM.IYoutubeIdStoreState) => Promise<any>;

    match: any;
    location: any;
    history: any;
}

export class VideoMenuMobile extends React.Component<IBurgerMenuMobileProps> {
    constructor(props: IBurgerMenuMobileProps) {
        super(props);
    }

    render() {
        if (!this.props.session) return null;
        return (
            <div className="menu-wrapper-mobile video-menu-mobile">
                <CourtSelectorMobile
                    courts={this.props.courts}
                    youtubeIds={this.props.session.youtubeIds}
                    selectedYoutubeId={this.props.selectedYoutubeId}
                    onCourtSelected={(youtubeId) => this.onChangeCourt(youtubeId)}
                />
            </div>
        )
    }

    private onChangeCourt(youtubeId: StM.IYoutubeIdStoreState) {
        this.props.closeMenu();
        this.props.onChangeCourtId(youtubeId);
    }
}

const mapStateToProps = (state: StM.IGlobalStoreState, ownProps: any) => {
    return {
        courts: state.club.courts
        , selectedYoutubeId: state.pages.video.selectedYoutubeId
        , session: state.pages.video.session
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        closeMenu: () => dispatch(ActM.BurgerMenuActions.close())
        , onChangeCourtId: (youtubeId: StM.IYoutubeIdStoreState) => dispatch(ActM.VideoPageActions.changeYoutubeId(youtubeId))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(VideoMenuMobile);