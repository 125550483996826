import * as React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { ActM, StM, SrvM } from '../../modules';
import CreditsWalletMobile from '../../components/creditsWallet.mobile';
import { Menu, MenuItem } from '../../components/UI';
import { IClubStoreState } from "../../models/store";

export interface IClientMenuMobileProps {
    isAuthorized: boolean;
    user: StM.IUserStoreState;
    club: StM.IClubStoreState;
    clubs: StM.IClubStoreState[];
    customSessionTypes: Array<StM.ICustomSessionTypeStoreState>;
    groupAccounts: StM.IGroupStoreState[];
    authTicket: StM.IAuthenticationTicketStoreState;

    cloSrvMenu: () => Promise<any>;
    signOut: () => Promise<any>;
    openAuthDialog: () => Promise<any>;
    go: (url: string) => void;
    showSpinner: () => any;
    hideSpinner: () => any;
    closeDialog: (dialogName: string) => void;
    switchClub: (club: IClubStoreState) => void;
    loginOnBehalf: (userId: string) => Promise<void>;
    revertToSelf: () => Promise<void>;

    match: any;
    location: any;
    history: any;
}

export class ClientMenuMobile extends React.Component<IClientMenuMobileProps> {
    private routeService = new SrvM.RouteService();
    private authSrv = new SrvM.AuthenticationService();
    private maxGroupsToShow = 10;

    constructor(props: IClientMenuMobileProps) {
        super(props);
    }

    public render() {
        const mySessionsRoute = this.routeService.getMySessionsDefaultRoute(this.props.club);
        const ownerPrefix = this.authSrv.getOwnerPrefix(this.props.user);
        const profileRoute = "{0}/{1}".format(StM.Pages.User, StM.UserPages.Profile);
        const coachesRoute = "{0}".format(StM.Pages.Coaches);
        const pricingRoute = "{0}".format(StM.Pages.Pricing);
        const contactsRoute = "{0}".format(StM.Pages.Contacts);
        const packagesRoute = "{0}".format(StM.Pages.Packages);
        const isCoach = this.authSrv.isInRoles([StM.Roles.Coach, StM.Roles.CoachAdmin], this.props.user);
        const isGroupAccount = this.authSrv.isInRole(StM.Roles.GroupAccount, this.props.user);
        const isSwitchableToSelf = isGroupAccount && !!this.props.authTicket.self && this.props.authTicket.impersonated;

        // We add root DIV for each menu block bc burger component will replace it by bm-item
        return (
            <div className="menu-wrapper-mobile client-menu-mobile">
                <div>
                    <div className="menu-block session-block">
                        <span className="menu-item menu-header">Book Sessions</span>
                        <NavLink to={this.getBookRoute(StM.BookPageSessionType.Play)} className="menu-item play" onClick={() => this.onSessionClick(StM.BookPageSessionType.Play)}>Play</NavLink>
                        <NavLink to={this.getBookRoute(StM.BookPageSessionType.Lesson)} className="menu-item lesson" onClick={() => this.onSessionClick(StM.BookPageSessionType.Lesson)}>Lesson</NavLink>
                        <NavLink to={this.getBookRoute(StM.BookPageSessionType.Clinic)} className="menu-item clinic" onClick={() => this.onSessionClick(StM.BookPageSessionType.Clinic)}>Clinic</NavLink>
                        <NavLink to={this.getBookRoute(StM.BookPageSessionType.Join)} className="menu-item join-session" onClick={() => this.onSessionClick(StM.BookPageSessionType.Join)}>Other Sessions</NavLink>
                    </div>
                </div>

                {this.props.isAuthorized ?
                    (<div>
                        <div className="menu-block signed-block">
                            <NavLink 
                                className="menu-item" 
                                to={mySessionsRoute}
                                title={`${ownerPrefix} Sessions`}
                                onClick={() => this.onMenuItemClick()}
                            >
                                {ownerPrefix} Sessions
                            </NavLink>
                            {!isGroupAccount && (this.props.clubs.length > 1) && 
                                <Menu>
                                    <MenuItem key={'my-clubs'} collapse label='My Clubs'>
                                        {this.renderMyClubsMenu()}
                                    </MenuItem>
                                </Menu>
                            }
                            {!isGroupAccount && 
                                <Menu>
                                    <MenuItem key={'my-groups'} collapse label='My Groups'>
                                        {this.renderMyGroupsMenu()}
                                    </MenuItem>
                                </Menu> 
                            }
                            {isGroupAccount && 
                                <NavLink 
                                    className="menu-item" 
                                    to={`${StM.Pages.User}/${StM.UserPages.GroupMembers}`} 
                                    title='Group Members'
                                    onClick={() => this.onMenuItemClick()}
                                >
                                    Group Members
                                </NavLink>
                            }
                            {isCoach && 
                                <div 
                                    className="menu-item" 
                                    onClick={() => this.onSessionClick(StM.BookPageSessionType.Availability)}
                                >
                                    EDIT AVAILABILITY
                                </div>
                            }
                            <NavLink 
                                className="menu-item" 
                                to={profileRoute} 
                                onClick={() => this.onMenuItemClick()}
                            >
                                Profile
                            </NavLink>
                            {isSwitchableToSelf && 
                                <MenuItem key={'self-switch'}>
                                    <a 
                                        onClick={(e) => this.onSwitchToSelfClick(e)} 
                                        title={`Switch to ${this.props.authTicket.self}`}
                                    >
                                        Switch to {this.props.authTicket.self}
                                    </a>
                                </MenuItem>
                            }
                            <div className="profile-item-mobile mobile-row">
                                <div className="menu-item profile-link mobile-col-10">
                                    <NavLink 
                                        className="username" 
                                        to={profileRoute} 
                                        onClick={() => this.onMenuItemClick()}
                                    >
                                        {this.props.user.displayName}
                                    </NavLink>
                                </div>
                                <div className="menu-item logout-btn-mobile mobile-col-2" onClick={(e) => { this.onSignOutClick(e) }}>
                                    <img src="/content/img/logout.svg" />
                                </div>
                            </div>
                        </div>
                    </div>) :
                    (<div>
                        <div className="menu-block login-block">
                            <div className="menu-item" onClick={(e) => { this.onLogInClick(e) }}>LOG IN</div>
                        </div>
                    </div>)
                }
                {this.props.isAuthorized && <CreditsWalletMobile user={this.props.user} customSessionTypes={this.props.customSessionTypes}/>}
                <div>
                    <div className="menu-block">
                        <NavLink className="menu-item" to={coachesRoute} onClick={() => this.onMenuItemClick()}>Coaches</NavLink>
                        {!!this.props.club.priceList && (<NavLink className="menu-item" to={pricingRoute} onClick={() => this.onMenuItemClick()}>Pricing</NavLink>)}
                        <NavLink className="menu-item" to={packagesRoute} onClick={() => this.onMenuItemClick()}>Packages</NavLink>
                        <NavLink className="menu-item" to={contactsRoute} onClick={() => this.onMenuItemClick()}>Contacts</NavLink>
                    </div>
                </div>
            </div>
        )
    }

    private renderMyGroupsMenu() {
        let userGroups = this.props.groupAccounts.filter((item) => 
            item.members.some((member) => member.user 
                && member.user.id === this.props.user.id 
                && member.canLoginAsGroupAccount)
            );
        const showMoreGroupsButton = userGroups.length > this.maxGroupsToShow;
        if(showMoreGroupsButton) {
            userGroups = userGroups.slice(0, this.maxGroupsToShow - 1);
        }
        return (
            <Menu>
                <MenuItem key={'summary'}>
                    <NavLink 
                        to={`${StM.Pages.User}/${StM.UserPages.MyGroups}`} 
                        title='Summary'
                        onClick={() => this.onMenuItemClick()}
                    >
                        Summary
                    </NavLink>
                </MenuItem>
                {userGroups.map((group, index) => (
                    <MenuItem key={index}>
                        <a 
                            onClick={(e) => this.onGroupAccountClick(e, group.groupAccountId)} 
                            title={`Switch to ${group.title} View`}
                        >
                            {group.title}
                        </a>
                    </MenuItem>
                ))}
                {showMoreGroupsButton && 
                    <MenuItem key={'show-more'}>
                        <NavLink 
                            to={`${StM.Pages.User}/${StM.UserPages.MyGroups}`} 
                            title='Go to My Groups page'
                        >
                            More...
                        </NavLink>
                    </MenuItem>
                }
            </Menu>
        );
    }

    private renderMyClubsMenu() {
        let userClubs = this.props.clubs;

        return (
            <Menu>
                {userClubs.map((club, index) => (
                    <MenuItem key={index}>
                        <a className='menu-mobile-item' onClick={(e) => this.onSwitchClubClick(e, club)} title={`Switch to ${club.title}`}>{club.title}</a>
                    </MenuItem>
                    ))}
            </Menu>
        );
    }

    private onSwitchClubClick(e: any, club: IClubStoreState) {
        this.props.cloSrvMenu();
        this.closeDialogs();
        if(e) e.preventDefault();

        if (club.id != this.props.club.id) {
            this.props.switchClub(club);
        }
    }

    private getBookRoute(sessionType: string) {
        const handledFilter: StM.IBookPageRouteParams = {
            sessionType
            , date: null
            , playSubfilter: null
            , lessonSubfilter: null
            , lessonSubfilterId: null
            , clinicSubfilter: null
            , clinicSubfilterId: null
            , otherSubfilter: null
            , memberId: null
        };
        return this.routeService.getBookPage(handledFilter).path;
    }

    private changeUrl(type: string) {
        const prom = new Promise<void>((resolve, reject) => {
            const path = this.getBookRoute(type);
            if (window.location.pathname != path)
                this.props.go(path);
            else this.props.hideSpinner();
            return resolve();
        });

        return prom;
    }

    private onSignOutClick(e: any) {
        if (e) { e.preventDefault(); e.stopPropagation() }
        this.props.signOut().then(() => {
            this.props.cloSrvMenu();
            this.closeDialogs();

            new SrvM.RequestCacheService().reset();
        });
    }

    private onLogInClick(e: any) {
        if (e) { e.preventDefault(); e.stopPropagation() }
        this.props.openAuthDialog();
        this.props.cloSrvMenu()
        this.closeDialogs();
    }

    private onMenuItemClick() {
        this.props.cloSrvMenu();
        this.closeDialogs();
    }

    private onSessionClick(sessionType: string) {
        this.props.showSpinner();
        this.props.cloSrvMenu()
            .then(() => {
                this.props.hideSpinner();
                return this.changeUrl(sessionType);
            }).then(() => {
                this.props.hideSpinner();
                this.closeDialogs();
            });
    }

    private onGroupAccountClick(e: any, groupAccountId: string) {
        this.props.cloSrvMenu();
        this.closeDialogs();
        if(e) e.preventDefault();
        this.props.loginOnBehalf(groupAccountId);
    }

    private onSwitchToSelfClick(e: any) {
        this.props.cloSrvMenu();
        this.closeDialogs();
        if(e) e.preventDefault();

        const isselfAsAdmin = this.props.authTicket.selfAsAdmin;
        this.props.revertToSelf()
            .then(() => {
                if(!isselfAsAdmin) {
                    this.props.go(StM.Pages.Book);
                }
            });
    }

    private closeDialogs() {
        this.props.closeDialog(StM.DialogNames.Waiver);
        this.props.closeDialog(StM.DialogNames.DemographicInformation);
        this.props.closeDialog(StM.DialogNames.NewSession);
        this.props.closeDialog(StM.DialogNames.SessionInfo);
        this.props.closeDialog(StM.DialogNames.ContactUs);
        this.props.closeDialog(StM.DialogNames.CreateGroupAccount);
        this.props.closeDialog(StM.DialogNames.UserEdit);
        this.props.closeDialog(StM.DialogNames.Book);
        this.props.closeDialog(StM.DialogNames.PackagePurchase);
    }
}

const mapStateToProps = (state: StM.IGlobalStoreState, ownProps: any) => {
    return {
        isAuthorized: state.app.isAuthorized, 
        user: state.user, 
        club: state.club, 
        customSessionTypes: state.customSessions,
        clubs: state.clubs,
        groupAccounts: state.groupAccounts,
        authTicket: state.authenticationTicket,
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        cloSrvMenu: () => dispatch(ActM.BurgerMenuActions.close()),
        signOut: () => dispatch(ActM.AccountActions.logout()),
        openAuthDialog: () => dispatch(ActM.DialogActions.open(StM.DialogNames.Auth, { tab: StM.AuthDialogTabs.SignIn, returnUrl: '' })),
        go: (url: string) => dispatch(ActM.RouteActions.push(url)),
        showSpinner: () => dispatch(ActM.AppActions.showSpinner()),
        hideSpinner: () => dispatch(ActM.AppActions.hideSpinner()),
        closeDialog: (dialogName: string) => dispatch(ActM.DialogActions.close(dialogName)),
        switchClub: (club: IClubStoreState) => dispatch(ActM.AccountActions.switchClub(club)),
        loginOnBehalf: (userId: string) => dispatch(ActM.AccountActions.loginOnBehalf(userId)),
        revertToSelf: () => dispatch(ActM.AccountActions.revertToSelf())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientMenuMobile);
