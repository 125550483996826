import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as StM from '../../../../models/store';
import * as PolM from '../../../../policies';
import { IBaseBadgeProps, DurationClasses } from './baseBadge.mobile';
import { PriceBadgeMobile } from './priceBadge.mobile';
import { EventBadgeMobile } from './eventBadge.mobile';
import * as SrvM from '../../../../services';

interface IBadgeBadgeProps extends IBaseBadgeProps {
    user?: StM.IUserStoreState;
    club: StM.IClubStoreState
    basket: StM.IBasketStoreState;
    courts: Array<StM.ICourtStoreState>;
    sessionType: string;
    courtTimeBlocks: Array<StM.ICourtTimeBlockStoreState>;
    notifications: Array<StM.INotificationStoreState>;
    timeKey: string;
    expandedSlotTimeKey: string;
    coaches: Array<StM.ICoachStoreState>;
    coachFeeTiers: Array<StM.ICoachFeeTierStoreState>;
    params: StM.IBookPageRouteParams;
    onCreateSessionClick: (courtId: number, timeKey: string) => any;
    openSessionClick: (from: string, id: number) => void;
    onExpandSlot: (timeKey: string) => void;
}

interface IBadgeState {
}

const utils = new SrvM.Utils();

export default class BadgeMobile extends React.Component<IBadgeBadgeProps, IBadgeState> {
    constructor(props: IBadgeBadgeProps) {
        super(props);
    }

    render() {
        let badge = this.getBadge(this.props.courtTimeBlocks, this.props.sessionType);
        return badge;
    };

    private getIsPrivate(time: StM.ICourtTimeBlockStoreState, sessionType: string) {
        const sessionAvailability = new PolM.SessionAvailabilityPolicy(time.session, [], this.props.user);
        let isPrivate = !(
            time.isBought
            || sessionAvailability.getIsTrainer()
            || time.isSessionFromBasket
            || (time.isAvailableTime && time.isFilter));
        return isPrivate;
    }

    private getEventBadgeCondition(courtTimeBlocks: Array<StM.ICourtTimeBlockStoreState>, sessionType: string) {
        return courtTimeBlocks.some(block => {
            let result = false;
            const isBought = block.isBought;
            const isInvited = block.isInvited;
            const isOpenBoard = block.isOpenBoard;
            const isShared = this.getIsShared(block.session);
            const sessionAvailability = new PolM.SessionAvailabilityPolicy(block.session, []);
            const isOwner = sessionAvailability.getIsOwner();
            const isTrainer = block.session
                && (block.session.type === StM.SessionType.Private || block.session.type === StM.SessionType.Custom)
                && sessionAvailability.getIsTrainer();
            const isFuture = block.isFutureTime;
            const isAvailableTime = block.isAvailableTime;
            const isClinic = block.session.type == StM.SessionType.Clinic;
            const isCustom = block.session.type === StM.SessionType.Custom;
            const isCustomClinic = isCustom && block.session.typeAlias === 'clinic';

            switch (sessionType) {
                case StM.BookPageSessionType.Join:
                    result = isOpenBoard && !isOwner && isShared && isFuture && !isBought && isAvailableTime;
                    break;
                case StM.BookPageSessionType.Clinic:
                    result = ((isClinic || isCustomClinic) && !isBought && isFuture && isAvailableTime) || isBought || isTrainer;
                    break;
                case StM.BookPageSessionType.Play:
                case StM.BookPageSessionType.Lesson:
                default:
                    result = isBought || (isInvited && isAvailableTime) || isTrainer;
                    break;
            }
            return result;
        });
    }

    private getIsShared(session: StM.ISessionStoreState): boolean {
        return session && !session.isHidden;
    }

    private getBadge(courtTimeBlocks: Array<StM.ICourtTimeBlockStoreState>, sessionType: string): any {
        const sessions = this.props.courtTimeBlocks.filter(block => !!block.session && block.isFutureTime);
        if (sessions && sessions.length) {
            const isAllCourtsBooked = sessionType != StM.BookPageSessionType.Join && sessionType != StM.BookPageSessionType.Clinic &&
                this.props.courtTimeBlocks.every(block => !!block.session && this.getIsPrivate(block, sessionType));
            if (isAllCourtsBooked) {
                return (
                    <div className="badge-mobile"></div>
                );
            }
            if (this.getEventBadgeCondition(sessions, sessionType)) {
                return (
                    <EventBadgeMobile
                        user={this.props.user}
                        club={this.props.club}
                        basket={this.props.basket}
                        courtTimeBlocks={this.props.courtTimeBlocks}
                        courts={this.props.courts}
                        params={this.props.params}
                        sessionType={this.props.sessionType}
                        expandedSlotTimeKey={this.props.expandedSlotTimeKey}
                        timeKey={this.props.timeKey}
                        coaches={this.props.coaches}
                        coachFeeTiers={this.props.coachFeeTiers}
                        onExpandSlot={(timeKey) => this.props.onExpandSlot(timeKey)}
                        onCreateSessionClick={(courtId, timeKey) => this.props.onCreateSessionClick(courtId, timeKey)}
                        openSessionInfo={(from: string, id: number) => this.props.openSessionClick(from, id)} />
                );
            }
        }
        let isCourtsNotAvailable = this.props.courtTimeBlocks.every(block => { return block.isNotAvailable });
        let isCourtsAvailable = !this.props.courtTimeBlocks.every(block => { return !(block.isAvailableTime && block.isFilter && !block.session) });
        if (isCourtsNotAvailable) {
            return (
                <div className="badge-mobile"></div>
            );
        } else if (isCourtsAvailable) {
            return (
                <PriceBadgeMobile
                    user={this.props.user}
                    courtTimeBlocks={this.props.courtTimeBlocks}
                    courts={this.props.courts}
                    params={this.props.params}
                    sessionType={this.props.sessionType}
                    expandedSlotTimeKey={this.props.expandedSlotTimeKey}
                    timeKey={this.props.timeKey}
                    coaches={this.props.coaches}
                    coachFeeTiers={this.props.coachFeeTiers}
                    onCreateSessionClick={(courtId, timeKey) => this.props.onCreateSessionClick(courtId, timeKey)}
                    onExpandSlot={(timeKey) => this.props.onExpandSlot(timeKey)} />
            );
        } else {
            return (
                <div className="badge-mobile"></div>
            );
        }
    }

    private getIsFuture(session: StM.ISessionStoreState) {
        return session && utils.getIsFuture(this.props.club, session.endDateTime, utils.getDurationTime(session.endDateTime));
    }
};
