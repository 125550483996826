import '../dialogs.mobile.scss';
import '../legalTextsDialog.mobile.scss';
import '../../legalTexts.scss';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import * as _ from 'lodash';

import { ModalMobile } from '../../../components/modal.mobile';
import * as StM from '../../../models/store';
import * as ActM from '../../../actions';
import Strings from '../../../models/store/strings';

export interface WaiverDialogProps {
    isShow: boolean;
    isShowAuthDialog: boolean;
    isShowAuthDialogSignin: boolean;

    closeDialog: () => void;
    openAuthDialog: () => Promise<any>;
    openTermsAndConditionsDialog: () => Promise<any>;
    accept: () => void;
    decline: () => void;
    logout: () => Promise<any>;
    getStatic: () => Promise<any>;
}

export interface WaiverDialogState {
    isNeedAccept: boolean;
    title: string;
    content: string;
}

class WaiverDialogMobile extends React.Component<WaiverDialogProps, WaiverDialogState> {
    private isAccepted: boolean = false;
    constructor(props: WaiverDialogProps) {
        super(props);

        this.state = {
            isNeedAccept: false,
            title: 'Release Waiver and Grant of Rights',
            content: 'Please wait...'
        };
    }

    componentDidUpdate(prevProps: WaiverDialogProps) {
        const { isShow, isShowAuthDialog, logout } = this.props;

        const isShowChanged = !prevProps.isShow && isShow;
        const isNeedAccept = isShowAuthDialog || this.props.isShowAuthDialogSignin;

        if (isNeedAccept !== this.state.isNeedAccept) {
            this.setState({ isNeedAccept });
        }

        if (isShowChanged) {
            this.init(this.props);
        }

        if (prevProps.isShow && !isShow && isShowAuthDialog && !this.isAccepted) {
            logout();
        }
    }

    render() {
        if (!this.props.isShow) return null;
        return (
            <ModalMobile classes="waiver-modal" dialogName={StM.DialogNames.Waiver} customZIndex={9999}>
                <div className="modal-dialog-header">
                    <div className="title">{this.state.title}</div>
                </div>
                <div className="modal-dialog-body container">
                    <article className="waiver-text-wrapper" dangerouslySetInnerHTML={this.state && { __html: this.state.content }}></article>
                    <div className="divider-line"></div>
                    <div className="btns-wrapper">
                        <div className="mobile-col-6 btn-left-mobile">
                            <button className="btn-mobile btn-decline btn-black" onClick={(e) => this.onDeclineClick(e)}>CANCEL</button>
                        </div>
                        <div className="mobile-col-6 btn-right-mobile">
                            <button className="btn-mobile btn-red" onClick={(e) => this.onAcceptClick(e)}>ACCEPT</button>
                        </div>
                    </div>
                </div>
            </ModalMobile>
        );
    }

    private init(props: WaiverDialogProps) {
        props.getStatic().then((staticPages: Array<IStaticPageDto>) => {
            const page = _.find(staticPages, { name: StM.LegalTexts.Waiver });
            const content = page ? page.content : "Page is currently unavailable";
            const title = page ? page.title : "Not Found";
            this.setState({ content, title });
        });
        this.isAccepted = false;
        props.decline();
    }

    private onDeclineClick(e: any) {
        if (e) { e.preventDefault(); e.stopPropagation(); }
        this.props.closeDialog();
        this.logoutIfNotAcceptedWaiver();
    }

    private onAcceptClick(e: any) {
        if (e) { e.preventDefault(); e.stopPropagation(); }
        this.props.accept();
        this.props.closeDialog();
        this.props.openTermsAndConditionsDialog();
    }

    private logoutIfNotAcceptedWaiver() {
        if (this.props.isShowAuthDialog && this.props.isShowAuthDialogSignin) {
            this.props.logout();
        }
    }
}

const mapStateToProps = (state: StM.IGlobalStoreState, ownProps: any) => {
    return {
        isShow: state.dialogs.waiver.isOpened
        , isShowAuthDialog: state.dialogs.auth.isOpened
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        closeDialog: () => dispatch(ActM.DialogActions.close(StM.DialogNames.Waiver))
        , openAuthDialog: () => dispatch(ActM.DialogActions.open(StM.DialogNames.Auth, { tab: StM.AuthDialogTabs.SignIn }))
        , openTermsAndConditionsDialog: () => dispatch(ActM.DialogActions.open(StM.DialogNames.TermsAndConditions))
        , accept: () => dispatch(ActM.WaiverDialogActions.accept())
        , decline: () => dispatch(ActM.WaiverDialogActions.decline())
        , logout: () => dispatch(ActM.AccountActions.logout())
        , getStatic: () => dispatch(ActM.AppActions.getStatic())
    };

}

export default connect(mapStateToProps, mapDispatchToProps)(WaiverDialogMobile);
