import * as React from 'react';
import { connect } from 'react-redux';
import { ActM } from "../../modules";

interface ISwitchClubPageProps {
    loginClub: (token: string) => void;
}

interface ISwitchClubPageState { }

class SwitchClubPage extends React.Component<ISwitchClubPageProps, ISwitchClubPageState> {

    constructor(props: ISwitchClubPageProps) {
        super(props);
    }

    componentDidMount (){
        let token = this.props.match.params.token

        this.props.loginClub(token);
    }

    render() {
        return (null);
    };
};

function mapDispatchToProps(dispatch: any) {
    return {
        loginClub: (token: string) => dispatch(ActM.AccountActions.loginClub(token))
    }
}

export default connect(null, mapDispatchToProps)(SwitchClubPage);
