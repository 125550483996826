import './myPackagesPage.scss';
import * as React from 'react';
import moment from 'moment';
import _ from 'lodash';

import { ICoachFeeTierStoreState, IPackageSessionTypeStoreState } from '@models/store';
import ButtonGroup from '../../../../components/UI/button-group';
import PackageCurrentInfo, { IPackageInfoCreditsDefinitionWithRemaining } from '../../../../components/pages/packages/packageCurrentInfo';
import { ActM, StM } from '../../../../modules';
import { connect } from 'react-redux';
import SubscriptionCurrentCard from '../../../../components/pages/subscriptionOffer/currentSubscription/subscriptionCurrentCard';
import { toSubscriptionCurrent } from '../../../../components/pages/subscriptionOffer/currentSubscription/convertors';

interface IMyPackagesPageProps {
    user: StM.IUserStoreState;
    currentSubscriptions: IRecurrentPackageCommitmentDetailsDto[];
    subscriptionDefinitions: StM.ISubscriptionOfferStateModel[];
    balances: Array<ICreditsWalletBalanceDto>;
    isAuthorized: boolean;
    club: StM.IClubStoreState;
    customSessionTypes: Array<StM.ICustomSessionTypeStoreState>;
    go: (url: string) => any;
    sessionTypes: IPackageSessionTypeStoreState[];
    coachFeeTiers: ICoachFeeTierStoreState[];
    getCurrentSubscriptions: () => Promise<any>;
    onUserCommitmentCancellation: (id: number) => Promise<any>;
    openCancelConfirmationDialog: (msgKey: string, messageType: string, message?: string, acceptButtonText?: string, rejectButtonText?: string, yesCallback?: () => void) => void;
    openAlertDialog: (msgKey: string, messageType: string, message: string) => Promise<any>;
    closeAlert: () => void;
    openAuthDialog: (url: string) => Promise<any>;
    openBillingInfoDialog: () => Promise<any>;
    closeDialog: () => void;
    checkout: (definition: StM.ISubscriptionOfferStateModel, params: any) => Promise<any>;
    openCheckoutSubscriptionDialog: (subscription: StM.ISubscriptionOfferStateModel) => Promise<any>;
}

interface IMyPackagesPageState {
    validationError: string;
    currentSubpage: string;
}

const ONE_TIME_SUBROUTE = 'one-time';
const SUBSCRIPTIONS_SUBROUTE = 'subscriptions';

class MyPackagesPage extends React.Component<IMyPackagesPageProps, IMyPackagesPageState> {
    private readonly packageSelectorConfig = [
        { label: 'One-Time Packages', value: ONE_TIME_SUBROUTE },
        { label: 'Subscriptions', value: SUBSCRIPTIONS_SUBROUTE },
    ]

    constructor(props: IMyPackagesPageProps) {
        super(props);
        this.state = {
            currentSubpage: '',
            validationError: null,
        }
    }

    componentDidMount() {
        this.props.getCurrentSubscriptions();
    }

    private onListTypeSelect(currentSubpage: string) {
        this.setState({ currentSubpage });
    }

    render() {
        const user = this.props.user;
        const isAuthorized = user && user.id && this.props.isAuthorized;
        const packages = _(this.props.balances || [])
            .map(b => b.package)
            .uniqBy('id')
            .orderBy('expires')
            .valueOf();
        const sortedPackages = packages.filter(p => !p.recurrentPackageCommitment);
        const sortedSubscriptions = _.orderBy(this.props.currentSubscriptions, s => s.nextRenewDate);

        const defaultSubpage = sortedPackages.length > 0
            ? ONE_TIME_SUBROUTE
            : SUBSCRIPTIONS_SUBROUTE;
        const subpage = this.state.currentSubpage || defaultSubpage;
        const isOneTime = subpage === ONE_TIME_SUBROUTE;

        return (
            <div className="main-content-wrapper profile-pages my-packages-page">
                <div className="page-title">Club Credits</div>
                <ButtonGroup
                  config={this.packageSelectorConfig}
                  value={subpage}
                  select={(v: string) => this.onListTypeSelect(v)}
                />
                {isAuthorized && (
                    isOneTime
                        ? <>
                            <div className="packages-list-wrapper">
                                {sortedPackages.map((item, index) => {
                                    const expiration = moment(item.expires);
                                    const creditsDefinitions = this.getPackageCreditsDefinitions(item);
                                    return (
                                        <PackageCurrentInfo key={index}
                                            id={item.id}
                                            title={item.title}
                                            description={item.description}
                                            coachFeeTier={item.coachFeeTier}
                                            price={item.price}
                                            expirationDate={expiration}
                                            creditsDefinitions={creditsDefinitions}
                                            customSessionTypes={this.props.customSessionTypes}
                                        />
                                    )
                                })}  
                            </div>
                            {sortedPackages.length === 0 && 
                                <div className='no-active-offers'>
                                    <p className='offerings-text'>You have no current One-Time Packages.</p>
                                    <p className='offerings-header'>Play more, spend less.</p>
                                    <a className='btn btn-red' href='/packages'>Check out our offerings</a>
                                </div>
                            }
                        </>
                        : <>
                            <div className="packages-list-wrapper">
                                {sortedSubscriptions
                                    .map(toSubscriptionCurrent)
                                    .map((item) => {
                                            const currentSubscription = this.props.currentSubscriptions.find((subscription) => {
                                                return subscription.id === item.id;
                                            });
                                            const duplicatedSubscriptions = this.props.currentSubscriptions.filter((subscription) => {
                                                return subscription.recurrentPackageOfferId === currentSubscription.recurrentPackageOfferId;
                                            });
                                            const foundCanceledSubscription = this.props.subscriptionDefinitions.find((definition) => definition.id === currentSubscription.recurrentPackageOfferId);
                                            const displayResubscribeButton = duplicatedSubscriptions.length < 2 && typeof foundCanceledSubscription !== 'undefined';

                                            return (
                                                <SubscriptionCurrentCard key={item.id}
                                                    subscription={item}
                                                    options={{ isActiveSubtitle: false }}
                                                    handleUnsubscribe={() => this.onSubscriptionCancellation(item.id, item.billingPeriod)}
                                                    handleResubscribe={() => this.onResubscribe(item.id)}
                                                    displayRemainingCredits={true}
                                                    displayCancelButton={true}
                                                    displayResubscribeButton={displayResubscribeButton}
                                                    customSessionTypes={this.props.customSessionTypes}
                                                />
                                            )
                                        }
                                    )}
                            </div>
                            {sortedSubscriptions.length === 0 && 
                                <div className='no-active-offers'>
                                    <p className='offerings-text'>You have no current Subscriptions.</p>
                                    <p className='offerings-header'>Play more, spend less.</p>
                                    <a className='btn btn-red' href='/packages/subscriptions'>Check out our offerings</a>
                                </div>
                            }
                        </>)
                }
                {!isAuthorized && <p>{StM.Strings.UnathorizedAccessTitle}</p>}

            </div>
        );
    }

    private onSubscriptionCancellation(id: number, billingPeriod: string) {
        this.props.openCancelConfirmationDialog( 
            StM.MessagesKey.CancelSubscriptionConfirmation,
            StM.MessageTypes.Error,
            `Are you sure that you want to cancel your subscription? <br /><br /> Your subscription will be canceled at the end of your billing cycle.`,
            'Cancel Subscription',
            'Close',
            () => { 
                this.props.onUserCommitmentCancellation(id).then(() => {
                        this.props.getCurrentSubscriptions();
                        this.props.openAlertDialog(
                            StM.MessagesKey.CancelSubscription,
                            StM.MessageTypes.Success,
                            'See you on the courts!'
                    ).catch((error) => {
                        throw error;
                    })
                }); 
            }
        );
    }

    private onResubscribe(id: number) {
        this.state = { ...this.state, validationError: null };
        const selectedSubscription = this.props.currentSubscriptions.find((selectedSubscription) => 
            selectedSubscription.id === id
        );

        if (!this.props.user.paymentProfileId ||
                    this.props.user.paymentProfileStatus !== StM.PaymentProfileStatus.Active) {
            this.props.openBillingInfoDialog();
        } else {
            this.props.closeDialog();
            const latestSubscription = this.props.subscriptionDefinitions.find((subscription) => 
                subscription.id === selectedSubscription.recurrentPackageOfferId
            );
            latestSubscription.status === StM.RecurrentPackageOfferStatuses.Canceled 
                ? this.props.openAlertDialog(
                    StM.MessagesKey.GenericRequestError,
                    StM.MessageTypes.Error,
                    'Current version of this offer is no more available to purchase.'
                    )
                : this.props.openCheckoutSubscriptionDialog(latestSubscription);
        }
    }

    private getPackageCreditsDefinitions(packageDto: IPackageDto): IPackageInfoCreditsDefinitionWithRemaining[] {
        const nonZeroBalances = (this.props.balances || [])
            .filter(balance => balance.package.id == packageDto.id)
            .map(creditsWalletBalance => {
                const creditTypeCredits = creditsWalletBalance.packageSessionTypeCredits;
                return {
                    id: creditsWalletBalance.packageSessionTypeCredits.id,
                    credits: creditTypeCredits ? creditTypeCredits.credits : creditsWalletBalance.credits,
                    title: creditTypeCredits ? creditTypeCredits.title : '',
                    description: creditTypeCredits ? creditTypeCredits.description : '',
                    sortOrder: creditTypeCredits ? creditTypeCredits.sortOrder : 0,
                    sessionType: creditsWalletBalance.packageSessionType.sessionType,
                    alias: creditTypeCredits ? creditTypeCredits.typeAlias : '',
                    remaining: creditsWalletBalance.credits,
                }
            });
        const zeroBalances = _(packageDto.credits)
            .map(cd => {
                return nonZeroBalances.some(balance => balance.id === cd.id)
                    ? null
                    : {
                        id: cd.id,
                        credits: cd.credits,
                        title: cd.title,
                        description: cd.description,
                        sortOrder: cd.sortOrder,
                        sessionType: cd.packageSessionType.sessionType,
                        alias: cd.typeAlias,
                        remaining: 0,
                    }
            }).compact().valueOf();
        return _.orderBy([...nonZeroBalances, ...zeroBalances], 'sortOrder');
    }
}

function mapStateToProps(state: StM.IGlobalStoreState) {
    return {
        user: state.user,
        currentSubscriptions: state.subscriptionOffers.currentSubscriptions,
        subscriptionDefinitions: state.subscriptionOffers.definitions,
        isAuthorized: state.app.isAuthorized,
        club: state.club,
        customSessionTypes: state.customSessions,
        sessionTypes: state.subscriptionOffers.types,
        coachFeeTiers: state.coachFeeTiers,
        balances: state.user?.creditsWallet?.creditsWalletBalances || [],
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        go: (url: string) => dispatch(ActM.RouteActions.replace(url)),
        getCurrentSubscriptions: () => dispatch(ActM.PackagesActions.getCurrentSubscriptions()),
        onUserCommitmentCancellation: (id: number) => dispatch(ActM.PackagesActions.onUserCommitmentCancellation(id)),
        openCancelConfirmationDialog: (msgKey: string, messageType: string = StM.MessageTypes.Error, message?: string, acceptButtonText?: string, rejectButtonText?: string, yesCallback?: () => void) => 
            dispatch(ActM.DialogActions.open(StM.DialogNames.CancelConfirmation, { 
                msgKey, messageType, message, acceptButtonText, rejectButtonText, yesCallback 
            })),
        openAlertDialog: (msgKey: string, messageType: string, message: string) => dispatch(ActM.DialogActions.open(StM.DialogNames.Alert, { msgKey, messageType, message })),
        closeAlert: ()=> dispatch(ActM.DialogActions.close(StM.DialogNames.Alert, null, true)),
        openAuthDialog: (url: string) => dispatch(ActM.DialogActions.open(StM.DialogNames.Auth, {
            tab: StM.AuthDialogTabs.SignIn,
            returnUrl: url
        })),
        openBillingInfoDialog: () => dispatch(ActM.DialogActions.open(StM.DialogNames.BillingInfo)),
        checkout: (definition: StM.ISubscriptionOfferStateModel, params: any) => dispatch(ActM.SubscriptionPurchaseDialogActions.checkout(definition, params)),
        closeDialog: () => dispatch(ActM.DialogActions.close(StM.DialogNames.SubscriptionPurchase)),
        openCheckoutSubscriptionDialog: (subscription: StM.ISubscriptionOfferStateModel) =>
            dispatch(ActM.DialogActions.open(StM.DialogNames.SubscriptionPurchase, {
                subscription, id: subscription.id
        })),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyPackagesPage);
