import ClassNames from 'classnames';
import moment from 'moment';
import * as React from 'react';
import * as StM from '../../../../../models/store';
import * as SrvM from '../../../../../services';

interface ISessionBadgeProps {
    start: moment.Duration;
    date: moment.Moment;
    timeBlock: StM.ICourtTimeBlockStoreState;
    user: StM.IUserStoreState;
    durationMins: number;
    index: number;
    isAvailable: boolean;
    isSelected: boolean;
    isAvailabilityMode: boolean;

    setAvailabilityClick?: (timeItem: StM.ICoachAvailabilityTimeItem) => void;
}

export default class SessionBadgeMobile extends React.Component<ISessionBadgeProps, {}> {
    private utils: SrvM.Utils;
    constructor(props: ISessionBadgeProps) {
        super(props);
        this.utils = new SrvM.Utils();
    }

    public render() {
        const session = this.props.timeBlock.session;
        if (session) {
            const endSessionDur = moment.duration({ hours: session.endDateTime.hours(), minutes: session.endDateTime.minutes() });
            const minsLeft = endSessionDur.asMinutes() - this.props.start.asMinutes();
            const timeBlockClasses = ClassNames('time-range-block-mobile session-block'
                , {
                    'available': this.props.isAvailable,
                    'block-xs': minsLeft <= 15,
                    'selected': this.props.isSelected
                }
                , this.utils.getSessionClassByType(session.type)
                , 'session-block-{0}'.format(this.props.index));
            const styles: any = {};
            if (session.type === StM.SessionType.Custom) {
                styles.backgroundColor = session.customBackgroundColor;
                styles.color = session.customTextColor;
            }
            return (
                <div className={timeBlockClasses} style={styles} onClick={(e) => this.onClick(e)}>
                    <div className="block-wrapper">
                        <span className="title">{this.getTitle(session)}</span>
                    </div>
                </div>
            );
        }
        return null;
    };

    private getTitle(session: StM.ISessionStoreState): string {
        let title = this.utils.getSessionType(session);
        title = session.type === StM.SessionType.Custom ? 'Other' : title;
        if (session.ownerId === this.props.user.id) {
            title = 'My ' + title;
        }
        return title;
    }

    private onClick(e: any) {
        if (this.props.setAvailabilityClick) {
            const startTime = this.props.start;
            const time = !this.props.isAvailabilityMode ? startTime : moment.duration(startTime.asMilliseconds());
            const startAvailabilityTime = this.props.date.clone().set({ hours: time.hours(), minutes: time.minutes() });
            const endAvailabilityTime = startAvailabilityTime.clone().add(this.props.durationMins, 'minutes');
            this.props.setAvailabilityClick({
                rect: null,
                startTime: startAvailabilityTime,
                endTime: endAvailabilityTime
            });
        }
    }
};
