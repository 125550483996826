import './staticPage.scss';
import '../../legalTexts.scss';
import * as React from 'react';
import { connect } from 'react-redux';
import * as _ from 'lodash';

import * as ActM from '../../../actions';
import * as StM from '../../../models/store';

interface IStaticPageProps {
    getStatic: () => Promise<any>;
    name: string;
}

interface IStaticPageState {
    title: string;
    content: string;
}

class StaticPage extends React.Component<IStaticPageProps, IStaticPageState> {
    constructor(props: IStaticPageProps) {
        super(props);
    }

    componentDidMount() {
        this.init();
    }

    render() {
        return (<div className="static-wrapper">
            <div className="page-title">{this.state && this.state.title}</div>
            <article className="waiver-text-wrapper" dangerouslySetInnerHTML={this.state && { __html: this.state.content }}></article>
        </div>);
    }

    private init() {
        this.props.getStatic().then((staticPages: Array<IStaticPageDto>) => {
            const page = _.find(staticPages, { name: this.props.name.replace('/', '') });
            const content = page ? page.content : "Page is currently unavailable";
            const title = page ? page.title : "Not Found";
            this.setState({ content, title });
        });
    }
}

function mapStateToProps(state: StM.IGlobalStoreState, ownProps: any) {
    return {
        name: ownProps.location.pathname
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
       getStatic: () => dispatch(ActM.AppActions.getStatic())
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(StaticPage);
