import './baseBadge.scss';
import * as _ from 'lodash';
import moment from 'moment';
import * as React from 'react';
import ClassNames from 'classnames';

import * as StM from '../../../../models/store';
import * as SrvM from '../../../../services';
import * as PolM from '../../../../policies';
import Credits from '../../../credits/credits';
let utils = new SrvM.Utils();

export interface IBaseBadgeProps {
    time: StM.ICourtTimeBlockStoreState;
    filter: StM.IBookPageRouteParams;
}

export interface IBaseBadge {
}

export interface IBaseBadgeState { }

export class DurationClasses {
    static BLOCK_MIN_15 = 'min-15-cell';
    static BLOCK_MIN_30 = 'min-30-cell';
    static BLOCK_MIN_45 = 'min-45-cell';
    static BLOCK_MIN_60 = 'min-30-2x-cell';
    static BLOCK_MIN_90 = 'min-45-2x-cell';
    static BLOCK_MIN_120 = 'min-60-2x-cell';

    static EXTRA_SMALL = 'badge-xs';
    static SMALL = 'badge-sm';
    static LARGE = 'badge-lg';
    static EXTRA_LARGE = 'badge-xl';

    static getDurationClass(duration: number): string {
        let dict: any = DurationClasses;
        return dict['BLOCK_MIN_{0}'.format(duration.toString())];
    }
    static getDurationClassFromSession(session: StM.ISessionStoreState) {
        let minutes = moment.duration(session.endDateTime.diff(session.startDateTime)).asMinutes();
        return this.getDurationClass(minutes);
    }

    static getBadgeSizeByDuration(duration: number): string {
        if (duration < 30) return this.EXTRA_SMALL;
        if (duration >= 30 && duration < 45) return this.SMALL;
        if (duration > 60 && duration <= 120) return this.LARGE;
        if (duration > 120) return this.EXTRA_LARGE;
        return null;
    }
}

export class SkillClasses {
    static Beginner = 'lvl-beginer';
    static Intermediate = 'lvl-intermediate';
    static Advanced = 'lvl-advanced';
    static getSkillClasses(level: string) {
        let dict: any = SkillClasses;
        switch (level) {
            case StM.UserSkill.Beginner: {
                return dict.Beginner;
            }
            case StM.UserSkill.Intermediate: {
                return dict.Intermediate;
            }
            case StM.UserSkill.Advanced: {
                return dict.Advanced;
            }
            default: {
                return '';
            }
        }
    }
}

export class CommonBadge {
    private static heightOneMin = 20 / 15;
    private static authSrv = new SrvM.AuthenticationService();
    private static groupInfo = new PolM.GroupInfoPolicy();

    public static getRenderSlots(maxUserCount: number, playersCount: number) {
        if (maxUserCount > 1) {
            let arr: Array<any> = [];

            for (let i = 0; i < maxUserCount; i++) {
                let classes = ClassNames("slots-item",
                    { "one-slot": i == 0 },
                    { "two-slot": i == 1 },
                    { "three-slot": i == 2 },
                    { "four-slot": i == 3 },
                    { "five-slot": i == 4 },
                    { "much-slot": i > 4 },
                    { vacancy: i < playersCount })
                arr.push(<span className={classes} key={i}></span>)
            }

            let classesSlots = ClassNames("slots",
                { "one-player": maxUserCount == 1 },
                { "two-players": maxUserCount == 2 },
                { "three-players": maxUserCount == 3 },
                { "four-players": maxUserCount == 4 },
                { "more-than-5": maxUserCount > 4 },
            );
            return (
                <div className={classesSlots}>
                    {arr}
                    <span className="slots-item plus-slot"></span>
                </div>
            );
        }
        return null;
    }

    public static getBadgeHeightByDuration(duration: number): number {
        return duration * this.heightOneMin;
    }

    public static getRenderVideoIcon(session: StM.ISessionStoreState) {
        return ((session.recordVideo || session.videoWasRecorded) && (
            <span className="ic_video"></span>
        ));
    }

    public static renderPrice(session: StM.ISessionStoreState, time: StM.ICourtTimeBlockStoreState, user: StM.IUserStoreState) {
        const sessionAvailabilityPolicy = new PolM.SessionAvailabilityPolicy(session, [], user);
        const isBasket = sessionAvailabilityPolicy.getIsBasket();
        if (isBasket) session = sessionAvailabilityPolicy.getSessionFromBasket() || session;

        const pricesPolicy = new PolM.PricesPolicy(session.isDoubledSession, session);
        const prices = pricesPolicy.handle();
        const showPriceInCredits = PolM.PackagePolicy.showPriceInCredits(session, time) && (isBasket ? !!session.credits : !!session.checkoutCredits);
      
        const price = isBasket ? prices.price + prices.additionalPrice + prices.servicesPrice : session.checkoutPrice;
        const credits = isBasket ? prices.credits + prices.additionalCredits : session.checkoutCredits;
        const handledPrice = utils.formatStringPrice(price.toString());
        
        return (
            <div className="price-wrapper">
                {!showPriceInCredits && (<div className="lesson-price">${handledPrice}</div>)}
                {showPriceInCredits && (<Credits credits={credits} type={session.type} small bgColor={session.customBackgroundColor} />)}
                {(showPriceInCredits && !!prices.servicesPrice) && <span className="lesson-price additional-price">${prices.servicesPrice}</span>}
            </div>
        );
    }

    public static renderGroupMemberContent(session: StM.ISessionStoreState, user: StM.IUserStoreState, filter: StM.IBookPageRouteParams) {
        return (
            <div className='group-member-content'>
                {this.getGroupMemberTitle(session, user, filter)}
            </div>
        );
	}
	
	public static getGroupMemberTitle(session: StM.ISessionStoreState, user: StM.IUserStoreState, filter?: StM.IBookPageRouteParams) {
        const sessionAvailabilityPolicy = new PolM.SessionAvailabilityPolicy(session, [], user);
		const groupMemberBookings: StM.IBookingStoreState[] = session.bookings.filter(b => utils.isActiveBooking(session, b) && user.group.members.some(m => m.user.id === b.userId));
        const filteredMember = filter ? user.group.members.find(m => m.id === +filter.memberId) : null;
		const isOwnerGroupMember = sessionAvailabilityPolicy.getIsOwnerGroupMember();
		let prefix = '';
		let title = session.owner.displayName;
		if(filteredMember) {
			title = filteredMember.user.displayName;
		} else if(groupMemberBookings.length) {
			title = groupMemberBookings.reduce((result, booking) => result += `${result ? '; ' : ''}${booking.user.displayName}`, '');
		}
        if(!isOwnerGroupMember || (filteredMember && session.ownerId !== filteredMember.user.id)) {
            prefix = `${isOwnerGroupMember ? session.owner.displayName : session.title || utils.getSessionType(session)} / `;
        }
        if(!isOwnerGroupMember && !session.isHidden) {
            prefix = '';
            title = utils.getSessionTitle(session, false);
        }
        return `${prefix}${title}`
	}

    public static getBadgeTitle(session: StM.ISessionStoreState, user: StM.IUserStoreState, isOwner: boolean) {
        const sessionAvailabilityPolicy = new PolM.SessionAvailabilityPolicy(session, [], user);
        const isGroupMemberSession = sessionAvailabilityPolicy.getIsGroupMemberSession(false);
        const isGroupMemberOwner = sessionAvailabilityPolicy.getIsOwnerGroupMember();
        let prefix = isOwner ? `${this.authSrv.getOwnerPrefix(user)} ` : '';
        
        let baseTitle = '';
        if (!session.isHidden) {
            baseTitle = utils.getSessionTitle(session, false, false);
            if (isGroupMemberSession) {
                const members = sessionAvailabilityPolicy.getSessionGroupMemberBookings(false).map(b => b.user);
                members.push(...session.addedUsers);
                baseTitle+=` / ${members.map(m => m.displayName).join('; ')}`;
            }
        } else if (isGroupMemberOwner) {
            baseTitle = session.owner.displayName;
        } else {
            baseTitle = utils.getSessionType(session);
        }

        return `${prefix}${baseTitle}`;
    }

    public static getTimeString(session: StM.ISessionStoreState) {
        return `${session.startDateTime.format('h:mm')} - ${session.endDateTime.format('h:mm')}`;
    }

    public static renderSkillMarkup(skill: string) {
        if (!skill) return null;
        if (skill == "Beginner, Intermediate, Advanced") return null;
        let skillClasses = ClassNames("lesson-difficult", SkillClasses.getSkillClasses(skill));
        return (
            <div className={skillClasses}>
                <div className="sq-tooltip">
                    <span className="tooltip-lvl">Level:</span> <span className="tooltip-lvl-desc">{skill}</span>
                </div>
            </div>
        );
    }

    public static getBadgeClasses(time: StM.ICourtTimeBlockStoreState, user: StM.IUserStoreState) {
        const sessionAvailability = new PolM.SessionAvailabilityPolicy(time.session, [], user);
        const isGroupSession = sessionAvailability.getIsGroupSession();
        const isGroupMemberSession = sessionAvailability.getIsGroupMemberSession(false);
        const sizeClass = time.session.type !== StM.SessionType.Clinic 
            ? DurationClasses.getBadgeSizeByDuration(time.duration) 
            : null;
        const durationClass = DurationClasses.getDurationClass(time.duration);
        return ClassNames('table-column-item-wrapper', utils.getSessionClass(time.session)
            , durationClass
            , sizeClass
            , {
                invited: time.isInvited,
                bought: !time.isSessionFromBasket && (time.isBought || isGroupSession),
                'in-basket-cell': time.isSessionFromBasket,
                'group-filter': isGroupMemberSession && !isGroupSession && !time.isSessionFromBasket,
            });
    }
}