declare global {
    interface ImportMeta {
        env: Record<string, unknown>;
    }
}

const isProduction = import.meta.env.MODE === 'production';

let customWindow: any = window;
customWindow.ISDEVELOPMENT = !isProduction;
customWindow.ISPRODUCTION = isProduction;
customWindow.IDLE_TIMEOUT_IN_MS = 1000*60*(isProduction ? 5 : 60);
customWindow.DASHBOARD_REFRESH_TIMEOUT_IN_MS = 1000*60*(isProduction ? 5 : 60);
customWindow.DASHBOARD_DICTIONARIES_REFRESH_TIMEOUT_IN_MS = 1000*60*60*24-1;

import './main';
