import '../styles.mobile.scss';
import ClassNames from 'classnames';
import * as React from 'react';
import * as Redux from 'redux';
import { connect } from 'react-redux';
import * as _ from 'lodash';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';

import { store } from '../store/store';
import {ActM, StM, SrvM } from '../modules';

import RouteWithAuth from '../components/auth/routeWithAuth';
import MenuMobile from './menu/burgerMenu.mobile';
import FooterMobile from './footer.mobile';
import HeaderMobile from './header.mobile';
import AuthDialogMobile from './dialogs/auth/authDialog.mobile';
import WaiverDialogMobile from './dialogs/waiver/waiverDialog.mobile';
import TermsAndConditionsDialogMobile from './dialogs/termsAndConditions/termsAndConditionsDialog.mobile';
import CreditTermsDialogMobile from './dialogs/creditTerms/creditTermsDialog.mobile';
import DemographicInfoDialogMobile from './dialogs/demographicInformation/demographicInformationDialog.mobile';
import BillingInfoDialogMobile from './dialogs/billingInfo/billingInfoDialog.mobile';
import BillingInfoStripeDialog from './dialogs/billingInfo/billingInfoStripeDialog';
import CreateSessionDialog from './dialogs/session/sessionCreating/createSessionDialog.mobile';
import PasswordRecoveryDialogMobile from './dialogs/passwordRecovery/passwordRecoveryDialog.mobile';
import PasswordChangingDialogMobile from './dialogs/passwordChanging/passwordChangingDialog.mobile';
import AlertDialogMobile from './dialogs/alertDialog.mobile';
import CancelConfirmationMobile from './dialogs/cancelConfirmation.mobile';
import SessionCreateSuccessDialogMobile from './dialogs/sessionCreateSuccessDialog.mobile';
import PackageAddedDialogMobile from './dialogs/packageAddedDialog.mobile';
import BookDialogMobile from './dialogs/book/bookDialog.mobile';
import EditUserDialog from './dialogs/profileEditing/editUserDialog.mobile';
import ConfirmDeleteBasketItemDialogMobile from './dialogs/confirmDeleteBasketItemDialog.mobile';
import ContactUsDialogMobile from './dialogs/contactUsDialog.mobile';
import PackageCheckoutDialogMobile from './dialogs/packageCheckoutDialog.mobile';
import SubscriptionCheckoutDialogMobile from './dialogs/subscriptionCheckoutDialog.mobile';
import SessionsInfoDialogMobile from './dialogs/session/sessionInfo/sessionInfoDialog.mobile';

import LandingMobile from './pages/landing.mobile';
import BookPageMobile from './pages/book/bookPage.mobile';
import CoachPageMobile from './pages/coachPage.mobile';
import CoachDetailsPageMobile from './pages/coachDetailsPage.mobile';
import PricingPageMobile from './pages/pricing/pricingPage.mobile';

import ContactsPageMobile from './pages/contactsPage.mobile';

import EditUserDialogMobile from '../containers/dialogs/profileEditing/editUserDialog.mobile';

import MySessionsPageMobile from '../containers/pages/mySessions/mySessionsPage.mobile';
import PackagesPageMobile from './pages/packages/packagesPage.mobile';

import NotificationMobile from '../containers/notifications.mobile';
import BasketMobile from '../containers/basket.mobile';
import CheckoutMobile from './dialogs/book/bookDialog.mobile';
import EmailConfirmationPageMobile from '../containers/pages/emailConfirmationPage.mobile';
import UserPageMobile from '../containers/pages/user/userPage.mobile';
import ScoreboardPage from '../containers/pages/scoreboard/scoreboardPage';
import VideoPageMobile from './pages/video/videoPage.mobile';
import StaticPage from './pages/static/staticPage';

import CreateGroupAccountDialogMobile from './dialogs/groupAccount/groupAccountCreating/createGroupAccountDialog.mobile';
import InviteGroupMemberDialogMobile from './dialogs/groupAccount/groupMemberInvitation/inviteGroupMemberDialog.mobile';
import EditGroupAccountDialogMobile from './dialogs/groupAccount/groupAccountEditing/editGroupAccountDialog.mobile';
import GroupInfoDialog from './dialogs/groupAccount/groupInfoDialog';
import ConfirmDialogMobile from './dialogs/confirmDialog.mobile';

import Spinner from './spinner/spinner';

interface IAppMobileProps {
    isInitializing: boolean;
    isAuthorized: boolean;
    isFinalized: boolean;
    match: any;
    location: any;
    isBasketFull: boolean;
    hasNotifications: boolean;
    dispatch: any;
    user: StM.IUserStoreState;
    isMenuOpen: boolean;
    isDialogsOpened: boolean;
    isDehydrated: boolean;

    logout: () => Promise<any>;
    closeMenu: () => void;
    go: (url: string) => any;
    initOwnProps: (ownProps: any) => void;
    init: () => Promise<void>;
    closeAuthDialog: () => void;
    initBuildVersion: () => Promise<any>;
}

interface IAppMobileState {
    params: any;
}
const bookPlayRoute = `${StM.Pages.Book}/:type/:date?/:playSubfilter?`;
const bookLessonRoute = `${StM.Pages.Book}/${StM.BookPageSessionType.Lesson}/:date?/:lessonSubfilter?/:lessonSubfilterId?`;
const bookClinicRoute = `${StM.Pages.Book}/${StM.BookPageSessionType.Clinic}/:date?/:clinicSubfilter?/:clinicSubfilterId?`;
const joinSessionRoute = `${StM.Pages.Book}/${StM.BookPageSessionType.Join}/:date?`;
const availabilityRoute = `/${StM.BookPageSessionType.Availability}/:date?`;
const scoreboardRoute = `${StM.Pages.Scoreboard}/:courtId`;
const videoPageRoute = `${StM.Pages.Video}/:videoLinkId`;

class AppMobile extends React.Component<IAppMobileProps, IAppMobileState> {
    constructor(props: IAppMobileProps) {
        super(props);
        document.body.classList.add('mobile');
        const root = document.documentElement;
        root.classList.add('mobile');
    }

    public componentDidMount() {
        const frontendBuildVersion = APP_VERSION;
        const maxReloadAttempts = 2;
        const reloadAttempts = localStorage.getItem('reloadAttempts') 
            ? parseInt(localStorage.getItem('reloadAttempts') || '0', 10) 
            : 0;
    
        this.props.initBuildVersion().then((model) => {
            const { backendBuildNumber } = model;
            const skipVersionCheck = !backendBuildNumber || !frontendBuildVersion;
    
            if (backendBuildNumber === frontendBuildVersion || skipVersionCheck || reloadAttempts >= maxReloadAttempts) {
                this.props.initOwnProps(this.props.match.params);
                if (this.props.isDehydrated) this.props.init();

                if (reloadAttempts >= maxReloadAttempts) {
                    localStorage.removeItem('reloadAttempts');
                }
            } else {
                localStorage.setItem('reloadAttempts', String(reloadAttempts + 1));
                window.location.reload();
            }
        });
    }

    componentDidUpdate(newProps: IAppMobileProps) {
        const user = newProps.user;
        const isAppAdmin = !!user && !!_.find(user.permissions, { role: StM.Roles.ApplicationAdmin });
        const isWorker = !!user && !!_.find(user.permissions, { role: StM.Roles.Worker });
        const isChangedProps = !_.isEqual(this.props.match.params, newProps.match.params);
        const isAuthChanged = this.props.isAuthorized !== newProps.isAuthorized;

        if (this.props.location.pathname !== newProps.location.pathname) {
            this.updateRouteCallback();
        }

        if(isAuthChanged && newProps.isAuthorized) {
            this.props.closeAuthDialog();
        }

        if (user && user.id && (isAppAdmin || isWorker)) {
            this.props.logout().then(() => {
                window.location.href = "/admin/";
            });
        }

        const pageWrapper = document.getElementById("page-wrapper");
        if (pageWrapper) {
            pageWrapper.ontouchmove = function (e) {
                if (e && newProps.isMenuOpen) e.preventDefault();
            }
        }

        isChangedProps && (newProps.initOwnProps(newProps.match.params));
    }

    shouldComponentUpdate(nextProps: IAppMobileProps, nextState: IAppMobileState) {
        return !nextProps.isInitializing;
    }

    updateRouteCallback() {
        const dispatch = store.dispatch;
        dispatch(ActM.BasketActions.close());
        dispatch(ActM.NotificationActions.close());
        dispatch(ActM.DialogActions.close(StM.DialogNames.TermsAndConditions));
        dispatch(ActM.DialogActions.close(StM.DialogNames.Waiver));
        dispatch(ActM.DialogActions.close(StM.DialogNames.CreditTerms));
        dispatch(ActM.DialogActions.close(StM.DialogNames.PrivacyPolicy));
        const scrollingElement = document.getElementsByClassName('content-wrapper')[0];
        scrollingElement && (scrollingElement.scrollTop = 0);
    }

    render() {
        if (this.props.isInitializing) { return null; }
        const contentWrapperClasses = ClassNames('content-wrapper');
        const mainConentWrapperClasses = ClassNames('main-content-wrapper', { 'dialog-opened': this.props.isDialogsOpened });
        const pageWrapperClasses = ClassNames({ 'menu-opened': this.props.isMenuOpen });
        return (
            <div>
                <div className={mainConentWrapperClasses} id="main-wrapper">
                    <MenuMobile />
                    <div id="page-wrapper" className={pageWrapperClasses}>
                        <div className="page-content-wrapper">
                            <HeaderMobile />
                            <div className={contentWrapperClasses}>
                                <Switch>
                                    <Route exact={true} path={StM.Pages.Landing} component={LandingMobile} />
                                    <Route exact={true} path={bookPlayRoute} component={BookPageMobile} />
                                    <Route exact={true} path={bookLessonRoute} component={BookPageMobile} />
                                    <Route path={bookClinicRoute} component={BookPageMobile} />
                                    <Route exact={true} path={joinSessionRoute} component={BookPageMobile} />
                                    <RouteWithAuth exact={true} path={availabilityRoute} component={BookPageMobile} authorize={[StM.Roles.Coach, StM.Roles.CoachAdmin]} />
                                    <Route path={`${StM.Pages.FBLink}/:inviteToken`} component={BookPageMobile} />
                                    <Route path={StM.Pages.MySessions} component={MySessionsPageMobile} />
                                    <Route path={StM.Pages.Packages} component={PackagesPageMobile} />
                                    <Route path={StM.Pages.Coaches} component={CoachPageMobile} />
                                    <Route path={`${StM.Pages.Coach}/:id`} component={CoachDetailsPageMobile} />
                                    <Route path={StM.Pages.Pricing} component={PricingPageMobile} />
                                    <Route path={StM.Pages.User} component={UserPageMobile} />
                                    <Route path={StM.Pages.Contacts} component={ContactsPageMobile} />
                                    <Route path={StM.Pages.Notifications} component={NotificationMobile} />
                                    <Route path={StM.Pages.Basket} component={BasketMobile} />
                                    <Route path={StM.Pages.ContactUs} component={ContactUsDialogMobile} />
                                    <Route path={StM.UserPages.EditProfile} component={EditUserDialogMobile} />
                                    <Route path={StM.UserPages.ChangePassword} component={PasswordChangingDialogMobile} />
                                    <Route path={`${StM.Pages.SessionInfo}/:id`} component={SessionsInfoDialogMobile} />
                                    <Route path={StM.Pages.Checkout} component={CheckoutMobile} />
                                    <Route path="/email-confirmation/:result" component={EmailConfirmationPageMobile} />
                                    <Route path={scoreboardRoute} component={ScoreboardPage} />
                                    <Route path={videoPageRoute} component={VideoPageMobile} />
                                    <Route path={StM.Pages.Terms} component={StaticPage} />
                                    <Route path={StM.Pages.Privacy} component={StaticPage} />
                                    <Route path={StM.Pages.Waiver} component={StaticPage} />
                                    <Redirect to={StM.Pages.Landing} />
                                </Switch>
                                {this.props.isFinalized && <FooterMobile />}
                            </div>
                            {this.props.isFinalized && (<div>
                                <AuthDialogMobile />
                                <WaiverDialogMobile />
                                <TermsAndConditionsDialogMobile />
                                <CreditTermsDialogMobile />
                                <DemographicInfoDialogMobile />
                                <BillingInfoDialogMobile type={StM.PaymentSystem.AuthorizeNet} />
                                <BillingInfoStripeDialog type={StM.PaymentSystem.Stripe} />
                                <CreateSessionDialog />
                                <EditUserDialog />
                                <PasswordRecoveryDialogMobile />
                                <PasswordChangingDialogMobile />
                                <AlertDialogMobile />
                                <CancelConfirmationMobile />
                                <SessionCreateSuccessDialogMobile />
                                <PackageAddedDialogMobile />
                                <BookDialogMobile />
                                <SessionsInfoDialogMobile params={this.props.match.params} />
                                <ConfirmDeleteBasketItemDialogMobile />
                                <ContactUsDialogMobile />
                                <PackageCheckoutDialogMobile params={this.props.match.params} />
                                <CreateGroupAccountDialogMobile />
                                <InviteGroupMemberDialogMobile />
                                <EditGroupAccountDialogMobile />
                                <GroupInfoDialog />
                                <ConfirmDialogMobile />
                                <SubscriptionCheckoutDialogMobile />
                            </div>)}
                        </div>
                        {this.props.isMenuOpen && (<div className="burger-overlay" onClick={() => this.props.closeMenu()}/>)}
                    </div>
                </div>
                <Spinner />
            </div>
        )
    };
};

const mapStateToProps = (state: StM.IGlobalStoreState, ownProps: any) => {
    const routeDialogService = new SrvM.RouteDialogService();
    const openedDialogs = routeDialogService.getOpenDialogs();
    const isDemographicInfoModalOpened = state.dialogs.demographicInformation.isOpened;
    return {
        match: ownProps.match,
        isAuthorized: state.app.isAuthorized,
        isFinalized: state.app.isFinalized,
        location: ownProps.location,
        isInitializing: state.app.isInitializing,
        user: state.user,
        isMenuOpen: state.burgerMenu.isOpen,
        isBasketFull: state.basket.goods && state.basket.goods.length > 0,
        hasNotifications: state.notificationBoard.notifications && state.notificationBoard.notifications.length > 0,
        isDialogsOpened: openedDialogs && openedDialogs.length > 0 || isDemographicInfoModalOpened,
        isDehydrated: state.app.isDehydrated
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        dispatch,
        logout: () => dispatch(ActM.AccountActions.logout()),
        closeMenu: () => dispatch(ActM.BurgerMenuActions.close()),
        go: (url: string) => dispatch(ActM.RouteActions.replace(url)),
        initOwnProps: (ownProps: any) => dispatch(ActM.AppActions.initOwnProps(ownProps)),
        init: () => dispatch(ActM.AppActions.init()),
        closeAuthDialog: () => dispatch(ActM.DialogActions.close(StM.DialogNames.Auth)),
        initBuildVersion: () => dispatch(ActM.BuildVersionActions.init())
    };
}

const connectedApp = connect(mapStateToProps, mapDispatchToProps)(AppMobile);
const AppMobileController = withRouter(connectedApp);
export default AppMobileController;

