import './creditsWallet.scss';
import * as React from 'react';
import * as _ from 'lodash';

import * as StM from '../models/store';
import PackageBalanceItem from '../components/credits/packageBalanceItem';

interface ICreditsWalletProps {
    user: StM.IUserStoreState;
    class: string;
    customSessionTypes: Array<StM.ICustomSessionTypeStoreState>
}

class CreditsWallet extends React.Component<ICreditsWalletProps> {
    constructor(props: ICreditsWalletProps) {
        super(props);
    }

    readonly agregateBalances = (balances: ICreditsWalletBalanceDto[]) => _.sortBy(_.reduce(
        balances.filter(b => b.credits > 0),
        (squashed: Array<ICreditsWalletBalanceDto>, balance: ICreditsWalletBalanceDto) => {
            const existingBalanceType = _.cloneDeep(balance);
            let matchingBalance = null;
            if (existingBalanceType.packageSessionType.sessionType === StM.SessionType.Private) {
                // looking weird method to find similar points in the existing array and add those together
                if (existingBalanceType.package.coachFeeTier) {
                    // if coachFeeTier  defined you need to match it, also you need to match the group and session type to the private...
                    // which is the private in this case always
                    matchingBalance = _.find(squashed, (b) => b.package.coachFeeTier && b.packageSessionType.sessionType === StM.SessionType.Private && b.packageSessionType.group === existingBalanceType.packageSessionType.group && b.package.coachFeeTier.id === existingBalanceType.package.coachFeeTier.id);
                } else {
                    matchingBalance = _.find(squashed, (b) => !b.package.coachFeeTier && b.packageSessionType.sessionType === StM.SessionType.Private && b.packageSessionType.group === existingBalanceType.packageSessionType.group);
                }
            } else if (existingBalanceType.packageSessionType.sessionType === StM.SessionType.Custom) {
                matchingBalance = _.find(squashed, (b) => b.packageSessionTypeCredits.typeAlias === existingBalanceType.packageSessionTypeCredits.typeAlias);
            } else {
                matchingBalance = _.find(squashed, (b) => b.packageSessionType.group === existingBalanceType.packageSessionType.group);
            }

            if (matchingBalance) {
                matchingBalance.credits += existingBalanceType.credits;
                matchingBalance.packageSessionTypeCredits.title = existingBalanceType.packageSessionTypeCredits.title;
                matchingBalance.packageSessionTypeCredits.description = existingBalanceType.packageSessionTypeCredits.description;
            } else {
                squashed.push(existingBalanceType);
            }

            return squashed;

        }, []), c => c.packageSessionTypeCredits.sortOrder);

    private renderList(balances: Array<ICreditsWalletBalanceDto>) {
        const oneTimeCredits = this.agregateBalances(balances.filter(b => !b.package.recurrentPackageCommitmentId));
        const subscriptionCredits = this.agregateBalances(balances.filter(b => b.package.recurrentPackageCommitmentId));
        return (
            <>
                <h4 className="credits-wallet-subtitle">Monthly Subscription Credits:</h4>
                <div className="credits-wallet-list ">
                    {subscriptionCredits.map((item: ICreditsWalletBalanceDto, index: number) => {
                        const styles: any = {};
                        if (item.packageSessionType.sessionType === StM.SessionType.Custom) {
                            styles.backgroundColor = item.packageSessionTypeCredits.color;
                        }

                        return (
                            <PackageBalanceItem key={index}
                                credits={item.credits}
                                title={item.packageSessionTypeCredits.title}
                                description={item.packageSessionTypeCredits.description}
                                coachFeeTier={item.package.coachFeeTier}
                                id={item.id}
                                sessionType={item.packageSessionType.sessionType}
                                customSessionTypes={this.props.customSessionTypes}
                                alias={item.packageSessionTypeCredits.typeAlias}
                            />);
                    })}
                </div>
                {!subscriptionCredits.length && <div className="desc">No credits left.</div>}

                <h4 className="credits-wallet-subtitle">One-Time credits remaining:</h4>
                <div className="credits-wallet-list ">
                    {oneTimeCredits.map((item: ICreditsWalletBalanceDto, index: number) => {
                        const styles: any = {};
                        if (item.packageSessionType.sessionType === StM.SessionType.Custom) {
                            styles.backgroundColor = item.packageSessionTypeCredits.color;
                        }
                        return (
                            <PackageBalanceItem key={index}
                                credits={item.credits}
                                title={item.packageSessionTypeCredits.title}
                                description={item.packageSessionTypeCredits.description}
                                coachFeeTier={item.package.coachFeeTier}
                                id={item.id}
                                sessionType={item.packageSessionType.sessionType}
                                customSessionTypes={this.props.customSessionTypes}
                                alias={item.packageSessionTypeCredits.typeAlias}
                            />);
                    })}
                </div>
                {!oneTimeCredits.length && <div className="desc">No credits left.</div>}
            </>
        );
    }

    render() {
        if (!this.props.user.creditsWallet || !this.props.user.creditsWallet.creditsWalletBalances.length || !_.some(this.props.user.creditsWallet.creditsWalletBalances, (x) => x.credits > 0)) {
            return (
                <span/>);
        }
        const balances = this.renderList(this.props.user.creditsWallet.creditsWalletBalances);
        return (<div className={this.props.class}>{balances}</div>);
    }
}

export default CreditsWallet;
