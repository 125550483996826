import './userMenu.scss';

import * as React from 'react';
import { connect } from 'react-redux';
import ClassNames from 'classnames';
import { NavLink } from 'react-router-dom';
import * as _ from 'lodash';

import * as StM from '../models/store';
import * as SrvM from '../services';
import * as ActM from '../actions';
import CreditsWallet from './creditsWallet';
import { Menu, MenuItem } from './UI';
import {IClubStoreState} from "../models/store";

interface IUserMenuProps {
    user: StM.IUserStoreState;
    clubs: StM.IClubStoreState[];
    club: StM.IClubStoreState;
    packages: StM.IPackagesStoreState;
    customSessionTypes: Array<StM.ICustomSessionTypeStoreState>;
    match: any;
    location: any;
    history: any;
    groupAccounts: StM.IGroupStoreState[];
    authTicket: StM.IAuthenticationTicketStoreState;

    signOut: () => Promise<any>;
    showSpinner: () => any;
    hideSpinner: () => any;
    loginOnBehalf: (userId: string) => Promise<void>;
    revertToSelf: () => Promise<void>;
    go: (url: string) => void;
    switchClub: (club: IClubStoreState) => void;
}

interface IUserMenuState {
    isShown: boolean
}

class UserMenu extends React.Component<IUserMenuProps, IUserMenuState> {
    private maxGroupsToShow = 10;
    private routeService = new SrvM.RouteService();
    private authSrv = new SrvM.AuthenticationService();
    private _listDOM: any = null;
    constructor(props: IUserMenuProps) {
        super(props);
        this.state = {
            isShown: false
        }
    }

    public componentWillUnmount() {
        if (this.state.isShown) this.removeDocumentClickHandler();
    }

    public render() {
        const user = this.props.user;
        const historyPath = this.routeService.getHistoryDefaultRoute(this.props.club);
        const mySessionsPath = this.routeService.getMySessionsDefaultRoute(this.props.club);
        const noPackages = !this.props.packages.definitions || !this.props.packages.definitions.length;
        const isGroupAccount = this.authSrv.isInRole(StM.Roles.GroupAccount, this.props.user);
        const hasGroupAccounts = this.props.groupAccounts.some((item) => 
            item.members.some((member) => member.user 
                && member.user.id === this.props.user.id 
                && member.canLoginAsGroupAccount)
        );
        const classesDropdown = ClassNames('dropdown', {
            'no-credits': !this.props.user.creditsWallet 
                || !this.props.user.creditsWallet.creditsWalletBalances.length 
                || !this.props.user.creditsWallet.creditsWalletBalances.some((item) => item.credits > 0),
        });
        const ownerPrefix = this.authSrv.getOwnerPrefix(this.props.user);
        const isSwitchableToSelf = isGroupAccount && !!this.props.authTicket.self && this.props.authTicket.impersonated;
        return (
            <div className="user-name-wrapper">
                <a href="#" className="user-name" onClick={(e) => this.showClick(e)}>
                    <span className="user-name-text">
                        {isGroupAccount ? user.group.title : user.displayName}
                    </span>
                    <span className="caret"></span>
                </a>
                {this.state.isShown && (
                    <div className={classesDropdown} ref={(elem) => this.setHandlers(elem)}>
                        <Menu isOpen={this.state.isShown}>
                            <MenuItem><NavLink to={`${StM.Pages.User}/${StM.UserPages.Profile}`} title={`${ownerPrefix} Profile`}>{ownerPrefix} Profile</NavLink></MenuItem>
                            {!isGroupAccount && (this.props.clubs.length > 1) && <MenuItem collapse label='My Clubs'>{this.renderMyClubsMenu()}</MenuItem>}
                            <MenuItem><NavLink to={historyPath} title='History'>History</NavLink></MenuItem>
                            <MenuItem><NavLink to={`${StM.Pages.User}/${StM.UserPages.MyPackages}`} title='Club Credits'>Club Credits</NavLink></MenuItem>
                            <MenuItem><NavLink to={mySessionsPath} title={`${ownerPrefix} Sessions`}>{ownerPrefix} Sessions</NavLink></MenuItem>
                            {!isGroupAccount && <MenuItem collapse label='My Groups'>{this.renderMyGroupsMenu()}</MenuItem>}
                            {isGroupAccount && <MenuItem><NavLink to={`${StM.Pages.User}/${StM.UserPages.GroupMembers}`} title='Group Members'>Group Members</NavLink></MenuItem>}
                            {!noPackages && (<MenuItem><NavLink to={StM.Pages.Packages} title='Packages'>Packages</NavLink></MenuItem>)}
                            {isSwitchableToSelf && (<MenuItem><button onClick={(e) => this.onSwitchToSelfClick(e)} title={`Switch to ${this.props.authTicket.self}`}>Switch to {this.props.authTicket.self}</button></MenuItem>)}
                            {this.renderPrivilegedMenuItem()}
                            <MenuItem><button onClick={(e) => this.onSignOutClick(e)} title='Logout'>Logout</button></MenuItem>
                        </Menu>
                        <CreditsWallet user={this.props.user} class="package-menu-wrapper" customSessionTypes={this.props.customSessionTypes} />
                    </div>
                )}
            </div>
        );
    }

    private renderMyClubsMenu() {
        let userClubs = this.props.clubs;

        return (
            <Menu>
                {userClubs.map((club, index) => (
                    <MenuItem key={index}>
                        <button onClick={(e) => this.onSwitchClubClick(e, club)} title={`Switch to ${club.title}`}>{club.title}</button>
                    </MenuItem>
                ))}
            </Menu>
        );
    }

    private renderMyGroupsMenu() {
        let userGroups = this.props.groupAccounts.filter((item) => 
            item.members.some((member) => member.user 
                && member.user.id === this.props.user.id 
                && member.canLoginAsGroupAccount)
            );
        const showMoreGroupsButton = userGroups.length > this.maxGroupsToShow;
        if(showMoreGroupsButton) {
            userGroups = userGroups.slice(0, this.maxGroupsToShow - 1);
        }
        return (
            <Menu>
                <MenuItem><NavLink to={`${StM.Pages.User}/${StM.UserPages.MyGroups}`} title='Summary'>Summary</NavLink></MenuItem>
                {userGroups.map((group, index) => (
                    <MenuItem key={index}>
                        <button onClick={(e) => this.onGroupAccountClick(e, group.groupAccountId)} title={`Switch to ${group.title} View`}>{group.title}</button>
                    </MenuItem>
                ))}
                {showMoreGroupsButton && (
                    <MenuItem><NavLink to={`${StM.Pages.User}/${StM.UserPages.MyGroups}`} title='Go to My Groups page'>More...</NavLink></MenuItem>
                )}
            </Menu>
        );
    }

    private renderPrivilegedMenuItem() {
        const isPrivileged = this.getIsUserPrivileged(this.props.user);
        if(!isPrivileged) return null;

        const isCoach = this.props.user.permissions.some((item) => item.role === StM.Roles.Coach || item.role === StM.Roles.CoachAdmin);
        const title = `Switch to ${isCoach ? 'Coach' : 'Admin'}`;
        return <MenuItem><button onClick={(e) => this.onAdminClick(e)} title={title}>{title}</button></MenuItem>;
    }

    private onSwitchClubClick(e: any, club: IClubStoreState) {
        if(e) e.preventDefault();

        if (club.id != this.props.club.id) {
            this.props.switchClub(club);
        }
    }

    private onSignOutClick(e: any) {
        if (e) { e.preventDefault(); e.stopPropagation(); }
        this.props.showSpinner();
        this.props.signOut().then(() => {
            this.props.hideSpinner();
        }).catch(() => {
            this.props.hideSpinner();
        });
    }

    private onAdminClick(e: any) {
        window.location.href = "/admin/";
    }

    private onGroupAccountClick(e: any, groupAccountId: string) {
        if(e) e.preventDefault();
        this.props.loginOnBehalf(groupAccountId);
    }

    private onSwitchToSelfClick(e: any) {
        if(e) e.preventDefault();

        const isselfAsAdmin = this.props.authTicket.selfAsAdmin;
        this.props.revertToSelf()
            .then(() => {
                if(!isselfAsAdmin) {
                    this.props.go(StM.Pages.Book);
                }
            });
    }

    private showClick(e: any) {
        if (e) { e.preventDefault(); e.stopPropagation() }
        if (!this.state.isShown) this.setState({ isShown: true });
        else if (!!this._listDOM && !!this._listDOM.contains && !this._listDOM.contains(e.target)) {
            this.onBlurHandler();
        }
    }

    private setDocumentClickHandler() {
        document.addEventListener('click', this.handleDocumentClick);
    }

    private removeDocumentClickHandler() {
        document.removeEventListener('click', this.handleDocumentClick);
    }

    private onBlurHandler() {
        this.setState({...this.state, isShown: false});
        this.removeDocumentClickHandler();
    }

    private handleDocumentClick = (evt: any) => {
        if (this && this._listDOM) {
            const inMenu = !!this._listDOM.contains && this._listDOM.contains(evt.target);
            const isCollapseBtn = _.includes(evt.target.className,'menu-item-collapse-btn');
            if (!inMenu || (inMenu && !isCollapseBtn && (evt.target.nodeName === 'A' || evt.target.nodeName === 'BUTTON'))) {
                this.onBlurHandler();
            }
        }
    }

    private setHandlers(elem: any) {
        this._listDOM = elem;
        if (!elem) return false;
        this.setDocumentClickHandler();
    }

    private getIsUserPrivileged(user: StM.IUserStoreState) {
        return !!user && !this.props.user.permissions.some((item) => item.role === StM.Roles.Member);
    }
}

function mapStateToProps(state: StM.IGlobalStoreState, ownProps: any) {
    return {
        user: ownProps && ownProps.user,
        clubs: state.clubs,
        packages: state.packages,
        customSessionTypes: state.customSessions,
        groupAccounts: state.groupAccounts,
        authTicket: state.authenticationTicket,
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        showSpinner: () => dispatch(ActM.AppActions.showSpinner())
        , hideSpinner: () => dispatch(ActM.AppActions.hideSpinner())
        , loginOnBehalf: (userId: string) => dispatch(ActM.AccountActions.loginOnBehalf(userId))
        , revertToSelf: () => dispatch(ActM.AccountActions.revertToSelf())
        , go: (url: string) => dispatch(ActM.RouteActions.push(url))
        , switchClub: (club: IClubStoreState) => dispatch(ActM.AccountActions.switchClub(club))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserMenu);
