import './bookDialogItem.mobile.scss';

import * as React from 'react';
import moment from 'moment';
import ClassNames from 'classnames';

import { StM, SrvM, PolM } from '../../modules';
import Credits from '../credits/credits';

interface IBookDialogItemProps {
    item: StM.ISessionStoreState;
    user: StM.IUserStoreState;

    removeItem: (id: number) => void;
}

export class BookDialogItemMobile extends React.Component<IBookDialogItemProps> {
    private utils = new SrvM.Utils();

    constructor(props: IBookDialogItemProps) {
        super(props);
    }

    public render() {
        const item = this.props.item;
        let start = item.startDateTime.clone();
        let end = item.endDateTime.clone();

        let startTime = start.format('h:mm');
        let startA = start.format('a');
        let endTime = end.format('h:mm');
        let endA = end.format('a');
        let title = this.utils.getSessionTitle(item);
        title = item.series && item.series.isCourse ? `${title} (${item.series.sessionCount} Sessions)` : title;

        const pricesPolicy = new PolM.PricesPolicy(item.isDoubledSession, item);
        const prices = pricesPolicy.handle();
        const price = this.utils.handleMinPrice(item.checkoutPrice);
        const handledPrice = this.utils.formatStringPrice(price.toString());

        const totalCredits = item.checkoutCredits || 0;

        let hasErrors = item.validationResult && item.validationResult.errors && item.validationResult.errors.length;
        let hasWarnings = item.validationResult && item.validationResult.warnings && item.validationResult.warnings.length;

        let errors: any;
        let warnings: any;
        let validationMessages: any;

        if (hasErrors) errors = this.renderErrors(item.validationResult.errors);
        if (hasWarnings) warnings = this.renderWarnings(item.validationResult.warnings)

        if (hasErrors || hasWarnings) validationMessages = (<div className="validation-wrapper-mobile">{errors}{warnings}</div>);
        let itemClasses = ClassNames("estimated-charges-list-item-mobile", {
            "with-coach-mobile": !!item.trainer,
            "validation-error-mobile": !!hasErrors,
            "validation-warning-mobile": !!hasWarnings,
            "with-credits-mobile": !!totalCredits
        }, totalCredits ? item.type.toLowerCase() : '');
        let sessionClasses = ClassNames("session-name-mobile", this.utils.getSessionClassByType(item.type));
        let sessionColorStyles: any = {};
        if (item.type === StM.SessionType.Custom) {
            sessionColorStyles.backgroundColor = item.customBackgroundColor;
        }

        const sessionAvailabilityPolicy = new PolM.SessionAvailabilityPolicy(item, []);
        let isOwner = sessionAvailabilityPolicy.getIsOwner();
        const hasServices = isOwner && !!this.utils.getSessionServices(item, this.props.user).length;
        const bookingWithCharge = item.bookings.find((b) => b.id === 0 && b.paymentType == StM.PaymentTypes.Charge && b.amount > 0);
        const hasBookingAdditionalPrice = item.checkoutCredits && !!bookingWithCharge;
        const hasAdditionalPrice = hasServices || hasBookingAdditionalPrice;
        const additionalPrice = hasBookingAdditionalPrice ? this.utils.formatStringPrice(((hasServices ? prices.servicesPrice : 0) + bookingWithCharge.amount).toString()) : prices.servicesPrice;

        return (
            <li className={itemClasses}>
                <div className="mobile-row flex-baseline-mobile">
                    <div className="mobile-col-11 date-mobile">
                        <span className="day-mobile">{item.startDateTime.date()} </span>
                        <span className="month-mobile">{moment.monthsShort(item.startDateTime.month())}, </span>
                        <div className="time-wrapper-mobile">
                            <div className="time-mobile">{startTime}</div>
                            <div className="time-type-mobile">{startA}</div>
                        </div>
                        <div className="time-divider-mobile">&ndash;</div>
                        <div className="time-wrapper-mobile">
                            <div className="time-mobile">{endTime}</div>
                            <div className="time-type-mobile">{endA}</div>
                        </div>
                    </div>
                    <div className="mobile-col-1 delete-mobile">
                        <div className="delete-wrapper-mobile" onClick={(e) => this.removeItemClick(e)}></div>
                    </div>
                </div>
                <div className="mobile-row">
                    <div className="mobile-col-9">
                        <div className={sessionClasses}>
                            <i className="session-color" style={sessionColorStyles}></i>
                            <span>{title} {hasServices ? ' + Services' : ''}</span>
                        </div>
                    </div>
                </div>
                <div className="mobile-row flex-baseline-mobile court-price-wrapper-mobile">
                    <div className="mobile-col-8">
                        {this.renderCourtInfo(item)}
                    </div>
                    <div className="mobile-col-4 price-wrapper-mobile">
                        {!totalCredits && <span className="price-mobile">${handledPrice}</span>}
                        {!!totalCredits && (
                            <Credits credits={totalCredits}
                                type={item.type}
                                bgColor={item.customBackgroundColor}
                                small
                            />
                        )}
                        {!!hasAdditionalPrice && <span className="additional-price-mobile"> + ${additionalPrice}</span>}
                    </div>
                </div>
                <div className="mobile-row">
                    <div className="mobile-col-12">
                        {validationMessages}
                    </div>
                </div>
            </li>
        )
    }

    private removeItemClick(e: any) {
        if (e) { e.preventDefault(); e.stopPropagation() };
        this.props.removeItem(this.props.item.basketId);
    }

    private renderCourtInfo(session: StM.ISessionStoreState) {
        let imageUrl: string;
        if (session.trainer) {
            let wnd = window as any;
            let image = session.trainer.imageId ? session.trainer.imageId : '';
            imageUrl = image ? `/api/v2/blobs/${image}/content` : '/content/img/nophoto-2.png';
        }
        
        return (
            <div className="court-info-mobile flex-center-mobile">
                <span className="court-wrapper-mobile">{this.utils.getReadableSessionCourtsTitle(session)}</span>
                {session.trainerId &&
                    <div className="coach-photo-wrapper-mobile">
                        <img src={imageUrl} />
                    </div>
                }
                {session.trainerId &&
                    <span className="coach-name-mobile">{session.trainer.displayName}</span>
                }
            </div>
        )
    }

    private renderErrors(errors: Array<IValidationMessageDto>) {
        return errors.map((error: IValidationMessageDto, index: number) => (
            <div className="validation-error-mobile" key={index}>
                <div className="message-mobile">{error.message}</div>
            </div>
        ));
    }

    private renderWarnings(warnings: Array<IValidationMessageDto>) {
        return warnings.map((warning: IValidationMessageDto, index: number) => (
            <div className="validation-warning-mobile" key={index}>
                <div className="message-mobile">{warning.message}</div>
            </div>
        ));
    }
}

export default BookDialogItemMobile;