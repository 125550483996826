import './historyPage.scss';
import './datepicker.scss';
import moment from 'moment';
import ClassNames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import * as _ from 'lodash';
import * as StM from '../../../models/store';
import * as ActM from '../../../actions';
import { Constants } from '../../../constants';
import * as SrvM from '../../../services';
import { InputSearch } from '../../../components/inputSearch';

import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';

let utils = new SrvM.Utils();

interface IHistoryPageProps {
    isAuthorized: boolean;
    payments: Array<StM.IUserPaymentStoreState>;
    startFormat: string;
    endFormat: string;
    start: moment.Moment;
    end: moment.Moment;
    go: (url: string) => any;
    showSpinner: () => any;
    hideSpinner: () => any;
    init: (start: moment.Moment, end: moment.Moment) => Promise<any>;
    getPaymentHistory: (start: moment.Moment, end: moment.Moment) => Promise<any>;
    openQueryDialog: (paymentId: number) => void;
    club: StM.IClubStoreState;
    user: StM.IUserStoreState;
    customSessionTypes: Array<StM.ICustomSessionTypeStoreState>;
}

interface IHistoryPageState {
    activePage?: number;
    periodKey?: number;
    filterStartDate: moment.Moment;
    filterEndDate: moment.Moment;
}

class HistoryPage extends React.Component<IHistoryPageProps, IHistoryPageState> {
    private utils: SrvM.Utils;
    periodList: Array<any> = [
        {
            key: 1,
            value: StM.HistoryPeriods.Week
        },
        {
            key: 2,
            value: StM.HistoryPeriods.Month
        },
        {
            key: 3,
            value: StM.HistoryPeriods.Any,
            calendarShow: true
        }
    ];
    itemOnPage = 15;

    constructor(props: IHistoryPageProps) {
        super(props);
        this.utils = new SrvM.Utils();
        this.state = {
            activePage: 0,
            periodKey: 3,
            filterStartDate: moment(),
            filterEndDate: moment()
        }
    }

    componentDidMount() {
        this.props.showSpinner();
        this.props.init(this.props.start, this.props.end).then(() => {
            this.props.hideSpinner();
        }).catch(() => {
            this.props.hideSpinner();
        });
        this.setState({
            filterStartDate: this.props.start.clone(),
            filterEndDate: this.props.end.clone()
        });
    }

    componentDidUpdate(prevProps: IHistoryPageProps) {
        const isAuthorizedChanged = this.props.isAuthorized !== prevProps.isAuthorized;
        const isStartChanged = !this.props.start.isSame(prevProps.start);
        const isEndChanged = !this.props.end.isSame(prevProps.end);

        if (isAuthorizedChanged && this.props.isAuthorized) {
            this.props.getPaymentHistory(this.props.start, this.props.end);
        }

        if (isStartChanged || isEndChanged) {
            this.setState({
                filterStartDate: this.props.start.clone(),
                filterEndDate: this.props.end.clone()
            });
        }
    }

    shouldComponentUpdate(nextProps: IHistoryPageProps, nextState: IHistoryPageState): boolean {
        const isPaymentsChanged = !_.isEqual(this.props.payments, nextProps.payments);
        const isStartFormatChanged = !_.isEqual(this.props.startFormat, nextProps.startFormat);
        const isEndFormatChanged = !_.isEqual(this.props.endFormat, nextProps.endFormat);
        const isStartChanged = !this.props.start.isSame(nextProps.start);
        const isEndChanged = !this.props.end.isSame(nextProps.end);
        const isUserChanged = !_.isEqual(this.props.user, nextProps.user);
        const isClubChanged = !_.isEqual(this.props.club, nextProps.club);
        const isPeriodKeyChanged = !_.isEqual(this.state.periodKey, nextState.periodKey);
        const isActivePageChanged = !_.isEqual(this.state.activePage, nextState.activePage);
        const isFilterStartChange = !this.state.filterStartDate.isSame(nextState.filterStartDate);
        const isFilterEndChange = !this.state.filterEndDate.isSame(nextState.filterEndDate);
        return isPaymentsChanged || isStartFormatChanged || isEndFormatChanged || isStartChanged || isEndChanged || isUserChanged
            || isClubChanged || isPeriodKeyChanged || isActivePageChanged || isFilterStartChange || isFilterEndChange;
    }

    render() {
        const payments = this.props.payments || [];

        if (this.props.isAuthorized) {
            let start = this.itemOnPage * this.state.activePage;
            let end = this.itemOnPage + this.itemOnPage * this.state.activePage;
            let filtered = payments.slice(start, end);
            let selectedPeriod = this.state.periodKey || 3;
            let period = _.find(this.periodList, (i) => i.key === selectedPeriod);
            return (
                <div className="main-content-wrapper profile-pages">
                    <div className="page-title">History</div>
                    {this.renderPeriodHeader(period, this.state.filterStartDate.clone(), this.state.filterEndDate.clone())}
                    <div className="history-list-wrapper">
                        <div className="history-list-header">
                            <div className="session-date-time">Session Date, Time</div>
                            <div className="type">Type</div>
                            <div className="transaction-date">Transaction Date</div>
                            <div className="invoice-id">Invoice ID</div>
                            <div className="details">Details</div>
                            <div className="payment-amount">Payment Amount</div>
                            <div className="query-btn"></div>
                        </div>
                        {this.renderHistoryList(filtered)}
                    </div>
                    {this.renderPagination(payments)}
                </div>
            )
        } else {
            return (
                <div className="main-content-wrapper profile-pages">
                    <div className="page-title">History</div>
                    <p>{StM.Strings.UnathorizedAccessTitle}</p>
                </div>
            );
        }

    };

    private renderPeriodHeader(period: any, start: moment.Moment, end: moment.Moment) {
        if (period && period.calendarShow) {
            return (
                <div className="filter-history-wrapper">
                    <InputSearch placeholder="Search"
                        classes="period-select-wrapper"
                        selected={period.key}
                        array={this.periodList}
                        readOnly={true}
                        onSelectChange={(item) => this.updateSelectedPeriod(item)} />
                    <DatePicker
                        locale="en-US"
                        format={Constants.DateTime.DATEPICKER_FORMAT}
                        value={start.toDate()}
                        nextLabel={(<svg height="24" viewBox="0 0 24 24" width="24"><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg>)}
                        next2Label={(<div><svg height="24" viewBox="0 0 24 24" width="24" style={{ marginRight: -18 }}><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg><svg height="24" viewBox="0 0 24 24" width="24"><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path></svg></div>)}
                        prevLabel={(<svg height="24" viewBox="0 0 24 24" width="24"><path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg>)}
                        prev2Label={(<div><svg height="24" viewBox="0 0 24 24" style={{ marginRight: -18 }} width="24"><path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg><svg height="24" viewBox="0 0 24 24" width="24"><path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg></div>)}
                        onChange={(date: Date) => this.startDateHandler(date)}
                        maxDate={Constants.MaxDate}
                    />
                    <div className="divider-wrapper">
                        <div className="divider"></div>
                    </div>
                    <DatePicker
                        locale="en-US"
                        format={Constants.DateTime.DATEPICKER_FORMAT}
                        value={end.toDate()}
                        nextLabel={(<svg height="24" viewBox="0 0 24 24" width="24"><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg>)}
                        next2Label={(<div><svg height="24" viewBox="0 0 24 24" width="24" style={{ marginRight: -18 }}><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg><svg height="24" viewBox="0 0 24 24" width="24"><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path></svg></div>)}
                        prevLabel={(<svg height="24" viewBox="0 0 24 24" width="24"><path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg>)}
                        prev2Label={(<div><svg height="24" viewBox="0 0 24 24" style={{ marginRight: -18 }} width="24"><path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg><svg height="24" viewBox="0 0 24 24" width="24"><path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg></div>)}
                        onChange={(date: Date) => this.endDateHandler(date)}
                        maxDate={Constants.MaxDate}
                    />
                    <div className="button-apply-wrapper">
                        <div className="btn btn-apply" onClick={(e) => this.updatePeriodClick(e)}>apply</div>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="filter-history-wrapper">
                    <InputSearch placeholder="Search"
                        classes="period-select-wrapper"
                        selected={period.key}
                        array={this.periodList}
                        readOnly={true}
                        onSelectChange={(item) => this.updateSelectedPeriod(item)} />
                </div>
            )
        }
    }

    private renderHistoryList(payments: Array<StM.IUserPaymentStoreState>) {
        if (!payments) return null;
        let list: Array<any> = [];
        for (let i = 0; i < payments.length; i++) {
            let item = payments[i];
            list.push(this.renderHistoryItem(item))
        }
        return (
            <ul className="history-list">{list}</ul>
        );
    }

    private renderPagination(payments: Array<any>) {
        if (!payments) return null;
        let pagesCount = Math.ceil(payments.length / this.itemOnPage);
        // if (pagesCount == 1) return null;
        let list: Array<any> = [];
        for (let i = 0; i < pagesCount; i++) {
            let classes = ClassNames("paginator-item", { active: this.state.activePage == i });
            list.push(<div className={classes} key={i} onClick={(e) => this.pageChangeHandler(e, i)}>{i + 1}</div>);
        }
        return (
            <div className="paginator-wrapper">
                {list}
            </div>
        );
    }

    private renderHistoryItem(payment: StM.IUserPaymentStoreState) {
        const dateFormat = this.getStringFromDate(payment.session.startDateTime);
        const timeFormat = payment.session.startDateTime.format('h:mm');
        const timePeriodFormat = payment.session.startDateTime.format('A');
        const dateTimeFormat = `${dateFormat}, ${timeFormat} ${timePeriodFormat}`;
        const transactionDate = payment.transactionDate ? moment(payment.transactionDate).format('MM/DD/YYYY') : '';
        const isPackagePayment = payment.displayName == "Package";
        const paymentType = payment.displayName != "Custom" ? payment.displayName : utils.getCustomSessionTypeTitleByAlias(payment.session.typeAlias, this.props.customSessionTypes);
        const isCreditsPayment = payment.credits && !isPackagePayment;
        return (
            <li className="history-list-item" key={payment.id}>
                <div className="session-date-time">
                    {!isPackagePayment && <span>{dateTimeFormat}</span>}
                </div>
                <div className="type">{paymentType}</div>
                <div className="transaction-date">{transactionDate}</div>
                <div className="invoice-id">{!isPackagePayment && payment.id}</div>
                <div className="details">{this.utils.getReadableBookingStatus(payment.status)}</div>
                {!isCreditsPayment && (<div className="payment-amount">
                    <span>$</span><span className="payment-count">{utils.formatStringPrice(payment.amount.toString())}</span>
                </div>)}
                {!!isCreditsPayment && (<div className="payment-amount">
                    <span className="payment-count">{payment.credits}</span> <span>credit{payment.credits > 1 ? "s" : ""}</span>
                </div>)}
                {(!isPackagePayment || !isCreditsPayment) && (<div className="query-btn">
                    <div className="query" onClick={(e) => this.onQueryClick(e, payment)}>Leave Comment</div>
                    <div className="query-sent">Query sent</div>
                </div>)}
            </li>
        );
    }

    private onQueryClick(e: any, payment: StM.IUserPaymentStoreState) {
        if (e) e.stopPropagation();
        this.props.openQueryDialog(payment.id);
    }

    private endDateHandler(date: Date) {
        this.setState({ filterEndDate: moment(date) });
    }

    private startDateHandler(date: Date) {
        this.setState({ filterStartDate: moment(date) });
    }

    private validPeriod(start: moment.Moment, end: moment.Moment) {
        let endIsNotBefore = end.isAfter(start) || start.isSame(end);
        if (!endIsNotBefore) return false;
        return true;
    }

    private updatePeriodClick(e: any) {
        if (e) e.stopPropagation();
        let isValid = this.validPeriod(this.state.filterStartDate, this.state.filterEndDate);
        if (isValid) {
            this.updateRoute(this.state.filterStartDate, this.state.filterEndDate);
        } else {
            this.setState({
                filterStartDate: this.props.start.clone(),
                filterEndDate: this.props.end.clone()
            });
            this.forceUpdate();
        }
    }

    private updateRoute(start: moment.Moment, end: moment.Moment) {
        let url = '{0}/{1}/{2}/{3}'.format(
            StM.Pages.User
            , StM.UserPages.History
            , start.format(Constants.DateTime.DATE_FORMAT)
            , end.format(Constants.DateTime.DATE_FORMAT)
        );
        this.props.go(url);
        this.getPayments(start, end);
    }

    private updateSelectedPeriod(item: any) {
        const utils = new SrvM.Utils();
        let date = utils.getCurrentClubDateTime(this.props.club);
        let startDate = moment(this.props.start);
        let endDate = moment(this.props.end);
        switch (item.value) {
            case StM.HistoryPeriods.Week: {
                endDate = date.clone();
                startDate = date.clone().add(-7, 'day');
                this.updateRoute(startDate, endDate);
                break;
            }
            case StM.HistoryPeriods.Month: {
                endDate = date.clone();
                startDate = date.clone().add(-1, 'month');
                this.updateRoute(startDate, endDate);
                break;
            }
        }
        this.setState({
            periodKey: item.key,
            filterStartDate: startDate.clone(),
            filterEndDate: endDate.clone()
        });
    }

    private getPayments(start: moment.Moment, end: moment.Moment) {
        this.setState({ activePage: 0 });
        this.props.showSpinner();
        this.props.getPaymentHistory(start, end)
            .then(() => {
                this.props.hideSpinner();
            }).catch(() => {
                this.props.hideSpinner();
            });
    }

    private getStringFromDate(date: moment.Moment) {
        return date.format("M/D/YYYY");
    }

    private pageChangeHandler(e: any, number: number) {
        if (e) e.stopPropagation();
        this.setState({ activePage: number });
    }
};

function mapStateToProps(state: StM.IGlobalStoreState, ownProps: any) {
    return {
        isAuthorized: state.app.isAuthorized
        , payments: state.pages.history.payments
        , startFormat: ownProps.match.params.start
        , endFormat: ownProps.match.params.start
        , start: moment(ownProps.match.params.start, Constants.DateTime.DATE_FORMAT)
        , end: moment(ownProps.match.params.end, Constants.DateTime.DATE_FORMAT)
        , club: state.club
        , user: state.user
        , customSessionTypes: state.customSessions
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        init: (start: moment.Moment, end: moment.Moment) => dispatch(ActM.HistoryPageActions.init(start, end)),
        getPaymentHistory: (start: moment.Moment, end: moment.Moment) => dispatch(ActM.HistoryPageActions.getPaymentHistory(start, end)),
        openQueryDialog: (paymentId: number) => dispatch(ActM.DialogActions.open(StM.DialogNames.HistoryQuery, { paymentId: paymentId })),
        showSpinner: () => dispatch(ActM.AppActions.showSpinner()),
        hideSpinner: () => dispatch(ActM.AppActions.hideSpinner()),
        go: (url: string) => dispatch(ActM.RouteActions.replace(url))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HistoryPage));
