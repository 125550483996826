import React from 'react';
import { connect } from 'react-redux';
import ClassNames from 'classnames';
import * as StM from '../models/store';
import * as ActM from '../actions';
import * as SrvM from '../services';
import { IGlobalStoreState, GlobalStoreState } from '../models/store';

interface IBannerProps {
    isShown: boolean;
    club: StM.IClubStoreState;
    banner: string;

    close: () => void;
    open: () => void;
}

interface IBannerState {
}

class Banner extends React.Component<IBannerProps, IBannerState> {
    
    private routeSrv: SrvM.RouteService = new SrvM.RouteService();

    constructor(props: IBannerProps) {
        super(props);
    }

    render() {
        const isClubDashboard = this.routeSrv.isActivePage(StM.Pages.ClubDashboard);
        const isScoreboardPage = this.routeSrv.isActivePage(StM.Pages.Scoreboard);
        const isVideoPage = this.routeSrv.isActivePage(StM.Pages.Video);
        if (isClubDashboard || isScoreboardPage || isVideoPage) { return null; }

        let classes = ClassNames('banner', {
            'small': !this.props.isShown
            , 'custom': !!this.props.club.uxV2MainBannerUrl
            , 'default': this.props.club.clubTimes.length && !this.props.club.uxV2MainBannerUrl
        });
        let bannerAlternateText = this.props.club.bannerAlternateText || '';
        return (
            <div className={classes}>
                {!!this.props.club.uxV2MainBannerUrl && this.renderIframe()}
                <div className="btn-banner-close" onClick={(e) => this.closeClick(e)}>Close</div>
                <p onClick={(e) => this.openClick(e)}>{bannerAlternateText}</p>
            </div>
        );
    }

    private renderIframe() {
        return (
            <iframe className="banner-frame" src={
                `data:text/html;charset=utf-8,${encodeURIComponent(this.props.banner)}`
            }/>
        );
    }

    openClick(e: any) {
        if (e) e.stopPropagation();
        this.props.open();
    }

    closeClick(e: any) {
        this.props.close();
    }
}

const mapStateToProps = (state: IGlobalStoreState, ownProps: any) => {
    return {
        isShown: state.banner.isShown,
        club: state.club,
        banner: state.banner.mainClubBanner
    };
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        close: () => dispatch(ActM.BannerActions.close()),
        open: () => dispatch(ActM.BannerActions.open())
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Banner);
