import './dialogs.mobile.scss';
import './openSessionBoardDialog.mobile.scss';
import * as React from 'react';
import * as _ from 'lodash';
import * as SrvM from '../../services';
import ClassNames from 'classnames';
import { ModalMobile, InputSearchMobile } from '../../components';


interface IOpenSessionBoardDialogProps {
    isShow: boolean;
    isPrivate: boolean;
    sessionType: string;
    sex: Array<any>;
    skills: Array<any>;
    searchOptions: any;
    typesOfPlay: Array<any>;

    closeOpenSessionBoardDialog: () => void;
    onPrivateClick: (e: any) => void;
    offPrivateClick: (e: any) => void;
    handlePrivateClick: () => void;
    selectSearchOptions: (item: any, option: string) => void;
    typePlayHandler: (e: any) => void;
}

interface IOpenSessionBoardDialogState { }

export default class OpenSessionBoardDialogMobile extends React.Component<IOpenSessionBoardDialogProps, IOpenSessionBoardDialogProps> {

    constructor(props: IOpenSessionBoardDialogProps) {
        super(props);
    }

    render() {
        let modalClasses = ClassNames('open-session-board-dialog', this.props.sessionType);
        let formWrapperClasses = ClassNames('form-wrapper','seeking-board-wrapper-mobile', this.props.isPrivate?'private':'open');
        let sex:any = this.props.searchOptions ? _.find(this.props.sex, {key: this.props.searchOptions.sex}): null;
        let skill:any = this.props.searchOptions ? _.find(this.props.skills, {key: this.props.searchOptions.skill}): null;
        let type:any = this.props.searchOptions? _.find(this.props.typesOfPlay, {key: this.props.searchOptions.type}): null;
        if (sex) sex = sex.key;
        if (skill) skill = skill.key;
        if (type) type = type.key;
        return (
            this.props.isShow ?
            (<ModalMobile classes={modalClasses} dialogName={"open-session-board"}>
                <div className="modal-dialog-header">
                    <div className="title">Privacy</div>
                </div>
                <div className="modal-dialog-body-block">
                    <p className="note-mobile">Create a Public Session and make a new friend</p>
                    <div className="divider-line"></div>
                    <div className="slider-wrapper-mobile">
                        <div className={'switch-label-mobile left-label-mobile' + (this.props.isPrivate ? ' active' : '')} >
                            <label htmlFor="private-open-session-slider-mobile" onClick={(e) => this.props.onPrivateClick(e)}>PRIVATE SESSION</label>
                        </div>
                        <label className="switch-mobile">
                            <input type="checkbox" id="private-open-session-slider-mobile" onChange={() => this.props.handlePrivateClick()} checked={!this.props.isPrivate}/>
                            <div className="slider-mobile round-mobile"></div>
                        </label>
                        <div className={"switch-label-mobile right-label-mobile" + (!this.props.isPrivate ? ' active' : '')}>
                            <label htmlFor="private-open-session-slider-mobile" onClick={(e) => this.props.offPrivateClick(e)}>PUBLIC SESSION</label>
                        </div>
                    </div>
                </div>
                <div className="modal-dialog-body">
                    <div className={formWrapperClasses}>
                        <div className="inputs-wrapper">
                            <div className="input-item">
                                <label>What kind of player would you like to play with?</label>
                                <InputSearchMobile 
                                    array={this.props.sex} 
                                    placeholder="All Gender"
                                    classes="sex-input-wrapper-mobile"
                                    selected={sex}
                                    readOnly={true}
                                    disabled={this.props.isPrivate}
                                    onSelectChange={(item) => this.props.selectSearchOptions(item, 'sex')}/>
                                <InputSearchMobile 
                                    array={this.props.skills} 
                                    placeholder="All Skills"
                                    classes="skill-input-wrapper-mobile"
                                    selected={skill}
                                    readOnly={true}
                                    disabled={this.props.isPrivate}
                                    onSelectChange={(item)=> this.props.selectSearchOptions(item, 'skill')}/>
                            </div>
                            <div className="input-item">
                                <label>Type of play</label>
                                <InputSearchMobile 
                                    array={this.props.typesOfPlay} 
                                    placeholder="Hit"
                                    classes="type-of-play-input-wrapper"
                                    selected={type}
                                    readOnly={true}
                                    disabled={this.props.isPrivate}
                                    onSelectChange={(item)=> this.props.selectSearchOptions(item, 'type')}/>
                                {this.props.searchOptions.type == 4 && (
                                    <input type="text" value={this.props.searchOptions.typeValue} onChange={(e) => this.props.typePlayHandler(e)} disabled={this.props.isPrivate}/>
                                )} 
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-dialog-footer">
                    <div className="btns-wrapper">
                        <button className="btn-mobile btn-black btn-post" onClick={() => this.props.closeOpenSessionBoardDialog()}>POST TO BOARD</button>
                    </div>
                </div>
            </ModalMobile>) :
            (null)
        );
    }
}