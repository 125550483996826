import './bookingUserList.mobile.scss';

import * as React from 'react';

import { StM, SrvM } from '../../modules';
import { Images } from '../../constants'

interface IBookingUserListProps {
    list: Array<StM.IPublicUserStoreState>;
    firstItemText?: string;
    caption?: string;

    removeClick?: (id: string) => void;
    showRemoveButton?: (item: StM.IPublicUserStoreState) => boolean;
}

class BookingUserListMobile extends React.Component<IBookingUserListProps> {
    private utils = new SrvM.Utils();

    constructor(props: IBookingUserListProps) {
        super(props);
    }

    public render() {
        return (
            <ul className="add-list-mobile">
                {this.props.caption && <span className="list-caption">{this.props.caption}</span>}
                {this.props.list.map((item, index) => this.renderListItem(item, index))}
            </ul>
        );
    }

    public renderListItem(item: StM.IPublicUserStoreState, index: number) {
        const { firstItemText, list, showRemoveButton } = this.props;
        const imageUrl = item.imageId ? this.utils.getImageUrl(item.imageId) : Images.NoPhoto2;
        let title = this.utils.shortenString(item.displayName);
        if (firstItemText && list.length > 1 && index == 0) {
            title = `${title} (${firstItemText})`;
        }
        return (
            <li className="add-list-item-mobile" key={item.id}>
                <div className="mobile-row flex-center-mobile">
                    <div className="mobile-col-2 photo-mobile">
                        <img src={imageUrl} alt={title} />
                    </div>
                    <div className="mobile-col-9 name-mobile">{title}</div>
                    {(!showRemoveButton || showRemoveButton(item)) && (<div className="mobile-col-1 btn-container-mobile" onClick={(e) => this.onRemoveClick(e, item.id)}></div>)}
                </div>
            </li>
        );
    }

    // handlers

    private onRemoveClick(e: any, id: string) {
        if (e) { e.stopPropagation(); }
        if (this.props.removeClick) {
            this.props.removeClick(id);
        }
    }
}

export default BookingUserListMobile;