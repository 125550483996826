import './clinicBadge.scss';
import * as React from 'react';
import ClassNames from 'classnames';
import { IBaseBadgeProps, DurationClasses, CommonBadge } from './baseBadge';


interface IClinicBadgeBadgeProps extends IBaseBadgeProps { }

interface IClinicBadgeState { }

export class ClinicBadge extends React.Component<IClinicBadgeBadgeProps, IClinicBadgeState> {

    constructor(props: IClinicBadgeBadgeProps) {
        super(props);
    }

    render() {
        const coach = this.props.time.session && this.props.time.session.trainer
            ? this.props.time.session.trainer.displayName
            : '';
        const title = this.props.time.session && this.props.time.session.title
            ? this.props.time.session.title
            : '';
        const durationClass = DurationClasses.getDurationClass(this.props.time.duration);
        const classes = ClassNames('table-dashboard-column-item-wrapper', 'clinic', 'with-coach-dashboard', durationClass);
        return (
            <div className={classes}>
                <div className="table-dashboard-column-item">
                    <div className="name-coach-dashboard-wrapper">
                        <div className="name-dashboard-wrapper">
                            <div className="type-lesson-dashboard">Clinic:</div>
                            <div className="name-lesson-dashboard">{title}</div>
                        </div>
                        {coach && <div className="coach-dashboard-wrapper">{coach}</div>}
                    </div>
                </div>
            </div>
        );
    }
}
