import './mySessionsPage.mobile.scss';

import moment from 'moment';
import * as React from 'react';
import BigSessionInfoItemMobile from '../../../components/pages/bigSessionInfoItem/bigSessionInfoItem.mobile';
import { connect } from 'react-redux';
import * as StM from '../../../models/store';
import * as ActM from '../../../actions';
import * as SrM from '../../../services';

interface IMySessionsPageProps {
    mySessions: Array<StM.ISessionStoreState>;
    onSessionInfoClick: (from: string, id: number) => void;
    user: StM.IUserStoreState;
    isAuthorized: boolean;
    isLoading: boolean;
    club: StM.IClubStoreState;
    coachFeeTiers: Array<StM.ICoachFeeTierStoreState>;
    coaches: Array<StM.ICoachStoreState>;
    basket: StM.IBasketStoreState;

    init: () => Promise<any>;
    openAuthDialog: (url:string) => void;
    go: (url: string) => any;
    location: any;
}

interface IMySessionsPageState { 
    isSessionsLoading: boolean;
}

interface IMySessionsDayItem {
    date: moment.Moment;
    title: string;
    array: Array<StM.ISessionStoreState>;
}

class MySessionsPageMobile extends React.Component<IMySessionsPageProps, IMySessionsPageState> {
    private routeService: SrM.RouteService;
    constructor(props: IMySessionsPageProps) {
        super(props);
        this.routeService = new SrM.RouteService();
        this.state = { isSessionsLoading: false };
    }

    componentDidMount() {
        if (this.props.club.id) {
            this.updateSessions();
        }
    }

    componentDidUpdate(prevProps: IMySessionsPageProps) {
        const isAuthChanged = !prevProps.isAuthorized && this.props.isAuthorized;
        
        if (prevProps.isAuthorized && !this.props.isAuthorized) {
            const bookTodayRoute = this.routeService.getBookTodayRoute();
            this.props.go(bookTodayRoute);
        } else if (this.props.club.id && (isAuthChanged || !prevProps.club.id)) {
            this.updateSessions();
        }
    }

    render() {
        return (
            <div className="main-content-wrapper my-session-page-mobile">
                <div className="page-title-mobile">My Sessions</div>
                {this.props.isAuthorized ?
                    this.state.isSessionsLoading ? (<p className="note-mobile"> Loading, please wait </p>) : (this.renderSessionList()) : 
                    (<p className="note-mobile">Please login to continue</p>)
                }
            </div>
        )
    }

    renderSessionList() {
        if (!this.props.mySessions.length) {
            return (
                <p className="note-mobile">No sessions. <a href="#" onClick={(e) => { this.onBookPageClick(e) }}>Fire up your game</a></p>
            )
        }
        return (<div className="my-session-list-wrapper-mobile">{this.props.mySessions
            .map((item, index) => {
                return (
                    <BigSessionInfoItemMobile
                        session={item}
                        user={this.props.user}
                        basket={this.props.basket}
                        coachFeeTiers={this.props.coachFeeTiers}
                        coaches={this.props.coaches}
                        key={index}
                        club={this.props.club}
                        infoClick={this.props.onSessionInfoClick} />
                );
            })}
        </div>);
    }

    private onBookPageClick(e) {
        if (e) { e.preventDefault(); e.stopPropagation(); }
        this.props.go(this.routeService.getDefaultPage());
    }

    private updateSessions(): Promise<any>{
        if(this.state.isSessionsLoading) return;
        this.setState({ isSessionsLoading: true});
        return this.props.init()
        .then(() => {
            if (!this.props.isAuthorized && !this.routeService.isInvitationWithoutLoginLink(this.props.location.hash)) {
                let hash = window.location.hash;
                let url = encodeURIComponent(hash);
                this.props.openAuthDialog(url);
            }
            this.setState({ isSessionsLoading: false});
        }).catch(() => {
            this.setState({ isSessionsLoading: false});
        });
    }
};

function mapStateToProps(state: StM.IGlobalStoreState, ownProps: any) {
    return {
        mySessions: state.pages.mySessions.sessions,
        user: state.user,
        isAuthorized: state.app.isAuthorized,
        isLoading: state.app.isLoading,
        coachFeeTiers: state.coachFeeTiers,
        basket: state.basket,
        coaches: state.coaches,
        club: state.club,
        location: ownProps.location
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        init: () => dispatch(ActM.MySessionsPageActions.init())
        , onSessionInfoClick: (from: string, id:number) =>  dispatch(ActM.DialogActions.open(StM.DialogNames.SessionInfo, {from:from, id: id}))
        , openAuthDialog: (url:string) => dispatch(ActM.DialogActions.open(StM.DialogNames.Auth, { tab: StM.AuthDialogTabs.SignIn, returnUrl: url}))
        , go: (url: string) => dispatch(ActM.RouteActions.replace(url))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MySessionsPageMobile);
