import './timeLine.scss';
import * as React from 'react';
import moment from 'moment';
import * as StM from '../../../models/store';
import * as SrvM from '../../../services';
import Constants from '../../../constants';

interface ITimeLineProps {
    club: StM.IClubStoreState;
    pageDate?: moment.Moment;
}
interface ITimeLineState {}

class TimeLine extends React.Component<ITimeLineProps,  ITimeLineState> {
    private height = Constants.TimeLine.hourHeight;
    private utils: SrvM.Utils;

    constructor(props: ITimeLineProps) {
        super(props);
        this.utils = new SrvM.Utils();
    }

    public render() {
        const current = this.utils.getCurrentClubTime(this.props.club).asMilliseconds();

        const currentDay = this.props.pageDate ? this.props.pageDate : this.utils.getCurrentClubDateTime(this.props.club);
        
        const startTime = !!this.props.club && this.props.club.clubTimes.length > 0 ? this.props.club.clubTimes[currentDay.day()].startTime.asMilliseconds() : null;
        const endTime = !!this.props.club && this.props.club.clubTimes.length > 0 ? this.props.club.clubTimes[currentDay.day()].endTime.asMilliseconds() : null;
        
        const isShown = (() => {
            if (current > startTime && current < endTime) {
                if (this.props.pageDate) {
                    const pageDate = this.props.pageDate;
                    const currentDate = this.utils.getCurrentClubDateTime(this.props.club);
        
                    if (!this.isDateSame(pageDate, currentDate)) {
                        return false;
                    }
                }
                return true;
            }
            return false;
        })();
        
        if (isShown) {
            return (
                <div className="current-time-line" ref={(elem) => this.setPosition(elem, current, startTime)}></div>
            );
        }
        return null;        
    }

    private timeFromMs(ms: number) {
        const duration = moment.duration(ms, 'milliseconds');
        return moment({hour: duration.hours(), minute: duration.minutes()});
    }

    private formatTime(ms: number) {
        return this.timeFromMs(ms).format('h:mm');
    }

    private timePart(ms: number) {
        return this.timeFromMs(ms).format('a');
    }

    private setPosition(elem: any, time: number, start: number) {
        if (!elem) {
            return false;
        }

        const milliseconds = 1000;
        const minutes = 60;
        const hours = 60;

        const delta = time - start;
        const top = +(this.height * delta / milliseconds / minutes / hours).toFixed(1);
        
        elem.style.top = top + 2.5 + 'px';
    }

    private isDateSame(date1: moment.Moment, date2: moment.Moment) {
        const _val_1 = moment({date: date1.date(), month: date1.month(), year: date1.year()});
        const _val_2 = moment({date: date2.date(), month: date2.month(), year: date2.year()})
        return _val_1.isSame(_val_2);
    }
}
export default TimeLine;