import './header.mobile.scss';

import * as React from 'react';
import { connect } from 'react-redux';
import ClassNames from 'classnames';
import * as _ from 'lodash';

import * as StM from '../models/store';
import * as ActM from '../actions';
import * as SrvM from '../services';
import * as StoreModule from '../models/store';

interface IHeaderMobileProps {
    isBasketFull: boolean;
    hasNotifications: boolean;
    isAuthorized: boolean;
    hidden: boolean;

    toggleMenu: () => void;
    go: (url: string) => void;
    closeDemographicInfoDialog: () => void;
    closeWaiverDialog: () => void;
    closeTermsAndConditionsDialog: () => void;
}

class HeaderMobile extends React.Component<IHeaderMobileProps> {
    private routeSrv = new SrvM.RouteService();
    constructor(props: IHeaderMobileProps) {
        super(props);
    }

    componentDidUpdate(prevProps: IHeaderMobileProps) {
        const isAuthorizeChanged = this.props.isAuthorized !== prevProps.isAuthorized;
        const isBasketChanged = this.props.isBasketFull !== prevProps.isBasketFull;
        const isNotificationChanged = this.props.hasNotifications !== prevProps.hasNotifications;

        if (isAuthorizeChanged || isBasketChanged || isNotificationChanged) {
            this.forceUpdate();
        }
    }

    shouldComponentUpdate(nextProps: IHeaderMobileProps) {
        const isAuthorizeChanged = nextProps.isAuthorized !== this.props.isAuthorized;
        const isBasketChanged = nextProps.isBasketFull !== this.props.isBasketFull;
        const isNotificationChanged = nextProps.hasNotifications !== this.props.hasNotifications;
        const isHiddenChanged = nextProps.hidden !== this.props.hidden;
        return isAuthorizeChanged || isBasketChanged || isNotificationChanged || isHiddenChanged;
    }

    render() {
        if (this.props.hidden) return null;
        const isScoreboard = this.routeSrv.isActivePage(StM.Pages.Scoreboard);
        const isVideoPage = this.routeSrv.isActivePage(StM.Pages.Video);
        const notifButtonClasses = ClassNames('notifications-btn-mobile', { active: this.props.hasNotifications });
        const cartButtonClasses = ClassNames('cart-btn-mobile', { active: this.props.isBasketFull });
        const headerClasses = ClassNames('header-mobile', { 'scoreboard-page': isScoreboard, 'video-page-header-mobile': isVideoPage });
        return (
            <div className={headerClasses}>
                <div className="mobile-row">
                    <div className="mobile-col-4">
                        <button className="burger-btn" type="button" onClick={(e) => this.toggleMenuClick(e)}></button>
                    </div>
                    <div className="mobile-col-4">
                        <div className="logo-wrapper">
                            <span className="logo-header" onClick={(e) => this.onLogoClick()}></span>
                        </div>
                    </div>
                    <div className="mobile-col-4">
                        {!isVideoPage && (
                            <div className="header-btns flex-center-mobile">
                                {this.props.isAuthorized &&
                                    (<div className="header-btn notif-btn">
                                        <button className={notifButtonClasses} onClick={(e) => this.onNotificationClick(e)}></button>
                                    </div>)
                                }
                                <div className="header-btn cart-btn">
                                    <button className={cartButtonClasses} onClick={(e) => this.onCartClick(e)}></button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    };

    // handlers

    private toggleMenuClick(e: any) {
        if (e) { e.preventDefault(); }
        this.props.toggleMenu();
    }

    private onNotificationClick(e: any) {
        if (e) { e.preventDefault(); }
        this.props.go(StM.Pages.Notifications);
        this.closeDialogs();
    }

    private onCartClick(e: any) {
        if (e) { e.preventDefault(); }
        this.props.go(StM.Pages.Basket);
        this.closeDialogs();
    }

    private onLogoClick() {
        if(this.routeSrv.isActivePage(StM.Pages.Video)) return;
        this.closeDialogs();
        this.props.go('/');
    }

    private closeDialogs() {
        this.props.closeDemographicInfoDialog();
        this.props.closeWaiverDialog();
        this.props.closeTermsAndConditionsDialog();
    }
};

const mapStateToProps = (state: StoreModule.IGlobalStoreState, ownProps: any) => {
    let notifications = _.filter(state.notificationBoard.notifications, (noty) => {
        return noty.targetType == StM.NotificationTargetType.User || noty.targetType == StM.NotificationTargetType.Broadcast
            || (noty.targetType == StM.NotificationTargetType.Session
                && noty.event == StM.NotificationEventType.SessionYouInvited);
    });
    const routeSrv = new SrvM.RouteService();
    const isScoreboard = routeSrv.isActivePage(StM.Pages.Scoreboard);
    let isHeaderHidden = isScoreboard;
    const isVideoPage = routeSrv.isActivePage(StM.Pages.Video);
    if (isVideoPage) {
        const utils = new SrvM.Utils();
        const videoSession = state.pages.video.session;
        isHeaderHidden = videoSession && videoSession.startDateTime.isAfter(utils.getCurrentClubDateTime(state.club));
    }
    return {
        isAuthorized: state.app.isAuthorized
        , isBasketFull: (state.basket.goods && state.basket.goods.length > 0) || (state.basket.packages && state.basket.packages.length > 0)
        , hasNotifications: notifications && notifications.length > 0
        , hidden: isHeaderHidden
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        toggleMenu: () => dispatch(ActM.BurgerMenuActions.toggle())
        , go: (url: string) => dispatch(ActM.RouteActions.push(url))
        , closeDemographicInfoDialog: () => dispatch(ActM.DialogActions.close(StM.DialogNames.DemographicInformation))
        , closeWaiverDialog: () => dispatch(ActM.DialogActions.close(StM.DialogNames.Waiver))
        , closeTermsAndConditionsDialog: () => dispatch(ActM.DialogActions.close(StM.DialogNames.TermsAndConditions))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderMobile);
