import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as StM from '../../../../../models/store';
import {IBaseBadgeProps, DurationClasses} from './baseBadge';
import {FreeBadge} from './freeBadge';
import {PlayBadge} from './playBadge';
import {LessonBadge} from './lessonBadge';
import {ClinicBadge} from './clinicBadge';
import {CustomBadge} from './customBadge';
import {CourtShiftBadge} from './courtShiftBadge';

interface IBadgeBadgeProps extends IBaseBadgeProps {
    //users: Array<StM.IPublicUserStoreState>;
}

interface IBadgeState { }

export class Badge extends React.Component<IBadgeBadgeProps, IBadgeState> {
    constructor(props: IBadgeBadgeProps) {
        super(props);
    }

    render() {
        let badge = this.getBadge(this.props.time); 

        return badge;
    };

    private getBadge(time: StM.ICourtTimeBlockStoreState): any{
        if(!!time.session){          
            switch(time.session.type){
                case StM.SessionType.Play:{
                    return (<PlayBadge time={time} />);
                }
                case StM.SessionType.Private:{
                    return (<LessonBadge time={time} />);
                }
                case StM.SessionType.Clinic:{
                    return (<ClinicBadge time={time} />);
                }
                case StM.SessionType.Custom:{
                    return (<CustomBadge time={time} />);
                }
                default:{
                    return (<FreeBadge time={time} />);
                }
            }
        }

        if(time.isOffset){
            return (<CourtShiftBadge offset={time.duration}/>);
        }
            
        return (<FreeBadge time={time} />);
    }
}
