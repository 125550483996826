import './creditsWallet.mobile.scss';
import * as React from 'react';
import ClassNames from 'classnames';
import * as _ from 'lodash';

import * as StM from '../models/store';
import * as SrvM from '../services';
import PackageBalanceItem from './credits/packageBalanceItem';
import Credits from './credits/credits';

interface ICreditsWalletProps {
    user: StM.IUserStoreState;
    customSessionTypes: Array<StM.ICustomSessionTypeStoreState>;
}

interface ICreditsWalletState {
    isExpanded: boolean;
}

class CreditsWalletMobile extends React.Component<ICreditsWalletProps, ICreditsWalletState> {
    constructor(props: ICreditsWalletProps) {
        super(props);
        this.state = {
            isExpanded: false
        }
    }

    render() {
        if (!this.props.user.creditsWallet || !this.props.user.creditsWallet.creditsWalletBalances.length
            || !_.some(this.props.user.creditsWallet.creditsWalletBalances, (x) => x.credits > 0)) return (null);
        let walletClasses = ClassNames('credits-wallet-mobile', 'menu-block', { 'wallet-expanded': this.state.isExpanded });
        let totalBalance = this.props.user.creditsWallet.creditsWalletBalances.map((item) => { return item.credits }).reduce((a, b) => { return a + b }, 0);

        const squashedBalances = _.sortBy(_.reduce(this.props.user.creditsWallet.creditsWalletBalances.filter(b => b.credits > 0), (squashed: Array<ICreditsWalletBalanceDto>, balance: ICreditsWalletBalanceDto) => {
            let existingBalanceType = _.cloneDeep(balance);
            let matchingBalance = null;

            if (existingBalanceType.packageSessionType.sessionType == StM.SessionType.Private) {
                if (existingBalanceType.package.coachFeeTier)
                    matchingBalance = _.find(squashed, (b) => b.package.coachFeeTier && b.packageSessionType.sessionType == StM.SessionType.Private && b.packageSessionType.group == existingBalanceType.packageSessionType.group && b.package.coachFeeTier.id == existingBalanceType.package.coachFeeTier.id);
                else
                    matchingBalance = _.find(squashed, (b) => !b.package.coachFeeTier && b.packageSessionType.sessionType == StM.SessionType.Private && b.packageSessionType.group == existingBalanceType.packageSessionType.group);
            }
            else if (existingBalanceType.packageSessionType.sessionType == StM.SessionType.Custom) {
                matchingBalance = _.find(squashed, (b) => b.packageSessionTypeCredits.typeAlias == existingBalanceType.packageSessionTypeCredits.typeAlias);
            }
            else {
                matchingBalance = _.find(squashed, (b) => b.packageSessionType.group == existingBalanceType.packageSessionType.group);
            }

            if (matchingBalance) {
                matchingBalance.credits += existingBalanceType.credits;
                matchingBalance.packageSessionTypeCredits.title = existingBalanceType.packageSessionTypeCredits.title;
                matchingBalance.packageSessionTypeCredits.description = existingBalanceType.packageSessionTypeCredits.description;
            }
            else squashed.push(existingBalanceType);

            return squashed;

        }, new Array<ICreditsWalletBalanceDto>()), c => c.packageSessionTypeCredits.sortOrder);


        return (
            <div>
                <div className={walletClasses}>
                    <div className="menu-item total-credits-mobile mobile-row" onClick={(e) => this.toggleWallet(e)}>
                        <div className="credits-count-mobile mobile-col-11">
                            <Credits credits={totalBalance} disabled small />
                            <span>credits</span>
                        </div>
                        <div className="caret-mobile mobile-col-1"></div>
                    </div>
                    {this.state.isExpanded && (
                        <div className="credits-wrapper-mobile">
                            {squashedBalances.map((item: ICreditsWalletBalanceDto, index: number) => {
                                return (
                                    <PackageBalanceItem key={index}
                                        id={item.id}
                                        title={item.packageSessionTypeCredits.title}
                                        credits={item.credits}
                                        description={item.packageSessionTypeCredits.description}
                                        sessionType={item.packageSessionType.sessionType}
                                        customSessionTypes={this.props.customSessionTypes}
                                        coachFeeTier={item.package.coachFeeTier}
                                        alias={item.packageSessionTypeCredits.typeAlias}
                                    />
                                );
                            })}
                        </div>
                    )}
                </div>
            </div>
        );
    }

    private toggleWallet(e) {
        if (e) { e.preventDefault(); e.stopPropagation(); }
        this.setState({ ...this.state, isExpanded: !this.state.isExpanded });
    }
}

export default CreditsWalletMobile;
