import * as React from 'react';
import ClassNames from 'classnames';

import * as StM from '../../models/store';
import BaseBasketItem, { IBaseBasketItemProps } from './baseBasketItem';

interface IBasketItemProps extends IBaseBasketItemProps {}

class BasketItemMobile extends BaseBasketItem<IBasketItemProps> {
    constructor(props: IBasketItemProps) {
        super(props);
    }

    render() {
        const session = this.props.session;
        if (!session) return null;

        let title = this.utils.getSessionTitle(session);
        if(this.getIsCourse()) title = `${title} (${this.getCourseTitle()})`;
        
        const sessionClasses = ClassNames('order-name', this.utils.getSessionClass(session));
        const itemClasses = ClassNames('order-item', {
            'order-with-coach': !!session.trainer,
            'credits': !!session.checkoutCredits,
        }, session.type.toLowerCase());

        const sessionColorStyles: any = {};
        if (session.type === StM.SessionType.Custom) {
            sessionColorStyles.backgroundColor = session.customBackgroundColor;
        }

        return (
            <div className={itemClasses}>
                <span className="close" onClick={(e) => this.onRemoveItemClick(e)}></span>
                {this.renderDateTime()}
                <div className={sessionClasses}>
                    <i className="session-color" style={sessionColorStyles}></i>
                    <span>{title}</span>
                </div>
                <div className="court-price-wrapper">
                    <div className="court">{this.utils.getReadableSessionCourtsTitle(this.props.session)}</div>
                    {this.renderPrice()}
                </div>
                {this.renderCoach()}
            </div>
        );
    }
}

export default BasketItemMobile;
