import '../dialogs.scss';
import '../legalTextsDialog.scss';
import '../../legalTexts.scss';
import * as React from 'react';
import { connect } from 'react-redux';
import { Modal } from '../../../components';
import * as StM from '../../../models/store';
import * as ActM from '../../../actions';
import * as _ from 'lodash';

export interface ICreditTermsDialogProps {
    isShow: boolean;

    closeDialog: () => void;
    getStatic: () => Promise<any>;
}

export interface ICreditTermsDialogState {
    title: string;
    content: string;
}

class CreditTermsDialog extends React.Component<ICreditTermsDialogProps, ICreditTermsDialogState> {

    constructor(props: ICreditTermsDialogProps) {
        super(props);

        this.state = {
            title: 'Terms and Conditions',
            content: 'Please wait...',
        };
    }

    componentDidMount() {
        this.init();
    }

    render() {
        if (this.props.isShow) {
            return (
                <Modal classes="waiver-modal" closeHandler={this.props.closeDialog} dialogName={StM.DialogNames.TermsAndConditions} >
                    <div className="modal-dialog dialog">
                        <div className="modal-dialog-header">
                            <span className="close-dialog" onClick={e => this.onCloseClick(e)}></span>
                            <div className="title">{this.state.title}</div>
                        </div>
                        <div className="modal-dialog-body">
                        <div className="modal-dialog-body-content">
                                <article className="waiver-text-wrapper" dangerouslySetInnerHTML={this.state && { __html: this.state.content }}></article>
                            </div>
                            <div className="divider-line"></div>
                            <div className="btns-wrapper modal-dialog-body-content">
                                {/*<div className="left-btn-wrapper">
                                    <button className="btn-decline btn-black" >CANCEL</button>
                                </div>
                                */}
                                <div className="right-btn-wrapper close">
                                    <button className="btn-accept btn-black" onClick={(e) => this.onCloseClick(e)}>CLOSE</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            );
        }
        return null;
    }

    private init() {
        this.props.getStatic().then((staticPages: Array<IStaticPageDto>) => {
            const page = _.find(staticPages, { name: StM.LegalTexts.Terms });
            const content = page ? page.content : "Page is currently unavailable";
            const title = page ? page.title : "Not Found";
            this.setState({ content, title });
        });
    }

    private onCloseClick(e: any) {
        if (e) { e.preventDefault(); e.stopPropagation(); }
        this.props.closeDialog();
    }
}

const mapStateToProps = (state: StM.IGlobalStoreState, ownProps: any) => {
    return {
        isShow: state.dialogs.creditTerms.isOpened
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        closeDialog: () => dispatch(ActM.DialogActions.close(StM.DialogNames.CreditTerms)),
        getStatic: () => dispatch(ActM.AppActions.getStatic())
    };

}

export default connect(mapStateToProps, mapDispatchToProps)(CreditTermsDialog);
