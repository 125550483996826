import './clubDashboard.scss';

import moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';

import { Constants } from '../../../constants';
import * as StM from '../../../models/store';
import * as SrvM from '../../../services';
import * as ActM from '../../../actions';
import Calendar from '../../../components/pages/clubDashboard/calendar/calendar';


export interface IClubDashboardProps {
    club: StM.IClubStoreState;
    sessions: Array<StM.ISessionStoreState>;
    timeSlots: Array<StM.ICourtTimeSlotStoreState>;

    init: (date: moment.Moment) => Promise<any>;
    updateSessions: (date: moment.Moment) => Promise<any>;
    fillCourtTimeSlots: (date: moment.Moment) => Promise<any>;
    refreshDictionaries: (date: moment.Moment) => Promise<any>;
}

interface IClubDashboardState {
    currentDate: moment.Moment;
}

export class ClubDashboard extends React.Component<IClubDashboardProps, IClubDashboardState> {
    private utils = new SrvM.Utils();
    private setTimeoutId: number;
    private dictionariesIntervalId: number;
    private isDataReady: boolean;

    private hourHeight = 228;
    private timeLineHeight = 20;

    private headerDOM = null;

    constructor(props: IClubDashboardProps) {
        super(props);

        this.state = {
            currentDate: this.getCurrentDate()
        };
    }

    public componentDidMount() {
        const refs: any = this.refs;
        const hourHeight = refs && refs.calendar && refs.calendar.refs 
                && refs.calendar.refs.timeBoard && refs.calendar.refs.timeBoard.refs 
                && refs.calendar.refs.timeBoard.refs.timeItem0
            ? refs.calendar.refs.timeBoard.refs.timeItem0.clientHeight
            : this.hourHeight;
        this.hourHeight = hourHeight;
    }

	componentDidUpdate(prevProps: IClubDashboardProps) {
		if (!this.isDataReady && this.props.club.clubTimes.length > 0) {
			this.isDataReady = true;
			this.props.init(this.state.currentDate).then(() => this.scrollToCurrentTime());
			this.initRefreshTimer(true);
			this.initDictionariesRefreshInterval();
		}
	}

    public componentWillUnmount(){
        this.setTimeoutId && clearTimeout(this.setTimeoutId);
        this.dictionariesIntervalId && clearInterval(this.dictionariesIntervalId)
    }

    render() {
        const club = this.props.club;
        const year = moment().year();
        const calendarTopOffset = this.headerDOM ? this.headerDOM.clientHeight : 0;

        return (
            <div className="club-dashboard-page">
                <div className="top-content-dashboard" ref={(el) => this.headerDOM = el}>
                    <div className="header-dashboard-wrapper">
                        <div className="logo-wrapper-dashboard">
                        </div>
                        {this.renderDate(this.state.currentDate)}
                    </div>
                    {this.renderCourts()}
                </div>

                <Calendar club={this.props.club} timeSlots={this.props.timeSlots} date={this.state.currentDate} topOffset={calendarTopOffset}/>

                <div className="footer-dashboard">
                    <div className="footer-copyright">
                        &copy; {club.title} {year}
                    </div>
                    <div className="footer-text">
                        good night!
                    </div>
                </div>
            </div>
        );
    }

    private renderDate(date: moment.Moment){
        const d = date.format('D');
        const month = date.format('MMMM');
        const weekday = date.format('dddd');
        return (
            <div className="date-header-wrapper-dashboard">
                <div className="date-header-dashboard">
                    <span>what’s on today – </span> 
                    <span className="month">{month}</span> 
                    <span className="date">{d}</span> 
                    <span>,</span> 
                    <span className="day-of-week">{weekday}</span>
                </div>
            </div>
        );
    }

    private renderCourts(){
        return (
            <div className="court-wrapper-dashboard">
                {this.props.club.courts.map((court) => (
                    <div className="court-item-dashboard" key={court.id}>
                        {StM.Strings.CourtPrefix} {court.title}
                    </div>
                ))}
            </div>
        );
    }

    private initRefreshTimer(isInit: boolean = false){
        if (!isInit) this.updateDate().then(() => this.scrollToCurrentTime());

        this.setTimeoutId = setTimeout(() => {
            this.setTimeoutId = this.initRefreshTimer();
        }, DASHBOARD_REFRESH_TIMEOUT_IN_MS);

        return this.setTimeoutId;
    }

    private initDictionariesRefreshInterval(){
        this.dictionariesIntervalId = setInterval(() => {
            clearTimeout(this.setTimeoutId);
            this.updateDictionaries().then(() => {this.initRefreshTimer();});
        }, DASHBOARD_DICTIONARIES_REFRESH_TIMEOUT_IN_MS);
    }

    private updateDate(): Promise<any>{
        const currentDate = this.getCurrentDate();
        this.setState({ currentDate: currentDate});

        const formatted = moment(currentDate.format(Constants.DateTime.DATE_FORMAT), Constants.DateTime.DATE_FORMAT);
        return this.props.updateSessions(formatted)
            .then(() => {
                return this.props.fillCourtTimeSlots(formatted);
            }).catch(() => {
            });
    }

    private updateDictionaries(): Promise<any>{
        const formatted = moment(this.getCurrentDate().format(Constants.DateTime.DATE_FORMAT), Constants.DateTime.DATE_FORMAT);
        return this.props.refreshDictionaries(formatted)
            .then(() => {
                return this.props.fillCourtTimeSlots(formatted);
            }).catch(() => {
            });
    }

    private scrollToCurrentTime(){
        let clubStartTime = this.props.club.clubTimes[this.getCurrentDate().day()].startTime;
        let currentTime = this.getCurrentTime();
        let scrollTop = 0;

        let diffInHours = currentTime.asHours() - clubStartTime.asHours();
        if(diffInHours > 1){
            scrollTop = this.hourHeight * (diffInHours - 1) + this.timeLineHeight;
        }else{
            scrollTop = 0;
        }
        
        setTimeout(() => {
            document.documentElement.scrollTop = document.body.scrollTop = scrollTop;
        }) ;
    }

    private getCurrentDate(){
        return this.utils.getCurrentClubDateTime(this.props.club);
    }

    private getCurrentTime(){
        return this.utils.getCurrentClubTime(this.props.club);
    }
}

const mapStateToProps = (state: StM.IGlobalStoreState, ownProps: any) => ({
    club: state.club
    , sessions: state.pages.clubDashboard.sessions
    , timeSlots: state.pages.clubDashboard.timeSlots
});

const mapDispatchToProps = (dispatch: any) => ({
    init: (date: moment.Moment) => { return dispatch(ActM.ClubDashboardActions.init(date))}
    , updateSessions: (date: moment.Moment) => { return dispatch(ActM.ClubDashboardActions.getSessions(date))}
    , fillCourtTimeSlots: (date: moment.Moment) => {return dispatch(ActM.ClubDashboardActions.fillCourtTimeSlots(date))}
    , refreshDictionaries: (date: moment.Moment) => { return dispatch(ActM.ClubDashboardActions.refreshDictionaries(date))}
});

export default connect(mapStateToProps, mapDispatchToProps)(ClubDashboard);