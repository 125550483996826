import { ICreditCardStoreState } from "./creditCardStoreState";
import { IBillingAddressStoreState } from "./billingAddressStoreState";

export interface IPaymentProfileInformationStoreState{
    id: string;
    profileId: string;
    creditCard: ICreditCardStoreState; 
    billingAddress: IBillingAddressStoreState;
}

export class PaymentProfileInformationStoreState implements IPaymentProfileInformationStoreState{
    id: string;
    profileId: string;
    creditCard: ICreditCardDto;
    billingAddress: IBillingAddressDto;

    constructor(dto?: any) {
        this.id = dto && dto.id ? dto.id : '';
        this.profileId = dto && dto.profileId ? dto.profileId : '';
        this.creditCard = dto && dto.creditCard ? dto.creditCard : null;
        this.billingAddress = dto && dto.billingAddress ? dto.billingAddress : null;
    }
}