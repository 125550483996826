import moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import * as ActM from '../actions';
import * as StM from '../models/store';
import * as SrvM from '../services';

interface IBigFooterState {
    buildNumber: string;
    commitId: string;
}

interface IBigFooterProps extends RouteComponentProps {
    club: StM.IClubStoreState;

    openTermsAndConditionsDialog: () => Promise<any>;
    openPrivacyPolicyDialog: () => Promise<any>;
    openContactUsDialog: () => any;
}

class BigFooter extends React.Component<IBigFooterProps,IBigFooterState> {
    private routeSrv = new SrvM.RouteService();

    constructor(props: IBigFooterProps) {
        super(props);
        this.state = {
            buildNumber: null,
            commitId: null,
          }
    }

    render() {
        const route = this.props.location ? this.props.location.pathname : '';
        const isStandalonePage = this.routeSrv.isStandalonePage(route);
        if (isStandalonePage) return null;

        const club = this.props.club;
        const year = moment().year();

        return (
            <div className="footer big-footer">
                <div className="footer-copy">&copy; {club.title} {year}</div>
                <div className="big-footer-wrapper">
                    <div className="footer-copy-column">
                        <div className="column-item-header">&copy; {club.title} {year}</div>
                        <div className="column-item-header">
                            <a href="https://www.advantagebooking.com" target="_blank">Powered by Advantage Booking</a>
                        </div>
                    </div>
                    <div className="centered-footer-column">
                        <div className="contact-column">
                            <div className="button-contact-wrapper">
                                <button onClick={(e) => { this.onOpenContactUsDialogClick(e); }} className="btn-contact">CONTACT US</button>
                            </div>
                        </div>
                        <div className="phone-column">
                            <div className="column-item-wrapper">
                                <div className="column-item-body">
                                    {club.phone}
                                </div>
                            </div>
                        </div>
                        <div className="address-column">
                            <div className="column-item-wrapper">
                                <div className="column-item-body">
                                    <div>{club.address1}</div>
                                    <div>{club.address2}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="links-column">
                        <div className="column-item-header">
                            <a onClick={(e) => { this.onPrivacyPolicyClick(e); }}>Privacy Policy</a>
                        </div>
                        <div className="column-item-header">
                            <a onClick={(e) => { this.onTermsAndConditionsClick(e); }}>Terms and Conditions</a>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    private onOpenContactUsDialogClick(e: any) {
        if (e) { e.preventDefault(); e.stopPropagation(); }
        this.props.openContactUsDialog();
    }

    private onPrivacyPolicyClick(e: any) {
        if (e) { e.preventDefault(); e.stopPropagation(); }
        this.props.openPrivacyPolicyDialog();
        
    }

    private onTermsAndConditionsClick(e: any) {
        if (e) { e.preventDefault(); e.stopPropagation(); }
        this.props.openTermsAndConditionsDialog();
        
    }
};

const mapStateToProps = (state: StM.IGlobalStoreState, ownProps: any) => {
    return {
        club: state.club
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        openContactUsDialog: (sessionId: number) => dispatch(ActM.DialogActions.open(StM.DialogNames.ContactUs, { sessionId })),
        openPrivacyPolicyDialog: () => dispatch(ActM.DialogActions.open(StM.DialogNames.PrivacyPolicy)),
        openTermsAndConditionsDialog: () => dispatch(ActM.DialogActions.open(StM.DialogNames.TermsAndConditions, { noConfirmation: true }))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BigFooter));
