import '../dialogs.mobile.scss';
import './billingInfoDialog.mobile.scss';

import * as React from 'react';
import * as _ from 'lodash';

import { StM } from '../../../modules';
import { InputSearchMobile, ModalMobile, ModalHeader, ModalBody, ModalSection, Field, ModalFooter } from '../../../components';
import * as BD from './baseBillingInfoDialog';

interface IBillingInfoDialogProps extends BD.IBillingInfoDialogProps {}

class BillingInfoDialogMobile extends React.Component<IBillingInfoDialogProps> {
    public render() {
        if (!this.props.isShown) return null;
        const { months, years, countries, paymentProfile, errors } = this.props;
        const month = months.find((m) => m.value === paymentProfile.creditCard.expirationMonth);
        const year = years.find((y) => y.value === paymentProfile.creditCard.expirationYear);
        const country = countries.find((c) => c.value === paymentProfile.billingAddress.country);

        return (
            <ModalMobile classes='billing-info-modal' dialogName={StM.DialogNames.BillingInfo}>
                <ModalHeader title='Billing Information' />
                <ModalBody>
                    <ModalSection className='form-wrapper'>
                        <BD.BillingInfoDescription />
                        <div className='billing-card-info-wrapper'>
                            <div className='inputs-wrapper'>
                                <Field
                                    label='Card Number'
                                    name='creditCard.number'
                                    type='text'
                                    value={paymentProfile.creditCard.number}
                                    className={BD.getCardTypeClass(paymentProfile.creditCard.type)}
                                    error={errors && errors.creditCard && errors.creditCard.number}
                                    onFieldChange={(name, value) => this.props.onFieldChange(name, value)}
                                />
                                <Field
                                    name='creditCard.fullName'
                                    type='text'
                                    label='Name (as it appears on card)'
                                    value={paymentProfile.creditCard.fullName}
                                    error={errors && errors.creditCard && errors.creditCard.fullName}
                                    onFieldChange={(name, value) => this.props.onFieldChange(name, value)}
                                />
                                <div className='inputs-group-inline expiration-date-code-wrapper'>
                                    <div className='input-item expiration-date-wrapper'>
                                        <label>Expiration Date</label>
                                        <div className='inputs-group-inline'>
                                            <Field
                                                name='creditCard.expirationMonth'
                                                error={errors && errors.creditCard && errors.creditCard.expirationMonth}
                                            >
                                                <InputSearchMobile
                                                    selected={month ? month.key : null}
                                                    array={months}
                                                    onSelectChange={(item) => this.props.onFieldChange('creditCard.expirationMonth', item.value)}
                                                    readOnly
                                                    placeholder='00'
                                                />
                                            </Field>
                                            <span className='date-delimiter'>/</span>
                                            <Field
                                                name='creditCard.expirationYear'
                                                className='expiration-year-input'
                                                error={errors && errors.creditCard && errors.creditCard.expirationYear}
                                            >
                                                <InputSearchMobile
                                                    selected={year ? year.key : null}
                                                    array={years}
                                                    readOnly
                                                    onSelectChange={(item) => this.props.onFieldChange('creditCard.expirationYear', item.value)}
                                                    placeholder='00'
                                                />
                                            </Field>
                                        </div>
                                    </div>
                                    <Field
                                        name='creditCard.securityCode'
                                        type='text'
                                        label='CVV'
                                        value={paymentProfile.creditCard.securityCode}
                                        className='cvv-input'
                                        error={errors && errors.creditCard && errors.creditCard.securityCode}
                                        onFieldChange={(name, value) => this.props.onFieldChange(name, value)}
                                    />
                                </div>
                                {this.renderBillingInfoErrors(errors)}
                            </div>
                        </div>
                        <div className='divider-line' />
                    </ModalSection>
                    <ModalSection className='form-wrapper'>
                        <div className='inputs-wrapper'>
                            <Field
                                name='billingAddress.street'
                                type='text'
                                label='Billing Address'
                                error={errors && errors.billingAddress && errors.billingAddress.street}
                                value={paymentProfile.billingAddress.street}
                                onFieldChange={(name, value) => this.props.onFieldChange(name, value)}
                            />
                            <Field
                                name='billingAddress.city'
                                type='text'
                                label='City'
                                error={errors && errors.billingAddress && errors.billingAddress.city}
                                value={paymentProfile.billingAddress.city}
                                onFieldChange={(name, value) => this.props.onFieldChange(name, value)}
                            />
                            <Field
                                name='billingAddress.state'
                                type='text'
                                label='State'
                                value={paymentProfile.billingAddress.state}
                                error={errors && errors.billingAddress && errors.billingAddress.state}
                                onFieldChange={(name, value) => this.props.onFieldChange(name, value)}
                            />
                            <Field
                                name='billingAddress.country'
                                label='Country'
                                error={errors && errors.billingAddress && errors.billingAddress.country}
                            >
                                <InputSearchMobile
                                    array={countries}
                                    selected={country ? country.key : null}
                                    onSelectChange={(item) => this.props.onFieldChange('billingAddress.country', !!item && item.value)}
                                    placeholder='Select Country'
                                />
                            </Field>
                            <Field
                                name='billingAddress.zip'
                                type='text'
                                label='Postal / Zip code'
                                error={errors && errors.billingAddress && errors.billingAddress.zip}
                                value={paymentProfile.billingAddress.zip}
                                onFieldChange={(name, value) => this.props.onFieldChange(name, value)}
                            />
                        </div>
                    </ModalSection>
                </ModalBody>
                <ModalFooter className='btns-wrapper' errors={errors}>
                    <button type='button' className='btn-mobile btn-red btn-next' onClick={(e) => this.props.onSave(e)}>
                        Save
                    </button>
                    <button type='button' className='btn-mobile btn-black btn-next' onClick={(e) => this.props.onCloseDialog(e)}>
                        Cancel
                    </button>
                </ModalFooter>
            </ModalMobile>
        );
    }

    private renderBillingInfoErrors(errors: any) {
        const cardError =
            errors && errors.creditCard
                ? errors.creditCard.expirationMonth || errors.creditCard.expirationYear || errors.creditCard.securityCode
                : null;
        return !!cardError && <div className='validation-error'>{cardError}</div>;
    }
}

export default BD.withBillingInfoDialog(BillingInfoDialogMobile);
