import './videoPage.scss';
import * as React from 'react';
import moment from 'moment';
import * as _ from 'lodash';
import ClassNames from 'classnames';
import { connect } from 'react-redux';
import * as StM from '../../../models/store';
import * as ActM from '../../../actions';
import * as SrvM from '../../../services';
import { VideoPlayer, Footer, CourtSelector } from '../../../components/pages/video';
import EmptyVideoPage from './emptyVideoPage';

const utils = new SrvM.Utils();

interface IVideoPageProps {
    videoLinkId: string;
    club: StM.IClubStoreState;

    selectedYoutubeId: StM.IYoutubeIdStoreState;
    session: StM.ISessionStoreState;
    isVideoEnabled: boolean;
    courts: Array<StM.ICourtStoreState>;

    init: (videoLinkId: string, needSpinner?: boolean) => Promise<StM.ISessionStoreState>;
    changeCourtId: (youtubeId: StM.IYoutubeIdStoreState) => Promise<any>;
    showSpinner: () => void;
    hideSpinner: () => void;
}

interface IVideoPageState {
    currentClubTime: moment.Moment;
    checkingSessions: boolean;
    isLoading: boolean;
}

class VideoPage extends React.Component<IVideoPageProps, IVideoPageState> {
    private intervalID: any;
    private sessionCheckIntervalId: any;

    constructor(props: IVideoPageProps) {
        super(props);

        this.state = {
            currentClubTime: utils.getCurrentClubDateTime(props.club),
            checkingSessions: false,
            isLoading: true
        };
    }

    componentDidMount() {
        document.body.classList.add('video');
        this.intervalID = setInterval(() => this.tick(), 1000); 
    }

    componentDidUpdate(prevProps: IVideoPageProps) {
        if (
            this.props.videoLinkId !== prevProps.videoLinkId ||
            !_.isEqual(this.props.club, prevProps.club)
        ) {
            this.initializeSession(this.props);
        }
    }

    componentWillUnmount() {
        document.body.classList.remove('video');
        clearInterval(this.sessionCheckIntervalId);
        clearInterval(this.intervalID);
    }

    initializeSession(props: IVideoPageProps) {
        const { videoLinkId, club } = props;
        const isClubValid = club && club.id;

        if (isClubValid) {
            this.setState({ isLoading: true }, () => {
                this.props.showSpinner();

                this.props.init(videoLinkId).then(() => {
                    this.setState({ isLoading: false }, () => this.props.hideSpinner());
                    this.sessionCheckIntervalId = setInterval(() => this.checkSession(), 30 * 1000);
                });
            });
        }
    }

    render() {
        const { session, club } = this.props;
        const { isLoading } = this.state;

        if (!club || !club.id || isLoading) return null;

        const isShowVideoPage = !!session && utils.hasVideo(session) && session.startDateTime.isBefore(this.state.currentClubTime);

        return (
            <div>
                {isShowVideoPage && this.renderVideoPage(session, club)}
                {!isShowVideoPage && <EmptyVideoPage session={session} club={club} />}
            </div>
        );
    }

    private renderVideoPage(session: StM.ISessionStoreState, club: StM.IClubStoreState) {
        const selectedYoutubeId = this.props.selectedYoutubeId;
        const videoId = selectedYoutubeId ? selectedYoutubeId.youtubeId : '';
        const isMultiCourt = session.courts && session.courts.length > 1;
        const pageClasses = ClassNames('video-page page', { 'multicourt': isMultiCourt });

        return (
            <div className={pageClasses}>
                <div className="main-logo">
                    <a href={club.logoLinkUrl} target="_blank">
                        <img src={'/api/v2/blobs/logo'} alt="club logo" />
                    </a>
                </div>
                {this.renderHeader(session, club)}
                {isMultiCourt && (
                    <div className="court-selector-wrapper">
                        <CourtSelector
                            courts={this.props.courts}
                            youtubeIds={session.youtubeIds}
                            selectedYoutubeId={selectedYoutubeId}
                            onCourtSelected={(youtubeId) => this.onChangeCourt(youtubeId)}
                        />
                    </div>
                )}
                <div className="video-wrapper">
                    <VideoPlayer videoId={videoId} />
                </div>
                <div className="page-content-heading">
                    <h4>Experience one of America's fastest-growing sports.</h4>
                </div>
                <div className="page-content-body">
                    <p>
                        Squash On Fire is igniting squash in Washington, D.C. with a new world-class high-end squash facility that is open to all.
                        Centrally located, easily accessible, and with programs designed by award-winning coaches, Squash On Fire is designed from the ground up to be unlike any other squash facility in the DC area.
                        We believe the sport of squash should be accessible to everyone.
                        That's why no membership is required to play at our new Squash On Fire facility.
                        Welcome to our new eight-court, high-end facility where everyone who wants to play, can!
                    </p>
                </div>
                <div className="page-content"></div>
                <Footer club={club} />
                <div className="page-footer"></div>
                <div className="top-line"></div>
                <div className="bottom-line"></div>
            </div>
        );
    }

    private renderHeader(session: StM.ISessionStoreState, club: StM.IClubStoreState) {
        const title = utils.getSessionTitle(session);
        const startTimeString = session.startDateTime.format('MMMM D, h:mm A');
        const endTimeString = session.endDateTime.format('h:mm A');
        const timeCourtString = `${startTimeString}-${endTimeString}, ${utils.getSessionCourtsTitle(session)}`;

        return (
            <div className="page-header heading">
                <div className="title">{title}</div>
                <div className="subtitle">{timeCourtString}</div>
                {this.renderPlayers(session)}
                <div className="current-time">Current time: {`${this.state.currentClubTime.format("h:mm A")} ${club.aliasedTimeZone.alias}`}</div>
            </div>
        );
    }

    private renderPlayers(session: StM.ISessionStoreState) {
        if (!session.bookings || !session.bookings.length) return null;
        return (
            <div className="players-wrapper">
                <ul className="players-list">
                    {session.bookings.map((booking, index) => {
                        let player = booking.user.displayName;
                        if (booking.userId === session.ownerId) player += ' (host)';
                        return (
                            <li key={index}>{player}</li>
                        );
                    })}
                </ul>
            </div>
        );
    }

    private onChangeCourt(youtubeId: StM.IYoutubeIdStoreState) {
        this.props.changeCourtId(youtubeId);
    }

    private tick() {
        const club = this.props.club;
        const currentTime = utils.getCurrentClubDateTime(club);
        this.setState({ currentClubTime: currentTime });
    }

    private checkSession() {
        if (!this.state.checkingSessions) {
            this.setState({ checkingSessions: true }, () => {
                this.props.init(this.props.videoLinkId, false).then(() => {
                    this.setState({ checkingSessions: false });
                });
            });
        }
    }
};

function mapStateToProps(state: StM.IGlobalStoreState, ownProps: any) {
    const session = state.pages.video.session;
    const isVideoEnabled = !!session && utils.hasVideo(session) && session.youtubeIds && session.youtubeIds.length > 0;
    return {
        club: state.club,
        videoLinkId: ownProps.match.params.videoLinkId,
        session: session,
        selectedYoutubeId: state.pages.video.selectedYoutubeId,
        courts: state.club.courts,
        isVideoEnabled: isVideoEnabled
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        init: (videoLinkId: string, needSpinner?: boolean) => dispatch(ActM.VideoPageActions.init(videoLinkId, needSpinner)),
        changeCourtId: (youtubeId: StM.IYoutubeIdStoreState) => dispatch(ActM.VideoPageActions.changeYoutubeId(youtubeId)),
        showSpinner: () => dispatch(ActM.AppActions.showSpinner()),
        hideSpinner: () => dispatch(ActM.AppActions.hideSpinner()),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(VideoPage);
