import './profilePage.scss';

import * as React from 'react';
import ClassNames from 'classnames';

import * as StM from '../../../../models/store';
import * as SrvM from '../../../../services';
import { Images } from '../../../../constants';
import { DisplayField } from '../../../../components';
import * as BP from './baseProfilePage';

interface IProfilePageProps extends BP.IProfilePageProps { }

class ProfilePage extends React.Component<IProfilePageProps> {
    private utils = new SrvM.Utils();
    private authSrv = new SrvM.AuthenticationService();
    constructor(props: IProfilePageProps) {
        super(props);
    }

    render() {
        const user = this.props.user;
        const gender = user.gender === StM.Gender.Male || user.gender === StM.Gender.Female ? user.gender : StM.Strings.NoInfo;
        const skill = this.utils.getSkill(user.skill) || StM.Strings.NoInfo;
        const emergencyContactInfo = BP.getEmergencyContactInfo(user);
        const dateOfBirth = !!user.dateOfBirth ? user.dateOfBirth.format(BP.DATE_FORMAT) : StM.Strings.NoInfo;
        const ownerPrefix = this.authSrv.getOwnerPrefix(this.props.user);
        const isGroupAccount = this.props.isGroupAccount;
        return (
            <div className="main-content-wrapper profile-pages">
                <div className="page-title">{ownerPrefix} Profile</div>
                <div className="user-wrapper">
                    {this.props.renderAvatar()}
                    <div className="user-info-wrapper">
                        <div className="title">{isGroupAccount ? user.group.title : user.displayName}</div>
                        <div className="user-info">
                            {!isGroupAccount && (
                                <>
                                    <div className="user-info-row-1 phone">
                                        <DisplayField title="Phone" value={user.phone} />
                                        <DisplayField title="Date of Birth" value={dateOfBirth} />
                                        <DisplayField title="Skill" value={skill} />
                                        <DisplayField title="Invitation" value={`${(user.isAvailableForInvitation ? 'Available' : 'Disable')} for invitation`} />
                                    </div>
                                    <div className="user-info-row-2">
                                        <DisplayField title="Email" value={user.email} />
                                        <DisplayField title="Gender" value={gender} />
                                        <DisplayField title="Emergency Contact" value={emergencyContactInfo} />
                                        <DisplayField />
                                    </div>
                                </>
                            )}
                            
                            <div className="btn-wrapper">
                                <button className="btn btn-edit" onClick={(e) => this.props.onEditClick(e)}>EDIT</button>
                                {!isGroupAccount && <button className="btn btn-change-pswd" onClick={(e) => this.props.onPasswordRecoveryClick(e)}>CHANGE PASSWORD</button>}
                            </div>

                            {this.renderBillingInfo(user)}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private renderBillingInfo(user: StM.IUserStoreState) {
        const cardIcon = Images.getCardIcon(user.primaryCreditCardType.toLowerCase());
        const creditCardInfoClasses = ClassNames('card-number', { issues: !user.hasPaymentInfo });
        const showBillingInfo = !!user.creditCardShort || !!user.outstandingBalance;
        return (
            <div>
                <div className="divider-line"/>
                <div className="title">Billing Information</div>
                {showBillingInfo && (
                    <DisplayField className="card-info"
                        title={!!user.creditCardShort ? 'Credit Card' : 'Oustanding Balance'}>
                        {!!user.creditCardShort && (
                            <span>
                                <span className={creditCardInfoClasses}>
                                    {!user.hasPaymentInfo && <span className="warning"/>}
                                    {user.creditCardShort}
                                </span>
                                <img src={cardIcon} className="img-card" alt={user.primaryCreditCardType} /><br />
                            </span>
                        )}
                        {user.isCreditCardValidating && <span className="oustanding-balance-warning">Validating...</span>}
                        {!!user.outstandingBalance && (<span className="oustanding-balance-warning">You have outstanding balance of ${user.outstandingBalance}. Please update your billing information to process it.</span>)}
                    </DisplayField>
                )}

                <div className="btn-wrapper">
                    <button className="btn btn-edit" onClick={(e) => this.props.onBillingEditClick(e)}>EDIT</button>
                </div>
            </div>
        );
    }
}

export default BP.withProfilePage(ProfilePage);
