import React from 'react';
import './alert.scss';

type AlertType = 'warning' | 'error';

const Alert: React.FC<{ text: string, type?: AlertType, className?: string }> = ({ text, type = 'error', className }) => {

    const getIcon = (type: AlertType) => {
        return (type === 'error'
                ? <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M7 0C3.1 0 0 3.1 0 7C0 10.9 3.1 14 7 14C10.9 14 14 10.9 14 7C14 3.1 10.9 0 7 0ZM9.7 10.5L7 7.8L4.3 10.5L3.5 9.7L6.2 7L3.5 4.3L4.3 3.5L7 6.2L9.7 3.5L10.5 4.3L7.8 7L10.5 9.7L9.7 10.5Z"/>
                </svg>
                : <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M6.33337 10.3335H7.66671V6.3335H6.33337V10.3335ZM7.00004 5.00016C7.18893 5.00016 7.34737 4.93616 7.47537 4.80816C7.60337 4.68016 7.66715 4.52194 7.66671 4.3335C7.66671 4.14461 7.60271 3.98639 7.47471 3.85883C7.34671 3.73127 7.18849 3.66727 7.00004 3.66683C6.81115 3.66683 6.65293 3.73083 6.52537 3.85883C6.39782 3.98683 6.33382 4.14505 6.33337 4.3335C6.33337 4.52239 6.39737 4.68083 6.52537 4.80883C6.65337 4.93683 6.8116 5.00061 7.00004 5.00016ZM7.00004 13.6668C6.07782 13.6668 5.21115 13.4917 4.40004 13.1415C3.58893 12.7913 2.88337 12.3164 2.28337 11.7168C1.68337 11.1168 1.20849 10.4113 0.858707 9.60016C0.50893 8.78905 0.333818 7.92239 0.333374 7.00016C0.333374 6.07794 0.508485 5.21127 0.858707 4.40016C1.20893 3.58905 1.68382 2.8835 2.28337 2.2835C2.88337 1.6835 3.58893 1.20861 4.40004 0.858829C5.21115 0.509052 6.07782 0.333941 7.00004 0.333496C7.92226 0.333496 8.78893 0.508607 9.60004 0.858829C10.4112 1.20905 11.1167 1.68394 11.7167 2.2835C12.3167 2.8835 12.7918 3.58905 13.142 4.40016C13.4923 5.21127 13.6672 6.07794 13.6667 7.00016C13.6667 7.92239 13.4916 8.78905 13.1414 9.60016C12.7912 10.4113 12.3163 11.1168 11.7167 11.7168C11.1167 12.3168 10.4112 12.7919 9.60004 13.1422C8.78893 13.4924 7.92226 13.6673 7.00004 13.6668Z"
                        fill="#0062DC"/>
                </svg>
        )
    }

    return (
        <div className={`alert alert${'-' + type} ${className}`}>
            <span className='alert-icon'>{getIcon(type)}</span>
            <span className="alert-text">{text}</span>
        </div>
    );
}

export default Alert;
