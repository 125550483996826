import * as React from 'react';
import * as _ from 'lodash';

import { StM } from '../../../modules';
import CoachTierSelectDialog from '../../../containers/dialogs/coachTierSelectDialog.mobile';

interface ICoachSelectorProps {
    params: StM.IBookPageRouteParams;
    coaches: Array<StM.ICoachStoreState>;
    coachFeeTiers: Array<StM.ICoachFeeTierStoreState>;
    club: StM.IClubStoreState;
    availableTimesLookup: IDictionary;
    coachFeeTierPrices: StM.ICoachFeeTierPriceStoreState[];
    pricingTiers: StM.IPricingTierStoreState[];
    
    onCoachChangedHandler: (params:any) => void;
}

interface ICoachSelectorState {
    isCoachNotTierFilter: boolean;
    isCoachTierSelectDialogOpen: boolean;
}

class CoachSelectorMobile extends React.Component<ICoachSelectorProps, ICoachSelectorState> {
    constructor(props: ICoachSelectorProps) {
        super(props);
        this.state = {
            isCoachNotTierFilter: true,
            isCoachTierSelectDialogOpen: this.props.params.lessonSubfilterId == StM.BookPageFilterTypeDefault.SubFilterId
        };
    }

    componentDidUpdate(prevProps) {
        const { lessonSubfilter, lessonSubfilterId } = this.props.params;
        const defaultSubFilterId = StM.BookPageFilterTypeDefault.SubFilterId;

        if (
            prevProps.params.lessonSubfilter !== lessonSubfilter || 
            (prevProps.params.lessonSubfilterId !== lessonSubfilterId && 
             lessonSubfilterId !== defaultSubFilterId)
        ) {
            const isCoachNotTierFilter = true;
            this.setState({
                isCoachNotTierFilter: isCoachNotTierFilter,
                isCoachTierSelectDialogOpen: lessonSubfilterId === defaultSubFilterId,
            });
        }
    }

    render() {
        return (
            <div className="coach-selector-mobile mobile-row flex-center-mobile" onClick={(e)=>this.openCoachTierSelectDialog(e)}>
                {this.renderCoach()}
                <div className="mobile-col-4 tier-wrapper-mobile">
                    <span className="tier-title-mobile"></span>
                    <span className="select-coach-btn-mobile"></span>
                </div>
            </div>
        )
    };

    private openCoachTierSelectDialog(e: any) {
        if(e) {e.preventDefault();e.stopPropagation()}
        this.setState({...this.state, isCoachTierSelectDialogOpen: true});
    }

    private closeCoachTierSelectDialog() {
        this.setState({...this.state, isCoachTierSelectDialogOpen: false});
    }

    renderCoach() {
        let selectedCoach = this.getSelectedCoach();
        let coachTitle = selectedCoach 
            ? selectedCoach.displayName 
            : this.getTierTitle(null);
        const image = selectedCoach && selectedCoach.imageId ? selectedCoach.imageId : '' ;
        const urlimg = image ? `/api/v2/blobs/${image}/content` : '/content/img/nophoto-2.png';
        return (
            <div className="mobile-col-8 coach-wrapper-mobile flex-center-mobile">
                {selectedCoach && (
                    <div className="coach-photo-wrapper-mobile">
                        <img className="coach-photo-mobile" src={urlimg}/>
                    </div>
                )}
                <div className="coach-title-mobile">{coachTitle}</div>
                <CoachTierSelectDialog
                    params={this.props.params}
                    isShow={this.state.isCoachTierSelectDialogOpen}
                    availableTimesLookup={this.props.availableTimesLookup}
                    coaches={this.props.coaches}
                    coachFeeTiers={this.props.coachFeeTiers}
                    coachFeeTierPrices={this.props.coachFeeTierPrices}
                    pricingTiers={this.props.pricingTiers}
                    club={this.props.club}
                    closeDialog={()=>this.closeCoachTierSelectDialog()}
                    onCoachChanged={(coach)=>this.onCoachChanged(coach)}
                />
            </div>
        );
    }

    private getSelectedCoach() {
        if(this.state.isCoachNotTierFilter) {
            let selectedCoaches = this.props.coaches.filter(coach => {return coach.id == this.props.params.lessonSubfilterId});
            return selectedCoaches && selectedCoaches.length > 0 ? selectedCoaches[0] : null;
        } else {
            return null;
        }
    }

    private getTierTitle(selectedCoach: StM.ICoachStoreState) {
        let tierId: string;
        if(selectedCoach && selectedCoach.coachFeeTierId){
            tierId = selectedCoach.coachFeeTierId.toString();
        } else {
            tierId = this.props.params.lessonSubfilterId;
        }
        let selectedTiers = this.props.coachFeeTiers.filter(tier => {return tier.id.toString() == tierId});
        let tier = _.find(this.props.coachFeeTiers, {id: +tierId});
        let tierCoaches = _.filter(this.props.coaches, {tierId : +tierId});
        if(tier){
            const anyStr = tierCoaches.length > 1 ? 'Any ' : '' ;

            return '{0}{1}'.format(anyStr, tier.title);
        }
        return null;
    }

    private onCoachChanged(coach: StM.ICoachStoreState){
        this.props.onCoachChangedHandler(_.assign({}, this.props.params, {
            lessonSubfilter: StM.BookPageLessonFilterType.Coach,
            lessonSubfilterId: coach.id
        }));
        this.closeCoachTierSelectDialog();
    }

}

export default CoachSelectorMobile;
