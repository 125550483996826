import './bookDialogItem.mobile.scss';

import * as React from 'react';
import ClassNames from 'classnames';

import { StM, SrvM } from '../../modules';

interface IBookPackageDialogItemProps {
    item: StM.IPackageDefinitionStoreState;

    removeItem: (id: number) => void;
}

export class BookPackageDialogItemMobile extends React.Component<IBookPackageDialogItemProps> {
    private utils = new SrvM.Utils();

    constructor(props: IBookPackageDialogItemProps) {
        super(props);
    }

    public render() {
        let hasErrors = this.props.item.validationResult && this.props.item.validationResult.errors && this.props.item.validationResult.errors.length;
        let hasWarnings = this.props.item.validationResult && this.props.item.validationResult.warnings && this.props.item.validationResult.warnings.length;

        let errors: any;
        let warnings: any;
        let validationMessages: any;

        if(hasErrors) errors = this.renderErrors(this.props.item.validationResult.errors);
        if(hasWarnings) warnings = this.renderWarnings(this.props.item.validationResult.warnings)

        if(hasErrors || hasWarnings) validationMessages = (<div className="validation-wrapper-mobile">{errors}{warnings}</div>);
        let itemClasses = ClassNames("estimated-charges-list-item-mobile", {
            "validation-error-mobile": !!hasErrors,
            "validation-warning-mobile": !!hasWarnings,
        });
        
        let sessionClasses = ClassNames("session-name-mobile");

        const price = this.utils.handleMinPrice(this.props.item.price);
        const handledPrice = this.utils.formatStringPrice(price.toString());
        
        return(
            <li className={itemClasses}>
                <div className="mobile-row flex-baseline-mobile">
                    <div className="mobile-col-9">
                        <div className={sessionClasses}>
                            <div>{this.props.item.title}</div>
                        </div>
                    </div>
                    <div className="mobile-col-3"></div>
                    <div className="mobile-col-1 delete-mobile">
                        <div className="delete-wrapper-mobile" onClick={(e) => this.removeItemClick(e)}></div>
                    </div>
                </div>
                <div className="mobile-row flex-baseline-mobile court-price-wrapper-mobile">
                    <div className="mobile-col-9">
                        PACKAGE
                    </div>
                    <div className="mobile-col-3 price-wrapper-mobile">
                    <span className="price-mobile">{'$' + handledPrice}</span>
                    </div>
                </div>
                <div className="mobile-row">
                    <div className="mobile-col-12">
                        {validationMessages}
                    </div>
                </div>
            </li>
        )
    }

    private removeItemClick(e:any) {
        if(e) {e.preventDefault(); e.stopPropagation()};
        this.props.removeItem(this.props.item.id);
    }
    
    private renderErrors(errors:Array<IValidationMessageDto>) {
         return errors.map((error: IValidationMessageDto, index: number) => (
           <div className="validation-error-mobile" key={index}>
               <div className="message-mobile">{error.message}</div>
            </div>
        ));
    }

    private renderWarnings(warnings:Array<IValidationMessageDto>) {
         return warnings.map((warning: IValidationMessageDto, index: number) => (
           <div className="validation-warning-mobile" key={index}>
               <div className="message-mobile">{warning.message}</div>
            </div>
        ));
    }
}
export default BookPackageDialogItemMobile;