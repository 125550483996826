import * as React from 'react';
import ClassNames from 'classnames';
import { Link } from 'react-router-dom';

import { StM, SrvM } from '../../../../modules';

interface IPlayTabLinkProps {
    routeParams: StM.IBookPageRouteParams;
    onTabClickHandler: (params: any) => void;
}

interface IPlayTabLinkState {
    isShowOtherPlayers?: boolean;
    isShown?: boolean;
}

export class PlayTabLink extends React.Component<IPlayTabLinkProps, IPlayTabLinkState> {
    private isTabOpen: boolean = false;
    private routeSrv = new SrvM.RouteService();

    constructor(props: any) {
        super(props);
        this.state = {
            isShowOtherPlayers: this.props.routeParams.playSubfilter === StM.BookPagePlaySessionVisibility.AllSessions,
            isShown: props.routeParams.sessionType === StM.BookPageSessionType.Play
        };
    }

    componentDidUpdate(prevProps: IPlayTabLinkProps) {
        if (prevProps.routeParams.sessionType !== this.props.routeParams.sessionType ||
            prevProps.routeParams.playSubfilter !== this.props.routeParams.playSubfilter) {

            const isSessionTypeChanged = prevProps.routeParams.sessionType !== this.props.routeParams.sessionType;
            this.setState({
                isShowOtherPlayers: this.props.routeParams.playSubfilter === StM.BookPagePlaySessionVisibility.AllSessions,
                isShown: isSessionTypeChanged ? this.props.routeParams.sessionType === StM.BookPageSessionType.Play : this.state.isShown,
            });
        }
    }

    public render() {
        const isActive = this.props.routeParams.sessionType === StM.BookPageSessionType.Play;
        const classes = ClassNames("side-block-item-wrapper side-block-item-wrapper-play play", {
            extended: isActive,
            open: this.state.isShown
        });

        return (
            <Link to={this.getRoute()} className={classes} onClick={(e) => this.onTabClick(e)}>
                <div className="side-block-item">
                    <div className="block-item">
                        <div className="block-item-title">PLAY</div>
                        <div className="hidden-content play-hidden-content">
                            <div className="description">
                                Create or Join a Play Session
                            </div>
                            <div className="checkbox-wrapper" onClick={(e) => this.onShowOtherPlayersClick(e)}>
                                <label className="show-sessions">
                                    <input type="checkbox" readOnly checked={this.state.isShowOtherPlayers} hidden />
                                    <span>Show Public Play Sessions</span>
                                </label>
                            </div>
                        </div>                            
                    </div>
                </div>
            </Link>
        );
    }

    private setOpacity(){
        const blockPlayHidden: any = document.getElementsByClassName('play-hidden-content')[0];
        blockPlayHidden.style.opacity='1';
    }

    private onTabClick(e: any): void {
        if(e) {e.preventDefault(); e.stopPropagation();}
        if (this.props.routeParams.sessionType === StM.BookPageSessionType.Play) {
            this.setState({isShown: !this.state.isShown});
        }
        this.props.onTabClickHandler({sessionType: StM.BookPageSessionType.Play, date: this.props.routeParams.date});

        const blockLessonItem:any = document.getElementsByClassName('side-block-item-lesson')[0];

        const blockPlayHidden: any = document.getElementsByClassName('play-hidden-content')[0];

        const blockLessonItemOutter:any = document.getElementsByClassName('side-block-item-wrapper-lesson')[0];
        blockLessonItem.style.height= '79px';
        blockLessonItemOutter.style.height= '82px';
        
        if(!this.isTabOpen){
            blockPlayHidden.style.opacity = '0';
            setTimeout(()=>this.setOpacity(), 300);
            this.isTabOpen = true;
        }
    }

    private onShowOtherPlayersClick(e: any): void {
        if(e) { e.preventDefault(); e.stopPropagation(); }

        const isShowOtherPlayers = !this.state.isShowOtherPlayers;
        this.setState({isShowOtherPlayers: isShowOtherPlayers});
        
        const playSubfilter = isShowOtherPlayers ? StM.BookPagePlaySessionVisibility.AllSessions : StM.BookPagePlaySessionVisibility.OnlyMySessions ;
        this.props.onTabClickHandler({playSubfilter: playSubfilter, date: this.props.routeParams.date});
    }

    private getRoute() {
        return this.routeSrv.getBookPage({
            ...this.props.routeParams,
            sessionType: StM.BookPageSessionType.Play
        }).path;
    }
}
