import * as React from 'react';
import * as Redux from 'redux';
import { connect } from 'react-redux';

import { ActM, StM, SrvM } from '../modules';
import BasketItemMobile from '../components/basket/basketItem.mobile';
import BasketPackageItemMobile from '../components/basket/basketPackageItem.mobile';

interface IBasketProps {
    basket: any;
    isAuthorized: boolean;
    setHeight: boolean;
    user: StM.IUserStoreState;
    timeSlots: Array<StM.ICourtTimeSlotStoreState>;

    removeItem: (id: number) => void;
    removePackageItem: (id: number) => void;
    openBookDialog: () => Promise<any>;
    openAuthDialog: (url: string) => Promise<any>;
    openConfirmDialog: (index: number, isPackage: boolean) => Promise<any>;
    openCreateSessionDialog: (courtId: number, timeKey: string, sessionType: string, subFilter?: string, subFilterId?: string, sessionId?: number) => void;
    openSessionInfoDialog: (id: number) => Promise<any>;
    openSessionDialog: (id: number) => Promise<any>;
    openPackageDialog: (packageDefinition: StM.IPackageDefinitionStoreState) => Promise<any>;
}

interface IBasketState { }

class BasketMobile extends React.Component<IBasketProps, IBasketState> {
    _DOM: any;

    constructor(props: IBasketProps) {
        super(props);
    }

    render() {
        const goods = this.renderList(this.props.basket);
        const packages = this.renderPackagesList(this.props.basket);
        const hasItemsInBasket = !!goods.length || !!packages.length;

        return (
            <div className={"basket-content-block-wrapper-mobile"} ref={(elem) => this._DOM = elem}>
                <div className="basket-content-title-mobile">
                    My Cart
                </div>
                {!hasItemsInBasket &&
                    <div className="no-sessions-mobile">
                        <img src="/content/img/big_cart.svg" className="img-no-sessions-mobile" />
                        You have no sessions in your cart
                    </div>
                }
                {hasItemsInBasket &&
                    <div className="basket-content-mobile">
                    </div>
                }
                {hasItemsInBasket && packages}
                {hasItemsInBasket && goods}
                {hasItemsInBasket &&
                    <div className="button-wrapper-mobile">
                        <button className="btn-book btn-mobile btn-red" onClick={(e) => this.openBookDialogClick(e)}>CHECKOUT</button>
                    </div>
                }
            </div>
        )
    }

    private renderList(basket: StM.IBasketStoreState) {
        return basket.goods.map((item: any) => {
            return (
                <BasketItemMobile 
                    user={this.props.user}
                    key={item.id} 
                    session={item}
                    onBasketSessionClick={(basketSession) => { this.onBasketSessionClick(basketSession) }}
                    removeItem={(index) => this.removeClick(index)}
                />
            )
        })
    }

    private renderPackagesList(basket: StM.IBasketStoreState) {
        return basket.packages.map((item: any, index: number)=> {
            return (
                <BasketPackageItemMobile 
                    key={index}
                    basketPackage={item}
                    onBasketPackageClick={(basketPackage) => {this.onBasketPackageClick(basketPackage)}}
                    removeItem={(index) => this.removeClick(index, true)}
                />
            ) 
        });
    }
    

    openBookDialogClick(e: any) {
        if (e) { e.stopPropagation(); }
        if (!this.props.isAuthorized) {
            let thunk = new SrvM.RouteDialogService();
            let hash = thunk.getDialogHash(StM.DialogNames.Book, {});
            let url = encodeURIComponent(hash);
            this.props.openAuthDialog(url);
        } else {
            this.props.openBookDialog();
        }
    }

    private removeClick(index: number, isPackage: boolean = false) {
        this.props.openConfirmDialog(index, isPackage);
    }

    private onBasketSessionClick(basketSession: StM.ISessionStoreState) {
        this.props.openSessionDialog(basketSession.basketId);
    }

    private onBasketPackageClick(basketPackage: StM.IPackageDefinitionStoreState){
        this.props.openPackageDialog(basketPackage);
    }
};

const mapStateToProps = (state: StM.IGlobalStoreState) => {
    return {
        basket: state.basket,
        isAuthorized: state.app.isAuthorized,
        user: state.user,
        timeSlots: state.pages.book.timeSlots
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        removeItem: (id: number) => (dispatch(ActM.BasketActions.remove(id))),
        removePackageItem: (id: number) => dispatch(ActM.BasketActions.removePackage(id)),
        openBookDialog: () => (dispatch(ActM.DialogActions.open(StM.DialogNames.Book))),
        openAuthDialog: (url: string) => dispatch(ActM.DialogActions.open(StM.DialogNames.Auth, { tab: StM.AuthDialogTabs.SignIn, returnUrl: url })),
        openConfirmDialog: (index:number, isPackage: boolean) => (dispatch(ActM.DialogActions.open(StM.DialogNames.ConfirmDeleteBasketItem, {basketIndex:index, isPackage: isPackage}))),
        openSessionDialog: (id: number) => dispatch(ActM.SessionActions.openSessionDialog(StM.SessionInfoFromTypes.basket, id)),
        openPackageDialog: (packageDefinition: StM.IPackageDefinitionStoreState) => dispatch(ActM.DialogActions.open(StM.DialogNames.PackagePurchase, { packageDefinition, packageDefinitionId: packageDefinition.id }))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(BasketMobile);