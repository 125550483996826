import './confirmDeleteBasketItemDialog.mobile.scss';
import './dialogs.scss';

import * as _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { StM, ActM } from '../../modules';
import { ModalMobile } from '../../components/modal.mobile';


interface IConfirmDeleteBasketItemDialogProps {
    isShown: boolean;
    basketIndex: number;
    isPackage: boolean;

    closeDialog: () => void;
    removeSessionItem: (id: number) => void;
    removePackageItem: (id: number) => void;
}

interface IConfirmDeleteBasketItemState {}

class ConfirmDeleteBasketItemDialogMobile extends React.Component<IConfirmDeleteBasketItemDialogProps, IConfirmDeleteBasketItemState> {
    constructor(props: IConfirmDeleteBasketItemDialogProps) {
        super(props);
    }

    closeClick(e:any) {
        if(e) {e.stopPropagation();}
        this.props.closeDialog();
    }

    deleteClick(e:any) {
        if(e) {e.stopPropagation();}
        if(this.props.isPackage) {
            this.props.removePackageItem(this.props.basketIndex);
        }
        else {
            this.props.removeSessionItem(this.props.basketIndex);
        }
        this.props.closeDialog();
    }

    render() {
        if (this.props.isShown) {
            return (
                <ModalMobile classes="confirmation-delete-dialog-mobile" closeHandler={this.props.closeDialog} dialogName={StM.DialogNames.ConfirmDeleteBasketItem}>
                    <div className="modal-dialog-header">
                        <div className="title">Are you sure you want to delete the {this.props.isPackage ? 'package' : 'session'}?</div>
                    </div>
                    <div className="modal-dialog-footer">
                        <div className="btns-wrapper">
                            <div className="left-btn-wrapper">
                                <button onClick={(e) => this.deleteClick(e)}>delete</button>
                            </div>
                            <div className="right-btn-wrapper">
                                <button onClick={(e) => this.closeClick(e)}>cancel</button>
                            </div>
                        </div>
                    </div>
                </ModalMobile>
            );
        } else {
            return null;
        }
    }
}

const mapStateToProps = (state: StM.IGlobalStoreState, ownProps: any) => {
    let dialog = state.dialogs.confirm;
    return {
        isShown: dialog.isOpened,
        basketIndex: dialog.basketIndex,
        isPackage: dialog.isPackage
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        closeDialog: () => dispatch(ActM.DialogActions.close(StM.DialogNames.ConfirmDeleteBasketItem)),
        removeSessionItem: (id: number) => (dispatch(ActM.BasketActions.remove(id))),
        removePackageItem: (id :number) => (dispatch(ActM.BasketActions.removePackage(id)))
    };

}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmDeleteBasketItemDialogMobile);
