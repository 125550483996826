import * as React from 'react';
import '../containers/dialogs/dialogs.mobile.scss';
import ClassNames from 'classnames';
import { DialogHashParam } from '../appSettings';
import Footer from '../containers/footer.mobile';
import * as _ from 'lodash';

interface IModalMobileProps {
    dialogName: string;
    showHeader?: boolean;
    classes?: string;
    customZIndex?: number;
    closeHandler?: () => void;
    children?: React.ReactNode;
}

interface IModalMobileState { }

const BaseZIndex = 999;

export class ModalMobile extends React.Component<IModalMobileProps, IModalMobileState> {
    _DOM: HTMLDivElement;
    _LAY: HTMLDivElement;
    constructor(props: IModalMobileProps) {
        super(props);
    }

    render() {
        const order = this.getDialogOrder();
        const modalClasses = ClassNames("modal-mobile", { "full-screen": this.props.showHeader != undefined && !this.props.showHeader });
        const classes = ClassNames("modal-mobile-dialog-wrapper", this.props.classes);
        var zIndexStyle = this.props.customZIndex ? { zIndex: this.props.customZIndex } : { zIndex: BaseZIndex + order };

        return (
            <div className={modalClasses} style={zIndexStyle}>
                <div className={classes} ref={(elem) => this._DOM = elem}>
                    {/**(this.props.showHeader == undefined || this.props.showHeader) && <HeaderMobile />*/}
                    <div className="modal-dialog-content-mobile">

                        {this.props.children}

                        <Footer />
                    </div>
                </div>
            </div>
        );
    }

    private getDialogOrder() {
        const routeData = window.location.href.split('#');
        const dialogDatas = routeData && routeData.length > 1 ? routeData[1].split('&') : [];
        const dialogs = _.filter(dialogDatas, (dialogData: string) => {
            return dialogData.indexOf(DialogHashParam) > -1;
        });
        const currentDialogRouteString = '{0}={1}'.format(DialogHashParam, this.props.dialogName);
        const currentDialogRoute = _.find(dialogs, (dialog: string) => {
            return dialog.indexOf(currentDialogRouteString) > -1;
        });
        let order = dialogs.indexOf(currentDialogRoute);
        order = order > -1 ? order : 0;
        return order;
    }
}