import * as React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import * as StM from '../../../models/store';
import * as _ from 'lodash';

interface ITimeBoardProps {
    club: StM.IClubStoreState;
    date: moment.Moment;
}

interface ITimeBoardState {
}

class TimeBoard extends React.Component<ITimeBoardProps, ITimeBoardState> {
    constructor(props: ITimeBoardProps) {
        super(props);
    }

    private fillTimes(club: StM.IClubStoreState): Array<any>{
        let start = moment.duration(club && club.clubTimes.length > 0 ? club.clubTimes[this.props.date.day()].startTime : null);
        let end = moment.duration(club && club.clubTimes.length > 0 ? club.clubTimes[this.props.date.day()].endTime : null);
        let durationInMinutes = 60;
        let times = new Array<any>();
        for(let currentStartTime = moment.duration(start), currentEndTime = moment.duration(currentStartTime).add(durationInMinutes, 'minutes'); 
            currentEndTime.asMinutes() <= end.asMinutes(); 
            currentStartTime = currentStartTime.add(durationInMinutes, 'minutes'), currentEndTime = currentEndTime.add(durationInMinutes, 'minutes')
            ){
                let currentStartTimeUtc = moment.utc(currentStartTime.asMilliseconds());
                let currentEndTimeUtc = moment.utc(currentEndTime.asMilliseconds());

                let startFormat = currentStartTimeUtc.format('h:mm');
                let endFormat = currentEndTimeUtc.format('h:mm');

                let startTypeFormat = currentStartTimeUtc.format('a');
                let endTypeFormat = currentEndTimeUtc.format('a');
                times.push({
                    start: moment.duration(currentStartTime)
                    , end: moment.duration(currentEndTime)
                    , startFormat: startFormat
                    , endFormat: endFormat
                    , startTypeFormat: startTypeFormat
                    , endTypeFormat: endTypeFormat
                });
        }
        return times;
    }

    render() {
        let times = this.fillTimes(this.props.club);
        let last = _.last(times);
        let lastTime = last 
                    ? (
                        <div className="left-column-item">
                            <div className="time-wrapper-left-column">
                                <div className="time">{last.endFormat}</div>
                                <div className="time-format">{last.endTypeFormat}</div>
                            </div>
                            <div className="divider-line"></div>
                        </div>
                    ) 
                    : null ;
        return (
            <div className="table-left-column-wrapper">
                <div className="table-left-column">

                    {times.map((time) => {return (
                        <div key={time.startFormat + time.startTypeFormat} className="left-column-item">
                            <div className="time-wrapper-left-column">
                                <div className="time">{time.startFormat}<div className="divider-line"></div></div>
                                <div className="time-format">{time.startTypeFormat}</div>
                            </div>
                            
                        </div>
                    )})}

                    {lastTime}

                </div>
            </div>
        )
    };
};

function mapStateToProps(state: StM.IGlobalStoreState, ownProps: any) {
    return {
        club: state.club,
        date: ownProps.date
    }
}

function mapDispatchToProps(dispatch: any) {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(TimeBoard);
