import * as React from 'react';
import { compose, Dispatch } from 'redux';
import { connect } from 'react-redux';
import * as _ from 'lodash';

import * as StM from '../../../../models/store';
import * as ActM from '../../../../actions';
import * as SrvM from '../../../../services';

export interface IEditGroupAccountDialogProps extends IBaseEditGroupAccountDialogState {
    isOpened: boolean;

    onCloseDialog: (e?: any) => void;
    onFieldChange: (name: string, value: string) => void;
    onSave: (e: any) => void;
}

interface IBaseEditGroupAccountDialogProps {
    isOpened: boolean;
    group: StM.IGroupStoreState;
    user: StM.IUserStoreState;

    closeDialog: () => void;
    showSpinner: () => void;
    hideSpinner: () => void;
    updateGroupAccount: (group: StM.IGroupStoreState, image?: any) => Promise<StM.IGroupStoreState>;
    closeAlert: () => void;
}

interface IBaseEditGroupAccountDialogState {
    group: StM.IGroupStoreState;
    errors?: any;
}

const withBaseEditGroupAccountDialog = (Component: React.ComponentType<IEditGroupAccountDialogProps>) => {
    return class BaseEditGroupAccountDialog extends React.Component<IBaseEditGroupAccountDialogProps, IBaseEditGroupAccountDialogState> {
        private validator = new SrvM.ValidationService();
        private formValidator = new SrvM.FormValidationService();
        private authSrv = new SrvM.AuthenticationService();
        constructor(props: IBaseEditGroupAccountDialogProps) {
            super(props);

            this.state = {
                group: new StM.GroupStoreState(),
                errors: null,
            }
        }

        componentDidMount() {
            this.init(this.props);
        }
    
        componentDidUpdate(prevProps: IBaseEditGroupAccountDialogProps) {
            const isOpenedChanged = !prevProps.isOpened && this.props.isOpened;
            const isGroupChanged = this.props.isOpened && !!prevProps.group && !this.props.group;
    
            if (isGroupChanged) {
                this.reset();
                this.props.closeDialog();
            }
    
            if (isOpenedChanged) {
                this.init(this.props);
            }
        }

        public render() {
            return (
                <Component 
                    {...this.state}
                    isOpened={this.props.isOpened}
                    onCloseDialog={(e) => this.handleCloseDialog(e)}
                    onFieldChange={(name, value) => this.handleFieldChange(name, value)}
                    onSave={(e: any) => this.handleSave(e)}
                />
            );
        }

        private init(props: IBaseEditGroupAccountDialogProps) {
            this.setState({...this.state, group: {...props.group}});
        }

        private handleCloseDialog(e: any) {
            if(e) e.preventDefault();
            this.props.closeDialog();
        }

        private handleFieldChange(name: string, value: any) {
            let errors = !!this.state.errors && { ...this.state.errors };
            if (errors) delete errors[name];
            this.setState({
                group: {
                    ...this.state.group,
                    [name]: value
                },
                errors
            });
        };

        private validate(model: any): boolean {
            const errors = this.formValidator.validateCreateGroupAccountForm(model);
            if (this.validator.hasErrors(errors)) {
                this.setState({ ...this.state, errors });
                return false;
            }
            return true;
        }

        private handleSave(e: any) {
            if(e) e.preventDefault();
            if (!this.validate(this.state.group)) return false;

            this.props.showSpinner();
            this.props.updateGroupAccount(this.state.group)
                .then(() => {
                    this.props.hideSpinner();
                    this.props.closeDialog();
                }).catch((error) => {
                    this.props.closeAlert();
                    this.props.hideSpinner();
                    this.setState({...this.state, errors: this.validator.setServerError(error.data, this.state.errors)});
                });
        }

        private reset() {
            this.setState({
                errors: null,
                group: new StM.GroupStoreState(),
            });
        }
    }
}

const mapStateToProps = (state: StM.IGlobalStoreState, ownProps: any) => ({
    isOpened: state.dialogs.editGroupAccount.isOpened,
    group: state.user ? state.user.group : null,
    user: state.user,
});

const mapDispatchToProps = (dispatch: any) => ({
    showSpinner: () => dispatch(ActM.AppActions.showSpinner()),
    hideSpinner: () => dispatch(ActM.AppActions.hideSpinner()),
    closeDialog: () => dispatch(ActM.DialogActions.close(StM.DialogNames.EditGroupAccount)),
    updateGroupAccount: (group: StM.IGroupStoreState, image?: any) => dispatch(ActM.GroupAccountActions.update(group)),
    closeAlert: () => dispatch(ActM.DialogActions.close(StM.DialogNames.Alert)),
});

export const withEditGroupAccountDialog = compose(connect(mapStateToProps, mapDispatchToProps), withBaseEditGroupAccountDialog);