import * as React from 'react';
import moment from 'moment';
import ClassNames from 'classnames';
import * as StM from '../../../models/store';
import * as _ from 'lodash';
import CourtBoardMobile from './courtBoard.mobile';
import { EmptyCourtBoard } from './emptyCourtBoard';

interface ITimeBoardProps {
    club: StM.IClubStoreState;
    timeSlots: Array<StM.ICourtTimeSlotStoreState>;
    sessionType: string;
    user?: StM.IUserStoreState;
    basket: StM.IBasketStoreState;
    notifications: Array<StM.INotificationStoreState>;
    date: moment.Moment;
    expandedSlotTimeKey: string;
    coaches: Array<StM.ICoachStoreState>;
    coachFeeTiers: Array<StM.ICoachFeeTierStoreState>;
    params: StM.IBookPageRouteParams;
    availableTimes: Array<StM.IAvailableTimeStoreState>;
    selectedAvailability: StM.ICoachAvailabilityStoreState;

    onCreateSessionClick: (courtId: number, timeKey: string) => any;
    openSessionClick: (from: string, id: number) => void;
    onExpandSlot: (timeKey: string) => void;
    setAvailabilityClick?: (timeItem: StM.ICoachAvailabilityTimeItem) => void;
}

interface ITimeBoardState {
}

export default class TimeBoardMobile extends React.Component<ITimeBoardProps, ITimeBoardState> {
    constructor(props: ITimeBoardProps) {
        super(props);
    }

    private fillTimes(club: StM.IClubStoreState): Array<any> {
        if (!club.clubTimes.length) return new Array();
        let startTime = club.clubTimes[this.props.date.day()].startTime;

        let start = moment.duration(startTime);
        let startUtc = moment.utc(start.asMilliseconds());
        let startUtcRounded = startUtc.startOf('hour');
        let end = moment.duration(club.clubTimes[this.props.date.day()].endTime);
        let endUtc = moment.utc(end.asMilliseconds());
        let endUtcRounded = endUtc.startOf('hour');
        let durationInMinutes = 60;
        let times = new Array<any>();
        for (let currentStartTime = moment.duration(startUtcRounded.valueOf()), currentEndTime = moment.duration(currentStartTime).add(durationInMinutes, 'minutes');
            currentEndTime.asMinutes() <= moment.duration(endUtcRounded.valueOf()).add(durationInMinutes, 'minutes').asMinutes();
            currentStartTime = currentStartTime.add(durationInMinutes, 'minutes'), currentEndTime = currentEndTime.add(durationInMinutes, 'minutes')
        ) {
            let currentStartTimeUtc = moment.utc(currentStartTime.asMilliseconds());
            let currentEndTimeUtc = moment.utc(currentEndTime.asMilliseconds());
            let startFormat = currentStartTimeUtc.format('h:mm');
            let endFormat = currentEndTimeUtc.format('h:mm');
            let startTypeFormat = currentStartTimeUtc.format('a');
            let endTypeFormat = currentEndTimeUtc.format('a');
            times.push({
                start: moment.duration(currentStartTime)
                , end: moment.duration(currentEndTime)
                , startFormat: startFormat
                , endFormat: endFormat
                , startTypeFormat: startTypeFormat
                , endTypeFormat: endTypeFormat
            });
        }
        return times;
    }

    filterTimeBlocks(time: any) {
        const isAvailability = this.props.sessionType === StM.BookPageSessionType.Availability;
        let courtTimeBlocks = new Array<StM.ICourtTimeBlockStoreState>();
        this.props.timeSlots.map(slot => {
            var timeBlocks = slot.timeBlocks.filter(block => {
                const isStart = isAvailability
                    ? block.start.hours() <= time.start.hours() && block.end.hours() >= time.start.hours()
                    : block.start.hours() == time.start.hours();
                return isStart && !block.isOffset
            });
            if (timeBlocks && timeBlocks.length > 0) courtTimeBlocks.push(...timeBlocks);
        });
        return courtTimeBlocks;
    }

    filterNotificationBlocks(time: any) {
        if (!this.props.notifications || this.props.notifications.length == 0) return null;
        return this.props.notifications.filter(notification => {
            return !!notification.target &&
                notification.target.startDateTime.date() == this.props.date.date() &&
                notification.target.startDateTime.hour() == time.start.hours();
        });
    }

    filterAvailableTimes(time: any) {
        return this.props.availableTimes.filter(availableTime => {
            return availableTime.begin.date() == this.props.date.date();
        });
    }

    render() {
        const clubTime = this.props.club.clubTimes[this.props.date.day()];
        let isDayBlockedOut = !this.props.club.clubTimes.length ? true : clubTime.startTime.asMilliseconds() >= clubTime.endTime.asMilliseconds();

        let times = this.fillTimes(this.props.club);
        let last = _.last(times);
        let lastTime = last
            ? (
                <div className="time-item-mobile">
                    <div className="mobile-row">
                        <div className="mobile-col-3 time-item-container">
                            <div className="time-wrapper-mobile">
                                <div className="time-mobile">{last.endFormat}</div>
                                <div className="time-format-mobile">{last.endTypeFormat}</div>
                                <div className="divider-line-mobile"></div>
                            </div>
                        </div>
                        <div className="mobile-col-9 blank-mobile"></div>
                    </div>

                </div>
            )
            : null;
        return isDayBlockedOut ? (<EmptyCourtBoard message={!this.props.club.clubTimes.length ? "Please wait..." : null} />) : (
            <div className="time-board-mobile">
                <div className="time-item-mobile">
                    <div className="mobile-row">
                        <div className="mobile-col-3 time-item-container"></div>
                        <div className="mobile-col-9 blank-mobile"></div>
                    </div>
                </div>

                {times.map((time, index) => {
                    let courtTimeBlocks = this.filterTimeBlocks(time);
                    let notificationBlocks = this.filterNotificationBlocks(time);
                    let availableTimes = this.filterAvailableTimes(time);
                    let timeKey = time.startFormat + time.startTypeFormat;
                    const classes = ClassNames('time-item-mobile', { 'last-time-item-mobile': index == times.length - 1 });
                    return (
                        <div key={timeKey} className={classes} id={timeKey}>
                            <div className="mobile-row">
                                <div className="mobile-col-3 time-item-container">
                                    <div className="time-wrapper-mobile">
                                        <div className="time-mobile">{time.startFormat}</div>
                                        <div className="time-format-mobile">{time.startTypeFormat}</div>
                                        <div className="divider-line-mobile"></div>
                                    </div>
                                </div>
                                <div className="mobile-col-9">
                                    <CourtBoardMobile sessionType={this.props.sessionType}
                                        club={this.props.club}
                                        courtTimeBlocks={courtTimeBlocks}
                                        user={this.props.user}
                                        basket={this.props.basket}
                                        notifications={notificationBlocks}
                                        timeKey={timeKey}
                                        start={time.start}
                                        end={time.end}
                                        date={this.props.date}
                                        params={this.props.params}
                                        coaches={this.props.coaches}
                                        coachFeeTiers={this.props.coachFeeTiers}
                                        expandedSlotTimeKey={this.props.expandedSlotTimeKey}
                                        availableTimes={availableTimes}
                                        selectedAvailability={this.props.selectedAvailability}
                                        onCreateSessionClick={(courtId, timeKey) => this.props.onCreateSessionClick(courtId, timeKey)}
                                        openSessionClick={this.props.openSessionClick}
                                        onExpandSlot={(timeKey) => this.props.onExpandSlot(timeKey)}
                                        setAvailabilityClick={this.props.setAvailabilityClick}
                                    />
                                </div>
                            </div>

                        </div>
                    )
                })}

                {lastTime}

            </div>
        )
    };
};
