import './coachDetailsPage.mobile.scss';
import * as _ from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import * as ActionsModule from '../../actions';
import * as StoreModule from '../../models/store';
import { Constants } from '../../constants';
import * as StM from '../../models/store';

interface ICoachDetailsPageProps {
    coachId?: string,
    coach: StoreModule.ICoachStoreState,
    init: () => void;
    go: (url: string) => any;
}

interface ICoachDetailsPageState {
}

class CoachDetailsPageMobile extends React.Component<ICoachDetailsPageProps, ICoachDetailsPageState> {
    constructor(props: ICoachDetailsPageProps) {
        super(props);
    }

    componentDidMount() {
        this.props.init();
    }

    bookCoach(e: any) {
        if (e) e.stopPropagation();
        if (!this.props.go) return false;
        let handledDate = moment().format(Constants.DateTime.DATE_FORMAT);
        let url = '/book/lesson/{0}/{1}/{2}'.format(handledDate, StM.BookPageLessonFilterType.Coach, this.props.coach.id);
        this.props.go(url);
    }

    render() {
        const image = this.props.coach && this.props.coach.imageId ? this.props.coach.imageId : '';
        const urlimg = image ? `/api/v2/blobs/${image}/content` : '/content/img/nophoto-2.png';
        const displayName = this.props.coach && this.props.coach.displayName ? this.props.coach.displayName : 'no name';
        const displayTier = this.props.coach && this.props.coach.coachFeeTier
            ? this.props.coach.coachFeeTier.title
            + (this.props.coach.coachFeeTier.subtitle ? ', ' + this.props.coach.coachFeeTier.subtitle : '')
            : 'no tier';
        const displayDescription = this.props.coach && this.props.coach.description ? this.props.coach.description : 'no description';

        return (
            <div className="main-content-wrapper-mobile coach-page">
                <div className="page-title-mobile">{displayName}</div>
                <div className="coach-tier-mobile">{displayTier}</div>
                <div className="coach-wrapper">
                    <div className="coach-item-photo-wrapper">
                        <img src={(urlimg)} className="coach-photo" width="250px" height="250px" />
                    </div>
                    <div className="coach-item-info-wrapper-mobile">
                        <div className="coach-item-info">
                            <div className="coach-biography" dangerouslySetInnerHTML={{__html: displayDescription}}></div>
                        </div>
                        <button className="btn-book-coach btn-red btn-mobile" onClick={(e) => this.bookCoach(e)}>Book a Lesson</button>
                    </div>

                </div>
            </div>
        )
    };
};

function mapStateToProps(state: StoreModule.IGlobalStoreState, ownProps: any) {
    const coach = _.find(state.coaches, { id: ownProps.match.params.id });

    return {
        coach: coach
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        init: () => { },
        go: (url: string) => dispatch(ActionsModule.RouteActions.push(url))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CoachDetailsPageMobile);
