import * as React from 'react';
import './groupGrid.scss';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import * as StM from '../../../../models/store';
import * as SrvM from '../../../../services';

interface IGroupsGridProps {
    data?: StM.IGroupStoreState[];
    isHead?: boolean;
    user?: StM.IPublicUserStoreState;

    onSwitchToGroup?: (group: StM.IGroupStoreState) => void;
    onInviteGroupMember?: (group: StM.IGroupStoreState) => void;
    onRemoveGroup?: (group: StM.IGroupStoreState) => void;
    onLeaveGroup?: (group: StM.IGroupStoreState) => void;
}

export class GroupsGrid extends React.Component<IGroupsGridProps> {
    private readonly groupPortalButtonLabel: string = 'Group Portal';
    private readonly groupPortalInviteButtonLabel: string = 'Invite a Member';
    private readonly leaveGroupButtonLabel: string = 'Leave Group';
    private utils = new SrvM.Utils();

    constructor(props: IGroupsGridProps){
        super(props);
    }

    public render() {
        if (this.props.isHead)
        {
            return this.renderHeadGrid();
        }
        else
        {
            return this.renderMemberGrid();
        }
    }

    private renderHeadGrid() {
        return (
            <div className="group-grid ag-theme-alpine">
                <AgGridReact
                    domLayout='autoHeight'
                    rowData={this.props.data}
                    columnDefs={this.getHeadGridColumns()}
                />
            </div>
        );
    }

    private getHeadGridColumns(): Array<any> {
        return [
            {
                field: 'title',
                headerName: 'Group Name',
                resizable: false,
                flex: 10
            },
            {
                field: 'portal',
                headerName: '',
                resizable: false,
                sortable: false,
                flex: 2,
                cellRenderer: (params) => (
                    <a
                        className='k-button'
                        onClick={() => this.handleSwitchToGroupClick(params.data)}
                    >
                        { this.groupPortalButtonLabel }
                    </a>   
                )
            },
            {
                field: 'invite',
                headerName: '',
                resizable: false,
                sortable: false,
                flex: 2,
                cellRenderer: (params) => (
                    <a
                        className='k-button'
                        onClick={() => this.handleInviteMemberClick(params.data)}
                    >
                        { this.groupPortalInviteButtonLabel }
                    </a>   
                )
            },
            {
                field: 'remove',
                headerName: '',
                resizable: false,
                sortable: false,
                flex: 1,
                cellRenderer: (params) => (
                    <a
                        href='#'
                        role='button'
                        className='remove-btn'
                        onClick={(e) => this.handleRemoveGroupClick(params.data)}
                    />
                ),
            }
          ];
    }

    private renderMemberGrid()
    {
        const groups = this.props.data.map(item => {
            return {
                ...item,
                isAdmin: item.members.find(member => member.user.id === this.props.user.id).canLoginAsGroupAccount
            }
        }).sort((left, right) => {
            if (left.isAdmin == right.isAdmin) {
                return left.title.localeCompare(right.title);
            }

            return left.isAdmin ? -1 : 1;
        });

        return (
            <div className="group-grid ag-theme-alpine">
                <AgGridReact
                    domLayout='autoHeight'
                    rowData={groups}
                    columnDefs={this.getMemberGridColumns()}
                />
            </div>
        );
    }

    private getMemberGridColumns(): Array<any> {
        return [
            {
                field: 'title',
                headerName: 'Group Name',
                resizable: false,
                flex: 3
            },
            {
                field: 'members',
                headerName: 'Group Admins',
                resizable: false,
                flex: 3,
                cellRenderer: (params) => (
                    <td>
                        {params.data.members
                        .sort((left, right) => { // The head must be first all others must be in the alphabetical order
                            if (left.type  === StM.GroupMemberType.Head) {
                                return -1;
                            } else if (right.type  === StM.GroupMemberType.Head) {
                                return 1;
                            }
                            return left.user.displayName.localeCompare(right.user.displayName);
                        })
                        .filter( // Filter out all non-admins
                            member => member.type  === StM.GroupMemberType.Head || member.canLoginAsGroupAccount)
                        .map(member =>
                            member.type === StM.GroupMemberType.Head && (
                                <span key={member.id} className='text-bold'>{this.formatName(member)}<br/></span>
                            ) || (<span key={member.id}>{this.formatName(member)}<br/></span>))
                        }
                    </td>  
                )
            },
            {
                field: 'groupPortal',
                headerName: '',
                resizable: false,
                sortable: false,
                flex: 1,
                cellRenderer: (params) => (
                    params.data.isAdmin ? (
                        <a onClick={() => this.handleSwitchToGroupClick(params.data)}>
                            { this.groupPortalButtonLabel }
                        </a>   
                    ) : null
                )
            },
            {
                field: 'leave',
                headerName: '',
                resizable: false,
                sortable: false,
                flex: 1,
                cellRenderer: (params) => (
                    <a onClick={(e) => this.handleLeaveGroupClick(params.data)}>
                        { this.leaveGroupButtonLabel }
                    </a>
                ),
            }
          ];
    }

    private formatName(member: any) {
        // Show Head in bold with " (Head)" suffix
        if (member.type === StM.GroupMemberType.Head) {
            return member.user.displayName + " (Head)";
        }

        // Show the user with " (Me)" suffix
        if (member.user.id == this.props.user.id) {
            return member.user.displayName + " (Me)";
        }

        return member.user.displayName;
    }

    private handleSwitchToGroupClick(dataItem: StM.IGroupStoreState) {
        if(this.props.onSwitchToGroup){
            this.props.onSwitchToGroup(dataItem);
        }
    }

    private handleInviteMemberClick(dataItem: StM.IGroupStoreState) {
        if(this.props.onInviteGroupMember){
            this.props.onInviteGroupMember(dataItem);
        }
    }

    private handleRemoveGroupClick(dataItem: StM.IGroupStoreState) {
        if(this.props.onRemoveGroup){
            this.props.onRemoveGroup(dataItem);
        }
    }

    private handleLeaveGroupClick(dataItem: StM.IGroupStoreState) {
        if(this.props.onLeaveGroup){
            this.props.onLeaveGroup(dataItem);
        }
    }
}