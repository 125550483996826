import * as ActionsModule from '../';
import * as StoreModule from '../../models/store';
import * as ServiceModule from '../../services';
import { ThunkService } from '../../services/thunkService';
import * as Redux from 'redux';

export class EmailConfirmationPageActions {

    static init(    ): (dispatch: any, getState: () => StoreModule.IGlobalStoreState, thunkService: ThunkService) => void {
        return (dispatch: any, getState: () => StoreModule.IGlobalStoreState, thunkService: ThunkService) => {

            const final = () => {
                //dispatch({ type: 'Pages/EmailConfirmation/Init', payload: null});
                dispatch(ActionsModule.AppActions.hideSpinner());
                dispatch(ActionsModule.BaseActions.hideSplash());
            };
            final();
        };
    }
}
