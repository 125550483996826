import './dialogs.scss';
import './historyQueryDialog.scss';

import * as _ from 'lodash';
import * as React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import * as StM from '../../models/store';
import * as SeM from '../../services';
import * as ActM from '../../actions';
import { Modal } from '../../components';

import * as SrvM from '../../services';

let utils = new SrvM.Utils();

const MESSAGE_ERROR_TEXT = "Message cannot be empty";

interface IHistoryQueryDialogProps {
    isShown: boolean;
    payment: StM.IUserPaymentStoreState;
    customSessionTypes: Array<StM.ICustomSessionTypeStoreState>;

    closeDialog: () => void;
    sendRequest: (message: string, payment: StM.IUserPaymentStoreState) => Promise<any>;
    showSpinner: () => any;
    hideSpinner: () => any;
    showAlert: (msgKey:string) => Promise<any>;
}

interface IHistoryQueryDialogState {
    messageError: boolean;
}

class HistoryQueryDialog extends React.Component<IHistoryQueryDialogProps, IHistoryQueryDialogState> {
    
    private message: string;
    private isSend: boolean;

    private submitDirty: boolean;

    constructor(props: IHistoryQueryDialogProps) {
        super(props);
        this.message = '';
        this.isSend = false;
        this.state = {
            messageError: false,
        };
    }

    componentDidUpdate(prevProps: IHistoryQueryDialogProps) {
        if (
            prevProps.isShown !== this.props.isShown ||
            prevProps.payment !== this.props.payment ||
            prevProps.customSessionTypes !== this.props.customSessionTypes
        ) {
            this.setState({ messageError: false });
            this.message = '';
            this.submitDirty = false;
            this.isSend = false;
        }
    }

    render() {
        let payment = this.props.payment;

        if (this.props.isShown && payment) {
            const dateFormat = this.getStringFromDate(payment.session.startDateTime);
            const timeFormat = payment.session.startDateTime.format('h:mm');
            const timePeriodFormat = payment.session.startDateTime.format('A');
            const isPackagePayment = payment.displayName == "Package";
            const paymentType = payment.displayName != "Custom" ? payment.displayName : utils.getCustomSessionTypeTitleByAlias(payment.session.typeAlias, this.props.customSessionTypes);
            const isCreditsPayment = payment.credits && !isPackagePayment;
            return (
                <Modal classes="send-query" closeHandler={this.props.closeDialog} dialogName={StM.DialogNames.HistoryQuery}>
                    <div className="modal-dialog">
                        <div className="modal-dialog-header">
                            <span className="close-dialog" onClick={(e) => this.onCloseClick(e)}></span>
                            <div className="modal-dialog-title">
                                Leave Comment
                            </div>
                            <div className="query-info-wrapper">
                                <div className="query-info-header">
                                    <div className="session-date">{isPackagePayment ? "Transaction" : "Session"} Date:</div>
                                    <div className="type">Type:</div>
                                    <div className="payment-amount">Payment Amount:</div>
                                    <div className="invoice">Invoice ID:</div>
                                </div>
                                <div className="query-info-body">
                                    <div className="session-date">
                                        <span className="date">{dateFormat}</span><span>, </span> 
                                        <span className="time">{timeFormat}</span>
                                        <span className="time-zone"> {timePeriodFormat}</span>
                                    </div>
                                    <div className="type">{paymentType}</div>
                                    {!isCreditsPayment && (<div className="payment-amount">
                                        <span>$</span><span className="payment-count">{utils.formatStringPrice(payment.amount.toString())}</span>
                                    </div>)}
                                    {!!isCreditsPayment && (<div className="payment-amount">
                                        <span className="payment-count">{payment.credits}</span> <span>credit{payment.credits > 1 ? "s": ""}</span>
                                    </div>)}
                                    <div className="invoice">{payment.id}</div>
                                </div>
                            </div>
                            <div className="divider-line-modal"></div>
                        </div>
                        <div className="modal-dialog-body">
                            <div className="query-form-wrapper">
                                <div className="query-form-title">
                                    Note
                                </div>
                                <textarea rows={5} maxLength={3000} onChange={(e) => {this.onMessageChagingHandler(e)}}></textarea>
                                {this.state.messageError && <div className="validation-error">{MESSAGE_ERROR_TEXT}</div>}
                            </div>
                        </div>
                        <div className="modal-dialog-footer">
                            <div className="modal-dialog-footer-content">
                                <div className="btn-book-wrapper">
                                    <button className="btn-book" onClick={(e)=> this.onSendClick(e)}>Send</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            );
        } else {
            return null;
        }
    }

    private onCloseClick(e:any) {
        if (e) e.stopPropagation();
        this.props.closeDialog();
    }

    private getStringFromDate(date:moment.Moment) {
        return date.format("MM/DD/YYYY");
    }

    private validateMessage(): boolean {
        return !!this.message;
    }

    private onMessageChagingHandler(e: any){
        this.message = e.target.value as string || "";
        const wasError = this.state.messageError;
        const isValid = this.validateMessage();
        if (this.submitDirty && wasError === isValid) {
            this.updateErrorMessage(isValid);
        }
    }

    private updateErrorMessage(isValid: boolean) {
        this.setState({...this.state, messageError: !isValid});
    }

    private onSendClick(e: any){
        if(e) {e.preventDefault(); e.stopPropagation();}
        
        this.submitDirty = true;
        const isValid = this.validateMessage();
        if (isValid) {
            this.isSend = true;

            this.props.showSpinner();
            this.props.sendRequest(this.message, this.props.payment)
            .then(() => {
                this.props.closeDialog();
                this.props.hideSpinner();
                this.props.showAlert(StM.MessagesKey.SupportWasSend);
            }).catch(() => {
                this.props.hideSpinner();
            });
        } else {
            this.updateErrorMessage(isValid);
        }
    }

}

const mapStateToProps = (state: StM.IGlobalStoreState, ownProps: any) => {
    let dialog = state.dialogs.historyQuery;
    let isOpened = dialog.isOpened;
    let payment: StM.IUserPaymentStoreState;
    let utils = new SeM.Utils();
    if (isOpened) {
        let payments = utils.getPagePayments(state);
        let id = dialog.paymentId;
        payment = _.find(payments, {id: id})
    }
    return {
        isShown: isOpened,
        payment: payment,
        customSessionTypes: state.customSessions
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        closeDialog: () => dispatch(ActM.DialogActions.close(StM.DialogNames.HistoryQuery))
        , sendRequest: (message: string, payment: StM.IUserPaymentStoreState) => dispatch(ActM.HistoryQueryDialogActions.sendRequest(message, payment))
        , showSpinner: () => dispatch(ActM.AppActions.showSpinner())
        , hideSpinner: () => dispatch(ActM.AppActions.hideSpinner())
        , showAlert: (msgKey: string) => dispatch(ActM.DialogActions.open(StM.DialogNames.Alert, {msgKey: msgKey, messageType: StM.MessageTypes.Success}))
    };

}

export default connect(mapStateToProps, mapDispatchToProps)(HistoryQueryDialog);
