import moment from 'moment';
import PackageInfo, { IPackageInfoCreditsDefinition } from '../../../components/pages/packages/packageInfo';
import * as React from 'react';
import { IPackageDefinitionStoreState } from '@models/store/packages';
import { ICustomSessionTypeStoreState } from '@models/store/clubs';

export const PackagesList: React.FC<{ 
    packages: IPackageDefinitionStoreState[],
    onBuyPackageClick: (id: number) => void,
    customSessionTypes: ICustomSessionTypeStoreState[]
}> = (props) => {
    
    const getPackageCreditsDefinitions = (packageDef: IPackageDefinitionStoreState): Array<IPackageInfoCreditsDefinition> => {
        return packageDef.creditsDefinitions.map(c => {
            return {
                id: c.id,
                credits: c.credits,
                title: c.title,
                description: c.description,
                sortOrder: c.sortOrder,
                sessionType: c.packageSessionType.sessionType,
                alias: c.typeAlias
            }
        });
    }
    
    return <>
        {
            (props.packages || []).map((item, index) => {
                const expirationDate = moment().clone().add(item.expirationTimeSpan.asDays(), 'days');
                const creditsDefinitions = getPackageCreditsDefinitions(item);
                return (
                    <PackageInfo key={index}
                        id={item.id}
                        title={item.title}
                        description={item.description}
                        coachFeeTier={item.coachFeeTier}
                        expirationDate={expirationDate}
                        price={item.price}
                        creditsDefinitions={creditsDefinitions}
                        customSessionTypes={props.customSessionTypes}
                        enablePurchasing={true}
                        onBuyClick={props.onBuyPackageClick}
                    />);
            })
        }
    </>
}
