import './controlls.scss';
import './fonts.scss';
import './layout.scss';
import './media-query.scss';
import './packages.scss';
import './styles.scss';

import 'core-js/stable';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { Route, Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import moment from 'moment';

import { Utils } from './services/utils';
import { history, persistor, store } from './store/store';
import App from './containers/app';
import AppMobile from './containers/app.mobile';
import ErrorBoundary from './containers/errorBoundary';

const utils = new Utils();

moment.duration.prototype.toString = function() {
    return '{0}:{1}:{2}'.format(this.hours(), this.minutes(), this.milliseconds())
}

const customWindow: any = window;
const customDocument: any = document;
customWindow.IS_IE = /*@cc_on!@*/ false || !!customDocument.documentMode;
customWindow.IS_MOBILE = utils.isMobile();

ReactDOM.createRoot(document.getElementById('root')!).render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <Router history={history}>
                <ErrorBoundary>
                    <Route path="/" component={!customWindow.IS_MOBILE ? App : AppMobile} />
                </ErrorBoundary>
            </Router>
        </PersistGate>
    </Provider>
);
