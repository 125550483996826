import './historyPage.mobile.scss';

import moment from 'moment';
import ClassNames from 'classnames';
import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as _ from 'lodash';
import * as StM from '../../../models/store';
import * as ActM from '../../../actions';
import { Constants } from '../../../constants';
import * as SrvM from '../../../services';

let utils = new SrvM.Utils();

interface IHistoryPageProps {
    isAuthorized: boolean;
    payments: Array<StM.IUserPaymentStoreState>;
    startFormat: string;
    endFormat: string;
    start: moment.Moment;
    end: moment.Moment;
    go: (url: string) => any;
    showSpinner: () => any;
    hideSpinner: () => any;
    init: (start: moment.Moment, end: moment.Moment) => Promise<any>;
    getPaymentHistory: (start: moment.Moment, end: moment.Moment) => Promise<any>;
    openQueryDialog: (paymentId: number) => void;
    openAuthDialog: () => void;
    club: StM.IClubStoreState;
    user: StM.IUserStoreState;
    customSessionTypes: Array<StM.ICustomSessionTypeStoreState>;
}

interface IHistoryPageState {
    activePage?: number;
    periodKey?: number;
}

class HistoryPageMobile extends React.Component<IHistoryPageProps, IHistoryPageState> {
    private utils: SrvM.Utils;
    filterStartDate: moment.Moment;
    filterEndDate: moment.Moment;
    periodList: Array<any> = [
        {
            key: 1,
            value: StM.HistoryPeriods.Week
        },
        {
            key: 2,
            value: StM.HistoryPeriods.Month
        },
        {
            key: 3,
            value: StM.HistoryPeriods.Any,
            calendarShow: true
        }
    ];
    itemOnPage = 5;
    constructor(props: IHistoryPageProps) {
        super(props);
        this.utils = new SrvM.Utils();
        this.state = {
            activePage: 0,
            periodKey: 3
        }
    }

    componentDidMount() {
        this.props.init(this.props.start, this.props.end);
    }

    componentDidUpdate(prevProps: IHistoryPageProps) {
        const isAuthorizedChanged = prevProps.isAuthorized !== this.props.isAuthorized;
        if (isAuthorizedChanged && this.props.isAuthorized) {
            this.props.getPaymentHistory(this.props.start, this.props.end);
        }
    }

    render() {
        const payments = this.props.payments || [];
        this.filterEndDate = this.props.end.clone();
        this.filterStartDate = this.props.start.clone();

        if (this.props.isAuthorized) {
            let start = this.itemOnPage * this.state.activePage;
            let end = this.itemOnPage + this.itemOnPage * this.state.activePage;
            let filtered = payments.slice(start, end);
            let selectedPeriod = this.state.periodKey || 3;
            let period = _.find(this.periodList, (i) => i.key === selectedPeriod);
            return (
                <div className="main-content-wrapper profile-pages-history-mobile">
                    <div className="page-title">History</div>
                    {this.renderPeriodHeader(period)}
                    <div className="history-list-wrapper">
                        {this.renderHistoryList(filtered)}
                    </div>
                    {this.renderPagination(payments)}
                </div>
            )
        } else {
            return (
                <div className="main-content-wrapper profile-pages-history-mobile">
                    <div className="page-title">History</div>
                    <p>{StM.Strings.UnathorizedAccessTitle}</p>
                </div>
            );
        }

    };

    renderPeriodHeader(period: any) {
        if (period && period.calendarShow) {
            return null
           
        } else {
            return null
           
        }
    }

    renderHistoryList(payments: Array<StM.IUserPaymentStoreState>) {
        if (!payments) return null;
        let list: Array<any> = [];
        for (let i = 0; i < payments.length; i++) {
            let item = payments[i];
            list.push(this.renderHistoryItem(item))
        }
        return (
            <ul className="history-list">{list}</ul>
        );
    }

    renderPagination(payments: Array<any>) {
        if (!payments) return null;
        let pagesCount = Math.ceil(payments.length / this.itemOnPage);
        // if (pagesCount == 1) return null;
        let list: Array<any> = [];
        for (let i = 0; i < pagesCount; i++) {
            let classes = ClassNames("paginator-item", { active: this.state.activePage == i });
            list.push(<div className={classes} key={i} onClick={(e) => this.pageChangeHandler(e, i)}>{i + 1}</div>);
        }
        return (
            <div className="paginator-wrapper">
                {list}
            </div>
        );
    }

    renderHistoryItem(payment: StM.IUserPaymentStoreState) {
        const dateFormat = this.getStringFromDate(payment.session.startDateTime);
        const timeFormat = payment.session.startDateTime.format('h:mm');
        const timePeriodFormat = payment.session.startDateTime.format('A');
        const transactionDate = payment.transactionDate ? moment(payment.transactionDate).format('MM/DD/YYYY') : '';
        const dateTimeFormat = `${dateFormat}, ${timeFormat} ${timePeriodFormat}`;
        const isPackagePayment = payment.displayName == "Package";
        const paymentType = payment.displayName != "Custom" ? payment.displayName : utils.getCustomSessionTypeTitleByAlias(payment.session.typeAlias, this.props.customSessionTypes);
        const isCreditsPayment = payment.credits && !isPackagePayment;
        return (
            <li className="history-list-item" key={payment.id}>
                <div className="mobile-col-12">
                    <div className="mobile-col-6 transaction-date">
                        <div className="transaction-date-mobile">Transaction Date</div>
                        {transactionDate}
                    </div>
                    <div className=" mobile-col-6 type ">
                        <div className="type-mobile">Type</div>
                        {paymentType}
                    </div>
                </div>
                {!isPackagePayment && <div className="mobile-col-12">
                    <div className="mobile-col-6 session-date-time ">
                        <div className="session-date-time-mobile">Session Date</div>
                        {dateTimeFormat}
                    </div>
                    <div className="mobile-col-6 invoice-id">
                        <div className="invoice-id-mobile">Invoice ID</div>
                        {payment.id}
                    </div>
                </div>}
                <div className="mobile-col-12">
                    <div className="mobile-col-6 details">
                         <div className="details-mobile">Details</div>
                         {payment.status}
                    </div>
                    <div className="mobile-col-6 payment-amount">
                        <div className="payment-amount-mobile">Payment Amount</div>
                        {!isCreditsPayment && <span>${utils.formatStringPrice(payment.amount.toString())}</span>}
                        {!!isCreditsPayment && <span>{payment.credits} credit{payment.credits > 1 ? "s" : ""}</span>}
                    </div>
                </div>
            </li>
        );
    }

    onQueryClick(e: any, payment: StM.IUserPaymentStoreState) {
        if (e) e.stopPropagation();
        this.props.openQueryDialog(payment.id);
    }

    endDateHandler(date: moment.Moment) {
        this.filterEndDate = date.clone();
    }

    startDateHandler(date: moment.Moment) {
        this.filterStartDate = date.clone();
    }

    validPeriod(start: moment.Moment, end: moment.Moment) {
        let startIsSame = this.props.start.isSame(start);
        let endIsSame = this.props.end.isSame(end);
        let endIsNotBefore = end.isAfter(start) || start.isSame(end);
        if (startIsSame && endIsSame && !endIsNotBefore) return false;
        return true;
    }

    updatePeriodClick(e: any) {
        if (e) e.stopPropagation();
        let isValid = this.validPeriod(this.filterStartDate, this.filterEndDate);
        if (isValid) {
            this.updateRoute(this.filterStartDate, this.filterEndDate);
        } else {
            this.filterEndDate = this.props.end.clone();
            this.filterStartDate = this.props.start.clone();
            this.forceUpdate();
        }
    }

    updateRoute(start: moment.Moment, end: moment.Moment) {
        let url = '{0}/{1}/{2}/{3}'.format(
            StM.Pages.User
            , StM.UserPages.History
            , start.format(Constants.DateTime.DATE_FORMAT)
            , end.format(Constants.DateTime.DATE_FORMAT)
        );
        this.props.go(url);
        this.getPayments(start, end);
    }

    updateSelectedPeriod(item: any) {
        const utils = new SrvM.Utils();
        let date = utils.getCurrentClubDateTime(this.props.club);
        switch (item.value) {
            case StM.HistoryPeriods.Week: {
                this.filterEndDate = date.clone();
                this.filterStartDate = date.clone().add(-7, 'day');
                this.updateRoute(this.filterStartDate, this.filterEndDate);
                break;
            }
            case StM.HistoryPeriods.Month: {
                this.filterEndDate = date.clone();
                this.filterStartDate = date.clone().add(-1, 'month');
                this.updateRoute(this.filterStartDate, this.filterEndDate);
                break;
            }
        }
        this.setState({ periodKey: item.key })
    }

    getPayments(start: moment.Moment, end: moment.Moment) {
        this.setState({ activePage: 0 });
        this.props.showSpinner();
        this.props.getPaymentHistory(start, end)
            .then(() => {
                this.props.hideSpinner();
            }).catch(() => {
                this.props.hideSpinner();
            });
    }

    getStringFromDate(date: moment.Moment) {
        return date.format("M/D/YYYY");
    }

    pageChangeHandler(e: any, number: number) {
        if (e) e.stopPropagation();
        this.setState({ activePage: number });
    }
};

function mapStateToProps(state: StM.IGlobalStoreState, ownProps: any) {
    return {
        isAuthorized: state.app.isAuthorized
        , payments: state.pages.history.payments
        , startFormat: ownProps.match.params.start
        , endFormat: ownProps.match.params.start
        , start: moment(ownProps.match.params.start, Constants.DateTime.DATE_FORMAT)
        , end: moment(ownProps.match.params.end, Constants.DateTime.DATE_FORMAT)
        , club: state.club
        , user: state.user
        , customSessionTypes: state.customSessions
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        init: (start: moment.Moment, end: moment.Moment) => dispatch(ActM.HistoryPageActions.init(start, end)),
        getPaymentHistory: (start: moment.Moment, end: moment.Moment) => dispatch(ActM.HistoryPageActions.getPaymentHistory(start, end)),
        openQueryDialog: (paymentId: number) => dispatch(ActM.DialogActions.open(StM.DialogNames.HistoryQuery, { paymentId: paymentId })),
        showSpinner: () => dispatch(ActM.AppActions.showSpinner()),
        hideSpinner: () => dispatch(ActM.AppActions.hideSpinner()),
        go: (url: string) => dispatch(ActM.RouteActions.replace(url)),
        openAuthDialog: () => dispatch(ActM.DialogActions.open(StM.DialogNames.Auth, { tab: StM.AuthDialogTabs.SignIn }))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HistoryPageMobile);
