import * as React from 'react';
import * as StM from '../../../models/store';

interface ICoachInfoProps {
    coach: StM.ICoachStoreState;
    go?: (url: string) => any;
}

interface ICoachInfoState { }

export class CoachInfoMobile extends React.Component<ICoachInfoProps, ICoachInfoState> {

    selectCoach(e: any) {
        if (e) e.stopPropagation();
        if (!this.props.go) return false;
        let url = '/coach/{0}'.format(this.props.coach.id);
        this.props.go(url);
    }

    render() {
        const image = this.props.coach && this.props.coach.imageId ? this.props.coach.imageId : '';
        const urlimg = image ? `/api/v2/blobs/${image}/content` : '/content/img/nophoto-2.png';

        const displayName = this.props.coach && this.props.coach.displayName ? this.props.coach.displayName : 'no name';
        const displayTier = this.props.coach && this.props.coach.coachFeeTier ? this.props.coach.coachFeeTier.title  : 'no tier';
        return (
            <div className="coaches-item-mobile">
                <div className="coaches-item-photo-wrapper" >
                    <img src={(urlimg)} className="coach-photo" onClick={(e) => this.selectCoach(e)} width="250px" height="250px" />
                </div>
                <div className="coaches-item-info">
                    <div className="coach-name-mobile" onClick={(e) => this.selectCoach(e)}>{displayName}</div>
                    <div className="coach-tier-mobile">{displayTier}</div>
                </div>
            </div>
        )
    };
};