import './clinicBadge.scss';
import * as React from 'react';

import { StM, PolM } from '../../../../modules';
import { IBaseBadgeProps, CommonBadge } from './baseBadge';

interface IClinicBadgeBadgeProps extends IBaseBadgeProps {
    user: StM.IUserStoreState;

    onBadgeClick: (from: string, id: number) => void;
}

export class ClinicBadge extends React.Component<IClinicBadgeBadgeProps> {
    private sessionInfo: PolM.SessionInfoPolicy;
    private sessionAvailability: PolM.SessionAvailabilityPolicy;

    constructor(props: IClinicBadgeBadgeProps) {
        super(props);
        this.init(props);
    }

    public componentDidUpdate(prevProps: IClinicBadgeBadgeProps) {
        const didUserChange = this.props.user.id != prevProps.user.id;
        const didTimeChange = this.props.time.date != prevProps.time.date;
        
        if (didUserChange || didTimeChange) this.init(this.props);
    }

    public render() {
        const session = this.props.time.session;
        const isGroupSession = this.sessionAvailability.getIsGroupSession();
        const isGroupMemberSession = this.sessionAvailability.getIsGroupMemberSession(false);
        const isBoughtByGroupMember = isGroupMemberSession && !isGroupSession && !this.props.time.isSessionFromBasket;

        return (
            <div className={CommonBadge.getBadgeClasses(this.props.time, this.props.user)} onClick={(e) => this.onBadgeClick(e, session)}>
                <div className="table-column-item">
                    {!isBoughtByGroupMember && this.renderFullContent(session)}
                    {isBoughtByGroupMember && CommonBadge.renderGroupMemberContent(session, this.props.user, this.props.filter)}
                </div>
            </div>
        )
    }

    private renderFullContent(session: StM.ISessionStoreState) {
        const skill = session.playerQualification ? session.playerQualification.skill : null;
        const ownerName = session.owner && session.owner.displayName ? session.owner.displayName : 'No Owner';
        const slots = CommonBadge.getRenderSlots(session.maxUserCount, this.sessionInfo.getPlayers(true, true).length);

        let invitation = '';
        let invitationTitle = '';
        if (this.props.time.isInvited) {
            let inviterName = this.props.time.userInviter ? this.props.time.userInviter.displayName : ownerName;
            invitation = 'from ' + inviterName;
            invitationTitle = 'Invitation ' + invitation;
        }
        return (
            <>
                <div className="header-cell">
                        <div className="lesson-name-difficult">
                            <div className="lesson-name" title={invitationTitle}>{CommonBadge.getBadgeTitle(session, this.props.user, this.props.time.isOwner)}</div>
                            {CommonBadge.getRenderVideoIcon(session)}
                            {CommonBadge.renderSkillMarkup(skill)}
                        </div>
                    </div>
                    <div className="header-cell-full">
                        <div className="lesson-time-difficult">
                            <div className="lesson-time-wrapper">
                                <span className="lesson-time">{CommonBadge.getTimeString(session)}</span>
                                <span className="lesson-time-zone">{session.endDateTime.format('a')}</span>
                            </div>
                            {CommonBadge.renderSkillMarkup(skill)}
                        </div>
                        <div className="coach-name">
                            {session.trainer ? session.trainer.displayName : 'no coach'}
                        </div>
                        <div className="coach-tooltip">
                            <span className="coach-tooltip-title">{session.trainer ? session.trainer.displayName : 'no coach'}</span>
                        </div>
                        <div className="lesson-summary">
                            <div className="lesson-name-content">
                                <div className="lesson-name" title={invitationTitle}>
                                    <span className="title">{invitationTitle}</span>
                                    {CommonBadge.getRenderVideoIcon(session)}
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="body-cell">
                        {slots}
                        {CommonBadge.renderPrice(session, this.props.time, this.props.user)}
                    </div>
            </>
        );
    }

    private init(props: IClinicBadgeBadgeProps) {
        this.sessionInfo = new PolM.SessionInfoPolicy(props.time.session);
        this.sessionAvailability = new PolM.SessionAvailabilityPolicy(props.time.session, [], props.user);
    }

    private onBadgeClick(e: any, session: StM.SessionStoreState) {
        if (e) { e.preventDefault(); e.stopPropagation(); }
        let id = session.id || session.basketId;
        let from = session.id ? StM.SessionInfoFromTypes.calendar : StM.SessionInfoFromTypes.basket;
        this.props.onBadgeClick(from, id);
    }
};
