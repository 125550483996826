import './bookPage.scss';

import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as _ from 'lodash';
import ClassNames from 'classnames';

import { ActM, StM, SrvM } from '../../../modules';
import CalendarCourtBoard from '../../../components/pages/book/calendarCourtBoard';
import CourtSelector from '../../../components/pages/book/courtSelector';
import FilterBoard from '../../../components/pages/book/filterBoard/filterBoard';
import HoverLine from '../../../components/pages/book/hoverLine';
import TimeLine from '../../../components/pages/book/timeLine';
import ClinicListView from '../../../components/pages/clinics/clinicListView';
import TimeBoard from '../../../components/pages/book/timeBoard';
import { CommonBookPage } from './commonBookPage';
import { DateSelector } from '../../../components/pages/book';

export interface IBookPageProps {
    params: StM.IBookPageRouteParams;
    match: StM.IBookPageRouteParamsMatch;
    currentMatch: any;
    location: string;
    isAuthorized: boolean;
    user: StM.IUserStoreState;
    club: StM.IClubStoreState;
    customSessions: StM.ICustomSessionTypeStoreState[];
    basketSessions: Array<StM.ISessionStoreState>;
    basketPackages: Array<StM.IPackageDefinitionStoreState>;
    sessions: Array<StM.ISessionStoreState>;
    timeSlots: Array<StM.ICourtTimeSlotStoreState>;
    coaches: Array<StM.ICoachStoreState>;
    coachFeeTiers: Array<StM.ICoachFeeTierStoreState>;
    availableTimesLookup: IDictionary;
    isFinalized: boolean;
    hoverLine: StM.IHoverLineStoreState;
    inviteToken: string;

    fillCourtTimeSlots: (date: moment.Moment) => void;
    init: (currentDate: moment.Moment, isNeedSpinner?: boolean) => Promise<any>;
    getSessions: (start: moment.Moment, end: moment.Moment) => Promise<any>;
    getAvailableTimes: (start: moment.Moment, end: moment.Moment) => Promise<any>;
    showSpinner: () => any;
    hideSpinner: () => any;
    go: (url: string) => any;
    onCreateSessionClick: (sessionType: string, subFilter?: any, subFilterId?: any, sessionId?: number) => any;
    updateMatch: (match: any) => Promise<any>;
    updateCurrentPageSessions: (params: any, clearCache?: boolean) => Promise<StM.ISessionStoreState>;
    openSessionDialog: (from: string, id: number) => Promise<any>;
    timeLineBadgeHover: () => void;
    timeLineBadgeHoverOut: () => void;
    initFbLink: (inviteToken: string) => Promise<void>;
}

export class BookPage extends React.Component<IBookPageProps> {
    private logger = new SrvM.LogService();
    private routeSrv = new SrvM.RouteService();

    constructor(props: IBookPageProps) {
        super(props);
    }

    public UNSAFE_componentWillMount() {
        if(this.props.inviteToken && this.routeSrv.isActivePage(StM.Pages.FBLink)) {
            this.props.initFbLink(this.props.inviteToken);
        }
        CommonBookPage.initPrevParams(this.props.match.params);
        if (this.props.club.clubTimes.length) this.updateDateData(this.props.match.params);
    }

    public UNSAFE_componentWillReceiveProps(newProps: IBookPageProps) {
        const isLocationChanged = this.props.location !== newProps.location;
        const isMatchChanged = !_.isEqual(this.props.currentMatch.params, newProps.match.params);
        const isFinalizedChanged = this.props.isFinalized != newProps.isFinalized;

        if (isLocationChanged || isMatchChanged || !this.props.currentMatch.params) {
            this.props.updateMatch({ ... this.props.match, params: newProps.match.params || {} });
        }
        if (isFinalizedChanged && !!newProps.club.clubTimes.length) {
            this.props.init(this.getCurrentDate()).then(() => {
                CommonBookPage.initPrevParams(this.props.match.params);
            });
        }
    }

    public shouldComponentUpdate(nextProps: IBookPageProps): boolean {
        if (nextProps.club.id === 0) { return false; }

        const isDateChanged = this.props.match.params.date != nextProps.match.params.date;
        const isClubChanged = !_.isEqual(this.props.club, nextProps.club);

        if ((isDateChanged || isClubChanged) && this.props.club.clubTimes.length) {
            this.updateDateData(nextProps.match.params);
        }
        else {
            const isAuthorizedChanged = this.props.isAuthorized != nextProps.isAuthorized;
            const isHoverLineChanged = this.props.hoverLine.key != nextProps.hoverLine.key;
            const isClinicsViewChangeRequiresDataLoad = !_.isEqual(this.props.match.params.clinicSubfilterId, nextProps.match.params.clinicSubfilterId) && !this.props.timeSlots.length;

            const isTypeChanged = this.props.match.params.sessionType != nextProps.match.params.sessionType;

            const isFilterChanged = !_.isEqual(this.props.match.params, nextProps.match.params);

            let isBasketSessionsChanged = false;
            let isBasketPackagesChanged = false;
            let isSessionsChanged = false;
            let isTimeSlotsChanged = false;
            let isUserBalancesChange = false;


            if (!isFilterChanged) {
                isBasketPackagesChanged = this.props.basketPackages.length != nextProps.basketPackages.length
                    || !_.isEqual(this.props.basketPackages, nextProps.basketPackages);

                isUserBalancesChange = nextProps.isAuthorized && (this.props.basketSessions.length != nextProps.basketSessions.length
                    || !_.isEqual(this.props.user.creditsWallet, nextProps.user.creditsWallet));

                isBasketSessionsChanged = this.props.basketSessions.length != nextProps.basketSessions.length
                    || !_.isEqual(this.props.basketSessions, nextProps.basketSessions);

                if (!isBasketSessionsChanged || !isBasketPackagesChanged || !isUserBalancesChange) isSessionsChanged =
                    this.props.sessions.length != nextProps.sessions.length
                    || !_.isEqual(this.props.sessions, nextProps.sessions);

                if (!isTypeChanged
                    && !isAuthorizedChanged && !isFilterChanged
                    && !isBasketSessionsChanged && !isBasketPackagesChanged && !isSessionsChanged)
                    isTimeSlotsChanged = this.props.timeSlots.length != nextProps.timeSlots.length
                        || !_.isEqual(this.props.timeSlots, nextProps.timeSlots);
            }
            if (isTypeChanged || isTimeSlotsChanged
                || isBasketSessionsChanged || isBasketPackagesChanged || isSessionsChanged || isAuthorizedChanged || isFilterChanged || isClinicsViewChangeRequiresDataLoad || isHoverLineChanged || isUserBalancesChange) {

                if (nextProps.isFinalized && isAuthorizedChanged) {
                    this.props.showSpinner();
                    this.props.updateCurrentPageSessions(nextProps.match.params, isAuthorizedChanged).then(() => {
                        this.props.fillCourtTimeSlots(this.getCurrentDate(nextProps.match.params.date));
                        this.props.hideSpinner();
                    }).catch(() => {
                        this.props.hideSpinner();
                    });
                } else if ((isTypeChanged || isFilterChanged || isBasketSessionsChanged || isBasketPackagesChanged || isSessionsChanged || isFilterChanged) && this.props.club.clubTimes.length || isUserBalancesChange) {
                    this.props.showSpinner();
                    this.props.fillCourtTimeSlots(this.getCurrentDate(nextProps.match.params.date));
                    this.props.hideSpinner();
                }
                return true;
            }
        }

        return false;
    }

    public render() {
        let content = this.isClinicListView() ? this.renderClinics() : this.renderCalendar();

        return (
            <div className="main-content-wrapper">
                <div className="leftblock-table-wrapper">
                    <FilterBoard routeParams={this.props.match.params}
                        coaches={this.props.coaches}
                        user={this.props.user}
                        club={this.props.club}
                        customSessions={this.props.customSessions}
                        coachFeeTiers={this.props.coachFeeTiers}
                        collapseCalendar={this.isClinicListView() || this.isLeagueListView()}
                        availableTimesLookup={this.props.availableTimesLookup}
                        onChangeFilterParamHandler={(params: any) => CommonBookPage.changeFilter(params)}
                    />
                    {content}
                </div>
            </div>
        );
    }

    private renderCalendar() {
        const date = this.getCurrentDate(this.props.match.params.date);
        const classes = ClassNames("table-wrapper", {
            'loading': !this.props.club.clubTimes.length
        });

        return (
            <div className="table-block-wrapper">
                <DateSelector club={this.props.club} params={this.props.match.params} date={date}
                    onDateChangedHandler={(params) => CommonBookPage.changeFilter(params, this.props.coaches)} />
                <div className={classes}>
                    {!!this.props.club.clubTimes.length &&
                        (<div className='display-flex'>
                            <TimeBoard date={this.getCurrentDate()}/>
                            <div className='table-overflow-scroll'>
                                <div className="table-main-wrapper">
                                    <div className="table-content-wrapper">
                                        <HoverLine isShown={this.props.hoverLine.isShown}
                                            courts={this.props.club.courts}
                                            timeBlock={this.props.hoverLine.item}
                                            hideHandler={this.props.timeLineBadgeHoverOut}
                                            right={this.props.hoverLine.right}
                                            sessionType={this.props.match.params.sessionType}
                                            top={this.props.hoverLine.top} />
                                        <CourtSelector />
                                        <TimeLine club={this.props.club} pageDate={this.getCurrentDate()} />
                                        <CalendarCourtBoard club={this.props.club}
                                            date={this.getCurrentDate()}
                                            user={this.props.user}
                                            filter={this.props.match.params}
                                            timeSlots={this.props.timeSlots}
                                            onBageMouseEnter={this.props.timeLineBadgeHover}
                                            onSessionClick={(from: string, id: number) => this.onSessionClick(from, id)}
                                            onCreateSessionClick={this.createSession()}
                                            onNonFilterSessionClick={(sessionId: number) => this.onNonFilterSessionClick(sessionId)} />                                 
                                    </div>
                                </div>
                            </div>
                        </div>)}
                </div>
            </div>
        );
    }

    private renderClinics() {
        return (
            <ClinicListView onFilterChange={(params: any) => CommonBookPage.changeFilter(params)} routeParams={this.props.match.params}></ClinicListView>
        );
    }

    private updateDateData(params: StM.IBookPageRouteParams) {
        const start = this.getCurrentDate(params.date).startOf('day');
        const end = start.clone().add(1, 'day');
        this.props.showSpinner();

        let sessionProm = this.props.getSessions(start, end);
        let availableTimeProm = this.props.getAvailableTimes(start, end);

        Promise.all([sessionProm, availableTimeProm])
            .then(() => {
                this.props.fillCourtTimeSlots(this.getCurrentDate(params.date));
                this.props.hideSpinner();
            }).catch((error) => {
                this.logger.error(error);
                this.props.hideSpinner();
            });
    }

    private isClinicListView(): boolean {
        return this.routeSrv.isActivePage(StM.Pages.Book, StM.BookPageSessionType.Clinic) && _.includes(window.location.pathname, 'list');
    }

    private isLeagueListView(): boolean {
        return this.routeSrv.isActivePage(StM.Pages.Book, StM.BookPageSessionType.League) && _.includes(window.location.pathname, 'list');
    }

    private getCurrentDate(srcDate?: string): moment.Moment {
        const date = srcDate || this.props.match.params.date;
        return this.routeSrv.getRouteDate(date, this.props.club);
    }

    private createSession() {
        let sessionType = this.props.match.params.sessionType;
        let subFilter: string = StM.BookPageFilterTypeDefault.SubFilter;
        let subFilterId: string = StM.BookPageFilterTypeDefault.SubFilterId;
        if (sessionType !== StM.BookPageSessionType.Clinic) {
            if (sessionType == StM.BookPageSessionType.Lesson) {
                subFilter = this.props.match.params.lessonSubfilter;
                subFilterId = this.props.match.params.lessonSubfilterId;
            }
            return this.props.onCreateSessionClick(sessionType, subFilter, subFilterId);
        }
        return () => { };
    }

    private onSessionClick(from: string, id: number) {
        this.props.openSessionDialog(from, id);
    }

    private onNonFilterSessionClick(sessionId: number) {
        this.props.openSessionDialog(StM.SessionInfoFromTypes.calendar, sessionId);
    }
}

const mapStateToProps = (state: StM.IGlobalStoreState, ownProps: any) => {
    let sessionType: string = ownProps.location.pathname.split('/')[2];
    sessionType = sessionType ? sessionType.toLowerCase() : sessionType;
    if (ownProps && ownProps.match && ownProps.match.params) {
        ownProps.match.params['sessionType'] = sessionType;
    }
    return {
        location: ownProps.location.pathname,
        isAuthorized: state.app.isAuthorized,
        user: state.user,
        club: state.club,
        customSessions: state.customSessions,
        currentMatch: state.app.match,
        basketSessions: state.basket.goods,
        basketPackages: state.basket.packages,
        sessions: state.pages.book.sessions,
        timeSlots: state.pages.book.timeSlots,
        coaches: state.coaches,
        coachFeeTiers: state.coachFeeTiers,
        hoverLine: state.hoverLine,
        availableTimesLookup: state.pages.book.availableTimesLookup,
        isFinalized: state.app.isFinalized,
        inviteToken: ownProps.match.params.inviteToken
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        fillCourtTimeSlots: (date: moment.Moment, refresh: boolean) =>
            dispatch(ActM.BookPageActions.fillCourtTimeSlots(date, refresh)),
        init: (currentDate: moment.Moment, isNeedSpinner?: boolean) => dispatch(ActM.BookPageActions.init(currentDate, isNeedSpinner)),
        getSessions: (start: moment.Moment, end: moment.Moment) => dispatch(ActM.BookPageActions.getSessions(start, end)),
        getAvailableTimes: (start: moment.Moment, end: moment.Moment) => dispatch(ActM.BookPageActions.getAvailableTimes(start, end)),
        showSpinner: () => dispatch(ActM.AppActions.showSpinner()),
        updateMatch: (match: any) => dispatch(ActM.AppActions.updateRouteMatch(match)),
        updateCurrentPageSessions: (params: any, clearCache?: boolean) => dispatch(ActM.SessionActions.updateForCurrentPage(params, clearCache)),
        hideSpinner: () => dispatch(ActM.AppActions.hideSpinner()),
        go: (url: string) => dispatch(ActM.RouteActions.push(url)),
        onCreateSessionClick: (sessionType: string, subFilter?: string, subFilterId?: string, sessionId?: number) => {
            return (courtId: number, timeKey: string) => dispatch(ActM.DialogActions.open(StM.DialogNames.NewSession, {
                courtId: courtId,
                timeKey: timeKey,
                sessionType: sessionType,
                sessionSubFilter: subFilter,
                sessionSubFilterId: subFilterId,
                sessionId: sessionId
            }))
        },
        openSessionDialog: (from: string, id: number) => dispatch(ActM.SessionActions.openSessionDialog(from, id)),
        timeLineBadgeHover: (time: StM.CourtTimeBlockStoreState, top: number, right: number) => dispatch(ActM.hoverLineActions.show(time, top, right)),
        timeLineBadgeHoverOut: () => dispatch(ActM.hoverLineActions.hide()),
        initFbLink: (inviteToken: string) => dispatch(ActM.FBLinkPageActions.init(inviteToken))
    }
}

const connectedBookPage = connect(mapStateToProps, mapDispatchToProps)(BookPage);
const BookPageController = withRouter(connectedBookPage);
export default BookPageController;