import './header.scss';
import * as React from 'react';
import { connect } from 'react-redux';
import ClassNames from 'classnames';
import * as _ from 'lodash';
import { NavLink } from 'react-router-dom';

import { StM, ActM, SrvM } from '../modules';
import HeaderBasket from '../components/basket/headerBasket';
import UserMenu from '../components/userMenu';
import Notifications from './notifications';


interface IHeaderProps {
    match: any;
    location: any;
    history: any;
    isAuthorized: boolean;
    openAuthDialog: () => Promise<any>;
    signOut: () => Promise<any>;
    basket: any;
    toggleBasket: () => void;
    toggleNotification: () => void;
    go: (url: string) => any;
    user: StM.IUserStoreState;
    club: StM.IClubStoreState;
    notifications: Array<StM.INotificationStoreState>;
    notificationIsOpen: boolean;
    setHeight: () => void;
    packages: StM.IPackagesStoreState;
}

interface IHeaderState {
}

class Header extends React.Component<IHeaderProps, IHeaderState> {
    private authSrv = new SrvM.AuthenticationService();
    private routeSrv: SrvM.RouteService = new SrvM.RouteService();

    constructor(props: IHeaderProps) {
        super(props);
    }

    private onSignInClick(e: any) {
        if (e) { e.stopPropagation(); }
        this.props.openAuthDialog();
    }

    private onSignOutClick(e: any) {
        if (e) { e.stopPropagation(); }
        this.props.signOut();
    }

    private onBookCkick(e: any) {
        const bookTodayRoute = this.routeSrv.getBookTodayRoute();
        this.props.go(bookTodayRoute);
    }

    private toggleNotificationClick(e: any) {
        //if(e) {e.stopPropagation();}
        this.props.toggleNotification();
    }

    setMenuHeight(elem: any) {
        if (!elem) return false;
        this.props.setHeight();
    }

    render() {
        const isClubDashboard = this.routeSrv.isActivePage(StM.Pages.ClubDashboard);
        const isScoreboardPage = this.routeSrv.isActivePage(StM.Pages.Scoreboard);
        const isVideoPage = this.routeSrv.isActivePage(StM.Pages.Video);
        if (isClubDashboard || isScoreboardPage || isVideoPage) { return null; }

        const signInOut = this.getSignInOut();
        const bookNavLinkClasses = ClassNames({ active: this.routeSrv.isActivePage(StM.Pages.Book) });
        const coachesNavLinkClasses = ClassNames({ active: this.routeSrv.isActivePage(StM.Pages.Coaches) });
        const pricingNavLinkClasses = ClassNames({ active: this.routeSrv.isActivePage(StM.Pages.Pricing) });
        const openSessionsBoardNavLinkClasses = ClassNames({ active: this.routeSrv.isActivePage(StM.Pages.OpenSessionsBoard) });
        const loginWrapperClasses = ClassNames({ loged: this.props.isAuthorized }, 'login-wrapper');
        const mySessionsClasses = ClassNames({ active: this.routeSrv.isActivePage(StM.Pages.MySessions) && this.props.isAuthorized, inactive: !this.props.isAuthorized });
        const noPackages = !this.props.packages.definitions || !this.props.packages.definitions.length;
        const packagesNavLinkClasses = ClassNames({ active: this.routeSrv.isActivePage(StM.Pages.Packages) });

        const mySessionsPath = this.routeSrv.getMySessionsDefaultRoute(this.props.club);
        const ownerPrefix = this.authSrv.getOwnerPrefix(this.props.user);

        const basketNotifClasses = ClassNames('basket-notif', {
            'show-basket': this.props.basket.isShown,
            'show-notif': this.props.notificationIsOpen
        })
        return (
            <div className="header">
                <div className="header-content">
                    <div className="left-menu">
                        <div className="logo-wrapper">
                            <span className="logo-header" onClick={(e) => this.onBookCkick(e)}></span>
                        </div>
                        <ul className="list-inline">
                            <li className={bookNavLinkClasses} onClick={(e) => this.onBookCkick(e)}><NavLink to={''}> Book </NavLink></li>
                            <li className={coachesNavLinkClasses}>
                                <NavLink to={'{0}'.format(StM.Pages.Coaches)}> Coaches </NavLink>
                            </li>
                            {!!this.props.club.priceList && (<li className={pricingNavLinkClasses}>
                                <NavLink to={StM.Pages.Pricing}> Pricing </NavLink>
                            </li>)}
                            <li className={openSessionsBoardNavLinkClasses}>
                                <NavLink to={'{0}'.format(StM.Pages.OpenSessionsBoard)}> Public Sessions </NavLink>
                            </li>
                            {!noPackages && (
                                <li className={packagesNavLinkClasses}>
                                    <NavLink to={'{0}'.format(StM.Pages.Packages)}> Packages </NavLink>
                                </li>
                            )}
                            <li className={mySessionsClasses}>
                                {this.props.isAuthorized ?
                                    (<NavLink to={mySessionsPath}> {ownerPrefix} Sessions </NavLink>) :
                                    (<span>{ownerPrefix} Sessions</span>)
                                }
                            </li>
                        </ul>
                    </div>
                    <div className="right-menu">
                        <div className="right-menu-content">
                            <div className={loginWrapperClasses}>
                                {signInOut}
                            </div>
                            <div className="basket-notif-wrapper">
                                <div className={basketNotifClasses} ref={(elem) => this.setMenuHeight(elem)}>
                                    {this.renderNotification()}
                                    <Notifications />
                                    <HeaderBasket user={this.props.user} basket={this.props.basket} toggle={this.props.toggleBasket} club={this.props.club} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    };
    getSignInOut() {
        return this.props.isAuthorized
            ? (
                <div className="logged-content">
                    <UserMenu user={this.props.user} signOut={this.props.signOut} club={this.props.club} />
                </div>
            ) : (<a className="sign-in" onClick={(e) => { this.onSignInClick(e) }}>Sign In</a>);
    }
    renderNotification() {
        const count = _.filter(this.props.notifications, (noty) => {
            return noty.targetType == StM.NotificationTargetType.User || noty.targetType == StM.NotificationTargetType.Broadcast
                || (noty.targetType == StM.NotificationTargetType.Session && noty.event == StM.NotificationEventType.SessionYouInvited)
                || (noty.targetType == StM.NotificationTargetType.Group && noty.event == StM.NotificationEventType.GroupYouInvited)
        }).length;
        return this.props.isAuthorized
            ? (
                <div className="bell-wrapper" onClick={(e) => this.toggleNotificationClick(e)}>
                    <div className="bell">
                        <img src="/content/img/notifications.svg" className="img-bell" />
                        <div className="notification-count">{count}</div>
                    </div>
                </div>
            ) : null;
    }
};

const mapStateToProps = (state: StM.IGlobalStoreState, ownProps: any) => {
    return {
        isAuthorized: state.app.isAuthorized
        , basket: state.basket
        , user: state.user
        , club: state.club
        , notifications: state.notificationBoard.notifications
        , notificationIsOpen: state.notificationBoard.isShown
        , packages: state.packages
        , match: ownProps.match
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        openAuthDialog: () => dispatch(ActM.DialogActions.open(StM.DialogNames.Auth, { tab: StM.AuthDialogTabs.SignIn, returnUrl: '' }))
        , toggleBasket: () => dispatch(ActM.BasketActions.showToggle())
        , signOut: () => dispatch(ActM.AccountActions.logout())
        , go: (url: string) => dispatch(ActM.RouteActions.push(url))
        , toggleNotification: () => dispatch(ActM.NotificationActions.toggle())
        , setHeight: () => dispatch(ActM.BaseActions.setContentHeight())
    };

}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
