import * as React from 'react';
import { compose, Dispatch } from 'redux';
import { connect } from 'react-redux';

import * as StM from '../../../../models/store';
import * as ActM from '../../../../actions';
import * as SrvM from '../../../../services';
import { Images } from '../../../../constants';
import ImageUploader from '../../../../components/imageUploader';

export const DATE_FORMAT = 'D MMMM YYYY';

export const getEmergencyContactInfo = (user: StM.IUserStoreState) => {
    if (!user) return null;
    let result = user.emergencyContactPhoneNumber || StM.Strings.NoInfo;
    if (user.emergencyContactPhoneNumber && user.emergencyContactFullName) {
        result += ` (${user.emergencyContactFullName})`;
    }
    return result;
}

export interface IProfilePageProps extends IBaseProfilePageState {
    user: StM.IUserStoreState;
    isGroupAccount: boolean;

    onEditClick: (e: any) => void;
    onBillingEditClick: (e: any) => void;
    onPasswordRecoveryClick: (e: any) => void;
    renderAvatar: () => JSX.Element;
}

export interface IBaseProfilePageProps {
    isAuth: boolean;
    isFinalized: boolean;
    user: StM.IUserStoreState;

    init: () => void;
    openEditDialog: () => void;
    openBillingInfo: () => void;
    openPasswordChange: () => void;
    openAuthDialog: () => void;
    hideSpinner: () => any;
    uploadProfileImage: (file: any) => Promise<any>;
    openEditGroupAccountDialog: () => void;
}

export interface IBaseProfilePageState {
    imagePreview: string;
    validationResult: any;
}

const withBaseProfilePage = (Component: React.ComponentType<IProfilePageProps>) => {
    return class BaseProfilePage extends React.Component<IBaseProfilePageProps, IBaseProfilePageState> {
        private authSrv = new SrvM.AuthenticationService();
        constructor(props: IBaseProfilePageProps) {
            super(props);
            this.state = {
                imagePreview: null,
                validationResult: null
            };
        }

        componentDidMount() {
            this.props.init();
        }

        componentDidUpdate(prevProps: IBaseProfilePageProps) {
            if (!prevProps.isAuth && this.props.isAuth) {
                this.props.init();
            }
        }

        render() {
            if (!this.props.user.id || !this.props.isFinalized) {
                return (
                    <div className="main-content-wrapper profile-pages">
                        <div className="page-title">My Profile</div>
                        <p>{StM.Strings.UnathorizedAccessTitle}</p>
                    </div>
                );
            }
            return (
                <Component
                    {...this.state}
                    user={this.props.user}
                    isGroupAccount={this.authSrv.isInRole(StM.Roles.GroupAccount, this.props.user)}
                    onEditClick={(e) => this.onEditClick(e)}
                    onBillingEditClick={(e) => this.onBillingEditClick(e)}
                    onPasswordRecoveryClick={(e) => this.onPasswordRecoveryClick(e)}
                    renderAvatar={() => this.renderAvatar()}
                />
            );
        }

        private renderAvatar() {
            const utils = new SrvM.Utils();
            let imageUrl = this.props.user.imageId ? utils.getImageUrl(this.props.user.imageId) : Images.NoPhoto2;
            if (this.state.imagePreview) { imageUrl = this.state.imagePreview; }
        
            return (
                <ImageUploader url={imageUrl} onDrop={(acceptedFiles, rejectedFiles) => this.onDrop(acceptedFiles, rejectedFiles)} />
            );
        }

        private onDrop(acceptedFiles: any, rejectedFiles: any) {
            if (rejectedFiles.length) {
                this.setState({
                    imagePreview: null,
                    validationResult: { error: 'Please choose another file and try again' }
                });
            } else if (acceptedFiles.length) {
                this.props
                    .uploadProfileImage(acceptedFiles[0])
                    .then(() => {
                        this.setState({
                            imagePreview: URL.createObjectURL(acceptedFiles[0]),
                            validationResult: { success: 'Your profile image was updated' },
                        });
                    })
                    .catch((error) => {
                        this.setState({
                            imagePreview: null,
                            validationResult: { error: error.message || 'Error updating profile image. Please try again' },
                        });
                    });
            }
        }

        private onEditClick(e: any) {
            if (e) { e.stopPropagation(); e.preventDefault() };
            if(this.authSrv.isInRole(StM.Roles.GroupAccount, this.props.user)) {
                this.props.openEditGroupAccountDialog();
            } else {
                this.props.openEditDialog();
            }
        }
        private onBillingEditClick(e: any) {
            if (e) { e.stopPropagation(); e.preventDefault() };
            this.props.openBillingInfo();
        }

        private onPasswordRecoveryClick(e: any) {
            if (e) { e.stopPropagation(); e.preventDefault() };
            this.props.openPasswordChange();
        }
    };
};

const mapStateToProps = (state: StM.IGlobalStoreState) => {
    return {
        user: state.user,
        isFinalized: state.app.isFinalized,
        isAuth: state.app.isAuthorized
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        init: () => dispatch(ActM.ProfilePageActions.init()),
        openEditDialog: () => dispatch(ActM.DialogActions.open(StM.DialogNames.UserEdit)),
        openBillingInfo: () => dispatch(ActM.DialogActions.open(StM.DialogNames.BillingInfo)),
        openPasswordChange: () => dispatch(ActM.DialogActions.open(StM.DialogNames.PasswordChange, { hash: null, email: null })),
        openAuthDialog: () => dispatch(ActM.DialogActions.open(StM.DialogNames.Auth, { tab: StM.AuthDialogTabs.SignIn })),
        hideSpinner: () => dispatch(ActM.AppActions.hideSpinner()),
        uploadProfileImage: (file: any) => dispatch(ActM.ProfilePageActions.uploadProfileImage(file)),
        openEditGroupAccountDialog: () => dispatch(ActM.DialogActions.open(StM.DialogNames.EditGroupAccount)),
    };
};

export const withProfilePage = compose(
    connect(mapStateToProps, mapDispatchToProps)
    , withBaseProfilePage);
