import '../dialogs.mobile.scss';
import '../legalTextsDialog.mobile.scss';
import '../../legalTexts.scss';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import * as _ from 'lodash';

import { ModalMobile } from '../../../components/modal.mobile';
import * as StM from '../../../models/store';
import * as ActM from '../../../actions';

export interface TermsAndConditionsDialogProps {
    isShow: boolean;
    isShowAuthDialog: boolean;
    isShowAuthDialogSignIn: boolean;    
    hideConfirmation: boolean;

    closeDialog: () => void;
    openAuthDialog: () => Promise<any>;
    accept: () => Promise<any>;
    decline: () => void;
    logout: () => Promise<any>;
    showSpinner: () => any;
    hideSpinner: () => any;
    getStatic: () => Promise<any>;
}

export interface TermsAndConditionsDialogState {
    isNeedAccept: boolean;
    title: string;
    content: string;
}

class TermsAndConditionsDialogMobile extends React.Component<TermsAndConditionsDialogProps, TermsAndConditionsDialogState> {
    constructor(props: TermsAndConditionsDialogProps) {
        super(props);

        this.state = {
            isNeedAccept: false,
            title: 'Terms and Conditions',
            content: 'Please wait...',
        };
    }

    componentDidUpdate(prevProps: TermsAndConditionsDialogProps) {
        const { isShow, isShowAuthDialog, isShowAuthDialogSignIn, hideConfirmation } = this.props;

        const isShowChanged = !prevProps.isShow && isShow;
        const isNeedAccept = isShowAuthDialog || (isShowAuthDialogSignIn && !hideConfirmation);

        if (isNeedAccept !== this.state.isNeedAccept) {
            this.setState({ isNeedAccept });
        }

        if (isShowChanged) {
            this.init(this.props);
        }
    }

    render() {
        if (!this.props.isShow) return null;
        return (
            <ModalMobile classes="waiver-modal" dialogName={StM.DialogNames.TermsAndConditions} customZIndex={9999}>
                <div className="modal-dialog-header">
                    <div className="title">{this.state.title}</div>
                </div>
                <div className="modal-dialog-body container">
                    <article className="waiver-text-wrapper" dangerouslySetInnerHTML={this.state && { __html: this.state.content }}></article>
                    <div className="divider-line"></div>
                    <div className="btns-wrapper">
                        {this.state.isNeedAccept ? (
                            <>
                                <div className="mobile-col-6 btn-left-mobile">
                                    <button className="btn-mobile btn-decline btn-black" onClick={(e) => this.onDeclineClick(e)}>CANCEL</button>
                                </div>
                                <div className="mobile-col-6 btn-right-mobile">
                                    <button className="btn-mobile btn-red" onClick={(e) => this.onAcceptClick(e)}>ACCEPT</button>
                                </div>
                            </>
                        ) : (
                            <div className="mobile-col-6 btn-left-mobile">
                                <button className="btn-mobile btn-black" onClick={e => this.onCloseClick(e)}>CLOSE</button>
                            </div>
                        )}
                    </div>
                </div>
            </ModalMobile>
        );
    }

    private init(props: TermsAndConditionsDialogProps) {
        props.getStatic().then((staticPages: Array<IStaticPageDto>) => {
            const page = _.find(staticPages, { name: StM.LegalTexts.Terms });
            const content = page ? page.content : "Page is currently unavailable";
            const title = page ? page.title : "Not Found";
            this.setState({ content, title });
        });
        props.decline();
    }

    private onCloseClick(e: any) {
        if (e) { e.preventDefault(); e.stopPropagation(); }
        this.props.closeDialog();
        this.logoutIfNotAcceptedTermsAndConditions();
    }

    private onDeclineClick(e: any) {
        if (e) { e.preventDefault(); e.stopPropagation(); }
        this.props.closeDialog();
        this.logoutIfNotAcceptedTermsAndConditions();
    }

    private onAcceptClick(e: any) {
        if (e) { e.preventDefault(); e.stopPropagation(); }
        this.props.showSpinner();
        this.props.accept()
            .then(() => {
                this.props.closeDialog();
                this.props.hideSpinner();
            }).catch(() => {
                this.props.hideSpinner();
            });
    }

    private logoutIfNotAcceptedTermsAndConditions() {
        if (this.props.isShowAuthDialog && this.props.isShowAuthDialogSignIn) {
            this.props.logout();
        }
    }
}

const mapStateToProps = (state: StM.IGlobalStoreState, ownProps: any) => {
    return {
        isShow: state.dialogs.termsAndConditions.isOpened
        , isShowAuthDialog: state.dialogs.auth.isOpened
        , isShowAuthDialogSignIn: state.dialogs.auth.tab === StM.AuthDialogTabs.SignIn
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        closeDialog: () => dispatch(ActM.DialogActions.close(StM.DialogNames.TermsAndConditions))
        , openAuthDialog: () => dispatch(ActM.DialogActions.open(StM.DialogNames.Auth, { tab: StM.AuthDialogTabs.SignIn }))
        , accept: () => dispatch(ActM.TermsAndConditionsDialogActions.accept())
        , decline: () => dispatch(ActM.TermsAndConditionsDialogActions.decline())
        , logout: () => dispatch(ActM.AccountActions.logout())
        , showSpinner: () => dispatch(ActM.AppActions.showSpinner())
        , hideSpinner: () => dispatch(ActM.AppActions.hideSpinner())
        , getStatic: () => dispatch(ActM.AppActions.getStatic())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TermsAndConditionsDialogMobile);
