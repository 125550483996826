import './inviteGroupMemberDialog.scss';

import * as React from 'react';
import ClassNames from 'classnames';

import { Modal, ModalHeader, Field, ModalBody, ModalFooter, ModalSection } from '../../../../components';
import * as StM from '../../../../models/store';
import * as BD from './baseInviteGroupMemberDialog';
import { InviteGroupMembersList } from '../../../../components/pages/user/groups';

interface IInviteGroupMemberDialogProps extends BD.IInviteGroupMemberDialogProps {}

class InviteGroupMemberDialog extends React.Component<IInviteGroupMemberDialogProps> {
    constructor(props: IInviteGroupMemberDialogProps) {
        super(props);
    }

    public render() {
        if(!this.props.isOpened || !this.props.group || !this.props.group.id) return null;
        const searchModel = this.props.searchModel;
        const errors = this.props.errors;
        const emailClasses = ClassNames('email-input', { hidden: !this.props.isEmailNeeded })

        return (
            <Modal closeHandler={(e) => this.props.onCloseDialog(e)} dialogName={StM.DialogNames.InviteGroupMember}>
                <div className="modal-dialog dialog">
                    <ModalHeader title="Add a Member" onCloseDialog={(e) => this.props.onCloseDialog(e)} />
                    <ModalBody>
                        <ModalSection>
                            <label>Invite Players</label>
                            <div className='inputs-wrapper-inline search-box'>
                                <Field name='userName'
                                    type='text'
                                    maxLength={128}
                                    value={searchModel.userName}
                                    error={errors && errors.userName}
                                    autoComplete='off'
                                    placeholder={this.props.isEmailNeeded ? 'Enter Player Name' : 'Enter Player Name or Email'}
                                    onFieldChange={(name, value) => this.props.onFieldChange(name, value)}
                                    onKeyDown={(e) => this.props.onFieldKeyDown(e)}
                                />
                                <Field name='email'
                                    type='text'
                                    className={emailClasses}
                                    maxLength={128}
                                    value={searchModel.email}
                                    error={errors && errors.email}
                                    autoComplete='off'
                                    placeholder='Enter Email'
                                    onFieldChange={(name, value) => this.props.onFieldChange(name, value)}
                                    onKeyDown={(e) => this.props.onFieldKeyDown(e)}
                                />
                                <button className='btn btn-black look-up' onClick={(e) => this.props.onAddUser(e)}>Look up</button>
                            </div>
                            {this.props.searchError && <span className='search-error'>{this.props.searchError}</span>}
                        </ModalSection>
                        <ModalSection>
                            <InviteGroupMembersList list={this.props.addedUsers} onRemoveItem={(item) => this.props.onRemoveUser(item) } />
                        </ModalSection>
                    </ModalBody>
                    <ModalFooter className='btns-wrapper' errors={errors} errorMessage={this.props.errors && this.props.errors.addedUsers}>
                        <button className="btn btn-red" onClick={(e) => this.props.onSendInvite(e)} title='Send Invite'>Send Invite</button>
                    </ModalFooter>
                </div>
            </Modal>
        );
    }
}

export default BD.withInviteGroupMemberDialog(InviteGroupMemberDialog);