import './courtSelector.scss';
import * as React from 'react';
import ClassNames from 'classnames';
import * as StM from '../../../models/store';
import * as _ from 'lodash';


interface ICourtSelectorProps {
    courts: Array<StM.ICourtStoreState>;
    youtubeIds: Array<StM.IYoutubeIdStoreState>;
    selectedYoutubeId: StM.IYoutubeIdStoreState;

    onCourtSelected?: (youtubeId: StM.IYoutubeIdStoreState) => void;
}

interface ICourtSelectorState { }

export class CourtSelector extends React.Component<ICourtSelectorProps, ICourtSelectorState> {
    constructor(props: ICourtSelectorProps) {
        super(props);
    }

    shouldComponentUpdate(newProps: ICourtSelectorProps) {
        const isCourtsChanged = !_.isEqual(this.props.courts, newProps.courts);
        const youtubeIdsChanged = !_.isEqual(this.props.youtubeIds, newProps.youtubeIds);
        const isSelectedYoutubeIdChanged = !_.isEqual(this.props.selectedYoutubeId, newProps.selectedYoutubeId);

        return isCourtsChanged || youtubeIdsChanged || isSelectedYoutubeIdChanged;
    }

    render() {
        const courtGroupCount = this.props.courts ? Math.ceil(this.props.courts.length / 2) : 0;
        const firstCourtGroup = this.props.courts ? this.props.courts.slice(0, courtGroupCount) : [];
        const secondCourtGroup = this.props.courts ? this.props.courts.slice(courtGroupCount) : [];
        return (
            <div className="court-selector">
                {this.renderCourtGroup(firstCourtGroup)}
                {this.renderCourtGroup(secondCourtGroup.reverse())}
            </div>
        )
    };

    private renderCourtGroup(courts: Array<StM.ICourtStoreState>) {
        const availableCourts = this.props.youtubeIds.map(id => id.courtId);
        const selectedYoutubeId = this.props.selectedYoutubeId;
        return (
            <div className="court-group">
                {courts.map((court) => {
                    const currentYoutubeId = _.find(this.props.youtubeIds, { courtId: court.id });
                    const isAvailableCourt = _.includes(availableCourts, court.id);
                    const courtClasses = ClassNames('court-wrapper', {
                        'not-available': !isAvailableCourt,
                        'active': selectedYoutubeId && selectedYoutubeId.courtId == court.id
                    });
                    const courtNumber = court.title.slice(court.title.indexOf('#') + 1);
                    return (
                        <div className={courtClasses} key={court.id} onClick={(e) => isAvailableCourt ? this.onSelectCourt(e, currentYoutubeId) : void (0)}>
                            <span className="court-title">{courtNumber}</span>
                            {this.renderCameraGroup(currentYoutubeId)}
                        </div>
                    );
                })}
            </div>
        );
    }

    private renderCameraGroup(youtubeId: StM.IYoutubeIdStoreState) {
        if (!youtubeId) return null;
        const selectedYoutubeId = this.props.selectedYoutubeId;
        let cameraSide = '';
        switch (youtubeId.cameraPosition) {
            case 'Top':
                cameraSide = 'top';
                break;
            case 'Left':
                cameraSide = 'left';
                break;
            case 'Right':
                cameraSide = 'right';
                break;
            case 'Bottom':
                cameraSide = 'bottom';
                break;
            default:
                cameraSide = 'top';
                break;
        }
        const cameraClasses = ClassNames('camera-item', cameraSide, {
            'active': selectedYoutubeId.youtubeId === youtubeId.youtubeId
        });
        return (
            <div className="camera-group">
                <div className={cameraClasses} key={youtubeId.youtubeId} onClick={(e) => this.onSelectCourt(e, youtubeId)}></div>

                {/* {cameras.map(camera => {
                    let cameraSide = '';
                    switch (camera.side) {
                        case 'Top':
                            cameraSide = 'top';
                            break;
                        case 'Left':
                            cameraSide = 'left';
                            break;
                        case 'Right':
                            cameraSide = 'right';
                            break;
                        case 'Bottom':
                            cameraSide = 'bottom';
                            break;
                        default:
                            cameraSide = 'top';
                            break;
                    }
                    const isCameraSelected = isSelectedCourt && this.props.selectedCameraId == camera.side;
                    const cameraClasses = ClassNames('camera-item', cameraSide, {
                        'active': isCameraSelected,
                        'not-available': !camera.isActive
                    });
                    return (
                        <div className={cameraClasses} key={camera.name} onClick={(e) => camera.isActive ? this.onSelectCourt(e, court.code, camera.side) : void (0)}></div>
                    ); 
                })}*/}
            </div>
        );
    }

    private onSelectCourt(e: any, youtubeId: StM.IYoutubeIdStoreState) {
        if (e) { e.preventDefault(); e.stopPropagation(); }
        if (this.props.onCourtSelected) {
            this.props.onCourtSelected(youtubeId);
        }
    }
}