import './dialogs.mobile.scss';
import './coachTierSelectDialog.mobile.scss';

import * as React from 'react';
import * as _ from 'lodash';

import { StM, SrvM, Constants } from '../../modules';
import { ModalMobile } from '../../components';

interface ICoachTierSelectDialogProps {
    params: StM.IBookPageRouteParams;
    isShow: boolean;
    coaches: Array<StM.ICoachStoreState>;
    coachFeeTiers: Array<StM.ICoachFeeTierStoreState>;
    availableTimesLookup: IDictionary;
    coachFeeTierPrices: StM.ICoachFeeTierPriceStoreState[];
    pricingTiers: StM.IPricingTierStoreState[];
    club: StM.IClubStoreState;

    closeDialog: () => void;
    onCoachChanged: (coach: StM.ICoachStoreState) => void;
}

const PriceWrapper = (props: { price: number; label?: string }) => {
    return (
        <div className='price-wrapper-mobile'>
            <span className='price-mobile'>${props.price.toString()} </span>
            {!!props.label && <span className='label-mobile'>{props.label}</span>}
        </div>
    );
};

export default class CoachTierSelectDialogMobile extends React.Component<ICoachTierSelectDialogProps> {
    private utils = new SrvM.Utils();

    constructor(props: ICoachTierSelectDialogProps) {
        super(props);
    }

    // renders

    public render() {
        const { coachFeeTiers, coaches, isShow } = this.props;
        if (!isShow) return null;

        const source = coachFeeTiers
            .filter((tier) => tier.isShowForUser && !tier.isNoCoach && coaches.some((coach) => coach.coachFeeTierId === tier.id))
            .sort((tier1, tier2) => tier1.orderNumber - tier2.orderNumber);
        return (
            <ModalMobile classes='coach-tier-select-dialog' dialogName='coach-tier-select'>
                <div className='tier-list-wrapper-mobile'>{source.map((tier) => this.renderTierBlock(tier))}</div>
            </ModalMobile>
        );
    }

    private renderTierBlock(tier: StM.ICoachFeeTierStoreState) {
        const { coaches, availableTimesLookup, params, club } = this.props;
        const tierCoaches = coaches.filter((coach) => coach.coachFeeTierId === tier.id);
        const date = params.date === Constants.TodayRouteParam 
            ? this.utils.getCurrentClubDateTime(club).format(Constants.DateTime.DATE_FORMAT)
            : params.date;
        const orderedCoaches = this.utils.getOrderedCoachesMobile(tierCoaches, [tier], availableTimesLookup, date);
        return (
            <div className='modal-dialog-body tier-item-block-mobile' key={`tier${tier.id}`}>
                <div className='tier-title-wrapper-mobile'>
                    <div className='mobile-row flex-baseline-mobile'>
                        <div className='tier-title-mobile mobile-col-10'>{tier.title}</div>
                        <div className='tier-subtitle-mobile mobile-col-2'></div>
                    </div>
                    {this.renderCoachFeeTierPrices(tier)}
                </div>
                <div className='coaches-list-wrapper-mobile'>{orderedCoaches.map((coach) => this.renderCoach(coach))}</div>
            </div>
        );
    }

    private renderCoach(coach: StM.ICoachStoreState) {
        const img = this.utils.getImageUrl(coach.imageId);
        return (
            <div className='coach-item-wrapper-mobile' key={coach.id} onClick={(e) => this.onCoachClick(e, coach)}>
                <div className='mobile-row flex-center-mobile coach-wrapper-mobile'>
                    <div className='mobile-col-10 coach-info-wrapper-mobile flex-center-mobile'>
                        <div className='coach-photo-wrapper-mobile'>
                            <img className='coach-photo-mobile' src={img} />
                        </div>
                        <div className='coach-name-mobile text-ellipsis'>{coach.displayName}</div>
                    </div>
                    <div className='mobile-col-2 tier-subtitle-mobile'></div>
                </div>
            </div>
        );
    }

    private renderCoachFeeTierPrices(tier: StM.ICoachFeeTierStoreState) {
        const { coachFeeTierPrices, pricingTiers } = this.props;
        const prices = coachFeeTierPrices.filter((t) => t.coachFeeTierId === tier.id);
        const isSinglePrice = !!prices.length && prices.every((p) => p.privateLessonPrice === prices[0].privateLessonPrice);
        const filteredPricingTiers = pricingTiers.filter((t) => t.isEnabled && prices.some((p) => p.pricingTierId === t.id));

        return (
            <div className='mobile-row prices-wrapper-mobile'>
                {isSinglePrice ? (
                    <PriceWrapper price={prices[0].privateLessonPrice} />
                ) : (
                    StM.AvailablePricingTiers.reduce((results, tierType, index) => {
                        const pricingTier = filteredPricingTiers.find((t) => t.type === tierType);
                        if (pricingTier) {
                            if (index > 0) results.push(<div key={`delimiter-${pricingTier.id}`} className='price-delimiter'> / </div>);
                            const coachFeeTierPrice = prices.find((p) => p.pricingTierId === pricingTier.id);
                            results.push(
                                <PriceWrapper
                                    key={pricingTier.id}
                                    price={coachFeeTierPrice ? coachFeeTierPrice.privateLessonPrice : 0}
                                    label={pricingTier.title}
                                />
                            );
                        }
                        return results;
                    }, [])
                )}
            </div>
        );
    }

    // handlers

    private onCoachClick(e: any, coach: StM.ICoachStoreState) {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        this.props.onCoachChanged(coach);
    }
}
