import './openSessionsBoardPage.scss';
import * as React from 'react';
import BigSessionInfoItem from '../../../components/pages/bigSessionInfoItem';
import { connect } from 'react-redux';
import * as _ from 'lodash';
import * as StM from '../../../models/store';
import * as ActM from '../../../actions';
import * as SrvM from '../../../services';

interface IOpenSessionsBoardPageProps {
    isAuthorized: boolean;
    user: StM.IUserStoreState;
    club: StM.IClubStoreState;
    sessions: Array<StM.ISessionStoreState>;
    coachFeeTiers: Array<StM.ICoachFeeTierStoreState>;
    coaches: Array<StM.ICoachStoreState>;
    basket: StM.IBasketStoreState;

    showSpinner: () => any;
    hideSpinner: () => any;
    init: () => Promise<any>;
    getSessions: () => Promise<any>;
    onSessionInfoClick: (from: string, id: number) => void;
    openAuthDialog: () => void;
    isLoading: boolean;
}

interface IOpenSessionsBoardPageState { }

class OpenSessionsBoardPage extends React.Component<IOpenSessionsBoardPageProps, IOpenSessionsBoardPageState> {

    private todaySessions: Array<StM.ISessionStoreState>;
    private weekSessions: Array<StM.ISessionStoreState>;
    private utils: SrvM.Utils;
    constructor(props: IOpenSessionsBoardPageProps) {
        super(props);
        this.todaySessions = [];
        this.weekSessions = [];
        this.utils = new SrvM.Utils();
    }

    componentDidMount() {
        if (this.props.club.id) this.updateSessions();
    }

	componentDidUpdate(prevProps: IOpenSessionsBoardPageProps) {
		const isClubChanged = !_.isEqual(this.props.club, prevProps.club);
		if (isClubChanged && this.props.club.id) {
			this.updateSessions();
		}
	}

    shouldComponentUpdate(nextProps: IOpenSessionsBoardPageProps, nextState: IOpenSessionsBoardPageState): boolean {
        const isAuthorizedChanged = this.props.isAuthorized != nextProps.isAuthorized;
        const today = this.utils.getCurrentClubDateTime(this.props.club);
        this.todaySessions = _.filter(nextProps.sessions, (session: StM.ISessionStoreState) => {
            return session.startDateTime.clone().date() === today.clone().date();
        }).sort((a, b) => {
            return a.startDateTime.valueOf() - b.startDateTime.valueOf();
        });
        this.weekSessions = _.filter(nextProps.sessions, (session: StM.ISessionStoreState) => {
            return session.startDateTime.clone().date() !== today.clone().date();
        }).sort((a, b) => {
            return a.startDateTime.valueOf() - b.startDateTime.valueOf();
        });

        if (nextProps.club.id && isAuthorizedChanged) {
            this.updateSessions();
        }
        return true;
    }

    render() {
        const today = this.renderToday();
        const week = this.renderWeek();
        if (
            (this.todaySessions && this.todaySessions.length > 0) ||
            (this.weekSessions && this.weekSessions.length > 0)
        ) {
            return (
                <div className="main-content-wrapper my-session-page">
                    <div className="page-title">Public sessions</div>
                    <div className="page-subtitle">
                        Looking for a game or to join a lesson?<br />
                        Sessions are posted here by other players, and are open for you to join. Any session you make public will be displayed on other player’s boards.
                    </div>
                    <div className="player-seeking-board-wrapper">
                        {today}
                        {week}
                    </div>
                </div>
            );
        } else {
            return (
                <div className="main-content-wrapper my-session-page">
                    <div className="page-title">Public sessions</div>
                    <div className="page-subtitle">
                        Looking for a game or to join a lesson?<br />
                        Sessions are posted here by other players, and are open for you to join. Any session you make public will be displayed on other player’s boards.
                    </div>
                    <div className="player-seeking-board-wrapper">
                        {this.props.isLoading ? (<p> Loading, please wait </p>) : (<p>NO PUBLIC SESSIONS</p>)}
                    </div>
                </div>
            );
        }
    }

    private renderToday() {
        if (this.todaySessions && this.todaySessions.length > 0) {
            return (
                <div className="table-wrapper">
                    <div className="table-name">Today</div>
                    <div className="session-wrapper">
                        {this.todaySessions.map((session, index) => {
                            return (<BigSessionInfoItem
                                isOpenBoard={true}
                                coachFeeTiers={this.props.coachFeeTiers}
                                session={session}
                                user={this.props.user}
                                basket={this.props.basket}
                                coaches={this.props.coaches}
                                key={index}
                                club={this.props.club}
                                infoClick={this.props.onSessionInfoClick} />)
                        })}
                    </div>
                </div>
            )
        }
        return null;
    }

    private renderWeek() {
        if (this.weekSessions && this.weekSessions.length > 0) {
            return (
                <div className="table-wrapper">
                    <div className="table-name">Next 7 days</div>
                    <div className="session-wrapper">
                        {this.weekSessions.map((session, index) => {
                            return (<BigSessionInfoItem
                                isOpenBoard={true}
                                coachFeeTiers={this.props.coachFeeTiers}
                                session={session}
                                user={this.props.user}
                                basket={this.props.basket}
                                key={index}
                                club={this.props.club}
                                coaches={this.props.coaches}
                                infoClick={this.props.onSessionInfoClick} />)
                        })}
                    </div>
                </div>
            )
        }
        return null;
    }

    private updateSessions() {
        this.props.showSpinner();
        this.props.init()
            .then(() => {
                this.props.hideSpinner();
            }).catch(() => {
                this.props.hideSpinner();
            });
    }
}

function mapStateToProps(state: StM.IGlobalStoreState) {
    return {
        isAuthorized: state.app.isAuthorized,
        user: state.user,
        basket: state.basket,
        club: state.club,
        sessions: state.pages.seekingPlayerBoard.sessions,
        coachFeeTiers: state.coachFeeTiers,
        isLoading: state.app.isLoading,
        coaches: state.coaches
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        showSpinner: () => dispatch(ActM.AppActions.showSpinner())
        , hideSpinner: () => dispatch(ActM.AppActions.hideSpinner())
        , init: () => dispatch(ActM.OpenSessionsBoardPageActions.init())
        , onSessionInfoClick: (from: string, id: number) => dispatch(ActM.DialogActions.open(StM.DialogNames.SessionInfo, { from: from, id: id }))
        , openAuthDialog: () => dispatch(ActM.DialogActions.open(StM.DialogNames.Auth, { tab: StM.AuthDialogTabs.SignIn }))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OpenSessionsBoardPage);
