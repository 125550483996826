import '../dialogs.scss';
import './passwordRecoveryDialog.mobile.scss';

import * as React from 'react';

import * as StM from '../../../models/store';
import { ModalMobile } from '../../../components/modal.mobile';
import { Field, FormError } from '../../../components';
import * as BD from './basePasswordRecoveryDialog';

interface IPasswordRecoveryDialogProps extends BD.IPasswordRecoveryDialogProps {}

class PasswordRecoveryDialogMobile extends React.Component<IPasswordRecoveryDialogProps> {
    constructor(props: IPasswordRecoveryDialogProps) {
        super(props);
    }

    public render() {
        if(!this.props.isShown) return null;
        return (
            <ModalMobile classes="pass-recovery-modal-mobile" dialogName={StM.DialogNames.PasswordRecovery}>
                <div className="modal-dialog-header">
                    <div className="title">Password Recovery</div>
                </div>
                <div className="modal-dialog-body">
                    <form onSubmit={(e) => this.props.onResetPassClick(e)}>
                        <div className="modal-dialog-body-content">
                            <div className="form-wrapper">
                                <Field type="text" 
                                    name="email"
                                    error={this.props.errors && this.props.errors.email}
                                    placeholder="Email"
                                    onFieldChange={(name, value) => this.props.onFieldChange(name, value)}
                                />                                
                                <div className="btns-wrapper">
                                    <button className="btn btn-block btn-red" type="submit" onClick={(e) => this.props.onResetPassClick(e)}>Reset Password</button>
                                </div>
                            </div>
                        </div>
                        <FormError errors={this.props.errors}/>
                        <div className="divider-line"></div>
                        <div className="btn-back-wrapper">
                            <span className="btn-back" onClick={(e) => this.props.onBackClick(e)}>Back</span>
                        </div>
                    </form>
                </div>
            </ModalMobile>
        );
    }
}

export default BD.withPasswordRecoveryDialog(PasswordRecoveryDialogMobile);
