import './dialogs.mobile.scss';
import './contactUsDialog.mobile.scss';
import * as React from 'react';
import moment from 'moment';
import * as StM from '../../models/store';
import * as ActM from '../../actions';
import { connect } from 'react-redux';
import ClassNames from 'classnames';
import { ModalMobile } from '../../components/modal.mobile';

export interface IContactUsDialogProps {
    isShow: boolean;

    closeDialog: () => void;
    showSpinner: () => any;
    hideSpinner: () => any;
    sendRequest: (firstName: string, lastName: string, email: string, message: string) => Promise<any>;
}

export interface IContactUsDialogState {
    error: string;
}

var FIRST_NAME_ERROR_TEXT = "Please provide your first name";
var LAST_NAME_ERROR_TEXT = "Please provide your last name";
var EMAIL_ERROR_TEXT = "Please check your email";
var MESSAGE_ERROR_TEXT = "Please provide your message";
var GENERAL_ERROR_TEXT = "Error has occured.";

class ContactUsDialogMobile extends React.Component<IContactUsDialogProps, IContactUsDialogState>{
    private firstName: string = '';
    private lastName: string = '';
    private email: string = '';
    private message: string = '';

    private firstNameError: boolean = false;
    private lastNameError: boolean = false;
    private emailError: boolean = false;
    private messageError: boolean = false;

    private submitDirty: boolean = false;

    constructor(props: IContactUsDialogProps) {
        super(props);
        this.state = {
            error: '',
        };
    }

    componentDidUpdate(prevProps: IContactUsDialogProps) {
        if (prevProps.isShow !== this.props.isShow && this.props.isShow) {
            this.setState({ ...this.state, error: '' });
        }
    }

    render() {
        const firstNameClasses = ClassNames("input-item", "first-name", { error: this.firstNameError });
        const lastNameClasses = ClassNames("input-item", "last-name", { error: this.lastNameError });
        const emailClasses = ClassNames("input-item", "e-mail", { error: this.emailError });
        const messageClasses = ClassNames("input-item", "message", { error: this.messageError });

        if (this.props.isShow) {
            return (
                <ModalMobile classes="contact-us-modal" closeHandler={this.props.closeDialog} dialogName={StM.DialogNames.ContactUs}>
                        <div className="modal-dialog-header">
                            <div className="title">Contact Us</div>
                        </div>
                        <div className="modal-dialog-body">
                            <div className="modal-dialog-body-content">
                                <div className="form-wrapper">
                                    <div className="inputs-wrapper">
                                        <div className={firstNameClasses}>
                                            <label>First Name</label>
                                            <div className="first-name-input-wrapper">
                                                <input type="text" className="first-name-input" maxLength={70} onChange={(e) => this.handleFirstNameChanging(e)} />
                                            </div>
                                            {this.firstNameError && <div className="validation-error">{FIRST_NAME_ERROR_TEXT}</div>}
                                        </div>
                                        <div className={lastNameClasses}>
                                            <label>Last Name</label>
                                            <div className="first-name-input-wrapper">
                                                <input type="text" className="first-name-input" maxLength={70} onChange={(e) => this.handleLastNameChanging(e)} />
                                            </div>
                                            {this.lastNameError && <div className="validation-error">{LAST_NAME_ERROR_TEXT}</div>}
                                        </div>
                                        <div className={emailClasses}>
                                            <label>Email</label>
                                            <div className="e-mail-input-wrapper">
                                                <input type="text" className="e-mail-input" maxLength={70} onChange={(e) => this.handleEmailChanging(e)} />
                                            </div>
                                            {this.emailError && <div className="validation-error">{EMAIL_ERROR_TEXT}</div>}
                                        </div>
                                        <div className={messageClasses}>
                                            <label>Message</label>
                                            <div className="message-input-wrapper">
                                                <textarea className="message-textarea-contact-us" maxLength={4000} onChange={(e) => this.handleMessageChanging(e)} rows={8}></textarea>
                                            </div>
                                            {this.messageError && <div className="validation-error">{MESSAGE_ERROR_TEXT}</div>}
                                        </div>
                                    </div>
                                </div>
                                <div className="btns-wrapper modal-dialog-body-content">
                                    <button className="btn-next btn-red btn-mobile" onClick={(e) => { this.onSendClick(e); }}>SEND</button>
                                    <button className="btn-next btn-black btn-mobile" onClick={(e) => { this.props.closeDialog(); }}>CANCEL</button>
                                </div>
                            </div>
                            {this.state.error && (
                                <div className="error-message-wrapper">
                                    <span className="error-message-title">{this.state.error}</span>
                                </div>
                            )}
                        </div>
                </ModalMobile>
            );
        } else {
            return null;
        }
    }

    private validateFirstName(): boolean {
        this.firstNameError = !this.firstName;
        return !this.firstNameError;
    }

    private validateLastName(): boolean {
        this.lastNameError = !this.lastName;
        return !this.lastNameError;
    }

    private validateEmail(): boolean {
        var emailRegexp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!emailRegexp.test(this.email)) {
            this.emailError = true;
        } else if (!this.email) {
            this.emailError = true;
        } else {
            this.emailError = false;
        }

        return !this.emailError;
    }

    private validateMessage(): boolean {
        this.messageError = !this.message;
        return !this.messageError;
    }

    private validateForm() {
        let isValid = true;
        isValid = this.validateFirstName() && isValid;
        isValid = this.validateLastName() && isValid;
        isValid = this.validateEmail() && isValid;
        isValid = this.validateMessage() && isValid;

        return isValid;
    }

    private onSendClick(e: any) {
        if (e) { e.stopPropagation(); }

        this.submitDirty = true;
        if (!this.validateForm()) {
            this.setState({ ...this.state, error: GENERAL_ERROR_TEXT });
            return;
        }

        this.props.showSpinner();
        this.props.sendRequest(this.firstName, this.lastName, this.email, this.message)
            .then(() => {
                this.props.closeDialog();
                this.props.hideSpinner();
            }).catch(() => {
                this.props.hideSpinner();
            });
    }

    private updateErrorMessage() {
        var error = this.validateForm() ? "" : GENERAL_ERROR_TEXT;
        this.setState({ ...this.state, error });
    }

    private handleFirstNameChanging(e: any) {
        this.firstName = e.target.value as string || "";
        var wasError = this.firstNameError;
        if (this.submitDirty && wasError === this.validateFirstName()) {
            this.updateErrorMessage();
        }
    }

    private handleLastNameChanging(e: any) {
        this.lastName = e.target.value as string || "";
        var wasError = this.lastNameError;
        if (this.submitDirty && wasError === this.validateLastName()) {
            this.updateErrorMessage();
        }
    }

    private handleEmailChanging(e: any) {
        this.email = e.target.value as string || "";
        var wasError = this.emailError;
        if (this.submitDirty && wasError === this.validateEmail()) {
            this.updateErrorMessage();
        }
    }

    private handleMessageChanging(e: any) {
        this.message = e.target.value as string || "";
        var wasError = this.messageError;
        if (this.submitDirty && wasError === this.validateMessage()) {
            this.updateErrorMessage();
        }
    }
}

const mapStateToProps = (state: StM.IGlobalStoreState, ownProps: any) => {
    return {
        isShow: state.dialogs.contactUs.isOpened
    }
}


const mapDispatchToProps = (dispatch: any) => {
    return {
        closeDialog: () => dispatch(ActM.DialogActions.close(StM.DialogNames.ContactUs))
        , showSpinner: () => dispatch(ActM.AppActions.showSpinner())
        , hideSpinner: () => dispatch(ActM.AppActions.hideSpinner())
        , sendRequest: (firstName: string, lastName: string, email: string, message: string) =>
            dispatch(ActM.ContactUsDialogActions.sendRequest(firstName, lastName, email, message))
    };

}

export default connect(mapStateToProps, mapDispatchToProps)(ContactUsDialogMobile);