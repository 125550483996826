import React from 'react';
import ClassNames from 'classnames';
import { connect } from 'react-redux';

import { StM, SrvM, ActM } from '../modules';
import BasketItem from '../components/basket/basketItem';
import BasketPackageItem from '../components/basket/basketPackageItem';

interface IBasketProps {
    basket: any;
    isAuthorized: boolean;
    setHeight: boolean;
    user: StM.IUserStoreState;
    timeSlots: Array<StM.ICourtTimeSlotStoreState>;
    club: StM.IClubStoreState;
    banner: string;

    removeItem: (id: number) => void;
    removePackageItem: (id: number) => void;
    openBookDialog: () => Promise<any>;
    openAuthDialog: (url: string) => Promise<any>;
    openConfirmDialog: (index: number, isPackage: boolean) => Promise<any>;
    openCreateSessionDialog: (courtId: number, timeKey: string, sessionType:string, subFilter?:string, subFilterId?:string, sessionId?: number) => void;
    openSessionInfoDialog: (id: number) => Promise<any>;
    openSessionDialog: (id: number) => Promise<any>;
    openPackageDialog: (packageDefinition: StM.IPackageDefinitionStoreState) => Promise<any>;
}

interface IBasketState {}

class Basket extends React.Component<IBasketProps, IBasketState> {
    private routeSrv: SrvM.RouteService = new SrvM.RouteService();

    constructor(props: IBasketProps) {
        super(props);

    }
    
    render() {
        const isClubDashboard = this.routeSrv.isActivePage(StM.Pages.ClubDashboard);
        const isScoreboardPage = this.routeSrv.isActivePage(StM.Pages.Scoreboard);
        const isVideoPage = this.routeSrv.isActivePage(StM.Pages.Video);
        if (isClubDashboard || isScoreboardPage || isVideoPage) { return null; }

        const shownList = this.props.basket.isShown;
        const goods = this.renderList(this.props.basket);
        const packages = this.renderPackagesList(this.props.basket);

        const cartHasItems = !!goods.length || !!packages.length;

        const basketClasses = ClassNames('basket-content-block-wrapper', {'basket-open': shownList, 'custom': !!this.props.club.uxV2RightBannerUrl});
        const bannerWrapperClasses = ClassNames('banner-basket-wrapper', {'custom': !!this.props.club.uxV2RightBannerUrl});
        const bannerClasses = ClassNames('banner-basket', {'custom': !!this.props.club.uxV2RightBannerUrl});

        return (
            <div className={basketClasses}>
                {!!cartHasItems && 
                    <div className="basket-content-title">
                        My Cart
                    </div>
                }
                {!!cartHasItems && packages}
                {!!cartHasItems && goods}
                {!!cartHasItems && 
                    <div className="button-wrapper">
                        <button className="btn-book" onClick={(e) => this.openBookDialogClick(e)}>CHECKOUT</button>
                    </div>
                }
                <div className={bannerWrapperClasses}>
                    {!!this.props.club.uxV2RightBannerUrl && this.renderIframe()}
                    <span className={bannerClasses}></span>
                </div>
            </div>
        );
    }

    private renderIframe() {
        return (
            <iframe 
                className="banner-frame" 
                id="basketFrame"
                src={
                    `data:text/html;charset=utf-8,${encodeURIComponent(this.props.banner)}`
                } 
            />
        );
    }

    private renderList(basket: StM.IBasketStoreState) {
        return basket.goods.map((item)=> {
            return (
                <BasketItem 
                    user={this.props.user} 
                    key={item.basketId} 
                    session={item} 
                    onBasketSessionClick={(basketSession) => {this.onBasketSessionClick(basketSession)}}
                    removeItem={(index) => this.removeClick(index)}
                />
            ) 
        });
    }

    private renderPackagesList(basket: StM.IBasketStoreState) {
        return basket.packages.map((item: any, index: number)=> {
            return (
                <BasketPackageItem 
                    key={index} 
                    basketPackage={item} 
                    onBasketPackageClick={(basketPackage) => {this.onBasketPackageClick(basketPackage)}}
                    removeItem={(index) => this.removeClick(index, true)}
                />
            ) 
        });
    }
    
    private openBookDialogClick(e:any) {
        if(e) {e.stopPropagation();}
        if (!this.props.isAuthorized) {
            let thunk = new SrvM.RouteDialogService();
            let hash = thunk.getDialogHash(StM.DialogNames.Book, {});
            let url = encodeURIComponent(hash);
            this.props.openAuthDialog(url);
        } else {
            this.props.openBookDialog();
        }
    }

    private removeClick(index: number, isPackage: boolean = false) {
        this.props.openConfirmDialog(index, isPackage);
    }

    private onBasketSessionClick(basketSession: StM.ISessionStoreState){
        this.props.openSessionDialog(basketSession.basketId);
    }

    private onBasketPackageClick(basketPackage: StM.IPackageDefinitionStoreState){
        this.props.openPackageDialog(basketPackage);
    }
};

const mapStateToProps = (state: StM.IGlobalStoreState, ownProps: any) => {
    return {
        basket: state.basket,
        isAuthorized: state.app.isAuthorized,
        user: state.user,
        timeSlots: state.pages.book.timeSlots,
        club: state.club,
        banner: state.banner.rightClubBanner
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        removeItem: (id: number) => (dispatch(ActM.BasketActions.remove(id))),
        removePackageItem: (id: number) => dispatch(ActM.BasketActions.removePackage(id)),
        openBookDialog: () => (dispatch(ActM.DialogActions.open(StM.DialogNames.Book))),
        openAuthDialog: (url:string) => dispatch(ActM.DialogActions.open(StM.DialogNames.Auth, { tab: StM.AuthDialogTabs.SignIn, returnUrl: url})),
        openConfirmDialog: (index:number, isPackage: boolean) => (dispatch(ActM.DialogActions.open(StM.DialogNames.ConfirmDeleteBasketItem, {basketIndex:index, isPackage: isPackage}))),
        openSessionDialog: (id: number) => dispatch(ActM.SessionActions.openSessionDialog(StM.SessionInfoFromTypes.basket, id)),
        openPackageDialog: (packageDefinition: StM.IPackageDefinitionStoreState) => dispatch(ActM.DialogActions.open(StM.DialogNames.PackagePurchase, { packageDefinition, packageDefinitionId: packageDefinition.id }))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Basket);