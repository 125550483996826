import './baseBadge.scss';
import './privateBadge.scss';

import * as React from 'react';
import ClassNames from 'classnames';

import * as StM from '../../../../models/store';
import * as SrvM from '../../../../services';
import * as PolM from '../../../../policies';
import { IBaseBadgeProps, DurationClasses, CommonBadge } from './baseBadge';
import { Constants } from '../../../../constants';

interface IPrivateBadgeBadgeProps extends IBaseBadgeProps {
    user: StM.IUserStoreState;
    onNonFilterSessionClick: (sessionId: number) => void;
}

interface IPrivateBadgeState { }

export class PrivateBadge extends React.Component<IPrivateBadgeBadgeProps, IPrivateBadgeState> {
    private utils = new SrvM.Utils();
    private authSrv = new SrvM.AuthenticationService();

    constructor(props: IPrivateBadgeBadgeProps) {
        super(props);
    }

    render() {
        const session = this.props.time.session;
        const sessionAvailability = new PolM.SessionAvailabilityPolicy(session, [], this.props.user);

        const isGroup = this.authSrv.isInRole(StM.Roles.GroupAccount, this.props.user);
        const isAvailableSessionButNotFiltered = this.props.time.isAvailableSession && this.props.time.isAvailableTime;
        const isClinic = session.type === StM.SessionType.Clinic;
        const isCustom = session.type === StM.SessionType.Custom;
        const isPublicSession = isCustom || isClinic || (isGroup && !session.isHidden);
        const isMemberTierMatched = sessionAvailability.isMemberTierMatched();
        const isFilterLink = isPublicSession && isAvailableSessionButNotFiltered && isMemberTierMatched;

        return isFilterLink ? this.getFilterLinkBadge() : this.getPrivateBadge();
    }

    private getFilterLinkBadge() {
        const session = this.props.time.session;
        const durationClass = DurationClasses.getDurationClass(this.props.time.duration);
        const sizeClass = DurationClasses.getBadgeSizeByDuration(this.props.time.duration);
        const classes = ClassNames('table-column-item-wrapper', 'private-session-cell', 'filter-link', durationClass, sizeClass);
        const title = (session.type === StM.SessionType.Custom) 
            ? session.title || 'Custom Session' 
            : `${this.utils.getSessionTypeTitle(session)}${session.title ? `: ${session.title}` : ''}`;
        const filterClass = ClassNames('filter-link-title', {
            'fix-weight': this.utils.get_name_browser() === 'Firefox' || this.utils.get_name_browser() === 'Safari'
        });
        const styles: any = {
            height: CommonBadge.getBadgeHeightByDuration(this.props.time.duration)
        }
        return (
            <div className={classes} onClick={(e) => this.onNonFilterSessionClickHandler(e)} style={styles}>
                <div className="table-column-item">
                    <div className="private-badge-content">
                        <span className={filterClass}>
                            {title}
                        </span>
                    </div>
                </div>
            </div>
        )
    }

    private getPrivateBadge() {
        const session = this.props.time.session;
        const sessionAvailability = new PolM.SessionAvailabilityPolicy(session, [], this.props.user);
        const durationClass = DurationClasses.getDurationClass(this.props.time.duration);
        const sizeClass = DurationClasses.getBadgeSizeByDuration(this.props.time.duration);
        const classes = ClassNames('table-column-item-wrapper', 'private-session-cell', durationClass, sizeClass);
        const isCustom = session.type === StM.SessionType.Custom;
        let title = '';
        if (this.props.time.isAvailableSession && this.props.time.isAvailableTime) {
            title = this.utils.getSessionTitle(session);
        }
        if(sessionAvailability.getIsGroupSession() || sessionAvailability.getIsGroupMemberSession(false)) {
            title = CommonBadge.getGroupMemberTitle(session, this.props.user);
        } else if (isCustom) {
            title = session.title;
        }
        const styles: any = {
            height: CommonBadge.getBadgeHeightByDuration(this.props.time.duration)
        }
        return (
            <div className={classes} style={styles}>
                <div className="table-column-item">
                    <span>{title}</span>
                </div>
            </div>
        )
    }

    private onNonFilterSessionClickHandler(e: any) {
        if (e) { e.preventDefault(); e.stopPropagation(); }
        this.props.onNonFilterSessionClick(this.props.time.session.id);
    }
}
