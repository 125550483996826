import './burgerMenu.mobile.scss';
import * as React from 'react';
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';
import { push as Menu } from 'react-burger-menu';

import * as StM from '../../models/store';
import * as SrvM from '../../services';
import ClientMenuMobile from './clientMenu.mobile';
import VideoMenuMobile from './videoMenu.mobile';

export interface IBurgerMenuMobileProps {
    isOpen: boolean;
    hidden: boolean;
}

const scoreboardRoute = `${StM.Pages.Scoreboard}/:courtId`;
const videoPageRoute = '{0}/:videoLinkId'.format(StM.Pages.Video);

export class BurgerMenuMobile extends React.Component<IBurgerMenuMobileProps> {
    constructor(props: IBurgerMenuMobileProps) {
        super(props);
    }

    render() {
        if (this.props.hidden) return null;
        return (
            <Menu pageWrapId={"page-wrapper"} outerContainerId={"main-wrapper"} isOpen={this.props.isOpen} noOverlay>
                <Switch>
                    <Route path={videoPageRoute} exact component={VideoMenuMobile} />
                    <Route component={ClientMenuMobile} />
                </Switch>
            </Menu>
        )
    }
}

const mapStateToProps = (state: StM.IGlobalStoreState, ownProps: any) => {
    const routeSrv = new SrvM.RouteService();
    const isScoreboard = routeSrv.isActivePage(StM.Pages.Scoreboard);
    let isMenuHidden = isScoreboard;
    const isVideoPage = routeSrv.isActivePage(StM.Pages.Video);
    if (isVideoPage) {
        const utils = new SrvM.Utils();
        const videoSession = state.pages.video.session;
        isMenuHidden = videoSession && videoSession.startDateTime.isAfter(utils.getCurrentClubDateTime(state.club));
    }
    return {
        isOpen: state.burgerMenu.isOpen,
        hidden: isMenuHidden
    }
}

export default withRouter(connect(mapStateToProps)(BurgerMenuMobile));
