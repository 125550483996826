import * as React from 'react';
import { compose, Dispatch } from 'redux';
import { connect } from 'react-redux';

import * as StM from '../../../../models/store';
import * as ActM from '../../../../actions';
import * as SrvM from '../../../../services';

export interface IGroupsPageProps extends IBaseGroupsPageState {
    user: StM.IUserStoreState;
    groups: StM.IGroupStoreState[];

    onCreateGroupBtnClick?: (e: any) => void;
    onSwitchToGroup: (group: StM.IGroupStoreState) => void;
    openInviteMemberToGroupDialog: (groupId: number) => void;
    onRemoveGroup: (group: StM.IGroupStoreState) => void;
    onLeaveGroup: (group: StM.IGroupStoreState) => void;
}

interface IBaseGroupsPageProps {
    isAuthorized: boolean;
    isFinalized: boolean;
    user: StM.IUserStoreState;
    groups: StM.IGroupStoreState[];

    openCreateGroupAccountDialog: () => void;
    showSpinner: () => void;
    hideSpinner: () => void;
    onSwitchToGroup: (group: StM.IGroupStoreState) => void;
    loginOnBehalf: (groupId: string) => Promise<void>;
    openInviteMemberToGroupDialog: (groupId?: number) => void;
    removeGroup: (groupId: number) => void;
    showConfirm: (msg: string, yesCallback: () => void, noCallback: () => void, yesBtnTxt?: string, noBtnTxt?: string, showExitBtn?: boolean) => Promise<any>;
    closeConfirm: () => Promise<any>;
    go: (url: string) => void;
    leaveGroup: (groupId: number) => Promise<void>;
}

interface IBaseGroupsPageState {
}

const withBaseGroupsPage = (Component: React.ComponentType<IGroupsPageProps>) => {
    return class BaseGroupsPage extends React.Component<IBaseGroupsPageProps, IBaseGroupsPageState> {
        private authSrv = new SrvM.AuthenticationService();
        constructor(props: IBaseGroupsPageProps) {
            super(props);
        }
        
        componentDidMount() {
            this.init(this.props);
        }

        componentDidUpdate(prevProps: IBaseGroupsPageProps) {
            if (prevProps !== this.props) {
                this.init(this.props);
            }
        }

        public render() {
            if (!this.props.isFinalized || !this.props.isAuthorized) {
                return (
                    <div className="main-content-wrapper profile-pages groups-page">
                        <h2 className="page-title">My Groups</h2>
                        <p>{StM.Strings.UnathorizedAccessTitle}</p>
                    </div>
                );
            }
            return (
                <Component 
                    {...this.state}
                    user={this.props.user}
                    groups={this.props.groups}
                    onCreateGroupBtnClick={(e) => this.handleCreateGroupBtnClick(e)}
                    onSwitchToGroup={(group) => this.handleSwitchToGroup(group)}
                    openInviteMemberToGroupDialog={(groupId) => this.props.openInviteMemberToGroupDialog(groupId)}
                    onRemoveGroup={(group) => this.handleRemoveGroup(group)}
                    onLeaveGroup={(group) => this.handleLeaveGroup(group)}
                />
            );
        }

        private init(props: IBaseGroupsPageProps) {
            if(this.authSrv.isInRole(StM.Roles.GroupAccount, props.user)) {
                this.props.go(StM.UserPages.Profile);
            }
        }

        private handleCreateGroupBtnClick(e: any) {
            if(e) e.preventDefault();
            this.props.openCreateGroupAccountDialog();
        }

        private handleSwitchToGroup(group: StM.IGroupStoreState) {
            this.props.loginOnBehalf(group.groupAccountId);
        }

        private handleRemoveGroup(group: StM.IGroupStoreState) {
            this.props.showConfirm(
                'I want to close the group.'
                , () => {
                    this.props.removeGroup(group.id);
                    this.props.closeConfirm();
                }, () => this.props.closeConfirm()
                , 'Close the Group'
                , 'Keep the Group');
        }

        private handleLeaveGroup(group: StM.IGroupStoreState) {
            this.props.showConfirm(
                'I want to leave the group.'
                , () => {
                    this.props.leaveGroup(group.id);
                    this.props.closeConfirm();
                }, () => this.props.closeConfirm()
                , 'Leave'
                , 'Stay');
        }
    }
}

const mapStateToProps = (state: StM.IGlobalStoreState, ownProps: any) => ({
    isAuthorized: state.app.isAuthorized
    , isFinalized: state.app.isFinalized
    , user: state.user
    , groups: state.groupAccounts
});

const mapDispatchToProps = (dispatch: any) => ({
    showSpinner: () => dispatch(ActM.AppActions.showSpinner()),
    hideSpinner: () => dispatch(ActM.AppActions.hideSpinner()),
    openCreateGroupAccountDialog: () => dispatch(ActM.DialogActions.open(StM.DialogNames.CreateGroupAccount)),
    loginOnBehalf: (groupId: string) => dispatch(ActM.AccountActions.loginOnBehalf(groupId)),
    openInviteMemberToGroupDialog: (groupId?: number) => dispatch(ActM.DialogActions.open(StM.DialogNames.InviteGroupMember, { groupId })),
    removeGroup: (groupId: number) => dispatch(ActM.GroupAccountActions.remove(groupId)),
    showConfirm: (msg: string, yesCallback: () => void, noCallback: () => void, yesBtnTxt?: string, noBtnTxt?: string, showExitBtn?: boolean) =>
        dispatch(ActM.DialogActions.open(StM.DialogNames.Confirm, { msg, yesCallback, noCallback, yesBtnTxt, noBtnTxt, showExitBtn })),
    closeConfirm: () => dispatch(ActM.DialogActions.close(StM.DialogNames.Confirm)),
    go: (url: string) => dispatch(ActM.RouteActions.push(url)),
    leaveGroup: (groupId: number) => dispatch(ActM.GroupAccountActions.leaveGroup(groupId)),
});

export const withGroupsPage = compose(connect(mapStateToProps, mapDispatchToProps), withBaseGroupsPage);