import * as _ from 'lodash';
import * as StM from '../../models/store';
import * as React from 'react';
import moment from 'moment';

export class SessionInfoDialogRenderService {
    private static _instance: SessionInfoDialogRenderService;
    constructor(config?: any) {
        if (typeof SessionInfoDialogRenderService._instance === undefined) {
            SessionInfoDialogRenderService._instance = this;
        }
        return SessionInfoDialogRenderService._instance;
    }

    // support
    getTimeString(date: moment.Moment) {
        return date.format('h:mm');
    }

    getTimeZone(date: moment.Moment) {
        return date.format('a');
    }

    getDateMonth(date: moment.Moment) {
        return date.format('MMM');
    }

    getDateDay(date: moment.Moment) {
        return date.format('DD');
    }

    getSessionDuration(session: StM.SessionStoreState) {
        let ms = session.endDateTime.diff(session.startDateTime);
        return moment.duration(ms).asMinutes();
    }

    // renders 
    renderDateBlock(session: StM.SessionStoreState) {
        return (
            <div className="date-wrapper">
                <div className="date">{this.getDateDay(session.startDateTime)}</div>
                <div className="month">{this.getDateMonth(session.startDateTime)},</div>
                <div className="time-start-wrapper">
                    <div className="time-start">{this.getTimeString(session.startDateTime)}</div>
                    <div className="time-start-time-type">{this.getTimeZone(session.startDateTime)}</div>
                </div>
                <div className="time-divider">&ndash;</div>
                <div className="time-end-wrapper">
                    <div className="time-end">{this.getTimeString(session.endDateTime)}</div>
                    <div className="time-end-time-type">{this.getTimeZone(session.endDateTime)}</div>
                </div>
                <div className="session-type">
                    {(session.isDoubledSession ? "Double" : "Single") + ' Session (' + this.getSessionDuration(session) + ' min)'}
                </div>
            </div>
        );
    }
    renderCoach(session: StM.SessionStoreState) {
        if (!session.trainerId) return null;
        let wnd = window as any, trainer = session.trainer;
        let image = trainer.imageId ? trainer.imageId : '';
        let imageUrl = image ? `/api/v2/blobs/${image}/content` : '/content/img/nophoto-2.png';
        return (
            <div className="coach">
                <img className="coach-photo" src={imageUrl} />
                <span className="coach-wrapper">{trainer.displayName}</span>
                <div className="coach-tooltip">
                    <span className="coach-tooltip-title">{trainer.displayName}</span>
                </div>
            </div>
        )
    }
    renderGender(session: StM.SessionStoreState) {
        if (session.playerQualification && session.playerQualification.sex) {
            if (session.type == StM.SessionType.Clinic) {
                return <div className="gender">{session.playerQualification.sex}</div>
            }
        }
        return null;
    }

    renderAge(session: StM.SessionStoreState) {
        if (session.playerQualification && (session.playerQualification.ageLower || session.playerQualification.ageUpper)) {
            let min = session.playerQualification.ageLower;
            let max = session.playerQualification.ageUpper;

            let title: string;
            if (min === -1 && max === -1) {
                title = "All Ages";
            } else if (min && max === -1) {
                title = 'Age from ' + min;
            } else if (min === -1 && max) {
                title = 'Age up to ' + max;
            } else {
                title = "Age " + min + '-' + max;
            }
            return <div className="age">{title}</div>
        }
        return null;
    }

    renderDescription(session: StM.SessionStoreState) {
        if (session && session.notes && session.type == StM.SessionType.Custom) {
            return <div className="description">{session.notes}</div>
        }
        return null;
    }
}