import './clinicItem.scss'

import * as React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import ClassNames from 'classnames';
import * as _ from 'lodash';

import * as ActM from '../../../actions';
import * as StM from '../../../models/store';
import * as PolM from '../../../policies';
import * as SrvM from '../../../services';
import Credits from '../../credits/credits';

let render = new SrvM.SessionInfoDialogRenderService();

interface IClinicItemPageProps {
    isAuthorized: boolean;
    session: StM.ISessionStoreState;
    user: StM.IUserStoreState;
    basket: StM.IBasketStoreState;
    coaches: Array<StM.ICoachStoreState>;
    params: any;
    match: StM.IBookPageRouteParamsMatch;
    club: StM.IClubStoreState;

    showSpinner: () => any;
    hideSpinner: () => any;
    dropOutSession: (session: StM.ISessionStoreState, user: StM.IUserStoreState) => Promise<any>;
    addSessionToBasket: (item: StM.ISessionStoreState) => void;
    getClinics: () => Promise<Array<StM.ISessionStoreState>>;
    updateCurrentPageSessions: (params: any) => Promise<StM.ISessionStoreState>;
    openAuthDialog: (url: string) => Promise<any>;
    showAlert: (msgKey: string) => Promise<any>;
    openSessionInfoDialog: (from: string, id: number) => void;
}

interface IClinicItemPageState { }

class ClinicItem extends React.Component<IClinicItemPageProps, IClinicItemPageState> {
    private utils = new SrvM.Utils();
    private groupInfo = new PolM.GroupInfoPolicy();
    constructor(props: IClinicItemPageProps) {
        super(props);
    }

    render() {
        return (
            <div className="session-item">
                {this.renderSessionTimeBlock(this.props.session)}
                {this.renderSessionDescription(this.props.session, this.props.user, this.props.basket)}
            </div>
        )
    }

    private formatTime(date: moment.Moment) {
        return date.format('h:mm');
    }

    private formatTimeZone(date: moment.Moment) {
        return date.format('a');
    }

    private getSkillClass(skill: string) {
        switch (skill) {
            case StM.UserSkill.Beginner: {
                return 'beginner';
            }
            case StM.UserSkill.Intermediate: {
                return 'intermediate';
            }
            case StM.UserSkill.Advanced: {
                return 'advanced';
            }
            default: {
                return '';
            }
        }
    }

    private dropOutClick(e: any) {
        if (e) { e.stopPropagation(); }
        this.props.showSpinner();
        this.props.dropOutSession(this.props.session, this.props.user)
            .then(() => {
                return this.props.getClinics();
            }).then(() => {
                this.props.hideSpinner();
            }).catch(() => {
                this.props.hideSpinner();
            }).then(() => {
                this.props.updateCurrentPageSessions(this.props.params);
                this.props.showAlert(StM.MessagesKey.DropOutSession);
            });
    }

    private joinClick(e: any) {
        if (e) e.stopPropagation();

        if (!this.props.isAuthorized) {
            let hash = window.location.hash;
            let url = encodeURIComponent(hash);
            this.props.openAuthDialog(url);
        }
        else {
            const pricesPolicy = new PolM.PricesPolicy(this.props.session.isDoubledSession, this.props.session);
            const prices = pricesPolicy.handle();

            this.props.session.credits = prices.credits;

            this.props.addSessionToBasket(this.props.session);
        }
    }

    private getUserBooking(session: StM.ISessionStoreState) {
        for (let i = 0; i < session.bookings.length; i++) {
            let booking = session.bookings[i];
            if (booking.user.id == this.props.user.id) {
                return booking;
            }
        }

        return false;
    }

    private renderCoach(trainer: StM.ICoachStoreState) {
        if (!trainer) return (
            <div className="coach">
                <img className="coach-photo" src={'/content/img/nophoto-2.png'} />
                <span className={ClassNames({ 'coach-wrapper': true, 'assigned-by-club': true })}>Assigned By Club</span>
            </div>
        );

        let image = trainer.imageId ? trainer.imageId : '';
        let imageUrl = image ? `/api/v2/blobs/${image}/content` : '/content/img/nophoto-2.png';
        return (
            <div className="coach">
                <img className="coach-photo" src={imageUrl} />
                <span className="coach-wrapper">{trainer.displayName}</span>
                <div className="coach-tooltip">
                    <span className="coach-tooltip-title">{trainer.displayName}</span>
                </div>
            </div>
        );
    }

    private renderCoachDescription(coach: StM.IUserStoreState) {
        if (!coach) return null;
        return (
            <div className="coach-description" dangerouslySetInnerHTML={{__html: coach.description}}></div>
        );
    }

    private renderDescription(session: StM.ISessionStoreState) {
        if (session.notes) {
            return (
                <div className="coach-description" dangerouslySetInnerHTML={{__html: session.notes}}></div>
            );
        }
        return null;
    }

    private renderSkillLvl(session: StM.ISessionStoreState) {
        if (session.playerQualification && session.playerQualification.skill) {
            let skill = session.playerQualification.skill;
            let skillClass = this.getSkillClass(skill);
            let classes = ClassNames("lvl", skillClass);
            if (!skillClass) skill = 'Any Skill';
            return (
                <div className={classes}>
                    {skill}
                </div>
            )
        }
        return null;
    }

    private renderSlot(session: StM.ISessionStoreState) {
        if (session.maxUserCount < 2) return null;
        let free = session.maxUserCount - Math.min(session.maxUserCount,
            session.bookings.filter(e => e.status === 'CheckedOut').length);
        return (
            <div className="slots">
                {/*<span className="count-free-slots">{free}</span> of <span className="count-all-slots">{session.maxUserCount}</span> Places Free*/}
            </div>
        );
    }

    private renderGender(session: StM.SessionStoreState) {
        if (session.playerQualification && session.playerQualification.sex) {
            if (session.type == StM.SessionType.Clinic) {
                return <div className="gender">{session.playerQualification.sex}</div>
            }
        }
        return null;
    }

    private renderAge(session: StM.SessionStoreState) {
        return session.type === StM.SessionType.Clinic
            ? render.renderAge(session)
            : null
            ;
    }

    private renderSessionDescription(session: StM.ISessionStoreState, user: StM.IUserStoreState, basket: any) {
        const pricesPolicy = new PolM.PricesPolicy(session.isDoubledSession, session);
        const prices = pricesPolicy.handle();
        const priceClass = ClassNames('price-count', {
            'fix-weight-price': this.utils.get_name_browser() == 'Firefox' || this.utils.get_name_browser() == 'Safari'
        });
        const coach = _.find(this.props.coaches, { id: session.trainerId });
        const sessionAvailabilityPolicy = new PolM.SessionAvailabilityPolicy(session, this.props.basket.goods);
        const basketSession = sessionAvailabilityPolicy.getSessionFromBasket();
        const isCourse = session.series && session.series.isCourse;
        const priceTitle = isCourse ? "Total price:" : "Price:";
        const title = isCourse ? `${session.title} (${session.seriesOrder + 1} of ${session.series.sessionCount})` : session.title;

        let credits = session.checkoutCredits;
        let price = prices.price;

        if (basketSession) {
            credits = basketSession.credits ? prices.credits : 0;
            price = !basketSession.credits ? prices.price + prices.additionalPrice + prices.servicesPrice : price 
        }

        const handledPrice = this.utils.formatStringPrice(price.toString());

        return (
            <div className="session-description-wrapper clinic">
                <div className="session-title-wrapper">
                    <div className="clinic-title">{title}</div>
                </div>
                <div className="session-info">
                    {this.renderCoach(coach)}
                    <div className="court">{StM.Strings.CourtPrefix} {session.court.title}</div>
                    {this.renderSlot(session)}
                    {this.renderGender(session)}
                    {this.renderAge(session)}
                    {this.renderSkillLvl(session)}
                </div>
                {this.renderDescription(session)}
                <div className="price">
                    <span className="title">{priceTitle}</span>
                    {!credits && (<span className={priceClass}>${handledPrice}</span>)}
                    {!!credits && (<div className="price-count-club-credits">
                        <Credits credits={credits} type={session.type} />
                        <div className="club-credit-label">Club Credit</div>
                    </div>)}
                    {this.renderAction(session, user, basket)}
                </div>
            </div>
        );
    }

    private renderSessionTimeBlock(session: StM.ISessionStoreState) {
        return (
            <div className="session-time-wrapper">
                <div className="time-start-wrapper">
                    <div className="time-start">{this.formatTime(session.startDateTime)}</div>
                    <div className="time-start-time-type">{this.formatTimeZone(session.startDateTime)}</div>
                </div>
                <div className="time-divider">-</div>
                <div className="time-end-wrapper">
                    <div className="time-end">{this.formatTime(session.endDateTime)}</div>
                    <div className="time-end-time-type">{this.formatTimeZone(session.endDateTime)}</div>
                </div>
            </div>
        );
    }

    private renderAction(session: StM.SessionStoreState, user: StM.IUserStoreState, basket: any) {
        const sessionAvailabilityPolicy = new PolM.SessionAvailabilityPolicy(session, this.props.basket.goods);
        const isOwner = sessionAvailabilityPolicy.getIsOwner();
        const isLateCancelled = _.some(this.props.session.bookings, (b) => b.userId == this.props.user.id && (b.status == StM.BookingStatus.LateCancel || b.status == StM.BookingStatus.LateCancelPayFail));
        const isMaxPlayers = sessionAvailabilityPolicy.getIsMaxPlayers();
        const date = session.series && session.series.isCourse ? session.series.startDate.clone() : session.startDateTime.clone();
        const time = moment.duration(date.format('H:mm'));
        const isFuture = this.utils.getIsFuture(this.props.club, date, time);
        const isClosed = session.status === StM.SessionStatus.Closed;

        if (moment.utc(session.startDateTime).isBefore(moment().utc(), 'day') || isLateCancelled || isMaxPlayers) return null;
        if(this.groupInfo.getIsAuthenticated()) {
            return (<button className="btn-view" onClick={(e) => this.viewSessionClick(e)}>View</button>)
        }
        if (sessionAvailabilityPolicy.getIsBasket()) {
            return (<button className="btn-view" disabled={true}>Added</button>);
        }
        if (sessionAvailabilityPolicy.getIsBoughtUser() && !isClosed) {
            return (<button className="btn-view" onClick={(e) => this.dropOutClick(e)}> {isOwner ? 'Cancel' : 'Drop out'}</button>);
        }

        if (isFuture) {
            return (<button className="btn-view" onClick={(e) => this.joinClick(e)}>Join</button>);
        }

        return null;
    }

    private viewSessionClick(e: any) {
        if (e) { e.preventDefault(); e.stopPropagation(); }
        const id = this.props.session.id || this.props.session.basketId;
        const from = this.props.session.id ? StM.SessionInfoFromTypes.calendar : StM.SessionInfoFromTypes.basket;
        this.props.openSessionInfoDialog(from, id);
    }
}

const mapStateToProps = (state: StM.IGlobalStoreState, ownProps: any) => {
    return {
        isAuthorized: state.app.isAuthorized,
        session: ownProps.session,
        user: ownProps.user,
        basket: state.basket,
        coaches: state.coaches,
        params: ownProps && ownProps.params ? ownProps.params : null,
        club: state.club
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        showSpinner: () => dispatch(ActM.AppActions.showSpinner()),
        hideSpinner: () => dispatch(ActM.AppActions.hideSpinner()),
        dropOutSession: (session: StM.ISessionStoreState, user: StM.IUserStoreState) => dispatch(ActM.SessionInfoDialogActions.dropOut(session)),
        addSessionToBasket: (item: StM.SessionStoreState) => {
            dispatch(ActM.BasketActions.add(item));
            dispatch(ActM.DialogActions.open(StM.DialogNames.SessionCreateSuccess, { isExist: true }))
        },
        getClinics: () => dispatch(ActM.ClinicListViewActions.getClinics()),
        updateCurrentPageSessions: (params: any) => dispatch(ActM.SessionActions.updateForCurrentPage(params)),
        showAlert: (msgKey: string) => dispatch(ActM.DialogActions.open(StM.DialogNames.Alert, { msgKey: msgKey, messageType: StM.MessageTypes.Success })),
        openAuthDialog: (url: string) => dispatch(ActM.DialogActions.open(StM.DialogNames.Auth, { tab: StM.AuthDialogTabs.SignIn, returnUrl: url })),
        openSessionInfoDialog: (from: string, id: number) => dispatch(ActM.DialogActions.open(StM.DialogNames.SessionInfo, { from: from, id: id })),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ClinicItem);