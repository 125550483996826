import './groupsPage.scss';
import * as _ from 'lodash';

import * as React from 'react';

import * as BP from './baseMembersPage';
import { GroupMembersGrid } from '../../../../components/pages/user/groups';

class MembersPage extends React.Component<BP.IMembersPageProps> {
    constructor(props: BP.IMembersPageProps) {
        super(props);
    }

    public render() {
        const group = this.props.group;
        const adminUserId = this.props.adminUserId;
        return (
            <div className="main-content-wrapper profile-pages members-page">
                <section className='page-section'>
                    <header>
                        <h1 className='page-title'>{group.title}</h1>
                        <div className='btns-wrapper'>
                            <button className='btn btn-black' title='Invite a member' onClick={(e) => this.props.onInviteMemberBtnClick(e)}>Invite member</button>
                        </div>
                    </header>
                    <div className='page-list-wrapper'>
                        <GroupMembersGrid
                            adminUserId={adminUserId}
                            data={group.members}
                            onRemove={(member) => this.props.onRemoveMember(member)}
                            onResendInvite={(member) => this.props.onResendInvite(member)}
                            onSwitchAdminAccess={(member) => this.props.onSwitchAdminAccess(member)}
                        />
                    </div>
                </section>
            </div>
        );
    }
}

export default BP.withMembersPage(MembersPage);