import * as React from 'react';
import moment from 'moment';
import * as _ from 'lodash';
import { InputSearchMobile } from './';

interface IDatepickerProps {
    classes?: string;
    title?: string;
    date?: moment.Moment;
    disabled?: boolean;
    onChange?: (date: moment.Moment) => void;
}

interface IDatepickerState {
    date: moment.Moment;
    day: number;
    month: string;
    year: number;
}

class Datepicker extends React.Component<IDatepickerProps, IDatepickerState> {
    minYear: number;
    days: Array<any>;
    monthes: Array<any>;
    years: Array<any>;

    constructor(props: IDatepickerProps) {
        super(props);
        this.minYear = props.date && props.date.year() < 1940 ? props.date.year() : 1940;
        this.monthes = this.getMonthArray();
        this.years = this.getYearsArray(moment().year());
        this.state = this._getStateFromMoment(props.date);
        this.days = [];
        for (var i = 1; i <= 31; i++) {
            this.days.push({ key: i, value: i });
        }
    }

    render() {
        if (this.state.month && this.state.year) {
            let currentDate = (this.state && this.state.date || moment()).clone();
            currentDate.year(this.state.year);
            currentDate.month(this.state.month);
            this.days = this.getDaysArray(currentDate);
        }
        let year = null;
        let month = null;
        let day = null;

        if (this.state.year) { year = _.find(this.years, (i) => i.value === this.state.year); }
        if (this.state.month) { month = _.find(this.monthes, (i) =>  i.value === this.state.month); }
        if (this.state.day) { day = _.find(this.days, (i) => i.value === this.state.day); }

        if (year) year = year.key;
        if (month) month = month.key;
        if (day) day = day.key;

        return (
            <div className={this.props.classes}>
                {!!this.props.title && <label>{this.props.title}</label>}
                <InputSearchMobile
                    array={this.days}
                    placeholder=" "
                    selected={day}
                    onInputBlur={(selected, filter) => this.onDateBlur(selected, filter)}
                    onSelectChange={(item) => this.onDayChange(item)}
                    readOnly={true}
                    disabled={this.props.disabled}
                    classes="day-input-wrapper" />
                <InputSearchMobile
                    array={this.monthes}
                    placeholder=" "
                    selected={month}
                    onSelectChange={(item) => this.onMonthChange(item)}
                    readOnly={true}
                    disabled={this.props.disabled}
                    classes="month-input-wrapper" />
                <InputSearchMobile
                    array={this.years}
                    placeholder=" "
                    selected={year}
                    onInputBlur={(selected, filter) => this.onYearBlur(selected, filter)}
                    onSelectChange={(item) => this.onYearChange(item)}
                    readOnly={true}
                    disabled={this.props.disabled}
                    classes="year-input-wrapper" />
            </div>
        );
    }

    private _getStateFromMoment(date?: moment.Moment): IDatepickerState {
        date = date ? date : null;
        if (date) {
            return {
                date: date
                , day: date.date()
                , month: date.format('MMMM')
                , year: date.year()
            }
        }
        return {
            date: null
            , day: null
            , month: null
            , year: null
        };
    }

    private getDaysArray(date: moment.Moment) {
        let days: Array<any> = [];
        let lastDay = date.daysInMonth();
        for (let i = 1; i <= lastDay; i++) {
            days.push({ key: i, value: i });
        }
        return days;
    }

    private getYearsArray(current: number) {
        let years: Array<any> = [];
        for (let i = current; i >= this.minYear; i--) {
            years.push({ key: i, value: i });
        }
        return years;
    }

    private getMonthArray() {
        let monthes = moment.months();
        let result: Array<any> = [];
        for (let i = 0; i < monthes.length; i++) {
            result.push({ key: i + 1, value: monthes[i] })
        }
        return result;
    }

    private onDayChange(item: any) {
        this.setDate(this.state.year, this.state.month, item.value);
    }

    private onMonthChange(item: any) {
        this.setDate(this.state.year, item.value, this.state.day);
    }

    private onYearChange(item: any) {
        this.setDate(item.value, this.state.month, this.state.day);
    }

    private onDateBlur(selected: any, filter: string) {
        if (filter) {
            let day = +filter;
            if (isNaN(day)) {
                this.onDayChange(selected)
            } else if (day > this.state.date.daysInMonth()) {
                let day = this.days.slice().pop();
                this.onDayChange(day);
            } else {
                let item = _.find(this.days, (i) => i.value === day);
                if (item) this.onDayChange(item);
            }
        }
    }

    private onYearBlur(selected: any, filter: string) {
        if (filter) {
            let year = +filter;
            if (!isNaN(year)) {
                let item = _.find(this.years, (i) => i.value === year);
                if (item) {
                    this.onYearChange(item)
                } else if (year > 1900 && year < this.minYear) {
                    this.minYear = year;
                    this.years = this.getYearsArray(moment().year());
                    this.onYearChange({ value: year })
                }
            }
        }
    }

    private setDate(year: number, month: string, day: number) {
        if (year && month && day) {
            let date = moment();
            date.year(year);
            date.month(month);
            if (day > date.daysInMonth()) { day = date.daysInMonth(); }
            date.date(day);
            this.setState({ ...this.state, ...{ date: date } });
            if (this.props.onChange && date) { this.props.onChange(date); };
        }
        this.setState({ ...this.state, ...{ year: year, month: month, day: day } });
    }
}

export default Datepicker;
