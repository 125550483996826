import * as React from 'react';
import { connect } from 'react-redux';

import { SrvM, ActM, StM } from '../../../modules';

interface IPricingPageProps {
    isFinalized: boolean;
    priceList: string;

    init: () => void;
    getPricing: () => Promise<any>;
    closeBanner: () => void;
    goHome: () => void;
}

interface IPricingPageState {
    html: string;
}

class PricingPage extends React.Component<IPricingPageProps, IPricingPageState> {
    private routeSrv = new SrvM.RouteService();
    constructor(props: IPricingPageProps) {
        super(props);
        this.state = {
            html: null,
        };
    }

    componentDidMount() {
        this.init(this.props);
    }

    componentDidUpdate(prevProps: IPricingPageProps) {
        const { isFinalized } = this.props;
        if (isFinalized !== prevProps.isFinalized) {
            this.init(this.props);
        }
    }

    public render() {
        return <div className='pricing-page-wrapper' dangerouslySetInnerHTML={{ __html: this.state.html }}></div>;
    }

    private init(props: IPricingPageProps) {
        if (!props.isFinalized) return;
        if (!!location.hash) props.closeBanner();
        if (!props.priceList) props.goHome();

        props.getPricing().then((response) => {
            this.setState({ html: response }, () => {
                props.init();
                this.routeSrv.scrollHashIntoView(300);
            });
        });
    }
}

const mapStateToProps = (state: StM.IGlobalStoreState) => ({
    isFinalized: state.app.isFinalized,
    priceList: state.club.priceList,
});

const mapDispatchToProps = (dispatch: any) => ({
    init: () => dispatch(ActM.PricingPageActions.init()),
    getPricing: () => dispatch(ActM.AppActions.getPricing()),
    closeBanner: () => dispatch(ActM.BannerActions.close()),
    goHome: () => dispatch(ActM.RouteActions.goHome()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PricingPage);
