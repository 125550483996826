import './coachPage.mobile.scss';
import _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import * as ActM from '../../actions';
import * as SrvM from '../../services';
import * as StM from '../../models/store';
import { CoachInfoMobile } from '../../components/pages/coach/coachInfo.mobile';

interface ICoachPageProps {
    init: () => void;
    coaches: Array<StM.ICoachStoreState>;
    coachFeeTiers: Array<StM.ICoachFeeTierStoreState>;
    go: (url: string) => any;
}

interface ICoachPageState {
    orderedCoaches: Array<StM.ICoachStoreState>;
}

class CoachPageMobile extends React.Component<ICoachPageProps, ICoachPageState> {
    private utils: SrvM.Utils;

    constructor(props: ICoachPageProps) {
        super(props);
        this.utils = new SrvM.Utils();
        this.state = { orderedCoaches: [] };
    }

    componentDidMount() {
        this.props.init();
        const orderedCoaches = this.utils.getOrderedCoaches(this.props.coaches, this.props.coachFeeTiers);
        this.setState({ orderedCoaches: orderedCoaches });
    }

    componentDidUpdate(prevProps: ICoachPageProps) {
        if (!_.isEqual(this.props.coaches, prevProps.coaches) || !_.isEqual(this.props.coachFeeTiers, prevProps.coachFeeTiers)) {
            const orderedCoaches = this.utils.getOrderedCoaches(this.props.coaches, this.props.coachFeeTiers);
            this.setState({ orderedCoaches: orderedCoaches });
        }
    }

    render() {
        const coaches = this.state.orderedCoaches;

        return (
            <div className="mobile-row">
                <div className="mobile-col-12">
                    <div className="main-content-wrapper-mobile coaches-page">
                        <div className="page-title-mobile coach-mobile">Coaches</div>
                        {coaches.map((coach, i) => {
                            return (
                                <div className="coaches-wrapper-mobile" key={i}>
                                    <CoachInfoMobile coach={coach} go={(url) => this.props.go(url)} />
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        )
    };
}

function mapStateToProps(state: StM.IGlobalStoreState, ownProps: any) {
    return {
        coaches: state.coaches
        , coachFeeTiers: state.coachFeeTiers
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        init: () => dispatch(ActM.CoachPageActions.init()),
        go: (url: string) => dispatch(ActM.RouteActions.push(url))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CoachPageMobile);
