import * as React from 'react';
import ClassNames from 'classnames';
import * as _ from 'lodash';

import * as StM from '../../../models/store';

interface ICourtSelectorMobileProps {
    courts: Array<StM.ICourtStoreState>;
    youtubeIds: Array<StM.IYoutubeIdStoreState>;
    selectedYoutubeId: StM.IYoutubeIdStoreState;

    onCourtSelected?: (youtubeId: StM.IYoutubeIdStoreState) => void;
}

export class CourtSelectorMobile extends React.Component<ICourtSelectorMobileProps> {
    constructor(props: ICourtSelectorMobileProps) {
        super(props);
    }

    shouldComponentUpdate(newProps: ICourtSelectorMobileProps) {
        const isCourtsChanged = !_.isEqual(this.props.courts, newProps.courts);
        const youtubeIdsChanged = !_.isEqual(this.props.youtubeIds, newProps.youtubeIds);
        const isSelectedYoutubeIdChanged = !_.isEqual(this.props.selectedYoutubeId, newProps.selectedYoutubeId);

        return isCourtsChanged || youtubeIdsChanged || isSelectedYoutubeIdChanged;
    }


    render() {
        const selectedYoutubeId = this.props.selectedYoutubeId;
        const availableCourts = this.props.youtubeIds.map(id => id.courtId);
        return (
            <div className="menu-block court-selector-mobile">
                <span className="menu-item menu-header">Select court</span>
                {this.props.courts.map((court) => {
                    let courtTitle = `${StM.Strings.CourtPrefix} ${court.title}`;
                    let key = court.id;
                    const currentYoutubeId = _.find(this.props.youtubeIds, (i) => i.courtId === court.id);
                    const isAvailableCourt = _.includes(availableCourts, court.id);
                    // if (isAvailableCourt) {
                    //     // return court.cameras.map((cam, index) => {
                    //     //     let title = courtTitle;
                    //     //     let courtClasses = ClassNames('menu-item court-wrapper', {
                    //     //         'not-available': !court.isAvailable || !cam.isActive
                    //     //     });
                    //     //     if (court.cameras.length > 1) {
                    //     //         title = "{0} - {1}".format(title, cam.name);
                    //     //         key = key + cam.name;
                    //     //         courtClasses += (court.code == selectedCourtId
                    //     //             && cam.side == selectedCamId) ? ' active' : '';
                    //     //     } else {
                    //     //         courtClasses += (court.code == selectedCourtId) ? ' active' : '';
                    //     //     }

                    //     //     return (
                    //     //         <div className={courtClasses} key={key} onClick={(e) => (court.isAvailable && cam.isActive) ? this.onSelectCourt(e, currentYoutubeId) : void (0)}>
                    //     //             <span className="court-title">{title}</span>
                    //     //             <i className="icon"></i>
                    //     //         </div>
                    //     //     );
                    //     // });
                    // } else {
                    let courtClasses = ClassNames('menu-item court-wrapper', {
                        'not-available': !isAvailableCourt,
                        'active': selectedYoutubeId && selectedYoutubeId.courtId == court.id
                    });
                    return (
                        <div className={courtClasses} key={key} onClick={(e) => isAvailableCourt ? this.onSelectCourt(e, currentYoutubeId) : void (0)}>
                            <span className="court-title">{courtTitle}</span>
                            <i className="icon"></i>
                        </div>
                    );
                    //}
                })}
            </div>
        )
    };

    private onSelectCourt(e: any, youtubeId: StM.IYoutubeIdStoreState) {
        if (e) { e.preventDefault(); e.stopPropagation(); }
        if (this.props.onCourtSelected) {
            this.props.onCourtSelected(youtubeId);
        }
    }
};