import './coachPage.scss';
import * as React from 'react';
import { connect } from 'react-redux';
import * as ActM from '../../actions';
import * as SrvM from '../../services';
import * as StM from '../../models/store';
import _ from 'lodash';
import { CoachInfo } from '../../components/pages/coach/coachInfo';

interface ICoachPageProps {
    init: () => void;
    coaches: Array<StM.ICoachStoreState>;
    coachFeeTiers: Array<StM.ICoachFeeTierStoreState>;
    go: (url: string) => any;
}

interface ICoachPageState {
    orderedCoaches: Array<StM.ICoachStoreState>;
    defaultCoach: StM.ICoachStoreState;
}

class CoachPage extends React.Component<ICoachPageProps, ICoachPageState> {
    private utils: SrvM.Utils;

    constructor(props: ICoachPageProps) {
        super(props);
        this.utils = new SrvM.Utils();
        this.state = { orderedCoaches: [], defaultCoach: null};
    }

    componentDidMount() {
        this.props.init();
        const orderedCoaches = this.utils.getOrderedCoaches(this.props.coaches, this.props.coachFeeTiers);
        const defaultCoach = this.utils.getDefaultCoach(this.props.coaches, this.props.coachFeeTiers);
        this.setState({ orderedCoaches: orderedCoaches, defaultCoach: defaultCoach });
    }

    componentDidUpdate(prevProps: ICoachPageProps) {
        if (!_.isEqual(this.props.coaches, prevProps.coaches) || !_.isEqual(this.props.coachFeeTiers, prevProps.coachFeeTiers)) {
            const orderedCoaches = this.utils.getOrderedCoaches(this.props.coaches, this.props.coachFeeTiers);
            const defaultCoach = this.utils.getDefaultCoach(this.props.coaches, this.props.coachFeeTiers);
            this.setState({ orderedCoaches: orderedCoaches, defaultCoach: defaultCoach });
        }
    }

    render() {
        const coaches = this.state.orderedCoaches;

        return (
            <div className="main-content-wrapper coaches-page">
                <div className="page-title coach">Coaches</div>
                {coaches.map((coach, i) => {
                    return (
                        <div className="coaches-wrapper" key={i} >
                            <CoachInfo go={(url) => this.props.go(url)} coach={coach} />
                        </div>
                    );
                })}
            </div>
        )
    };
}

function mapStateToProps(state: StM.IGlobalStoreState, ownProps: any) {
    return {
        coaches: state.coaches
        , coachFeeTiers: state.coachFeeTiers
     }
}

function mapDispatchToProps(dispatch: any) {
    return {  
        init: () => dispatch(ActM.CoachPageActions.init()),
        go: (url: string) => dispatch(ActM.RouteActions.push(url))
      }
}

export default connect(mapStateToProps, mapDispatchToProps)(CoachPage);
