import { ISubscriptionCurrentCredit } from './subscriptionCurrentCredits';
import { ISubscriptionCurrent, SubscriptionCurrentStatus } from './subscriptionCurrentCard';

const toCurrentCredits = (balance: ISessionCreditsDto[], offerCredits: IRecurrentPackageCreditsDefinitionDto[]) => {
    return offerCredits.map(offerCredit => {
        const creditBalance = balance.find(b => b.packageSessionTypeId === offerCredit.packageSessionTypeId && b.typeAlias === offerCredit.typeAlias);
        return {
            title: offerCredit.title,
            description: offerCredit.description,
            iconColor: offerCredit.color,
            creditBalance: creditBalance?.credits || 0,
            creditAmnt: offerCredit.credits,
            sessionType: creditBalance?.packageSessionType?.sessionType,
            bgColor: offerCredit.color,
            txtColor: '',
            typeAlias: offerCredit.typeAlias
        } as ISubscriptionCurrentCredit
    })
}

export const toSubscriptionCurrent = (subscription: IRecurrentPackageCommitmentDetailsDto): ISubscriptionCurrent => {
    return {
        id: subscription.id,
        title: subscription.currentOffer.title,
        description: subscription.currentOffer.description,
        price: subscription.currentOffer.price,
        credits: toCurrentCredits(subscription.creditBalance, subscription.currentOffer.creditsDefinitions),
        billingPeriod: subscription.currentOffer.billingPeriod,
        status: subscription.status as SubscriptionCurrentStatus,
        nextRenewDate: subscription.nextRenewDate,
        endDate: subscription.endDate,
        billingAlerts: subscription.billingAlerts,
        subscriptionAlerts: subscription.subscriptionAlerts,
    }
}
