import '../dialogs.mobile.scss';
import './bookDialog.mobile.scss';

import * as React from 'react';
import * as _ from 'lodash';
import ClassNames from 'classnames';
import { Link } from 'react-router-dom';

import { StM, SrvM } from '../../../modules';
import * as BD from './baseBookDialog';
import { ModalMobile } from '../../../components';
import { BookDialogItemMobile, BookPackageDialogItemMobile } from '../../../components/basket/index.mobile';

interface BookDialogProps extends BD.IBookDialogProps {}

class BookDialogMobile extends React.Component<BookDialogProps> {
    private utils = new SrvM.Utils();
    constructor(props: BookDialogProps) {
        super(props);
    }

    public render() {
        if (!this.props.isShown) return null;
        const prices = this.props.basketPolicy.getBasketPrices();
        const hasDiscount = prices.discountPercentage > 0  && (0 + prices.discountPrice) && (!!prices.subtotalPrice || !!prices.totalServicesPrice);
        const isValidBasket = this.props.basketPolicy.handle();

        const totalPriceClasses = ClassNames('summary-wrapper-mobile text-right', {
            'price-total': !!prices.totalSalesTaxesPrice,
        });

        const modalClasses = ClassNames('your-estimated-charges-modal-mobile', { error: !isValidBasket });

        return (
            <ModalMobile classes={modalClasses} closeHandler={() => this.props.onCloseClick()} dialogName={StM.DialogNames.Book}>
                <div className='modal-dialog-header'>
                    <div className='title'>Checkout</div>
                </div>
                <div className='modal-dialog-body-block'>
                    <p className="book-note">
                        {StM.Strings.BookingNote(this.props.club)}
                        {!!this.props.club.priceList && (
                            <>
                                &nbsp;For more details visit our <Link to={StM.Pages.Pricing}>Pricing</Link> page.
                            </>
                        )}
                    </p>
                </div>
                <ul className='estimated-charges-list-wrapper-mobile'>
                    {this.props.basketPackages.map((item: StM.PackageDefinitionStoreState) => {
                        return (
                            <BookPackageDialogItemMobile
                                item={item}
                                key={item.id}
                                removeItem={(id) => this.props.onRemovePackageItemClick(id)}
                            />
                        );
                    })}
                    {this.props.basketSessions.map((item: StM.SessionStoreState) => {
                        return (
                            <BookDialogItemMobile
                                user={this.props.user}
                                item={item}
                                key={item.basketId}
                                removeItem={(id) => this.props.onRemoveItemClick(id)}
                            />
                        );
                    })}
                </ul>
                <div className='modal-dialog-footer'>
                    <div className='summary-wrapper-mobile text-right'>
                        {(!!hasDiscount || !!prices.totalSalesTaxesPrice || (!!prices.totalServicesPrice && prices.subtotalPrice)) && (
                            <div>
                                <div className='labels-wrapper-mobile'>
                                    <div className='mobile-row label-mobile total-mobile text-right'>Price</div>
                                </div>
                                <div className='prices-wrapper-mobile'>
                                    <div className='mobile-row price-mobile total-mobile text-right'>
                                        ${this.utils.formatStringPrice(prices.subtotalPrice.toString())}
                                    </div>
                                </div>
                            </div>
                        )}
                        {!!prices.totalServicesPrice && (
                            <div>
                                <div className='labels-wrapper-mobile'>
                                    <div className='mobile-row label-mobile total-mobile text-right'>Services</div>
                                </div>
                                <div className='prices-wrapper-mobile'>
                                    <div className='mobile-row price-mobile total-mobile text-right'>
                                        ${this.utils.formatStringPrice(prices.totalServicesPrice.toString())}
                                    </div>
                                </div>
                            </div>
                        )}
                        {!!hasDiscount && (
                            <div>
                                <div className='labels-wrapper-mobile'>
                                    <div className='mobile-row label-mobile total-mobile text-right'>
                                        {`Discount (${prices.discountPercentage}%)`}
                                    </div>
                                </div>
                                <div className='prices-wrapper-mobile'>
                                    <div className='mobile-row price-mobile total-mobile text-right'>
                                        ${this.utils.formatStringPrice(prices.discountPrice.toString())}
                                    </div>
                                </div>
                            </div>
                        )}
                        {!!prices.totalSalesTaxesPrice && (
                            <div>
                                <div className='labels-wrapper-mobile'>
                                    <div className='mobile-row label-mobile total-mobile text-right'>
                                        {`${this.props.club.salesTaxTitle} (${this.props.club.salesTaxRate}%)`}
                                    </div>
                                </div>
                                <div className='prices-wrapper-mobile'>
                                    <div className='mobile-row price-mobile total-mobile text-right'>
                                        ${this.utils.formatStringPrice(prices.totalSalesTaxesPrice.toString())}
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className={totalPriceClasses}>
                            <div className='labels-wrapper-mobile'>
                                <div className='mobile-row label-mobile total-mobile text-right'>Total</div>
                            </div>
                            <div className='prices-wrapper-mobile'>
                                <div className='mobile-row price-mobile total-mobile text-right'>
                                    ${this.utils.formatStringPrice(prices.totalPrice.toString())}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='btns-wrapper'>
                        <button className='btn-mobile btn-checkout btn-red' onClick={(e) => this.props.onCheckoutClick(e)} disabled={!isValidBasket}>
                            Complete purchase
                        </button>
                    </div>
                </div>
            </ModalMobile>
        );
    }
}

export default BD.withBookDialog(BookDialogMobile);
