import * as _ from 'lodash';

import { IBasePolicy, BasePolicy } from './basePolicy';
import { PricesPolicy } from './pricesPolicy';
import { SessionAvailabilityPolicy } from './sessions/sessionAvailabilityPolicy';
import * as StM from '../models/store';

export class BasketPolicy extends BasePolicy implements IBasePolicy {
    constructor(private club: StM.IClubStoreState, private basket: StM.IBasketStoreState, private user: StM.IUserStoreState = null) {
        super();
    }

    public handle(): boolean {
        return this.getIsValid();
    }

    public getIsValid(): boolean {
        return (
            this.basket &&
            !_.some(this.basket.goods, (x: StM.ISessionStoreState) => this.getHasValidationErrors(x.validationResult)) &&
            !_.some(this.basket.packages, (x: StM.PackageDefinitionStoreState) => this.getHasValidationErrors(x.validationResult))
        );
    }

    public getBasketPrices(useCheckout: boolean = true): StM.IBasketPrices {
        const results: StM.IBasketPrices = {
            subtotalPrice: 0,
            totalSalesTaxesPrice: 0,
            totalServicesPrice: 0,
            discountPercentage: this.user && this.user.actualDetails ? this.user.actualDetails.discount : 0,
            discountPrice: 0,
            totalPrice: 0,
        };

        this.applySessionsPrices(results, useCheckout);
        this.applyPackagesPrices(results);

        results.discountPrice = +this.utils.formatStringPrice(
            (((results.discountPrice) * results.discountPercentage) / 100).toString(),
        );
        results.totalPrice =
            Math.round(+(results.subtotalPrice - results.discountPrice + results.totalSalesTaxesPrice + results.totalServicesPrice) * 100) / 100;

        return results;
    }

    public getBasketSession(session: StM.ISessionStoreState): StM.ISessionStoreState {
        return session && this.basket && _.find(this.basket.goods, (basketItem) => {
            const isCourse = basketItem.series && basketItem.series.isCourse
                && session.series && basketItem.series.id === session.series.id;
            return basketItem.id === session.id || isCourse;
        });
    }

    private getHasValidationErrors(validationResult: IValidationResultDto): boolean {
        return !!validationResult && !!validationResult.errors && !!validationResult.errors.length;
    }

    private applySessionsPrices(source: StM.IBasketPrices, useCheckout: boolean = true) {
        if (!this.basket || !this.club) return;
        const user = this.getCurrentUser();
     
        for (const item of this.basket.goods) {
            const pricesPolicy = new PricesPolicy(item.isDoubledSession, item);
            const sessionAvailability = new SessionAvailabilityPolicy(item, []);
            const prices = pricesPolicy.getSessionPrices(user);
            const hasGroupBookings = !!sessionAvailability.getSessionGroupMemberBookings(false, true).length;
    
            let handledPrice = 0;
            
            if (!item.credits || _.some(item.bookings, (b) => b.id == 0 && b.paymentType == StM.PaymentTypes.Charge)) {
                handledPrice = useCheckout 
                    ? item.checkoutPrice + (hasGroupBookings && !item.splitPrice ? prices.price : 0)
                    : prices.price + prices.additionalPrice;
                handledPrice = this.utils.handleMinPrice(handledPrice);
                source.totalPrice = handledPrice;
            }

            source.totalServicesPrice += prices.servicesPrice;
            source.subtotalPrice += handledPrice;
            source.discountPrice += handledPrice + prices.servicesPrice;
            
            const salesTax = _.find(this.club.salesTaxes, (tax) => tax.subject.toLowerCase() === item.type.toLowerCase());
            source.totalSalesTaxesPrice +=
                _.round(((!!salesTax ? (handledPrice + prices.servicesPrice) : prices.servicesPrice) * (100 - source.discountPercentage) * this.club.salesTaxRate) / 10000, 2);
        }
    }

    private applyPackagesPrices(source: StM.IBasketPrices) {
        if (!this.basket || !this.club) return;
        for (const item of this.basket.packages) {
            const itemPrice = this.utils.handleMinPrice(item.price);
            source.subtotalPrice += itemPrice;
            source.totalPrice += itemPrice;

            const shouldApplyTaxes = _.some(item.creditsDefinitions, (x) =>
                _.some(this.club.salesTaxes, (y) => y.subject.toLowerCase() === x.packageSessionType.sessionType.toLowerCase()),
            );

            if (shouldApplyTaxes) {
                const packageSalesTax = _.round((itemPrice * this.club.salesTaxRate) / 100, 2);
                source.totalSalesTaxesPrice += packageSalesTax;
                source.totalPrice += packageSalesTax;
            }
        }
    }
}
