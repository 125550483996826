import './sessionPrices.scss';

import * as React from 'react';
import ClassNames from 'classnames';

import { StM, PolM } from '../../../modules';
import { SessionPrice } from '../../../components/session';

interface IProps {
    session: StM.ISessionStoreState;
    className?: string;
    pricingTier?: StM.IPricingTierStoreState;
}

const getSessionPrices = (session: StM.ISessionStoreState, pricingTier: StM.IPricingTierStoreState) => {
    const pricesPolicy = new PolM.PricesPolicy(session.isDoubledSession, session, pricingTier);
    return pricesPolicy.handle();
};

const getSessionPriceNote = (session: StM.ISessionStoreState) => {
    const sessionInfoPolicy = new PolM.SessionInfoPolicy(session);
    if (!sessionInfoPolicy.getIsCanShowPriceDescription()) return null;

    const sessionAvailabilityPolicy = new PolM.SessionAvailabilityPolicy(session);
    const isGroupSession = sessionAvailabilityPolicy.getIsGroupSession();
    const isOwner = sessionAvailabilityPolicy.getIsOwner();
    const isGroupMemberOwner = sessionAvailabilityPolicy.getIsOwnerGroupMember();
    const isNew = !session.id;
    const isShared = !session.isPaidByOwner;

    let note = isShared ? StM.Strings.SharedPriceNote : StM.Strings.PaidByOwnerNote;
    if (isNew) return note;

    if (isGroupSession && !isShared) {
        if (session.invitedUsers.length) note =  StM.Strings.GroupPaidByOwnerNote;
        else if (!isGroupMemberOwner) note = '';
    } else if (!isOwner) {
        note = session.isPaidByOwner ? StM.Strings.PaidByOwnerNoteForJoining : StM.Strings.SharedPriceNoteForJoining;
    }

    return note;
};

export class SessionPrices extends React.Component<IProps> {
    constructor(props: IProps) {
        super(props);
    }

    public render() {
        const { session, pricingTier } = this.props;
        if (!session) return null;

        const prices = getSessionPrices(session, pricingTier);
        const isCredits = !!prices.credits && !!session.credits;
        const classes = ClassNames('session-prices', this.props.className);
        return (
            <section className={classes}>
                <SessionPrice
                    title={StM.Strings.SessionPrice}
                    sessionType={session.type}
                    sessionBgColor={session.customBackgroundColor}
                    sessionTxtColor={session.customTextColor}
                    amount={isCredits ? prices.credits : prices.price}
                    isCredits={isCredits}
                    additional={isCredits ? prices.additionalCredits : prices.additionalPrice}
                    note={getSessionPriceNote(session)}
                />
                {!!prices.servicesPrice && (
                    <SessionPrice title={StM.Strings.ServicesPrice} sessionType={session.type} amount={prices.servicesPrice} />
                )}
            </section>
        );
    }
}
