import './baseBadge.scss';
import './naBadge.scss';
import * as React from 'react';
import moment from 'moment';
import { StM } from '../../../../modules';
import { IBaseBadgeProps, IBaseBadge, DurationClasses, CommonBadge } from './baseBadge';

interface INABadgeProps extends IBaseBadgeProps {
    court?: StM.ICourtStoreState;
}

interface INABadgeState { }

export class NABadge extends React.Component<INABadgeProps, INABadgeState> implements IBaseBadge {
    constructor(props: INABadgeProps) {
        super(props);
    }

    render() {
        const durationClass = DurationClasses.getDurationClass(this.props.time.duration);
        const sessionTime = moment.utc(this.props.time.start.asMilliseconds()).format('HH-mm');
        const classes = 'table-column-item-wrapper n-a-cell ' + durationClass;
        const styles: any = {
            height: CommonBadge.getBadgeHeightByDuration(this.props.time.duration)
        };
        const slotId = `court-${this.props.court?.id}-time-${sessionTime}`;
        
        return (
            <div className={classes} style={styles} data-testid={slotId}>
                <div className="table-column-item">
                    {/* n/a */}
                </div>
            </div>
        )
    };
};
