import * as React from 'react';
import moment from 'moment';

import { SrvM, StM, PolM } from '../../modules';
import Credits from '../../components/credits/credits';

export interface IBaseBasketItemProps {
    session: StM.ISessionStoreState;
    user: StM.IUserStoreState;

    removeItem: (id: number) => void;
    onBasketSessionClick: (basketSession: StM.ISessionStoreState) => void;
}

export default class BaseBasketItem<TProps extends IBaseBasketItemProps> extends React.Component<TProps> {
    protected utils = new SrvM.Utils();

    constructor(props: TProps) {
        super(props);
    }

    // renderers

    protected renderCoach() {
        const coach = this.props.session.trainer;
        if (!coach) return null;
        return (
            <div className="coach-wrapper">
                <span className="coach">{coach.displayName}</span>
            </div>
        );
    }

    protected renderDateTime() {
        const session = this.props.session;
        const start = session.startDateTime.clone();
        const end = session.endDateTime.clone();

        return (
            <div className="date-wrapper">
                <div className="date">{session.startDateTime.date()}</div>
                <div className="month">{moment.monthsShort(session.startDateTime.month())},</div>
                <div className="time-start-wrapper">
                    <div className="time-start">{start.format('h:mm')}</div>
                    <div className="time-start-time-type">{start.format('a')}</div>
                </div>
                <div className="time-divider">&ndash;</div>
                <div className="time-end-wrapper">
                    <div className="time-end">{end.format('h:mm')}</div>
                    <div className="time-end-time-type">{end.format('a')}</div>
                </div>
            </div>
        );
    }

    protected renderCourt() {
        return <div className="court">{this.utils.getSessionCourtsTitle(this.props.session)}</div>;
    }

    protected renderAdditionalPrice(prices: StM.SessionPrices) {
        if (!this.props.session.credits || !(prices.additionalPrice || prices.servicesPrice)) return null;
        return <span className="additional-price">+ ${prices.additionalPrice + prices.servicesPrice}</span>;
    }

    protected renderCredits(prices: StM.SessionPrices) {
        const session = this.props.session;
        
        if(!this.props.session.credits) return null;

        let credits = prices.credits + prices.additionalCredits;
        const creditsBgColor = session.type === StM.SessionType.Custom ? session.customBackgroundColor : '';
        return <Credits credits={credits} type={session.type} bgColor={creditsBgColor} small />;
    }

    protected renderPrice() {
        const pricesPolicy = new PolM.PricesPolicy(this.props.session.isDoubledSession, this.props.session);
        const prices = pricesPolicy.getSessionPrices(this.props.user);
        
        return (
            <div className="price-wrapper">
                {!this.props.session.credits && (
                    <div className="price">${this.getPriceString(prices)}</div>
                )}
                {this.renderCredits(prices)}
                {this.renderAdditionalPrice(prices)}
            </div>
        );
    }

    // helpers

    protected getIsCourse(): boolean {
        return this.props.session.series && this.props.session.series.isCourse;
    }

    protected getPriceString(prices: StM.SessionPrices): string {
        let handledPrice = prices.price;
     
        if (!this.props.session.credits) handledPrice += prices.additionalPrice + prices.servicesPrice;
        return this.utils.formatStringPrice(handledPrice.toString());
    }

    protected getCourseTitle(): string {
        if(!this.getIsCourse()) return null;
        return `${this.props.session.series.sessionCount} Sessions`;
    }

    // handlers

    protected onRemoveItemClick(e: any) {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        this.props.removeItem(this.props.session.basketId);
    }

    protected onItemClick(e: any) {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        this.props.onBasketSessionClick(this.props.session);
    }
}
