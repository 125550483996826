import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import * as StM from '../../../models/store';
import * as ActM from '../../../actions';

interface IGroupInfoDialogProps {
    from: StM.GroupInfoFromType;
    inviteToken: string;
    isAuthorized: boolean;
    user: StM.IUserStoreState;
    isFinalized: boolean;
    isAuthorizing: boolean;

    closeDialog: () => void;
    acceptInvitation: (token: string) => Promise<void>;
    rejectInvitation: (token: string) => Promise<void>;
    openAuthDialog: (returnUrl: string) => void;
    showConfirm: (msg: string, yesCallback: () => void, noCallback: () => void, yesBtnTxt?: string, noBtnTxt?: string, showExitBtn?: boolean) => Promise<any>;
    getGroupByInviteToken: (token: string) => Promise<StM.IGroupStoreState>;
    closeConfirm: () => void;
}

class GroupInfoDialog extends React.Component<IGroupInfoDialogProps> {
    constructor(props: IGroupInfoDialogProps) {
        super(props);
    }

    componentDidMount() {
        this.init(this.props);
    }

    componentDidUpdate(prevProps: IGroupInfoDialogProps) {
        const isFromChanged = prevProps.from !== this.props.from;
        const isInviteTokenChanged = prevProps.inviteToken !== this.props.inviteToken;
        const isFinalizedChanged = prevProps.isFinalized !== this.props.isFinalized;
        const isAuthorizedChanged = prevProps.isAuthorized !== this.props.isAuthorized;
        const isAuthorizingChanged = prevProps.isAuthorizing !== this.props.isAuthorizing;

        if (isFromChanged || isInviteTokenChanged || isFinalizedChanged || isAuthorizedChanged || isAuthorizingChanged) {
            this.init(this.props);
        }
    }

    public render(){
        return null;
    }

    private init(props: IGroupInfoDialogProps) {
        if(!props.from || !props.inviteToken || !props.isFinalized || props.isAuthorizing) {
            return;
        }

        switch(props.from) {
            case StM.GroupInfoFromType.InviteAcceptLink:
                if(!props.isAuthorized) {
                    props.openAuthDialog(encodeURIComponent(window.location.hash));
                    props.closeDialog();
                    return;
                }
                this.acceptInvitationLink(props.inviteToken);
                break;
            case StM.GroupInfoFromType.InviteRejectLink:
                this.rejectInvitationLink(props.inviteToken);
                break;
            default:
                props.closeDialog();
                break;
        }
    }

    private acceptInvitationLink(token: string) {
        this.props.acceptInvitation(token)
            .then(() => {
                this.props.closeDialog();
            }).catch(() => {
                this.props.closeDialog();
            });
    }

    private rejectInvitationLink(token: string) {
        this.props.rejectInvitation(token)
            .then(() => {
                this.props.closeDialog();
            }).catch(() => {
                this.props.closeDialog();
            });
    }
}

const mapStateToProps = (state: StM.IGlobalStoreState, ownProps: any) => ({
    from: state.dialogs.groupInfo.from,
    inviteToken: state.dialogs.groupInfo.inviteToken,
    isAuthorized: state.app.isAuthorized,
    user: state.user,
    isFinalized: state.app.isFinalized,
    isAuthorizing: state.app.isAuthorizing,
});

const mapDispatchToProps = (dispatch: any) => ({
    closeDialog: () => dispatch(ActM.DialogActions.close(StM.DialogNames.GroupInfo)),
    acceptInvitation: (inviteToken: string) => dispatch(ActM.GroupAccountActions.acceptInvitation(inviteToken)),
    rejectInvitation: (inviteToken: string) => dispatch(ActM.GroupAccountActions.rejectInvitation(inviteToken)),
    openAuthDialog: (returnUrl: string) => dispatch(ActM.DialogActions.open(StM.DialogNames.Auth, { tab: StM.AuthDialogTabs.SignIn, returnUrl })),
    showConfirm: (msg: string, yesCallback: () => void, noCallback: () => void, yesBtnTxt?: string, noBtnTxt?: string, showExitBtn?: boolean) =>
        dispatch(ActM.DialogActions.open(StM.DialogNames.Confirm, { msg, yesCallback, noCallback, yesBtnTxt, noBtnTxt, showExitBtn })),
    getGroupByInviteToken: (token: string) => dispatch(ActM.GroupAccountActions.getGroupByInviteToken(token)),
    closeConfirm: () => dispatch(ActM.DialogActions.close(StM.DialogNames.Confirm)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupInfoDialog);