import './calendar.scss';
import * as React from 'react';
import moment from 'moment';
import * as _ from 'lodash';
import * as StM from '../../../../models/store';
import TimeBoard from '../timeBoard/timeBoard';
import CourtBoard from './courtBoard';

interface ICalendarProps {
    club: StM.IClubStoreState;
    timeSlots: Array<StM.ICourtTimeSlotStoreState>;
    date: moment.Moment;
    topOffset?: number;
}

interface ICalendarState {
}

class Calendar extends React.Component<ICalendarProps, ICalendarState> {
    constructor(props: ICalendarProps) {
        super(props);
    }

    render() {
        const topOffset = this.props.topOffset || 0;
        return (
            <div className="content-dashboard-wrapper" style={{marginTop: `${topOffset}px`}}>
                <div className="table-dashboard-wrapper">
                    <TimeBoard club={this.props.club} ref="timeBoard" date={this.props.date}/>
                    {this.props.timeSlots ? this.props.timeSlots.map((timeSlot) => {
                        return <CourtBoard club={this.props.club} timeSlot={timeSlot} key={timeSlot.court.id}/>
                    }): null}
                </div>
            </div>
        );
    }
}

export default Calendar;
