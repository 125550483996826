import './subscriptionCurrentCredits.scss';
import React from 'react';

import Credits from '../../../credits/credits';
import _ from 'lodash';
import { ICustomSessionTypeStoreState } from '../../../../models/store';
import * as StM from '../../../../models/store'
import { SrvM } from '../../../../modules';

export interface ISubscriptionCurrentCredit {
    title: string;
    description: string;
    iconColor: string;
    creditBalance: number;
    creditAmnt: number;
    sessionType: StM.SessionType;
    typeAlias: string;
    bgColor?: string;
    txtColor?: string;
}

export default class SubscriptionCurrentCredits extends React.Component<{ 
    credit: ISubscriptionCurrentCredit, 
    displayRemainingCredits: boolean,
    customSessionTypes: Array<ICustomSessionTypeStoreState>;
    alias?: string;
}> {
    private utils = new SrvM.Utils();

    constructor(props) {
        super(props);
    }

    render() {
        const credit = this.props.credit;
        const customSessionType = _.find(this.props.customSessionTypes, { alias: credit.typeAlias });
        const isCustom = credit.sessionType === StM.SessionType.Custom && customSessionType;
        let creditsBgColor: string;
        let creditsTextColor: string;

        if (isCustom) {
            creditsBgColor = customSessionType.colorBackground;
            creditsTextColor = customSessionType.colorText;
        }

        return (<>
            <div className="subscription-credit-wrapper">
                <div className="balance-wrapper">
                    <Credits credits={credit.creditAmnt} type={credit.sessionType} bgColor={creditsBgColor} txtColor={creditsTextColor}/>
                    <div className="title-wrapper">
                        <span className="credits-title">{credit.title}</span>
                        <span className="desc">{credit.description}</span>
                    </div>
                </div>
            </div>
            {this.props.displayRemainingCredits &&
                <div className={'credits--remaining ' + this.utils.getSessionClassByType(credit.sessionType, customSessionType)}>
                    <div className={`title-wrapper`}>
                        {credit.creditBalance ? `${credit.creditBalance} remaining` : 'No credits left'}
                    </div>
                </div>
            }
        </>);
    }
}
