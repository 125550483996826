import * as React from 'react';
import ClassNames from 'classnames';

import * as SrvM from '../../services';
import * as StM from '../../models/store';
import * as PolM from '../../policies';
import Basket from '../../containers/basket';

interface IHeaderBasketProps {
    basket: StM.IBasketStoreState;
    user: StM.IUserStoreState;
    club: StM.IClubStoreState;
    toggle: () => void;
}

class HeaderBasket extends React.Component<IHeaderBasketProps> {
    private utils = new SrvM.Utils();

    constructor(props: IHeaderBasketProps) {
        super(props);
    }

    render() {
        let itemsCount = this.props.basket.goods.length;
        let packagesCount = this.props.basket.packages.length;
      
        const basketPolicy = new PolM.BasketPolicy(this.props.club, this.props.basket, this.props.user);
       
        const prices = basketPolicy.getBasketPrices(false);

        itemsCount += packagesCount;
    
        const price = prices.subtotalPrice + prices.totalServicesPrice;
        const label = !packagesCount ? ' Session' : ' Item';
        const itemsCountTitle = `${itemsCount}${label}${(itemsCount !== 1 ? 's' : '')}`;
        const basketeWrapperClasses = ClassNames('basket-wrapper', { extended: this.props.basket.isShown });
        return (
            <div className={basketeWrapperClasses} onClick={(e) => this.toggleClick(e)}>
                <div className="basket-content">
                    <div className="basket">${this.utils.formatStringPrice(price.toString())}</div>
                    <div className="caret"></div>
                </div>
                <div className="sessions-count">{itemsCountTitle}</div>
                <Basket />
            </div>
        );
    }

    private toggleClick(e: any) {
        if (e) { e.preventDefault(); }
        let target = e.target;
        let inDropDown = false;
        while(target.nodeName !== 'BODY') {
            if (!!target.classList.contains && target.classList.contains('basket-content-block-wrapper')) {
                inDropDown = true;
                break;
            }
            target = target.parentElement;
        }
       
        if (!inDropDown) this.props.toggle();
    }
}

export default HeaderBasket;
