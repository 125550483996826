import * as React from 'react';
import { RouteService, RouteDialogService } from '../../services';
import { connect } from 'react-redux';
import { IGlobalStoreState } from '../../models/store';
import * as ActionsModule from '../../actions'; 
import * as StM from '../../models/store'; 
import { history } from '../../store/store';


const routeService = new RouteService(); 
const routeDialogService = new RouteDialogService();

interface IEmailConfirmationProps {
    init: () => void;
    club: StM.IClubStoreState;
}

interface IEmailConfirmationPageState { }

class EmailConfirmationPage extends React.Component<IEmailConfirmationProps, IEmailConfirmationPageState> {

    constructor(props: IEmailConfirmationProps) {
        super(props);
    }

    componentDidMount() {
        if (this.props) {
            this.props.init();
            const url = routeService.getBookTodayRoute();
            const alertHash = routeDialogService.getDialogHash(
                StM.DialogNames.Alert, 
                { msgKey: StM.MessagesKey.EmailConfirmation, messageType: StM.MessageTypes.Success }
            );
            history.push(url + '#' + alertHash);
        }
    }

    render() {
        return (
            <div>
                <h2>Email Confirmation Success</h2>
            </div>
        )
    };
};

function mapStateToProps(state: IGlobalStoreState) {
    return {
        club: state.club
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        init: () => dispatch(ActionsModule.EmailConfirmationPageActions.init())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailConfirmationPage);
