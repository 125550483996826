import './bookPage.mobile.scss';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import ClassNames from 'classnames';
import _ from 'lodash';


import { ActM, StM, SrvM, Constants } from '../../../modules';
import CalendarCourtBoardMobile from '../../../components/pages/book/calendarCourtBoard.mobile';
import DateSelector from '../../../components/pages/book/dateSelector.mobile';
import CoachSelectorMobile from '../../../components/pages/book/coachSelector.mobile';
import { CommonBookPage } from './commonBookPage';

export interface IBookPageProps {
    params: StM.IBookPageRouteParams;
    match: any;
    currentMatch: any;
    location: string;
    isAuthorized: boolean;
    user: StM.IUserStoreState;
    club: StM.IClubStoreState;
    sessions: Array<StM.ISessionStoreState>;
    basketSessions: Array<StM.ISessionStoreState>;
    basket: StM.IBasketStoreState;
    timeSlots: Array<StM.ICourtTimeSlotStoreState>;
    coaches: Array<StM.ICoachStoreState>;
    coachFeeTiers: Array<StM.ICoachFeeTierStoreState>;
    notifications: Array<StM.INotificationStoreState>;
    isShowAuthDialog: boolean;
    refresh: boolean;
    availableTimesLookup: IDictionary;
    availableTimes: Array<StM.IAvailableTimeStoreState>;
    selectedAvailability: StM.ICoachAvailabilityStoreState;
    isFinalized: boolean;
    coachFeeTierPrices: StM.ICoachFeeTierPriceStoreState[];
    pricingTiers: StM.IPricingTierStoreState[];
    inviteToken: string;

    fillCourtTimeSlots: (date: moment.Moment, refresh: boolean) => void;
    updateCurrentPageSessions: (params: any, clearCache?: boolean) => Promise<StM.ISessionStoreState>;
    init: (currentDate: moment.Moment) => Promise<any>;
    getSessions: (start: moment.Moment, end: moment.Moment) => Promise<any>;
    updateMatch: (match: any) => void;
    getAvailableTimes: (start: moment.Moment, end: moment.Moment, force?: boolean) => Promise<any>;
    go: (url: string) => any;
    onCreateSessionClick: (sessionType: string, courtId: number, timeKey: string, subFilter?: any, subFilterId?: any, sessionId?: number) => any;
    openSessionDialog: (from: string, id: number) => Promise<any>;
    showSpinner: () => any;
    hideSpinner: () => any;
    setAvailabilityTime: (timeItem: StM.ICoachAvailabilityTimeItem) => Promise<any>;
    resetCoachAvailability: () => Promise<any>;
    setAvailability: (coach: StM.ICoachStoreState, start: moment.Moment, duration: moment.Duration) => Promise<any>;
    removeAvailability: (coach: StM.ICoachStoreState, start: moment.Moment, duration: moment.Duration) => Promise<any>;
    addAvailableTime: (coach: StM.ICoachStoreState, start: moment.Moment, duration: moment.Duration) => Promise<any>;
    removeAvailableTime: (coach: StM.ICoachStoreState, start: moment.Moment, duration: moment.Duration) => Promise<any>;
    initFbLink: (inviteToken: string) => Promise<void>;
}

interface IBookPageState {
    expandedSlotTimeKey: string;
}

export class BookPageMobile extends React.Component<IBookPageProps, IBookPageState> {
    private logger = new SrvM.LogService();
    private utils = new SrvM.Utils();
    private routeSrv = new SrvM.RouteService();
    private isDeferredAuthorizedChanged: boolean;
    private isViewUpdating: boolean;

    constructor(props: IBookPageProps) {
        super(props);
        this.isViewUpdating = true;
        this.state = {
            expandedSlotTimeKey: ""
        };
    }

    public UNSAFE_componentWillMount() {
        if(this.props.inviteToken && this.routeSrv.isActivePage(StM.Pages.FBLink)) {
            this.props.initFbLink(this.props.inviteToken);
        }
        if (this.props.club.clubTimes.length) this.updateDateData(this.props.match.params.date)
    }

    public componentDidMount() { 
        this.scrollToActualTime(this.getCurrentDate(this.props.match.params.date));
    }

    public UNSAFE_componentWillReceiveProps(newProps: IBookPageProps) {
        this.isViewUpdating = true;
        const isFinalizedChanged = this.props.isFinalized !== newProps.isFinalized;
        const isLocationChanged = this.props.location !==newProps.location;
        const isMatchChanged = !_.isEqual(this.props.currentMatch.params, newProps.match.params);

        if (!newProps.isAuthorized && newProps.match.params && newProps.match.params.sessionType === StM.BookPageSessionType.Availability) this.props.go('/');
        if (isFinalizedChanged && !!newProps.club.clubTimes.length) this.init();
        if (isLocationChanged || isMatchChanged || !this.props.currentMatch.params)
            this.props.updateMatch({ ... this.props.match, params: newProps.match.params || {} });
        
        if (newProps.match.params && newProps.match.params.sessionType === StM.BookPageSessionType.Availability) {
            const isRangeChanged = !_.isEqual(this.props.selectedAvailability.range, newProps.selectedAvailability.range);
            if (isRangeChanged && newProps.selectedAvailability.range && newProps.selectedAvailability.range.length == 2) {
                const coach = _.find(newProps.coaches, { id: newProps.user.id });
                let range = [...newProps.selectedAvailability.range];
                this.updateAvailability(coach, range);
            }
        }
    }

    public shouldComponentUpdate(nextProps: IBookPageProps, nextState: IBookPageState): boolean {
        if (nextProps.club.id === 0) return false;

        if (nextProps.refresh) return true;

        nextProps.match.params.playSubfilter = StM.BookPagePlaySessionVisibility.AllSessions;

        let isAuthorizedChanged = this.props.isAuthorized != nextProps.isAuthorized;
        const isDateChanged = this.props.match.params.date != nextProps.match.params.date;
        const isClubChanged = !_.isEqual(this.props.club, nextProps.club);

        if ((isDateChanged || isClubChanged) && this.props.club.clubTimes.length) {
            this.updateDateData(nextProps.match.params.date);
        }
        else {
            const isTypeChanged = this.props.match.params.sessionType != nextProps.match.params.sessionType;
            const isFilterChanged = !_.isEqual(this.props.match.params, nextProps.match.params);
            const isSelectedAvailabilityChanged = !_.isEqual(this.props.selectedAvailability, nextProps.selectedAvailability);
            const isAvailableTimesChanged = !_.isEqual(this.props.availableTimes, nextProps.availableTimes);
            let isBasketSessionsChanged = false;
            let isSessionsChanged = false;
            let isTimeSlotsChanged = false;
            let isUserBalancesChange = false;

            if (isSelectedAvailabilityChanged || isAvailableTimesChanged) return true;
            if (!isFilterChanged) {
                isBasketSessionsChanged = this.props.basketSessions.length != nextProps.basketSessions.length
                    || !_.isEqual(this.props.basketSessions, nextProps.basketSessions);

                isUserBalancesChange = nextProps.isAuthorized && (this.props.basketSessions.length != nextProps.basketSessions.length
                    || !_.isEqual(this.props.user.creditsWallet, nextProps.user.creditsWallet));

                if (!isBasketSessionsChanged) isSessionsChanged =
                    this.props.sessions.length != nextProps.sessions.length
                    || !_.isEqual(this.props.sessions, nextProps.sessions);

                if (!isTypeChanged
                    && !isAuthorizedChanged && !isFilterChanged
                    && !isBasketSessionsChanged && !isSessionsChanged)
                    isTimeSlotsChanged = this.props.timeSlots.length != nextProps.timeSlots.length
                        || !_.isEqual(this.props.timeSlots, nextProps.timeSlots);

                const isAuthDialogShowChanged = this.props.isShowAuthDialog && !nextProps.isShowAuthDialog;

                if (isAuthorizedChanged && nextProps.isShowAuthDialog) {
                    this.isDeferredAuthorizedChanged = true;
                    isAuthorizedChanged = false;
                }

                if (isAuthDialogShowChanged && this.isDeferredAuthorizedChanged) {
                    this.isDeferredAuthorizedChanged = false;
                    isAuthorizedChanged = true;
                }
            }
            if ((isTypeChanged || isClubChanged || isTimeSlotsChanged
                || isBasketSessionsChanged || isSessionsChanged || isAuthorizedChanged || isFilterChanged || isUserBalancesChange) && this.props.club.clubTimes.length) {

                if (isTypeChanged || isFilterChanged || isBasketSessionsChanged || isSessionsChanged || isUserBalancesChange) {
                    this.isViewUpdating = true;
                    this.props.showSpinner();
                    this.fillCourtsData(this.getCurrentDate(nextProps.match.params.date), nextProps.match.params).then(() => {
                        this.props.hideSpinner();
                        this.isViewUpdating = false;
                    }).catch(() => {
                        this.props.hideSpinner();
                    });
                }
                if (nextProps.isFinalized && isAuthorizedChanged) {
                    this.isViewUpdating = true;
                    this.props.updateCurrentPageSessions(this.props.match.params, isAuthorizedChanged);
                }


                if (!this.isViewUpdating) this.props.hideSpinner();
                this.scrollToActualTime(this.getCurrentDate(nextProps.match.params.date));
                return true;
            }
            if (!this.isViewUpdating) this.props.hideSpinner();
        }

        return this.state.expandedSlotTimeKey !== nextState.expandedSlotTimeKey;
    }

    public componentDidUpdate(preveProps: IBookPageProps, prevState: IBookPageState) {
        let expandedSlotTimeKey = this.state.expandedSlotTimeKey != '' ?
            this.state.expandedSlotTimeKey :
            prevState.expandedSlotTimeKey;
        if (expandedSlotTimeKey) {
            this.scrollCalendar(expandedSlotTimeKey);
        }
    }

    public render() {
        const type = this.props.match.params.sessionType;
        const wrapperClasses = ClassNames('session-wrapper-mobile'
            , { 'play-session-mobile': type == StM.BookPageSessionType.Play }
            , { 'clinic-session-mobile': type == StM.BookPageSessionType.Clinic }
            , { 'lesson-session-mobile': type == StM.BookPageSessionType.Lesson }
            , { 'join-session-mobile': type == StM.BookPageSessionType.Join }
            , { 'availability-mobile': type == StM.BookPageSessionType.Availability }
        );
        const coachAvailability = this.props.availableTimes.filter((time) => {
            return time.ownerId == this.props.user.id;
        });
        return (
            <div className={wrapperClasses} >
                <div className="calendar-header-mobile">
                    <div className="mobile-row title-mobile">{this.getTitle(type)}</div>
                    {type == StM.BookPageSessionType.Lesson &&
                        <CoachSelectorMobile
                            coaches={this.props.coaches}
                            coachFeeTiers={this.props.coachFeeTiers}
                            availableTimesLookup={this.props.availableTimesLookup}
                            club={this.props.club}
                            params={this.props.match.params}
                            coachFeeTierPrices={this.props.coachFeeTierPrices}
                            pricingTiers={this.props.pricingTiers}
                            onCoachChangedHandler={(params) => this.onChangeFilter(params)} />
                    }
                    <DateSelector
                        club={this.props.club}
                        params={this.props.match.params}
                        date={this.getCurrentDate()}
                        onDateChangedHandler={(params) => this.onChangeFilter(params)} />
                </div>
                <CalendarCourtBoardMobile
                    club={this.props.club}
                    expandedSlotTimeKey={this.state.expandedSlotTimeKey}
                    date={this.getCurrentDate()}
                    user={this.props.user}
                    basket={this.props.basket}
                    params={this.props.match.params}
                    sessionType={this.props.match.params.sessionType}
                    timeSlots={this.props.timeSlots}
                    notifications={this.props.notifications}
                    coaches={this.props.coaches}
                    coachFeeTiers={this.props.coachFeeTiers}
                    availableTimes={this.props.availableTimes}
                    selectedAvailability={this.props.selectedAvailability}
                    onCreateSessionClick={(courtId, timeKey) => this.createSession(courtId, timeKey)}
                    openSessionClick={(from, id) => this.onSessionClick(from, id)}
                    onExpandSlot={(timeKey) => this.expandSlotHandle(timeKey)}
                    setAvailabilityClick={this.props.setAvailabilityTime}
                />
            </div>
        )
    }

    private init() {
        this.props.init(this.getCurrentDate());
        CommonBookPage.initPrevParams(this.props.match.params);
        this.isDeferredAuthorizedChanged = false;
    }

    private onChangeFilter(params: any) {
        CommonBookPage.changeFilter(params, this.props.params);
    }

    private getTitle(type: string) {
        let title: string;
        switch (type) {
            case StM.BookPageSessionType.Join:
                title = "Join a Session";
                break;
            case StM.BookPageSessionType.Play:
                title = "Book Play Session";
                break;
            case StM.BookPageSessionType.Lesson:
                title = "Book Lesson";
                break;
            case StM.BookPageSessionType.Clinic:
                title = "Book Clinic";
                break;
            case StM.BookPageSessionType.Availability:
                title = "Edit Availability";
                break;
            default:
                title = "Book Play Session";
                break;
        }
        return title;
    }

    private updateDateData(data: string) {
        const start = this.getCurrentDate(data).startOf('day');
        const end = start.clone().add(1, 'day');
        this.props.showSpinner();
        let sessionProm = this.props.getSessions(start, end);
        let availableTimeProm = this.props.getAvailableTimes(start, end);
        this.props.match.params.playSubfilter = StM.BookPagePlaySessionVisibility.AllSessions;

        Promise.all([sessionProm, availableTimeProm])
            .then(() => {
                CommonBookPage.initPrevParams(this.props.match.params);
                this.props.resetCoachAvailability();
                return this.fillCourtsData(this.getCurrentDate(data), this.props.match.params);
            })
            .then(() => {
                this.props.hideSpinner();
            })
            .catch((error) => {
                this.logger.error(error);
                this.props.hideSpinner();
            });
    }

    private updateAvailability(coach: StM.ICoachStoreState, range: Array<StM.ICoachAvailabilityTimeItem>) {
        const proms = this.getUpdateAvailabilityProms(coach, range);
        this.props.showSpinner();
        Promise.all(proms).then(() => {
            this.props.hideSpinner();
        }).catch(() => {
            this.props.hideSpinner();
        });
    }

    private correctAvailabilityTimesRange(source: Array<StM.ICoachAvailabilityTimeItem>): Array<StM.ICoachAvailabilityTimeItem> {
        let range = [...source];

        const minDate = range[0].startTime.isBefore(range[1].startTime, 'day') ? range[0].startTime : range[1].startTime;
        const maxDate = range[0].startTime.isAfter(range[1].startTime, 'day') ? range[0].startTime : range[1].startTime;
        range[0].startTime = range[0].startTime.clone().set({ year: minDate.year(), month: minDate.month(), day: minDate.day() });
        range[1].startTime = range[1].startTime.clone().set({ year: maxDate.year(), month: maxDate.month(), day: maxDate.day() });
        if (range[0].startTime.isAfter(range[1].startTime)) {
            range[0].startTime = range[0].endTime.clone();
            range[0].endTime = range[0].startTime.clone();
        }

        return range;
    }

    private shouldRemoveAvailabilityTime(range: Array<StM.ICoachAvailabilityTimeItem>): boolean {
        const firstItemTime = range[0].startTime.clone();
        const clubStartFirstTimeDuration = this.props.club.clubTimes[firstItemTime.day()].startTime;
        const clubStartFirstTime = moment.utc(clubStartFirstTimeDuration.asMilliseconds());
        const startFirstTimeItemDuration = this.utils.getDurationTime(firstItemTime);
        const startFirstTime = (clubStartFirstTimeDuration.asMilliseconds() > startFirstTimeItemDuration.asMilliseconds()
            ? firstItemTime.clone().set({ hours: clubStartFirstTime.hours(), minutes: clubStartFirstTime.minutes() })
            : firstItemTime.clone());
        const startFirstTimeMs = this.utils.getDurationTime(startFirstTime).asMilliseconds();
        const isFromDown = moment.duration({ hours: firstItemTime.hours(), minutes: firstItemTime.minutes() }).asMilliseconds()
            > moment.duration({ hours: range[1].endTime.hours(), minutes: range[1].endTime.minutes() }).asMilliseconds();

        const isToRemove = _.some(this.props.availableTimes, (time) => {
            if (!time.begin.isSame(startFirstTime, 'day')) return false;
            const avBeginMs = this.utils.getDurationTime(time.begin).asMilliseconds();
            const avEndMs = avBeginMs + time.duration.asMilliseconds();
            return isFromDown
                ? startFirstTimeMs <= avEndMs && startFirstTimeMs > avBeginMs
                : startFirstTimeMs >= avBeginMs && startFirstTimeMs < avEndMs;
        });

        return isToRemove;
    }

    private getAvailabilityStartTime(date: moment.Moment): moment.Moment {
        const clubStartTimeDuration = this.props.club.clubTimes[date.day()].startTime;
        const clubStartTime = moment.utc(clubStartTimeDuration.asMilliseconds());
        const startTimeDuration = this.utils.getDurationTime(date);
        const startTime = (clubStartTimeDuration.asMilliseconds() > startTimeDuration.asMilliseconds()
            ? date.clone().set({ hours: clubStartTime.hours(), minutes: clubStartTime.minutes() })
            : date.clone());

        return startTime;
    }

    private getAvailabilityEndTime(date: moment.Moment, startTime: moment.Moment, availabilityTimeItem: StM.ICoachAvailabilityTimeItem): moment.Moment {
        const clubEndTimeDuration = this.props.club.clubTimes[date.day()].endTime;
        const clubEndTime = moment.utc(clubEndTimeDuration.asMilliseconds());
        const endTimeDuration = this.utils.getDurationTime(availabilityTimeItem.endTime);

        const endTime = (endTimeDuration.asMilliseconds() > clubEndTimeDuration.asMilliseconds()
            ? startTime.clone().set({ hours: clubEndTime.hours(), minutes: clubEndTime.minutes() })
            : startTime.clone().set({ hours: availabilityTimeItem.endTime.hours(), minutes: availabilityTimeItem.endTime.minutes() }));

        return endTime;
    }

    private checkIsTimesBeyonClubTime(date: moment.Moment, availabilityTimeItem: StM.ICoachAvailabilityTimeItem): boolean {
        const clubStartTimeDuration = this.props.club.clubTimes[date.day()].startTime;
        const clubEndTimeDuration = this.props.club.clubTimes[date.day()].endTime;
        const startTimeDuration = this.utils.getDurationTime(date);
        const endTimeDuration = this.utils.getDurationTime(availabilityTimeItem.startTime);

        const isStartTimeBeforeClubTime = clubStartTimeDuration.asMilliseconds() > startTimeDuration.asMilliseconds();
        const isEndTimeBeforeClubTime = endTimeDuration.asMilliseconds() < clubStartTimeDuration.asMilliseconds();
        const isStartTimeAfterClubTime = clubEndTimeDuration.asMilliseconds() < startTimeDuration.asMilliseconds();
        const isEndTimeAfterClubTime = endTimeDuration.asMilliseconds() > clubEndTimeDuration.asMilliseconds();

        return (isStartTimeBeforeClubTime && isEndTimeBeforeClubTime) || (isStartTimeAfterClubTime && isEndTimeAfterClubTime);
    }

    private getAvailabilityDuration(startTime: moment.Moment, endTime: moment.Moment): moment.Duration {
        const startMs = this.utils.getDurationTime(startTime.clone()).asMilliseconds();
        const endMs = this.utils.getDurationTime(endTime.clone()).asMilliseconds();
        return moment.duration(endMs - startMs);
    }

    private getUpdateAvailabilityProms(coach: StM.ICoachStoreState, range: Array<StM.ICoachAvailabilityTimeItem>): Array<Promise<any>> {
        let timesRange = this.correctAvailabilityTimesRange(range);
        const isToRemove = this.shouldRemoveAvailabilityTime(timesRange);
        timesRange = this.sortTimesRange(timesRange);
        const start = this.getCurrentDate().startOf('day');
        const end = start.clone().add(1, 'day');

        let proms = new Array<Promise<any>>();

        for (let date = timesRange[0].startTime.clone(); date.isSameOrBefore(timesRange[1].startTime, 'day'); date = date.add(1, 'day')) {
            if (this.checkIsTimesBeyonClubTime(date, timesRange[1])) continue;
            const startTime = this.getAvailabilityStartTime(date);
            const endTime = this.getAvailabilityEndTime(date, startTime, timesRange[1]);
            const duration = this.getAvailabilityDuration(startTime, endTime);

            if (isToRemove) {
                proms.push(this.props.removeAvailability(coach, startTime, duration)
                    .then((result: boolean) => {
                        if (result) return this.props.getAvailableTimes(start, end, true);
                    }));
            } else {
                proms.push(this.props.setAvailability(coach, startTime, duration)
                    .then((result: boolean) => {
                        if (result) return this.props.getAvailableTimes(start, end, true);
                    }));
            }
        }

        return proms;
    }

    private sortTimesRange(range: Array<StM.ICoachAvailabilityTimeItem>): Array<StM.ICoachAvailabilityTimeItem> {
        if (!range) return [];
        return range.sort((a, b) => {
            if (a.startTime.hours() > b.startTime.hours()) return 1;
            if (a.startTime.hours() < b.startTime.hours()) return -1;
            if (a.startTime.minutes() > b.startTime.minutes()) return 1;
            if (a.startTime.minutes() < b.startTime.minutes()) return -1;

            return -1;
        });
    }

    private fillCourtsData(date: moment.Moment, params: StM.IBookPageRouteParams) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.props.fillCourtTimeSlots(date, true);
                return resolve();
            }, 200);
        });
    }

    private expandSlotHandle(slotTimeKey: string) {
        this.setState({ ...this.state, expandedSlotTimeKey: slotTimeKey });
    }

    private scrollToActualTime(currentDate: moment.Moment) {
        if (currentDate.date() == moment().date()) {
            let currentClubTime = this.utils.getCurrentClubTime(this.props.club).asMilliseconds();
            let availableHour = moment.utc(currentClubTime).startOf('hour');
            let availableHourFormat = availableHour.format('h:mm');
            let availableHourTypeFormat = availableHour.format('a');
            this.scrollCalendar(availableHourFormat + availableHourTypeFormat);
        } else {
            document.getElementsByClassName("content-wrapper")[0].scrollTop = 0;
        }
    }

    private scrollCalendar(badgeTimeKey: string) {
        let actualTimeBlock = document.getElementById(badgeTimeKey);
        let type = this.props.match.params.sessionType;
        if (actualTimeBlock) {
            actualTimeBlock.scrollIntoView();
            document.getElementsByClassName("content-wrapper")[0].scrollTop -= type == StM.BookPageSessionType.Lesson ? 193 : 130;
        }
    }

    private getCurrentDate(srcDate?: string): moment.Moment {
        const date = srcDate || this.props.match.params.date;
        return this.routeSrv.getRouteDate(date, this.props.club);
    }

    private createSession(courtId: number, timeKey: string) {
        let sessionType = this.props.match.params.sessionType;
        let subFilter: string = StM.BookPageFilterTypeDefault.SubFilter;
        let subFilterId: string = StM.BookPageFilterTypeDefault.SubFilterId;
        if (sessionType !== StM.BookPageSessionType.Clinic) {
            if (sessionType == StM.BookPageSessionType.Lesson) {
                subFilter = this.props.match.params.lessonSubfilter;
                subFilterId = this.props.match.params.lessonSubfilterId;
            }
            this.props.showSpinner();
            this.createSessionClick(sessionType, courtId, timeKey, subFilter, subFilterId).then(() => {
                return this.props.hideSpinner();
            }).catch(() => {
                this.props.hideSpinner();
            });
        }
    }

    private createSessionClick(sessionType: string, courtId: number, timeKey: string, subFilter: any, subFilterId: any) {
        const prom = new Promise((resolve, reject) => {
            setTimeout(() => {
                this.props.onCreateSessionClick(sessionType, courtId, timeKey, subFilter, subFilterId);
                return resolve();
            }, 200);
        });
        return prom;
    }

    private onSessionClick(from: string, id: number) {
        this.props.showSpinner();
        this.props.openSessionDialog(from, id).then(() => {
            this.props.hideSpinner();
        }).catch(() => {
            this.props.hideSpinner();
        });
    }
}

function mapStateToProps(state: StM.IGlobalStoreState, ownProps: any) {
    const routeParams = ownProps.location.pathname.split('/');
    const isAvailability = routeParams[1] === StM.BookPageSessionType.Availability;
    let sessionType: string = isAvailability ? StM.BookPageSessionType.Availability : routeParams[2];
    sessionType = sessionType ? sessionType.toLowerCase() : sessionType;
    if (ownProps && ownProps.match && ownProps.match.params) {
        ownProps.match.params['sessionType'] = sessionType;
    }
    const availableTimes = state.pages.book.availableTimes.filter(time => { return time.ownerId == state.user.id });
    return {
        location: ownProps.location.pathname
        , isAuthorized: state.app.isAuthorized
        , user: state.user
        , currentMatch: state.app.match
        , club: state.club
        , sessions: state.pages.book.sessions
        , basketSessions: state.basket.goods
        , basket: state.basket
        , timeSlots: state.pages.book.timeSlots
        , coaches: state.coaches
        , coachFeeTiers: state.coachFeeTiers
        , notifications: state.notificationBoard.notifications.filter(n => n.targetType !== StM.NotificationTargetType.Group)
        , isShowAuthDialog: state.dialogs.auth.isOpened
        , refresh: state.pages.book.refresh
        , availableTimesLookup: state.pages.book.availableTimesLookup
        , availableTimes: availableTimes
        , selectedAvailability: state.admin.coachAvailability
        , isFinalized: state.app.isFinalized
        , coachFeeTierPrices: state.coachFeeTierPrices
        , pricingTiers: state.pricingTiers
        , inviteToken: ownProps.match.params.inviteToken
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        fillCourtTimeSlots: (date: moment.Moment, refresh: boolean) =>
            dispatch(ActM.BookPageActions.fillCourtTimeSlots(date, refresh))
        , init: (currentDate: moment.Moment) => dispatch(ActM.BookPageActions.init(currentDate))
        , getSessions: (start: moment.Moment, end: moment.Moment) => dispatch(ActM.BookPageActions.getSessions(start, end))
        , updateMatch: (match: any) => dispatch(ActM.AppActions.updateRouteMatch(match))
        , updateCurrentPageSessions: (params: any, clearCache?: boolean) => dispatch(ActM.SessionActions.updateForCurrentPage(params, clearCache))
        , getAvailableTimes: (start: moment.Moment, end: moment.Moment, force?: boolean) => dispatch(ActM.BookPageActions.getAvailableTimes(start, end, force))
        , go: (url: string) => dispatch(ActM.RouteActions.push(url))
        , onCreateSessionClick: (sessionType: string, courtId: number, timeKey: string, subFilter?: string, subFilterId?: string, sessionId?: number) => {
            dispatch(ActM.DialogActions.open(StM.DialogNames.NewSession, {
                courtId: courtId,
                timeKey: timeKey,
                sessionType: sessionType,
                sessionSubFilter: subFilter,
                sessionSubFilterId: subFilterId,
                sessionId: sessionId
            }));
        }
        , openSessionDialog: (from: string, id: number) => dispatch(ActM.SessionActions.openSessionDialog(from, id))
        , showSpinner: () => dispatch(ActM.AppActions.showSpinner())
        , hideSpinner: () => dispatch(ActM.AppActions.hideSpinner())
        , setAvailabilityTime: (timeItem: StM.ICoachAvailabilityTimeItem) => dispatch(ActM.CoachAvailabilityActions.setTime(timeItem))
        , resetCoachAvailability: () => dispatch(ActM.CoachAvailabilityActions.reset())
        , setAvailability: (coach: StM.ICoachStoreState, start: moment.Moment, duration: moment.Duration) => dispatch(ActM.CoachAvailabilityActions.markAsAvailable(coach, start, duration))
        , removeAvailability: (coach: StM.ICoachStoreState, start: moment.Moment, duration: moment.Duration) => dispatch(ActM.CoachAvailabilityActions.markAsUnavailable(coach, start, duration))
        , addAvailableTime: (coach: StM.ICoachStoreState, start: moment.Moment, duration: moment.Duration) => dispatch(ActM.SchedulePageActions.addCoachAvailableTime(coach, start, duration))
        , removeAvailableTime: (coach: StM.ICoachStoreState, start: moment.Moment, duration: moment.Duration) => dispatch(ActM.SchedulePageActions.removeCoachAvailableTime(coach, start, duration))
        , initFbLink: (inviteToken: string) => dispatch(ActM.FBLinkPageActions.init(inviteToken))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BookPageMobile);