import './subscriptionOfferInfo.scss';
import * as React from 'react';
import moment from 'moment';
import * as _ from 'lodash';

import * as StM from '@models/store';
import * as SrvM from '../../../services';
import { ISubscriptionCreditsDefinition } from '../../../containers/pages/packages/subscriptionsList';
import SubscriptionBalanceItem from '../../credits/subscriptionsBalanceItem';

const utils = new SrvM.Utils();

interface ISubscriptionInfoProps {
    id: number;
    title: string;
    description: string;
    price: number;
    creditsDefinitions: Array<ISubscriptionCreditsDefinition>;
    customSessionTypes: Array<StM.ICustomSessionTypeStoreState>;
    sessionTypes: StM.IPackageSessionTypeStoreState[]
    coachFeeTiers: StM.ICoachFeeTierStoreState[]
    coachFeeTierId?: number;
    enablePurchasing?: boolean;
    billingPeriod: string;
    onBuyClick?: (id: number) => void;
}

export default class SubscriptionOfferInfo extends React.Component<ISubscriptionInfoProps> {

    constructor(props: ISubscriptionInfoProps) {
        super(props);
    }

    render() {
        return (
            <article className="subscription-wrapper">
                {this.renderHeader()}
                {this.renderMainContent()}
                {this.props.enablePurchasing && this.renderFooter()}
            </article>
        );
    }

    private renderHeader() {
        return (
            <header>
                <div className="title-wrapper">
                    <span className="subscription-title">{this.props.title}</span>
                    <span className="expiration">Renews {this.props.billingPeriod}</span>
                </div>
                <div className="desc">{this.props.description}</div>
            </header>
        );
    }

    private renderMainContent() {
        const coachFeeTier = this.props.coachFeeTiers.find(cft => cft.id === this.props.coachFeeTierId)
        return (
            <main>
                <div className="credits-wrapper">
                    <ul className="credits-list">
                        {_.sortBy(this.props.creditsDefinitions.filter((item) => item.credits > 0), c => c.sortOrder)
                            .map((item, index) => {
                                return (
                                    <li key={index} className="credits-item">
                                        <SubscriptionBalanceItem
                                            title={item.title}
                                            description={item.description}
                                            credits={item.credits}
                                            sessionType={this.props.sessionTypes.find(st => st.id === item.packageSessionTypeId)?.sessionType}
                                            alias={item.alias}
                                            coachFeeTier={coachFeeTier}
                                            customSessionTypes={this.props.customSessionTypes}
                                        />
                                    </li>
                                );
                            })}
                    </ul>
                </div>
            </main>
        );
    }

    private renderFooter() {
        return (
            <footer>
                <div className="price-wrapper">
                    <span className="price-title">Price:</span>
                    <span className="price">${utils.formatStringPrice(this.props.price.toString())}</span>
                </div>
                <div className="btns-wrapper">
                    <button type="button" className="btn btn-black" onClick={(e) => this.handleOnBuyClick(e)}>SUBSCRIBE</button>
                </div>
            </footer>
        );
    }

    private handleOnBuyClick(e: any) {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        this.props.onBuyClick(this.props.id);
    }

    private getExpirationDateString(expirationTimeSpan: moment.Duration) {
        return moment().clone().add(expirationTimeSpan.asDays(), 'days').format('MMM D, YYYY');
    }

}
