import './subscriptionBalanceItem.scss';
import React from 'react';
import ClassNames from 'classnames';
import _ from 'lodash';

import * as SrvM from '../../services';
import * as StM from '../../models/store'
import { ICustomSessionTypeStoreState } from '../../models/store';
import Credits from './credits';

interface ISubscriptionBalanceItemProps {
    id?: number;
    credits: number;
    title: string;
    description: string;
    sessionType: StM.SessionType;
    customSessionTypes: Array<ICustomSessionTypeStoreState>;
    alias?: string;
    coachFeeTier?: StM.ICoachFeeTierStoreState;
    enableAdjusting?: boolean;

    onBalanceChanged?: (id: number, credits: number) => void;
}

interface ISubscriptionBalanceItemState {
    credits: number;
}

export default class SubscriptionBalanceItem extends React.Component<ISubscriptionBalanceItemProps, ISubscriptionBalanceItemState>{
    private _integerPattern = "/^[0-9\b]+$/";
    private utils = new SrvM.Utils();
    constructor(props: ISubscriptionBalanceItemProps) {
        super(props);

        this.state = {
            credits: 0
        }
    }

    componentDidMount() {
        this.init(this.props)
    }

    render() {
        const sessionType = this.props.sessionType;
        const tier = sessionType === StM.SessionType.Private && this.props.coachFeeTier ? this.props.coachFeeTier.title : '';
        const title = this.props.title;
        let description = this.props.description;

        if (tier) description = description ? `${description}, ${tier}` : tier;

        let creditsBgColor: string;
        let creditsTextColor: string;
        const customSessionType = _.find(this.props.customSessionTypes, { alias: this.props.alias });
        if (sessionType === StM.SessionType.Custom && customSessionType) {
            creditsBgColor = customSessionType.colorBackground;
            creditsTextColor = customSessionType.colorText;
        }
        return (
            <div className="subscription-balance-item-wrapper">
                <div className="balance-wrapper">
                    <Credits credits={this.props.credits} type={sessionType} bgColor={creditsBgColor} txtColor={creditsTextColor}/>
                    <div className="title-wrapper">
                        <span className="credits-title">{title}</span>
                        <span className="desc">{description}</span>
                    </div>
                </div>
                {this.renderCreditsControls(this.state.credits)}
            </div>
        );
    }

    renderCreditsControls(credits: number) {
        if (!this.props.enableAdjusting) return null;
        return (
            <div className="credits-controls-wrapper">
                <button className="btn-black btn-default btn-credits" disabled={credits === 0} onClick={(e) => credits > 0 && this.handleSubCreditsClick(e)}>-</button>
                <input className="credits-input"
                    type="number"
                    min={0}
                    step={1}
                    pattern={this._integerPattern}
                    value={credits}
                    onChange={(e) => this.handleCreditsChanging(e)}
                    onBlur={(e) => this.handleCreditsBlur(e)}
                />
                <button className="btn-black btn-default btn-credits" onClick={(e) => this.handleAddCreditsClick(e)}> +</button>
            </div>
        );
    }

    private init(props: ISubscriptionBalanceItemProps) {
        this.setState({
            ...this.state,
            credits: props.credits
        });
    }

    // handlers
    private handleCreditsChanging(e: any) {
        if (e) { e.preventDefault(); e.stopPropagation(); }
        if (e.target.validity.valid) {
            let credits = e.target.value;
            credits = credits >= 0 ? credits : 0;
            this.updateCredits(credits);
        }
    }

    private handleCreditsBlur(e: any) {
        if (e) { e.preventDefault(); e.stopPropagation(); }
        this.updateCredits(+e.target.value);

    }

    private handleAddCreditsClick(e: any) {
        if (e) { e.preventDefault(); e.stopPropagation(); }
        this.updateCredits(this.state.credits + 1);
    }

    private handleSubCreditsClick(e: any) {
        if (e) { e.preventDefault(); e.stopPropagation(); }
        let credits = this.state.credits - 1;
        credits = credits >= 0 ? credits : 0;
        this.updateCredits(credits);
    }

    private updateCredits(credits: number) {
        this.setState(
            { ...this.state, credits },
            () => { if (this.props.onBalanceChanged) this.props.onBalanceChanged(this.props.id, credits); }
        );
    }
}
