import moment from 'moment';
import * as React from 'react';
import * as StoreModule from '../../../models/store';
import * as ServiceModule from '../../../services';

import {CourtBoard} from './courtBoard';
import {EmptyCourtBoard} from './emptyCourtBoard';

interface IСalendarCourtBoardProps {
    club: StoreModule.IClubStoreState;
    date: moment.Moment;
    user?: StoreModule.IUserStoreState;
    timeSlots: Array<StoreModule.ICourtTimeSlotStoreState>;
    filter: StoreModule.IBookPageRouteParams;

    onCreateSessionClick: (courtId: number, timeKey: string) => any;
    onSessionClick: (from: string, id:number) => void;
    onNonFilterSessionClick: (sessionId: number) => void;
    onBageMouseEnter?: () => void;
    onBageMouseLeave?: () => void;
}

interface IСalendarCourtBoardState {
}

class СalendarCourtBoard extends React.Component<IСalendarCourtBoardProps, IСalendarCourtBoardState> {
    courtTimeService: ServiceModule.CourtTimeService;
    constructor(props: IСalendarCourtBoardProps) {
        super(props);
        this.courtTimeService = new ServiceModule.CourtTimeService();
    }

    render() {
        const clubTime = this.props.club.clubTimes[this.props.date.day()];

        let isDayBlockedOut = clubTime && clubTime.startTime.asMilliseconds() >= clubTime.endTime.asMilliseconds();

        let timeSlots = this.props.timeSlots;
        let courts = this.props.club.courts;
        let timeSlotProp = this.props.timeSlots; 
        
        return (
            <div className="table-content">
                
                {!isDayBlockedOut && courts.map((court) => { 
                    let timeSlot = this.courtTimeService.findCourtSlot(this.props.date, court, timeSlots);
                    return ( <CourtBoard key={court.id} 
                                         timeSlot={timeSlot} 
                                         court={court} 
                                         user={this.props.user}
                                         filter={this.props.filter} 
                                         onBageMouseEnter={this.props.onBageMouseEnter}
                                         onBageMouseLeave={this.props.onBageMouseLeave}
                                         onCreateSessionClick={this.props.onCreateSessionClick}
                                         onSessionClick={(from: string, id:number) => this.props.onSessionClick(from, id)}
                                         onNonFilterSessionClick={(sessionId: number) => this.props.onNonFilterSessionClick(sessionId)}/>
                )})}
                {isDayBlockedOut && <EmptyCourtBoard/>}
            </div>
        )
    };
};

export default СalendarCourtBoard;
