import './dialogs.scss';
import './alertDialog.scss';

import * as _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import * as StM from '../../models/store';
import * as ActM from '../../actions';
import * as SrvM   from '../../services';
import { Modal } from '../../components';


interface ISubscriptionAddedDialogProps {
    isAuthorized: boolean;
    isShown: boolean;
    
    closeDialog: () => void;
    openBookDialog: () => Promise<any>;
    openAuthDialog: (url:string) => Promise<any>;
}

class SubscriptionAddedDialog extends React.Component<ISubscriptionAddedDialogProps> {
    constructor(props: ISubscriptionAddedDialogProps) {
        super(props);
    }
    checkoutClick(e:any) {
        if(e) {e.stopPropagation();}
        if (!this.props.isAuthorized) {
            const routeDlgSrv = new SrvM.RouteDialogService();
            const hash = routeDlgSrv.getDialogHash(StM.DialogNames.Book, {});
            const url = encodeURIComponent(hash);
            this.props.openAuthDialog(url);
        } else {
            this.props.openBookDialog();
        }
        this.props.closeDialog();
    }

    closeClick(e:any) {
        if(e) {e.stopPropagation();}
        this.props.closeDialog();
    }
    
    render() {
        if (this.props.isShown) {
            return (
                <Modal classes="alert-dialog success session-create" ref={(elem) => setPositionCreatSessionDialog(elem)} closeHandler={this.props.closeDialog} dialogName={StM.DialogNames.SubscriptionAdded}>
                    <div className="modal-dialog dialog">
                        <div className="modal-dialog-header">
                            <div className="title success create-session-title"><span className="ico-alert-dialog"/>
                                <div className="alert-dialog-text-wrapper">
                                    <div>Subscription has been added</div>
                                    <div>to your cart</div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-dialog-body">
                            <p className="note">Checkout to confirm your purchase or Continue to add more.</p>
                            <div className="btns-wrapper">
                                <button onClick={(e) => this.checkoutClick(e)}>Checkout</button>
                                <button onClick={(e) => this.closeClick(e)}>Continue</button>
                            </div>
                        </div>
                    </div>
                </Modal>
            );
        } else {
            return null;
        }
    }
}

const setPositionCreatSessionDialog = (elem:any) => {
        const windowHeight: any = document.body.clientHeight;
        const dialog: any = document.getElementsByClassName('session-create')[0];
        const dialogHeight: any = dialog.offsetHeight;
        const topPosition = (windowHeight - dialogHeight)/2;
        dialog.style.top = topPosition + "px";
}

const mapStateToProps = (state: StM.IGlobalStoreState, ownProps: any) => {
    const dialog = state.dialogs.subscriptionAdded;
    return {
        isShown: dialog.isOpened,
        isAuthorized: state.app.isAuthorized
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        closeDialog: () => dispatch(ActM.DialogActions.close(StM.DialogNames.SubscriptionAdded)),
        openBookDialog: () => (dispatch(ActM.DialogActions.open(StM.DialogNames.Book))),
        openAuthDialog: (url:string) => dispatch(ActM.DialogActions.open(StM.DialogNames.Auth, { tab: StM.AuthDialogTabs.SignIn, returnUrl: url})),
    };

}

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionAddedDialog);
