import './timeRangeWrapper.mobile.scss';
import * as React from 'react';
import moment from 'moment';
import * as StM from '../../../../../models/store';
import AvailableBadgeMobile from './availableBadge.mobile';
import SessionBadgeMobile from './sessionBadge.mobile';
import * as _ from 'lodash';

interface ITimeRangeBlockProps {
    date: moment.Moment;
    start: moment.Duration;
    end: moment.Duration;
    stepMins?: number;
    user: StM.IUserStoreState;
    courtTimeBlocks: Array<StM.ICourtTimeBlockStoreState>;
    availableTimes: Array<StM.IAvailableTimeStoreState>;
    selectedAvailability: StM.ICoachAvailabilityStoreState;
    club: StM.IClubStoreState;

    setAvailabilityClick?: (timeItem: StM.ICoachAvailabilityTimeItem) => void;
}

interface ITimeRangeBlockState {
}

const DefaultStepMins: number = 15;

export default class TimeRangeBlock extends React.Component<ITimeRangeBlockProps, ITimeRangeBlockState> {
    private tempBlock: StM.ICourtTimeBlockStoreState;
    private tempIndex: number;
    private isAvailabilityMode: boolean = false;
    constructor(props: ITimeRangeBlockProps) {
        super(props);
    }

    componentDidUpdate(prevProps: ITimeRangeBlockProps) {
        if (
            this.props.selectedAvailability !== prevProps.selectedAvailability ||
            (this.props.selectedAvailability && this.props.selectedAvailability.range &&
                this.props.selectedAvailability.range.length !== prevProps.selectedAvailability.range.length)
        ) {
            this.isAvailabilityMode = !!this.props.selectedAvailability &&
            this.props.selectedAvailability.range &&
            this.props.selectedAvailability.range.length > 0;
        }
    }

    render() {
        const times = this.getTimes();
        return (
            <div className="time-range-wrapper-mobile">
                {times.map((time, index) => {
                    return this.getBadge(time, index);
                })}
            </div>
        );
    };

    private getTimes(): Array<moment.Duration> {
        let times = new Array<moment.Duration>();
        for (let tempTime = moment.duration(this.props.start.asMilliseconds());
            tempTime.asMilliseconds() < this.props.end.asMilliseconds();
            tempTime = moment.duration(tempTime.asMilliseconds()).add(this.props.stepMins || DefaultStepMins, 'minutes')) {
            times.push(tempTime);
        }
        return times;
    }

    private getBadge(time: moment.Duration, index: number): JSX.Element {
        const clubTime = this.props.club.clubTimes[this.props.date.day()];
        const timeMs = time.asMilliseconds();
        const isDisabled = clubTime.startTime.asMilliseconds() > timeMs || clubTime.endTime.asMilliseconds() <= timeMs;
        const timeBlock = this.getTimeBlock(time);
        const isAvailableTime = this.isAvailableTime(time);
        const isSelected = this.isSelectedTime(time);
        if (isDisabled) {
            return <div key={timeMs} className="time-range-block-mobile disabled"></div>
        } else if (!!timeBlock) {
            if (!this.tempBlock || (!!this.tempBlock && this.tempBlock.session.id != timeBlock.session.id)) {
                this.tempIndex = index;
            }
            this.tempBlock = timeBlock;
            return <SessionBadgeMobile key={timeMs}
                start={time}
                durationMins={this.props.stepMins || DefaultStepMins}
                date={this.props.date}
                timeBlock={timeBlock}
                user={this.props.user}
                index={this.tempIndex}
                isAvailable={isAvailableTime}
                isSelected={isSelected}
                isAvailabilityMode={this.isAvailabilityMode}
                setAvailabilityClick={this.props.setAvailabilityClick}
            />
        } else {
            return <AvailableBadgeMobile key={timeMs}
                start={time}
                durationMins={this.props.stepMins || DefaultStepMins}
                date={this.props.date}
                user={this.props.user}
                isAvailable={isAvailableTime}
                isSelected={isSelected}
                isAvailabilityMode={this.isAvailabilityMode}
                setAvailabilityClick={this.props.setAvailabilityClick}
            />
        }
    }

    private isAvailableTime(time: moment.Duration): boolean {
        const timeMs = time.asMilliseconds();
        return _.some(this.props.availableTimes, (availableTime) => {
            const beginMs = moment.duration({ hours: availableTime.begin.hours(), minutes: availableTime.begin.minutes() }).asMilliseconds();
            const endMs = beginMs + availableTime.duration.asMilliseconds();
            return timeMs >= beginMs && timeMs < endMs;
        });
    }

    private getTimeBlock(time: moment.Duration) {
        const timeMs = time.asMilliseconds();
        return _.find(this.props.courtTimeBlocks, (timeBlock) => {
            const isStart = timeMs >= timeBlock.start.asMilliseconds();
            const isEnd = timeMs < timeBlock.end.asMilliseconds();
            const isSession = !!timeBlock.session;
            return isSession && isStart && isEnd;
        });
    }

    private isSelectedTime(time: moment.Duration) {
        const timeMs = time.asMilliseconds();
        const range = this.props.selectedAvailability ? this.props.selectedAvailability.range : null;
        if (range && range.length > 0) {
            const rangeStart = range[0].startTime;
            const rangeStartMs = moment.duration({ hours: rangeStart.hours(), minutes: rangeStart.minutes() }).asMilliseconds();

            if (range.length == 1) {
                return timeMs == rangeStartMs;
            } else {
                const rangeEnd = range[1].endTime;
                const rangeEndMs = moment.duration({ hours: rangeEnd.hours(), minutes: rangeEnd.minutes() }).asMilliseconds();
                return timeMs >= rangeStartMs && timeMs < rangeEndMs;
            }
        }
        return false;
    }
};
