import * as _ from 'lodash';

import * as StM from '../models/store';
import * as PolM from './';
import { IBasePolicy, BasePolicy } from './basePolicy';
export class PackagePolicy extends BasePolicy implements IBasePolicy {
    constructor() {
        super();
    }

    public static HideCreditsOnCalendar: boolean = true;

    public handle() {}

    public static showPriceInCredits(
        session: StM.ISessionStoreState,
        time: StM.ICourtTimeBlockStoreState,
        sessionAvailabilityPolicy?: PolM.SessionAvailabilityPolicy, showCreditsOnCalendar: boolean = false): boolean {
        return (
            (!!session.checkoutCredits || (!!time && time.isSessionFromBasket && !!session.credits) ||
                (session.isPaidByOwner &&
                    _.some(
                        session.bookings,
                        (b) =>
                            !!b.groupId &&
                            session.ownerId == b.userId &&
                            b.credits > 0 &&
                            b.status != StM.BookingStatus.Cancelled &&
                            b.status != StM.BookingStatus.LateCancel &&
                            b.paymentType === StM.PaymentTypes.Credits
                    ))) &&
            ((!!time
                ? time.isSessionFromBasket || time.isBought
                : !!sessionAvailabilityPolicy && (sessionAvailabilityPolicy.getIsBasket() || sessionAvailabilityPolicy.getIsBoughtUser())) ||
                !this.HideCreditsOnCalendar || showCreditsOnCalendar)
        );
    }
}
