import './profilePage.mobile.scss';

import * as React from 'react';
import ClassNames from 'classnames';

import * as StM from '../../../../models/store';
import * as SrvM from '../../../../services';
import { Images, Constants } from '../../../../constants';
import * as BP from './baseProfilePage';
import { DisplayField } from '../../../../components';

interface IProfilePageProps extends BP.IProfilePageProps { }

class ProfilePageMobile extends React.Component<IProfilePageProps> {
    private utils = new SrvM.Utils();
    constructor(props: IProfilePageProps) {
        super(props);
    }

    render() {
        const user = this.props.user;
        const gender = user.gender === StM.Gender.Male || user.gender === StM.Gender.Female ? user.gender : StM.Strings.NoInfo;
        const skill = this.utils.getSkill(user.skill) || StM.Strings.NoInfo;
        const emergencyContactInfo = BP.getEmergencyContactInfo(user);
        const dateOfBirth = !!user.dateOfBirth ? user.dateOfBirth.format(BP.DATE_FORMAT) : StM.Strings.NoInfo;
        const isGroupAccount = this.props.isGroupAccount;

        return (
            <div className="user-wrapper-mobile">
                <div className="user-info-wrapper">
                    {this.props.renderAvatar()}
                    <div className="title">{isGroupAccount ? user.group.title : user.displayName}</div>
                    {!isGroupAccount && (
                        <>
                            <DisplayField title="Date of Birth" value={dateOfBirth} />
                            <DisplayField title="Email" value={user.email} />
                            <DisplayField title="Phone" value={user.phone} />
                            <DisplayField title="Gender" value={gender} />
                            <DisplayField title="Skill" value={skill} />
                            <DisplayField title="Invitation" value={`${(user.isAvailableForInvitation ? 'Available' : 'Disable')} for invitation`} />
                            <DisplayField title="Sign up for news" value={user.newsletterSubscribe ? 'Yes' : 'No'} />
                            <DisplayField title="Emergency Contact" value={emergencyContactInfo} />
                        </>
                    )}
                    <div className="divider-line"></div>
                    <div className="btn-wrapper">
                        <button className="btn-mobile btn-black btn-edit" onClick={(e) => this.props.onEditClick(e)}>EDIT</button>
                        {!isGroupAccount && <button className="btn-mobile btn-black btn-change-pswd" onClick={(e) => this.props.onPasswordRecoveryClick(e)}>CHANGE PASSWORD</button>}
                    </div>
                    {this.renderBillingInfo(user)}
                </div>
            </div>
        );
    }

    private renderBillingInfo(user: StM.IUserStoreState) {
        const cardIcon = Images.getCardIcon(user.primaryCreditCardType.toLowerCase());
        const creditCardInfoClasses = ClassNames('card-number', { issues: !user.hasPaymentInfo });
        return (
            <div>
                <div className="divider-line-red"></div>
                <div className="title">Billing Information</div>
                {(!!user.creditCardShort || !!user.outstandingBalance) && (
                    <div className="user-info-item card-info">
                        <div className="user-info-item-title">
                            {!!user.creditCardShort ? 'Credit Card' : 'Oustanding Balance'}
                        </div>
                        {!!user.creditCardShort && <div className="user-info-item-content flex-center-mobile">
                            <span className={creditCardInfoClasses}>{!user.hasPaymentInfo && (<span className="warning"></span>)} {this.formatCardNumber(user.creditCardShort)}</span> <img src={cardIcon} className="img-card" />
                        </div>}
                        {!!user.outstandingBalance && (<div className="user-info-item-content flex-center-mobile">
                            <span className="oustanding-balance-warning">You have outstanding balance of ${user.outstandingBalance}. Please update your billing information to process it.</span>
                        </div>)}
                    </div>
                )
                }
                <div className="btn-wrapper">
                    <button className="btn-mobile btn-black btn-edit" onClick={(e) => this.props.onBillingEditClick(e)}>EDIT</button>
                </div>
            </div>
        );
    }

    private formatCardNumber(number: string): string {
        return number ? `${Constants.CardNumberPlaceholder} ${number.replace(/^X+/i, '')}` : null;
    }
}

export default BP.withProfilePage(ProfilePageMobile);
