import { ISessionStoreState } from './../models/store/sessions/sessionStoreState';
import * as Redux from 'redux';
import * as _ from 'lodash';

import {ThunkService} from '../services/thunkService';
import * as DtoModule from '../models/dto';
import * as StoreModule from '../models/store';
import * as ActM from './';

export class BurgerMenuActions {
    static toggle(): (dispatch: any, getState: () => StoreModule.IGlobalStoreState, thunkService: ThunkService) => any {
        return (dispatch: any, getState: () => StoreModule.IGlobalStoreState, thunkService: ThunkService) => {
            return Promise.resolve(dispatch({
                type: 'BurgerMenu/Toggle'
            }));
        };
    }

    static open(): (dispatch: any, getState: () => StoreModule.IGlobalStoreState, thunkService: ThunkService) => any {
        return (dispatch: any, getState: () => StoreModule.IGlobalStoreState, thunkService: ThunkService) => {
            return Promise.resolve(dispatch({
                type: 'BurgerMenu/Open'
            }));
        };
    }

    static close(): (dispatch: any, getState: () => StoreModule.IGlobalStoreState, thunkService: ThunkService) => any {
        return (dispatch: any, getState: () => StoreModule.IGlobalStoreState, thunkService: ThunkService) => {
            return Promise.resolve(dispatch({
                type: 'BurgerMenu/Close'
            }));
        };
    }
}