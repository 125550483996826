import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as StM from '../../../models/store';

interface ICourtSelectorProps {
    courts: Array<StM.ICourtStoreState>;
}

interface ICourtSelectorState {
}

class CourtSelector extends React.Component<ICourtSelectorProps, ICourtSelectorState> {
    constructor(props: ICourtSelectorProps) {
        super(props);
    }

    render() {
        var selectedCourts = 'current-court';
        return (
            <div className="table-header-wrapper">
                <div className="left-arrow"></div>
                {this.props.courts.map((court)=> { return (
                    <div key={court.id} className="table-header-item">
                        {StM.Strings.CourtPrefix} {court.title}
                    </div>
                )})}
                <div className="right-arrow"></div>
            </div>
        )
    };
};

function mapStateToProps(state: StM.IGlobalStoreState) {
    return { courts: state.club.courts  }
}

function mapDispatchToProps(dispatch: any) {
    return {    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CourtSelector);
