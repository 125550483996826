import moment from 'moment';

import { ThunkService } from '../../services/thunkService';
import { StM, ActM } from '../../modules';

export class VideoPageActions {
    public static init(videoLinkId: string, needSpinner: boolean = true): (dispatch: any) => Promise<void> {
        return (dispatch: any) => {
            if (needSpinner) dispatch(ActM.AppActions.showSpinner());
            const final = () => {
                dispatch(ActM.AppActions.hideSpinner());
                dispatch(ActM.BaseActions.hideSplash());
            }            

            return Promise.resolve(dispatch(ActM.VideoPageActions.getSession(videoLinkId)))
                .then(() => {
                    dispatch({ type: 'Pages/Video/Init', payload: null });
                    final();
                })
                .catch(() => {
                    final();
                });
        };
    }

    static getSession(videoLinkId: string): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<any> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            const { club, pricingTiers } =  getState();
            return thunkService.api.session.getByVideoId(videoLinkId, club, pricingTiers)
                .then((session) => {
                    if (session && session.youtubeIds && session.youtubeIds.length > 0) {
                        session.youtubeIds.forEach((id, index) => {
                            id.courtId = id.courtId == 0 ? index + 1 : id.courtId
                        });
                    }
                    dispatch({ type: 'Pages/Video/Session', payload: session });
                }).catch(() => {
                    dispatch({type: 'Pages/Video/Session', payload: null });
                });
        };
    }

    static changeYoutubeId(youtubeId: StM.IYoutubeIdStoreState): (dispatch: any) => Promise<any> {
        return (dispatch: any) => {
            return Promise.resolve(dispatch({ type: 'Pages/Video/SetYoutubeId', payload: youtubeId }));
        }
    }

    static refreshDictionaries(date: moment.Moment): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<any> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            return thunkService.api.common.getDictionaries()
                .then((dictionaries: StM.ICommonDictionariesStoreState) => {
                    const clubP = dispatch(ActM.ClubActions.init(dictionaries.club));
                    const coachesP = dispatch(ActM.CoachesActions.init(dictionaries.coaches));
                    const coachFeeTiersP = dispatch(ActM.CoachFeeTiersActions.init(dictionaries.coachFeeTiers));
                    const membershipLevelsP = dispatch(ActM.MembershipLevelsActions.init(dictionaries.membershipLevels));
                    const packagesP = dispatch(ActM.PackagesActions.init(dictionaries.packageDefinitions, dictionaries.packageSessionTypes));

                    const allP = Promise.all([clubP, coachesP, coachFeeTiersP, membershipLevelsP, packagesP
                    ]).then(() => {
                        return dispatch({
                            type: 'App/Init'
                        });
                    });
                    return allP;
                }).catch((error) => {
                    thunkService.logger.error(error);
                });
        };
    }
}
