import ClassNames from 'classnames';
import * as React from 'react';
import { Link } from 'react-router-dom';

import { StM, SrvM } from '../../../../modules';

interface ILeagueTabLinkProps {
    routeParams: StM.IBookPageRouteParams;
    onTabClickHandler: (params: any) => void;
}

interface ILeagueTabLinkState {
    isShown?: boolean;
}

export class LeagueTabLink extends React.Component<ILeagueTabLinkProps, ILeagueTabLinkState> {
    private routeSrv = new SrvM.RouteService();
    
    constructor(props: any) {
        super(props);
        this.state = {
            isShown: props.routeParams.sessionType === StM.BookPageSessionType.League
        };
    }

    public componentDidUpdate(prevProps: ILeagueTabLinkProps) {
        if (this.props.routeParams.sessionType !== prevProps.routeParams.sessionType) {
            this.setState({ isShown: this.props.routeParams.sessionType === StM.BookPageSessionType.League });
        }
    }

    public render() {
        const isActive = this.props.routeParams.sessionType === StM.BookPageSessionType.League;
        const classes = ClassNames('side-block-item-wrapper', 'league', { extended: isActive, open: this.state.isShown });
        return (                
            <Link to={this.getRoute()} className={classes} onClick={(e) => this.onTabClick(e)}>
                <div className="side-block-item">
                    <div className="block-item">
                        <div className="block-item-title">LEAGUE</div>
                    </div>    
                </div>
            </Link>
        );
    }

    private onTabClick(e: any): void {
        if (e) { e.stopPropagation(); }
        if (this.props.routeParams.sessionType === StM.BookPageSessionType.League) {
            this.setState({ isShown: !this.state.isShown });
        }
        this.props.onTabClickHandler({ sessionType: StM.BookPageSessionType.League, date: this.props.routeParams.date });
    }

    private getRoute() {
        return this.routeSrv.getBookPage({
            ...this.props.routeParams,
            sessionType: StM.BookPageSessionType.League,
        }).path;
    }
}
