import './dialogs.scss';
import './alertDialog.scss';

import * as _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import * as StM from '../../models/store';
import * as ActM from '../../actions';
import * as SrvM   from '../../services';
import {Modal} from '../../components';


interface ISessionCreateSuccessDialogProps {
    isAuthorized: boolean;
    isShown: boolean;
    isExist: boolean;
    
    closeDialog: () => void;
    openBookDialog: () => Promise<any>;
    openAuthDialog: (url:string) => Promise<any>;
}

interface ISessionCreateSuccessDialogState {}

class SessionCreateSuccessDialog extends React.Component<ISessionCreateSuccessDialogProps, ISessionCreateSuccessDialogState> {
    constructor(props: ISessionCreateSuccessDialogProps) {
        super(props);
    }
    checkoutClick(e:any) {
        if(e) {e.stopPropagation();}
        if (!this.props.isAuthorized) {
            let routeDlgSrv = new SrvM.RouteDialogService();
            let hash = routeDlgSrv.getDialogHash(StM.DialogNames.Book, {});
            let url = encodeURIComponent(hash);
            this.props.openAuthDialog(url);
        } else {
            this.props.openBookDialog();
        }
        this.props.closeDialog();
    }

    closeClick(e:any) {
        if(e) {e.stopPropagation();}
        this.props.closeDialog();
    }
    
    render() {
        const message1 = !this.props.isExist ? 'Session has been created' : 'Session has been added';
        const message2 = !this.props.isExist ? 'and added to your cart' : 'to your cart';
        if (this.props.isShown) {
            return (
                <Modal classes="alert-dialog success session-create" ref={(elem) => setPositionCreatSessionDialog(elem)} closeHandler={this.props.closeDialog} dialogName={StM.DialogNames.SessionCreateSuccess}>
                    <div className="modal-dialog dialog">
                        <div className="modal-dialog-header">
                            <div className="title success create-session-title"><span className="ico-alert-dialog"></span>
                                <div className="alert-dialog-text-wrapper">
                                    <div>{message1}</div>
                                    <div>{message2}</div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-dialog-body">
                            <p className="note">Checkout to confirm your session or Continue to add more.</p>
                            <div className="btns-wrapper">
                                <button onClick={(e) => this.checkoutClick(e)}>Checkout</button>
                                <button onClick={(e) => this.closeClick(e)}>Continue</button>
                            </div>
                        </div>
                    </div>
                </Modal>
            );
        } else {
            return null;
        }
    }
}

const setPositionCreatSessionDialog = (elem:any) => {
        var windowHeight: any = document.body.clientHeight;
        var dialog: any = document.getElementsByClassName('session-create')[0];
        var dialogHeight: any = dialog.offsetHeight;
        var topPosition = (windowHeight - dialogHeight)/2;
        dialog.style.top = topPosition + "px";
}

const mapStateToProps = (state: StM.IGlobalStoreState, ownProps: any) => {
    let dialog = state.dialogs.sessionCreateSuccess;
    return {
        isShown: dialog.isOpened,
        isAuthorized: state.app.isAuthorized,
        isExist: dialog.isExist
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        closeDialog: () => dispatch(ActM.DialogActions.close(StM.DialogNames.SessionCreateSuccess)),
        openBookDialog: () => (dispatch(ActM.DialogActions.open(StM.DialogNames.Book))),
        openAuthDialog: (url:string) => dispatch(ActM.DialogActions.open(StM.DialogNames.Auth, { tab: StM.AuthDialogTabs.SignIn, returnUrl: url})),
    };

}

export default connect(mapStateToProps, mapDispatchToProps)(SessionCreateSuccessDialog);
