import * as React from 'react';
import { compose, Dispatch } from 'redux';
import { connect } from 'react-redux';
import * as _ from 'lodash';

import * as StM from '../../../models/store';
import * as ActM from '../../../actions';
import * as PolM from '../../../policies';
import { PackageDefinitionStoreState } from '../../../models/store';

export interface IBookDialogProps extends IBaseBookDialogState {
    isShown: boolean;
    user: StM.UserStoreState;
    customSessionTypes: Array<StM.ICustomSessionTypeStoreState>;
    coaches: Array<StM.ICoachStoreState>;
    club: StM.ClubStoreState;
    basketSessions: Array<StM.ISessionStoreState>;
    basketPackages: Array<PackageDefinitionStoreState>;
    basketPolicy: PolM.BasketPolicy;

    onCloseClick: (e?: any) => void;
    onCheckoutClick: (e: any) => Promise<any>;
    onRemoveItemClick: (id: number) => void;
    onRemovePackageItemClick: (id: number) => void;
}

export interface IBaseBookDialogProps {
    match: any;
    isShown: boolean;
    basket: StM.BasketStoreState;
    user: StM.UserStoreState;
    club: StM.ClubStoreState;
    isAuthorized: boolean;
    coaches: Array<StM.ICoachStoreState>;
    customSessionTypes: Array<StM.ICustomSessionTypeStoreState>;

    showSpinner: () => any;
    hideSpinner: () => any;
    closeBookDialog: () => void;
    openBillingInfoDialog: () => Promise<any>;
    removeItem: (id: number) => void;
    removePackageItem: (id: number) => void;
    checkout: (sessions: Array<StM.ISessionStoreState>, params: any) => Promise<any>;
    updateCurrentPageSessions: (params: any) => Promise<StM.ISessionStoreState>;
    validateBasket: () => Promise<any>;
    showAlert: (msgKey: string) => Promise<any>;
}

export interface IBaseBookDialogState {}

const withBaseBookDialog = (Component: React.ComponentType<IBookDialogProps>) => {
    return class BaseBookDialog extends React.Component<IBaseBookDialogProps, IBaseBookDialogState> {
        constructor(props: IBaseBookDialogProps) {
            super(props);
        }

        componentDidUpdate(prevProps: IBaseBookDialogProps) {
            const isShownChanged = this.props.isShown !== prevProps.isShown;
    
            if (isShownChanged && this.props.isShown) {
                this.props.validateBasket();
            }
    
            if (this.props.isShown) {
                if (!this.props.basket.goods.length && !this.props.basket.packages.length) {
                    this.props.closeBookDialog();
                }
    
                if (
                    !prevProps.isShown &&
                    _.some(this.props.basket.goods, (x) => x.validationResult && x.validationResult.errors && x.validationResult.errors.length)
                ) {
                    this.props.updateCurrentPageSessions(this.props.match.params);
                }
            }
        }

        render() {
            const basketPolicy = new PolM.BasketPolicy(this.props.club, this.props.basket, this.props.user);
            return (
                <Component
                    {...this.state}
                    user={this.props.user}
                    club={this.props.club}
                    coaches={this.props.coaches}
                    isShown={this.props.isShown}
                    customSessionTypes={this.props.customSessionTypes}
                    basketSessions={this.props.basket.goods}
                    basketPackages={this.props.basket.packages}
                    basketPolicy={basketPolicy}
                    onCloseClick={(e: any) => this.onCloseClick(e)}
                    onCheckoutClick={(e: any) => this.onCheckoutClick(e)}
                    onRemoveItemClick={(id: number) => this.onRemoveItemClick(id)}
                    onRemovePackageItemClick={(id: number) => this.props.removePackageItem(id)}
                />
            );
        }

        private onCloseClick(e?: any) {
            if (e) {
                e.preventDefault();
                e.stopPropagation();
            }
            this.props.closeBookDialog();
        }

        private onCheckoutClick(e: any): Promise<any> {
            if (e) {
                e.preventDefault();
                e.stopPropagation();
            }
            if (!this.props.user.paymentProfileId ||
                this.props.user.paymentProfileStatus !== StM.PaymentProfileStatus.Active) {
                return this.props.openBillingInfoDialog();
            } else {
                const sessions = this.props.basket.goods.slice();
                return this.props.checkout(sessions, this.props.match.params);
            }
        }

        private onRemoveItemClick(id: number) {
            var backToBackValidationFails = _.filter(
                this.props.basket.goods,
                (x) =>
                    x.validationResult &&
                    x.validationResult.errors &&
                    x.validationResult.errors.length &&
                    _.some(x.validationResult.errors, (y) => y.type === 'BackToBack'),
            );
            backToBackValidationFails.forEach((x) => _.remove(x.validationResult.errors, (y) => y.type == 'BackToBack'));

            var peakTimeValidationFails = _.filter(
                this.props.basket.goods,
                (x) =>
                    x.validationResult &&
                    x.validationResult.errors &&
                    x.validationResult.errors.length &&
                    _.some(x.validationResult.errors, (y) => y.type === 'PeakTime'),
            );
            peakTimeValidationFails.forEach((x) => _.remove(x.validationResult.errors, (y) => y.type == 'PeakTime'));

            this.props.showSpinner();
            this.props.removeItem(id);
            this.props
                .validateBasket()
                .then(() => {
                    this.props.hideSpinner();
                    this.props.updateCurrentPageSessions(this.props.match.params);
                })
                .catch(() => {
                    this.props.hideSpinner();
                });
        }
    };
};

const mapStateToProps = (state: StM.IGlobalStoreState) => {
    return {
        match: state.app.match,
        isShown: state.dialogs.book.isOpened,
        basket: state.validatedBasket,
        isAuthorized: state.app.isAuthorized,
        user: state.user,
        club: state.club,
        coaches: state.coaches,
        customSessionTypes: state.customSessions,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        closeBookDialog: () => dispatch(ActM.DialogActions.close(StM.DialogNames.Book)),
        openBillingInfoDialog: () => dispatch(ActM.DialogActions.open(StM.DialogNames.BillingInfo)),
        removeItem: (id: number) => dispatch(ActM.BasketActions.remove(id)),
        removePackageItem: (id: number) => dispatch(ActM.BasketActions.removePackage(id)),
        validateBasket: () => dispatch(ActM.BookDialogActions.validateBasket()),
        checkout: (sessions: Array<StM.ISessionStoreState>, params: any) => dispatch(ActM.BookDialogActions.checkout(sessions, params)),
        updateCurrentPageSessions: (params: any) => dispatch(ActM.SessionActions.updateForCurrentPage(params)),
        showAlert: (msgKey: string) =>
            dispatch(ActM.DialogActions.open(StM.DialogNames.Alert, { msgKey: msgKey, messageType: StM.MessageTypes.Success })),
        showSpinner: () => dispatch(ActM.AppActions.showSpinner()),
        hideSpinner: () => dispatch(ActM.AppActions.hideSpinner()),
    };
};

export const withBookDialog = compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    withBaseBookDialog,
);
