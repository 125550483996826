import './userNavigation.scss';

import * as React from 'react';
import { NavLink } from 'react-router-dom';
import ClassNames from 'classnames';
import { withRouter } from 'react-router-dom';

import * as StM from '../../../models/store';
import * as SrvM from '../../../services';

interface IUserNavigationProps {
    club: StM.IClubStoreState;
    match: any;
    location: any;
    history: any;
    staticContext: any;
    user: StM.IUserStoreState;
}

interface IUserNavigationState {
}

class UserNavigation extends React.Component<IUserNavigationProps, IUserNavigationState> {
    private routeService: SrvM.RouteService;
    private authSrv = new SrvM.AuthenticationService();
    constructor(props: IUserNavigationProps) {
        super(props);
        this.routeService = new SrvM.RouteService();
    }
    render() {
        const profilePageClasses = ClassNames({ active: this.routeService.isActivePage(StM.Pages.User, StM.UserPages.Profile) });
        const historyPageClasses = ClassNames({ active: this.routeService.isActivePage(StM.Pages.User, StM.UserPages.History) });
        const myPackagesPageClasses = ClassNames({ active: this.routeService.isActivePage(StM.Pages.User, StM.UserPages.MyPackages) });
        const myGroupsPageClasses = ClassNames({ active: this.routeService.isActivePage(StM.Pages.User, StM.UserPages.MyGroups) });
        const groupMembersPageClasses = ClassNames({ active: this.routeService.isActivePage(StM.Pages.User, StM.UserPages.GroupMembers) });
        const isGroupAccount = this.authSrv.isInRole(StM.Roles.GroupAccount, this.props.user);
        const historyPath = this.routeService.getHistoryDefaultRoute(this.props.club);
        const ownerPrefix = this.authSrv.getOwnerPrefix(this.props.user);
        return (
            <div className="sq-bredcamp">
                <ul className="list-inline">
                    <li className={profilePageClasses}><NavLink to={`${StM.Pages.User}/${StM.UserPages.Profile}`}>{ownerPrefix} Profile</NavLink></li>
                    <li className={historyPageClasses}><NavLink to={historyPath}>History</NavLink></li>
                    <li className={myPackagesPageClasses}><NavLink to={`${StM.Pages.User}/${StM.UserPages.MyPackages}`}>Club Credits</NavLink></li>
                    {!isGroupAccount && <li className={myGroupsPageClasses}><NavLink to={`${StM.Pages.User}/${StM.UserPages.MyGroups}`}>My Groups</NavLink></li>}
                    {isGroupAccount && <li className={groupMembersPageClasses}><NavLink to={`${StM.Pages.User}/${StM.UserPages.GroupMembers}`}>Group Members</NavLink></li>}
                </ul>
            </div>
        );
    }
}

const UserNavigationController = withRouter(UserNavigation);
export default UserNavigationController;
