import './dialogs.mobile.scss';
import './alertDialog.mobile.scss';

import * as _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import * as StM from '../../models/store';
import * as ActM from '../../actions';
import * as SrvM   from '../../services';
import {ModalMobile} from '../../components';

interface IPackageAddedDialogProps {
    isAuthorized: boolean;
    isShown: boolean;
    
    closeDialog: () => void;
    openBookDialog: () => Promise<any>;
    openAuthDialog: (url:string) => Promise<any>;
    showSpinner: () => any;
    hideSpinner: () => any;
}

interface IPackageAddedDialogState {}

class PackageAddedDialogMobile extends React.Component<IPackageAddedDialogProps, IPackageAddedDialogState> {
    constructor(props: IPackageAddedDialogProps) {
        super(props);
    }
    
    public render() {
        if (this.props.isShown) {

            return (
                <ModalMobile classes="alert-dialog-mobile success session-create" 
                    closeHandler={this.props.closeDialog} 
                    dialogName={StM.DialogNames.PackageAdded}
                    showHeader={false}>
                        <div className="modal-dialog-header">
                            <div className="title success create-session-title">Package has been added to your cart</div>
                        </div>
                        <div className="modal-dialog-body">
                            <p className="note-mobile">Checkout to confirm your purchase or Continue to add more.</p>
                        </div>
                    <div className="modal-dialog-footer">
                        <div className="btns-wrapper">
                            <button className="btn-mobile btn-checkout btn-black" onClick={(e) => this.checkoutClick(e)}>Checkout</button>
                            <button className="btn-mobile btn-continue btn-black" onClick={(e) => this.closeClick(e)}>Continue</button>
                        </div>
                    </div>
                </ModalMobile>
            );
        } else {
            return null;
        }
    }

    private closeClick(e:any) {
        if(e) {e.stopPropagation();}
        this.closeDialog()
    }

    private closeDialog(){
        this.props.closeDialog();
    }

    private checkoutClick(e:any) {
        if(e) {e.stopPropagation();}
       
        if (!this.props.isAuthorized) {
            const thunk = new SrvM.RouteDialogService();
            const hash = thunk.getDialogHash(StM.DialogNames.Book, {});
            const url = encodeURIComponent(hash);
            this.props.openAuthDialog(url);
        } else {
            this.props.openBookDialog();
        }
        this.closeDialog()
    }
}

const mapStateToProps = (state: StM.IGlobalStoreState, ownProps: any) => {
    let dialog = state.dialogs.packageAdded;
    return {
        isShown: dialog.isOpened,
        isAuthorized: state.app.isAuthorized
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        closeDialog: () => dispatch(ActM.DialogActions.close(StM.DialogNames.PackageAdded)),
        openBookDialog: () => (dispatch(ActM.DialogActions.open(StM.DialogNames.Book))),
        openAuthDialog: (url:string) => dispatch(ActM.DialogActions.open(StM.DialogNames.Auth, { tab: StM.AuthDialogTabs.SignIn, returnUrl: url})),
        showSpinner: () => dispatch(ActM.AppActions.showSpinner()),
        hideSpinner: () => dispatch(ActM.AppActions.hideSpinner())
    };

}

export default connect(mapStateToProps, mapDispatchToProps)(PackageAddedDialogMobile);
