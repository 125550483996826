import './emptyCourtBoard.scss';
import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as StoreModule from '../../../models/store';
import * as ServiceModule from '../../../services';
import { Badge } from './badges';

interface IEmptyCourtBoardProps {
    message?: string;
}

interface IEmptyCourtBoardState {
}

export class EmptyCourtBoard extends React.Component<IEmptyCourtBoardProps, IEmptyCourtBoardState> {
    constructor(props: IEmptyCourtBoardProps) {
        super(props);
    }

    render() {
        
        return (
            <div className="table-column empty">
                <span>{this.props.message ? this.props.message : "Courts Closed"}</span>
            </div>
        )
    }
}
