import * as _ from 'lodash';

import { ActM, StM, SrvM, Constants } from '../../../modules';
import { store } from '../../../store/store';

const utils = new SrvM.Utils();
const routeSrv = new SrvM.RouteService();

export class CommonBookPage {
    private static prevParams = {
        sessionType: '',
        date: '',
        playSubfilter: '',
        lessonSubfilter: '',
        lessonSubfilterId: '',
        clinicSubfilter: '',
        clinicSubfilterId: '',
        otherSubfilter: '',
        memberId: '',
        isLessonShowCoachSelecting: false,
    };

    public static initPrevParams(filter: StM.IBookPageRouteParams) {
        this.changeFilter(filter);
    }

    public static changeFilter(filter: StM.IBookPageRouteParams, prevParams: any = null) {
        const { user, pages, coachFeeTiers, coaches, club } = store.getState() as StM.IGlobalStoreState;
        const { availableTimesLookup } = pages.book;

        if (filter.date && !filter.sessionType && this.prevParams.sessionType == StM.BookPageSessionType.League) {
            filter.sessionType = StM.BookPageSessionType.Play;
        }

        if (!filter.sessionType && prevParams) {
            this.updatePrevParams(prevParams, club);
        }

        const handledFilter = {
            sessionType: filter && filter.sessionType ? filter.sessionType : this.prevParams.sessionType,
            date: filter && filter.date ? filter.date : this.prevParams.date,
            playSubfilter: filter && filter.playSubfilter ? filter.playSubfilter : this.prevParams.playSubfilter,
            lessonSubfilter: filter && filter.lessonSubfilter ? filter.lessonSubfilter : this.prevParams.lessonSubfilter,
            lessonSubfilterId: filter && filter.lessonSubfilterId ? filter.lessonSubfilterId : this.prevParams.lessonSubfilterId,
            clinicSubfilter: filter && filter.clinicSubfilter ? filter.clinicSubfilter : this.prevParams.clinicSubfilter,
            clinicSubfilterId: filter && filter.clinicSubfilterId ? filter.clinicSubfilterId : this.prevParams.clinicSubfilterId,
            otherSubfilter: filter && filter.otherSubfilter ? filter.otherSubfilter : this.prevParams.otherSubfilter,
            memberId: filter && filter.memberId ? filter.memberId : this.prevParams.memberId,
            isLessonShowCoachSelecting: !!this.prevParams.sessionType,
        };
        
        if (filter && filter.sessionType === StM.BookPageSessionType.League && utils.isMobile()) handledFilter.sessionType = StM.BookPageSessionType.Play;
        if (filter && !filter.lessonSubfilter) handledFilter.lessonSubfilter = 'coach';
        if (filter && filter.lessonSubfilter && !filter.lessonSubfilterId) handledFilter.lessonSubfilterId = '';
        if (filter && filter.clinicSubfilter && !filter.clinicSubfilterId) handledFilter.clinicSubfilterId = 'all';
        if (this.checkIsToday(handledFilter.date, club)) handledFilter.date = Constants.TodayRouteParam;

        const defaultCoach = _.first(utils.getOrderedCoachesDesktop(coaches, coachFeeTiers, availableTimesLookup,
            handledFilter.date == 'today' ? utils.getCurrentClubDateTime(club).format(Constants.DateTime.DATE_FORMAT) :  handledFilter.date));
        const defaultMember =
            user && user.group && user.group.members.length
                ? _.first(
                      user.group.members
                          .filter((m) => m.type !== StM.GroupMemberType.Head)
                          .sort((a, b) => (a.user.displayName > b.user.displayName ? 1 : -1))
                  )
                : null;
        const routeData = routeSrv.getBookPage(handledFilter, defaultCoach, defaultMember);
        store.dispatch(ActM.RouteActions.replace(routeData.path));
        this.updatePrevParams(routeData.filter, club);
    }

    private static updatePrevParams(params: StM.IBookPageRouteParams, club: StM.IClubStoreState) {
        this.prevParams.sessionType = params && params.sessionType ? params.sessionType : this.prevParams.sessionType;
        this.prevParams.date = params && params.date ? params.date : this.prevParams.date;
        this.prevParams.playSubfilter = params && params.playSubfilter ? params.playSubfilter : this.prevParams.playSubfilter;
        this.prevParams.lessonSubfilter = params && params.lessonSubfilter ? params.lessonSubfilter : this.prevParams.lessonSubfilter;
        this.prevParams.lessonSubfilterId = params && params.lessonSubfilterId ? params.lessonSubfilterId : this.prevParams.lessonSubfilterId;
        this.prevParams.memberId = params && params.memberId ? params.memberId : this.prevParams.memberId;
        if (this.checkIsToday(this.prevParams.date, club)) this.prevParams.date = Constants.TodayRouteParam;
    }

    private static checkIsToday(date: string, club: StM.IClubStoreState) {
        return utils.getCurrentClubDateTime(club).format(Constants.DateTime.DATE_FORMAT) === date;
    }
}
