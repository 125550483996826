import * as React from 'react';
import * as StM from '../../../models/store';
import moment from 'moment';
import { Constants } from '../../../constants';

interface ICoachInfoProps {
    coach: StM.ICoachStoreState;
    go?: (url: string) => any;
}

interface ICoachInfoState { }

export class CoachInfo extends React.Component<ICoachInfoProps, ICoachInfoState> {

    nameClick(e: any) {
        if (e) e.stopPropagation();
        if (!this.props.go) return false;
        let handledDate = moment().format(Constants.DateTime.DATE_FORMAT);
        let url = '/book/lesson/{0}/{1}/{2}'.format(handledDate, StM.BookPageLessonFilterType.Coach, this.props.coach.id);
        this.props.go(url);
    }
    render() {
        const image = this.props.coach && this.props.coach.imageId ? this.props.coach.imageId : '';
        const urlimg = image ? `/api/v2/blobs/${image}/content` : '/content/img/nophoto-2.png';

        const displayName = this.props.coach && this.props.coach.displayName ? this.props.coach.displayName : 'no name';
        const displayTier = this.props.coach && this.props.coach.coachFeeTier ? this.props.coach.coachFeeTier.title  : 'no tier';
        const displayDescription = this.props.coach && this.props.coach.description ? this.props.coach.description : 'no description';
        return (
            <div className="coaches-item">
                <div className="coaches-item-photo-wrapper">
                    <img src={(urlimg)} className="coach-photo" width="250px" height="250px" />
                </div>
                <div className="coaches-item-info-wrapper">
                    <div className="coaches-item-info">
                        <div className='coach-description'>
                            <div className="coach-name" onClick={(e) => this.nameClick(e)}>{displayName}</div>
                            <div className="coach-tier">{displayTier}</div>
                            <div className="coach-biography" dangerouslySetInnerHTML={{__html: displayDescription}}></div>
                        </div>
                        <button className="btn-book-lesson-coach" onClick={(e) => this.nameClick(e)}>BOOK A LESSON</button>
                    </div>
                </div>
            </div>
        )
    };
};