import './filterBoard.scss';

import * as React from 'react';
import moment from 'moment';
import * as _ from 'lodash';

import { StM, SrvM } from '../../../../modules';
import { CalendarFilterBoard, PlayTabLink, LessonTabLink, ClinicTabLink, CustomTabLink, LeagueTabLink, GroupMembersTabLink } from '.';

interface IFilterBoardProps {
    routeParams: StM.IBookPageRouteParams;
    customSessions: StM.ICustomSessionTypeStoreState[];
    coaches: StM.ICoachStoreState[];
    coachFeeTiers: StM.ICoachFeeTierStoreState[];
    collapseCalendar: boolean;
    availableTimesLookup: IDictionary;
    user: StM.IUserStoreState;
    club: StM.IClubStoreState;

    onChangeFilterParamHandler: (params: any) => void;
}

class FilterBoard extends React.Component<IFilterBoardProps> {
    private utils = new SrvM.Utils();
    private authSrv = new SrvM.AuthenticationService();
    private routeSrv = new SrvM.RouteService();
    constructor(props: IFilterBoardProps) {
        super(props);
    }

    public render() {
        const coaches = this.props.coaches;
        const coachFeeTiers = this.props.coachFeeTiers;
        const availableTimesLookup = this.props.availableTimesLookup;
        const leagueAvailable = this.props.customSessions.findIndex(item => item.alias && item.alias === 'league') !== -1;
        const user = this.props.user;
        const isGroup = this.authSrv.isInRole(StM.Roles.GroupAccount, user);
        const groupMembers = user && user.group 
            ? user.group.members.filter(m => m.invitationStatus === StM.InvitationStatus.Accepted) 
            : [];
        const date = this.routeSrv.getRouteDate(this.props.routeParams.date, this.props.club);
        return (
            <div className="side-block-wrapper">
                {!this.isClinicListView() && <CalendarFilterBoard date={date} onDateChangedHandler={(date) => this.onDateClickHandler(date)} collapse={this.props.collapseCalendar} />}
                {isGroup && <GroupMembersTabLink routeParams={this.props.routeParams} user={this.props.user} members={groupMembers} onTabClickHandler={(params: any) => this.onTabClickHandler(params)}/>}
                <PlayTabLink routeParams={this.props.routeParams} onTabClickHandler={(params: any) => this.onTabClickHandler(params)} />
                <LessonTabLink routeParams={this.props.routeParams} club={this.props.club} coaches={coaches} coachFeeTiers={coachFeeTiers} availableTimesLookup={availableTimesLookup} onTabClickHandler={(params: any) => this.onTabClickHandler(params)} />
                <ClinicTabLink routeParams={this.props.routeParams} onTabClickHandler={(params: any) => this.onTabClickHandler(params)} />
                <CustomTabLink routeParams={this.props.routeParams} onTabClickHandler={(params: any) => this.onTabClickHandler(params)} />
                {leagueAvailable && <LeagueTabLink routeParams={this.props.routeParams} onTabClickHandler={(params: any) => this.onTabClickHandler(params)} />}
            </div>
        )
    }

    private onDateClickHandler(date: moment.Moment) {
        const dateFormat = this.utils.getDateFormat(date);
        this.props.onChangeFilterParamHandler({ date: dateFormat });
    }

    private onTabClickHandler(params: any) {
        this.props.onChangeFilterParamHandler(params);
    }

    private isClinicListView(): boolean {
        const routeService = new SrvM.RouteService();
        return routeService.isActivePage(StM.Pages.Book, StM.BookPageSessionType.Clinic) && _.includes(window.location.pathname, 'list');
    }
}

export default FilterBoard;
