import './leagueListViewPage.scss';

import * as React from 'react';
import { connect } from 'react-redux';

import * as StM from '../../../../models/store';
import * as ActM from '../../../../actions';
import FilterBoard from '../../../../components/pages/book/filterBoard/filterBoard';
import { CommonBookPage } from '../commonBookPage';

interface ILeagueListViewPageProps {
    params: StM.IBookPageRouteParams;
    match: StM.IBookPageRouteParamsMatch;
    club: StM.IClubStoreState;
    customSessions: StM.ICustomSessionTypeStoreState[];
    coaches: Array<StM.ICoachStoreState>;
    coachFeeTiers: Array<StM.ICoachFeeTierStoreState>;
    sessions: Array<StM.ISessionStoreState>;
    availableTimesLookup: IDictionary;
    user: StM.IUserStoreState;

    init: () => Promise<any>;
    go: (url: string) => any;
    openRequestToJoinDialog: (sessionId: number) => Promise<any>;
    getSessions: () => Promise<any>;
}

interface ILeagueListViewPageState {}

class LeagueListViewPage extends React.Component<ILeagueListViewPageProps, ILeagueListViewPageState> {    
    constructor(props: ILeagueListViewPageProps) {
        super(props);
    }

    componentDidMount (){
        this.props.init();
    }

    shouldComponentUpdate(nextProps: ILeagueListViewPageProps, nextState: ILeagueListViewPageState): boolean{
        if(nextProps.club.id ===0 ){ return false;}
        return true;
    }

    render() {
        return (
            <div className="main-content-wrapper clinic-list-view">
                <div className="leftblock-table-wrapper">
                    <FilterBoard routeParams={this.props.match.params} 
                        coaches={this.props.coaches} 
                        user={this.props.user}
                        club={this.props.club}
                        customSessions={this.props.customSessions}
                        coachFeeTiers={this.props.coachFeeTiers} 
                        availableTimesLookup={this.props.availableTimesLookup}
                        onChangeFilterParamHandler={(params: any)=> CommonBookPage.changeFilter(params)}
                        collapseCalendar={true}
                    />
                    <div className="main-content-wrapper league-list">
                        <div className="header-league-wrapper">
                            <div className="title">JOIN A LEAGUE</div>
                            <div className="sub-title">Want to join a team, and pitch your game against another's – join a league.</div>
                        </div>
                        <div className="league-lest-wrapper">
                            <ul className="league-list">
                                <li className="league-list-item">
                                    <button className="btn-request-join" onClick={(e) => {this.onRequestToJoinClick(e, 1);}}>Request to join</button>
                                    <div className="league-list-item-title">{this.props.club.title} League</div>
                                    <div className="league-description">
                                        If you are interested in learning about our league please register your interest and we will contact you with details of our next League season.
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
       )
   }
   
    onRequestToJoinClick(e: any, sessionId: number){
        if(e){ e.preventDefault(); e.stopPropagation();}
        this.props.openRequestToJoinDialog(sessionId);
    }
}

function mapStateToProps(state: StM.IGlobalStoreState, ownProps: any) {
    let sessionType: string = ownProps.location.pathname.split('/')[2];
    sessionType = sessionType ? sessionType.toLowerCase(): sessionType;
    if(ownProps && ownProps.match.params){
        ownProps.match.params['sessionType'] = sessionType;
    }
    return {
         club: state.club
        , customSessions: state.customSessions
        , coaches: state.coaches
        , coachFeeTiers: state.coachFeeTiers
        , sessions: state.pages.leagues.sessions
        , availableTimesLookup: state.pages.book.availableTimesLookup
        , user: state.user
     }
}

function mapDispatchToProps(dispatch: any) {
    return {  
        init: () => dispatch(ActM.LeagueListViewPageActions.init())
        , go: (url: string) => dispatch(ActM.RouteActions.push(url))
        , openRequestToJoinDialog: (sessionId: number) => dispatch(ActM.DialogActions.open(StM.DialogNames.LeagueRequestToJoin, {sessionId: sessionId}))
        , getSessions: () => dispatch(ActM.LeagueListViewPageActions.getSessions())
      }
}

export default connect(mapStateToProps, mapDispatchToProps)(LeagueListViewPage);
