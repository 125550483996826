import './baseBadge.mobile.scss';
import './eventBadge.mobile.scss';

import * as React from 'react';
import ClassNames from 'classnames';
import * as _ from 'lodash';

import { StM, PolM, SrvM } from '../../../../modules';
import { IBaseBadgeProps, CommonBadgeMobile } from './baseBadge.mobile';
import { PriceBadgeMobile } from './priceBadge.mobile';
import Credits from '../../../credits/credits';

interface IEventBadgeProps extends IBaseBadgeProps {
    user?: StM.IUserStoreState;
    club: StM.IClubStoreState;
    basket: StM.IBasketStoreState;
    courts: Array<StM.ICourtStoreState>;
    sessionType: string;
    courtTimeBlocks: Array<StM.ICourtTimeBlockStoreState>;
    timeKey: string;
    expandedSlotTimeKey: string;
    coaches: Array<StM.ICoachStoreState>;
    coachFeeTiers: Array<StM.ICoachFeeTierStoreState>;
    params: StM.IBookPageRouteParams;
    onCreateSessionClick: (courtId: number, timeKey: string) => any;
    openSessionInfo: (from: string, id: number) => void;
    onExpandSlot: (timeKey: string) => void;
}

interface IEventBadgeState {
    isShow: boolean;
    isExpanded: boolean;
}

export class EventBadgeMobile extends React.Component<IEventBadgeProps, IEventBadgeState> {
    private utils: SrvM.Utils;
    constructor(props: IEventBadgeProps) {
        super(props);
        this.state = {
            isShow: true,
            isExpanded: this.props.timeKey == this.props.expandedSlotTimeKey
        };
        this.utils = new SrvM.Utils();
    }

    componentDidUpdate(prevProps) {
        const { sessionType, courtTimeBlocks, params, timeKey, expandedSlotTimeKey } = this.props;

        const isSessionTypeChanged = !_.isEqual(prevProps.sessionType, sessionType);
        const isSessionsChanged = !_.isEqual(prevProps.courtTimeBlocks, courtTimeBlocks);
        const isParamsChanged = !_.isEqual(prevProps.params, params);
        const shouldExpand = timeKey === expandedSlotTimeKey;

        if (isSessionTypeChanged || isSessionsChanged || isParamsChanged) {
            this.reset();
        } else if (this.state.isExpanded !== shouldExpand) {
            this.setState({ isExpanded: shouldExpand });
        }
    }

    private reset() {
        this.setState({ ...this.state, isExpanded: false, isShow: true });
        this.props.onExpandSlot("");
    }

    renderSessions() {
        let sessions = this.props.courtTimeBlocks.filter(block => {
            const sessionAvailability = new PolM.SessionAvailabilityPolicy(block.session, [], this.props.user);
            return (block.isInvited && block.isBought) || (block.isAvailableTime || block.isBought || sessionAvailability.getIsTrainer());
        });
        let playSessions = this.filterSessions(sessions, StM.BookPageSessionType.Play);
        let lessonSessions = this.filterSessions(sessions, StM.BookPageSessionType.Lesson);
        let clinicSessions = this.filterSessions(sessions, StM.BookPageSessionType.Clinic);
        let customSessions = _.uniqBy(this.filterSessions(sessions, StM.BookPageSessionType.Custom), i => i.session.id);
        if (playSessions.length == 0 && lessonSessions.length == 0 && clinicSessions.length == 0 && customSessions.length == 0) {
            return null;
        }

        let badgePrice = CommonBadgeMobile.getMinBadgePrice(this.props.courtTimeBlocks);
        let badgeCredits = CommonBadgeMobile.getMinBadgeCredits(this.props.courtTimeBlocks);
        let hasCredits = CommonBadgeMobile.getIsHasCredits(this.props.courtTimeBlocks);

        if ((this.props.params.sessionType == StM.BookPageSessionType.Play || this.props.params.sessionType == StM.BookPageSessionType.Lesson)
            && playSessions.length == 0 && lessonSessions.length == 0 && clinicSessions.length == 0 && customSessions.length == 0
            && isFinite(badgePrice) && !isNaN(badgePrice)
        ) {
            let badgePrice = CommonBadgeMobile.getMinBadgePrice(this.props.courtTimeBlocks);
            let badgeCredits = CommonBadgeMobile.getMinBadgeCredits(this.props.courtTimeBlocks);
            let hasCredits = CommonBadgeMobile.getIsHasCredits(this.props.courtTimeBlocks);
            return (
                <div className="price-wrapper-mobile">
                    {!hasCredits && <span className="price-mobile">${badgePrice}</span>}
                    {hasCredits && <span className="credits-mobile">{badgeCredits}</span>}
                </div>
            );
        }

        return (
            <div className="session-list-wrapper-mobile">
                {playSessions.length > 0 && this.renderSessionsCount(playSessions, StM.BookPageSessionType.Play)}
                {lessonSessions.length > 0 && this.renderSessionsCount(lessonSessions, StM.BookPageSessionType.Lesson)}
                {clinicSessions.length > 0 && this.renderClinicsCount(clinicSessions)}
                {customSessions.length > 0 && this.renderCustomSessionsCount(customSessions)}
            </div>
        );
    }

    renderSessionsCount(sessions: Array<StM.ICourtTimeBlockStoreState>, sessionType: string) {
        let routeSessionType = this.props.sessionType;
        let title = "";
        switch (sessionType) {
            case StM.BookPageSessionType.Play:
                title = "Play Session ";
                break;
            case StM.BookPageSessionType.Lesson:
                title = "Lesson ";
                break;
        }
        let sessionWrapperClasses = ClassNames("session-item-wrapper-mobile", sessionType.toLowerCase());
        let isOwner = sessions.every(session => { return session.isOwner });
        switch (routeSessionType) {
            case StM.BookPageSessionType.Play:
            case StM.BookPageSessionType.Lesson:
                title = (isOwner ? "My " : "") + title;
                break;
        }
        return (
            <div className={sessionWrapperClasses}>
                <span className="session-title-mobile">{title}</span>
                {sessions.length > 1 &&
                    (<span className="session-count-mobile">({sessions.length})</span>)
                }
            </div>
        );
    }

    renderCustomSessionsCount(sessions: Array<StM.ICourtTimeBlockStoreState>) {
        let sessionWrapperClasses = ClassNames("session-item-wrapper-mobile", "custom");
        return (
            <div className={sessionWrapperClasses}>
                <span className="session-title-mobile">{sessions.length > 1 ? "Other Sessions" : sessions[0].session.title}</span>
                {sessions.length > 1 &&
                    (<span className="session-count-mobile">({sessions.length})</span>)
                }
            </div>
        );
    }

    renderClinicsCount(clinics: Array<StM.ISessionStoreState>) {
        const title = "Clinic ";
        let sessionWrapperClasses = ClassNames("session-item-wrapper-mobile", "clinic");
        return (
            <div className={sessionWrapperClasses}>
                <span className="session-title-mobile">{title}</span>
                {clinics.length > 1 &&
                    (<span className="session-count-mobile">({clinics.length})</span>)
                }
            </div>
        );
    }

    renderInvitations() {
        let sessions = this.props.courtTimeBlocks.filter(block => !!block.session && block.isInvited && !block.isBought && block.isAvailableTime);
        if (!sessions || sessions.length == 0) {
            return null;
        }
        const session: StM.ISessionStoreState = sessions[0].session;
        let invitationTitle = sessions.length > 1 ?
            "Invitation" :
            "Invitation to a {0}".format(session.type === StM.SessionType.Custom ? session.title + ' Session' : this.utils.getSessionTypeTitle(session));
        return (
            <div className="invitation-wrapper-mobile">
                <span className="invitation-title-mobile">{invitationTitle} </span>
                {sessions.length > 1 &&
                    <span className="invitation-count-mobile">({sessions.length})</span>
                }
            </div>
        );
    }

    renderExpandedBagde() {
        let sessionBlocks = _.uniqBy(this.getSessions(this.props.courtTimeBlocks), i => i.session.id);
        let isCourtsAvailable = !this.props.courtTimeBlocks.every(block => { return !(block.isAvailableTime && block.isFilter && !block.session) });
        return (
            <div className="badge-mobile badge-mobile-event-expanded">
                {(isCourtsAvailable &&
                    this.props.sessionType != StM.BookPageSessionType.Clinic &&
                    this.props.sessionType != StM.BookPageSessionType.Join) &&
                    <PriceBadgeMobile
                        user={this.props.user}
                        courtTimeBlocks={this.props.courtTimeBlocks}
                        courts={this.props.courts}
                        expandedSlotTimeKey={this.props.expandedSlotTimeKey}
                        timeKey={this.props.timeKey}
                        params={this.props.params}
                        isEventShow={this.state.isShow}
                        onExpandHandle={(isExpand) => this.onExpandHandle(isExpand)}
                        sessionType={this.props.sessionType}
                        coaches={this.props.coaches}
                        coachFeeTiers={this.props.coachFeeTiers}
                        onCreateSessionClick={(courtId, timeKey) => this.props.onCreateSessionClick(courtId, timeKey)}
                        onExpandSlot={(timeKey) => this.props.onExpandSlot(timeKey)} />
                }

                {(this.state.isExpanded && this.state.isShow) &&
                    <div className="event-list-wrapper-mobile">
                        {sessionBlocks.filter(block => {
                            const sessionAvailability = new PolM.SessionAvailabilityPolicy(block.session, []);
                            return (!this.getIsClinicOrCustomClinic(block.session) 
                                && ((!block.isInvited || (block.isInvited && block.isBought))) || sessionAvailability.getIsTrainer());
                        }).map((sessionBlock) => {
                            return this.renderEventItem(sessionBlock);
                        })}
                        {sessionBlocks
                            .filter((block) => {
                                const sessionAvailability = new PolM.SessionAvailabilityPolicy(block.session, []);
                                return !sessionAvailability.getIsTrainer() && this.getIsClinicOrCustomClinic(block.session)
                                    && !block.isInvited && this.getIsFuture(block.session)
                            })
                            .map(sessionBlock => {
                                return this.renderEventItem(sessionBlock);
                            })}
                        {sessionBlocks.filter(block => block.isInvited && !block.isBought && block.isAvailableTime).map((sessionBlock) => {
                            return this.renderEventItem(sessionBlock);
                        })}
                    </div>
                }

                {(this.state.isExpanded && this.state.isShow) && <div className="badge-collapse-btn-mobile mobile-row" onClick={(e) => this.onCollapseBadge(e)}></div>}

            </div>
        );
    }

    render() {
        return (
            (this.state.isExpanded) ?
                (this.renderExpandedBagde()) :
                (<div className="badge-mobile badge-mobile-event" onClick={(e) => this.onBadgeClick(e)}>
                    <div className="events-wrapper-mobile">
                        <div className="sessions-notifications-wrapper-mobile">
                            {this.renderSessions()}
                            {this.renderInvitations()}
                        </div>
                    </div>
                </div>)
        )
    }

    renderEventItem(block: StM.CourtTimeBlockStoreState) {
        const { session } = block;
        const sessionAvailabilityPolicy = new PolM.SessionAvailabilityPolicy(session, this.props.basket.goods, this.props.user);
        let eventItemClasses = ClassNames("event-item-wrapper-mobile", this.utils.getSessionClass(session));
        const isInvited = sessionAvailabilityPolicy.getIsInvited();
        const isBought = sessionAvailabilityPolicy.getIsBoughtUser();
        const isBasket = sessionAvailabilityPolicy.getIsBasket();
        let sessionTitleClasses = ClassNames("session-title-mobile", { "notification-title-mobile": isInvited && !isBought });
        const pricesPolicy = new PolM.PricesPolicy(session.isDoubledSession, session);
        const prices = pricesPolicy.handle();
        let price = isBasket ? session.checkoutPrice : prices.price;
        price += prices.servicesPrice;
        const credits = isBasket ? session.checkoutCredits : prices.credits;
        const handledPrice = this.utils.formatStringPrice(price.toString());

        const showPriceInCredits = PolM.PackagePolicy.showPriceInCredits(session, block) && !!prices.credits;
        
        let sessionColorStyles: any = {};
        if (session.type === StM.SessionType.Custom) {
            sessionColorStyles.backgroundColor = session.customBackgroundColor;
        }
        const courtText = this.utils.getReadableSessionCourtsTitle(session);

        return (
            <div className={eventItemClasses} key={session.id} onClick={(e) => this.openSessionInfo(e, session)}>
                <div className="mobile-row time-skill-wrapper-mobile flex-baseline-mobile">
                    <div className="mobile-col-11 time-range-wrapper-mobile flex-baseline-mobile">
                        <div className="time-wrapper-mobile">
                            <div className="time-mobile">{session.startDateTime.format("h:mm")}</div>
                            <div className="time-type-mobile">{session.startDateTime.format("a")}</div>
                        </div>
                        <div className="time-divider-mobile">&ndash;</div>
                        <div className="time-wrapper-mobile">
                            <div className="time-mobile">{session.endDateTime.format("h:mm")}</div>
                            <div className="time-type-mobile">{session.endDateTime.format("a")}</div>
                        </div>
                    </div>
                    <div className="mobile-col-1 level-indicator-mobile">
                        {this.renderSkillLvl(session)}
                    </div>
                </div>
                <div className="mobile-row session-title-wrapper-mobile">
                    <i className="session-color" style={sessionColorStyles}></i>
                    <div className={sessionTitleClasses}>
                        <span className="title">{this.getSessionTitle(session)}</span>
                        {this.utils.hasVideo(session) && <span className="ic_video"></span>}
                    </div>
                </div>
                <div className="mobile-row court-coach-wrapper-mobile">
                    <div className="mobile-col-12 flex-center-mobile">
                        <span className="court-name-mobile">{courtText}</span>
                        {this.renderCoach(session.trainerId)}
                    </div>
                </div>
                <div className="price-info-mobile mobile-row flex-center-mobile">
                    <div className="mobile-col-6 slots-mobile">
                        {this.renderSlot(session)}
                    </div>
                    {!showPriceInCredits && (
                        <div className="mobile-col-6 price-wrapper-mobile">
                            <span className="price-mobile">${handledPrice}</span>
                        </div>
                    )}
                    {showPriceInCredits && (
                        <div className="mobile-col-6 credits-wrapper-mobile">
                            <Credits credits={credits} type={session.type} bgColor={session.customBackgroundColor} small />
                            {!!prices.servicesPrice && <span className="price-mobile">+ ${this.utils.formatStringPrice(prices.servicesPrice.toString())}</span>}
                        </div>
                    )}
                </div>
            </div>
        );
    }

    private filterSessions(courtTimeBlocks: Array<StM.ICourtTimeBlockStoreState>, sessionType: string) {
        let result = [];
        const routedFilter = this.getRoutedFilter();
        const routeSessionType = this.props.sessionType;

        switch (sessionType) {
            case StM.BookPageSessionType.Play:
                result = courtTimeBlocks.filter(block => { return !!block.session && block.session.type == StM.SessionType.Play && routedFilter(block); });
                break;
            case StM.BookPageSessionType.Lesson:
                result = courtTimeBlocks.filter(block => { return !!block.session && block.session.type == StM.SessionType.Private && routedFilter(block); });
                break;
            case StM.BookPageSessionType.Clinic:
                result = courtTimeBlocks.filter(block => { return !!block.session && this.getIsClinicOrCustomClinic(block.session) && routedFilter(block)});
                break;
            case StM.BookPageSessionType.Custom:
                result = courtTimeBlocks.filter(block => {
                    const isCustom = !!block.session && block.session.type == StM.SessionType.Custom;
                    const isCustomClinic = !!block.session && block.session.typeAlias === 'clinic';
                    return isCustom && !isCustomClinic && routedFilter(block);
                });
                break;
            default:
                result = courtTimeBlocks.filter(block => { return !!block.session && block.session.type == StM.SessionType.Play && routedFilter(block); });
                break;
        }

        return result;
    }

    private getRoutedFilter(): (block: StM.ICourtTimeBlockStoreState) => boolean {
        return (block: StM.ICourtTimeBlockStoreState) => {
            const routeSessionType = this.props.sessionType;
            const sessionAvailability = new PolM.SessionAvailabilityPolicy(block.session, []);
            const isOpen = block.isOpenBoard;
            const isBought = block.isBought;
            const isOwner = sessionAvailability.getIsOwner();
            const isShared = this.getIsShared(block.session);
            const isFuture = block.isFutureTime;
            const isClinic = block.session.type == StM.SessionType.Clinic;
            const isCustom = block.session.type === StM.SessionType.Custom;
            const isTrainer = sessionAvailability.getIsTrainer();
            const isAvailableTime = block.isAvailableTime;
            let result = false;

            switch (routeSessionType) {
                case StM.BookPageSessionType.Join:
                    result = (isOpen && !isOwner && isShared && isFuture && isAvailableTime && !isBought);
                    break;
                case StM.BookPageSessionType.Clinic:
                    result = (this.getIsClinicOrCustomClinic(block.session) && !isBought && isFuture && isAvailableTime) || isBought || isTrainer;
                    break;
                default:
                    result = isBought || (!!block.session && isTrainer && isFuture);
                    break;
            }
            return result;
        };
    }

    private getIsFuture(session: StM.ISessionStoreState) {
        return session && this.utils.getIsFuture(this.props.club, session.endDateTime, this.utils.getDurationTime(session.endDateTime));
    }

    private getSessions(courtTimeBlocks: Array<StM.ICourtTimeBlockStoreState>) {
        let routeSessionType = this.props.sessionType;
        if (_.includes([StM.BookPageSessionType.Join, StM.BookPageSessionType.Clinic], routeSessionType)) {
            const isJoin = routeSessionType === StM.BookPageSessionType.Join;
            return courtTimeBlocks.filter(block => {
                const session = block.session;
                if (!session) return false;
                let result = false;
                const sessionAvailability = new PolM.SessionAvailabilityPolicy(session, []);
                const isOpen = block.isOpenBoard && !sessionAvailability.getIsOwner() && this.getIsShared(session);
                const isTrainer = sessionAvailability.getIsTrainer();
                if(!isJoin) {
                    const isClinic = this.getIsClinicOrCustomClinic(session);
                    result = ((isClinic && isOpen && block.isAvailableTime) || block.isBought || (block.isInvited && block.isAvailableTime) || isTrainer);
                } else {
                    result = block.isAvailableTime && ((isOpen && !block.isBought) || block.isInvited);
                }
                return result;
            });
        }
        return courtTimeBlocks.filter(block => {
            let sessionAvailability = new PolM.SessionAvailabilityPolicy(block.session, []);
            return !!block.session && ((block.isBought || (block.isInvited && block.isAvailableTime)) || sessionAvailability.getIsTrainer())
        });
    }

    private getIsShared(session: StM.ISessionStoreState) {
        return session && !session.isHidden;
    }


    renderSkillLvl(session: StM.ISessionStoreState) {
        if (session.playerQualification && session.playerQualification.skill) {
            let skill = session.playerQualification.skill;
            let skillClass = this.getSkillClass(skill)
            let classes = ClassNames("lvl", skillClass);
            if (skillClass) return (
                <div className={classes}></div>
            );
        }
        return null;
    }

    renderCoach(trainerId: string) {
        let trainer: StM.ICoachStoreState = trainerId ? _.find(this.props.coaches, { id: trainerId }) : null;
        if (!trainer) return null;
        let image = trainer.imageId ? trainer.imageId : '';
        let imageUrl = image ? `/api/v2/blobs/${image}/content` : '/content/img/nophoto-2.png';
        return (
            <div className="coach-wrapper-mobile flex-center-mobile">
                <div className="coach-photo-mobile">
                    <img src={imageUrl} />
                </div>
                <span className="coach-name text-ellipsis">{trainer.displayName}</span>
            </div>
        );
    }

    renderSlot(session: StM.ISessionStoreState) {
        if (session.maxUserCount < 2) return null;
        let sessionInfo = new PolM.SessionInfoPolicy(session, this.props.user);
        const playersCount = sessionInfo.getPlayers().length;
        let arr: Array<any> = [];

        for (let i = 0; i < session.maxUserCount; i++) {
            let classes = ClassNames("slots-item",
                { "one-slot": i == 0 },
                { "two-slot": i == 1 },
                { "three-slot": i == 2 },
                { "four-slot": i == 3 },
                { "five-slot": i == 4 },
                { "much-slot": i > 4 },
                { vacancy: i < playersCount })
            if (i <= 5) { arr.push(<span className={classes} key={i}></span>) }
        }

        let classesSlots = ClassNames("slots-mobile",
            { "one-player": session.maxUserCount == 1 },
            { "two-players": session.maxUserCount == 2 },
            { "three-players": session.maxUserCount == 3 },
            { "four-players": session.maxUserCount == 4 },
            { "more-than-5": session.maxUserCount > 4 },
        );
        return (
            <div className={classesSlots}>
                {arr}
            </div>
        );
    }

    getSessionTitle(session: StM.ISessionStoreState) {
        const sessionInfoPolicy = new PolM.SessionInfoPolicy(session, this.props.user);
        const notification = sessionInfoPolicy.getSessionNotification();        
        let sessionAvailability = new PolM.SessionAvailabilityPolicy(session, [], this.props.user);
        let isOwner = sessionAvailability.getIsOwner();
        let isDouble = session.isDoubledSession;
        let type = this.utils.getSessionTypeTitle(session);
        
        if (sessionAvailability.getIsInvited() && !sessionAvailability.getIsBoughtUser() && notification) return notification.message;

        if (session.type == StM.SessionType.Clinic) {
            return (isOwner ? "My " : "") + "Clinic" + (session.title ? ': ' + session.title : '');
        }
        if (session.type == StM.SessionType.Custom) {
            type = session.title || 'Custom Session';
        }
        return (isOwner ? 'My ' : '') + (isDouble ? 'Double ' : '') + type;
    }

    getSkillClass(skill: string) {
        switch (skill) {
            case StM.UserSkill.Beginner: {
                return 'beginner';
            }
            case StM.UserSkill.Intermediate: {
                return 'intermediate';
            }
            case StM.UserSkill.Advanced: {
                return 'advanced';
            }
            default: {
                return '';
            }
        }
    }

    onBadgeClick(e: any) {
        if (e) { e.preventDefault(); e.stopPropagation(); }
        this.props.onExpandSlot(this.props.timeKey);
    }

    onExpandHandle(isExpand: boolean) {
        this.setState({ ...this.state, isShow: !isExpand });
    }

    onCollapseBadge(e: any) {
        if (e) { e.preventDefault(); e.stopPropagation(); }
        this.props.onExpandSlot("");
    }

    private openSessionInfo(e: any, session: StM.SessionStoreState) {
        if (e) { e.preventDefault(); e.stopPropagation(); }
        let id = session.id || session.basketId;
        let from = session.id ? StM.SessionInfoFromTypes.calendar : StM.SessionInfoFromTypes.basket;
        this.props.openSessionInfo(from, id);
    }

    private getIsClinicOrCustomClinic(session: StM.ISessionStoreState): boolean {
        if (!session) return false;
        const isClinic = session.type === StM.SessionType.Clinic;
        const isCustomClinic = session.type === StM.SessionType.Custom && session.typeAlias === 'clinic';
        return isClinic || isCustomClinic;
    }
};