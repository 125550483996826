import './dialogs.scss';
import './leagueRequestToJoinDialog.scss';
import * as React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import * as _ from 'lodash';

import * as StM from '../../models/store';
import * as ActM from '../../actions';
import * as SrvM from '../../services';
import { Modal } from '../../components';

export interface ILeagueRequestToJoinDialogProps {
    isShow: boolean;
    isAuthorized: boolean;
    user: StM.IUserStoreState;
    sessionId: number;
    sessions: Array<StM.ISessionStoreState>;
    club: StM.IClubStoreState;

    showAlert: (msgKey:string) => Promise<any>;
    closeDialog: () => void;
    showSpinner: () => any;
    hideSpinner: () => any;
    openAuthDialog: (url: string) => Promise<any>;
    sendRequest: (name: string, email: string, phone: string, skill: string, leagueName: string, comment: string, session: StM.ISessionStoreState) => Promise<any>;
}

export interface ILeagueRequestToJoinDialogState {
    error: string;
}

class LeagueRequestToJoinDialog extends React.Component<ILeagueRequestToJoinDialogProps, ILeagueRequestToJoinDialogState> {
    private name: string; 
    private email: string; 
    private phone: string; 
    private skill: string;
    private comment: string;

    constructor(props: ILeagueRequestToJoinDialogProps) {
        super(props);
    }

    componentDidMount() {
        if (this.props.user) {
            this.name = this.props.user.displayName;
            this.email = this.props.user.userName;
            this.phone = '';
            this.skill = this.getSkill(this.props.user.skill);
        }
    }

    componentDidUpdate(prevProps: ILeagueRequestToJoinDialogProps) {
        if (!prevProps.isShow && this.props.isShow) {
            this.name = this.props.user.displayName;
            this.email = this.props.user.userName;
            this.phone = '';
            this.comment = '';
            this.skill = this.getSkill(this.props.user.skill);

            if (!this.props.isAuthorized) {
                this.props.closeDialog();
                let routeDlgSrv = new SrvM.RouteDialogService();
                let hash = routeDlgSrv.getDialogHash(StM.DialogNames.LeagueRequestToJoin, { sessionId: this.props.sessionId });
                let url = encodeURIComponent(hash);
                this.props.openAuthDialog(url);
            }
        }
    }

    render() {
        if(!this.props.isShow || !this.props.isAuthorized || !this.props.club) return null;
        return (
            <Modal classes="request-join-modal" closeHandler={this.props.closeDialog} dialogName={StM.DialogNames.LeagueRequestToJoin}>
                <div className="modal-dialog dialog">
                    <div className="modal-dialog-header">
                        <span className="close-dialog" onClick={(e) => this.onCloseClick(e)}></span>
                        <div className="title">Apply to Join {this.props.club.title} League</div>
                    </div>
                    <div className="modal-dialog-body profile-edit">
                        <div className="query-form-wrapper">
                            <div className="query-form-title">Comment</div>
                            <textarea rows={5} maxLength={3000} onChange={(e) => this.handleCommentChanging(e)}></textarea>
                        </div>
                        <div className="divider-line"></div>
                        <div className="btns-wrapper modal-dialog-body-content">
                            <button className="btn-send btn-red" onClick={(e) => { this.onSendClick(e);}}>apply</button>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }

    private onCloseClick(e: any){
        if(e) {e.stopPropagation();}
        this.props.closeDialog();
    }

    private handleCommentChanging(e: any){
        if(e) {e.preventDefault(); e.stopPropagation();}
        this.comment = e.target.value;
    }

    private onSendClick(e: any){
        if(e) {e.stopPropagation();}
        if(this.getIsValid()){
            this.props.showSpinner();
            const session = _.find(this.props.sessions, {id: this.props.sessionId});
            const leagueName = `${this.props.club.title} League`;
            this.props.sendRequest(this.name, this.email, this.phone, this.skill, leagueName, this.comment, session)
            .then(() => {
                this.props.closeDialog();
                this.props.hideSpinner();
                this.props.showAlert(StM.MessagesKey.LeagueRequestWasSend);
            }).catch(() => {
                this.props.hideSpinner();
            });
        }
    }

    private getIsValid(): boolean{
        return true;
    }

    getSkill(skill:string) {
        switch(skill) {
            case StM.UserSkill.Beginner: {
                return StM.DisplayUserSkill.Beginner;
            }
            case StM.UserSkill.Intermediate: {
                return StM.DisplayUserSkill.Intermediate;
            }
            case StM.UserSkill.Advanced: {
                return StM.DisplayUserSkill.Advanced;
            }
            default: {
                return '';
            }
        }
    }
}

const mapStateToProps = (state: StM.IGlobalStoreState, ownProps: any) => {
    return {
        isShow: state.dialogs.leagueRequestToJoin.isOpened
        , isAuthorized: state.app.isAuthorized
        , user: state.user
        , sessionId: state.dialogs.leagueRequestToJoin.sessionId
        , sessions: state.pages.leagues.sessions
        , club: state.club
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        closeDialog: () => dispatch(ActM.DialogActions.close(StM.DialogNames.LeagueRequestToJoin))
        , showSpinner: () => dispatch(ActM.AppActions.showSpinner())
        , hideSpinner: () => dispatch(ActM.AppActions.hideSpinner())
        , openAuthDialog: (url:string) => dispatch(ActM.DialogActions.open(StM.DialogNames.Auth, { tab: StM.AuthDialogTabs.SignIn, returnUrl: url}))
        , sendRequest: (name: string, email: string, phone: string, skill: string, leagueName: string, comment: string, session: StM.ISessionStoreState) => 
            dispatch(ActM.LeagueRequestToJoinDialogActions.sendRequest(name, email, phone, skill, leagueName, comment, session))
        , showAlert: (msgKey:string) => dispatch(ActM.DialogActions.open(StM.DialogNames.Alert, {msgKey: msgKey, messageType: StM.MessageTypes.Success}))
        
    };

}

export default connect(mapStateToProps, mapDispatchToProps)(LeagueRequestToJoinDialog);
