import ClassNames from 'classnames';
import * as React from 'react';
import { Link } from 'react-router-dom';

import { StM, SrvM } from '../../../../modules';

interface ICustomTabLinkProps {
    routeParams: StM.IBookPageRouteParams;

    onTabClickHandler: (params: any) => void;
}

interface ICustomTabLinkState {
    isShown?: boolean;
}

export class CustomTabLink extends React.Component<ICustomTabLinkProps, ICustomTabLinkState> {
    private routeSrv = new SrvM.RouteService();
    constructor(props: any) {
        super(props);
        this.state = {
            isShown: props.routeParams.sessionType === StM.BookPageSessionType.Custom
        };
    }

    componentDidUpdate(prevProps: ICustomTabLinkProps) {
        if (prevProps.routeParams.sessionType !== this.props.routeParams.sessionType) {
            this.setState({ isShown: this.props.routeParams.sessionType === StM.BookPageSessionType.Custom });
        }
    }

    public render(){
        const isActive = this.props.routeParams.sessionType === StM.BookPageSessionType.Custom;
        const classes = ClassNames('side-block-item-wrapper', 'custom', {extended: isActive, open: this.state.isShown}); 
        return (                
            <Link to={this.getRoute()} className={classes} onClick={ (e) => {this.onTabClick(e)}}>
                <div className="side-block-item">
                    <div className="block-item">
                        <div className="block-item-title">OTHER SESSIONS</div>
                    </div>    
                </div>
            </Link>
        )
    }

    private onTabClick(e: any): void {
        if(e) {e.stopPropagation();}
        if (this.props.routeParams.sessionType === StM.BookPageSessionType.Custom) {
            this.setState({isShown: !this.state.isShown});
        }
        this.props.onTabClickHandler({sessionType: StM.BookPageSessionType.Custom, date: this.props.routeParams.date});
    };

    private getRoute() {
        return this.routeSrv.getBookPage({
            ...this.props.routeParams,
            sessionType: StM.BookPageSessionType.Custom,
        }).path;
    }
}
