import * as React from 'react';
import moment from 'moment';

import { StM, SrvM, PolM } from '../../modules';
import Credits from '../credits/credits';

interface IBookDialogItemProps {
    item: StM.ISessionStoreState;
    user: StM.IUserStoreState;
    coaches: Array<StM.ICoachStoreState>;

    removeItem: (id: number) => void;
}

export class BookDialogItem extends React.Component<IBookDialogItemProps> {
    private utils = new SrvM.Utils();
    private groupInfo = new PolM.GroupInfoPolicy();

    constructor(props: IBookDialogItemProps) {
        super(props);
    }

    public render() {
        const item = this.props.item;
        let start = item.startDateTime.clone();
        let end = item.endDateTime.clone();

        let startTime = start.format('h:mm');
        let startA = start.format('a');
        let endTime = end.format('h:mm');
        let endA = end.format('a');
        let title = this.utils.getSessionTitle(item);
        title = item.series && item.series.isCourse ? `${title} (${item.series.sessionCount} Sessions)` : title;

        const pricesPolicy = new PolM.PricesPolicy(item.isDoubledSession, item);
        const prices = pricesPolicy.getSessionPrices(this.props.user);
        const isGroup =  this.groupInfo.getIsAuthenticated();

        const sessionAvailabilityPolicy = new PolM.SessionAvailabilityPolicy(item, []);
     
        let isOwner = sessionAvailabilityPolicy.getIsOwner();
        const isOwnerOrGroup = isOwner || isGroup;
        const isOwnerGroupMember = sessionAvailabilityPolicy.getIsOwnerGroupMember();
        const hasGroupBookings = !!sessionAvailabilityPolicy.getSessionGroupMemberBookings(false, true).length;

        const hasServices = (isOwner || isOwnerGroupMember) && !!this.utils.getSessionServices(item, item.owner as StM.IUserStoreState).length;
        const price = this.utils.handleMinPrice(item.checkoutPrice + (hasGroupBookings && !item.splitPrice ? prices.price : 0));
        const formattedPrice = this.utils.formatStringPrice(price.toString());
        const totalCredits = item.checkoutCredits || 0;

        let hasErrors = item.validationResult && item.validationResult.errors && item.validationResult.errors.length;
        let hasWarnings = item.validationResult && item.validationResult.warnings && item.validationResult.warnings.length;

        let errors: any;
        let warnings: any;
        let validationMessages: any;

        if (hasErrors) errors = this.renderErrors(item.validationResult.errors);
        if (hasWarnings) warnings = this.renderWarnings(item.validationResult.warnings)

        if (hasErrors || hasWarnings) validationMessages = (<div className="validation-wrapper">{errors}{warnings}</div>);
       
        const creditsBgColor = item.type === StM.SessionType.Custom ? item.customBackgroundColor : '';
        const bookingWithCharge = item.bookings.find((b) => b.id === 0 && b.paymentType == StM.PaymentTypes.Charge && b.amount > 0);
        const hasBookingAdditionalPrice = item.credits && !!bookingWithCharge;
       
        const hasAdditionalPrice = hasServices || hasBookingAdditionalPrice;
        const additionalPrice = hasBookingAdditionalPrice 
            ? this.utils.formatStringPrice(((hasServices ? prices.servicesPrice : 0) + bookingWithCharge.amount).toString()) 
            : prices.servicesPrice;
        let playersString: string = null;

        if(isGroup) {
            const players = totalCredits 
                ? item.addedUsers.map((u) => u)
                : sessionAvailabilityPolicy.getSessionGroupMemberBookings().map((b) => b.user);
            playersString = players.reduce((result, user) => result += !!result ? `, ${user.displayName}` : user.displayName, '');
        }

        return (
            <li className={"estimated-charges-list-item"
                + (item.trainer ? " with-coach" : "")
                + (hasErrors ? " validation-error" : "")
                + (hasWarnings ? " validation-warning" : "")
                + (totalCredits ? " with-credit" : "")
                + (totalCredits ? " " + item.type.toLowerCase() : "")
            }>
                <div className="estimated-charges-date">
                    <span className="day">{item.startDateTime.date()} </span>
                    <span className="month">{moment.monthsShort(item.startDateTime.month())}</span>
                    <span className="comma">,</span>
                </div>
                <div className="estimated-charges-list-item-content">
                    <div className="time-wrapper">
                        <div className="time-start-wrapper">
                            <div className="time-start">{startTime}</div>
                            <div className="time-start-time-type">{startA}</div>
                        </div>
                        <div className="time-divider">&ndash;</div>
                        <div className="time-end-wrapper">
                            <div className="time-end">{endTime}</div>
                            <div className="time-end-time-type">{endA}</div>
                        </div>
                    </div>
                    <div className="info-wrapper">
                        <div className="session-name">{title} {!!playersString && (<span>({playersString})</span>)} {hasServices && (<span> + Services</span>)}</div>
                        {this.renderCourtInfo(item)}
                    </div>
                    <div className="credit-price-delete-wrapper">
                        {!!totalCredits && (
                            <div className="credit-wrapper">
                                <Credits credits={totalCredits} type={item.type} bgColor={creditsBgColor} small={!hasAdditionalPrice || !isOwnerOrGroup}/>
                                {(!hasAdditionalPrice || !isOwnerOrGroup) && <span className="credit-label"> Club Credit{totalCredits > 1 ? 's' : ''}</span>}
                            </div>
                        )}
                        <div className="price-delete-wrapper">
                            <div className="price-wrapper">${formattedPrice}</div>
                            {!!hasAdditionalPrice && <span className="additional-price">+ ${additionalPrice}</span>}
                            <div className="delete-wrapper" onClick={(e) => this.removeItemClick(e)}></div>
                        </div>
                    </div>
                </div>
                {validationMessages}
            </li>
        )
    }

    private renderCourtInfo(session: StM.ISessionStoreState) {
        let trainer = this.props.coaches.find(c => c.id === session.trainerId);
        let image = trainer && trainer.imageId ? trainer.imageId : '';
        let imageUrl = image ? `/api/v2/blobs/${image}/content` : '/content/img/nophoto-2.png';
        let displayName = trainer && trainer.displayName ? trainer.displayName : '';

        return (
            <div className="court-info">
                {session.trainerId && <div className="coach-photo-wrapper">
                    <img src={imageUrl} />
                </div>}
                {session.trainerId && <div className="coach-name">{displayName}</div>}
                <div className="court-wrapper">
                    {this.utils.getSessionCourtsTitle(session)}
                </div>
            </div>
        )
    }

    private renderErrors(errors: Array<IValidationMessageDto>) {
        return errors.map((error: IValidationMessageDto, index: number) => (
            <div className="validation-error" key={index}>{error.message}</div>
        ));
    }

    private renderWarnings(warnings: Array<IValidationMessageDto>) {
        return warnings.map((warning: IValidationMessageDto, index: number) => (
            <div className="validation-warning" key={index}>{warning.message}</div>
        ));
    }

    private removeItemClick(e: any) {
        if (e) { e.preventDefault(); e.stopPropagation() };
        this.props.removeItem(this.props.item.basketId);
    }
}

export default BookDialogItem;