import './footer.scss';

import ClassNames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import IdleTimer from 'react-idle-timer';
import * as _ from 'lodash';

import { store } from '../store/store';
import { StM, SrvM, ActM } from '../modules';
import Banner from './banner';
import Header from './header';
import RightBlock from './rightBlock';
import Spinner from './spinner/spinner';
import BigFooter from './bigfooter';

/* Dialogs */
import AuthDialog from './dialogs/auth';
import CreateSessionDialog from './dialogs/session/sessionCreating/createSessionDialog';
import BookDialog from './dialogs/book/bookDialog';
import PackageCheckoutDialog from './dialogs/packageCheckoutDialog';
import AlertDialog from './dialogs/alertDialog';
import CancelConfirmation from './dialogs/cancelConfirmation';
import BillingInfoDialog from './dialogs/billingInfo/billingInfoDialog';
import BillingInfoStripeDialog from './dialogs/billingInfo/billingInfoStripeDialog';
import SessionInfoDialog from './dialogs/session/sessionInfo/sessionInfoDialog';
import EditUserDialog from './dialogs/profileEditing/editUserDialog';
import PasswordRecoveryDialog from './dialogs/passwordRecovery';
import PasswordChangingDialog from './dialogs/passwordChanging';
import HistoryQueryDialog from './dialogs/historyQueryDialog';
import LeagueRequestToJoinDialog from './dialogs/leagueRequestToJoinDialog';
import ContactUsDialog from './dialogs/contactUsDialog';
import ConfirmDeleteBasketItemDialog from './dialogs/confirmDeleteBasketItemDialog';
import SessionCreateSuccessDialog from './dialogs/sessionCreateSuccessDialog';
import PackageAddedDialog from './dialogs/packageAddedDialog';
import WaiverDialog from './dialogs/waiver';
import TermsAndConditionsDialog from './dialogs/termsAndConditions';
import CreditTermsDialog from './dialogs/creditTerms';
import PrivacyPolicyDialog from './dialogs/privacyPolicy';
import DemographicInformationDialog from './dialogs/demographicInformation/demographicInformationDialog';
import CreateGroupAccountDialog from './dialogs/groupAccount/groupAccountCreating/createGroupAccountDialog';
import InviteGroupMemberDialog from './dialogs/groupAccount/groupMemberInvitation/inviteGroupMemberDialog';
import EditGroupAccountDialog from './dialogs/groupAccount/groupAccountEditing/editGroupAccountDialog';
import ConfirmDialog from './dialogs/confirmDialog';
import GroupInfoDialog from './dialogs/groupAccount/groupInfoDialog';

/* Client Pages */
import BookPage from './pages/book/bookPage';
import BookLeagueListViewPage from './pages/book/leagueListView/leagueListViewPage';
import CoachPage from './pages/coachPage';
import PricingPage from './pages/pricing/pricingPage';
import PackagesPage from './pages/packages/packagesPage';
import MySessionsPage from './pages/mySessions';
import OpenSessionsBoard from './pages/openSessionsBoard';
import EmailConfirmationPage from './pages/emailConfirmationPage';
import SwitchClubPage from './pages/switchClubPage';
import UserPage from './pages/user/userPage';

/* Standalone Pages */
import ClubDashboard from './pages/clubDashboard/clubDashboardPage';
import ScoreboardPage from '../containers/pages/scoreboard/scoreboardPage';
import VideoPage from './pages/video/videoPage';
import StaticPage from './pages/static/staticPage';
import SubscriptionAddedDialog from './dialogs/subscriptionAddedDialog';
import SubscriptionCheckoutDialog from './dialogs/subscriptionCheckoutDialog';

interface IAppProps {
    isInitializing: boolean;
    isFinalized: boolean;
    isAuthorized: boolean;
    isDehydrated: boolean;
    location: any;
    match: any;
    showBasket: boolean;
    showNotification: boolean;
    user: StM.IUserStoreState;
    banner: StM.BannerStoreState
    club: StM.IClubStoreState

    logout: () => Promise<any>;
    checkUpdateLastAction: () => void;
    openContactUsDialog: () => any;
    setHeight: () => void;
    bannerClose: () => void;
    initOwnProps: (ownProps: any) => void;
    init: () => Promise<any>;
    closeAuthDialog: () => void;
    initBuildVersion: () => Promise<any>;
}

interface IAppState { }

const bookLeagueListViewRoute = `${StM.Pages.Book}/${StM.BookPageSessionType.League}/:date?`;
const clubDashboardRoute = StM.Pages.ClubDashboard;
const bookPlayRoute = `${StM.Pages.Book}/${StM.BookPageSessionType.Play}/:date?/:playSubfilter?`;
const bookLessonRoute = `${StM.Pages.Book}/${StM.BookPageSessionType.Lesson}/:date?/:lessonSubfilter?/:lessonSubfilterId?`;
const bookClinicRoute = `${StM.Pages.Book}/${StM.BookPageSessionType.Clinic}/:date?/:clinicSubfilter?/:clinicSubfilterId?`;
const bookCustomRoute = `${StM.Pages.Book}/${StM.BookPageSessionType.Custom}/:date?`;
const bookGroupMembersRoute = `${StM.Pages.Book}/${StM.BookPageSessionType.Group}/:date/member/:memberId?`;
const scoreboardRoute = `${StM.Pages.Scoreboard}/:courtId`;
const videoPageRoute = `${StM.Pages.Video}/:videoLinkId`;
const switchClubRoute = `${StM.Pages.SwitchClub}/:token`;

class App extends React.Component<IAppProps, IAppState> {
    private bannerHeight: number = 357;
    private smallBannerHeight: number = 30;
    private routeSrv = new SrvM.RouteService();

    constructor(props: IAppProps) {
        super(props);
    }

    public componentDidMount() {
        const frontendBuildVersion = APP_VERSION;
        const maxReloadAttempts = 2;
        const reloadAttempts = localStorage.getItem('reloadAttempts') 
            ? parseInt(localStorage.getItem('reloadAttempts') || '0', 10) 
            : 0;
    
        this.props.initBuildVersion().then((model) => {
            const { backendBuildNumber } = model;
            const skipVersionCheck = !backendBuildNumber || !frontendBuildVersion;
    
            if (backendBuildNumber === frontendBuildVersion || skipVersionCheck || reloadAttempts >= maxReloadAttempts) {
                document.addEventListener('scroll', (e) => this.handleScroll(e));
                this.props.initOwnProps(this.props.match.params);
                if (this.props.isDehydrated) this.props.init();

                if (reloadAttempts >= maxReloadAttempts) {
                    localStorage.removeItem('reloadAttempts');
                }
            } else {
                localStorage.setItem('reloadAttempts', String(reloadAttempts + 1));
                window.location.reload();
            }
        });
    }

    public componentDidUpdate(prevProps: IAppProps) {
        const isChangedProps = !_.isEqual(prevProps.match.params, this.props.match.params);
        const isAuthChanged = prevProps.isAuthorized !== this.props.isAuthorized;
    
        if (prevProps.location.pathname !== this.props.location.pathname) {
          this.updateRouteCallback();
        }
    
        if (isAuthChanged && this.props.isAuthorized) {
          this.props.closeAuthDialog();
        }
    
        if (isChangedProps) {
          this.props.initOwnProps(this.props.match.params);
        }
      }

    public shouldComponentUpdate(nextProps: IAppProps, nextState: IAppState) {
        return !nextProps.isInitializing;
    }

    public render() {
        if (this.props.isInitializing) { return null; }
        let wrapperClasses = ClassNames("main-wrapper", "booking-main-wrapper", {
            'show-basket': this.props.showBasket,
            'show-notif': this.props.showNotification
        });
        
        return (
            <IdleTimer
                element={document}
                onAction={() => this.handleAction()}
                onIdle={() => this.handleIdle()}
                timeout={IDLE_TIMEOUT_IN_MS}
            >
                <div>
                    <div className="wrapper" ref={() => this.props.setHeight()}>
                        <Banner/>
                        <Header match={this.props.match} location={this.props.match} hitory={this.props.match} />
                        <div className={wrapperClasses}>
                            <Switch>
                                <Route path={bookPlayRoute} component={BookPage} />
                                <Route path={bookLessonRoute} component={BookPage} />
                                <Route path={bookLeagueListViewRoute} component={BookLeagueListViewPage} />
                                <Route path={bookClinicRoute} component={BookPage} />
                                <Route path={bookCustomRoute} component={BookPage} />
                                <Route path={bookGroupMembersRoute} component={BookPage} />
                                <Route path={`${StM.Pages.FBLink}/:inviteToken`} component={BookPage} />
                                <Route path={StM.Pages.Coaches} component={CoachPage} />
                                <Route path={StM.Pages.Pricing} component={PricingPage} />
                                <Route path={`${StM.Pages.MySessions}/:start/:end`} component={MySessionsPage} />
                                <Redirect from={`${StM.Pages.MySessions}`} to={this.routeSrv.getMySessionsDefaultRoute(this.props.club, this.props.location)} />
                                <Route path={StM.Pages.OpenSessionsBoard} component={OpenSessionsBoard} />
                                <Route path={StM.Pages.Packages} component={PackagesPage} />
                                <Route path={StM.Pages.User} component={UserPage} />
                                <Route path="/email-confirmation/:result" component={EmailConfirmationPage} />
                                <Route path={clubDashboardRoute} component={ClubDashboard} />
                                <Route path={scoreboardRoute} component={ScoreboardPage} />
                                <Route path={videoPageRoute} component={VideoPage} />
                                <Route path={StM.Pages.Terms} component={StaticPage} />
                                <Route path={StM.Pages.Privacy} component={StaticPage} />
                                <Route path={StM.Pages.Waiver} component={StaticPage} />
                                <Route path={`${StM.Pages.SwitchClub}/:token`} component={SwitchClubPage} />
                                <Redirect to={this.routeSrv.getBookTodayRoute()} />
                            </Switch>
                            <RightBlock />
                        </div>

                        <div className="pusher"></div>
                    </div>

                    <BigFooter params={this.props.match.params} />

                    {/* don't change the order here as it relies on it for stacking*/}
                    {this.props.isFinalized && (<div>
                        <CreateSessionDialog />
                        <BookDialog />
                        <PackageCheckoutDialog />
                        <SubscriptionCheckoutDialog />
                        <BillingInfoDialog type={StM.PaymentSystem.AuthorizeNet} />
                        <BillingInfoStripeDialog type={StM.PaymentSystem.Stripe} />
                        <AuthDialog />
                        <SessionInfoDialog />
                        <EditUserDialog />
                        <PasswordRecoveryDialog />
                        <PasswordChangingDialog />
                        <HistoryQueryDialog />
                        <LeagueRequestToJoinDialog />
                        <ContactUsDialog />
                        <ConfirmDeleteBasketItemDialog />
                        <AlertDialog />
                        <CancelConfirmation />
                        <SessionCreateSuccessDialog />
                        <PackageAddedDialog />
                        <SubscriptionAddedDialog />
                        <WaiverDialog />
                        <TermsAndConditionsDialog />
                        <CreditTermsDialog />
                        <PrivacyPolicyDialog />
                        <DemographicInformationDialog />
                        <CreateGroupAccountDialog />
                        <InviteGroupMemberDialog />
                        <EditGroupAccountDialog />
                        <ConfirmDialog />
                        <GroupInfoDialog />
                    </div>)}
                    <Spinner />
                </div>
            </IdleTimer>
        )
    }

    // handlers
    
    private handleIdle() {
        if (!IS_MOBILE && this.props.isAuthorized) {
            this.props.logout();
        }
    }

    private handleAction() {
        this.props.checkUpdateLastAction();
    }

    private handleScroll(e: any) {
        var scrollY = document.documentElement.scrollTop || document.body.scrollTop;
        const isClubDashboard = this.routeSrv.isActivePage(StM.Pages.ClubDashboard);
        const isScoreboardPage = this.routeSrv.isActivePage(StM.Pages.Scoreboard);
        const isVideoPage = this.routeSrv.isActivePage(StM.Pages.Video);
        if (!isClubDashboard && !isScoreboardPage && !isVideoPage && scrollY >= this.bannerHeight && this.props.banner.isShown) {
            this.props.bannerClose();
            setTimeout(() => {
                document.documentElement.scrollTop = document.body.scrollTop = this.smallBannerHeight;
            }, 200);
        }
    }

    // halpers

    private updateRouteCallback() {
        let dispatch = store.dispatch;
        dispatch(ActM.BasketActions.close());
        dispatch(ActM.NotificationActions.close());
        dispatch(ActM.DialogActions.close(StM.DialogNames.TermsAndConditions));
        dispatch(ActM.DialogActions.close(StM.DialogNames.Waiver));
        dispatch(ActM.DialogActions.close(StM.DialogNames.CreditTerms));
        dispatch(ActM.DialogActions.close(StM.DialogNames.PrivacyPolicy));
    }
}

const mapStateToProps = (state: StM.IGlobalStoreState, ownProps: any) => {
    return {
        match: ownProps.match,
        location: ownProps.location,
        isInitializing: state.app.isInitializing,
        isFinalized: state.app.isFinalized,
        isDehydrated: state.app.isDehydrated,
        isAuthorized: state.app.isAuthorized,
        showBasket: state.basket.isShown,
        showNotification: state.notificationBoard.isShown,
        user: state.user,
        banner: state.banner,
        club: state.club
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        logout: () => dispatch(ActM.AccountActions.logout()),
        checkUpdateLastAction: () => dispatch(ActM.UserActions.checkUpdateLastAction()),
        setHeight: () => dispatch(ActM.BaseActions.setContentHeight()),
        bannerClose: () => dispatch(ActM.BannerActions.close()),
        initOwnProps: (ownProps: any) => dispatch(ActM.AppActions.initOwnProps(ownProps)),
        init: () => dispatch(ActM.AppActions.init()),
        closeAuthDialog: () => dispatch(ActM.DialogActions.close(StM.DialogNames.Auth)),
        initBuildVersion: () => dispatch(ActM.BuildVersionActions.init())
    };
}

const connectedApp = connect(mapStateToProps, mapDispatchToProps)(App);
const AppController = withRouter(connectedApp);
export default AppController;

