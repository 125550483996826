import ClassNames from 'classnames';
import * as React from 'react';
import * as _ from 'lodash';

import * as StM from '../../../../models/store';
import * as SrvM from '../../../../services';
import { Images } from '../../../../constants';

interface IGroupMembersTabLinkProps {
    routeParams: StM.IBookPageRouteParams;
    members: StM.IGroupMemberStoreState[];
    user: StM.IUserStoreState;

    onTabClickHandler: (params: any) => void;
}

interface IGroupMembersTabLinkState {
    isShown: boolean;
}

export class GroupMembersTabLink extends React.Component<IGroupMembersTabLinkProps, IGroupMembersTabLinkState> {
    private tabElement: any;
    private out: number = 78;
    private memberHeight: number = 95;

    private utils = new SrvM.Utils();
    private authSrv = new SrvM.AuthenticationService();

    constructor(props: any) {
        super(props);
        this.state = { 
            isShown: props.routeParams.sessionType === StM.BookPageSessionType.Group, 
        };
    }

    public componentDidMount() {
        this.init(this.props);
    }

    public componentDidUpdate(prevProps: IGroupMembersTabLinkProps) {
        const isDateChanged = prevProps.routeParams.date !== this.props.routeParams.date;
        const isSessionTypeChanged = prevProps.routeParams.sessionType !== this.props.routeParams.sessionType;
        if(isDateChanged || isSessionTypeChanged) {
            this.init(this.props);
        }
    }
    public render() {
        if(!this.authSrv.isInRole(StM.Roles.GroupAccount, this.props.user)) return null;
        const isActive = this.props.routeParams.sessionType === StM.BookPageSessionType.Group;
        const members = this.props.members.sort((a, b) => {
            const aIsHead = a.type === StM.GroupMemberType.Head;
            const bIsHead = b.type === StM.GroupMemberType.Head;
            if(aIsHead && !bIsHead) return -1;
            else if(!aIsHead && bIsHead) return 1;
            else return a.user.displayName.toLowerCase() > b.user.displayName.toLowerCase() ? 1 : -1
        });
        const membersCount = members.length;
        const classes = ClassNames('side-block-item-wrapper side-block-item-wrapper-group-members session', { 
            extended: isActive,
            open: this.state.isShown
        });

        const setHeightOuter = (elem: any) => {
            const blockGroupMemberItem: any = document.getElementsByClassName('side-block-item-group-member')[0];
            const blockGroupMemberItemOuter: any = document.getElementsByClassName('side-block-item-wrapper-group-members')[0];
            const blockItemHeight = this.state.isShown 
                ? this.memberHeight * membersCount + this.out - (this.utils.get_name_browser() === 'Safari' ? 9 : 0)
                : 80;

            blockGroupMemberItem.style.height = blockGroupMemberItemOuter.style.height = `${blockItemHeight}px`;
            
            if(!this.state.isShown) {
                setTimeout(()=>this.setBlockHeight(), 250);
                setTimeout(() => this.setOpacity(), 370);
            }
        }

        return (
            <div ref={(elem) => setHeightOuter(elem)} className={classes} onClick={(e) => this.onTabClick(e, members)}>
                <div className="side-block-item side-block-item-group-member">
                    <div className="block-item">
                        <div className="block-item-title" ref={(elem) => this.tabElement = elem}>MEMBERS</div>
                        <div className="hidden-content group-members-hidden-content">
                            {this.getMembersFilter(members, +this.props.routeParams.memberId)}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private getMembersFilter(members: StM.IGroupMemberStoreState[], currentMemberId: number) {
        return (
            <section>
                {members
                    .map(member => {
                        const user = member.user;
                        const isHead = member.type === StM.GroupMemberType.Head;
                        const classes = ClassNames('inner-item-wrapper', { active: member.id === currentMemberId });
                        const imageUrl = user.imageId ? this.utils.getImageUrl(user.imageId) : Images.NoPhoto2;
                        return (
                            <div className={classes} key={member.id} onClick={(e) => this.onItemClick(e, member)}>
                                <div className="user-avatar-wrapper">
                                    <img className="user-avatar" src={imageUrl} alt={user.displayName} />
                                </div>
                                <div className="user-characteristic-wrapper">
                                    <div className="name">{user.displayName}</div>
                                    <div className="name-coach-tooltip">
                                        <span className="name-coach-tooltip-title">{user.displayName}</span>
                                    </div>
                                    <div className="skill">{isHead && '(Head) '}{user.skill}</div>
                                </div>
                            </div>
                        );
                })}
            </section>
        );
    }

    private setOpacity() {
        const blockGroupMembersHidden: any = document.getElementsByClassName('group-members-hidden-content')[0];
        if (blockGroupMembersHidden) {
            blockGroupMembersHidden.style.opacity = '1';
        }
    }

    private setBlockHeight() {
        const blockGroupMemberItem: any = document.getElementsByClassName('side-block-item-group-member')[0];
        if (blockGroupMemberItem) {
            blockGroupMemberItem.style.height = '81px';
        }
    }

    private onTabClick(e: any, members: StM.IGroupMemberStoreState[]): void {
        if (e) { e.stopPropagation(); }
        if (e.target !== this.tabElement) return;

        const membersCount = this.props.members.length;
        if (this.props.routeParams.sessionType === StM.BookPageSessionType.Group) {
            this.setState({ ...this.state, isShown: !this.state.isShown });
        }
        this.props.onTabClickHandler({ 
            sessionType: StM.BookPageSessionType.Group, 
            date: this.props.routeParams.date,
            memberId: members.length ? members[0].id.toString() : null
        });

        const blockGroupMemberItem: any = document.getElementsByClassName('side-block-item-group-member')[0];
        const blockGroupMembersItemOuter: any = document.getElementsByClassName('side-block-item-wrapper-group-members')[0];
        const blockGroupMembersHidden: any = document.getElementsByClassName('group-members-hidden-content')[0];

        blockGroupMembersHidden.style.opacity = '0';
        setTimeout(() => this.setOpacity(), 370);

        let blockItemHeight = 79;
        let blockItemOuterHeight = 80;

        if (!this.state.isShown) {
            const browserDiff = navigator.userAgent.indexOf("Safari") !== -1 || !(navigator.userAgent.indexOf('MSIE') !== -1) ? 0 : 26;
            blockItemHeight = blockItemOuterHeight = this.memberHeight * membersCount + this.out - (browserDiff);
        }

        blockGroupMemberItem.style.height = `${blockItemHeight}px`;
        blockGroupMembersItemOuter.style.height = `${blockItemOuterHeight}px`;
    };

    private onItemClick(e: any, member: StM.IGroupMemberStoreState): void {
        if (e) { e.stopPropagation(); }
        const membersCount = this.props.members.length;
        this.props.onTabClickHandler({
            sessionType: StM.BookPageSessionType.Group,
            date: this.props.routeParams.date,
            memberId: member.id.toString(),
        });
        const blockGroupMemberItem: any = document.getElementsByClassName('side-block-item-group-member')[0];
        const blockGroupMembersItemOuter: any = document.getElementsByClassName('side-block-item-wrapper-group-members')[0];
        blockGroupMemberItem.style.height = blockGroupMembersItemOuter.style.height = `${this.memberHeight * (membersCount) + this.out}px`;
    }

    private init(props: IGroupMembersTabLinkProps) {
        this.setState({
            ...this.state,
            isShown: props.routeParams.sessionType === StM.BookPageSessionType.Group
        });
    }
}