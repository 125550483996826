import './videoPlayer.scss';
import * as React from 'react';
import YouTube from 'react-youtube';

interface IVideoPlayerProps {
    videoId: string;
}

export class VideoPlayer extends React.PureComponent<IVideoPlayerProps> {
    constructor(props: IVideoPlayerProps) {
        super(props);
    }

    render() {
        const opts: any = {
            playerVars: { // https://developers.google.com/youtube/player_parameters
                autoplay: 0,
                controls: 1,
                rel: 0,
                fs: 1
            }
        }
        return (
            <YouTube
                id='video-control'
                videoId={this.props.videoId}
                opts={opts}
                className="video-control"
            />
        )
    };
};