import './baseBadge.scss';
import './freeBadge.scss';
import * as React from 'react';
import ClassNames from 'classnames';
import * as SrvM from '../../../../../services';
import {IBaseBadgeProps, DurationClasses} from './baseBadge';

interface IFreeBadgeBadgeProps extends IBaseBadgeProps {

}

interface IFreeBadgeState { }

export class FreeBadge extends React.Component<IFreeBadgeBadgeProps, IFreeBadgeState> {
    private utils: SrvM.Utils;

    constructor(props: IFreeBadgeBadgeProps) {
        super(props);
        this.utils = new SrvM.Utils();
    }

    render() {
        const offset = this.props.time.duration ;
        if(offset === 0) { return null; }

        const durationClass = DurationClasses.getDurationClass(offset);
        const classes = ClassNames('table-dashboard-column-item-wrapper', durationClass);

        return (
            <div className={classes}>
                <div className="table-dashboard-column-item "></div>
            </div>
        );
    }
}
