import * as Redux from 'redux';

import { ThunkService } from '../../services/thunkService';
import { StM, ActM } from '../../modules';

export class LeagueListViewPageActions {
    constructor() {
    }

    static init()
    : (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<any> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            
            dispatch(ActM.AppActions.showSpinner());
            const final = () => {
                dispatch({ type: 'Pages/LeagueListView/Init', payload: null });
                dispatch(ActM.AppActions.hideSpinner());
                dispatch(ActM.BaseActions.hideSplash());
            };

            const sessionP = Promise.resolve();//dispatch(LeagueListViewPageActions.getSessions());
            return Promise.all([sessionP])
                .then(() => {
                    return final();
                }).catch((error) => {
                    thunkService.logger.error(error);
                    return final();
                });
        };
    }

    static getSessions(): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<any> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            const { club, pricingTiers } =  getState();
            return thunkService.api.session.getLeagues(club, pricingTiers)
                .then((sessions: Array<StM.ISessionStoreState>) => {
                    dispatch({
                        type: 'Pages/LeagueListView/Sessions',
                        payload: sessions
                    });
                })
        };
    }
}
