import * as React from 'react';
import ClassNames from 'classnames';
import moment from 'moment';

import { StM, SrvM, Constants } from '../../../modules';

interface IDateSelectorProps {
    params: StM.IBookPageRouteParams;
    date: moment.Moment;
    club: StM.IClubStoreState;

    onDateChangedHandler: (params: StM.IBookPageRouteParams) => void;
}

export class DateSelector extends React.Component<IDateSelectorProps> {
    private _monthes = moment.monthsShort();
    private _weekdays = moment.weekdays();
    private utils =  new SrvM.Utils();
    private routeSrv = new SrvM.RouteService();

    constructor(props: IDateSelectorProps) {
        super(props);
    }

    public render() {
        const { date, club } = this.props;
        const leftArrowClasses = ClassNames("left-arrow", { "loading" : !club.clubTimes.length });
        return (
            <div className="calendar-wrapper">
                <div className={leftArrowClasses} onClick={(e) => this.handleArrowClick(e, true)}></div>
                {this.renderWeek(date)}
                <div className="right-arrow" onClick={(e) => this.handleArrowClick(e)}></div>
            </div>
        )
    };

    private renderWeek(day: moment.Moment) {
        let firstDay = day.clone().weekday(0);
        let weekElems:Array<any> = [];
        for (let i = 0; i < 7; i++) {
            weekElems.push(this.renderDay(firstDay, day));
            firstDay.add(1, 'day');                
        }
        return weekElems;
    }

    private renderDay(day: moment.Moment, active: moment.Moment) {
        let today = this.utils.getCurrentClubDateTime(this.props.club);
        let date = day.date();
        let month = this._monthes[day.month()];
        let weekday = this._weekdays[day.weekday()];
        let value = day.clone();
        let classes =  ClassNames("day-wrapper", {
            'current-date': active.date() == date,
            'today': this.dateCompare(day, today),
            'past-day': this.getIsPast(day, today)
        });
        return (
            <div className={classes} key={date} onClick={(e) => this.onDateClick(e, value)}>
                <div className="day-wrapper-content">
                    <div className="today-text">Today</div>
                    <span className="date">{date}</span>
                    <span className="month">{month}</span>
                    <div className="day-of-week">{weekday}</div>
                    <div className="caret"></div>
                </div>
            </div>
        );
    }

    private dateCompare(dateA: moment.Moment, dateB: moment.Moment) {
        return this.dateCompareFormat(dateA) == this.dateCompareFormat(dateB);
    }

    private dateCompareFormat(date: moment.Moment) {
        return date.format(Constants.DateTime.DATE_FORMAT);
    }

    private getIsPast(date: moment.Moment, today: moment.Moment) {
        return date.year() <= today.year() 
                && (date.month() < today.month() 
                    || (date.month() == today.month() && date.date() < today.date())
            );
    }

    private handleArrowClick(e: any, isPrev: boolean = false) {
        if(e) e.stopPropagation();
        const date = this.props.date.clone().add(isPrev ? -7 : 7, 'day');
        this.onDateChanged(date);
    }

    private onDateClick(e: any, date: moment.Moment){
        if(e) e.stopPropagation();
        this.onDateChanged(date);
    }

    private onDateChanged(date: moment.Moment){
        const dateFormat = this.routeSrv.getRouteDateParam(date, this.props.club);
        this.props.onDateChangedHandler({ ...this.props.params, date: dateFormat });
    }
}
