import './notifications.mobile.scss';
import * as React from 'react';
import { connect } from 'react-redux';
import ClassNames from 'classnames';
import * as _ from 'lodash';
import * as ActM from '../actions';
import * as StM from '../models/store';
import { IGlobalStoreState } from '../models/store';
import * as SrvM from '../services';

interface INotificationsProps {
    notifications: Array<StM.INotificationStoreState>;
    club: StM.IClubStoreState;

    showInfo: (id:number) => Promise<any>;
    dismiss: (notification: StM.INotificationStoreState) => void;
}

interface INotificationState { }

class NotificationMobile extends React.Component<INotificationsProps, INotificationState> {

    private utils: SrvM.Utils;

    constructor(props: INotificationsProps) {
        super(props);
        this.utils = new SrvM.Utils();
    }

    render() {
        if(!this.props.club) return null;
        let notifs = this.props.notifications;
        let admins = this.filterAdminMessage(notifs).sort((a, b) => {
            return b.dateCreated.valueOf() - a.dateCreated.valueOf();
        });
        let invitations = this.filterInvitation(notifs);

        return (
            <div className="main-content-wrapper notifications-page-mobile">
                <div className="page-title-mobile">Notifications</div>
                {!this.props.notifications || this.props.notifications.length == 0 ?
                    (<p className="note-mobile">You have no notifications</p>) :
                    (
                        <div className="notification-list-wrapper-mobile">
                            <div className="admin-notification-list-wrapper-mobile">
                                {admins.map(item => this.renderAdmin(item))}
                            </div>
                            <div className="invitation-list-wrapper-mobile">
                                {invitations.map(item => this.renderInvitation(item))}
                            </div>
                        </div>
                    )
                }
            </div>
        );
    }

    renderAdmin(notif: StM.INotificationStoreState) {
        return (
            <div className="admin-notification-item-mobile" key={notif.id}>
                <div className="title-mobile">{notif.subject}</div>
                <div className="message-body-mobile">{notif.message}</div>
                <div className="signature-wrapper-mobile">
                    <div className="signature-title">{this.props.club.title}</div>
                    <div className="signature-date">{notif.dateCreated.format('MMM DD, YYYY')}</div>
                </div>
                <div className="btn-wrapper-mobile">
                    <div className="dismiss-btn" onClick={(e) => this.dismissClick(e, notif)}>Dismiss</div>
                </div>
            </div>
        );
    }

    renderInvitation(notif: StM.INotificationStoreState) {

        if(notif.target && notif.targetType == StM.NotificationTargetType.Session){
            const session: StM.ISessionStoreState = notif.target;
            const start = session.startDateTime;
            const end = session.endDateTime;
            const classes = ClassNames("notification-item-mobile", this.utils.getSessionClass(session));

            const date = start.date();
            const month = start.format('MMM');
            const timeStart = start.format('h:mm');
            const timeStartType = start.format('a');
            const timeEnd = end.format('h:mm');
            const timeEndType = end.format('a');

            const iconStyles = { backgroundColor: session.customBackgroundColor };
            
            return (
                <div className={classes} key={notif.id}>
                    <div className="date-time-wrapper-mobile flex-baseline-mobile">
                        <span className="day-mobile">{date} </span>
                        <span className="month-mobile">{month}, </span>
                        <div className="time-wrapper-mobile">
                            <div className="time-mobile">{timeStart}</div>
                            <div className="time-type-mobile">{timeStartType}</div>
                        </div>
                        <div className="time-divider-mobile">&ndash;</div>
                        <div className="time-wrapper-mobile">
                            <div className="time-mobile">{timeEnd}</div>
                            <div className="time-type-mobile">{timeEndType}</div>
                        </div>
                    </div>
                    <div className="title-wrapper-mobile">
                        <div className="title-icon-mobile" style={iconStyles} />
                        <span className="title-mobile">{notif.message}</span>
                    </div>
                    <button className="btn-view btn-mobile btn-black" onClick={(e) => this.showInfoClick(e, notif)}>View</button>
                </div>
            );
        }
        return null;
    }

    showInfoClick(e: any, notif: StM.INotificationStoreState) {
        if (e) {e.stopPropagation();}
        this.props.showInfo(notif.targetId);
    }

    dismissClick(e: any, notif: StM.INotificationStoreState) {
        if (e) { e.stopPropagation(); }
        this.props.dismiss(notif);
    }

    filterAdminMessage(arr: Array<StM.INotificationStoreState>) {
        return arr.filter(item => item.targetType == StM.NotificationTargetType.User || item.targetType == StM.NotificationTargetType.Broadcast);
    }

    filterInvitation(arr: Array<StM.INotificationStoreState>) {
        return arr.filter((item) => {
            return item.targetType == StM.NotificationTargetType.Session
                && item.event == StM.NotificationEventType.SessionYouInvited
        });
    }
};

const mapStateToProps = (state: IGlobalStoreState) => {
    let notifications = _.filter(state.notificationBoard.notifications, (noty) => {
            return noty.targetType == StM.NotificationTargetType.User || noty.targetType == StM.NotificationTargetType.Broadcast
                || (noty.targetType == StM.NotificationTargetType.Session 
                    && noty.event == StM.NotificationEventType.SessionYouInvited);
    });
    return {
        notifications: notifications,
        club: state.club,
    };
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        showInfo: (id:number) =>  dispatch(ActM.DialogActions.open(StM.DialogNames.SessionInfo, {from: StM.SessionInfoFromTypes.calendar, id: id})),
        dismiss: (notification: StM.INotificationStoreState) => dispatch(ActM.NotificationActions.dismiss(notification))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationMobile);