import './customBadge.scss';
import * as React from 'react';
import ClassNames from 'classnames';
import * as PolM from '../../../../../policies';
import * as SrvM from '../../../../../services';
import { IBaseBadgeProps, DurationClasses, CommonBadge } from './baseBadge';


interface ICustomBadgeBadgeProps extends IBaseBadgeProps {
}

interface ICustomBadgeState { }

export class CustomBadge extends React.Component<ICustomBadgeBadgeProps, ICustomBadgeState> {
    private oneMinHeight: number = 57 / 15;
    private utils = new SrvM.Utils();
    constructor(props: ICustomBadgeBadgeProps) {
        super(props);
    }

    render() {
        const session = this.props.time.session;
        const sessionInfoPolicy = new PolM.SessionInfoPolicy(session);
        const players = sessionInfoPolicy.getPlayers();
        const coach = session && session.trainer ? this.props.time.session.trainer.displayName : '';
        const sizeClass = DurationClasses.getBadgeSizeByDuration(this.props.time.duration);
        const classes = ClassNames('table-dashboard-column-item-wrapper', 'custom', sizeClass, {
            'with-coach-dashboard': coach != '',
            'many-players': this.props.time.duration === 30 && players.length > 2,
            'has-video': this.utils.hasVideo(this.props.time.session) 
        });
        const title = session && session.title ? session.title : '';
        //const renderedPlayers = CommonBadge.renderPlayers(session, this.props.time);
        const styles = {
            backgroundColor: session.customBackgroundColor,
            color: session.customTextColor,
            height: this.oneMinHeight * this.props.time.duration
        }
        return (
            <div className={classes} style={styles}>
                <div className="table-dashboard-column-item">
                    <div className="name-coach-dashboard-wrapper">
                        <div className="name-dashboard-wrapper">
                            <div className="type-lesson-dashboard">
                                <span className="type-title">{title}</span>
                                {CommonBadge.getRenderVideoIcon(this.props.time.session)}
                            </div>
                            <div className="name-lesson-dashboard"></div>
                        </div>
                        {coach && <div className="coach-dashboard-wrapper">{coach}</div>}
                    </div>
                    {/*renderedPlayers*/}
                </div>
            </div>
        );
    }
}
