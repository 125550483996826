import '../dialogs.scss';
import './bookDialog.scss';

import * as React from 'react';
import * as _ from 'lodash';
import ClassNames from 'classnames';
import { Link } from 'react-router-dom';

import { StM, SrvM } from '../../../modules';
import * as BD from './baseBookDialog';
import { Images } from '../../../constants';
import { Modal } from '../../../components';
import { BookDialogItem, BookPackageDialogItem } from '../../../components/basket';
import CreditsWallet from '../../../components/creditsWallet';

interface BookDialogProps extends BD.IBookDialogProps {}

class BookDialog extends React.Component<BookDialogProps> {
    private utils = new SrvM.Utils();
    constructor(props: BookDialogProps) {
        super(props);
    }

    public render() {
        if (!this.props.isShown) return null;
        const prices = this.props.basketPolicy.getBasketPrices();
        const hasDiscount = prices.discountPercentage > 0 && (0 + prices.discountPrice) && (!!prices.subtotalPrice || !!prices.totalServicesPrice);
        const isValidBasket = this.props.basketPolicy.handle();
        const modalClasses = ClassNames('your-estimated-charges-modal', { error: !isValidBasket });

        return (
            <Modal classes={modalClasses} closeHandler={(e) => this.props.onCloseClick(e)} dialogName={StM.DialogNames.Book}>
                <div className='modal-dialog'>
                    <div className='modal-dialog-header'>
                        <span className='close-dialog' onClick={(e: any) => this.props.onCloseClick(e)} />
                        <div className='title checkout'>Checkout</div>
                        {this.renderCredits(this.props.user)}
                        <div className='title est-charges'>Your estimated charges</div>
                    </div>
                    <div className='modal-dialog-body'>
                        <div className='modal-dialog-body-content'>
                            <p className="book-note">
                                {StM.Strings.BookingNote(this.props.club)}
                                {!!this.props.club.priceList && (
                                    <>
                                        &nbsp;For more details visit our <Link to={StM.Pages.Pricing}>Pricing</Link> page.
                                    </>
                                )}
                            </p>
                            <ul className='estimated-charges-list-wrapper'>
                                {this.props.basketPackages.map((item: StM.PackageDefinitionStoreState) => {
                                    return (
                                        <BookPackageDialogItem
                                            item={item}
                                            key={item.id}
                                            removeItem={(id) => this.props.onRemovePackageItemClick(id)}
                                        />
                                    );
                                })}
                                {this.props.basketSessions.map((item: StM.SessionStoreState) => {
                                    return (
                                        <BookDialogItem
                                            user={this.props.user}
                                            item={item}
                                            key={item.basketId}
                                            coaches={this.props.coaches}
                                            removeItem={(id) => this.props.onRemoveItemClick(id)}
                                        />
                                    );
                                })}
                            </ul>
                            <div className='summary-wrapper'>
                                {(hasDiscount || !!prices.totalSalesTaxesPrice || !!prices.totalServicesPrice) && (
                                    <div className='session-price-wrapper'>
                                        <div className='sesson-price-label'>Price</div>
                                        <div className='sesson-price-count'>${this.utils.formatStringPrice(prices.subtotalPrice.toString())}</div>
                                    </div>
                                )}
                                {!!prices.totalServicesPrice && (
                                    <div className='session-price-wrapper'>
                                        <div className='sesson-price-label'>Services</div>
                                        <div className='sesson-price-count'>
                                            ${this.utils.formatStringPrice(prices.totalServicesPrice.toString())}
                                        </div>
                                    </div>
                                )}
                                {!!hasDiscount && (
                                    <div className='session-discount-wrapper'>
                                        <div className='sesson-discount'>{'Discount (' + prices.discountPercentage + '%)'}</div>
                                        <div className='sesson-discount-count'>${this.utils.formatStringPrice(prices.discountPrice.toString())}</div>
                                    </div>
                                )}
                                {!!prices.totalSalesTaxesPrice && (
                                    <div className='session-discount-wrapper'>
                                        <div className='sesson-discount'>{`${this.props.club.salesTaxTitle} (${this.props.club.salesTaxRate}%)`}</div>
                                        <div className='sesson-discount-count'>
                                            ${this.utils.formatStringPrice(prices.totalSalesTaxesPrice.toString())}
                                        </div>
                                    </div>
                                )}
                                <div className='session-total-wrapper'>
                                    <div className='sesson-total'>Total &nbsp;</div>
                                    <div className='sesson-total-count'>${this.utils.formatStringPrice(prices.totalPrice.toString())}</div>
                                </div>
                            </div>
                        </div>
                        <div className='divider-line' />
                        <div className='btns-wrapper modal-dialog-body-content'>
                            {this.renderPaymentSystemLogo(this.props.club.paymentSystem.type)}
                            <div className='btn-footer-wrapper'>
                                <button className='btn btn-block btn-red' onClick={(e) => this.props.onCheckoutClick(e)} disabled={!isValidBasket}>
                                    Complete purchase
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }

    private renderCredits(user: StM.UserStoreState) {
        if (
            !user.creditsWallet ||
            !user.creditsWallet.creditsWalletBalances ||
            !user.creditsWallet.creditsWalletBalances.length ||
            !_.some(user.creditsWallet.creditsWalletBalances, (x) => x.credits > 0)
        )
            return null;

        return (
            <div className='your-balance'>
                <div className='credit-title'>Your Credit Balance</div>
                <CreditsWallet user={user} class='credit-sessions-wrapper' customSessionTypes={this.props.customSessionTypes} />
            </div>
        );
    }

    private renderPaymentSystemLogo(paymentSystem: StM.PaymentSystem) {
        if (paymentSystem !== StM.PaymentSystem.AuthorizeNet) return null;
        return (
            <div className='img-footer-wrapper'>
                <img src={Images.AuthorizeNetLogo} className='authorize-img' alt={`${StM.Strings.PaymentSystems.get(paymentSystem)} logo.`} />
            </div>
        );
    }
}

export default BD.withBookDialog(BookDialog);
